import * as yup from 'yup';

export const schemeValidation = yup.object({
    facility: yup
        .string()
        .required('Facility is required'),
    year: yup
        .number()
        .required('Year is required'),
    date: yup
        .date()
        .required('Tarining Date is required'),
    assessmentPeriod: yup
        .string()
        .required('Assessment Period is required'),
    numberOfAttendees: yup
        .number()
        .required('Number of Attendees is required'),
    trainingMethods: yup
        .string()
        .required('Training method is required'),
    description: yup
        .string()
        .required('Description is required'),
    
});