import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Stack,
  Tooltip,
  Pagination,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CeroTable from "../../../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../../../redux/constants";
import CreateIcon from "@mui/icons-material/CreateOutlined";

const EmployeesDiversityTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    backToEmission,
    headerOff,
    editOff,
    reportTable,
    isOnlyAdmin,
    period,
    distribution
  } = props;

  const EmployeeDiversityByGender = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "male",
      columnId: "male",
      columnHeader: "% of Employees identifying as Male",
    },
    {
      columnKey: "female",
      columnId: "female",
      columnHeader: "% of Employees identifying as Female",
    },
    {
      columnKey: "other",
      columnId: "other",
      columnHeader: "% of Employees identifying as Others",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    }
  ];

  const EmployeeDiversityByAge = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "under_30",
      columnId: "under_30",
      columnHeader: "% of Employees under 30 years old",
    },
    {
      columnKey: "between_30_50",
      columnId: "between_30_50",
      columnHeader: "% of Employees 30-50 years old",
    },
    {
      columnKey: "over_50",
      columnId: "over_50",
      columnHeader: "% of Employees over 50 years old",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    }
  ];

  const RatioOfBasicsalary = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "basic_salary_ratio_for_male",
      columnId: "basic_salary_ratio_for_male",
      columnHeader: "Basic Salary Ratio (Male)",
    },
    {
      columnKey: "basic_salary_ratio_for_female",
      columnId: "basic_salary_ratio_for_female",
      columnHeader: "Basic Salary Ratio (Female)",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    }
  ];

  const totalEntriesForGender = emissionData?.gender_distribution?.length;
  const totalEntriesForAge = emissionData?.age_distribution?.length;
  const totalEntriesForSalaryRatio = emissionData?.ratio_salary?.length;

  const [page, setPage] = useState(1);
  const DEFAULT_ITEMS_PER_PAGE = 12;
  const currentFilter = `?distribution=gender`
  const onSelectEmissionData = (rowData) => {
    isAuditor
      ? navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}/?company=${company}`
        )
      : navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}${currentFilter}`
          // `/emissions/${emissionType}/details/${rowData.id}?distribution=gender`
        );
  };

  const onSelectData = (rowData) => {
    isAuditor
      ? navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}/?company=${company}`
        )
      : navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}?distribution=age`
        );
  };

  const onSelectSalaryRatio = (rowData) => {
      isAuditor
        ? navigate(
            `/reports/list-topic/${emissionType}/details/${rowData.id}/?company=${company}`
          )
        : navigate(
            `/reports/list-topic/${emissionType}/details/${rowData.id}?distribution=basic_salary_ratio`
          );
  }

  const getStatus = (status) => {
    if (status === "approved") {
      return reportTable ? (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      ) : (
        <Typography className={classes.approved}>Approved</Typography>
      );
    } else if (status === "submitted") {
      return reportTable ? (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "audited") {
      return reportTable ? (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      ) : (
        <Typography className={classes.audited}>Audited</Typography>
      );
    } else if (status === "pending") {
      return reportTable ? (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "added") {
      return reportTable ? (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      ) : (
        <Typography className={classes.added}>Added</Typography>
      );
    } else if (status === "assigned") {
      return reportTable ? (
        <Tooltip title="Assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "review") {
      return reportTable ? (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.review}>Review</Typography>
      );
    } else if (status === "verified") {
      return reportTable ? (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      ) : (
        <Typography className={classes.verified}>Verified</Typography>
      );
    }else if (status === "Awaiting approval") {
      return reportTable ? (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "pending_approval") {
      return reportTable ? (
        <Tooltip title="Pending approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending approval</Typography>
      );
    } else {
      return reportTable ? (
        <Tooltip title="" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>{status}</Typography>
      );
    }
  };

  const onEditEmissionData = (e, emission) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/emissions/edit/employees_diversity_and_ratio_of_basic_salary/${emission}`);
  };

  const getGenderDistribution = () =>
    emissionData?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      period: period,
      action: (
        <Box className={classes.actionContainer}>
          {item.status == "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }));

  const getByAgeDistribution = () =>
    emissionData?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      action: (
        <Box className={classes.actionContainer}>
          {item.status == "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }));

  const getBySalaryRatio = () =>
    emissionData?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      action: (
        <Box className={classes.actionContainer}>
          {item.status == "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }));

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, newPage);
  };
  const startItem = (page - 1) * DEFAULT_ITEMS_PER_PAGE + 1;
  const endItem = Math.min(
    startItem + DEFAULT_ITEMS_PER_PAGE - 1,
    totalEntriesForGender
  );
  const endItemAge = Math.min(
    startItem + DEFAULT_ITEMS_PER_PAGE - 1,
    totalEntriesForAge
  );
  const endItemSalary = Math.min(
    startItem + DEFAULT_ITEMS_PER_PAGE - 1,
    totalEntriesForSalaryRatio
  );


  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          {distribution === "gender" &&
          <>
          <Typography variant="h7" component="span">
            <span className={classes.tableSubTitle}>
              Employee Diversity by Gender
            </span>
          </Typography>
          <CeroTable
            columns={EmployeeDiversityByGender || []}
            data={getGenderDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          />
          {totalEntriesForGender > 0 && (
            <div className={classes.pagination}>
              <Typography variant="body" style={{ fontSize: 12 }}>
                Showing {startItem} to {endItem} of {totalEntriesForGender}{" "}
                entries
              </Typography>
              <Pagination
                count={Math.ceil(
                  totalEntriesForGender / DEFAULT_ITEMS_PER_PAGE
                )}
                page={page}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          )}</>}
          {distribution === "age" && 
          <>
          <Typography variant="h7" component="span">
            <span className={classes.tableSubTitle}>
              Employee Diversity by Age Group
            </span>
          </Typography>
          <CeroTable
            columns={EmployeeDiversityByAge || []}
            data={getByAgeDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectData}
            classes={{ tableContainer: classes.tableContainer }}
          />
          {totalEntriesForAge > 0 && (
            <div className={classes.pagination}>
              <Typography variant="body" style={{ fontSize: 12 }}>
                Showing {startItem} to {endItemAge} of {totalEntriesForAge}{" "}
                entries
              </Typography>
              <Pagination
                count={Math.ceil(totalEntriesForAge / DEFAULT_ITEMS_PER_PAGE)}
                page={page}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          )}</>}
          {distribution === "basic_salary_ratio" && 
          <>
          <Typography variant="h7" component="span">
            <span className={classes.tableSubTitle}>Ratio of Basic Salary</span>
          </Typography>
          <CeroTable
            columns={RatioOfBasicsalary || []}
            data={getBySalaryRatio}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectSalaryRatio}
            classes={{ tableContainer: classes.tableContainer }}
          />
          {totalEntriesForAge > 0 && (
            <div className={classes.pagination}>
              <Typography variant="body" style={{ fontSize: 12 }}>
                Showing {startItem} to {endItemSalary} of{" "}
                {totalEntriesForSalaryRatio} entries
              </Typography>
              <Pagination
                count={Math.ceil(
                  totalEntriesForSalaryRatio / DEFAULT_ITEMS_PER_PAGE
                )}
                page={page}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          )}</>}
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default EmployeesDiversityTable;
