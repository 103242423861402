import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "100%",
    width: "100%",
    borderRadius: 8,
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100% - 100px)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    marginTop: 20,
    overflow: "auto",
  },
  tableInnerContainer: {
    maxHeight: "100%",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  MultiSelectformControl: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 46,
    "& .MuiFormLabel-root": {
      left: theme.spacing(2),
      top: theme.spacing(1),
    },
    background: "white",
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
    "& .Mui-focused > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
  },
  filterContainer: {
    margin: theme.spacing( 3, 0, 8, 0),
    display: "flex",
    alignItems: "center",
    "& .MuiGrid-item":{
      paddingLeft: "0px !important",
    },
  },
  yearFilter: {
    "& .MuiBox-root": {
      height: "42px !important",
      margin: theme.spacing(0.6, 0, 0, 0),
      padding: "0px !important"
    },
    "& .MuiGrid-item":{
      paddingLeft: "0px !important",
    },
    "& .MuiBox-root > .MuiAutocomplete-root ": {
      background: "white",
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root":
      {
        height: 46,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > input[type=text]":
      {
        paddingBlock: 0,
        paddingInline: 0,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-outlined":
      {
        marginTop: -3,
      },
    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":{
      WebkitTextFillColor: "#024FE4",
      fontWeight: 500
    }
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    marginRight: 15,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  confirmButton:{
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    marginRight: 15,
    marginTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.contrastText,
    },
  },
  link: {
    cursor: "pointer",
  },
  icon: {
    color: theme.palette.Primary.hoverButton,
    fontSize: "xx-large",
  },
  dropdown: {
    backgroundColor: theme.palette.background.white,
  },
  autoComplete:{
    width: "100%",
    marginRight: theme.spacing(4),
  },
  textField: {
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
  popUpdialog: {
    width: "50% !important",
    textAlign: "center",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
  tooltip: {
    cursor: "pointer",
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white !important",
      border: "1px solid #5A404E",
      color: "black",
      padding: ` ${theme.spacing(5)} !important`,
      maxWidth: "none !important"
    },
  },
  toolTipText:{
    whiteSpace: "nowrap",
    fontSize: "14px",
    paddingBottom: theme.spacing(3),
  },
  confirmText:{
    paddingTop: theme.spacing( 5),
    fontWeight: 500,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: 5,
    paddingTop: theme.spacing( 10),
    paddingBottom: theme.spacing( 5),
    width: "auto",
    margin: "auto",
  },
  externalLink: {
    color: "blue",
    paddingRight: theme.spacing(3),
  },
}));

export default useStyles;
