import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    paddingBottom: theme.spacing(3),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  detailsContainer: {
    flex: 1,
    minHeight: 'calc( 100vh + 30px )',
    overflow: "hidden",
    padding: theme.spacing(4),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    position: "relative",
    margin: 0,
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 15
  },
  buttonPrimary: {
    background:theme.palette.Primary.background,
    height: 37,
    margin: theme.spacing(0, 2),
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor:theme.palette.Primary.hoverButton,
    },

  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Secondary.hoverButton,
    },
    width: 140,
    height: 37,
    fontSize: 13,
    margin: theme.spacing(0, 5),
  },
  buttonTeritiary:{
    height: 37,
    fontSize: 13,
    margin: theme.spacing(0, 5),
    background:theme.palette.Primary.background,
    "&:hover": {
      backgroundColor:theme.palette.Primary.dark,
    },
  },
  editorContainer: {
    height: "calc(100% - 50px)",
    width: "100%",
  },
  saveButton: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  tooltip: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    cursor: "pointer",
    color: theme.palette.text.accent,
  },
  footer: {
    marginTop: theme.spacing(3)
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: 'center',
    cursor: "pointer"
  },
  exportIcons:{
    display: "flex",
    justifyContent: "flex-end"
  },
  audited: {
    color: "green",
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  approved: {
    color: "orange",
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  review: {
    color: theme.palette.status.review,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  added:{
    color: "red",
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  signal:{
    backgroundColor: "green",
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalAdded:{
    backgroundColor: theme.palette.status.added,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalApproved:{
    backgroundColor: theme.palette.status.approved,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalReview:{
    backgroundColor: theme.palette.status.review,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)   
  },
  signalPending:{
    backgroundColor: theme.palette.status.pendingApproval,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  },
  signalAudited:{
    backgroundColor: theme.palette.status.audited,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  },
  signalVerified:{
    backgroundColor: theme.palette.status.verified,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  }
  
}));

export default useStyles;
