import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import {  useFormik } from "formik";
import { useParams } from 'react-router-dom';
import { assignReportSchema } from "./schema";
import { APIEndpoints } from "../../../../redux/constants";
import ErrorIcon from '@mui/icons-material/Error';
import CeroSideSheetDrawer from "../../../../components/CeroSideSheetDrawer";
import useStyles from "./styles";
import axios from "axios";
import { getCookie } from "../../../../services/cookie";


const AssignReport = (props) => {
  const {  onClose} = props;
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const assignReportData = useSelector((state) => state.reports.assignReport.data);


  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [errorMessage,setErrorMessage] = useState(null)
  const formik = useFormik({
    initialValues: {
      email: assignReportData.email || "",
    },
    validationSchema: assignReportSchema,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const checkEmailAvailability = async (email, save) => {
    try {
      const response = await axios.post(APIEndpoints.ASSIGN_REPORT(id), {
        email: email,
        save: false,
      });
      if (response.data.isAvailable) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
    
  const authToken = getCookie("auth_token_admin");
  const handleEmailChange = async (event) => {
    const email = event.target.value;
    formik.handleChange(event);
    try {
      await assignReportSchema.validate({ email });
      setIsEmailValid(true);
    } catch (error) {
      setIsEmailValid(false);
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post(
        APIEndpoints.ASSIGN_REPORT(id),
        {
          email: email,
          save: false,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setIsLoading(false);
      setIsEmailAvailable(!response.data.isAvailable);
      setErrorMessage(null)
    } catch (error) {
      setIsLoading(false);
      setIsEmailAvailable(false);
      setErrorMessage(error.response?.data.detail)
      enqueueSnackbar(error.response?.data.detail, { variant: 'error' });
    }
  };
  
  const onSubmitAssignReportData = async () => {
    const isValidEmail = await formik.validateForm();
    if (isValidEmail) {
      const email = formik.values.email; 
      const isEmailAvailable = await checkEmailAvailability(email, true); 
          const response = await axios.post(APIEndpoints.ASSIGN_REPORT(id), {
            email: email,
            save: true,
          },{
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
          enqueueSnackbar('Report Assigned successfully', { variant: 'success' });
          formik.resetForm();
          onClose();
      } 
  };

  const getPrimaryPaymentDrawer = () => {
    const isInvalidEmail = formik.touched.email || (!isEmailValid || !isEmailAvailable);
    return (
      <Box className={classes.mainContainer}>
        <TextField
          required
          id="email"
          name="email"
          label="Email"
          fullWidth
          value={formik.values.email}
          onChange={handleEmailChange}
          error={isInvalidEmail}
          helperText={isInvalidEmail ? <><ErrorIcon color="red"  fontSize="small"/>{"Invalid email address"}</> : ""}
          InputProps={{
            endAdornment: ( 
              <div style={{ position: "relative" }}>
                {isLoading && (
                  <CircularProgress
                    size={20}
                    style={{ transform: "translateY(-50%)", color: "#8C2148" }}
                  />
                )}
              </div>
            ),
          }}
        />
      </Box>
    );
  };

  return (
    <CeroSideSheetDrawer
      primaryDrawer={{
        drawerOpen: props.isOpen,
        onClose: () => props.onClose(false),
        content: getPrimaryPaymentDrawer(),
        header: { title: "Assign Report" },
        footer: {
          primaryBtnTitle: "Save",
          secondaryBtnTitle: "Cancel",
          primaryBtnAction: onSubmitAssignReportData,
          secondaryBtnAction: () => props.onClose(false),
          disablePrimaryBtn:
            !formik.dirty ||
            !formik.isValid ||
            !isEmailAvailable ||
            !isEmailValid,
        },
        classes: {
          drawerContainer: classes.drawerContainer,
          contentArea: classes.drawerContentArea,
        },
      }}
    />
  );
};

export default AssignReport;
