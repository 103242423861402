import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(5px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },

  content: {
    backgroundColor: "#fff",
    padding: "20px 60px",
    borderRadius: "6px",
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "center",
  },

  h2: {
    marginTop: 0,
  },

  p: {
    marginBottom: "20px",
  },

  btn: {
    padding: "10px 20px",
    marginRight: "10px",
    border: "none",
    borderRadius: "6px",
    background: "#8C2148",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
    height: "35px",
    "&:hover": {
      background: "#8C2148",
    },
  },

  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 999 ,
    pointerEvents: "none",
  },
}));

export default useStyles;
