import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: "16px 16px 0 0",
    width: "100%",
    borderRadius: 8,
    marginLeft: 12,
    marginRight: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "-webkit-fill-available",
    },
  },
  tableContainer: {
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    margin: 0,
  },
  cellContainer: {
    boxShadow: "none",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeaderContainer: {
    padding: theme.spacing(3),
    background: theme.palette.Secondary.background,
    fontWeight: 500,
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 10,
    alignItems: "center",
    cursor: "pointer",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: 500,
    marginTop: theme.spacing(8),
  },
}));

export default useStyles;