import React from "react";
import { Box, Container, Typography } from "@mui/material";
import dayjs from "dayjs";

import CeroInfoPair from "../../../../components/CeroInfoPair";
import useStyles from "./styles";

const Status = (props) => {
  const classes = useStyles();
  const data = props?.details ? props?.details[0] : false;

  return (
    <Container className={classes.container}>
      <Typography
        style={{
          fontWeight: 500,
          fontSize: 17,
          marginBottom: 30,
          marginLeft: 9,
        }}
      >
        {data.name ? data.name : "-"}
      </Typography>
      <Box style={{ display: "flex", gap: 10 }}>
        <CeroInfoPair
          title="Email:"
          value={data.email}
          classes={{ container: classes.infoContainer }}
        />
        <CeroInfoPair
          title="Role:"
          value={data ? data?.role[0] === "read_only_user" ? "Read Only User" : "" : ""}
          classes={{ container: classes.infoContainer }}
        />
        <CeroInfoPair
          title="Status:"
          value={data ? data.status === true ? "Active" : "Inactive" : ""}
          classes={{ container: classes.infoContainer }}
        />
      </Box>
    </Container>
  );
};

export default Status;
