import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const WorkerSafetyTraining = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionId,
    emissionData,
    onCancel,
    isDeleteEnable,
    setIsDrawerOpen,
    isSusManager
  } = props;

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Worker safety training procedures
            </Typography>
          </Box>
          <Box className={classes.actionBox}>
            <CeroInfoPair
              title="Status:"
              value={emissionData.status ? emissionData.status : "NA"}
              classes={{
                container: classes.statusContainer,
                title: classes.statusTitle,
                value:
                  emissionData.status === "In Progress"
                    ? classes.Inprogress
                    : classes[emissionData?.status],
              }}
            />
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              display={"contents"}
              spacing={12}
            >
              <Grid item container direction={"column"} display={"contents"} xs={10}>
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Title:"
                  value={
                    emissionData.training_title
                      ? emissionData.training_title
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Date:"
                  value={emissionData.date ? emissionData.date : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Training Methods:"
                  value={
                    emissionData.training_method
                      ? emissionData.training_method
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Number of training hours:"
                  value={
                    emissionData.number_of_hours
                      ? emissionData.number_of_hours
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                 <CeroItemPair
                  title="Trainer Profile:"
                  value={
                    emissionData.training_profile
                      ? emissionData.training_profile
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Number of Attendees:"
                  value={emissionData.attended ? emissionData.attended : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Training Details:"
                  value={
                    emissionData.training_details
                      ? emissionData.training_details
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                {emissionData.questions &&
                  emissionData.questions.map((ques, index) => (
                    <div key={index}>
                      {ques.answer && (
                        <CeroItemPair
                          title={`${ques.question}`}
                          value={ques.answer ? ques.answer : "NA"}
                          classes={{
                            container: classes.infoContainer,
                            title: classes.title,
                            value: classes.value,
                          }}
                        />
                      )}
                    </div>
                  ))}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default WorkerSafetyTraining;
