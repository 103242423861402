import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from "@mui/material";

import CeroTable from '../../../components/CeroTable';
import useStyles from "./styles";
import { useDispatch, useSelector } from 'react-redux';
import { companyCountryList } from "../../../redux/actions";
import { STATUS } from '../../../redux/constants';


export const TableColumns = [
    {
        columnKey: 'country',
        columnId: 'country',
        columnHeader: 'Country',
    },
    {
        columnKey: 'number',
        columnId: 'number',
        columnHeader: 'Number of Facilities',
    }
];

const CountriesTable = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {  onLoadMore } = props;
    const companyCountries = useSelector(state => state.listings.companyCountries.data);
    const companyCountriesStatus = useSelector(state => state.listings.companyCountries.status);

    useEffect(() => {
        dispatch(companyCountryList(props.companyId))
    }, [dispatch])


    const onSelectCompanyData = (company) => {
        navigate(`/companies/${props.companyId}/country/${company.country}/facilities`);
    };

    const getCountriesList = () => {
        return Object.entries(companyCountries).map(([country, number]) => ({
            country,
            number,
        }));
    };

    return (
        <div>
        {companyCountriesStatus === STATUS.SUCCESS?<CeroTable
            columns={TableColumns}
            data={getCountriesList()}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectCompanyData}
            classes={{ tableContainer: classes.tableContainer }}
        />: <Box className={classes.loader}>
        <Typography variant="h7" component="span">
          {companyCountriesStatus === STATUS.RUNNING
            ? <CircularProgress/>
            : companyCountriesStatus === STATUS.ERROR
            ? "We couldn't process your request. Please try again later"
            : ""}
        </Typography>
      </Box>}
        </div>
        
    );
};

export default CountriesTable;
