import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updateWorkerSafetyTraining,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import dayjs from "dayjs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";
import CeroSelect from "../../../components/CeroSelect";
import { sampleYear } from "../../../constants";
import CeroDate from "../../../components/CeroDate";

const EditWorkerSafetyTrainingForm = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const updateEmissionData = useSelector(
    (state) => state.emission.updateWorkerSafetyTraining
  );

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || "",
      assessmentPeriod: emissionData.assessment_period || "",
      trainingTitle: emissionData.training_title || "",
      date: emissionData.date || "",
      trainingMethods: emissionData.training_method || "",
      attended: emissionData.attended || "",
      trainingHours: emissionData.number_of_hours || "",
      trainingProfile: emissionData.training_profile || "",
      details: emissionData.training_details || "",
      isTrainingDuringPaidHours: (emissionData.questions && emissionData.questions[0].answer) || "",
      isTrainingFreeOfCharge: (emissionData.questions && emissionData.questions[1].answer) || "",
      isTrainingMandatory: (emissionData.questions && emissionData.questions[2].answer) || "",
      areEmployeesCompensated: (emissionData.questions && emissionData.questions[3].answer) || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values, dispatch]);

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Worker safety training procedures updated successfully",
        { variant: "success" }
      );
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateWorkerSafetyTraining = () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      training_title: formik.values.trainingTitle,
      training_date: dayjs(formik.values.date).format("DD/MM/YYYY"),
      training_method: formik.values.trainingMethods,
      no_of_attendees: formik.values.attended,
      training_hours: formik.values.trainingHours,
      training_profile: formik.values.trainingProfile,
      training_details: formik.values.details,
      questions: [
        {
          question: "Is the training conducted during paid working hours?",
          answer: formik.values.isTrainingDuringPaidHours,
        },
        {
          question: "Is the training provided free of charge?",
          answer: formik.values.isTrainingFreeOfCharge,
        },
        {
          question: "If no, is the training mandatory?",
          answer: formik.values.isTrainingMandatory,
        },
        {
          question: "Are the employees compensated for attending?",
          answer: formik.values.areEmployeesCompensated,
        },
      ],
      save: true,
    };
    dispatch(updateWorkerSafetyTraining(requestData));
  };

  const trainingMethods = [
    { key: "On the job Training", value: "On the job Training" },
    { key: "Online Courses", value: "Online Courses" },
    { key: "Workshops", value: "Workshops" },
  ];

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography variant="h6" component="div">
            Edit Worker Safety Training Procedures
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column">
                <CeroInput
                  required
                  id="trainingTitle"
                  name="trainingTitle"
                  label="Training Title"
                  fullWidth
                  multiline
                  rows="2"
                  value={formik.values.trainingTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroDate
                  required
                  label="Training Date"
                  value={formik.values.date}
                  assessmentPeriod={formik.values.assessmentPeriod}
                  year={formik.values.year}
                  onChange={(date) => formik.setFieldValue("date", date)}
                  error={formik.touched.date && formik.errors.date}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="trainingMethods"
                  name="trainingMethods"
                  label="Training Methods"
                  options={trainingMethods}
                  selectedValue={formik.values.trainingMethods}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroInput
                  required
                  id="attended"
                  name="attended"
                  label="Number of Attendees"
                  value={formik.values.attended}
                  type="number"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.attended && formik.errors.attended}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={4} xs={6}>
                <CeroInput
                  required
                  id="trainingHours"
                  name="trainingHours"
                  label="Number of training hours"
                  fullWidth
                  value={formik.values.trainingHours}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  required
                  id="trainingProfile"
                  name="trainingProfile"
                  label="Trainer Profile"
                  fullWidth
                  value={formik.values.trainingProfile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column">
                <CeroInput
                  required
                  id="details"
                  name="details"
                  label="Training details"
                  fullWidth
                  multiline
                  rows="2"
                  value={formik.values.details}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <CeroQuestionWithCheckbox
                required
                question="Is the training conducted during paid working hours?"
                formik={formik}
                fieldName="isTrainingDuringPaidHours"
                onChange={handleAnswerChange}
                checkedField={formik.values.isTrainingDuringPaidHours}
              />
              <CeroQuestionWithCheckbox
                required
                question="Is the training provided free of charge?"
                formik={formik}
                fieldName="isTrainingFreeOfCharge"
                onChange={handleAnswerChange}
                checkedField={formik.values.isTrainingFreeOfCharge}
              />
              {formik.values.isTrainingFreeOfCharge === "No" && (
                <>
                  <CeroQuestionWithCheckbox
                    question="If no, is the training mandatory?"
                    formik={formik}
                    fieldName="isTrainingMandatory"
                    onChange={handleAnswerChange}
                    checkedField={formik.values.isTrainingMandatory}
                  />
                  <CeroQuestionWithCheckbox
                    question="Are the employees compensated for attending?"
                    formik={formik}
                    fieldName="areEmployeesCompensated"
                    onChange={handleAnswerChange}
                    checkedField={formik.values.areEmployeesCompensated}
                  />
                </>
              )}
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={!formik.dirty || !formik.isValid}
            className={classes.buttonPrimary}
            onClick={onUpdateWorkerSafetyTraining}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={onCancel}
          />
        </Box>
      </Container>
    </>
  );
};

export default EditWorkerSafetyTrainingForm;
