import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updateLocalCommunities,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import CeroSelect from "../../../components/CeroSelect";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { sampleYear } from "../../../constants";
import CeroAutoComplete from "../../../components/CeroAutoComplete";

const EditLocalCommunitiesForm = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const updateEmissionData = useSelector(
    (state) => state.emission.updateLocalCommunities
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      assessmentPeriod: emissionData.assessment_period || "",
      year: emissionData.year || "",
      amount: emissionData.amount || "",
      localCommunity: emissionData.local_community || "",
      projectUndertaken: emissionData.project_undertaken || "",
      objective: emissionData.objective || "",
      activitiesExecuted: emissionData.activities_executed || "",
      resultAchieved: emissionData.result_achieved || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'local_communities', emissionData.id));
    }
  }, [formik.values, dispatch]);

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Local Communities details updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateLocalCommunitiesData = () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      amount: formik.values.amount,
      local_community: formik.values.localCommunity,
      project_undertaken: formik.values.projectUndertaken,
      objective: formik.values.objective,
      activities_executed: formik.values.activitiesExecuted,
      result_achieved: formik.values.resultAchieved,
      save: true,
    };
    dispatch(updateLocalCommunities(requestData));
  };

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Local Communities
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroInput
                  required
                  id="localCommunity"
                  name="localCommunity"
                  label="Name of local community"
                  fullWidth
                  value={formik.values.localCommunity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.localCommunity &&
                    formik.errors.localCommunity
                  }
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                <CeroInput
                  required
                  id="amount"
                  name="amount"
                  label="Total Contributions"
                  fullWidth
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && formik.errors.amount}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroInput
              required
              id="projectUndertaken"
              name="projectUndertaken"
              label="Describe the projects undertaken."
              fullWidth
              multiline
              rows="2"
              value={formik.values.projectUndertaken}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.projectUndertaken &&
                formik.errors.projectUndertaken
              }
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="objective"
              name="objective"
              label="Describe the main objective of the projects."
              fullWidth
              multiline
              rows="2"
              value={formik.values.objective}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.objective && formik.errors.objective}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="activitiesExecuted"
              name="activitiesExecuted"
              label="Describe activities that were executed as part of the engagement projects."
              fullWidth
              multiline
              rows="2"
              value={formik.values.activitiesExecuted}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.activitiesExecuted &&
                formik.errors.activitiesExecuted
              }
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="resultAchieved"
              name="resultAchieved"
              label="Summarize the results achieved as a result of the engagement projects."
              fullWidth
              multiline
              rows="2"
              value={formik.values.resultAchieved}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.resultAchieved && formik.errors.resultAchieved
              }
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
          <Box className={classes.buttonContainer}>
            <CeroButton
              buttonText="Update"
              disabled={!formik.dirty || !formik.isValid}
              className={clsx(classes.button, classes.buttonPrimary)}
              onClick={onUpdateLocalCommunitiesData}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={clsx(classes.button, classes.buttonSecondary)}
              onClick={formik.resetForm}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default EditLocalCommunitiesForm;
