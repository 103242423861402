import React, { useEffect, useState } from "react";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const AssignedReports = ({ assignedReports, filterYear }) => {
  const [internalFilterYear, setInternalFilterYear] = useState(filterYear);
  const classes = useStyles();
  const navigate = useNavigate();

  const auditSummaryColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Report Name",
      width : '15vw',
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
      width : '10vw'
    },
    {
      columnKey: "created_by_name",
      columnId: "created_by_name",
      columnHeader: "Created By",
      width : '15vw'
    },
    {
      columnKey: "created_ts",
      columnId: "created_ts",
      columnHeader: "Created on",
      width : '15vw'
    },
  ];

  useEffect(() => {
    setInternalFilterYear(filterYear !== undefined ? filterYear : null);
  }, [filterYear]);

  const onSelectAuditSummaryData = (row) => {
    navigate(
      `details/${row.id}?year=${row.year}&id=${row.id}&type=${row.report_type}`
    );
  };

  const getReportData = () =>
    assignedReports
      ?.filter(
        (item) => internalFilterYear === null || item.year == internalFilterYear
      )
      ?.map((item) => ({
        ...item,
        name: item.name || "",
        year: item.year || "",
        created_by_name: item.created_by_name || "",
        created_ts: item.created_ts
          ? dayjs(item.created_ts).format("DD MMM YYYY HH:mm")
          : "-",
      }));

  return (
    <>
      {assignedReports && (
        <CeroTable
          columns={auditSummaryColumns}
          data={getReportData()}
          hasMore={false}
          loading={false}
          onSelectRow={onSelectAuditSummaryData}
          // classes={{ tableContainer: classes.tableContainer }}
          className={[classes.tableContainer]}
        />
      )}
    </>
  );
};

export default AssignedReports;