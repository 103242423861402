import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';

export const dashboardState = {
  getEmissionYear: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEmissionTypes: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEmissionRegion: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEmissionsByMonth: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEmissionByScope: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getEmissionByScopeByYear: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getEsgOverview: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getStatistics: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getFacilityTopicEmission: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getFuelSource: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  notificationList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  markAllRead: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  markAsRead: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAdminDashboard: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  companyDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  stakeholderDashboard: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  stakeholderEsgOverview: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  stakeholderEmissionByScopeByYear: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  stakeholderEmissionTypeGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStakeholderEmissionYearData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  sustainabilityNews: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  auditorDashboard: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAuditStatusGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAuditTrendGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEsgOverviewGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getRecordStatus: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getTicketStatus: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  facilityDistribution: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  roleDistribution: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEnergyMaterial: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getFuelSourcesTarget: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getInitiativeTracking: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEmployeeDiversity: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getDevelopmentTraining: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getSocialEngagement: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getHireResignGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getBoardManagementGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEmployeeTurnoverGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEconomicGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEthicalBehaviourGraph: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAntiCorruptionTraining: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAntiCorruptionDisclosure: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getInitiativeTrackingDrillDown: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getEnergyMaterialsDrillDown: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getNotifications: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
};

const dashboardActions = {
  dashboard: handleActions(
    {
      [ActionTypes.GET_EMISSION_YEAR]: (state) =>
        immutable(state, {
          getEmissionYear: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_YEAR_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEmissionYear: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMISSION_YEAR_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEmissionYear: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_EMISSION_TYPES]: (state) =>
        immutable(state, {
          getEmissionTypes: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEmissionTypes: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMISSION_TYPES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEmissionTypes: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_EMISSION_REGION]: (state) =>
        immutable(state, {
          getEmissionRegion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_REGION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEmissionRegion: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMISSION_REGION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEmissionRegion: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_EMISSIONS_BY_MONTH]: (state) =>
        immutable(state, {
          getEmissionsByMonth: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_MONTH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEmissionsByMonth: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_MONTH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEmissionsByMonth: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_SCOPE]: (state) =>
        immutable(state, {
          getEmissionByScope: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_SCOPE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEmissionByScope: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_SCOPE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEmissionByScope: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_SCOPE_BY_YEAR]: (state) =>
        immutable(state, {
          getEmissionByScopeByYear: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_SCOPE_BY_YEAR_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getEmissionByScopeByYear: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMISSIONS_BY_SCOPE_BY_YEAR_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getEmissionByScopeByYear: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_ESG_OVERVIEW]: (state) =>
        immutable(state, {
          getEsgOverview: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ESG_OVERVIEW_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEsgOverview: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ESG_OVERVIEW_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEsgOverview: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_DASHBOARD_STATISTICS]: (state) =>
        immutable(state, {
          getStatistics: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_DASHBOARD_STATISTICS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getStatistics: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_DASHBOARD_STATISTICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getStatistics: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_FACILITY_TOPIC_EMISSION]: (state) =>
        immutable(state, {
          getFacilityTopicEmission: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_FACILITY_TOPIC_EMISSION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getFacilityTopicEmission: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_FACILITY_TOPIC_EMISSION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getFacilityTopicEmission: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_FUEL_SOURCE_EMISSION]: (state) =>
        immutable(state, {
          getFuelSource: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_FUEL_SOURCE_EMISSION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getFuelSource: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_FUEL_SOURCE_EMISSION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getFuelSource: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.LIST_NOTIFICATIONS]: (state) =>
        immutable(state, {
          notificationList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_NOTIFICATIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          notificationList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_NOTIFICATIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          notificationList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.MARK_ALL_READ]: (state) =>
        immutable(state, {
          markAllRead: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.MARK_ALL_READ_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          markAllRead: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.MARK_ALL_READ_FAILURE]: (state, { payload }) =>
        immutable(state, {
          markAllRead: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.MARK_AS_READ]: (state) =>
        immutable(state, {
          markAsRead: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.MARK_AS_READ_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          markAsRead: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.MARK_AS_READ_FAILURE]: (state, { payload }) =>
        immutable(state, {
          markAsRead: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_SUPERADMIN_DASHBOARD]: (state) =>
        immutable(state, {
          getAdminDashboard: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUPERADMIN_DASHBOARD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getAdminDashboard: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUPERADMIN_DASHBOARD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getAdminDashboard: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.STAKEHOLDER_COMPANY_DETAILS]: (state) =>
        immutable(state, {
          companyDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.STAKEHOLDER_COMPANY_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          companyDetails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.STAKEHOLDER_COMPANY_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          companyDetails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.STAKEHOLDER_DASHBOARD]: (state) =>
        immutable(state, {
          stakeholderDashboard: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.STAKEHOLDER_DASHBOARD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          stakeholderDashboard: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.STAKEHOLDER_DASHBOARD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          stakeholderDashboard: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.STAKEHOLDER_ESG_OVERVIEW]: (state) =>
        immutable(state, {
          stakeholderEsgOverview: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.STAKEHOLDER_ESG_OVERVIEW_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          stakeholderEsgOverview: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.STAKEHOLDER_ESG_OVERVIEW_FAILURE]: (state, { payload }) =>
        immutable(state, {
          stakeholderEsgOverview: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.STAKEHOLDER_ESG_OVERVIEW]: (state) =>
        immutable(state, {
          stakeholderEsgOverview: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.STAKEHOLDER_ESG_OVERVIEW_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          stakeholderEsgOverview: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.STAKEHOLDER_ESG_OVERVIEW_FAILURE]: (state, { payload }) =>
        immutable(state, {
          stakeholderEsgOverview: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR]: (state) =>
        immutable(state, {
          stakeholderEmissionByScopeByYear: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          stakeholderEmissionByScopeByYear: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          stakeholderEmissionByScopeByYear: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.STAKEHOLDER_EMISSION_TYPE_GRAPH]: (state) =>
        immutable(state, {
          stakeholderEmissionTypeGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.STAKEHOLDER_EMISSION_TYPE_GRAPH_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          stakeholderEmissionTypeGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.STAKEHOLDER_EMISSION_TYPE_GRAPH_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          stakeholderEmissionTypeGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_EMISSION_YEAR_DATA]: (state) =>
        immutable(state, {
          getStakeholderEmissionYearData: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_EMISSION_YEAR_DATA_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderEmissionYearData: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_EMISSION_YEAR_DATA_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderEmissionYearData: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_NEWS]: (state, { payload }) =>
        immutable(state, {
          sustainabilityNews: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_NEWS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          sustainabilityNews: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_NEWS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          sustainabilityNews: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_AUDITOR_DASHBOARD]: (state) =>
        immutable(state, {
          auditorDashboard: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_AUDITOR_DASHBOARD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditorDashboard: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_AUDITOR_DASHBOARD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditorDashboard: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_AUDIT_STATUS_GRAPH]: (state) =>
        immutable(state, {
          getAuditStatusGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_AUDIT_STATUS_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getAuditStatusGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_AUDIT_STATUS_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getAuditStatusGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_AUDIT_TREND_GRAPH]: (state) =>
        immutable(state, {
          getAuditTrendGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_AUDIT_TREND_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getAuditTrendGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_AUDIT_TREND_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getAuditTrendGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_ESG_OVERVIEW_GRAPH]: (state) =>
        immutable(state, {
          getEsgOverviewGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ESG_OVERVIEW_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEsgOverviewGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ESG_OVERVIEW_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEsgOverviewGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_RECORD_STATUS]: (state) =>
        immutable(state, {
          getRecordStatus: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_RECORD_STATUS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getRecordStatus: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_RECORD_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getRecordStatus: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_TICKET_STATUS]: (state) =>
        immutable(state, {
          getTicketStatus: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_TICKET_STATUS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getTicketStatus: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_TICKET_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getTicketStatus: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.FACILITY_DISTRIBUTION]: (state) =>
        immutable(state, {
          facilityDistribution: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.FACILITY_DISTRIBUTION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          facilityDistribution: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.FACILITY_DISTRIBUTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          facilityDistribution: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ROLE_DISTRIBUTION]: (state) =>
        immutable(state, {
          roleDistribution: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ROLE_DISTRIBUTION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          roleDistribution: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ROLE_DISTRIBUTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          roleDistribution: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_ENERGY_MATERIAL]: (state) =>
        immutable(state, {
          getEnergyMaterial: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ENERGY_MATERIAL_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEnergyMaterial: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ENERGY_MATERIAL_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEnergyMaterial: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_FUEL_SOURCES_TARGET]: (state) =>
        immutable(state, {
          getFuelSourcesTarget: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_FUEL_SOURCES_TARGET_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getFuelSourcesTarget: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_FUEL_SOURCES_TARGET_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getFuelSourcesTarget: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_INITIATIVE_TRACKING]: (state) =>
        immutable(state, {
          getInitiativeTracking: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_INITIATIVE_TRACKING_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getInitiativeTracking: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_INITIATIVE_TRACKING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getInitiativeTracking: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_EMPLOYEE_DIVERSITY]: (state) =>
        immutable(state, {
          getEmployeeDiversity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMPLOYEE_DIVERSITY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEmployeeDiversity: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMPLOYEE_DIVERSITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEmployeeDiversity: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_DEVELOPMENT_TRAINING]: (state) =>
        immutable(state, {
          getDevelopmentTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_DEVELOPMENT_TRAINING_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getDevelopmentTraining: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_DEVELOPMENT_TRAINING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getDevelopmentTraining: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_SOCIAL_ENGAGEMENT]: (state) =>
        immutable(state, {
          getSocialEngagement: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SOCIAL_ENGAGEMENT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getSocialEngagement: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SOCIAL_ENGAGEMENT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getSocialEngagement: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_HIRE_RESIGN_GRAPH]: (state) =>
        immutable(state, {
          getHireResignGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_HIRE_RESIGN_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getHireResignGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_HIRE_RESIGN_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getHireResignGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_BOARD_MANAGEMENT_GRAPH]: (state) =>
        immutable(state, {
          getBoardManagementGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_BOARD_MANAGEMENT_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getBoardManagementGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_BOARD_MANAGEMENT_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getBoardManagementGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_EMPLOYEE_TURNOVER_GRAPH]: (state) =>
        immutable(state, {
          getEmployeeTurnoverGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMPLOYEE_TURNOVER_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEmployeeTurnoverGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_EMPLOYEE_TURNOVER_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEmployeeTurnoverGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_ECONOMIC_GRAPH]: (state) =>
        immutable(state, {
          getEconomicGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ECONOMIC_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEconomicGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ECONOMIC_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEconomicGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_ETHICAL_BEHAVIOUR_GRAPH]: (state) =>
        immutable(state, {
          getEthicalBehaviourGraph: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ETHICAL_BEHAVIOUR_GRAPH_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getEthicalBehaviourGraph: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ETHICAL_BEHAVIOUR_GRAPH_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getEthicalBehaviourGraph: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_ANTI_CORRUPTION_TRAINING]: (state) =>
        immutable(state, {
          getAntiCorruptionTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ANTI_CORRUPTION_TRAINING_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getAntiCorruptionTraining: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ANTI_CORRUPTION_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getAntiCorruptionTraining: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_ANTI_CORRUPTION_DISCLOSURE]: (state) =>
        immutable(state, {
          getAntiCorruptionDisclosure: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ANTI_CORRUPTION_DISCLOSURE_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getAntiCorruptionDisclosure: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ANTI_CORRUPTION_DISCLOSURE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getAntiCorruptionDisclosure: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_INITIATIVE_TRACKING_DRILL_DOWN]: (state) =>
        immutable(state, {
          getInitiativeTrackingDrillDown: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_INITIATIVE_TRACKING_DRILL_DOWN_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getInitiativeTrackingDrillDown: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_INITIATIVE_TRACKING_DRILL_DOWN_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getInitiativeTrackingDrillDown: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_ENERGY_MATERIALS_DRILL_DOWN]: (state) =>
        immutable(state, {
          getEnergyMaterialsDrillDown: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ENERGY_MATERIALS_DRILL_DOWN_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getEnergyMaterialsDrillDown: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ENERGY_MATERIALS_DRILL_DOWN_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getEnergyMaterialsDrillDown: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_NOTIFICATIONS]: (state) =>
          immutable(state, {
            getNotifications: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_NOTIFICATIONS_SUCCESS]: (
          state,
          { payload }
        ) =>
          immutable(state, {
            getNotifications: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.GET_NOTIFICATIONS_FAILURE]: (
          state,
          { payload }
        ) =>
          immutable(state, {
            getNotifications: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),
  
      [ActionTypes.CLEAR_DASHBOARD_DATA]: (state, { payload }) =>
        immutable(state, {
          getEmissionYear: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getEmissionTypes: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getEmissionRegion: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getEmissionsByMonth: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getEmissionByScope: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getEmissionByScopeByYear: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getEsgOverview: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getStatistics: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getFacilityTopicEmission: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getFuelSource: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          notificationList: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          markAllRead: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          markAsRead: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getAdminDashboard: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          companyDetails: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          stakeholderDashboard: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          stakeholderEsgOverview: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          stakeholderEmissionByScopeByYear: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          stakeholderEmissionTypeGraph: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getStakeholderEmissionYearData: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          sustainabilityNews: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getAuditStatusGraph: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getAuditTrendGraph: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getEsgOverviewGraph: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getRecordStatus: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          getTicketStatus: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          facilityDistribution: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          roleDistribution: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
        }),
        [ActionTypes.CLEAR_NOTIFICATIONS]: (state, { payload }) =>
          immutable(state, {
            getNotifications: {
              status: { $set: STATUS.IDLE },
              message: { $set: "" },
              data: { $set: [] },
            }
          }),
    },
    dashboardState
  ),
};

export default dashboardActions;
