import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  getEmissionYear,
  getEmissionTypes,
  getEmissionRegion,
  getEmissionsByMonth,
  getDashboardStatistics,
  getFacilityTopicEmission,
  getFuelSourceEmission,
  getEmissionByScope,
  getEmissionsByScopeByYear,
  getEsgOverview,
  listNotifications,
  markAllRead,
  markAsRead,
  getSuperadminDashboard,
  stakeholderCompanyDetails,
  stakeholderDashboard,
  stakeholderEsgOverview,
  stakeholderEmissionByScopeByYear,
  stakeholderEmissionTypeGraph,
  getStakeholderEmissionYearData,
  getNews,
  getAuditorDashboard,
  getAuditStatusGraph,
  getAuditTrendGraph,
  getEsgOverviewGraph,
  getRecordStatus,
  getTicketStatus,
  facilityDistribution,
  roleDistribution,
  clearDashboardData,
  clearNotifications,
  getInitiativeTracking,
  getEnergyMaterial,
  getFuelSourcesTarget,
  getEmployeeDiversity,
  getDevelopmentTraining,
  getSocialEngagement,
  getHireResignGraph,
  getBoardManagementGraph,
  getEmployeeTurnoverGraph,
  getEconomicGraph,
  getEthicalBehaviourGraph,
  getAntiCorruptionTraining,
  getAntiCorruptionDisclosure,
  getInitiativeTrackingDrillDown,
  getEnergyMaterialsDrillDown,
  getNotifications
} = createActions({
  [ActionTypes.GET_EMISSION_YEAR]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_EMISSION_TYPES]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_EMISSION_REGION]: (filter) => ({ filter }),
  [ActionTypes.GET_EMISSIONS_BY_MONTH]: (filter) => ({ filter }),
  [ActionTypes.GET_DASHBOARD_STATISTICS]: (filter) => ({ filter }),
  [ActionTypes.GET_DASHBOARD_STATISTICS]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_FUEL_SOURCE_EMISSION]: (filter) => ({ filter }),
  [ActionTypes.GET_EMISSIONS_BY_SCOPE]: (filter) => ({ filter }),
  [ActionTypes.GET_EMISSIONS_BY_SCOPE_BY_YEAR]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_ESG_OVERVIEW]: (filter) => ({ filter }),
  [ActionTypes.LIST_NOTIFICATIONS]: (payload) => payload,
  [ActionTypes.MARK_ALL_READ]: (payload) => payload,
  [ActionTypes.MARK_AS_READ]: (payload) => payload,
  [ActionTypes.GET_SUPERADMIN_DASHBOARD]: (filter) => ({ filter }),
  [ActionTypes.STAKEHOLDER_COMPANY_DETAILS]: (companyId) => ({ companyId }),
  [ActionTypes.STAKEHOLDER_DASHBOARD]: (companyId) => ({ companyId }),
  [ActionTypes.STAKEHOLDER_ESG_OVERVIEW]: (companyId) => ({ companyId }),
  [ActionTypes.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR]: (companyId) => ({
    companyId,
  }),
  [ActionTypes.STAKEHOLDER_EMISSION_TYPE_GRAPH]: (companyId) => ({ companyId }),
  [ActionTypes.GET_STAKEHOLDER_EMISSION_YEAR_DATA]: (companyId) => ({
    companyId,
  }),
  [ActionTypes.GET_NEWS]: () => {},
  [ActionTypes.GET_AUDITOR_DASHBOARD]: (filter) => ({ filter }),
  [ActionTypes.GET_AUDIT_STATUS_GRAPH]: (role) => ({ role }),
  [ActionTypes.GET_AUDIT_TREND_GRAPH]: (filter, role) => ({ filter, role }),
  [ActionTypes.GET_ESG_OVERVIEW_GRAPH]: (role) => ({ role }),
  [ActionTypes.GET_RECORD_STATUS]: (filter) => ({ filter }),
  [ActionTypes.GET_TICKET_STATUS]: (isAuditor, filter, role) => ({
    isAuditor,
    filter,
    role,
  }),
  [ActionTypes.FACILITY_DISTRIBUTION]: (filter) => ({ filter }),
  [ActionTypes.ROLE_DISTRIBUTION]: (filter) => ({ filter }),
  [ActionTypes.GET_ENERGY_MATERIAL]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_FUEL_SOURCES_TARGET]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.CLEAR_DASHBOARD_DATA]: () => ({}),
  [ActionTypes.CLEAR_NOTIFICATIONS]: () => ({}),
  [ActionTypes.GET_INITIATIVE_TRACKING]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_EMPLOYEE_DIVERSITY]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_DEVELOPMENT_TRAINING]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_SOCIAL_ENGAGEMENT]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_HIRE_RESIGN_GRAPH]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_BOARD_MANAGEMENT_GRAPH]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_EMPLOYEE_TURNOVER_GRAPH]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_ECONOMIC_GRAPH]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_ETHICAL_BEHAVIOUR_GRAPH]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_ANTI_CORRUPTION_TRAINING]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_ANTI_CORRUPTION_DISCLOSURE]: (filter, role, companyId) => ({
    filter,
    role,
    companyId,
  }),
  [ActionTypes.GET_INITIATIVE_TRACKING_DRILL_DOWN]: (filter, role, companyId) => ({ filter, role, companyId }),
  [ActionTypes.GET_ENERGY_MATERIALS_DRILL_DOWN]: (filter, role, companyId) => ({ filter, role, companyId }),
  [ActionTypes.GET_NOTIFICATIONS]: () => ({ }),
});
