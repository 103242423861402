import * as yup from 'yup';

export const schemeValidation = yup.object().shape({
  facility: yup.string().required('Facility is required'),
  year: yup.string().required('Year is required'),
  assessmentPeriod: yup.string().required('Assessment Period is required'),
  significantInfrastructure: yup.string().required('Significant Infrastructure is required'),
  infrastructureDetails: yup.string().when('significantInfrastructure', {
    is: 'Yes',
    then: yup.string().required('Infrastructure Details is required'),
    otherwise: yup.string()
  }),
  investmentLength: yup.string().when('significantInfrastructure', {
    is: 'Yes',
    then: yup.string().required('Length of Investment is required'),
    otherwise: yup.string()
  }),
  natureOfInvestment: yup.string().when('significantInfrastructure', {
    is: 'Yes',
    then: yup.string().required('Nature of Investment is required'),
    otherwise: yup.string()
  }),
  anyDirectImpacts: yup.string().required('Direct impacts information is required'),
  detailsOfDirect: yup.string().when('anyDirectImpacts', {
    is: 'Yes',
    then: yup.string().required('Details of Direct impacts are required'),
    otherwise: yup.string()
  }),
  anyIndirectImpacts: yup.string().required('Indirect impacts information is required'),
  detailsOfIndirect: yup.string().when('anyIndirectImpacts', {
    is: 'Yes',
    then: yup.string().required('Details of Indirect impacts are required'),
    otherwise: yup.string()
  }),
  measuresTaken: yup.string().required('Mitigation measures information is required'),
});
