import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    questionItemContainer: {
        paddingTop: theme.spacing(4),
    },
    questionContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    number:{
        marginRight: theme.spacing(2),
        fontWeight: 500,
    },
    question:{
        fontWeight: 500,
    },
    answerContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    answer:{
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(4),
    },
    link:{
        fontSize: 12,
        color: theme.palette.text.accent,
        marginLeft: theme.spacing(4),
        cursor: 'pointer'
    },
    answerArea: {
        padding: theme.spacing(4,4,4),
        width: '100%',
        borderBottom: `1px solid ${theme.palette.border.gray}`,
        display: 'flex',
        flexDirection: 'column',
    },
    answerAreaContainer: {
        minHeight: 50,
        marginBottom: 10
    },
    buttonContainer: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: 80,
        height: 35,
        fontSize: 14,
        fontWeight: 400,
        borderRadius: 5
    },
    buttonPrimary: {
        textTransform: "capitalize",
        background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    },
    buttonSecondary: {
        textTransform: "capitalize",
        color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Primary.contrastText,
      border:"2px solid"
    },
        marginRight: theme.spacing(5),
    },
    textArea: {
        height: "auto!important",
        width: '50%',
    },
    textAreaScope:{
        height: "auto!important",
        width: '100%',
    },
    textAreaInput : {
        height: "auto!important",
        width: '50%',
        marginLeft: theme.spacing(4),
    },
    inputTitle:{
        marginLeft: theme.spacing(4),
    },
    dropdownContainer: {
        maxWidth: "50%",
    },
    editIcon: {
        fontSize: "large",
        color: theme.palette.Primary.background
    },
    scope:{
        fontSize: 14,
        marginLeft: theme.spacing(3),
    },
    radioContainer:{
        display: "flex",
        flexDirection: "row",
    },
    radio:{
        color: `${theme.palette.Primary.background} !important`,
    "& .MuiButtonBase-root .MuiRadio-root ":{
        color: `${theme.palette.Primary.background} !important`,
    }
    },
    MultiSelectformControl: {
        marginTop: theme.spacing(2),
        width: '50%',
        height: 46,
        "& .MuiFormLabel-root":{
            left:theme.spacing(2),
            top:theme.spacing(1),
            background:'white',
          },
          background:'white',
          "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
            maxWidth: '100%',
            paddingLeft: "140px !important"
        },
          "& .Mui-focused > fieldset > legend": {
              maxWidth: '100%',
              paddingLeft: "140px !important"
          },
      },
      value:{
        paddingTop: theme.spacing(2),
      },
      answerBox:{
        display: "flex", 
        alignItems: "flex-end",
        paddingLeft: theme.spacing(2),
      },
      dropdownText:{
        marginLeft: theme.spacing(5),
      },
      answerScope:{
        borderTop: "1px solid grey", 
        borderBottom: "1px solid grey",
        minHeight: "20px",
        margin: theme.spacing(4,0),
        padding: theme.spacing(3,0),
      },
      MultiSelectform: {
        marginTop: theme.spacing(2),
        width: '50%',
        height: 46,
        "& .MuiFormLabel-root":{
            left:theme.spacing(2),
            top:theme.spacing(1),
            background:'white',
          },
          background:'white',
          "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
            maxWidth: '100%',
            paddingLeft: "140px !important"
        },
          "& .Mui-focused > fieldset > legend": {
              maxWidth: '100%',
              paddingLeft: "140px !important"
          },
      },
      MultiSelectformScope:{
        marginTop: theme.spacing(2),
        width: '100%',
        height: 46,
        "& .MuiFormLabel-root":{
            left:theme.spacing(2),
            top:theme.spacing(1),
            background:'white',
          },
          background:'white',
          "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
            maxWidth: '100%',
            paddingLeft: "140px !important"
        },
          "& .Mui-focused > fieldset > legend": {
              maxWidth: '100%',
              paddingLeft: "140px !important"
          },
      },
      error: {
        color: "red",
        fontSize: 12
      }
}));

export default useStyles;