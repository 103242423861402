import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Link,
} from "@mui/material";
import useStyles from "./styles";
import CeroTable from "../../../components/CeroTable";
import { useNavigate } from "react-router-dom";

const CarbonOffsetTable = ({ data, onSelectRow }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const carbonOffsetColumns = [
    {
      columnKey: "program_name",
      columnId: "program_name",
      columnHeader: "Program Name",
      width: "25vw",
    },
    {
      columnKey: "location",
      columnId: "location",
      columnHeader: "Location",
      width: "25vw",
    },
    {
      columnKey: "program_type",
      columnId: "program_type",
      columnHeader: "Program Type",
      width: "25vw",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Details",
      width: "25vw",
    },
  ];

  const onSelectData = ( id ) => {
    navigate(`/carbon-offset/${id}`)
  }

  const getProgramList = () =>
    data.map((item) => ({
      ...item,
      program_type: item.program_type ? item.program_type : "",
      location: item.location ? item.location : "",
      program_name: item.program_name ? item.program_name : "",
      action: (
        <Link
          className={classes.link}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelectData(item.id);
          }}
        >
          Click here
        </Link>
      ),
    }));

    const noTableRows = () => (
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {carbonOffsetColumns.map((column) => (
                <TableCell key={column.columnId} className={classes.tableHeader}>
                  {column.columnHeader}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={carbonOffsetColumns.length} 
                className={classes.noDataWarning}
                align="center" 
              >
                <Typography align="center">No Entries Found</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
    

  return data?.length > 0 ? (
    <CeroTable
      columns={carbonOffsetColumns}
      data={getProgramList}
      hasMore={false}
      loading={false}
      classes={{tableContainer : classes.tableContainer}}
    />
  ) : (
    noTableRows()
  );
};

export default CarbonOffsetTable;
