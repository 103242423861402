import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, Paper } from '@mui/material';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import useStyles from './styles';

const HireTable = (props) => {
    const classes = useStyles();
    const type = props.type;
  // Define table data and headers
  const noTableRows = () => (
    <TableRow>
      <TableCell className={classes.noDataWarning}>
        <Typography>
          No data
        </Typography>
      </TableCell>
    </TableRow>
  )
  const tableRows = () => (
    props.inputData[type] && Object.keys(props.inputData[type]).map((item, index)=>
    <TableRow 
    className={clsx(
        classes.tableDataRow,
        props.classes?.tableDataRow,
        props.isSelectable && classes.selectableTableRow,
      )}
      onClick={() =>{
        const rowData = props.inputData;
        rowData['distribution'] = type; 
        props.onSelectRow?props.onSelectRow(rowData):console.log(rowData)}
      }
    >
      <TableCell className={clsx(classes.tableBodyCell)}>{item.split('_').join(' ')}</TableCell>
            {props.inputData[type][item] && Object.keys(props.inputData[type][item]).map((sub,subIndex)=>(
                ['hired', 'resigned','turn_over'].includes(sub)?
                <>
                <TableCell
                // colSpan={2}
                align={"center"}
                style={{
                width: 'auto',
                whiteSpace: 'initial', 
                }}
                className={clsx(classes.tableBodyCell)}
                key={subIndex}
                >
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ textAlign: "center" }}>
                    {props.inputData[type][item][sub]['permanent']}
                    {sub === "turn_over" ? "%" : null}
                    </div>
                </div>
                </TableCell>
                <TableCell
                // colSpan={2}
                align={"center"}
                style={{
                width: 'auto',
                whiteSpace: 'initial', 
                }}
                className={clsx(classes.tableBodyCell)}
                key={subIndex}
                >
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ textAlign: "center" }}>
                    {props.inputData[type][item][sub]['temporary']}
                    {sub === "turn_over" ? "%" : null}
                    </div>
                </div>
                </TableCell></>:<></>)
                )}  
    </TableRow>
    )
    
  )
const tableId = `CeroTable-${props.tableId || 'table-id'}`;
  return (
    <InfiniteScroll
      dataLength={[]?.length??0}
      hasMore={!!props.hasMore}
      next={props.loadMore}
      scrollableTarget={tableId}
      loader={<></>}
    >
      
      <TableContainer id={tableId} className={clsx(classes.tableContainer, props.classes?.tableContainer)} component={Paper}>
        <Table stickyHeader id='my-table'>
          <TableHead>
            <TableRow className={clsx(classes?.tableHeader, props.classes?.tableHeader)}>
            <TableCell
      rowSpan={2} 
      className={clsx(classes.headerCell)}
    >
      {type === "gender" ? "Gender" : "Age Group"} {/* Adjust the column heading */}
    </TableCell>
            
                {["Hired","Resigned","Turnover rate"].map((item,index)=>(
                <TableCell
                colSpan={2}
                align={"center"}
                style={{
                width: 'auto',
                whiteSpace: 'initial', 
                }}
                className={clsx(classes.headerCell)}
                key={index}
                >
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ textAlign: "center" }}>
                    {item}
                    </div>
                </div>
                </TableCell>)
                )}
            </TableRow>
            <TableRow className={clsx(classes?.tableHeader, props.classes?.tableHeader)}>
            {/* <TableCell
                align={"center"}
                style={{
                width: 'auto',
                whiteSpace: 'initial', 
                }}
                className={clsx(classes.headerCell)}
                key={1}
                >
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ textAlign: "center" }}>
                    {type==="gender"?"Gender":"Age Group"}
                    </div>
                </div>
                </TableCell> */}
            {["Permanent","Temporary","Permanent","Temporary","Permanent","Temporary"].map((item,index)=>(
                <TableCell
                // colSpan={2}
                align={"center"}
                style={{
                width: 'auto',
                whiteSpace: 'initial', 
                }}
                className={clsx(classes.headerCell)}
                key={index}
                >
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ textAlign: "center" }}>
                    {item}
                    </div>
                </div>
                </TableCell>)
                )}  
            </TableRow>
          </TableHead>
          <TableBody className={props?.classes?.tableBody}>
            {tableRows()}
            {!tableRows()?.length && noTableRows()}
          </TableBody>
          {props.loading && (
            <TableFooter className={classes.tableFooter}>
              <TableRow>
                <TableCell variant="footer" colSpan={props.columns.length} className={classes.footerCell}>
                  <CircularProgress thickness={3} size={20} className={classes.progress} />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
  ;
};

export default HireTable;