import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Container, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import CeroButton from "../../../../components/CeroButton";
import { submitApproval, resetApprovalData, requestApproval, approveRequest } from "../../../../redux/actions";
import { STATUS } from "../../../../redux/constants";
import TicketIcon from "../../../../assets/icons/TicketIcon.png"
import CreateTicketDrawer from "../../../common/CreateTicketDrawer";
import ListTicketDrawer from "../../../common/ListTicketsDrawer";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

const Header = ({
  approveId,
  selectedYear,
  selectedMonth,
  selectedFacility,
  actions,
  statusId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const submitApprovalStatus = useSelector(
    (state) => state.approval.submitApproval.status
  );

  const submitApprovalError = useSelector(
    (state) => state.approval.submitApproval.message
  );

  const requestApprovalStatus = useSelector(
    (state) => state.approval.requestApproval.status
  );

  const requestApprovalError = useSelector(
    (state) => state.approval.requestApproval.message
  );

  const approveRequestStatus = useSelector(
    (state) => state.approval.approveRequest.status
  );

  const approveRequestError = useSelector(
    (state) => state.approval.approveRequest.message
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showTickets, setShowTickets] = useState(false);

  const isSubmitLoading = submitApprovalStatus === STATUS.RUNNING;
  const isRequestApprovalLoading = requestApprovalStatus === STATUS.RUNNING;
  const isApproveRequestLoading = approveRequestStatus === STATUS.RUNNING;

  const onSubmitApproval = () => {
    dispatch(
      submitApproval(
        statusId,
        actions.next_assignee_id,
      )
    );
  };

  const onRequestApproval = () => {
    dispatch(
      requestApproval(
        statusId,
        actions.next_assignee_id,
      )
    );
  };

  const onApprovingRequest = () => {
    dispatch(
      approveRequest(
        statusId,
        'Approved',
      )
    );
  };

  const onclickShowTickets = () => {
    setShowTickets(true);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  useEffect(() => {
    if (submitApprovalStatus === STATUS.SUCCESS) {
      dispatch(resetApprovalData());
      navigate(-1)
      enqueueSnackbar("Successfully submitted for approval", {
        variant: "success",
      });
    } else if (submitApprovalStatus === STATUS.ERROR) {
      dispatch(resetApprovalData());
      enqueueSnackbar(submitApprovalError.message || "We couldn't process your request. Please try again later.", { variant: "error" });
    }
  }, [submitApprovalStatus, dispatch, enqueueSnackbar, submitApprovalError.message]);

  useEffect(() => {
    if (requestApprovalStatus === STATUS.SUCCESS) {
      dispatch(resetApprovalData());
      navigate(-1)
      enqueueSnackbar("Successfully requested for approval", {
        variant: "success",
      });
    } else if (requestApprovalStatus === STATUS.ERROR) {
      dispatch(resetApprovalData());
      enqueueSnackbar(requestApprovalError.message || "We couldn't process your request. Please try again later.", { variant: "error" });
    }
  }, [requestApprovalStatus, dispatch, enqueueSnackbar, requestApprovalError.message]);

  useEffect(() => {
    if (approveRequestStatus === STATUS.SUCCESS) {
      dispatch(resetApprovalData());
      navigate(-1)
      enqueueSnackbar("Successfully requested for approval", {
        variant: "success",
      });
    } else if (approveRequestStatus === STATUS.ERROR) {
      dispatch(resetApprovalData());
      enqueueSnackbar(approveRequestError.message || "We couldn't process your request. Please try again later.", { variant: "error" });
    }
  }, [approveRequestError.message, approveRequestStatus, dispatch, enqueueSnackbar]);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Container className={classes.headerContainer}>
      <div className={classes.gridContainer}>
       <div className={classes.backContainer} onClick={navigateBack}>
        <ArrowBackIosIcon />
         Back
      </div>
      <div className={classes.buttonsContainer}>
      {actions && actions.perform_submission && (
            <CeroButton
              buttonText={isSubmitLoading ? "Submitting..." : "Submit"}
              className={classes.buttonPrimary}
              onClick={onSubmitApproval}
              disabled={isSubmitLoading}
            />
          )}
            {actions && actions.perform_approval && (
            <CeroButton
              buttonText={isApproveRequestLoading ? "Approving..." : "Approve"}
              className={classes.buttonPrimary}
              onClick={onApprovingRequest}
              disabled={isApproveRequestLoading}
            />
          )}
          {actions && actions.perform_request_approval && (
            <CeroButton
              buttonText={isRequestApprovalLoading ? "Requesting..." : "Request Approval"}
              className={clsx(classes.buttonPrimary, classes.requestApproval)}
              onClick={onRequestApproval}
              disabled={isRequestApprovalLoading}
            />
           )}
      {/* <CeroButton
      buttonText="Raise a ticket"
      className={classes.buttonPrimary}
      onClick={() => setIsDrawerOpen(true)}
     />
    <IconButton onClick={onclickShowTickets}>
      <img src={TicketIcon} alt="Logo" width="25" height="25" />
    </IconButton> */}
   </div>
   </div>
        {showTickets && <ListTicketDrawer isOpen={showTickets} scope="approval" scopeId={approveId} onClose={onCloseTickets} />}
        <CreateTicketDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} scope="approval" scopeId={statusId}/>
    </Container>
  );
};

export default Header;
