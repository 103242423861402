import * as yup from "yup";

const phoneRegExp = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;

export const accountSchema = yup.object({
 username: yup
    .string()
    .min(2, "Name length should be more than 2")
    .required("User name is required"),
  company: yup.string().required("Company name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  userType: yup.string().required("User type is required"),
});
