import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    title:{
        cursor: 'pointer',
        color: "#6A6969",
        maxHeight: 24,
        fontSize: 13,
    },
    value:{
        fontWeight:600,
        cursor: 'pointer',
        paddingTop: theme.spacing(2),
        '@media (max-width: 1260px)': {
            paddingTop: `${theme.spacing(5)} !important`
          },
    },
    cardContent:{
        height: "100%",
        margin: "auto",
        display: "flex",
        alignItems: "center",
    },
    card:{
        height: "95%",
        borderRadius: 8,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    }
}));

export default useStyles;
