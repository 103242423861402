import { Typography, Box, Chip, Grid } from "@mui/material";
import clsx from "clsx";

import useStyles from "./styles";

const CeroInfoPair = (props) => {
    const classes = useStyles();
    const getStatus = (status) => {
        if (status === "open") {
            return <Chip label={"Open"} className={classes.openBtn} />;
        } else if (status === "closed") {
            return (
                <Chip
                    label={"Closed"}
                    color="success"
                    className={classes.closedBtn}
                />
            );
        } else {
            return <Chip label={status} color="info" variant="outlined" />;
        }
    };
    return (<>
        <Grid container  >
            <Grid item xs={6}>
                <Box className={clsx(classes.container, props.classes?.container)} >
                    <Typography className={clsx(classes.title, props.classes?.title)} >{props.title}</Typography>
                    {Array.isArray(props.value) ? (
                        <Box>
                            {props.value.map((item, index) => (
                            <Typography
                                key={index}
                                className={clsx(classes.value, props.classes?.value)}
                            >
                                {item === 0 || item ? item : '-'}
                            </Typography>
                            ))}
                        </Box>
                        ) : (
                        <Typography className={clsx(classes.value, props.classes?.value)}>
                            {props.value === 0 || props.value ? props.value : '-'}
                        </Typography>
                        )}
                </Box>

            </Grid>
            {/* <Grid item xs={6} sx={{ alignSelf: "center" }}>
                {props.ticketDetails &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {getStatus(props.ticketDetails.status)}
                    </Box>
                }
            </Grid> */}
        </Grid>
    </>
    );
};

export default CeroInfoPair;
