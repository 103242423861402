import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: 500,
    whiteSpace: "break-spaces",
  },

  menuIcon: {
    color: theme.palette.Primary.background,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    color: theme.palette.Primary.light,
    borderColor: theme.palette.Primary.background,

    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
      borderColor: theme.palette.Primary.background,
    },
    marginBottom: 20,
  },
  menuItemText: {},
}));

export default useStyles;
