import React, { useEffect, useRef } from 'react';
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const EsgOverviewChart = ({data}) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
  
    useEffect(() => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      const myChartRef = chartRef.current.getContext("2d");
  
      chartInstance.current = new Chart(myChartRef, {
        type: "doughnut",
        data: {
          labels:data?.map((entry) => capitalizeFirstLetter(entry.category_type)),
          datasets: [
            {
              data: data?.map((entry) => entry.total_records),
              backgroundColor: [
                  "#846BB5",
                  "#6FBFBA",
                  "#FBCE74",
              ],
            },
          ],
        },
        options: {
            plugins: {
              legend: {
                display: true,
                position: "right",
                labels: {
                  usePointStyle: true,
                  color: 'black',
                  padding: 20
                },
              },
              datalabels: {
                color: "white",
                font:{
                  weight:"bold",
                  size:12
                },
                formatter: (value, context) => {
                    return value.toFixed(0);

                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: 2, // Adjust the aspect ratio as needed
            layout: {
              padding: {
                bottom: 20, // Adjust the bottom padding to increase the gap
              },
            },
          },
          plugins: [ChartDataLabels],
      });
  
      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }, [data]);

    return (
           <canvas ref={chartRef}/>
    )
}

export default EsgOverviewChart;