import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'start', 
      gap: '8px',
      width: 'auto'
    },
    title: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.palette.text.primary,
        whiteSpace: "nowrap",
        padding: theme.spacing(1, 0),
    },
    value: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.palette.text.primary,
        textTransform: "capitalize",
        padding: theme.spacing(1, 0),
    },    
}));

export default useStyles;