import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  detailsContainer: {
    padding: theme.spacing(6),
    background: theme.palette.background.white,
    borderRadius: 8
  },
  ticketInnerContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(6),
    minHeight:"5rem"
  },
  innerContainerWithFooter: {
    height: "calc(100% - 50px)",
  },
  ownerName: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
  },
  commentBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  textArea: {
    height: "auto!important",
    background: "white !important"
  },
  commentButton: {
    alignSelf: "flex-end",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 140,
    height: 37,
    fontWeight: 400
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Primary.contrastText,
      border:"2px solid"
    },
    width: 140,
    height: 37,
    fontSize: 13,
  },
  secondaryMargin: {
    margin: theme.spacing(0, 5),
  },
  ticketFooter: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(9),
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: 'center',
    cursor: "pointer",
    paddingBottom: 10
  },
  comment:{
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  }
}));

export default useStyles;
