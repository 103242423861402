import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 'calc(100vh - 144px)',
    },
    button: {
        background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.background,
            color: theme.palette.Primary.light,
          },
        marginRight: theme.spacing(2),
        borderRadius:10
    },
    actions:{
        display: "flex",
        justifyContent: "space-evenly",
        color: theme.palette.icon.blue,
    },
    linkButton: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        // color: 'inherit',
        cursor: 'pointer',
        fontWeight: 400,
        color:"#8C2148"
      },
      icon: {
        marginRight: '5px',
        padding: 4,
        color:"#8C2148"
      },
}));

export default useStyles;
