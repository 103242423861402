import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import { Container } from '@mui/material';
import DashboardHeader from '../DashboardHeader';
import Statistics from '../Statistics';
import { getCookie } from '../../../services/cookie';
import useStyles from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { getEnergyMaterialsDrillDown, getInitiativeTrackingDrillDown } from '../../../redux/actions';
import InitiativeDetailsGraph from '../InitiativeTrackingGraph/InitiativeDetailsGraph';
import { useParams } from 'react-router-dom';
import EMCDetailsGraphs from '../EnergyMaterialConsumption/EMCDetailsGraphs';

function DrilledDashboard() {
      const classes = useStyles();
      const dispatch = useDispatch();
      const { type } = useParams();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const [filter, setFilter] = useState({ duration: "last_five_years" });
  const [value, setValue] = useState(0);

  const onApplyFilter = (filter) => {
    setFilter(filter);
  };

   const initiativeDrilledDownData = useSelector(
     (state) => state.dashboard.getInitiativeTrackingDrillDown.data
   );

    const energyMaterialsDrilledDownData = useSelector(
      (state) => state.dashboard.getEnergyMaterialsDrillDown.data
    );

  useEffect(() => {
    if (type === "initiatives") {
      dispatch(getInitiativeTrackingDrillDown(filter,role,companyId));
    } else dispatch(getEnergyMaterialsDrillDown(filter,role,companyId));
  }, [filter, type, dispatch]);


  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {/* {role === "sustainability_manager" ? <News /> : ""} */}
        <DashboardHeader
          onApplyFilter={onApplyFilter}
          value={value}
          setValue={setValue}
        />
        {type === "initiatives" ? (
          <div>
            {initiativeDrilledDownData.map((item) => (
              <InitiativeDetailsGraph key={item.id} data={item} />
            ))}
          </div>
        ) : (
          <EMCDetailsGraphs data={energyMaterialsDrilledDownData} />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default DrilledDashboard