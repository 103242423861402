import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box, Tabs, Tab, IconButton } from "@mui/material";
import { getMonth } from '../../../../../services/utilityService';
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const WaterConsumptionDetails = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { emissionId, emissionData, onCancel, isDeleteEnable, setIsDrawerOpen, isAuditor, company, isAdmin, isSusManager } = props


    return (
        <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Box className={classes.header}>
                    <Typography variant="h6" component="div" >Water Consumptions</Typography>
                </Box>
                <Box className={classes.topContainer}>
                    <Grid container direction={'row'} wrap='nowrap' justifyContent={'space-between'} spacing={8}>
                        <Grid item container direction={'column'} xs={12}>
                            <Typography className={classes.previewItem}>Facility: {emissionData.facility_name}</Typography>
                            <Typography className={classes.previewItem}>Month and year: {getMonth[emissionData.month]} {emissionData.year}</Typography>
                            <Typography className={classes.previewItem}>Source: {emissionData.water_source_name}</Typography>
                            <Typography className={classes.previewItem}>Source type: {emissionData.water_source_type_name}</Typography>
                            <Typography className={classes.previewItem}>Stress type: {emissionData.water_source_stress_type_name}</Typography>
                            <Typography className={classes.previewItem}>Amount: {emissionData.amount}{emissionData.unit}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.bottomContainer}>
                    <Typography variant="h6" component="h6" className={classes.previewTitle}>Emission</Typography>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                        <Grid item container direction='column' xs={6}>
                            <Typography className={classes.previewItem}>Usage: {emissionData.usage} {emissionData.usage_unit}</Typography>
                            <Typography className={classes.previewItem}>{<>CO<sub>2</sub>e</>}: {emissionData.co2e} {emissionData.emission_unit}</Typography> 
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
        </>
    )
}

export default WaterConsumptionDetails;
