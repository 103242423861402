import * as yup from 'yup';

export const addPerformance = yup.object({
  facility: yup.string().required('Facility is required'),
  year: yup.string().required('Year is required'),
  assessmentPeriod: yup.string().required('Assessment period is required'),
  programType: yup.string().required('Program type is required'),
  programScope: yup.string().required('Program scope is required'),
  transitionAssistance: yup.string().required('Transition assistance is required'),
  employeesParticipated: yup.number().required('Number of employees participated is required')
    .integer('Number of employees participated must be an integer'),
  trainingMethods: yup.string().required('Training methods is required')
});
