import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

export function* listCarbonOffsetPrograms({ payload }) {
  try {
    let apiUrl = APIEndpoints.LIST_CARBON_OFFSET_PROGRAMS(payload.location, payload.programType)
    const response = yield call(
      request, apiUrl,
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.LIST_CARBON_OFFSET_PROGRAMS_SUCCESS,
      payload: response.offset_program,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_CARBON_OFFSET_PROGRAMS_FAILURE,
      payload: err.message,
    });
  }
}

export function* addCarbonOffset({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.ADD_CARBON_OFFSET, {
      method: "POST",
      payload : payload.requestData
    });
    yield put({
      type: ActionTypes.ADD_CARBON_OFFSET_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_CARBON_OFFSET_FAILURE,
      payload: err,
    });
  }
}

export function* getProgramTypes() {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_PROGRAM_TYPES,
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_PROGRAM_TYPES_SUCCESS,
      payload: response.program_types,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PROGRAM_TYPES_FAILURE,
      payload: err.message,
    });
  }
}

export function* getPrograms({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_PROGRAMS(payload.programId),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_PROGRAMS_SUCCESS,
      payload: response.programs,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PROGRAMS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getoffsetDetails({ payload }) {
  try {
    const response = yield call(
      request, APIEndpoints.GET_OFFSET_DETAILS(payload.programId),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_OFFSET_DETAILS_SUCCESS,
      payload: response.offset_program,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_OFFSET_DETAILS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getIotData({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_IOT_DATA(payload.programId, payload.year),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_IOT_DATA_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_IOT_DATA_FAILURE,
      payload: err.message,
    });
  }
}

export function* updateIotData({payload}) {
  try {
    const response = yield call(request, APIEndpoints.UPDATE_IOT_DATA(payload.dataId), {
      method: 'PUT',
      payload: payload.requestData
    })
    yield put({
      type: ActionTypes.UPDATE_IOT_DATA_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_IOT_DATA_FAILURE,
      payload: err.message
    })
  }
}

export function* refreshIotData({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.REFRESH_IOT_DATA(payload.programId),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.REFRESH_IOT_DATA_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.REFRESH_IOT_DATA_FAILURE,
      payload: err.message,
    });
  }
}

export function* getOffsetCountries() {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_OFFSET_COUNTRIES,
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_OFFSET_COUNTRIES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_OFFSET_COUNTRIES_FAILURE,
      payload: err.message,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.LIST_CARBON_OFFSET_PROGRAMS,listCarbonOffsetPrograms),
    takeLatest(ActionTypes.ADD_CARBON_OFFSET,addCarbonOffset),
    takeLatest(ActionTypes.GET_PROGRAM_TYPES,getProgramTypes),
    takeLatest(ActionTypes.GET_PROGRAMS,getPrograms),
    takeLatest(ActionTypes.GET_OFFSET_DETAILS,getoffsetDetails),
    takeLatest(ActionTypes.GET_IOT_DATA,getIotData),
    takeLatest(ActionTypes.UPDATE_IOT_DATA,updateIotData),
    takeLatest(ActionTypes.REFRESH_IOT_DATA,refreshIotData),
    takeLatest(ActionTypes.GET_OFFSET_COUNTRIES,getOffsetCountries),
  ]);
}
