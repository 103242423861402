import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from "@mui/material"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables as registerablesJS
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import { getEmissionYear } from '../../../redux/actions';

import useStyles from './styles'
import { STATUS } from '../../../redux/constants';
import { getCookie } from '../../../services/cookie';

ChartJS.register(...registerablesJS)
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const TotalEmissionChart = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const role = getCookie('role');
    const companyId = getCookie("companyId");

    const emissionData = useSelector(state => state.dashboard.getEmissionYear);

    useEffect(() => {
        dispatch(getEmissionYear(props.filter,role,companyId));
    }, [dispatch, props.filter]);

    const formatAxisValue = (value) => {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + 'M';
        } else if (value >= 1000) {
            return (value / 1000).toFixed(0) + 'K';
        }
        return value;
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    fontColor: 'rgba(0,0,0,.4)',
                },
                align: 'end',
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.raw
                    return label;
                  },
                },
              },
            title: {
                align: 'start',
                display: true,
                text: 'Total CO2e emissions',
                color: 'black',
                padding: {
                    bottom: 20,
                },
                font: {
                    size: 18,
                    family: 'Inter',
                    weight: '400',
                },
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'CO2e Emitted',
                    color: '#4F4F4F',
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: '400',
                    },
                },
                ticks:{
                    color: "black",
                    // callback: formatAxisValue,z
                }
            },
            x:{
                ticks:{
                    color: "black"
                }
            }
        },
    };
    

    const data = {
      labels: _.map(emissionData.data, (item) => item.year),
      datasets: [
        {
          label: "Total Emission(tonnes)",
          data: _.map(emissionData.data, (item) => item.total_co2e),
          color: "black",
          borderColor: "#846BB5",
          backgroundColor: "#6FBFBA",
        },
      ],
    };
    


    return (
        <Paper className={classes.container} >
            {emissionData.status === STATUS.SUCCESS ? (
                emissionData?.data?.length> 0 ?
                <Line options={options} data={data} height={360} width={560} />
                : "Graph view only supports filtering data based on the year" ) :
                "Data loading..."
            }
        </Paper >
    )
}

export default TotalEmissionChart