import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.white,
    height: "fit-content",
    width: "95%",
    borderRadius: 14,
    padding: theme.spacing(5, 7),
    paddingBottom: 0,
    position: "relative",
  },
  innerContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.darkGray,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.background.darkGray,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    paddingTop: 50,
    paddingBottom: 20
  },
  button: {
    width: "fit-content",
    height: 42,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: "auto",
    height: 40,
    marginRight: 15,
    fontWeight: 400,
    padding: theme.spacing(0,5),
    "&:hover": {
        backgroundColor: theme.palette.Primary.hoverButton,
        color: theme.palette.Primary.light,
      },
},
buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: "auto",
    height: 40,
    padding: theme.spacing(0,5),
    "&:hover": {
        borderColor: theme.palette.Primary.background,
        backgroundColor:theme.palette.Secondary.hoverButton,
      },
    marginRight: theme.spacing(5),
},
  topContainer: {
    maxWidth: 700,
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 35,
    paddingTop: 10,
    alignItems: 'center',
    cursor: "pointer"
  },
  MultiSelectformControl: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: 60,
    "& .MuiFormLabel-root":{
      left:theme.spacing(2),
      top:theme.spacing(1),
      background:'white',
    },
    background:'white',
    "& .MuiInputBase-formControl .justCheckSelected > fieldset > legend": {
      maxWidth: '100%',
      paddingLeft: "135px !important"
  },
    "& .Mui-focused > fieldset > legend": {
        maxWidth: '100%',
        paddingLeft: "135px !important"
      },
      "& > fieldset > legend":{
        maxWidth: '100%',
        paddingLeft: "135px !important"
      }
    
  },
  justCheckSelected:{
    width:'100%',
    maxHeight:'100%',
    "& .MuiOutlinedInput-notchedOutline":{
        borderColor: `${theme.palette.Primary.main} !important`
      },
    "& .Mui-focused > fieldset > legend":{
      maxWidth: '100%',
      paddingLeft: "90px !important"
    }
  },
  MultiSelectformControlsocial:{
    marginTop: theme.spacing(2),
    width: '100%',
    height: 60,
    "& .MuiFormLabel-root":{
      left:theme.spacing(2),
      top:theme.spacing(1),
      background:'white',
    },
    background:'white',
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: '100%',
      paddingLeft: "90px !important"
  },
    "& .Mui-focused > fieldset > legend": {
        maxWidth: '100%',
        paddingLeft: "90px !important"
      },
      "& > fieldset > legend":{
        maxWidth: '100%',
        paddingLeft: "90px !important"
      }
  },
  justCheckSelectedSocial:{
    width:'100%',
    maxHeight:'100%',
    "& .MuiOutlinedInput-notchedOutline":{
        borderColor: `${theme.palette.Primary.main} !important`
      },
    "& .Mui-focused > fieldset > legend":{
      maxWidth: '100%',
      paddingLeft: "90px !important"
    }
  },
  textAreaContainer: {
    height: 'fit-content',
},
}));

export default useStyles;
