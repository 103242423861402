import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.text.primary,
        "&:hover .MuiIconButton-root .MuiButton-outlined": {
          borderColor: theme.palette.primary.main,
          background: theme.palette.primary.background,
        },
      },
      dialog: {
        width: "auto",
        paddingLeft: 40,
        paddingRight: 30,
        maxWidth: 1200,
        paddingBottom: 30,
        margin: "auto",
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        "& .MuiDialog-container":{
          display : "contents", 
        },
        "& .MuiPaper-root.MuiDialog-paper": {
          maxWidth: "none",
          backgroundColor: theme.palette.Primary.light

        },
      },
}));

export default useStyles;
