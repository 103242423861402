import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 8,
    margin: theme.spacing(5, 0),
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    }
  },
  ticket: {
    color: theme.palette.text.accent,
  },
  infoContainer: {
    maxWidth: 400,
    display:"flex !important",
    justifyContent: 'flex-start',
    height: "auto",
    paddingBottom: 15,
    "& .MuiTypography-root": {
      minWidth: 140,
    },
    "& .MuiBox-root > .MuiTypography-root": {
      paddingBottom: 4,
    },
  },
  title:{
    width:"50%"
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  assigned: {
    color: theme.palette.Success.warning,
    fontWeight:500,
    fontSize: 14
  },
  Inprogress:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  audited: {
    color: "#024FE4",
    fontWeight:500,
    fontSize: 14
  },
  review: {
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  },
  reported: {
    color: theme.palette.Error.main,
    fontWeight:500,
    fontSize: 14
  },
  submitted:{
    color: theme.palette.status.open,
    fontWeight:500,
    fontSize: 14
  },
  added:{
    color: "#7B7B7B",
    fontWeight:500,
    fontSize: 14
  },
  pending:{
    color: "#F7931D",
    fontWeight:500,
    fontSize: 14
  },
  'audit pending':{
    color: "#F7931D",
    fontWeight:500,
    fontSize: 14
  },
  default:{
    color: "black",
    fontWeight:500,
    fontSize: 14
  },
  statusContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "15%",
    marginLeft: "2%",
  },
}));

export default useStyles;
