import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6),
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
        borderRadius: theme.spacing(2)
    },
    buttonPrimary: {
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        height: 35,
        borderColor: theme.palette.Primary.background,
        marginRight: 15,
        fontWeight: 400,
        color: theme.palette.background.white,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            borderColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    },

}));

export default useStyles;
