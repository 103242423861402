import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CeroButton from "../../../components/CeroButton";
import DashboardLayout from "../../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroItemPair from "../../../components/CeroItemPair";
import {
  clearSubscriptionStatus,
  createSubscriptionPlan,
  getSubscriptionDetails,
  getSubscriptionDetailsSuperadmin,
} from "../../../redux/actions";
import Cookies from "js-cookie";
import { getCookie } from "../../../services/cookie";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import MenuItems from "./MenuItems";
import { firstPlan, secondPlan } from "./MenuItems/items";
import CeroPopUp from "../../../components/CeroPopUp";
import CeroRadioButton from "../../../components/CeroRadioButton";
import AddButton from "./AdditionalFeatures";
import { STATUS } from "../../../redux/constants";

const SubscriptionPlans = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = queryParams.get("company_id");
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [planId, setPlanId] = useState("");
  const [selectedValue, setSelectedValue] = useState("Quarterly");
  const [auditorsBundleBasic, setAuditorsBundleBasic] = useState(0);
  const [facilityWithTwoUsersBasic, setFacilityWithTwoUsersBasic] = useState(0);
  const [additionalCountryBasic, setAdditionalCountryBasic] = useState(0);
  const [iot25DeviceConnectionsBasic, setIot25DeviceConnectionsBasic] =
    useState(0);

  const [auditorsBundlePremium, setAuditorsBundlePremium] = useState(0);
  const [facilityWithTwoUsersPremium, setFacilityWithTwoUsersPremium] =
    useState(0);
  const [additionalCountryPremium, setAdditionalCountryPremium] = useState(0);
  const [iot25DeviceConnectionsPremium, setIot25DeviceConnectionsPremium] =
    useState(0);

  const planDetails = useSelector(
    (state) =>
      state.superAdmin.getSubscriptionDetailsSuperadmin.data.subscription_list
  );
  const createSubscriptionPlanStatus = useSelector(
    (state) => state.superAdmin.createSubscriptionPlan
  );

  const calculatedAmountQuarterly =
    planId === "664337227f2ab3587f6ccd4b" ? 1500 * 3 : 2000 * 3;
  const calculatedAmountYearly =
    planId === "664337227f2ab3587f6ccd4b" ? 1500 * 12 : 2000 * 12;

  const selectedPlanPrice =
    selectedValue === "Quarterly"
      ? calculatedAmountQuarterly
      : calculatedAmountYearly;

  const totalAmount =
    (planId === "664337227f2ab3587f6ccd4b"
      ? iot25DeviceConnectionsBasic *
        Number(planDetails && planDetails[0]?.additional_iot_devices_amount)
      : iot25DeviceConnectionsPremium *
        Number(planDetails && planDetails[1]?.additional_iot_devices_amount)) +
    (planId === "664337227f2ab3587f6ccd4b"
      ? additionalCountryBasic *
        Number(planDetails && planDetails[0]?.additional_country_amount)
      : additionalCountryPremium *
        Number(planDetails && planDetails[1]?.additional_country_amount)) +
    (planId === "664337227f2ab3587f6ccd4b"
      ? facilityWithTwoUsersBasic *
        Number(planDetails && planDetails[0]?.additional_facility)
      : facilityWithTwoUsersPremium *
        Number(planDetails && planDetails[1]?.additional_facility)) +
    (planId === "664337227f2ab3587f6ccd4b"
      ? auditorsBundleBasic *
        Number(planDetails && planDetails[0]?.additional_two_auditor_amount)
      : auditorsBundlePremium *
        Number(planDetails && planDetails[1]?.additional_two_auditor_amount)) +
    selectedPlanPrice;

  console.log(
    "iot25DeviceConnectionsBasic",
    iot25DeviceConnectionsBasic,
    additionalCountryBasic,
    totalAmount
  );

  const handleRadioChange = (newValue) => {
    setSelectedValue(newValue);
  };

  const handleSubscribe = () => {
    const data = {
      additional_two_auditor:
        planId === "664337227f2ab3587f6ccd4b"
          ? auditorsBundleBasic
          : auditorsBundlePremium,
      additional_facility:
        planId === "664337227f2ab3587f6ccd4b"
          ? facilityWithTwoUsersBasic
          : facilityWithTwoUsersPremium,
      additional_country_amount:
        planId === "664337227f2ab3587f6ccd4b"
          ? additionalCountryBasic
          : additionalCountryPremium,
      additional_iot_devices_amount:
        planId === "664337227f2ab3587f6ccd4b"
          ? iot25DeviceConnectionsBasic
          : iot25DeviceConnectionsPremium,
    };
    dispatch(
      createSubscriptionPlan(
        companyId,
        selectedValue.toLowerCase(),
        planId,
        data
      )
    );
  };

  useEffect(() => {
    if (createSubscriptionPlanStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar(createSubscriptionPlanStatus.data.message, {
        variant: "success",
      });
      dispatch(clearSubscriptionStatus());
      setOpenInvoice(false);
      navigate(`/companies/${companyId}/company-details`);
    } else if (createSubscriptionPlanStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        createSubscriptionPlanStatus.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      setOpenInvoice(false);
      dispatch(clearSubscriptionStatus());
    }
  }, [dispatch, enqueueSnackbar, createSubscriptionPlanStatus]);

  useEffect(() => {
    dispatch(getSubscriptionDetailsSuperadmin());
  }, []);

  const getApproveConfirm = () => {
    return (
      <Box className={classes.popUp}>
        <CeroRadioButton
          label="Choose Your Subscription Plan"
          row={true}
          labels={["Quarterly", "Yearly"]}
          default="Quarterly"
          value={handleRadioChange}
        />
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setOpen(false);
              setOpenInvoice(true);
              // handleClick();
            }}
            buttonText="Confirm"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setOpen(false)}
            buttonText="Cancel"
          />
        </div>
      </Box>
    );
  };

  const getPlanTotal = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" align="left" style={{ fontWeight: 500 }}>
          Amount Details
        </Typography>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <CeroItemPair
            title={`${selectedValue} Plan amount`}
            value={
              selectedValue === "Quarterly"
                ? calculatedAmountQuarterly
                : calculatedAmountYearly
            }
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title={`Additional Country (${
              planId === "664337227f2ab3587f6ccd4b"
                ? additionalCountryBasic
                : additionalCountryPremium
            })`}
            value={`${
              planId === "664337227f2ab3587f6ccd4b"
                ? additionalCountryBasic *
                  (planDetails && planDetails[0]?.additional_country_amount)
                : additionalCountryPremium *
                  (planDetails && planDetails[1]?.additional_country_amount)
            }`}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="25 IOT Device Connections"
            value={`${
              planId === "664337227f2ab3587f6ccd4b"
                ? iot25DeviceConnectionsBasic *
                  (planDetails && planDetails[0]?.additional_iot_devices_amount)
                : iot25DeviceConnectionsPremium *
                  (planDetails && planDetails[1]?.additional_iot_devices_amount)
            }`}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Additional Facility"
            value={`${
              planId === "664337227f2ab3587f6ccd4b"
                ? facilityWithTwoUsersBasic *
                  Number(planDetails && planDetails[0]?.additional_facility)
                : facilityWithTwoUsersPremium *
                  Number(planDetails && planDetails[1]?.additional_facility)
            }`}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Additional Auditor"
            value={`${
              planId === "664337227f2ab3587f6ccd4b"
                ? auditorsBundleBasic *
                  Number(
                    planDetails && planDetails[0]?.additional_two_auditor_amount
                  )
                : auditorsBundlePremium *
                  Number(
                    planDetails && planDetails[1]?.additional_two_auditor_amount
                  )
            }`}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Total"
            value={`$ ${totalAmount}`}
            classes={{
              container: classes.infoContainer,
              title: classes.titleTotal,
              value: classes.valueTotal,
            }}
          />
        </Box>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              handleSubscribe();
            }}
            buttonText="Subscribe Now"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setOpenInvoice(false)}
            buttonText="Cancel"
          />
        </div>
      </Box>
    );
  };

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container component="main" maxWidth="md">
        <Typography
          component="h1"
          variant="h5"
          className={classes.pageTitle}
          align="center"
        >
          Pricing Plans
        </Typography>
        <Typography align="center" mt={4} fontSize={16}>
          Select a Perfect Plan for a Greener Tomorrow:Choose Your
          Carbon-neutral
        </Typography>
        <Typography align="center" mt={2} fontSize={16}>
          Subscription and make a Difference today!
        </Typography>
        <Grid container columnSpacing={8} mt={4}>
          <Grid item xs={6}>
            <Paper
              variant="outlined"
              className={classes.container}
              display="flex"
              flexDirection="column"
              align="center"
            >
              <Typography
                component="h1"
                variant="h5"
                className={classes.planTitle}
                align="center"
              >
                {planDetails && planDetails[0]?.subscription_name}
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                className={classes.pageTitle}
                align="center"
              >
                US ${planDetails && planDetails[0]?.amount} /mo
              </Typography>
              <CeroButton
                buttonText="Subscribe Now"
                className={classes.buttonPrimary}
                onClick={() => {
                  setOpen(true);
                  setPlanId(planDetails && planDetails[0]?.id);
                }}
              />
              {firstPlan.map((item, index) => (
                <MenuItems key={index} topic={item} />
              ))}
              <Typography
                component="h1"
                variant="h5"
                className={classes.planTitle}
                align="center"
                mt={16}
                mb={16}
              >
                Additional Features
              </Typography>
              <Grid>
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    Auditors as a<br /> bundle of 2
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +$
                    {planDetails &&
                      planDetails[0]?.additional_two_auditor_amount}
                  </Typography>
                  <AddButton
                    count={auditorsBundleBasic}
                    setCount={setAuditorsBundleBasic}
                  />
                </Box>
                <Divider />
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    Facility with two
                    <br /> additional users
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +${planDetails && planDetails[0]?.additional_facility}
                  </Typography>
                  <AddButton
                    count={facilityWithTwoUsersBasic}
                    setCount={setFacilityWithTwoUsersBasic}
                  />
                </Box>
                <Divider />
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    Additional
                    <br /> country
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +${planDetails && planDetails[0]?.additional_country_amount}
                  </Typography>
                  <AddButton
                    count={additionalCountryBasic}
                    setCount={setAdditionalCountryBasic}
                  />
                </Box>
                <Divider />
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    25 IOT Device
                    <br /> Connections
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +$
                    {planDetails &&
                      planDetails[0]?.additional_iot_devices_amount}
                  </Typography>
                  <AddButton
                    count={iot25DeviceConnectionsBasic}
                    setCount={setIot25DeviceConnectionsBasic}
                  />
                </Box>
                <Divider />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              variant="outlined"
              className={classes.container}
              display="flex"
              flexDirection="column"
              align="center"
            >
              <Typography
                component="h1"
                variant="h5"
                className={classes.planTitle}
                align="center"
              >
                Premium
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                className={classes.pageTitle}
                align="center"
              >
                US $2000 /mo
              </Typography>
              <CeroButton
                buttonText="Subscribe Now"
                className={classes.buttonPrimary}
                onClick={() => {
                  setOpen(true);
                  setPlanId(planDetails && planDetails[1]?.id);
                }}
              />
              {secondPlan.map((item, index) => (
                <MenuItems key={index} topic={item} />
              ))}

              <Typography
                component="h1"
                variant="h5"
                className={classes.planTitle}
                align="center"
                mt={16}
                mb={16}
              >
                Additional Features
              </Typography>
              <Grid>
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    Auditors as a<br /> bundle of 2
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +$
                    {planDetails &&
                      planDetails[0]?.additional_two_auditor_amount}
                  </Typography>
                  <AddButton
                    count={auditorsBundlePremium}
                    setCount={setAuditorsBundlePremium}
                  />
                </Box>
                <Divider />
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    Facility with two
                    <br /> additional users
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +${planDetails && planDetails[0]?.additional_facility}
                  </Typography>
                  <AddButton
                    count={facilityWithTwoUsersPremium}
                    setCount={setFacilityWithTwoUsersPremium}
                  />
                </Box>
                <Divider />
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    Additional
                    <br /> country
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +${planDetails && planDetails[1]?.additional_country_amount}
                  </Typography>
                  <AddButton
                    count={additionalCountryPremium}
                    setCount={setAdditionalCountryPremium}
                  />
                </Box>
                <Divider />
                <Box
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr 1fr"}
                  justifyItems={"left"}
                  mt={2}
                  mb={2}
                  alignItems={"center"}
                >
                  <Typography align="left">
                    25 IOT Device
                    <br /> Connections
                  </Typography>
                  <Typography color={"#8C2148"} fontWeight={500}>
                    +$
                    {planDetails &&
                      planDetails[1]?.additional_iot_devices_amount}
                  </Typography>
                  <AddButton
                    count={iot25DeviceConnectionsPremium}
                    setCount={setIot25DeviceConnectionsPremium}
                  />
                </Box>
                <Divider />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <CeroPopUp
        primaryPopUp={{
          open: open,
          onClose: () => setOpen(false),
          content: getApproveConfirm(),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: openInvoice,
          onClose: () => setOpenInvoice(false),
          content: getPlanTotal(),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog }}
      />
    </DashboardLayout>
  );
};

export default SubscriptionPlans;
