import React from 'react';
import { useNavigate } from 'react-router-dom';
import CeroTable from '../../../components/CeroTable';
import useStyles from "./styles";
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

export const TableColumns = [{
    columnKey: 'ticket_id',
    columnId: 'ticket_id',
    columnHeader: 'Ticket ID',
}, {
    columnKey: 'type',
    columnId: 'type',
    columnHeader: 'Tickets Type',
}, {
    columnKey: 'severity',
    columnId: 'severity',
    columnHeader: 'Severity',
}, {
    columnKey: 'company',
    columnId: 'company',
    columnHeader: 'Company',
}, {
    columnKey: 'assigned_on',
    columnId: 'assigned_on',
    columnHeader: 'Assigned on',
}, {
    columnKey: 'ticket_raised_by',
    columnId: 'ticket_raised_by',
    columnHeader: 'Ticket Raised by',
}, {
    columnKey: 'status',
    columnId: 'status',
    columnHeader: 'Status',
}]

const TicketsTable = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { ticketList, onLoadMore } = props
    const getStatus = (status) => {
        if (status === "open") {
          return <Typography className={classes.Open}>Open</Typography>;
        } else if (status === "closed") {
            return <Typography className={classes.Close}>Closed</Typography>;
        } else {
          return <Typography>{status}</Typography>;
        }
      };

    const getTicketsList = () => ticketList.map((item) => ({
        ...item,
        status: getStatus(item.status),
        assigned_on: item.assigned_on
        ? dayjs(item.assigned_on).format("DD MMM YYYY HH:mm")
        : "-",
    }));

    const onSelectCompanyData = (rowData) => {
        navigate(`/super-admin-tickets/ticket-details/${rowData.id}`);
    };

    return (
        <CeroTable
            columns={TableColumns}
            data={getTicketsList()}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={rowData => onSelectCompanyData(rowData)}
            classes={{ tableContainer: classes.tableContainer }}
        />
    )
}


export default TicketsTable;
