import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const DiscriminationIncident = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;
  return (
    <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div">
            Discrimination Incident record
          </Typography>
        </Box>
        <Box className={classes.topContainer}>
          <Grid
            container
            direction={"row"}
            wrap="nowrap"
            justifyContent={"space-between"}
            display={"contents"}
            spacing={12}
          >
            <Grid item container direction={"column"} display={"contents"} xs={10}>
            <Box className={classes.actionBox}>
            <CeroInfoPair 
                title="Status:"
                value={emissionData.status ? emissionData.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: emissionData.status === "In Progress" ? classes.Inprogress : classes[emissionData?.status]
                }}
              /></Box>
            <CeroItemPair
              title="Facility:"
              value={emissionData.facility_name ? emissionData.facility_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Period:"
              value={emissionData.assessment_period ? emissionData.assessment_period : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Year:"
              value={emissionData.year ? emissionData.year : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Date of Incident:"
              value={emissionData.date ? emissionData.date : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Status of Incident:"
              value={emissionData.status_of_incident ? emissionData.status_of_incident : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
              <CeroItemPair
                title="Type of Discrimination:"
                value={
                  emissionData.types_of_discrimination
                    ? emissionData.types_of_discrimination
                        .split(",")
                        .map((type, index) => (
                          <li className={classes.list} key={index}>
                            {index + 1}. {type}
                          </li>
                        ))
                    : "NA"
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.value,
                }}
              />
            <CeroItemPair
              title="Details about the Incident:"
              value={emissionData.details ? emissionData.details : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Corrective Actions:"
              value={emissionData.corrective_action ? emissionData.corrective_action : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Audited on:"
              value={emissionData.audited_by ? emissionData.audited_by : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Audited by:"
              value={emissionData.audited_on
                ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default DiscriminationIncident;
