import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        height: '100%',
        width: '100%',
        maxWidth: '100%',
    },
    filterContainer: {
        display: 'flex',
        padding: theme.spacing(4),
    },
    topics: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: theme.spacing(4),
    },
    containerBox: {
        padding: theme.spacing(6),
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
        borderRadius: theme.spacing(4),
        height: "fit-content",
        borderColor: "#5A404E",
        borderStyle: "solid", 
        borderWidth: "1px", 
        width: "90%",
        margin: "auto",
        zIndex: 1,
        position: 'relative', 
        marginTop: '-20px',
        backgroundColor: '#F8F7F9',
    },
    headings: {
        fontWeight: 700,
        fontSize: 20,
        paddingTop: theme.spacing(4),
        whiteSpace: "nowrap"
    },
    headingTitle: {
        color: theme.palette.Primary.background,
        fontWeight:600,
    },
    button: {
        width: '100%',
        backgroundColor: `${theme.palette.Primary.background}!important`,
        fontStyle: 'normal',
        fontWeight: '600!important',
        fontSize: '13px!important',
        textTransform: "capitalize"
    },
    buttonSecondary: {
        color: theme.palette.Primary.background,
        borderColor: theme.palette.Primary.background,
        "&:hover": {
          borderColor: theme.palette.Primary.background,
          backgroundColor:theme.palette.Primary.contrastText,
          border:"2px solid"
        },
        marginRight: theme.spacing(5),
    },
    tabs:{
        zIndex: 2,
        position: 'relative', 
        marginBottom: '-20px',
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.login.loginButton,
            display: 'none',
          },
          "& .MuiTabs-flexContainer": {
            width: "80%",
            margin: "auto",
            display: "flex",
            justifyContent: "space-around",
            borderColor: "#5A404E",
            borderStyle: "solid", 
            borderWidth: "1px", 
            borderRadius: theme.spacing(4),
            position: 'relative',
            zIndex: 2,
            backgroundColor: "#F8F7F9"
          },
          "& .MuiTab-root": {
            color: theme.palette.text.primary, 
          },
         "& .MuiTab-root.Mui-selected":{
            color: `${theme.palette.login.loginButton} !important`,
         }
    },
    tabOption:{
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: 24
    },
    mainTitle:{
        padding: 0,
        marginLeft: theme.spacing(15),
        marginTop: 0,
    },
    headingText: {
        position: 'relative',
      },
      underline: {
        position: 'absolute',
        bottom: theme.spacing(-2), 
        left: 0,
        width: '100%',
        height: 1,
        background: 'linear-gradient(90deg, #FFFFFF 0%, #8F3052 30%, #8F3052 70%, #FFFFFF 100%)',
        opacity: 0.5,
      },
      tabTitle:{
        marginLeft: 8
      },
      tabLabel:{
        display: "flex",
        alignItems: "center"
      },   
}));

export default useStyles;