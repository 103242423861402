import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updateDiscriminationIncidentRecord,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { sampleYear } from "../../../constants";
import CeroDate from "../../../components/CeroDate";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroChipSelect from "../../../components/CeroChipSelect";

const EditDiscriminationIncidentForm = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDiscrimination, setSelectedDiscrimination] = useState([]);
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const updateEmissionData = useSelector(
    (state) => state.emission.updateDiscriminationIncidentRecord
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || "",
      assessmentPeriod: emissionData.assessment_period || "",
      date: emissionData.date || "",
      incidentStatus: emissionData.status_of_incident || "",
      discriminationType: emissionData.types_of_discrimination ? emissionData.types_of_discrimination.split(',') : [],
      details: emissionData.details || "",
      correctiveAction: emissionData.corrective_action || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const incidentTypes = [
      { key: "In Review", value: "In Review" },
      { key: "No further action required", value: "No further action required" },
      { key: "Remediation Completed", value: "Remediation Completed" },
      { key: "Remediation in progress", value: "Remediation in progress" }
  ];

  const discriminationTypes = {
    "Race": "Race",
    "Colour": "Colour",
    "Gender": "Gender",
    "Religion": "Religion",
    "Political opinion": "Political opinion",
    "Natural extraction": "Natural extraction",
    "Others": "Others",
  };

  const discriminationHandleChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue("discriminationType", value);
  };

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Discrimination incident record updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateDiscriminationIncidentDetails = () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      date: dayjs(formik.values.date).format("DD/MM/YYYY"),
      status_of_incident: formik.values.incidentStatus,
      types_of_discrimination: formik.values.discriminationType,
      details: formik.values.details,
      corrective_action: formik.values.correctiveAction,
      save: true,
    };
    dispatch(updateDiscriminationIncidentRecord(requestData));
  };

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Discrimination Incident Record
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroDate
                  required
                  label="Date"
                  value={formik.values.date}
                  assessmentPeriod={formik.values.assessmentPeriod}
                  year={formik.values.year}
                  onChange={(date) => formik.setFieldValue("date", date)}
                  error={formik.touched.date && formik.errors.date}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                <CeroSelect
                  required
                  id="incidentStatus"
                  name="incidentStatus"
                  label="Status of Incident"
                  fullWidth
                  options={incidentTypes}
                  selectedValue={formik.values.incidentStatus}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incidentStatus &&
                    formik.errors.incidentStatus
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
                <CeroChipSelect
                  required
                  id="discriminationType"
                  name="discriminationType"
                  label={"Type of Discrimination"}
                  formControlClass={classes.MultiSelectformControl}
                  optionsde={discriminationTypes}
                  selected={formik.values.discriminationType}
                  handleChange={(e)=>discriminationHandleChange(e)}
                  value={formik.values.discriminationType}
                  error={
                    formik.touched.discriminationType &&
                    formik.errors.discriminationType
                  }
                  classes={{justCheckSelected: classes.justCheckSelected}}
                />
              </Grid>
            </Grid>
            <CeroInput
              required
              id="details"
              name="details"
              label="Details about the incident"
              value={formik.values.details}
              fullWidth
              multiline
              rows="2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.details && formik.errors.details}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="correctiveAction"
              name="correctiveAction"
              label="Corrective Action"
              value={formik.values.correctiveAction}
              fullWidth
              multiline
              rows="2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.correctiveAction &&
                formik.errors.correctiveAction
              }
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={!formik.dirty || !formik.isValid}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onUpdateDiscriminationIncidentDetails}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={clsx(classes.button, classes.buttonSecondary)}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default EditDiscriminationIncidentForm;
