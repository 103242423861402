import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        maxWidth: '30vw',
        width: '30vw',
    },
    drawerContentArea: {
        padding: theme.spacing(4),
        overflow: 'auto'
    },
    selectContainer: {
        margin: theme.spacing(5, 0),
    },
    container: {
        height: 60,
        margin: theme.spacing(3, 0),
        display: 'flex',
        flexDirection: 'column',
    },
    fileInput: {
        opacity: 0,
        height: '100%',
        width: '100%',
        position: 'absolute',
        cursor: 'pointer',
        zIndex: 1,
        display: "none"
    },
    textField:{
        width: "100%",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.Primary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.Primary.main,
            },
            '&.Mui-focused fieldset': {
                borderWidth: 2,
                borderColor: theme.palette.Primary.main,
            },
        },
    },
    buttonPrimary: {
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    },
    progressLoader:{
    color: theme.palette.Primary.background,
    marginLeft: theme.spacing(5),
    }
}));

export default useStyles;