import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardFooter: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(5, 0, 0, 0),
  },
  button: {
    margin: theme.spacing(0, 2),
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  backButton: {
    backgroundColor: theme.palette.Primary.light,
    color: theme.palette.Primary.main,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.contrastText,
    },
  },
  yearPicker: {
    minWidth: "90%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Other.stroke,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      display: "flex",
    },
  },
  input: {
    marginBottom: theme.spacing(5),
  },
  phoneInput: {
    margin: theme.spacing(5, 0),
  },
  container: {
    margin: theme.spacing(5, 0),
    display: "flex",
    flexDirection: "column",
    "& .MuiStack-root": {
      display: "contents",
    },
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
}));

export default useStyles;
