import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(10),
    margin: theme.spacing(10, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(5),
    },
    borderRadius: 8,
    borderColor: theme.palette.Primary.background,
  },
  buttonContainer: {
    padding: theme.spacing(2, 0),
  },
  cardTitle: {
    marginBottom: theme.spacing(8),
    fontWeight: 500,
    fontSize: 20,
  },
  pageTitle: {
    marginBottom: theme.spacing(8),
    fontWeight: 600,
    fontSize: 20,
  },
  planTitle: {
    marginBottom: theme.spacing(8),
    fontWeight: 600,
    fontSize: 20,
    color: theme.palette.Primary.background,
  },
  buttonPrimary: {
    height: "37px !important",
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    marginBottom: 20,
  },
  infoContainer: {
    display: "flex !important",
    alignItems: "start",
    gap: "46px",
    padding: theme.spacing(1, 0),
  },
  title: {
    width: "120px",
    marginRight: theme.spacing(5),
    fontWeight: 400,
  },
  value: {
    fontWeight: 400,
    whiteSpace: "break-spaces",
  },
  titleTotal: {
    width: "120px",
    marginRight: theme.spacing(5),
    fontWeight: 600,
  },
  valueTotal: {
    fontWeight: 600,
    whiteSpace: "break-spaces",
  },
  planvalue: {
    fontWeight: 500,
    whiteSpace: "break-spaces",
    lineHeight: 2.5,
    marginTop: -2.5,
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
    alignItems: "center",
    cursor: "pointer",
  },

  popUp: {
    margin: "auto",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 15,
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    margin: "auto",
  },
  buttonSecondary: {
    height: "37px",
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
  },
}));

export default useStyles;
