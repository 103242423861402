import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        height: '95%',
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
        borderRadius: 8,
        '& svg': {
            padding: theme.spacing(0),
        }
    },
    graphTitle: {
        fontSize: 18,
        fontWeight: 500,
        margin: theme.spacing(2, 0),
        paddingBottom: theme.spacing(2)
    },
}));

export default useStyles;