import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.text.primary,
    },
    value: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.text.primary,
        textTransform: "capitalize"
    },
    subTitle: {
        marginLeft: theme.spacing(0),
    },
    closedBtn: {
        fontSize: 16,
        padding: theme.spacing(5,4.8),
        marginRight: theme.spacing(15),
        borderRadius:20
    },
    openBtn: {
        color: 'white',
        fontSize: 16,
        background: '#F7685B',
        padding: theme.spacing(4.8,5),
        marginRight: theme.spacing(15),
        borderRadius:20
    },
    
}));

export default useStyles;