import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CeroTable from "../../../../components/CeroTable";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  companyUserDetails,
  totalUserDetails,
} from "../../../../redux/actions";
import {
  resetSuperAdminStatus,
  updateBusinessAdminStatus,
} from "../../../../redux/actions/superAdmin";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Box, Container, Stack, Switch, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroButton from "../../../../components/CeroButton";
import AddAdmin from "../../CompanyDetail/AddAdmin";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";

export const TotalAdminUsersColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Action",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

export const TotalUsersColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

export const ActiveUsersColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
];
export const ActiveUsersAdminColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Action",
  },
];

const CompanyUserDetailsTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userList, setUserList] = useState([]);

  const { companyId, userType, isActive } = useParams();
  const user = userType.replace(/ /g, "_");
  const userDetails = useSelector(
    (state) => state.listings.companyUserDetail.data
  );
  
  const userUpdateStatus = useSelector(
    (state) => state.superAdmin.adminUserUpdateStatus.status
  );
  useEffect(() => {
    setUserList(userDetails);
  }, [userDetails]);
  useEffect(() => {
    if (userUpdateStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Sucessfully unlocked", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetSuperAdminStatus())
    } else if (userUpdateStatus === STATUS.ERROR) {
      enqueueSnackbar("failed", {
        variant: "error",
        autoHideDuration: 1000,
      });
      dispatch(resetSuperAdminStatus())
    }
  }, [userUpdateStatus, enqueueSnackbar, dispatch]);
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    dispatch(companyUserDetails(companyId, user, isActive));
  }, [dispatch]);
  const handleToggleStatus = (id, status) => {
    console.log(id);
    const updatedUserList = userList.map((item) =>
      item.id === id ? { ...item, lock_status: status } : item
    );
    // const requestData = {
    //   id: id,
    //   status: status,
    // };
    setUserList(updatedUserList);
    const requestData = {
      id: id,
      status: status,
    };
    dispatch(updateBusinessAdminStatus(id, status));
  };
  const getTotalUsers = () =>
    userList.map((item) => ({
      ...item,
      name: item.name ? item.name : "",
      email: item.email ? item.email : "",
      facility: item.facility ? item.facility : "",
      status: item.status ? (
        <Typography className={classes.activeStatus}>Active</Typography>
      ) : (
        <Typography className={classes.inActiveStatus}>Inactive</Typography>
      ),
      action: user === 'admin' ?(
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Unlock
          </Typography>
          <Switch
            checked={item.lock_status}
            className={classes.switchColor}
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            disabled={!item.lock_status}
          />
          <Typography
            style={{ fontWeight: item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Lock
          </Typography>
        </Stack>
      ):"",
    }));

  const getActiveUsers = () =>
    userList.map((item) => ({
      ...item,
      name: item.name ? item.name : "",
      email: item.email ? item.email : "",
      facility: item.facility ? item.facility : "",
      action: user === 'admin' ?(
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Unlock
          </Typography>
          <Switch
            checked={item.lock_status}
            className={classes.switchColor}
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            disabled={!item.lock_status}
          />
          <Typography
            style={{ fontWeight: item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Lock
          </Typography>
        </Stack>
      ):"",
    }));

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container>
        {isActive === "true" ? (
          <>
            <Typography variant="h6">Active {userType}s</Typography>
            <CeroTable
              columns={user === 'admin' ? ActiveUsersAdminColumns : ActiveUsersColumns}
              data={getActiveUsers()}
              hasMore={false}
              loading={false}
              classes={{ tableContainer: classes.tableContainer }}
            />
          </>
        ) : (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h6">Total {userType}s</Typography>
             {userType === "admin" && <CeroButton
                buttonText="Add Business Admin"
                onClick={() => setOpenDrawer(true)}
                className={classes.buttonPrimary}
              />}
            </Box>
            <CeroTable
              columns={user === 'admin' ? TotalAdminUsersColumns:TotalUsersColumns}
              data={getTotalUsers()}
              hasMore={false}
              loading={false}
              classes={{ tableContainer: classes.tableContainer }}
            />
            {openDrawer && (
              <AddAdmin isOpen={openDrawer} onClose={handleDrawerClose} />
            )}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default CompanyUserDetailsTable;
