import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        margin:0,
        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.Primary.main}`,
        },
    },
    input: {
        width: '100%',
        height: '100%',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.Primary.main} !important`
          },  
          "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
            borderColor: "green !important"
          }
    },
    select: {
        padding: theme.spacing(2),
    },
    text: {
        fontSize: 12,
        lineHeight: 1.5,
        fontWeight: 400,
        color: theme.palette.text.secondary,
        textAlign: 'start',
    },
    paperRoot: {
        maxHeight: 160,
    },
    error: {
        color: theme.palette.Error.main,
    },
    label: {
        color: theme.palette.Primary.main,
        "&.Mui-focused": {
          color: theme.palette.Primary.main,
        },
      },
      menuItem: {
        whiteSpace: 'normal',
        maxWidth: '300px',
      },
      menuItemText: {
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      },
}));

export default useStyles;
