import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from "@mui/material";

import CeroTable from '../../../components/CeroTable';
import useStyles from "./styles";
import { useDispatch, useSelector } from 'react-redux';
import { companyFacilityList } from "../../../redux/actions";
import { STATUS } from '../../../redux/constants';


export const TableColumns = [
    {
        columnKey: 'facility_name',
        columnId: 'facility_name',
        columnHeader: 'Facility Name',
    },
    {
        columnKey: 'address',
        columnId: 'address',
        columnHeader: 'Address',
    },
    {
        columnKey: 'phone_number',
        columnId: 'phone_number',
        columnHeader: 'Phone Number',
    },
    {
        columnKey: 'county',
        columnId: 'county',
        columnHeader: 'Country',
    },
    {
        columnKey: 'total_users',
        columnId: 'total_users',
        columnHeader: 'Total users',
    }
];

const FacilityListTable = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {  onLoadMore } = props;
    const companyFacilities = useSelector(state => state.listings.companyFacilities.data);
    const companyFacilitiesStatus = useSelector(state => state.listings.companyFacilities.status);

    useEffect(() => {
        dispatch(companyFacilityList(props.companyId))
    }, [dispatch])

    const getFacilitiesList = () => companyFacilities.map((item) => ({
        ...item,
    }));

    return (
        <div>
       
         {companyFacilitiesStatus === STATUS.SUCCESS ? (
           <CeroTable
            columns={TableColumns}
            data={getFacilitiesList()}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            classes={{ tableContainer: classes.tableContainer }}
        />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {companyFacilitiesStatus === STATUS.RUNNING
                ? <CircularProgress/>
                : companyFacilitiesStatus === STATUS.ERROR
                ? "We couldn't process your request. Please try again later"
                : ""}
            </Typography>
          </Box>
        )}
        </div>
    );
};

export default FacilityListTable;
