import { useFormik } from "formik";

import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import { passwordSchema } from "../schema";
import useStyles from "../styles";
import { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChangePassword = (props) => {
    const classes = useStyles();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const passwordForm = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: passwordSchema
    });

    const handleNext = () => {
        props.onNext(2, passwordForm.values.password)
    }

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    return (
        <>
            <CeroInput
                required
                type={showOldPassword ? "text" : "password"}
                id="password"
                label="Enter Password"
                value={passwordForm.values.password}
                onChange={passwordForm.handleChange}
                onBlur={passwordForm.handleBlur}
                error={passwordForm.touched.password && passwordForm.errors.password}
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleOldPasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                    </InputAdornment>
                  }
            />
            <CeroInput
                required
                type={showNewPassword ? "text" : "password"}
                id="confirmPassword"
                label="Confirm Password"
                value={passwordForm.values.confirmPassword}
                onChange={passwordForm.handleChange}
                onBlur={passwordForm.handleBlur}
                error={passwordForm.touched.confirmPassword && passwordForm.errors.confirmPassword}
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleNewPasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                    </InputAdornment>
                  }
            />
            <CeroButton
                variant="contained"
                onClick={handleNext}
                fullWidth
                buttonText='CHANGE PASSWORD'
                className={classes.button}
                disabled={!passwordForm.dirty || !passwordForm.isValid || props.isLoading}
            />
        </>
    );
};

export default ChangePassword;
