import * as yup from 'yup';

export const schemeValidation = yup.object({
    facility: yup.string().required('Facility is required'),
    date: yup.date().required('Date is required'),
    assessmentPeriod: yup.string().required('Assessment Period is required'),
    year: yup.string().required('Year is required'),
    typeOfIncident: yup.string().required('Type of Incident is required'),
    severtiyOfIncident: yup.string().required('Severity of Incident is required'),
    impactOnWork: yup.string().required('Impact on Work is required'),
    medicalTreatment: yup.string().required('Medical Treatment is required'),
    noOfAffected: yup.number().required('Number of Affected is required'),
    details: yup.string().required('Details is required'),
    correctiveAction: yup.string().required('Corrective Action is required'),
  });
  