import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
    questionBox:{
        width: "80%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    questionTitle:{
        whiteSpace: "nowrap",
    },
    checkbox:{
        display: "flex"
    }
}));
export default useStyles;
