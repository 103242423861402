import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from 'notistack';

import { STATUS } from "../../../redux/constants";
import { updateDevelopmentTrainingValidation } from './schema';
import { updateDevelopmentTrainingDetails } from '../../../redux/actions';
import CeroButton from '../../../components/CeroButton';
import CeroInput from '../../../components/CeroInput';
import useStyles from "./styles";
import CeroEpochDatePicker from '../../../components/CeroDateTimePicker/CeroEpochDatePicker';
import dayjs from 'dayjs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const EditDevelopmentTrainingForm = (props) => {
    const { emissionData, onCancel } = props
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const updateEmissionData = useSelector(state => state.emission.updateDevelopmentTrainingDetails);

    const formik = useFormik({
        initialValues: {
            numberOfAttendee: emissionData.attended || '',
            numberOfHours: emissionData.hours || '',
            content: emissionData.content || '',
            objective: emissionData.objective || '',
            date: dayjs().unix(),
        },
        validationSchema: updateDevelopmentTrainingValidation,
        onSubmit: () => {},
    });

    useEffect(() => {
        if (updateEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Development Training details updated successfully', { variant: 'success' });
            onCancel();
        } else if (updateEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(updateEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
        }
    }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

    const onUpdateDevelopmentTrainingData = () => {
        const requestData = {
            id: emissionData.id,
            date: dayjs(formik.values.date * 1000).format("DD/MM/YYYY"),
            attended: formik.values.numberOfAttendee,
            hours: formik.values.numberOfHours,
            objective: formik.values.objective,
            content: formik.values.content,
            save: true,
        };
        dispatch(updateDevelopmentTrainingDetails(requestData));
    };

    return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
      <ArrowBackIosIcon />
      Back
      </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Edit Development Training</Typography>
                <Box className={classes.topContainer}>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                        <Grid item container direction='column' md={6} xs={12}>
                            <CeroInput
                                required
                                id="numberOfAttendee"
                                name="numberOfAttendee"
                                label="Number of Attendees"
                                value={formik.values.numberOfAttendee}
                                type="number"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.numberOfAttendee && formik.errors.numberOfAttendee}
                            />
                            <CeroInput
                                required
                                id="objective"
                                name="objective"
                                label="Objective"
                                value={formik.values.objective}
                                fullWidth
                                multiline
                                rows="3"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.objective && formik.errors.objective}
                            />                        
                        </Grid>
                        <Grid item container direction={'column'} md={6} xs={12}>
                        {/* <CeroEpochDatePicker
                                name="date"
                                value={formik.values.date}
                                label="Date"
                                onChange={formik.setFieldValue}
                                error={formik.touched.date && formik.errors.date}
                            /> */}
                            <CeroInput
                                required
                                id="numberOfHours"
                                name="numberOfHours"
                                label="Number of Hours"
                                value={formik.values.numberOfHours}
                                type="number"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.numberOfHours && formik.errors.numberOfHours}
                            />
                            <CeroInput
                                required
                                id="content"
                                name="content"
                                label="Content Covered"
                                value={formik.values.content}
                                fullWidth
                                multiline
                                rows="3"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.content && formik.errors.content}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Update"
                    disabled={!formik.dirty || !formik.isValid}
                    className={clsx(classes.button, classes.buttonPrimary)}
                    onClick={onUpdateDevelopmentTrainingData}
                />
                <CeroButton
                    buttonText="Clear"
                    variant="outlined"
                    className={clsx(classes.button, classes.buttonSecondary)}
                    onClick={formik.resetForm}
                />
            </Box>
            </Box>
        </Container>
        </>
    );
};

export default EditDevelopmentTrainingForm;
