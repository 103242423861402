import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100vw',
        postion: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#000000b5',
        zIndex: 400,
    },
    loadingText:{
      marginTop:4,
      fontWeight:500,
      color:theme.palette.Primary.main
    },
    loaderContainer: {
        width: 200,
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.Primary.main,
    },


    rotatingLogo: {
        animation: '$spin 1.5s infinite linear', // Adjust the animation duration or timing function as needed
      },
      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },
}));

export default useStyles;