export const ApprovalEmployeeHireAndTurnover = [
    {
        columnKey: 'gender',
        columnId: 'gender',
        columnHeader: 'Gender',
        width : '15vw',
    }, {
        columnKey: 'employee_classification',
        columnId: 'employee_classification',
        columnHeader: 'Employee Classification',
        width : '18vw',
    }, {
        columnKey: 'resigned',
        columnId: 'resigned',
        columnHeader: 'Resigned',
        width : '12vw',
    }, {
        columnKey: 'hired',
        columnId: 'hired',
        columnHeader: 'Hired',
        width : '12vw',
    }, {
        columnKey: 'turnover_rate',
        columnId: 'turnover_rate',
        columnHeader: 'Turnover Rate',
        width : '12vw',
    }, {
        columnKey: 'status',
        columnId: 'status',
        columnHeader: 'Status',
        width : '12vw',
    },
  ]
  
  export const ApprovalEmployeeHireAndTurnoverByAge = [
    {
        columnKey: 'age',
        columnId: 'age',
        columnHeader: 'Age',
        width : '15vw',
    }, {
        columnKey: 'employee_classification',
        columnId: 'employee_classification',
        columnHeader: 'Employee Classification',
        width : '18vw',
    }, {
        columnKey: 'resigned',
        columnId: 'resigned',
        columnHeader: 'Resigned',
        width : '12vw',
    }, {
        columnKey: 'hired',
        columnId: 'hired',
        columnHeader: 'Hired',
        width : '12vw',
    }, {
        columnKey: 'turnover_rate',
        columnId: 'turnover_rate',
        columnHeader: 'Turnover Rate',
        width : '12vw',
    }, {
        columnKey: 'status',
        columnId: 'status',
        columnHeader: 'Status',
        width : '12vw',
    }
  ]

  export const EmployeeBenefits = [
    {
        columnKey: 'assessment_period',
        columnId: 'assessment_period',
        columnHeader: 'Period',
    },{
        columnKey: 'year',
        columnId: 'year',
        columnHeader: 'Year',
    }, {
        columnKey: 'facility_name',
        columnId: 'facility_name',
        columnHeader: 'Facility',
    }, {
        columnKey: 'employees_classification',
        columnId: 'employees_classification',
        columnHeader: 'Employee Classification',
    }, {
        columnKey: 'benefit_provided',
        columnId: 'benefit_provided',
        columnHeader: 'Benefits Provided',
    }, {
        columnKey: 'number_of_employees',
        columnId: 'number_of_employees',
        columnHeader: 'Number of Employees',
    }, {
        columnKey: 'status',
        columnId: 'status',
        columnHeader: 'Status',
    }, {
      columnKey: 'action',
      columnId: 'action',
      columnHeader: '',
  }
  ]