import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  Typography,
  Container,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  InputLabel,
  Link as MuiLink,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import { generalUserLogin, userAutoLogout } from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import CeroButton from "../../components/CeroButton";
import CeroInput from "../../components/CeroInput";
import ValidationSchema from "./ValidationSchema";
import ceroed from "../../assets/ceroed.png";
import useStyles from "./styles";
import loginBackGround from "../../assets/loginBackGround.png";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { deleteCookie, getCookie, setCookie } from "../../services/cookie";

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showTwofa, setShowTwofa] = useState(false);
  const [showCaution, setShowCaution] = useState(false);
  const navigate = useNavigate();
  const sessionExpred = getCookie('session_expired')
  
  useEffect(() => {
    if (loginData.status === STATUS.ERROR && loginData.message) {
      if (loginData.message == 'admin lock'){
        setShowCaution(false)
        setShowTwofa(true)
      }
      else if (loginData.message == 'one time left')
          setShowCaution(true)
      else
        {
        setShowCaution(false)
        enqueueSnackbar(loginData.message, { variant: "error" });
        }
    }
  }, [loginData.message, loginData.status, enqueueSnackbar]);

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: ValidationSchema,
  });
  const handleLogin = () => {
    dispatch(
      generalUserLogin(loginForm.values.email, loginForm.values.password
        )
    );
  };

  const handleLoginAgain = () => {
    dispatch(userAutoLogout(false));
  }

  const handleDeleteCookie = () => {
    deleteCookie('session_expired')
    window.location.reload()
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleOpenPopup = () =>{
    setCookie('tfa-admin',loginForm.values.email)
    navigate("/admin-2FA");
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      // Trigger login action when Enter key is pressed
      handleLogin();
    }
  };

  const signupPath = window.location.pathname.includes('/trade/login') ? '/trade/signup' : '/signup/';

  return (
    <form onSubmit={handleLogin} onKeyPress={handleKeyPress}>
      {" "}
      {/* Handle form submission */}
      <Box className={classes.mainBox}>
        <Box
          style={{
            opacity: loginData.autoLogggedOut ? 0.4 : 1,
            pointerEvents: loginData.autoLogggedOut ? "none" : "",
          }}
        >
          <Box className={classes.leftSide}></Box>
          <Box className={classes.rightSide}>
            <img
              src={loginBackGround}
              alt="Background img"
              className={classes.backgroundImage}
            />
            <Container component="main" className={classes.main} maxWidth="xs">
              <Box className={classes.loginCard}>
                <img src={ceroed} alt="ceroED Logo" className={classes.logo} />
              </Box>
              <Box className={classes.loginBox}>
                <Typography variant="h5" className={classes.loginText}>
                  Login
                </Typography>
                {showCaution && (
                  <Box className={classes.caution}>
                    <Typography
                      variant="h5"
                      className={classes.cautionTextMain}
                    >
                      Login Failed! You have attempt 2 of 3.
                    </Typography>
                    <Typography variant="h5" className={classes.cautionText}>
                      Please note that your account will be locked, if you enter
                      incorrect password again.
                    </Typography>
                  </Box>
                )}
                {showTwofa && (
                  <Box className={classes.caution}>
                    <Typography
                      variant="h2"
                      className={classes.cautionTextMain}
                    >
                      Login Failed! You have attempt 3 of 3.
                    </Typography>
                    <Typography variant="h2" className={classes.cautionText}>
                      Account locked! Do you want to login using 2FA
                      <MuiLink
                        component="button"
                        onClick={handleOpenPopup}
                        className={classes.title}
                      >
                        click here
                      </MuiLink>
                    </Typography>
                  </Box>
                )}
                <CeroInput
                  required
                  fullWidth
                  id="email"
                  placeholder="Email"
                  autoComplete="email"
                  values={loginForm.values.email}
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  error={loginForm.errors.email}
                  classes={{ input: classes.input }}
                />
                <CeroInput
                  type={showPassword ? "text" : "password"}
                  required
                  fullWidth
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  values={loginForm.values.password}
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  error={loginForm.errors.password}
                  classes={{ input: classes.input }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }} // Handle Enter key press
                />
                {/* <MuiLink
              component="button"
              onClick={handleOpenPopup}
              className={classes.title}
            >
              Do you want to unlock the account using 2FA?
            </MuiLink> */}
                {/* <Typography  className={classes.title} >{"do you want to unlock the account using 2FA?"}</Typography> */}
                <CeroButton
                  fullWidth
                  buttonText="Login"
                  className={classes.button}
                  onClick={handleLogin}
                  disabled={!loginForm.dirty || !loginForm.isValid}
                />
                <Box className={classes.alertBox}>
                  <Link
                    className={classes.forgotLink}
                    to={`/forgot-password/`}
                    variant="h3"
                  >
                    Forgot password?
                  </Link>

                  <Link
                    className={classes.newUser}
                    to={signupPath}
                    variant="h3"
                  >
                    Sign Up
                  </Link>
                </Box>
              </Box>
            </Container>
          </Box>
          <Typography className={classes.footer}>
            &copy;2023 CeroED,Inc.All rights reserved.
          </Typography>
        </Box>

        {loginData.autoLogggedOut  && (
          <>
            <Box className={classes.backdrop}></Box>
            <Box className={classes.modal}>
              <Box className={classes.content}>
                <h2>Logged out!!</h2>
                <p>You've been logged out due to inactivity.</p>
                <br />
                <Box>
                  <CeroButton
                    className={classes.btn}
                    onClick={() => handleLoginAgain()}
                    buttonText="OK"
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
        { sessionExpred && (
          <>
            <Box className={classes.backdrop}></Box>
            <Box className={classes.modal}>
              <Box className={classes.content}>
                <h2>Logged out!!</h2>
                <p>Your session is expired!!</p>
                <br />
                <Box>
                  <CeroButton
                    className={classes.btn}
                    onClick={handleDeleteCookie}
                    buttonText="OK"
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
};

export default Login;