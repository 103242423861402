import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  Switch,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroTable from "../../components/CeroTable";
import {
  getAssignedStakeholders,
  updateStakeholderStatus,
  resetUpdateStakeholderStatus,
} from "../../redux/actions/users";
import { STATUS } from "../../redux/constants";
import useStyles from "./styles";
import { useSnackbar } from "notistack";
import { userRoles } from "../../constants";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../services/cookie";

const ReadOnlyUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auditSummary, setAuditSummary] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const role = getCookie("role");
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const auditSummaryColumns = [
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
      width: "15vw",
    },
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
      width: "10vw",
    },
    {
      columnKey: "role",
      columnId: "role",
      columnHeader: "Role",
      width: "10vw",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      width: "10vw",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
      width: "10vw",
    },
  ];

  const stakeholderData = useSelector(
    (state) => state.users.getAssignedStakeholders.data.stakeholders_list
  );
  const updateStakeholder = useSelector(
    (state) => state.users.stakeholderStatus
  );

  const stakeholderStatus = useSelector(
    (state) => state.users.getAssignedStakeholders.status
  );

  useEffect(() => {
    dispatch(getAssignedStakeholders());
  }, [dispatch]);

  useEffect(() => {
    setAuditSummary(stakeholderData);
  }, [stakeholderData]);

  const onSelectSummaryData = (row) => {
    navigate(`${row.id}?stakeholder=${row.id}`);
  };

  useEffect(() => {
    if (updateStakeholder.status === STATUS.SUCCESS) {
      dispatch(getAssignedStakeholders());
      enqueueSnackbar(updateStakeholder.data.message, {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetUpdateStakeholderStatus());
    } else if (updateStakeholder.status === STATUS.ERROR) {
      dispatch(getAssignedStakeholders());
      enqueueSnackbar(updateStakeholder.message.message, {
        variant: "error",
        autoHideDuration: 1000,
      });
      dispatch(resetUpdateStakeholderStatus());
    }
  }, [updateStakeholder, enqueueSnackbar, dispatch]);

  const handleToggleStatus = (id, status) => {
    if (readOnlyMode) {
      return;
    }
    const updatedAuditSummary = auditSummary?.map((item) =>
      item.id === id ? { ...item, status: status } : item
    );
    const requestData = {
      id: id,
      status: status,
    };
    setAuditSummary(updatedAuditSummary);
    dispatch(updateStakeholderStatus(requestData));
  };

  const getAuditData = () =>
    auditSummary?.map((item) => ({
      ...item,
      email: item.email || "",
      name: item.name || "",
      role: item.role
        .map((key) => {
          const role = userRoles.find((role) => role.key === key);
          return role ? role.value : null;
        })
        .join(", "),
      status: item.status ? "Active" : "Inactive",
      action: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Deactivate
          </Typography>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleToggleStatus(item.id, !item.status);
            }}
          >
            <Switch
              checked={item.status}
              className={
                 classes.switchColor
              }
              inputProps={{ "aria-label": "Toggle Status" }}
              onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            />
          </Box>
          <Typography
            style={{ fontWeight: item.status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Activate
          </Typography>
        </Stack>
      ),
    }));

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {/* <CeroTable
            columns={auditSummaryColumns}
            data={getAuditData()}
            hasMore={false}
            loading={false}
            onSelectRow={onSelectSummaryData}
            classes={{ tableContainer: classes.tableContainer }}
          /> */}
        {stakeholderStatus === STATUS.SUCCESS ? (
          <CeroTable
            columns={auditSummaryColumns}
            data={getAuditData}
            hasMore={false}
            loading={false}
            onSelectRow={onSelectSummaryData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {stakeholderStatus === STATUS.RUNNING ||
              stakeholderStatus === STATUS.IDLE ? (
                <CircularProgress />
              ) : stakeholderStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ReadOnlyUsers;
