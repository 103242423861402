import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    margin: theme.spacing(4, 0),
    
  },
  infoContainer: {
    maxWidth: 400,
    height: 'auto',
    paddingBottom:15,
    display: 'flex',
    justifyContent:"flex-start",
    "& .MuiTypography-root":{
      minWidth:140
    },
    "& .MuiBox-root > .MuiTypography-root":{
      paddingBottom:4
    }
  }
}));

export default useStyles;
