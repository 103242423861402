import PropTypes from "prop-types";
import { useEffect, useState, useRef, useCallback } from "react";
import { Drawer, Collapse, Icon } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CeroEdLogo from "../../assets/images/Logo";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./styles";
import { userRoles } from "../../constants";
import Cookies from "js-cookie";
import { getCookie, setCookie } from "../../services/cookie";
import React from "react";

const SideMenu = ({ options }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const listRef = useRef(null);
  const [moveDrawerToBack, setMoveDrawerToBack] = useState(false);
  const showSwitchUserModal = useSelector((state) => state.users.showSwitchUserModal);
  const isTradeAdmin = getCookie("role") === "trade_admin";
  const scrollPos = getCookie("scrollPosition");

  const userInfo = useSelector((state) => state.auth.userInfo);
  const userRole = userRoles.find((role) => role.key === userInfo?.role);

  const sideMenu = Cookies.get("sideMenu") ? Cookies.get("sideMenu") : "{}";
  const [openSubMenu, setOpenSubMenu] = useState(JSON.parse(sideMenu));

  const handleSubMenuClick = (index) => {
    const tmp = { ...openSubMenu };
    tmp[index] = !tmp[index];
    setOpenSubMenu(tmp);
    const serializedObject = JSON.stringify(tmp);
    Cookies.set("sideMenu", serializedObject);
  };

  const handleNavigation = (path) => {
    const scrollPosition = listRef.current ? listRef.current.scrollTop : 0;
    setCookie("scrollPosition", scrollPosition);
    navigate(path);
  };

  useEffect(() => {
    if (listRef.current && scrollPos && scrollPos !== null) {
      setTimeout(() => {
        listRef.current.scrollTop = Number(scrollPos);
      }, 0);
    }
  }, [location.pathname, scrollPos]);

  useEffect(() => {
    if (showSwitchUserModal) {
      setMoveDrawerToBack(true);
    } else {
      setMoveDrawerToBack(false);
    }
  }, [showSwitchUserModal]);

  return (
    <Box
      className={[
        classes.drawerConainer,
        moveDrawerToBack ? classes.moveToBack : null,
      ]}
    >
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{ paper: classes.drawerCanvas }}
      >
        <Box className={classes.title}>
          <Box className={classes.titleText}>
            <CeroEdLogo />
          </Box>
        </Box>
        <List
          ref={listRef}
          sx={{
            fontWeight: "600 !important",
            marginTop: 8,
            marginLeft: 2,
            paddingLeft: 8,
            overflowY: "auto", // Ensure the list is scrollable
            height: "calc(100vh - 64px)", // Adjust this value based on your layout
          }}
        >
          {options.map((menuItem, index) => (
            <div key={menuItem.text}>
              <ListItem
                button
                onClick={() => {
                  handleSubMenuClick(index);
                  handleNavigation(menuItem.path);
                }}
                className={[
                  (location.pathname.includes(menuItem.path) ||
                    (menuItem.subItems &&
                      menuItem.subItems.some((subItem) =>
                        location.pathname.includes(subItem.path)
                      ))) &&
                  (openSubMenu[index] ||
                    location.pathname.includes(menuItem.path) ||
                    location.pathname.includes(
                      menuItem.path && menuItem.path.split("/")[0]
                    ))
                    ? (isTradeAdmin
                        ? classes.activeOptionTrade
                        : classes.activeOption)
                    : "",
                  (openSubMenu[index] && menuItem.subItems && menuItem.subItems.length > 0)
                    ? classes.expandedOption
                    : "",
                ].join(" ")}
              >
                <ListItemIcon
                  className={
                    (location.pathname.includes(menuItem.path) ||
                      (menuItem.subItems &&
                        menuItem.subItems.some((subItem) =>
                          location.pathname.includes(subItem.path)
                        ))) &&
                    (openSubMenu[index] ||
                      location.pathname.includes(menuItem.path))
                      ? (isTradeAdmin
                          ? classes.activeOptionTrade
                          : classes.activeOption)
                      : classes.icon
                  }
                >
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={menuItem.text}
                  className={
                    (location.pathname.includes(menuItem.path) ||
                      (menuItem.subItems &&
                        menuItem.subItems.some((subItem) =>
                          location.pathname.includes(subItem.path)
                        ))) &&
                    (openSubMenu[index] ||
                      location.pathname.includes(menuItem.path))
                      ? (isTradeAdmin
                          ? classes.activeOptionTrade
                          : classes.activeOption)
                      : classes.menuText
                  }
                />
                {menuItem.subItems && menuItem.subItems.length > 0 && (
                  <ListItemIcon>
                    <Icon
                      style={{
                        color: openSubMenu[index] ? "#8C2148" : "#5A404E",
                        marginLeft: "auto",
                      }}
                    >
                      {openSubMenu[index] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Icon>
                  </ListItemIcon>
                )}
              </ListItem>

              {menuItem.subItems && menuItem.subItems.length > 0 && (
                <Collapse in={openSubMenu[index]} timeout={0} unmountOnExit>
                  <List
                    className={classes.branchList}
                    sx={{
                      marginLeft: 3,
                      padding: 0,
                    }}
                  >
                    {menuItem.subItems.map((subItem) => (
                      <ListItem
                        key={subItem.text}
                        button
                        className={`${classes.branchListItem} ${
                          location.pathname.includes(subItem.path)
                            ? classes.activeOption
                            : ""
                        }`}
                        onClick={() => handleNavigation(subItem.path)}
                      >
                        <ListItemIcon
                          className={
                            location.pathname.includes(subItem.path)
                              ? classes.activeOption
                              : classes.icon
                          }
                        ></ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={subItem.text}
                          className={
                            location.pathname.includes(subItem.path)
                              ? classes.activeOption
                              : classes.menuText
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

SideMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
};

SideMenu.defaultProps = {
  options: [],
};

export default React.memo(SideMenu);
