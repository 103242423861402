import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "40% !important",
    textAlign: "center",
    height: "auto",
    backgroundColor: "white",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
    "& .MuiDialogTitle-root":{
      display: "none"
    },
    "& .MuiDialogContent-root":{
      padding: 0
    }
  },
  popUp: {
    margin: "auto",
  },
  popupTitle:{
    backgroundColor: theme.palette.Primary.hoverButton,
    color: "white",
    padding: theme.spacing(5),
    marginBottom: theme.spacing(8), 
  },
  icon:{
    color: theme.palette.Primary.hoverButton,
    fontSize: "xxx-large",
    marginBottom: theme.spacing(8), 
  },
  message:{
    marginBottom: theme.spacing(8), 
  }
}));

export default useStyles;
