import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    margin: theme.spacing(3, 0, 5, 0),
    borderRadius: 8
  },
  infoContainer: {
    maxWidth: 400,
    display:"flex !important",
    justifyContent: 'flex-start'
  },
  title:{
    width:"50%"
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight:500,
    fontSize: 14
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  inprogress:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  reported: {
    color: theme.palette.Error.main,
    fontWeight:500,
    fontSize: 14
  },
  review: {
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  submitted:{
    color: theme.palette.status.open,
    fontWeight:500,
    fontSize: 14
  },
  added:{
    color: "#7B7B7B",
    fontWeight:500,
    fontSize: 14
  },
  pending:{
    color: "#F7931D",
    fontWeight:500,
    fontSize: 14
  },
  default:{
    color:theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  }
}));

export default useStyles;
