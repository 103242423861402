import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        height: '95%',
        borderRadius: 8,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    },
    graphTitle: {
        fontSize: 18,
        fontWeight: 400,
        margin: theme.spacing(2, 0)
    },
    chart:{
        height: "80%",
        width: "100%"
    },
    doughnutChart:{
        height: "90%",
        width: "95%"
    }
}));

export default useStyles;
