import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    audited: {
        color: "#024FE4",
        fontWeight:500,
        fontSize: 14
      },
      assigned: {
        color: theme.palette.Success.warning,
        fontWeight:500,
        fontSize: 14
      },
      approved: {
        color: theme.palette.status.approved,
        fontWeight:500,
        fontSize: 14
      },
      verified: {
        color: theme.palette.status.verified,
        fontWeight:500,
        fontSize: 14
      },
      review:{
        color: theme.palette.status.review,
        fontWeight:500,
        fontSize: 14
      },
      pending: {
        color: "#F7931D",
        fontWeight:500,
        fontSize: 14
      },
      inprogress:{
        color: theme.palette.status.pendingApproval,
        fontWeight:500,
        fontSize: 14
      },
      submitted:{
        color: theme.palette.status.open,
        fontWeight:500,
        fontSize: 14
      },
      added:{
        color: theme.palette.icon.blue,
        fontWeight:500,
        fontSize: 14
      },
}));

export default useStyles;