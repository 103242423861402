export const firstPlan = ["6 Users","1 Country","2 Facility","Disclosure Reporting","One Standard","2 Auditors","Monthly, Quarterly and Yearly\n Reporting Cycles","1 ERP System","No External reviewer","Zero IOT Device Connections"]
export const secondPlan = [
  "8 Users",
  "1 Country",
  "4 Facility",
  "Disclosure Reporting",
  "One Standard",
  "2 Auditors",
  "Monthly, Quarterly and Yearly Reporting Cycles",
  "1 ERP System",
  "1 External reviewer",
  "25 IOT Device Connections",
];
