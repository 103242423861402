import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';

export const goalSettingsState = {
  addVision: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getVision: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addMission: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  editMission: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getMission: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getInitiativeDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addInitiative: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  listInitiative: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addTasks: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getTasksList: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  saveTaskTracking: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addTargetActual: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getTrackingCycleAssessmentPeriod: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  editTask: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
};

const goalSettingsActions = {
  goalSettings: handleActions(
    {
      [ActionTypes.ADD_VISION]: (state, { payload }) =>
        immutable(state, {
          addVision: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_VISION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addVision: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_VISION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addVision: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_VISION]: (state, { payload }) =>
        immutable(state, {
          getVision: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_VISION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getVision: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_VISION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getVision: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ADD_MISSION]: (state, { payload }) =>
        immutable(state, {
          addMission: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_MISSION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addMission: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_MISSION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addMission: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.EDIT_MISSION]: (state, { payload }) =>
        immutable(state, {
          editMission: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.EDIT_MISSION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          editMission: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.EDIT_MISSION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editMission: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_MISSION]: (state, { payload }) =>
        immutable(state, {
          getMission: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_MISSION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getMission: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_MISSION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getMission: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.LIST_INITIATIVE]: (state, { payload }) =>
        immutable(state, {
          listInitiative: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_INITIATIVE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          listInitiative: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_INITIATIVE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          listInitiative: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ADD_INITIATIVE]: (state, { payload }) =>
        immutable(state, {
          addInitiative: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_INITIATIVE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addInitiative: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_INITIATIVE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addInitiative: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.RESET_ADD_INITIATIVE_STATUS]: (state, { payload }) =>
        immutable(state, {
          addInitiative: {
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.GET_INITIATIVE_DETAILS]: (state, { payload }) =>
        immutable(state, {
          getInitiativeDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_INITIATIVE_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getInitiativeDetails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_INITIATIVE_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getInitiativeDetails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.SAVE_TASK_TRACKING]: (state, { payload }) =>
        immutable(state, {
          saveTaskTracking: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.SAVE_TASK_TRACKING_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          saveTaskTracking: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.SAVE_TASK_TRACKING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          saveTaskTracking: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ADD_TASKS]: (state, { payload }) =>
        immutable(state, {
          addTasks: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_TASKS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addTasks: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_TASKS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addTasks: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_ADD_TASK_STATUS]: (state, { payload }) =>
        immutable(state, {
          addTasks: {
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.GET_TASKS_LIST]: (state, { payload }) =>
        immutable(state, {
          getTasksList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_TASKS_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getTasksList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_TASKS_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getTasksList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ADD_TARGET_ACTUAL]: (state, { payload }) =>
        immutable(state, {
          addTargetActual: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_TARGET_ACTUAL_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addTargetActual: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_TARGET_ACTUAL_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addTargetActual: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getTrackingCycleAssessmentPeriod: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getTrackingCycleAssessmentPeriod: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getTrackingCycleAssessmentPeriod: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.EDIT_TASK]: (state, { payload }) =>
        immutable(state, {
          editTask: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.EDIT_TASK_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          editTask: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.EDIT_TASK_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editTask: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_GOALS]: (state, { payload }) =>
        immutable(state, {
          addVision: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          getVision: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          addMission: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          editMission: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          getMission: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          getTasksList: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          saveTaskTracking: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          addTargetActual: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          editTask: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
        }),
    },
    goalSettingsState
  ),
};


export default goalSettingsActions;
