import * as yup from 'yup';

export const schemeValidation = yup.object({
        facility: yup.string().required("Facility is required"),
        year: yup.number().required("Year is required").positive("Year must be a positive number"),
        assessmentPeriod: yup.string().required("Assessment Period is required"),
        trainingTitle: yup.string().required("Training Title is required"),
        date: yup.date().required("Date is required"),
        trainingMethods: yup.string().required("Training Methods is required"),
        attended: yup.string().required("Attended is required"),
        trainingHours: yup.number().required("Training Hours is required").positive("Training Hours must be a positive number"),
        trainingProfile: yup.string().required("Training Profile is required"),
        details: yup.string().required("Details is required"),
        isTrainingDuringPaidHours: yup.string().required("Please indicate your choice by selecting either Yes or No"),
        isTrainingFreeOfCharge: yup.string().required("Please indicate your choice by selecting either Yes or No"),
        isTrainingMandatory: yup.string().when('isTrainingFreeOfCharge', {
          is: 'No',
          then: yup.string().required('Please indicate your choice by selecting either Yes or No'),
          otherwise: yup.string()
      }),
        areEmployeesCompensated: yup.string().when('isTrainingFreeOfCharge', {
          is: 'No',
          then: yup.string().required('Please indicate your choice by selecting either Yes or No'),
          otherwise: yup.string()
      }),
});