import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    height: "100vh",
    position: "relative",
    background: `linear-gradient(to right, #D9CAD2 30vw, #fff 30vw)`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  login: {
    maxHeight: "100vh",
  },
  leftSide: {
    width: "250px",
    backgroundColor: "#D9CAD2",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightSide: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "20%",
    left: 0,
    right: "10%",
    [theme.breakpoints.down("sm")]: {
      position: "static",
      margin: "20px 0",
      top: "auto",
      left: "auto",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      top: "15%",
      left: "10vw",
    },
    [theme.breakpoints.down("xl")]: {
      top: "15%",
    },
  },
  backgroundImage: {
    width: "100vw",
    height: "50%",
    paddingRight: "10%",
    left:0,
    backgroundSize: "contain", 
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      height: "30%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "65vw",
      height: "35%",
      backgroundSize: "contain",
      paddingRight: "5%"
    },
  },
  loginCard: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 30,
    backgroundSize: "contain", 
    backgroundPosition: "center",
    [theme.breakpoints.down("xl")]: {
      height: "30px",
      width: "20vw",
      marginBottom: 15,
      backgroundSize: "contain",
    },
  },
  button: {
    marginBottom: 15,
    background: theme.palette.Primary.background,
    width: "100%",
    borderRadius: 6,
    textTransform: "capitalize",
    fontWeight: 300,
    // marginTop: theme.spacing(5),
    height: "40px",
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.light,
    },
    [theme.breakpoints.down("xl")]: {
      height: "35px",
      // marginTop: theme.spacing(5),
    }
  },
  forgotLink: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: theme.spacing(2),
    color: theme.palette.login.forgotLink,
    textDecoration: "none",
    fontWeight: "300",
    fontSize: "14px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "12px",
    },
  },
  newUser: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(2),
    color: theme.palette.login.forgotLink,
    textDecoration: "none",
    fontWeight: "300",
    fontSize: "14px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "12px",
    },
  },
  footer: {
    fontWeight: 400,
    fontSize: "14px",
    position: "absolute",
    bottom: 0,
    left: 0,
    marginBottom: 15,
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(1),
    // background: `linear-gradient(to right, #D9CAD2 30%, #fff 30%)`,
  },
  loginBox: {
    [theme.breakpoints.down("xl")]: {
      height: "200px",
      width: "20vw"
    },
  },
  alertBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  main:{
    width: "30vw",
    [theme.breakpoints.down("xl")]: {
      minHeight: "200px",
      width: "15vw",
      position: "absolute",
      top: "10%",
      right: "10%"
    },
  },
  loginText:{
    textAlign: "center",
    color: theme.palette.Primary.background,
    fontWeight: 600
  },
  focusedLabel:{
    '&.MuiInputLabel-root.MuiInputLabel-root.Mui-focused': {
      display: 'none',
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor: theme.palette.text.dark,
    }
  },
  userBox:{
    width: "-webkit-fill-available"
  },
  select:{
    height: "50px",
    marginBottom: theme.spacing(1),
    '& fieldset': {
      borderColor: 'black !important', 
    }
  },
  input:{
    "& .MuiInputBase-input":{
    height: "15px !important",
    marginBottom: theme.spacing(1),
    }
  },
  label:{
    color: 'rgba(0, 0, 0, 0.4)',
    letterSpacing: 0
    },
    logo:{
      [theme.breakpoints.down("xl")]: {
        height: "40px",
      },
    },
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)" /* semi-transparent background */,
      backdropFilter: "blur(5px)" /* apply blur filter */,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
  
    content: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "6px",
      width: "30%",
      display: "flex",
      flexDirection:"column",
      alignItems: "center",
      justifyContent: "center",
    },
    btn: {
      padding: "10px 20px",
      border: "none",
      borderRadius: "6px",
      background: "#8C2148",
      color: "#fff",
      fontSize: "16px",
      cursor: "pointer",
      height: "35px",
      "&:hover": {
        background: "#8C2148",
      },
    },
  
    backdrop: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(0, 0, 0, 0.5)" /* semi-transparent black background */,
      zIndex: 999 /* higher z-index than the modal */,
      pointerEvents: "none" /* allow clicks to pass through */,
    },
    title: {
      fontSize: 13,
      fontWeight: 500,
      color: theme.palette.text.primary,
      whiteSpace: "nowrap",
      padding: "0px 2px 2px 4px",
  },
  caution:{
    width: '20vw',
    backgroundColor: '#fcd1d687',
    borderLeft: '5px solid #f66a69',
    borderRadius: '4px',
    padding: '6px'
  },
  cautionTextMain:{
    textAlign: "start",
    color: theme.palette.text.dark,
    fontWeight: 200,
    fontSize: 'small', 
    paddingBottom:'5px'
  },
  cautionText:{
    textAlign: "justify",
    color: theme.palette.text.dark,
    fontWeight: 200,
    fontSize: 'small' 
  },
  
}));

export default useStyles;