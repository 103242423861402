import React, { useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import useStyles from "./styles";

const AddButton = ({count,setCount}) => {
    const classes = useStyles();
  const handleAddClick = () => {
    setCount(1);
  };

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    setCount((prevCount) => prevCount - 1);
  };

  return (
    <>
      {count === 0 ? (
        <Button
          className={classes.buttonPrimary}
          variant="contained"
          onClick={handleAddClick}
        >
          Add +
        </Button>
      ) : (
        <ButtonGroup>
          <Button className={classes.buttonPrimary} onClick={handleDecrement}>
            -
          </Button>
          <Button className={classes.buttonPrimary}>{count}</Button>
          <Button className={classes.buttonPrimary} onClick={handleIncrement}>
            +
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default AddButton;
