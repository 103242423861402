import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { EmployeeHireAndTurnover, EmployeeHireAndTurnoverByAge} from "../EmissionTable/TableColumns";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../redux/constants";
import { ApprovalEmployeeHireAndTurnover, ApprovalEmployeeHireAndTurnoverByAge } from "../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusDetails/ApprovalTableColumns";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { deleteEmissions, resetEmissionDelete } from "../../../redux/actions";
import EmissionDeletePrompt from "../../EmissionDeletePrompt";
import { getCookie } from "../../../services/cookie";

const EmployeeHireTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    backToEmission,
    editOff,
    reportTable,
    isOnlyAdmin,
    period,
    isDeleteActionRequired
  } = props;


  const currentPath = window.location.pathname;
  const approvalTable = currentPath.includes('approval') 
  const role = useSelector((state) => state.auth.role);
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );
  const [displayWarning, setDisplayWarning] = useState(false);
  const [emissionId, setEmissionId] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };
  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Topic deleted successfully",
        {
          variant: "success",
        }
      );
      dispatch(resetEmissionDelete());
      
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar("We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetEmissionDelete());
    }
    setDisplayWarning(false)
  }, [deleteEmissionData, enqueueSnackbar,  dispatch]);
  
  const onSelectEmissionData = (rowData) => {
    const currentFilter = encodeURI(
      `?${rowData.gender ? `&distribution=${rowData.gender}` : ""}${rowData.employee_classification ? `&classification=${rowData.employee_classification}` : ""}`
    );
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}&distribution=${rowData.gender}&classification=${rowData.employee_classification}`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };
  
  const onSelectData = (rowData) => {
    let ageFilter = rowData.age;
    if(rowData.age === "Under 30"){
      ageFilter = "under_30";
    }else if(rowData.age === "Between 30 and 50"){
      ageFilter = "between_30_50"
    }else{
      ageFilter = "over_50"
    }
    const currentFilter = encodeURI(
      `?${ageFilter ? `&distribution=${ageFilter}` : ""}${rowData.employee_classification ? `&classification=${rowData.employee_classification}` : ""}`
    );
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}&distribution=${ageFilter}&classification=${rowData.employee_classification}`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };

  const getStatus = (status) => {
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    }else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };
  const onEditEmissionData = (e, emission) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/emissions/edit/employees_hire_and_turnover/${emission}`);
  };
  const getGenderDistribution = () =>
  emissionData && emissionData?.gender_distribution?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      turnover_rate : item.turnover_rate ? `${item.turnover_rate}%` : "",
      period: period,
      action: (
        !readOnlyMode &&
        <Box className={classes.actionContainer}>
          {item.is_able_to_update === true &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired && ((item.status === "added" && role.includes('facility_manager')) || (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager')) || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
              // onClick={(e) => onEditEmissionData(e, item.id)}
            >
              <DeleteOutlineIcon />
            </IconButton>}
        </Box>
      ),
    }));

    const getByAgeDistribution = () =>
    emissionData && emissionData?.age_distribution?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      turnover_rate : item.turnover_rate ? `${item.turnover_rate}%` : "",
      action: (
        !readOnlyMode &&
        <Box className={classes.actionContainer}>
          {item.status == "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired && ((item.status === "added" && role.includes('facility_manager')) || (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager')) || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
              // onClick={(e) => onEditEmissionData(e, item.id)}
            >
              <DeleteOutlineIcon />
            </IconButton>}
        </Box>
      ),
    }));

  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Employees Hire And Turnover by Gender
              </span>
              </Typography>
          <CeroTable
            columns={(approvalTable ? ApprovalEmployeeHireAndTurnover : EmployeeHireAndTurnover) || []}
            data={getGenderDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={approvalTable ? '#' :onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          />
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Employees Hire And Turnover by Age
              </span>
              </Typography>
          <CeroTable
            columns={(approvalTable ? ApprovalEmployeeHireAndTurnoverByAge : EmployeeHireAndTurnoverByAge) || []}
            data={getByAgeDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={approvalTable ? '#' : onSelectData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
      {displayWarning && (
          <EmissionDeletePrompt
          isOpen={displayWarning}
          onClose={() => setDisplayWarning(false)}
          onConfirm={onConfirmDelete}
          setOpenDeletePrompt = {displayWarning}
        />
        )}
    </>
    
  );
};

export default EmployeeHireTable;
