import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import CeroTable from "../../components/CeroTable";
import CeroButton from "../../components/CeroButton";
import {
  getOffsetCountries,
  getPrograms,
  getProgramTypes,
  listCarbonOffsetPrograms
} from "../../redux/actions";
import { STATUS } from "../../redux/constants";

import useStyles from "./styles";
import { getCookie } from "../../services/cookie";
import CeroPopUp from "../../components/CeroPopUp";
import Filter from "../Reports/Filter";
import ReportTable from "../Reports/ReportTable";
import CloseIcon from "@mui/icons-material/Close";
import CreateCarbonOffsetPopup from "./CreateCarbonOffsetPopup";
import CeroSelect from "../../components/CeroSelect";
import CarbonOffsetTable from "./CarbonOffsetTable";
import CeroAutoComplete from "../../components/CeroAutoComplete";

const CarbonOffset = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const [addProgramPopup, setAddProgramPopup] = useState(false);
  const [location, setLocation] = useState(null);
  const [program, setProgram] = useState(null);
  const programsList = useSelector(
    (state) => state.carbonOffset.listCarbonOffsetPrograms.data
  );
  const programsListStatus = useSelector(
    (state) => state.carbonOffset.listCarbonOffsetPrograms.status
  );


  const programTypes = useSelector(
    (state) => state.carbonOffset.getProgramTypes.data
  );
  const programTypeOptions = programTypes.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const onSelectAuditSummaryData = (row) => {
    navigate(`details/${row.id}`);
  };

  const handleProgramChange = (event) => {
    setProgram(event.target.value);
  };

  useEffect(() => {
    dispatch(getOffsetCountries())
    dispatch(listCarbonOffsetPrograms(location,program));
    dispatch(getProgramTypes());
  }, [dispatch, location, program]);

  const countryData = useSelector(
    (state) => state.carbonOffset.getOffsetCountries.data
  );

  const countryOptions = Object.keys(countryData).map((item) => ({
    id: countryData[item],
    label: item,
  }));

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.topContainer}>
          <Grid spacing={2} className={classes.filterContainer}>
            <Grid item style={{ width: "90%"}}>
          <CeroAutoComplete
              classes={{ container: classes.autoComplete,  root: classes.textField }}
              id="location"
              label="Location"
              fullWidth
              selectedValue={location}
              onChange={(e, value) => setLocation(value ? value.id : undefined)}              
              options={countryOptions}
            />
            </Grid>
            <Grid item style={{ width: "90%"}}>
            <CeroSelect
              classes={{ container: classes.selectContainer,  root: classes.textField }}
              id="program_type"
              name="program_type"
              label="Program Type"
              fullWidth
              options={programTypeOptions}
              selectedValue={program}
              onChange={(e)=>handleProgramChange(e)}
            />
            </Grid>
          </Grid>
            <CeroButton
              buttonText="Add Programs"
              className={classes.buttonPrimary}
              onClick={() => setAddProgramPopup(true)}
              disabled={readOnlyMode}
            />
        </div>
        {programsListStatus === STATUS.SUCCESS ? (
          <Container className={classes.tableContainer}>
            <CarbonOffsetTable
              data={programsList}
              onSelectRow={onSelectAuditSummaryData}
            />
          </Container>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {programsListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : programsListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
        <CreateCarbonOffsetPopup
          isOpen={addProgramPopup}
          onClose={() => setAddProgramPopup(false)}
        />
      </Container>
    </DashboardLayout>
  );
};

export default CarbonOffset;
