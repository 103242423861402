import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "fit-content",
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: 18,
    textTransform: "capitalize",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
  },
  tableContainer: {
    width: "60%",
    margin: "auto",
    "& .MuiTableCell-body": {
      width: "auto",
      margin: "auto",
    },
  },
  tableBodyCell: {
    width: "60% !important",
    margin: "auto",
  },
  closeIcon: {
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
  },
  underline: {
    height: 1,
    backgroundColor: theme.palette.Primary.main,
    marginBottom: 25,
  },
  popupHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(3),
  },
  loading:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& .MuiCircularProgress-root':{
        color: theme.palette.Primary.background,
    }
  },
  noDataWarning: {
    textAlign: "center",
    background: "white",
    verticalAlign: "center"
  },
  tableHeader: {
    backgroundColor: "#D0C5CA",
    fontWeight: "bold",
    textAlign: "center",
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
}));

export default useStyles;
