import { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'

import { clearListEmissionAuditTrails, listEmissionAuditTrails } from '../../../redux/actions';
import AuditTrailCell from './AuditTrailCell';
import useStyles from "./styles";
import { STATUS } from '../../../redux/constants';

const ListAuditTrails = ({emissionId, isAuditor, company}) => {
    
    const classes = useStyles();
    const dispatch = useDispatch();

    const auditList = useSelector((state) => state.emission.listAuditTrails.data);

    const auditListStatus =  useSelector((state) => state.emission.listAuditTrails.status);

    useEffect(() => {
        dispatch(clearListEmissionAuditTrails());
        dispatch(listEmissionAuditTrails({ emissionId, isAuditor, company }));
    }, [dispatch, emissionId, isAuditor, company]);

    return <Box className={classes.listContainer}>
        <Box className={classes.commentContainer} >
            {auditListStatus=== STATUS.SUCCESS?auditList.map((audit, index) => (
                    <AuditTrailCell
                        key={index}
                        name={audit.user}
                        action={audit.action}
                        imageUrl={audit.image_url}
                        story={audit.story}
                        time={audit.performed_on}
                    />
                )
            ):  <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {auditListStatus === STATUS.RUNNING
                ? <CircularProgress/>
                : auditListStatus === STATUS.ERROR
                ? "We couldn't process your request. Please try again later"
                : ""}
            </Typography>
          </Box>}
            {!auditList.length && auditListStatus=== STATUS.SUCCESS && (
                <Typography variant="h7" component="span"> No Audit trails found </Typography>
            )}
        </Box>
    </Box>
};

export default ListAuditTrails;
