import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  CardHeader,
  CardContent,
  Card,
} from "@mui/material";

import DashboardLayout from "../../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroButton from "../../../components/CeroButton";
import tradeProfileIcon from "../../../assets/icons/tradeProfileIcon.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getCookie } from "../../../services/cookie";
import { getTradeAccount } from "../../../redux/actions";
import TradeAccountSettings from "./TradeAccountSettings";

const ProfileSettings = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const role = getCookie('role');
  const accountData = useSelector(state => state.trade.getTradeAccount.data);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    dispatch(getTradeAccount())
  }, [dispatch])

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
    <ArrowBackIosIcon />
      Back
    </div>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={4}>
            <Card className={classes.card}>
            <Typography variant="h5" className={classes.name}>{accountData?.user_details?.name}</Typography>
            <img src={tradeProfileIcon} alt="Logo" height="100" width="100" />
              <CardContent>
                <CeroButton
                buttonText="Upload New Photo"
                variant="contained"
                className={classes.button}
                />                  
              </CardContent>
              <CardContent>
                <Typography variant="body2" color="textSecondary" style={{background: '#F4F4F4', padding: 5}}>
                Uploads must be in either jpg or png formats and less than 1 MB.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5" className={classes.editTitle}>
              Edit Profile
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                className={classes.tabs}
                onChange={handleChange}
                aria-label="basic tabs example"
                style={{backgroundColor: "#BAC6C6", paddingLeft: 10}}
              >
                <Tab label="Profile" id="profile" />
              </Tabs>
            </Box>
            {value === 0 && <TradeAccountSettings />}
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default ProfileSettings;
