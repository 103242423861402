import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "fit-content",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 140px)",
  },
  actionContainer: {
    display: "flex",
  },
  button: {
    backgroundColor: theme.palette.Secondary.main,
  },
  editIcon: {
    marginLeft: theme.spacing(3),
    fontSize: "large",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: 140,
    height: 37,
    fontWeight: 400,
    marginBottom: theme.spacing(5),
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    color: theme.palette.icon.blue,
  },
  linkButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
    fontWeight: 400,
  }
}));

export default useStyles;
