import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "50%",
    "& .MuiPaper-root.MuiDialog-paper": {
      width: "40vw",
      margin: 80,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 15,
    width: "100%",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
      border: "2px solid",
    },
    marginLeft: theme.spacing(5),
  },
  editBox: {
    display: "flex",
    alignItems: "center",
  },
  textField: {
    width: "30%",
    marginRight: theme.spacing(3),
    "& .MuiInputBase-root .MuiOutlinedInput-root": {
      height: "30px !important",
    },
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  popupTitle: {
    fontWeight: 500,
    whiteSpace: "nowrap",
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  warningBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableContainer:{
    marginBottom: theme.spacing(5),
  },
  noDataWarning: {
    textAlign: "center",
    background: "white",
    verticalAlign: "center"
  },
  tableHeader: {
    backgroundColor: "#D0C5CA",
    fontWeight: "bold",
    textAlign: "center",
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
}));

export default useStyles;
