import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
buttonPrimary: {
    background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    marginLeft: theme.spacing(116),
  },

}));

export default useStyles;