import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(4),
        '@media (min-width: 1200px)': {
            maxWidth: "none"
          },
    },
    loader: {
        width: "100%",
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
}));

export default useStyles;