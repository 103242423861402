import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    containercard:{
        paddingLeft:0,
        paddingRight:0,
        "& .infinite-scroll-component__outerdiv > .infinite-scroll-component":{
            padding:"2px 8px"
        },
        "& .infinite-scroll-component__outerdiv > .infinite-scroll-component> .MuiPaper-root":{
            border: "1px solid #5a404e"
        }
    },
    containercardTable:{
      padding: theme.spacing(0,2),
      backgroundColor: "#EAE2E6",
      "& .MuiPaper-root.MuiTableContainer-root":{
        border: "1px solid black"
      }
    },
    tableContainer:{
      border: "1px solid black"
    },
    signalPending:{
      backgroundColor: theme.palette.status.pendingApproval,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1) 
    },
    signalAudited:{
      backgroundColor: theme.palette.status.audited,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1) 
    }
}));

export default useStyles;