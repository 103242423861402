import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        '@media (min-width: 1200px)': {
            maxWidth: "none"
          }
    },
    count:{
        color: theme.palette.Other.titleText,
        fontWeight: 700,
        textAlign: "center",
        paddingTop: theme.spacing(5)
    },
    card:{
        padding: theme.spacing(5),
        '& .MuiPaper-root-MuiCard-root':{
            borderRadius: 8,
            boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.3)",
        },
        '& .MuiCardContent-root:last-child':{
            padding: 'theme.spacing(5) !important'
        },
    }
}));

export default useStyles;