import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 140px)',
        width: "100%",
        margin: "auto",
        marginTop: theme.spacing(5)
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: 20,
        alignItems: 'center',
        cursor: "pointer"
    },
    activeStatus:{
        color: theme.palette.status.closed,
        fontWeight:500,
        fontSize: 14
    },
    inActiveStatus:{
        color:  '#E81111',
        fontWeight:500,
        fontSize: 14
    },
    actions:{
        display: "flex",
        justifyContent: "space-evenly",
        color: theme.palette.icon.blue,
    },
    linkButton: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer',
        fontWeight: 400,
      },
      icon: {
        marginRight: '5px',
        padding: 4
      },
      loader: {
        width: "100%",
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
}));

export default useStyles;