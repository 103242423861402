import { Typography, Box, Container, Grid } from "@mui/material";
import React from "react";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";

const EconomicImpactApproval = (props) => {
  const classes = useStyles();
  const { emissionData } = props;

  return (
    <Container className={classes.mainContainer}>
      {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Is there any significant Infrastructure investment:"
              value={item.any_significant_infrastructure || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Enter the details of the infrastructure investments:"
              value={item.infrastructure_investment || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Nature of Investment:"
              value={item.nature_of_investments || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />

            <CeroItemPair
              title="Length of Investment:"
              value={item.length_of_investments || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Does it have any direct impacts on communities and local economies?:"
              value={item.any_direct_impact_on_communities || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Details for Positive and Negative Impact:"
              value={item.details_of_indirect_impact || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />

<CeroItemPair
              title="Does it have any indirect impacts on communities and local economies?:"
              value={item.any_indirect_impact || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Details for Positive and Negative Impact:"
              value={item.outcomes_of_legal_action || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Mitigation measures taken for negative impacts"
              value={item.migration_for_negative_impact || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
          </Container>
))}
    </Container>
  );
};

export default EconomicImpactApproval;
