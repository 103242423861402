import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import useStyles from "./styles";
import { useSnackbar } from "notistack";
import CeroButton from "../../../../components/CeroButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEmissions,
  resetGetAllEmissionStatus,
} from "../../../../redux/actions";
import {
  sectionConfig,
  topicNames,
} from "../../../EmissionList/EmissionTable/TableColumns";
import { STATUS } from "../../../../redux/constants";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ExcelIcon from "../../../../assets/icons/ExcelIcon.png";
import PrintIcon from "../../../../assets/icons/PrintIcon.png";
import { getCookie } from "../../../../services/cookie";

function EmissionPDFExport({ emissionsArray, selectedYear, facility, period }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const allEmissionsData = useSelector(
    (state) => state.emission.getAllEmissions.data
  );
  const allEmissionsDataStatus = useSelector(
    (state) => state.emission.getAllEmissions.status
  );
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const countryFacilityDataObject = {};
  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }

  const facilitiesDataApi = facility?.map(
    (item) => countryFacilityDataObject[item]
  );
  // const facility = facilityDataApi
  useEffect(() => {
    const payload = {
      emissionsArray,
      selectedYear,
      facilitiesDataApi,
      period
    };
    dispatch(getAllEmissions(payload));
  }, []);

  function truncatedNames(inputString) {
    if (inputString.length <= 31) {
      return inputString;
    }
    const words = inputString.split(" ");
    const truncatedWords = words.reduceRight((acc, word) => {
      if (acc.join(" ").length + word.length <= 31) {
        acc.unshift(word);
      }
      return acc;
    }, []);

    return truncatedWords.join(" ");
  }

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    if (allEmissionsDataStatus === STATUS.ERROR) {
      enqueueSnackbar("Error loading data", { variant: "error" });
      return;
    } else if (
      !allEmissionsData ||
      allEmissionsData.every(
        (sectionData) =>
          sectionData[Object.keys(sectionData)[0]].emissions.length === 0
      )
    ) {
      enqueueSnackbar("No data to export", { variant: "error" });
      return;
    }

    allEmissionsData?.forEach((sectionData) => {
      const sectionKey = Object.keys(sectionData)[0];
      console.log('sectionKey', sectionKey)
      const emissions = sectionData[sectionKey].emissions;
      const config = sectionConfig[sectionKey];

      if (emissions.length > 0) {
        const title = `Table for ${config?.title}`;
        const headers = [config?.headers];
        const body = emissions?.map((emission) =>
          config?.keys.map((key) => emission[key] || (emission['components']? emission['components'][key]:null))
        );

        let content = {
          startY: 50,
          head: headers,
          body: body,
          theme: "grid",
          headStyles: { fontSize: 8 },
          bodyStyles: { fontSize: 8 },
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.addPage();
      }
    });

    doc.save("report.pdf");
  };

  const exportToXLSX = () => {
    const wb = XLSX.utils.book_new();

    if (allEmissionsDataStatus === STATUS.ERROR) {
      enqueueSnackbar("Error loading data", { variant: "error" });
      return;
    } else if (
      !allEmissionsData ||
      allEmissionsData.every(
        (sectionData) =>
          sectionData[Object.keys(sectionData)[0]].emissions.length === 0
      )
    ) {
      enqueueSnackbar("No data to export", { variant: "error" });
      return;
    }

    allEmissionsData?.forEach((sectionData, index) => {
      const sectionKey = Object.keys(sectionData)[0];
      const emissions = sectionData[sectionKey].emissions;
      const config = sectionConfig[sectionKey];
      const title =
      (config && config?.title?.length > 31) ? truncatedNames(config?.title) : config?.title;
      const keys = config?.keys;

      // if (emissions.length > 0) {
      //   const worksheetData = emissions.map((emission) =>
      //     keys.map((key) => emission[key] || emission['components'][key])
      //   );

      if (emissions.length > 0) {
        const worksheetData = emissions.map((emission) => {
          const rowData = keys && keys.map((key) => {
            if (emission[key]) {
              return emission[key];
            }
            else if (emission.components && emission.components[key]) {
              return emission.components[key];
            }
            else {
              return "";
            }
          });
          return rowData;
        });
        
        worksheetData.unshift(config?.headers);
        const ws = XLSX.utils.aoa_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(wb, ws, title);
      }
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // Save the workbook as an Excel file
    const excelBlob = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBlob], { type: fileType });
    saveAs(dataExcel, "report.xlsx");
  };

  return (
    <Box>
      {allEmissionsDataStatus === STATUS.RUNNING || allEmissionsDataStatus === STATUS.IDLE ? (
        <span>Loading options...</span>
      ) : (
        <Box>
          <IconButton onClick={exportToXLSX} disabled={readOnlyMode}>
            <img src={ExcelIcon} alt="Logo" width="25" height="25" />
          </IconButton>
          <IconButton onClick={exportToPDF} disabled={readOnlyMode}>
            <img src={PrintIcon} alt="Logo" width="25" height="25" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default EmissionPDFExport;
