import {
  Autocomplete,
  Box,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useEffect, useState } from "react";
import CeroPopUp from "../../../components/CeroPopUp";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CeroButton from "../../../components/CeroButton";
import {
  addMission,
  addVision,
  getVision,
  getMission,
  editMission,
  resetGoals,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import { getCookie } from "../../../services/cookie";

const VisionAndMission = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [showSuggestionBox, setShowSuggestionBox] = useState(true);
  const [randomSuggestions, setRandomSuggestions] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingMissionId, setEditingMissionId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isVisionPopup, setIsVisionPopup] = useState(false);
  const [visionText, setVisionText] = useState("");
  const [editVision, setEditVision] = useState(false);

  const visionData = useSelector((state) => state.goalSettings.getVision.data);
  const missionData = useSelector((state) => state.goalSettings.getMission.data);
  const addVisionStatus = useSelector((state) => state.goalSettings.addVision.status);
  const addMissionStatus = useSelector((state) => state.goalSettings.addMission.status);
  const editMissionStatus = useSelector((state) => state.goalSettings.editMission.status);
  const countryData = useSelector((state) => state.listings.getOrganizationCountry.data.country_data);
  const role = getCookie('role');
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const countryOptions = Object.keys(countryData).map((item) => ({
    key: countryData[item],
    value: item,
  }));

  useEffect(() => {
    dispatch(getVision());
    dispatch(getMission());
    dispatch(resetGoals())
  }, [dispatch]);


  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleCloseVision = () => {
    setIsVisionPopup(false);
  }

  const suggestionList = [
    "Reduce Scope 1 & Scope 2 emissions",
    "Improve energy efficiency",
    "Reduce product waste generation",
    "Strengthen ethical business practices",
    "Increase diversity and inclusion within the workforce",
    "Reduce water consumption",
    "Enhance transparency and disclosure practices",
    "Enhance employee well-being",
  ];

  useEffect(() => {
    const shuffledSuggestions = suggestionList.sort(() => Math.random() - 0.5);
    setRandomSuggestions(shuffledSuggestions);
  }, []);

  useEffect(() => {
    if (addVisionStatus === STATUS.SUCCESS) {
      dispatch(resetGoals())
      // enqueueSnackbar("Vision added successfully", {variant: "success"});
      setIsVisionPopup(false);
      dispatch(getVision())
    } else if (addVisionStatus === STATUS.ERROR) {
      dispatch(resetGoals())
      enqueueSnackbar(addVisionStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error"});
    }
  }, [addVisionStatus, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (addMissionStatus === STATUS.SUCCESS) {
      dispatch(resetGoals())
      enqueueSnackbar("Mission added successfully", {variant: "success"});
      setIsPopupOpen(false);
      setIsEditing(false)
      dispatch(getMission())
    } else if (addMissionStatus === STATUS.ERROR) {
      dispatch(resetGoals())
      enqueueSnackbar(addMissionStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error"});
    }
  }, [addMissionStatus, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (editMissionStatus === STATUS.SUCCESS) {
      dispatch(resetGoals())
      enqueueSnackbar("Mission updated successfully", {variant: "success"});
      setIsPopupOpen(false);
      dispatch(getMission())
    } else if (editMissionStatus === STATUS.ERROR) {
      dispatch(resetGoals())
      enqueueSnackbar(editMissionStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error"});
    }
  }, [editMissionStatus, dispatch, enqueueSnackbar]);

  const handleSuggestionClick = (suggestion) => {
    setTextFieldValue(suggestion);
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleVisionChange = (event) => {
    setVisionText(event.target.value);
  }

  const onSaveMission = () => {
    if (isEditing) {
      dispatch(editMission(editingMissionId, textFieldValue, selectedCountry));
      setTextFieldValue("")
      setSelectedCountry("")
    } else {
      dispatch(addMission(textFieldValue, selectedCountry));
      setTextFieldValue("")
      setSelectedCountry("")
      setIsEditing(false)
    }
  };

  const onSaveVision = () => {
    dispatch(addVision(visionText))
  }

  const handleCloseEdit = () => {
    setIsEditPopup(false);
  };

  const handleCloseSuggestionBox = () => {
    setShowSuggestionBox(false);
};

  const addMissionPopup = () => {
    return (
      <>
        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        <Typography variant="subtitle1">Mission</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={textFieldValue}
          onChange={handleTextFieldChange}
          InputLabelProps={{ shrink: false }}
          disabled={readOnlyMode}
          className={classes.textField}
        />
        <Box className={`${classes.suggestionBox} ${showSuggestionBox ? '' : classes.hidden}`}>
         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
           <Typography variant="h6">Suggested for you</Typography>
          <CloseIcon onClick={handleCloseSuggestionBox}  />
          </div>
          <div className={classes.quoteContainer}>
            {randomSuggestions.map((suggestion, index) => (
              <div
                key={index}
                className={classes.quote}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <Typography
                  variant="subtitle1"
                  className={classes.suggestionText}
                >
                  {suggestion}
                </Typography>
              </div>
            ))}
          </div>
        </Box>
        <Typography variant="subtitle1">Choose a country</Typography>
        <Autocomplete
          options={countryOptions}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => <TextField {...params} variant="outlined" value={selectedCountry}/>}
          onChange={(event, item) => setSelectedCountry(item.value)}
          value={countryOptions.find((option) => option.value === selectedCountry)}
          className={classes.textField}
        />
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSaveMission}
            buttonText={isEditing ? "Update" : "Save"}
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleClose}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  const editMissionPopup = () => {
    const handleEditClick = (mission) => {
      setTextFieldValue(mission.mission); 
      setSelectedCountry(mission.country);
      setIsEditing(true); 
      setEditingMissionId(mission.id);
      dispatch(getVision())
    };

    return (
      <>
        <CloseIcon onClick={handleCloseEdit} className={classes.closeIcon} />
        {missionData?.missions?.map((mission, index) => (
          <Box key={mission.id} mb={2} className={classes.editPopup}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" className={classes.editMission}>
                Mission {index + 1} - {mission.mission}
              </Typography>
              {missionData?.missions?.length > 0 && <IconButton>
                <EditOutlinedIcon className={classes.icon} onClick={(e)=> {handleEditClick(mission);setIsPopupOpen(true)}}/>
              </IconButton>}
            </Grid>
          </Box>
        ))}
      </>
    );
  };

  const handleVisionPopup = () => {
    return(
      <>
        <CloseIcon onClick={handleCloseVision} className={classes.closeIcon} />
        <Typography variant="subtitle1">Vision</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={visionText ? visionText : visionData?.vision}
          onChange={handleVisionChange}
          InputLabelProps={{ shrink: false }}
          disabled={readOnlyMode}
          className={classes.visionInput}
        />
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSaveVision}
            buttonText={editVision ? "Update" : "Save"}
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleCloseVision}
            buttonText="Cancel"
          />
        </div>
        </>)
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.visionBox}>
          <Grid
            container
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6" className={classes.label}>Vision Statement</Typography>
            </Grid>
            {!readOnlyMode && <Grid item>
              {visionData?.vision !== null ? (
          <IconButton onClick={()=> {(setIsVisionPopup(true));setEditVision(true);}}>
            <EditOutlinedIcon className={classes.icon} />
          </IconButton>
        ) : (
          <IconButton onClick={()=> setIsVisionPopup(true)}>
            <AddIcon className={classes.icon} />
          </IconButton>
        )}
            </Grid>}
          </Grid>
          <Box>
          {visionData?.vision !== null ?
          <Typography
            variant="subtitle1"
            className={classes.editMission}>{visionData?.vision}</Typography> 
            : <Typography
            variant="body2">Please enter your vision statement here</Typography>}
          </Box>
        </Box>
        <Box className={classes.visionBox}>
          <Grid
            container
            spacing={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6" className={classes.missionTitle}>Mission</Typography>
           {!readOnlyMode && <div>
              <AddIcon
                onClick={() => {setIsPopupOpen(true);setIsEditing(false)}}
                className={classes.icon}
              />
              {missionData?.missions?.length !== 0  && <EditOutlinedIcon
                className={classes.icon}
                onClick={() => setIsEditPopup(true)}
              />}
            </div>}
          </Grid>
          {missionData?.missions?.length === 0 ? (
            <Typography variant="body2">Please set your mission</Typography>
          ) : (
            <>
              {missionData?.missions?.map((mission, index) => (
                <Box key={mission.id} mb={2} style={{paddingLeft: "30px"}}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle1"
                      className={classes.editMission}>Mission {index + 1} - {mission.mission}</Typography>
                  </Grid>
                  <Typography variant="body1" style={{fontWeight: 400}}>Country : {mission.country}</Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
        <CeroPopUp
          primaryPopUp={{
            open: isPopupOpen,
            onClose: { handleClose },
            content: addMissionPopup(),
            header: { title: isEditing ? "Edit Mission" : "Add Mission" },
          }}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: isEditPopup,
            onClose: { handleCloseEdit },
            content: editMissionPopup(),
            header: { title: "Mission" },
          }}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: isVisionPopup,
            onClose: { handleCloseVision },
            content: handleVisionPopup(),
            header: { title: editVision ? "Edit Vision" : "Add Vision"},
          }}
          classes={{ dialog: classes.dialog }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default VisionAndMission;
