import {
  Box,
  Checkbox,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import { carbonCreditContent } from "../../constants";
import useStyles from "./styles";
import { useState } from "react";
import CeroButton from "../../components/CeroButton";
import CeroPopUp from "../../components/CeroPopUp";
import { useDispatch, useSelector } from "react-redux";
import { redirectCarbonCreditPool, tradeLoginArroUser, userLogout } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { STATUS } from "../../redux/constants";

const CarbonCreditPool = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [open,setOpen] = useState(false);
  const [redirectInfoPopUp, setRedirectInfoPopUp] = useState(false);
  const tradeLoginStatus = useSelector((state) => state.auth.tradeLoginArroUser);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClick = ()=>{
    setTimeout(() => {    
      dispatch(tradeLoginArroUser())
      setRedirectInfoPopUp(false);
    }, 3000);
  }

  const getApproveConfirm = () => {
    return (
      <Box className={!redirectInfoPopUp ?classes.popUp:classes.popUpRedirect}>
        {!redirectInfoPopUp ? <Typography variant="body1" style={{ fontWeight: 450 , whiteSpace: "nowrap"}} lineHeight={3}>
          You are about to logging out of the CeroEd and will be redirected to the Carbon Credit Pool Module.<br/>Would you like to proceed?
        </Typography> : <Typography variant="body1" style={{ fontWeight: 450 }} lineHeight={3}>Redirecting to Carbon Credit Module ...</Typography>}
       { !redirectInfoPopUp && <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setRedirectInfoPopUp(true);
              handleClick()
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setOpen(false)}
            buttonText="No"
          />
        </div>}
      </Box>
    );
  };

  return (
    <DashboardLayout>
      <Paper className={classes.container}>
        {carbonCreditContent.map((item) => (
          <Grid key={item.id} className={classes.gridBox}>
            {item.subItems.map((subItem) => (
              <>
                <Typography className={classes.subItemTitle} key={subItem.id}>
                  {subItem.title}
                </Typography>
                <Typography className={classes.subItemValue} key={subItem.id}>
                  {subItem.value}
                </Typography>
              </>
            ))}
            <Typography className={classes.subItemTitle}>
              How it works?
            </Typography>
            <Typography className={classes.subItemValue}>
              1. Registration: Carbon credit sellers and buyers can register for
              our platform. CeroEd customers can explore these options without
              registration.
            </Typography>
            <Typography className={classes.subItemValue}>
              2. Listing Credits: Sellers can submit their carbon credit details
              with authorized certificates. These are listed for purchase.
            </Typography>
            <Typography className={classes.subItemValue}>
              3. Initiating Transactions: Buyers browse listings and send
              requests to sellers for negotiation.
            </Typography>
            <Typography className={classes.subItemValue}>
              4. Negotiation: Sellers and buyers can discuss terms offline.
              Agreements are then formalised within the platform.
            </Typography>
            <Box display={"flex"} mt={3} alignContent={"center"}>
              <Typography className={classes.consentText}>
                Ceroed customers are required to provide consent for the public
                display of their carbon credit status
              </Typography>
              <span style={{ marginLeft: 5 }}>
                <Checkbox
                  className={classes.checkbox}
                  onChange={handleChange}
                  checked={checked}
                />
              </span>
            </Box>
            <Box mt={6} onClick={()=>setOpen(true)}>
                <Link href="#" underline="always">
              {'https://www.example.com/carbon_credit_poo\l'}
            </Link>
            </Box>    
          </Grid>
        ))}
           <CeroPopUp
        primaryPopUp={{
          open: open,
          onClose: () => setOpen(false),
          content: getApproveConfirm(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
      </Paper>
    </DashboardLayout>
  );
};

export default CarbonCreditPool;
