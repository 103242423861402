import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  childContainer: {
    backgroundColor: "#EDFFF8 !important",
  },
  backContainer: {
    color: "#387A8A",
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: theme.spacing(3),
    paddingTop: 10,
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: theme.spacing(5),
  },
  boxContainer: {
    backgroundColor: "white",
    borderRadius: 5,
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  gridContainer: {
    borderTop: "1px solid #5A404E",
    borderBottom: "1px solid #5A404E",
    padding: theme.spacing(3, 0),
    margin: theme.spacing(5, 0),
    display: "flex",
    justifyContent: "space-evenly",
    textAlign: "center",
  },
  gridItemTitle: {
    fontSize: 14,
  },
  gridItem: {
    fontWeight: 600,
    fontSize: 16,
    paddingTop: theme.spacing(2),
  },
  titleGrid: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(5),
  },
  contentBox: {
    paddingLeft: theme.spacing(5),
  },
  contactBox: {
    padding: theme.spacing(3, 5),
  },
  location:{
    display: "flex",
    marginTop: theme.spacing(2),
  },
  topRight: {
    color: "rgba(56, 122, 138, 1)", 
    fontWeight: 500, 
    paddingRight: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize:"20px",
    fontWeight:500
  },
  buttonPrimary: {
    background: "#2A6565",
    textTransform: "capitalize",
    width: 200,
    height: 42,
    marginRight: 15,
    marginTop:15,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#2A6565",
      color: theme.palette.Primary.light,
    },
  },
  textField: {
    background: "White",
    width: "29.1%",
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
        // backgroundColor: theme.palette.background.white,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
}));

export default useStyles;
