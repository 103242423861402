import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 140px)',
        marginTop: theme.spacing(5)
    },
    loader: {
        width: "100%",
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
}));

export default useStyles;