import React from 'react';
import {
  Table, TableCell, TableHead, TableRow, TableBody, TableContainer, TableFooter, CircularProgress, Typography, Paper, Box, Tooltip
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import useStyles from './styles';
import { getCookie } from '../../services/cookie';

const CeroTable = (props) => {
  const classes = useStyles();
  const isTradeAdmin = getCookie("availableRoles") === "trade_admin";
  const tableId = `CeroTable-${props.tableId || 'table-id'}`;
  const classMap = {
    added: "added",
    'in progress': "in-progress",
    'pending approval': "pending-approval",
    approved: "approved",
    review: "review",
    audited: "audited",
  };

  const inputData = Array.isArray(props.data) ? props.data : props.data();

  const getHeaders = () => props?.columns?.map((column, index) => {
    const align = 'left';
    const columnWidth = column.width || 'auto';

    if (props.useModifiedHeader) {
      return (
        <TableCell
          align={align}
          style={{
            width: columnWidth,
            minWidth: '150px',
            maxWidth: '190px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            flex: columnWidth
          }}
          className={clsx(isTradeAdmin ? classes.headerCellTrade : classes.headerCell, props?.classes?.headerCell, column.classes?.header)}
          key={column.columnId || index}
        >
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            {column.columnHeader.split(' ').reduce((acc, text, idx) => {
              if (idx % 2 === 0) {
                acc.push(text);
              } else {
                acc[acc.length - 1] = `${acc[acc.length - 1]} ${text}`;
              }
              return acc;
            }, []).map((text, idx) => (
              <div key={idx}>{text}</div>
            ))}
          </div>
        </TableCell>
      );
    }
    const isNumberColumn = column.type || inputData?.some(row => typeof row[column.columnKey] === 'number');
    const alignOriginal = column.align ||  'left';
    const contentLength = inputData && Math.max(
      ...inputData?.map(row => String(row[column.columnKey]).length)
    );

    return (
      <TableCell
        align={alignOriginal}
        style={{
          width: columnWidth,
          whiteSpace: 'initial',
          flex: columnWidth
        }}
        className={clsx(isTradeAdmin ? classes.headerCellTrade : classes.headerCell, props?.classes?.headerCell, column.classes?.header)}
        key={column.columnId || index}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {column.columnHeader.split(/(\(.*?\))/).map((text, index) => {
            return (
              <div key={index}   style={!props?.classes?.tableHead ? { textAlign: alignOriginal } : undefined}
              className={props?.classes?.tableHead}>
                {text}
              </div>
            );
          })}
        </div>
      </TableCell>
    );
  });

  const getTableRows = () => inputData?.map((rowData, rowIndex) => (
    <TableRow
      key={rowData.id}
      className={clsx(
        classes.tableDataRow,
        props.classes?.tableDataRow,
        rowData.selected && classes.tableSelectedRow,
        props.isSelectable && classes.selectableTableRow,
      )}
      onClick={() =>
        props?.onSelectRow ? props.onSelectRow(rowData) : console.log(rowData)}
    >
      {props.columns.map((column, columnIndex) => {
        const align = 'left';
        const isSignalColumn = column.columnKey === 'signal';
        const columnWidth = column.width || 'auto';

        const cellStyle = {
          width: columnWidth,
          ...(props.useExtraStyles && {
            minWidth: '150px',
            maxWidth: '190px',
          }),
          flex: columnWidth
        };

        return (
          <TableCell
            align={align}
            style={cellStyle}
            key={`${rowData.id || rowIndex}-${column.columnId || columnIndex}`}
            className={clsx(classes.tableBodyCell, props.classes?.tableBodyCell, column.classes?.column)}
          >
            {isSignalColumn ? (
              <Tooltip title={rowData.status} arrow>
                <Box component="span"
                  className={rowData['status'] ? classes[classMap[rowData['status']]] : classes.default}
                />
              </Tooltip>
            ) : rowData[column.columnKey]}
          </TableCell>
        );
      })}
    </TableRow>
  ));

  const noTableRows = () => (
    <TableRow>
      <TableCell className={classes.noDataWarning}>
        <Typography>
          No data
        </Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <InfiniteScroll
      dataLength={inputData?.length ?? 0}
      hasMore={!!props.hasMore}
      next={props.loadMore}
      scrollableTarget={tableId}
      loader={<></>}
    >
      <TableContainer id={tableId} className={clsx(classes.tableContainer, props.classes?.tableContainer)} component={Paper}>
        <Table stickyHeader id='my-table' >
          <TableHead>
            <TableRow className={clsx(classes?.tableHeader, props.classes?.tableHeader)}>
              {getHeaders()}
            </TableRow>
          </TableHead>
          <TableBody className={props?.classes?.tableBody}>
            {getTableRows()}
            {!getTableRows()?.length && noTableRows()}
          </TableBody>
          {props.loading && (
            <TableFooter className={classes.tableFooter}>
              <TableRow>
                <TableCell variant="footer" colSpan={props.columns.length} className={classes.footerCell}>
                  <CircularProgress thickness={3} size={20} className={classes.progress} />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
};

export default CeroTable;
