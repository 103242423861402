import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    "& .MuiTable-root":{
      paddingBottom: theme.spacing(3)
    }
  },
  tableHeader: {
    height: 60,
  },
  headerCell: {
    height: 70,
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1),
    color: theme.palette.text.dark,
    backgroundColor: theme.palette.Other.background,
    "&:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "&:last-child": {
      paddingRight: theme.spacing(4),
    },
  },
  tableDataRow: {
    height: 65,
    backgroundColor: theme.palette.Primary.contrastText,
    boxShadow: "inset 0px -1px 0px rgba(80, 80, 80, 0.15)",
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
  selectableTableRow: {
    cursor: "pointer",
  },
  tableSelectedRow: {
    backgroundColor: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: "#FFDED2", // TODO: add to the theme once it is available
    },
  },
  tableBodyCell: {
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(1),
    boxShadow: "inset 0px -1px 0px rgba(80, 80, 80, 0.15)",
    textTransform: "capitalize",
    "&:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "&:last-child": {
      paddingRight: theme.spacing(4),
    },
  },
  progress: {
    color: theme.palette.Warning.main,
  },
  footerCell: {
    textAlign: "center",
    padding: theme.spacing(3),
    boxShadow: "inset 0px -1px 0px rgba(80, 80, 80, 0.15)",
  },
  tableFooter: {
    height: 52,
    backgroundColor: theme.palette.Primary.contrastText,
  },
  noDataWarning: {
    textAlign: "center",
    background: "white",
    verticalAlign: "center"
  },
  checkboxCell:{
    backgroundColor: theme.palette.Other.background,
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    borderBottom: "none"
  },
  emptyCell:{
    backgroundColor: theme.palette.Other.background,
  },
  selectRow:{
    boxShadow: "inset 0px -1px 0px rgba(80, 80, 80, 0.15)",
  },
  headerIcons:{
    display: "flex", 
    alignItems: "center",
    width: "4.5%",
    backgroundColor: theme.palette.Secondary.hoverButton,
    borderRadius: 5,
    border: "0.5px solid #5A404E"
  },
  select:{
    position: "absolute",
      width: "6%",
      border: "1px solid black",
      borderRadius: 5,
    "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
      position: "absolute",
      backgroundColor: "white",
    },
    '& fieldset': {
      borderColor: 'none !important', 
    }
  },
  checkbox:{
    color: "#8C2148 !important",
    borderWidth: 1    
  },
  filterBox:{
    padding: theme.spacing(2),
    width: "30%",
  }
}));

export default useStyles;
