import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    loader: {
      width: "100%",
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    filterContainer: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        backgroundColor: theme.palette.background.white,
      },
    },
    button:{
      background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.background,
            color: theme.palette.Primary.light,
          },
    },
    buttonPrimary: {
      background: theme.palette.Primary.background,
      textTransform: "capitalize",
      fontWeight: 400,
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: theme.palette.Primary.hoverButton,
        color: theme.palette.Primary.light,
      },
      width: "fit-content",
      height: 37,
      fontSize: 14,
      marginLeft: theme.spacing(2)
    },
    buttonSecondary: {
      color: theme.palette.Primary.background,
      borderColor: theme.palette.Primary.background,
      textTransform: "capitalize",
      "&:hover": {
        borderColor: theme.palette.Primary.background,
        backgroundColor: theme.palette.Primary.contrastText,
      },
      marginRight: theme.spacing(5),
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(2),
    },
    topButtonsContainer: {
      display: "flex", 
      justifyContent:"space-between", 
      alignItems: "center"
    },
    textField: {
      backgroundColor: theme.palette.background.white,
    },
    noComments: {
      float: "left",
      fontFamily: "Arial, sans-serif",
      fontSize: 12,
    },
    maxCharacters: {
      float: "right",
      fontSize: 12,
    },
    dialogActions: {
      justifyContent: "flex-start",
      display: "flex",
      paddingLeft: 30,
      paddingBottom: 30,
    },
    textLimit: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: 10,
    },
    buttonContainerReview: {
      display: "flex",
      justifyContent: "space-around",
      paddingTop: 20,
      paddingBottom: 15,
      width: "50%",
      margin: "auto"
    },
    popUp:{
      margin: "auto",
    },
    dialog:{
      width: "40% !important",
      textAlign: "center",
    },
    reviewContainer:{
      display: "flex",
      alignItems: "center",
      color: "red",
    },
    reviewButtons:{
      marginRight: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      width: "20%"
    },
    selectCheckbox: {
      // padding: theme.spacing(3),
      width:"40%",
      paddingRight:'10px'
    },
    checkbox: {
      // margin: theme.spacing(1),
      padding: theme.spacing(1),
      display:"flex"
      // maxWidth: "27vw",
      // border: "1px solid #5A404E",
      // borderRadius: 4,
      // maxHeight: "55vh",
      
    },
    dropdown: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        backgroundColor: theme.palette.background.white,
      },
  },
}));

export default useStyles;