import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  signup: {
    backgroundColor: "#EDFFF8",
    minHeight: "100vh",
    margin: "auto",
    display: "flex",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
    successBox:{
        width: "30vw",
        backgroundColor: "#FFF",
        margin: "auto",
        height: "50vh",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(5, 8),
      },
      logo: {
        display: "flex",
        justifyContent: "center",
        width: 200,
        padding: theme.spacing(8),
      },
      loginButton:{
        background: "#2A6565",
        textTransform: "capitalize",
        width: "auto",
        height: 38,
        fontWeight: 400,
        whiteSpace: "nowrap",
        margin: theme.spacing( 8, 0, 12, 0),
        padding: theme.spacing(0,5),
        letterSpacing: 1.5,
        borderRadius: 5,
        "&:hover": {
          backgroundColor: "#2A6565",
          color: theme.palette.Primary.light,
        },
      },
}));

export default useStyles;