import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const EconomicImpact = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { emissionData } = props;

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Economic Impact
            </Typography>
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              display={"contents"}
              spacing={12}
            >
              <Grid
                item
                container
                direction={"column"}
                display={"contents"}
                xs={10}
              >
                <Box className={classes.actionBox}>
                  <CeroInfoPair
                    title="Status:"
                    value={emissionData.status ? emissionData.status : "NA"}
                    classes={{
                      container: classes.statusContainer,
                      title: classes.statusTitle,
                      value:
                        emissionData.status === "In Progress"
                          ? classes.Inprogress
                          : classes[emissionData?.status],
                    }}
                  />
                </Box>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Is there any significant Infrastructure investment:"
                  value={emissionData.any_significant_infrastructure || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Enter the details of the infrastructure investments:"
                  value={emissionData.infrastructure_investment || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Nature of Investment:"
                  value={emissionData.nature_of_investments || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Length of Investment:"
                  value={emissionData.length_of_investments || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Does it have any direct impacts on communities and local economies?:"
                  value={emissionData.any_direct_impact_on_communities || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Details for Positive and Negative Impact:"
                  value={emissionData.details_of_indirect_impact || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Does it have any indirect impacts on communities and local economies?:"
                  value={emissionData.any_indirect_impact || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Details for Positive and Negative Impact:"
                  value={emissionData.outcomes_of_legal_action || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Mitigation measures taken for negative impacts"
                  value={emissionData.migration_for_negative_impact || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default EconomicImpact;
