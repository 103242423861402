import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';
import { getTradeAccount, updateTradeAccount } from '../actions';

export const tradeState = {
  isAuthenticated: false,
  userInfo:{},
  status: STATUS.IDLE,
  message:"",
  signupTrade: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  industryTypes: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getLocations: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  sellCarbonCredits: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getCarbonCreditsAdded:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  buyCarbonCreditList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getProjectDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getTradeAccount: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  updateTradeAccount: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
};

const tradeActions = {
  trade: handleActions(
    {
        [ActionTypes.SIGNUP_TRADE]: (state, { payload }) =>
            immutable(state, {
              signupTrade: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.SIGNUP_TRADE_SUCCESS]: (state, { payload, role }) =>
            immutable(state, {
              signupTrade: {
                data: { $set: payload },
                status: { $set: STATUS.SUCCESS },
              },
            }),
    
          [ActionTypes.SIGNUP_TRADE_FAILURE]: (state, { payload }) =>
            immutable(state, {
              signupTrade: {
                status: { $set: STATUS.ERROR },
                message: { $set: payload },
              },
            }),

            [ActionTypes.RESET_SIGNUP]: (state, { payload }) =>
              immutable(state, {
                signupTrade: {
                  status: { $set: STATUS.IDLE },
                },
                sellCarbonCredits: {
                  status: { $set: STATUS.IDLE },
                },
                updateTradeAccount: {
                  status: { $set: STATUS.IDLE },
                }
              }),

              [ActionTypes.INDUSTRY_TYPES]: (state, { payload }) =>
                immutable(state, {
                  industryTypes: {
                    status: { $set: STATUS.RUNNING },
                  },
                }),
              [ActionTypes.INDUSTRY_TYPES_SUCCESS]: (state, { payload, role }) =>
                immutable(state, {
                  industryTypes: {
                    data: { $set: payload },
                    status: { $set: STATUS.SUCCESS },
                  },
                }),
        
              [ActionTypes.INDUSTRY_TYPES_FAILURE]: (state, { payload }) =>
                immutable(state, {
                  industryTypes: {
                    status: { $set: STATUS.ERROR },
                    message: { $set: payload },
                  },
                }),

                [ActionTypes.GET_LOCATIONS]: (state, { payload }) =>
                  immutable(state, {
                    getLocations: {
                      status: { $set: STATUS.RUNNING },
                    },
                  }),
                [ActionTypes.GET_LOCATIONS_SUCCESS]: (state, { payload, role }) =>
                  immutable(state, {
                    getLocations: {
                      data: { $set: payload },
                      status: { $set: STATUS.SUCCESS },
                    },
                  }),
          
                [ActionTypes.GET_LOCATIONS_FAILURE]: (state, { payload }) =>
                  immutable(state, {
                    getLocations: {
                      status: { $set: STATUS.ERROR },
                      message: { $set: payload },
                    },
                  }),

                  [ActionTypes.SELL_CARBON_CREDITS]: (state, { payload }) =>
                    immutable(state, {
                      sellCarbonCredits: {
                        status: { $set: STATUS.RUNNING },
                      },
                    }),
                  [ActionTypes.SELL_CARBON_CREDITS_SUCCESS]: (state, { payload, role }) =>
                    immutable(state, {
                      sellCarbonCredits: {
                        data: { $set: payload },
                        status: { $set: STATUS.SUCCESS },
                      },
                    }),
            
                  [ActionTypes.SELL_CARBON_CREDITS_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                      sellCarbonCredits: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: payload },
                      },
                    }),

                    [ActionTypes.GET_CARBON_CREDITS_ADDED]: (state, { payload }) =>
                      immutable(state, {
                        getCarbonCreditsAdded: {
                          status: { $set: STATUS.RUNNING },
                        },
                      }),
                    [ActionTypes.GET_CARBON_CREDITS_ADDED_SUCCESS]: (state, { payload, role }) =>
                      immutable(state, {
                        getCarbonCreditsAdded: {
                          data: { $set: payload },
                          status: { $set: STATUS.SUCCESS },
                        },
                      }),
              
                    [ActionTypes.GET_CARBON_CREDITS_ADDED_FAILURE]: (state, { payload }) =>
                      immutable(state, {
                        getCarbonCreditsAdded: {
                          status: { $set: STATUS.ERROR },
                          message: { $set: payload },
                        },
                      }),
                  [ActionTypes.BUY_CARBON_CREDIT_LIST]: (state, { payload }) =>
                    immutable(state, {
                      buyCarbonCreditList: {
                        status: { $set: STATUS.RUNNING },
                      },
                    }),
                  [ActionTypes.BUY_CARBON_CREDIT_LIST_SUCCESS]: (state, { payload, role }) =>
                    immutable(state, {
                      buyCarbonCreditList: {
                        data: { $set: payload },
                        status: { $set: STATUS.SUCCESS },
                      },
                    }),
            
                  [ActionTypes.BUY_CARBON_CREDIT_LIST_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                      buyCarbonCreditList: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: payload },
                      },
                    }),

                    [ActionTypes.GET_PROJECT_DETAILS]: (state, { payload }) =>
                      immutable(state, {
                        getProjectDetails: {
                          status: { $set: STATUS.RUNNING },
                        },
                      }),
                    [ActionTypes.GET_PROJECT_DETAILS_SUCCESS]: (state, { payload, role }) =>
                      immutable(state, {
                        getProjectDetails: {
                          data: { $set: payload },
                          status: { $set: STATUS.SUCCESS },
                        },
                      }),
              
                    [ActionTypes.GET_PROJECT_DETAILS_FAILURE]: (state, { payload }) =>
                      immutable(state, {
                        getProjectDetails: {
                          status: { $set: STATUS.ERROR },
                          message: { $set: payload },
                        },
                      }),

                      [ActionTypes.GET_TRADE_ACCOUNT]: (state, { payload }) =>
                        immutable(state, {
                          getTradeAccount: {
                            status: { $set: STATUS.RUNNING },
                          },
                        }),
                      [ActionTypes.GET_TRADE_ACCOUNT_SUCCESS]: (state, { payload, role }) =>
                        immutable(state, {
                          getTradeAccount: {
                            data: { $set: payload },
                            status: { $set: STATUS.SUCCESS },
                          },
                        }),
                
                      [ActionTypes.GET_TRADE_ACCOUNT_FAILURE]: (state, { payload }) =>
                        immutable(state, {
                          getTradeAccount: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: payload },
                          },
                        }),

                        [ActionTypes.UPDATE_TRADE_ACCOUNT]: (state, { payload }) =>
                          immutable(state, {
                            updateTradeAccount: {
                              status: { $set: STATUS.RUNNING },
                            },
                          }),
                        [ActionTypes.UPDATE_TRADE_ACCOUNT_SUCCESS]: (state, { payload, role }) =>
                          immutable(state, {
                            updateTradeAccount: {
                              data: { $set: payload },
                              status: { $set: STATUS.SUCCESS },
                            },
                          }),
                  
                        [ActionTypes.UPDATE_TRADE_ACCOUNT_FAILURE]: (state, { payload }) =>
                          immutable(state, {
                            updateTradeAccount: {
                              status: { $set: STATUS.ERROR },
                              message: { $set: payload },
                            },
                          }),
    },
    tradeState
  ),
};

export default tradeActions;
