import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        height: 60,
    },
    button: {
        width: '100%',
        fontWeight: '500',
        fontSize: '14px',
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.background,
            color: theme.palette.Primary.light,
          },
    },
}));

export default useStyles;
