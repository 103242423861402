import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";

import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails";
import GoalSelection from "./GoalSelection";
import { userSignUp, auditorSignUp, resetAuthentication, resetSignupAction } from "../../redux/actions/auth";
import { STATUS } from "../../redux/constants";
import useStyles from "./styles";
import CeroEdLogo from "../../assets/images/Logo";

const steps = ["User", "Company"];

const Signup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const signupData = useSelector((state) => state.auth.signup);
  const auditorSignUpData = useSelector((state) => state.auth.auditSignUp);
  const [userDetails, setUserDetails] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [userType, setuserType] = useState();

  useEffect(() => {
    if (signupData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Successfully Signed up as admin", { variant: "success" });
      dispatch(resetSignupAction())
      navigate("/signup-response");
    } else if (signupData.status === STATUS.ERROR) {
      enqueueSnackbar(signupData.message, { variant: "error" }); 
      dispatch(resetSignupAction())
      setActiveStep(0);  
    }
  }, [signupData.status, signupData.message, enqueueSnackbar, navigate, dispatch]);

  useEffect(() => {
    if (auditorSignUpData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Successfully Signed up as auditor", { variant: "success" });
      dispatch(resetSignupAction())
      navigate("/signup-response");
    } else if (auditorSignUpData.status === STATUS.ERROR) {
      enqueueSnackbar(auditorSignUpData.message, { variant: "error" });
      dispatch(resetSignupAction())
      setActiveStep(0);
    }
  }, [auditorSignUpData.status, auditorSignUpData.message, enqueueSnackbar, navigate]);

  const handleNext = (step, data) => {
    setActiveStep(activeStep + 1);
    if (step === 0) {
      setUserDetails(data);
    } else if (step === 1) {
      const commonRequest = {
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phone,
        password: userDetails.password,
        company: {
          company_name: data.company,
          company_phone: data.phone,
          company_est_year: data.establishedYear,
          company_address: data.address,
          company_website: data.website,
          company_country: data.country,
          company_email: data.email,
          company_industry_type: data.industryType,
        },
      };
      console.log('user',userType);
      if (userType === "business") {
        dispatch(userSignUp(commonRequest));
      } else if (userType === "auditor") {
        dispatch(auditorSignUp(commonRequest));
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleRoleChange = (event) => {
    setuserType(event.target.value);
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.signup}
    >
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        {/* <Box className={classes.logo}>
                    <CeroEdLogo />
                </Box> */}
        <Paper variant="outlined" className={classes.signupContainer}>
          <Typography
            component="h1"
            variant="h4"
            fontWeight={600}
            color="#8C2148"
            align="center"
          >
            Sign up
          </Typography>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            sx={{
              ".MuiStepLabel-root .Mui-active": { color: "#8C2148" },
              "& .MuiStepLabel-root .Mui-completed": { color: "#8C2148" },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <UserDetails userDetails={userDetails} onNext={handleNext} handleRoleChange={handleRoleChange} userType={userType}/>
          )}
          {activeStep === 1 && (
            <CompanyDetails
              companyDetails={companyDetails}
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {/* {activeStep === 2 && <GoalSelection onNext={handleNext} onBack={handleBack} />} */}
        </Paper>
      </Container>
    </Grid>
  );
};

export default Signup;
