import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Paper, Typography } from "@mui/material";
import _ from "lodash";
import { getDashboardStatistics } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const EmissionsCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");

  const statisticsData = useSelector(
    (state) => state.dashboard.getStatistics.data
  );

  useEffect(() => {
    dispatch(getDashboardStatistics(props.filter, role, companyId));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>Emissions</Typography>
      <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.subTitle}>Total Emissions</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.largeBox}>
          <Typography variant="h5" className={classes.value}>{statisticsData?.total_co2e?.prop?.value}</Typography>
          <Typography variant="body2" className={classes.unit}>metric tons</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1" className={classes.subTitle}>Scope 1</Typography>
          <Box  className={classes.scopeBox}>
          <Typography variant="h5" className={classes.value}>{statisticsData?.Scope_1?.prop?.value}</Typography>
          <Typography variant="body2" className={classes.unit}>metric tons</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1" className={classes.subTitle}>Scope 2</Typography>
          <Box  className={classes.scopeBox}>
          <Typography variant="h5" className={classes.value}>{statisticsData?.Scope_2?.prop?.value}</Typography>
          <Typography variant="body2" className={classes.unit}>metric tons</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1" className={classes.subTitle}>Scope 3</Typography>
          <Box  className={classes.scopeBox}>
          <Typography variant="h5" className={classes.value}>{statisticsData?.Scope_3?.prop?.value}</Typography>
          <Typography variant="body2" className={classes.unit}>metric tons</Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
    </Paper>
  );
};

export default EmissionsCard;
