import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const SubsidiesFinancialAssistance = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;

  return (
    <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div">
            Subsidies Financial Assistance
          </Typography>
        </Box>
        <Box className={classes.topContainer}>
          <Grid
            container
            direction={"row"}
            wrap="nowrap"
            justifyContent={"space-between"}
            display={"contents"}
            spacing={12}
          >
            <Grid item container direction={"column"} display={"contents"} xs={10}>
            <Box className={classes.actionBox}>
            <CeroInfoPair 
                title="Status:"
                value={emissionData.status ? emissionData.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: emissionData.status === "In Progress" ? classes.Inprogress : classes[emissionData?.status]
                }}
              /></Box>
            <CeroItemPair
              title="Facility:"
              value={emissionData.facility_name ? emissionData.facility_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Period:"
              value={emissionData.assessment_period ? emissionData.assessment_period : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Year:"
              value={emissionData.year ? emissionData.year : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Total Financial Assistance Received:"
              value={`$${emissionData.financial_assistant_received}` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Type of Financial Assistance:"
              value={emissionData.type_financial_assistant || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            {emissionData.type_financial_assistant?.includes('Others') && (
              <CeroItemPair
              title="Other Financial assistance details:"
              value={emissionData.type_financial_assistant_details || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />)}
            <Typography variant='subtitle2' className={classes.subsidiesHeading}>Total monetary value of financial assistance received from each Facility:</Typography>
            <Box className={classes.amountDistribution}>
              {emissionData.financial_assistant_by_facilities?.map(elem=>(
                <CeroItemPair
                title={elem.facility_name}
                value={`$${elem.amount}` || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.subsidiesTitle,
                  value: classes.value,
                }}
              />
              ))}
            </Box>
            <CeroItemPair
              title="Indicate whether any government is present in the shareholding structure of the organization:"
              value={emissionData.any_government_present_in_shareholder || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            {emissionData.any_government_present_in_shareholder == "Yes" &&
            <CeroItemPair
              title="Details:"
              value={emissionData.details || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />}
            <CeroItemPair
              title="Audited on:"
              value={emissionData.audited_by ? emissionData.audited_by : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Audited by:"
              value={emissionData.audited_on
                ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default SubsidiesFinancialAssistance;
