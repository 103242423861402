import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
  },
  cellContainer: {
    boxShadow: "none",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeaderContainer: {
    padding: theme.spacing(3),
    background: theme.palette.Secondary.background,
    fontWeight: 500,
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 10,
    alignItems: 'center',
    cursor: "pointer"
  },
  headerContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title:{
    fontWeight: 500,
    marginTop: theme.spacing(8)
  }
}));

export default useStyles;