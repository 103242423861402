import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    dashboardContainer: {
        height: '100vh',
        display: 'flex',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    rightContainer: {
        width: 'calc(100vw - 250px)',
        display: 'flex',
        flexDirection: 'column',
    },
    childContainer: {
        flex: 1,
        background: theme.palette.Secondary.background,
        padding: theme.spacing(5, 2, 0, 5),
        height: 'calc(100% - 60px)',
        overflowY: 'scroll',
        width: '100%',
    },
    submenuWrapper: {
        display: 'none',
        position: 'absolute',
        top: '100%',
        left: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
      },
      menuItemWrapper: {
        position: 'relative',
        '&:hover $submenuWrapper': {
          display: 'block',
        },
      },
      submenuItem: {
        display: 'block',
        padding: '10px',
        textDecoration: 'none',
        color: '#333',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      },
    
}));

export default useStyles;