import { all, put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

/**
 * Login
 */
export function* getApprovalSummaryList(action) {
    try {
        const response = yield call(request, APIEndpoints.GET_APPROVAL_SUMMARY(action.payload.year, action.payload.facility, action.payload.auditId), {
            method: 'GET'
        })
        yield put({
            type: ActionTypes.GET_APPROVAL_SUMMARY_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_APPROVAL_SUMMARY_FAILURE,
            payload: err
        })
    }
}

export function* getApprovalDetails(action) {
    try {
        const response = yield call(request, APIEndpoints.GET_APPROVAL_DETAILS(action.payload.year, action.payload.facility, action.payload.auditId), {
            method: 'GET'
        })
        yield put({
            type: ActionTypes.GET_APPROVAL_DETAILS_SUCCESS,
            payload: response.audit_status_detail
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_APPROVAL_DETAILS_FAILURE,
            payload: err
        })
    }
}

export function* getApprovalMonthlyDetails({payload}) {
  const requestPayload = {
  }
  if(payload.id) {
    requestPayload.monthly_approval_status_id = payload.id
  }
  if(payload.year && payload.year !== 'null') {
    requestPayload.year = payload.year
  }
  if(payload.facility) {
    requestPayload.facility_id = payload.facility
  }
  if(payload.month) {
    requestPayload.month = payload.month
  }
    try {
        const response = yield call(request, APIEndpoints.GET_APPROVAL_MONTHLY_DETAILS, {
            method: 'GET',
            payload: requestPayload
        })
        yield put({
            type: ActionTypes.GET_APPROVAL_MONTHLY_DETAILS_SUCCESS,
            payload: response
        })
    } catch (err) {
        yield put({
            type: ActionTypes.GET_APPROVAL_MONTHLY_DETAILS_FAILURE,
            payload: err
        })
    }
}

export function* getApprovalMonthlySummary({payload}) {
  const requestPayload = {}
    if(payload.id) {
      requestPayload.monthly_approval_status_id = payload.id
    }
    if(payload.year && payload.year !== 'null') {
      requestPayload.year = payload.year
    }
    if(payload.facility) {
      requestPayload.facility_id = payload.facility
    }
    if(payload.month) {
      requestPayload.month = payload.month
    }
    try {
        const response = yield call(request, APIEndpoints.GET_APPROVAL_MONTHLY_SUMMARY, {
            method: 'GET',
            payload: requestPayload
        })
        yield put({
            type: ActionTypes.GET_APPROVAL_MONTHLY_SUMMARY_SUCCESS,
            payload: response.monthly_approval_summary
        })
    } catch (err) {
        yield put({
            type: ActionTypes.GET_APPROVAL_MONTHLY_SUMMARY_FAILURE,
            payload: err
        })
    }
}

export function* submitApproval({payload}) {
    try {
      const response = yield call(
        request,
        APIEndpoints.SUBMIT_APPROVAL(payload.statusId),
        {
          method: "POST",
          payload: { submit_user_id: payload.userId },
        }
      );
  
      yield put({
        type: ActionTypes.SUBMIT_APPROVAL_SUCCESS,
        payload: response,
      });
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.SUBMIT_APPROVAL_FAILURE,
        payload: err,
      });
    }
  }

export function* requestApproval({payload}) {
    try {
      const response = yield call(
        request,
        APIEndpoints.REQUEST_APPROVAL(payload.statusId),
        {
          method: "POST",
          payload: { approve_user_id: payload.userId },
        }
      );
  
      yield put({
        type: ActionTypes.REQUEST_APPROVAL_SUCCESS,
        payload: response,
      });
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.REQUEST_APPROVAL_FAILURE,
        payload: err,
      });
    }
  }

export function* approveRequest({payload}) {
    try {
      const response = yield call(
        request,
        APIEndpoints.APPROVE_REQUEST(payload.statusId),
        {
          method: "POST",
          payload: { comment: payload.comment },
        }
      );
  
      yield put({
        type: ActionTypes.APPROVE_REQUEST_SUCCESS,
        payload: response,
      });
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.APPROVE_REQUEST_FAILURE,
        payload: err,
      });
    }
  }

export function* getApprovalTopicStatus(action) {
    try {
        const response = yield call(request, APIEndpoints.GET_APPROVAL_TOPIC_STATUS(action.payload.statusId), {
            method: 'GET'
        })
        yield put({
            type: ActionTypes.GET_APPROVAL_TOPIC_STATUS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_APPROVAL_TOPIC_STATUS_FAILURE,
            payload: err
        })
    }
}

export function* roleBasedApproval(action) {
  try {
      const response = yield call(request, APIEndpoints.ROLE_BASED_APPROVAL(action.payload.statusId, action.payload.emissionType, action.payload.categoryType), {
          method: 'GET'
      })
      yield put({
          type: ActionTypes.ROLE_BASED_APPROVAL_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.ROLE_BASED_APPROVAL_FAILURE,
          payload: err
      })
  }
}

export function* submitMonthlyApproval(action) {
  try {
      const {statusId, categoryType, topics} =action.payload
      const response = yield call(request, APIEndpoints.SUBMIT_MONTHLY_APPROVAL(statusId, categoryType), {
          method: 'POST',
          payload: {
            topics: topics
          }
      })
      yield put({
          type: ActionTypes.SUBMIT_MONTHLY_APPROVAL_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.SUBMIT_MONTHLY_APPROVAL_FAILURE,
          payload: err
      })
  }
}

export function* assignMonthlyApproval(action) {
  try {
      const {statusId, topics} =action.payload
      const response = yield call(request, APIEndpoints.ASSIGN_MONTHLY_APPROVAL(statusId), {
          method: 'POST',
          payload: {
            topics: topics
          }
      })
      yield put({
          type: ActionTypes.ASSIGN_MONTHLY_APPROVAL_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.ASSIGN_MONTHLY_APPROVAL_FAILURE,
          payload: err
      })
  }
}

export function* approveMonthlyApproval(action) {
  try {
      const {statusId, categoryType, topics} =action.payload
      const response = yield call(request, APIEndpoints.APPROVE_MONTHLY_APPROVAL(statusId, categoryType), {
          method: 'POST',
          payload: {
            topics: topics
          }
      })
      yield put({
          type: ActionTypes.APPROVE_MONTHLY_APPROVAL_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.APPROVE_MONTHLY_APPROVAL_FAILURE,
          payload: err
      })
  }
}

export function* pendingApprovals(action) {
  try {
    const {year, auditId, facility} = action.payload
      const response = yield call(request, APIEndpoints.PENDING_APPROVALS(year, auditId, facility), {
          method: 'GET',
      })
      yield put({
          type: ActionTypes.PENDING_APPROVALS_SUCCESS,
          payload: response.monthly_approval_status
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.PENDING_APPROVALS_FAILURE,
          payload: err
      })
  }
}

export function* addReviewComments(action) {
  try {
      const {statusId, topics, comments} =action.payload
      const response = yield call(request, APIEndpoints.ADD_REVIEW_COMMENTS(statusId), {
          method: 'POST',
          payload: {
            topics: topics,
            comment: comments
          }
      })
      yield put({
          type: ActionTypes.ADD_REVIEW_COMMENTS_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.ADD_REVIEW_COMMENTS_FAILURE,
          payload: err
      })
  }
}

export function* updateAuditTypes(action) {
  try {
      const {auditId, selfAudit} =action.payload
      const response = yield call(request, APIEndpoints.UPDATE_AUDIT_TYPES(auditId, selfAudit), {
          method: 'POST',
          payload: {
            self_audited: selfAudit
          }
      })
      yield put({
          type: ActionTypes.UPDATE_AUDIT_TYPES_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.UPDATE_AUDIT_TYPES_FAILURE,
          payload: err
      })
  }
}

export function* getApprovalEmission(action) {
  try {
      const response = yield call(request, APIEndpoints.GET_APPROVAL_EMISSION(action.payload.statusId, action.payload.emissionType, action.payload.categoryType), {
          method: 'GET'
      })
      yield put({
          type: ActionTypes.GET_APPROVAL_EMISSION_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.GET_APPROVAL_EMISSION_FAILURE,
          payload: err
      })
  }
}

export function* getTopicBasedApproval(action) {
  try {
      const response = yield call(request, APIEndpoints.GET_TOPIC_BASED_APPROVAL(action.payload.statusId, action.payload.emissionType, action.payload.categoryType), {
          method: 'GET'
      })
      yield put({
          type: ActionTypes.GET_TOPIC_BASED_APPROVAL_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.GET_TOPIC_BASED_APPROVAL_FAILURE,
          payload: err
      })
  }
}

export function* assignToSubmit(action) {
  try {
      const {statusId, categoryType, topics} =action.payload
      const response = yield call(request, APIEndpoints.ASSIGN_TO_SUBMIT(statusId, categoryType), {
          method: 'POST',
          payload: {
            topics: topics
          }
      })
      yield put({
          type: ActionTypes.ASSIGN_TO_SUBMIT_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.ASSIGN_TO_SUBMIT_FAILURE,
          payload: err
      })
  }
}

export function* pendingApprovalEmission(action) {
  try {
      const response = yield call(request, APIEndpoints.PENDING_APPROVAL_EMISSION(action.payload.statusId, action.payload.emissionType, action.payload.categoryType), {
          method: 'GET'
      })
      yield put({
          type: ActionTypes.PENDING_APPROVAL_EMISSION_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.PENDING_APPROVAL_EMISSION_FAILURE,
          payload: err
      })
  }
}

export function* getApprovedData(action) {
  try {
      const {statusId, categoryType, topics} =action.payload
      const response = yield call(request, APIEndpoints.GET_APPROVED_DATA(statusId, categoryType), {
          method: 'POST',
          payload: {
            topics: topics
          }
      })
      yield put({
          type: ActionTypes.GET_APPROVED_DATA_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.GET_APPROVED_DATA_FAILURE,
          payload: err
      })
  }
}

export default function* root() {
    yield all([
        takeLatest(ActionTypes.GET_APPROVAL_SUMMARY, getApprovalSummaryList),
        takeLatest(ActionTypes.GET_APPROVAL_DETAILS, getApprovalDetails),
        takeLatest(ActionTypes.GET_APPROVAL_MONTHLY_DETAILS, getApprovalMonthlyDetails),
        takeLatest(ActionTypes.GET_APPROVAL_MONTHLY_SUMMARY, getApprovalMonthlySummary),
        takeLatest(ActionTypes.SUBMIT_APPROVAL, submitApproval),
        takeLatest(ActionTypes.REQUEST_APPROVAL, requestApproval),
        takeLatest(ActionTypes.APPROVE_REQUEST, approveRequest),
        takeLatest(ActionTypes.GET_APPROVAL_TOPIC_STATUS, getApprovalTopicStatus),
        takeLatest(ActionTypes.ROLE_BASED_APPROVAL, roleBasedApproval),
        takeLatest(ActionTypes.SUBMIT_MONTHLY_APPROVAL, submitMonthlyApproval),
        takeLatest(ActionTypes.ASSIGN_MONTHLY_APPROVAL, assignMonthlyApproval),
        takeLatest(ActionTypes.APPROVE_MONTHLY_APPROVAL, approveMonthlyApproval),
        takeLatest(ActionTypes.PENDING_APPROVALS, pendingApprovals),
        takeLatest(ActionTypes.ADD_REVIEW_COMMENTS, addReviewComments),
        takeLatest(ActionTypes.UPDATE_AUDIT_TYPES, updateAuditTypes),
        takeLatest(ActionTypes.GET_APPROVAL_EMISSION, getApprovalEmission),
        takeLatest(ActionTypes.GET_TOPIC_BASED_APPROVAL, getTopicBasedApproval),
        takeLatest(ActionTypes.ASSIGN_TO_SUBMIT, assignToSubmit),
        takeLatest(ActionTypes.PENDING_APPROVAL_EMISSION, pendingApprovalEmission),
        takeLatest(ActionTypes.GET_APPROVED_DATA, getApprovedData),
    ])
}
