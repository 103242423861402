import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: "100%",
    maxWidth: "100%"
  },
  newsBox: {
    backgroundColor: "rgba(139, 33, 71, 0.11) !important",
    marginBottom: theme.spacing(3),
    borderRadius: 8,
    padding: theme.spacing(2, 1),
    color: "black",
    width: "100%",
    overflow: "hidden",
  },
  newsScroll: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 14,
    color: "black",
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      animation: "$scroll 20s linear 0s infinite",
    },
  },
  "@keyframes scroll": {
    "0%": { transform: "translateX(0%)" },
    "100%": { transform: "translateX(-100%)" },
  },
  textField: {
    backgroundColor: "rgba(139, 33, 71, 0.11) !important",
    marginBottom: theme.spacing(3.5),
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      color: "#5A404E !important",
      background: "#F9F4F6 !important",
      // background: "linear-gradient(to top, rgba(139, 33, 71, 0.01) 50%, #F9F4F6 50%) !important",
    },
    "&.MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: "#5A404E !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A404E !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A404E !important",
    },
    "MuiFormLabel": {
      fontSize: 16,
      backgroundColor:theme.palette.Secondary.hoverButton,
}
  },
  dialog: {
    width: "30%",
    padding: theme.spacing(2),
    "& .MuiPaper-root.MuiDialog-paper": {
      width: "50vw",
      margin: 80,
    },
    "& .MuiTypography-root.MuiDialogTitle-root":{
      color: 'blue !important'
    }
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer"
  },
  popupContent:{
    textAlign: "center",
    padding: theme.spacing(5,5,4,5)
  },
  newsSubtitle:{
    width: "60%",
    margin: "auto",
    fontWeight: 600,
    marginBottom: theme.spacing(4)
  },
  newsContent:{
    width: "100%",
    textAlign: "left",
    margin: theme.spacing(8,"auto")
  },
  title:{
    color: theme.palette.Primary.background,
    fontWeight: 600,
    textAlign: "center",
    width: "60%",
    margin: theme.spacing(5,"auto")
  },
  newsClose:{
    cursor: "pointer"
  }
}));

export default useStyles;
