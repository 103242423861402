import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";

export const listState = {
  listFacilities: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  industryTypes: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  countryList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  assigneeList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  gridRegions: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  listManager: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  frameworkList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  topicList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  companyCountries:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  companyFacilities:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  companyUsers:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  facilityDetails:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  userStatistics:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  usersList:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  companyUserDetail:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  totalUsersDetail:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getOrganizationCountry: {
    data: {country_facility_data:{},country_data:{}},
    status: STATUS.IDLE,
    message: ''
},
  stakeholderCompany:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getFacilityPeriods:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAuditTypes:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStates:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAssessmentYear:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
};

const listActions = {
  listings: handleActions(
    {
      [ActionTypes.LIST_FACILITIES]: (state, { payload }) =>
        immutable(state, {
          listFacilities: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_FACILITIES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          listFacilities: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_FACILITIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          listFacilities: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_INDUSTRY_TYPES]: (state, { payload }) =>
        immutable(state, {
          industryTypes: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_INDUSTRY_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          industryTypes: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_INDUSTRY_TYPES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          industryTypes: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_COUNTRY_LIST]: (state, { payload }) =>
        immutable(state, {
          countryList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_COUNTRY_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          countryList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_COUNTRY_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          countryList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.LIST_FRAMEWORK]: (state, { payload }) =>
        immutable(state, {
          frameworkList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_FRAMEWORK_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          frameworkList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_FRAMEWORK_FAILURE]: (state, { payload }) =>
        immutable(state, {
          frameworkList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.LIST_TOPIC]: (state, { payload }) =>
        immutable(state, {
          topicList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_TOPIC_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          topicList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_TOPIC_FAILURE]: (state, { payload }) =>
        immutable(state, {
          topicList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.LIST_ASSIGNEE]: (state, { payload }) =>
        immutable(state, {
          assigneeList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_ASSIGNEE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          assigneeList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_ASSIGNEE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          assigneeList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.LIST_GRID_REGIONS]: (state, { payload }) =>
        immutable(state, {
          gridRegions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_GRID_REGIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          gridRegions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_GRID_REGIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          gridRegions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_MANAGER_LIST]: (state, { payload }) =>
        immutable(state, {
          listManager: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_MANAGER_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          listManager: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_MANAGER_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          listManager: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.COMPANY_COUNTRY_LIST]: (state, { payload }) =>
        immutable(state, {
          companyCountries: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.COMPANY_COUNTRY_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          companyCountries: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.COMPANY_COUNTRY_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          companyCountries: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.COMPANY_FACILITY_LIST]: (state, { payload }) =>
        immutable(state, {
          companyFacilities: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.COMPANY_FACILITY_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          companyFacilities: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.COMPANY_FACILITY_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          companyFacilities: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.COMPANY_USERS_LIST]: (state, { payload }) =>
        immutable(state, {
          companyUsers: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.COMPANY_USERS_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          companyUsers: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.COMPANY_USERS_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          companyUsers: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.COMPANY_FACILITY_DETAILS]: (state, { payload }) =>
        immutable(state, {
          facilityDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.COMPANY_FACILITY_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          facilityDetails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.COMPANY_FACILITY_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          facilityDetails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_USER_STATISTICS]: (state, { payload }) =>
        immutable(state, {
          userStatistics: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_USER_STATISTICS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          userStatistics: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_USER_STATISTICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          userStatistics: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
        [ActionTypes.GET_USERS_LIST]: (state, { payload }) =>
        immutable(state, {
          usersList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_USERS_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          usersList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_USERS_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          usersList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
        [ActionTypes.COMPANY_USER_DETAILS]: (state, { payload }) =>
        immutable(state, {
          companyUserDetail: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.COMPANY_USER_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          companyUserDetail: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.COMPANY_USER_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          companyUserDetail: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
        [ActionTypes.TOTAL_USER_DETAILS]: (state, { payload }) =>
        immutable(state, {
          totalUsersDetail: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.TOTAL_USER_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          totalUsersDetail: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.TOTAL_USER_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          totalUsersDetail: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      
        [ActionTypes.GET_ORGANIZATION_COUNTRY]: (state, { payload }) =>
        immutable(state, {
            getOrganizationCountry: {
                status: { $set: STATUS.RUNNING }
            }
        }),
        [ActionTypes.GET_ORGANIZATION_COUNTRY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getOrganizationCountry: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
            }
        }),
        [ActionTypes.GET_ORGANIZATION_COUNTRY_FAILURE]: (state, { payload }) =>
            immutable(state, {
              getOrganizationCountry: {
                    status: { $set: STATUS.ERROR },
                    message: { $set: parseError(payload) }
                }
            }),

        [ActionTypes.GET_STAKEHOLDER_COMPANY]: (state, { payload }) =>
        immutable(state, {
          stakeholderCompany: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_COMPANY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          stakeholderCompany: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_COMPANY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          stakeholderCompany: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_FACILITY_PERIODS]: (state, { payload }) =>
        immutable(state, {
          getFacilityPeriods: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_FACILITY_PERIODS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getFacilityPeriods: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_FACILITY_PERIODS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getFacilityPeriods: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
        [ActionTypes.GET_AUDIT_TYPES]: (state, { payload }) =>
        immutable(state, {
          getAuditTypes: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_AUDIT_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getAuditTypes: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_AUDIT_TYPES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getAuditTypes: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_STATES]: (state, { payload }) =>
          immutable(state, {
            getStates: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_STATES_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            getStates: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.GET_STATES_FAILURE]: (state, { payload }) =>
          immutable(state, {
            getStates: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),

          [ActionTypes.GET_ASSESSMENT_YEAR]: (state, { payload }) =>
            immutable(state, {
              getAssessmentYear: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.GET_ASSESSMENT_YEAR_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              getAssessmentYear: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.GET_ASSESSMENT_YEAR_FAILURE]: (state, { payload }) =>
            immutable(state, {
              getAssessmentYear: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),
    },
    listState
  ),
};

export default listActions;
