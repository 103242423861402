import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
  
    content: {
      display: "flex",
      backgroundColor: "#F9F4F6",
      padding: "20px",
      borderRadius: "6px",
      width: "40%",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
    },
  
    closeIcon: {
      position: "absolute",
      top: "10px",
      right: "15px",
      cursor: "pointer",
    },
  
    h2: {
      marginTop: 0,
    },
    b: {
      fontWeight: "600",
    },
    p: {
      marginBottom: "20px",
      marginTop: "50px",
    },
  
    btn: {
      width: "40%",
      padding: "10px 20px",
      marginRight: "10px",
      border: "none",
      borderRadius: "6px",
      background: "#8C2148",
      color: "#fff",
      fontSize: "16px",
      cursor: "pointer",
      height: "35px",
      "&:hover": {
        background: "#8C2148",
      },
    },
  
    backdrop: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(0, 0, 0, 0.5)",
      zIndex: 999,
      pointerEvents: "none",
    },

    dropdown: {
        width: "200px",
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
          backgroundColor: theme.palette.background.white,
        },
    },
    primaryBtn: {
      height: 36,
      borderRadius: 5,
      borderWidth: 1,
      padding: theme.spacing(1.5, 4),
      marginRight: theme.spacing(2),
      width: 80,
      borderColor: theme.palette.Primary.main,
      backgroundColor: theme.palette.Primary.background,
      textTransform: "capitalize",
      '&:hover': {
        backgroundColor: theme.palette.Primary.hoverButton,
      },
      '&:disabled': {
        color: theme.palette.Actions.disabled,
        borderColor: theme.palette.action.disabledBackground,
        backgroundColor: theme.palette.Actions.disabledBackground,
      },
    },
  secondaryBtn: {
    height: 36,
    padding: theme.spacing(1.5, 4),
    borderWidth: 1,
    borderRadius: 5,
    borderStyle: 'solid',
    color:theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    backgroundColor: theme.palette.background.white,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Secondary.hoverButton,
    },
    '&:disabled': {
      color: theme.palette.Actions.disabled,
      borderColor: theme.palette.Actions.disabledBackground,
    },
  },
  input:{
    backgroundColor: theme.palette.background.white
  },
  menuItem:{
    maxWidth: "none !important",
  }
  }));
  export default useStyles;