import * as yup from 'yup';

export const schemeValidation = yup.object({
  facility: yup.string().required('Facility is required'),
  localCommunity: yup.string().required('Name of local community is required'),
  year: yup.string().required('Year is required'),
  amount: yup.number().required('Total Contributions is required').positive('Total Contributions must be a positive number'),
  assessmentPeriod: yup.string().required('Assessment Period is required'),
  projectUndertaken: yup.string().required('Describe the projects undertaken is required'),
  objective: yup.string().required('Describe the main objective of the projects is required'),
  activitiesExecuted: yup.string().required('Describe activities that were executed is required'),
  resultAchieved: yup.string().required('Summarize the results achieved is required'),
});