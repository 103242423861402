import * as yup from 'yup';

export const schemeValidation = yup.object({
    projectName: yup.string().required('Project Name is required'),
    projectType: yup.string().required('Project Type is required'),
    startDate: yup.date().required('Start Date is required'),
    location: yup.string().required('Location is required'),
    co2Reduction: yup.number().required('CO2 reduction is required'),
    endDate: yup.date().required('End Date is required'),
    certification: yup.string().required('Certification is required'),
    carbonVerified: yup.boolean().required('Is verified by third party organization is required'),
    details: yup.string().required('Details is required'),
  });
  