import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    padding: theme.spacing(4, 0),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 100,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  dialog: {
    width: "56%",
    "& .MuiPaper-root.MuiDialog-paper": {
      width: "40vw",
      margin: 80,
    },
  },
  popupContent:{
    textAlign: "center"
  },
  promptMessage: {
    fontWeight: 450,
    marginBottom: theme.spacing(3),
  },
  title:{
    color: theme.palette.Primary.background,
    fontWeight: 600,
    textAlign: "center",
    width: "60%",
    margin: theme.spacing(5,"auto"),
    textTransform: "uppercase",
  },
  infoContainer:{
    display: "flex",
    alignItems: "center"
  },
  icon:{
    fontSize: "xx-large",
    color: theme.palette.Primary.background,
  },
  viewMoreLink:{
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
    marginTop: theme.spacing(5)
  },
  assessementContent:{
    padding: theme.spacing(4)
  },
  subTitle:{
    fontWeight: 550,
    textAlign: "left",
    marginLeft:  theme.spacing(5),
  },
  promptList:{
    margin:  theme.spacing(5, 0, 0, 5 ),
  },
  listItem:{
    textAlign: "left",
  }
}));

export default useStyles;
