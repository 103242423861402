import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addSocialHumanRightsTraining,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroSelect from "../../../components/CeroSelect";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";

const AddSocialHumanRightsTrainingForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const addEmissionData = useSelector(
    (state) => state.emission.addSocialHumanRightsTraining
  );
  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      riskOfChildLabor: "",
      childLaborDetails: "",
      riskOfCompulsoryLabor: "",
      compulsoryLaborDetails: "",
      actionRequired: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'social_engagement_human_rights_training'));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Human Rights added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddSocialHumanRightsTraining = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      is_at_risk_of_child_labor: formik.values.riskOfChildLabor,
      child_labor_details: formik.values.childLaborDetails,
      is_at_risk_for_compulsory_labor: formik.values.riskOfCompulsoryLabor,
      compulsory_labor_details: formik.values.compulsoryLaborDetails,
      incident_or_action_occurred: formik.values.actionRequired,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addSocialHumanRightsTraining(requestData));
  };

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Human Rights
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroQuestionWithCheckbox
              required
              question="Indicate whether there is a significant risk of child labor within your organization's operations."
              formik={formik}
              fieldName="riskOfChildLabor"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.riskOfChildLabor === "Yes" &&
            <CeroInput
              id="childLaborDetails"
              name="childLaborDetails"
              label="If Yes, provide an explanation of the risk and the measures taken to address it."
              fullWidth
              multiline
              rows="2"
              value={formik.values.childLaborDetails}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.childLaborDetails && formik.errors.childLaborDetails}
              classes={{ container: classes.textAreaContainer }}
            />}
            <CeroQuestionWithCheckbox
              required
              question="Indicate whether there is a significant risk of forced or compulsory labor within your organization's operations."
              formik={formik}
              fieldName="riskOfCompulsoryLabor"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.riskOfCompulsoryLabor === "Yes" &&
            <CeroInput
              id="compulsoryLaborDetails"
              name="compulsoryLaborDetails"
              label="If Yes, provide an explanation of the risk and the measures taken to address it."
              fullWidth
              multiline
              rows="2"
              value={formik.values.compulsoryLaborDetails}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.compulsoryLaborDetails && formik.errors.compulsoryLaborDetails}
              classes={{ container: classes.textAreaContainer }}
            />}
            <CeroInput
              required
              id="actionRequired"
              name="actionRequired"
              label="Capture any incidents and actions taken related to human rights that your organization is aware of."
              fullWidth
              multiline
              rows="2"
              value={formik.values.actionRequired}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.actionRequired && formik.errors.actionRequired}
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid || addEmissionData.status === STATUS.RUNNING}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddSocialHumanRightsTraining}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddSocialHumanRightsTrainingForm;
