import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Paper, Typography } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getAuditStatusGraph } from "../../../redux/actions";
import { getCookie } from "../../../services/cookie";

const AuditStatusGraph = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const auditData = useSelector(state => state.dashboard.getAuditStatusGraph.data.response);

    const role = getCookie('role');
    if (role === 'auditor') {
     var userRole = 'auditor';
    } else {
      userRole = 'business';
    }


    useEffect(() => {
        dispatch(getAuditStatusGraph(userRole));
    }, [dispatch,userRole]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "pie",
      data: {
        labels:auditData&&auditData?.map((entry) => capitalizeFirstLetter(entry.status)),
        datasets: [
          {
            data: auditData&&auditData?.map((entry) => entry.total_audits),
            backgroundColor: [
            //   "#FBCE74",
              "#846BB5",
              "#6FBFBA",
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              padding: 30,
              color: "black"
            },
          },
          datalabels: {
            color: "white",
            font:{
              weight:"bold",
              size:12
            },
            formatter: (value, context) => {
              return value.toFixed(0);
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2,
        layout: {
          padding: {
            bottom: 20,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [auditData]);

  return (
    <Paper className={classes.container}>
        <Typography className={classes.graphTitle} >Audit Status</Typography>
        <div className={classes.pieChart}>
      <canvas ref={chartRef} />
      </div>
      </Paper>
  );
};

export default AuditStatusGraph;