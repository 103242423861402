import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing( 3),
    height: "100%",
    width: "100%",
    maxWidth: "100%",
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: "#686868 !important"
    },
  },
  filterContainer: {
    display: "flex",
    padding: theme.spacing(4),
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  },
  topics: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    // padding: theme.spacing(4),
    width: "100%",
    margin: "auto",
  },
  containerBox: {
    boxShadow: "none !important",
    borderRadius: 5,
    height: "fit-content",
    width: "80%",
    margin: "auto",
    zIndex: 1,
    position: "relative",
    marginTop: "-5px",
    "& .MuiPaper-root": {
      boxShadow: "none !important",
    },
  },
  containerList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing( 3),
    borderBottom: "1px solid black",
  },
  headings: {
    fontWeight: 700,
    fontSize: 20,
    paddingTop: theme.spacing(4),
    whiteSpace: "nowrap",
  },
  headingTitle: {
    color: theme.palette.Primary.background,
    fontWeight: 600,
  },
  button: {
    width: "150px",
    backgroundColor: `${theme.palette.Primary.background}!important`,
    fontStyle: "normal",
    fontWeight: "600!important",
    fontSize: "13px!important",
    textTransform: "capitalize",
    marginTop: theme.spacing(4),
    borderRadius: '6px',
    marginBottom: '30px',
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
      border: "2px solid",
    },
    marginRight: theme.spacing(5),
  },
  tabs: {
    zIndex: 1,
    position: "relative",
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.login.loginButton,
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      width: "70%",
      margin: "auto",
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      zIndex: 2,
      borderRadius: 5,
      backgroundColor: "#E0E0E0",
      borderTop: "1px solid #5A404E",
      borderLeft: "1px solid #5A404E",
      borderRight: "1px solid #5A404E",
      '@media screen and (min-width: 1706px) and (min-height: 377px)':{
        width: "55% !important",
      }
    },
    "& .MuiTab-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiTab-root.Mui-selected": {
      color: `${theme.palette.login.loginButton} !important`,
    },
  },
  tabOption: {
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: 24,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  mainTitle: {
    padding: 0,
    margin: theme.spacing(0, 0, 10, 20),
  },
  headingText: {
    position: "relative",
  },
  underline: {
    position: "absolute",
    bottom: theme.spacing(-2),
    left: 0,
    width: "100%",
    height: 1,
    background:
      "linear-gradient(90deg, #FFFFFF 0%, #8F3052 30%, #8F3052 70%, #FFFFFF 100%)",
    opacity: 0.5,
  },
  tabTitle: {
    marginLeft: 8,
  },
  tabLabel: {
    display: "flex",
    alignItems: "center",
  },
  subtopicLabel: {
    "& .subtopicTitle": {
      color: theme.palette.text.dark,
    },
    "&:hover .subtopicTitle": {
      color: theme.palette.Primary.background,
      cursor: "pointer",
      fontWeight: 700,
      fontSize: 16,
    },
    "&.clicked .subtopicTitle": {
      color: theme.palette.Primary.background,
      fontWeight: 700,
      fontSize: 16,
    },
    "&:not(:hover) .clicked .subtopicTitle": {
      color: theme.palette.text.dark,
    },
  },
  tabsContainer: {
    position: "relative",
    backgroundColor: "#F9F4F6",
  },
  tabBorder: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "3px",
    backgroundColor: "#5A404E",
  },
  bottomBox: {
    borderBottom: "3px solid #5A404E",
    borderLeft: "3px solid #5A404E",
    borderRight: "3px solid #5A404E",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: theme.spacing(1, 5, 3, 5),
  },
  textAreaContainerId: {
    width: "15%",
  },
  textAreaContainerValue: {
    width: "70%",
  },

  addTaskButton: {
    borderRadius: "40px",
    border: "1px solid #af647f",
    color: "#8f264c",
    backgroundColor: "#f9f4f6",
    textTransform: "none",
    transition: "none",
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "initial",
    },
  },

  inputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  ceroSelectContainer: {
    width: "35%",
  },
  ceroInputContainer: {
    width: "64%",
  },
  saveButton: {
    width: "100px",
    backgroundColor: `${theme.palette.Primary.background}!important`,
    fontStyle: "normal",
    fontWeight: "600!important",
    fontSize: "13px!important",
    textTransform: "capitalize",
    marginRight: "15px",
    borderRadius: "6px",
    marginTop: theme.spacing( 3 ),
  },
  cancelButton: {
    width: "100px",
    backgroundColor: "white",
    fontStyle: "normal",
    fontWeight: "600!important",
    fontSize: "13px!important",
    textTransform: "capitalize",
    border: "1px solid #af647f",
    borderRadius: "6px",
    color: "#af647f",
    transition: "none",
    boxShadow: "none",
    marginTop: theme.spacing( 3 ),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "initial",
    },
  },
  textContainer: {
    display: "flex",
  },
  textFieldLabelId: {
    width: "16%",
    color: "grey",
    fontSize: "16px",
    fontWeight: "500",
    marginLeft: "2px",
  },
  textFieldLabel: {
    width: "70%",
    color: "grey",
    fontSize: "16px",
    fontWeight: "500",
  },
  autoComplete: {
    color: "blue",
    "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
      padding: 0,
      // marginTop: 13,
      backgroundColor: theme.palette.background.white,
    },

    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
      padding: "4.5px 4px 7.5px 5px",
    },
    "& .MuiFormControl-root > .MuiInputLabel-outlined ": {
      color: "#6a475a",
      opacity: 0.5,
    },
    "& .MuiFormControl-root > .Mui-focused ": {
      color: "#6a475a",
      opacity: 10,
      marginTop: 12,
    },
    "& .MuiFormControl-root > .MuiFormLabel-filled ": {
      marginTop: 12,
    },
  },
  inputFieldContainer: {
    height: 40,
  },
  missionInput: {
    height: 40,
    maxWidth: "100%"
  },
  // paperRoot: {
  //   left: "27% !important",
  // },
}));

export default useStyles;
