import { Container, Box, Chip, Typography } from "@mui/material";
import CeroTable from "../../../components/CeroTable";
import { months } from "../../../constants";
import dayjs from "dayjs";

import useStyles from "./styles";

export const approvalMonthlySummaryColumns = [
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Assessment Year",
  },
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

const MonthlySummaryTable = (props) => {
  const classes = useStyles();
  const { onSelectApprovalSummaryData, summaryData } = props;

  const getStatus = (status) => {
    if (status === 'approved') {
        return <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'assigned') {
        return <Typography className={classes.assigned}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'review') {
      return <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'reported') {
        return <Typography className={classes.reported}>Reported</Typography>;
    } else if (status === 'submitted') {
      return <Typography className={classes.submitted}>Pending Approval</Typography>;
    } else if (status === 'added') {
      return <Typography className={classes.added}>Added</Typography>; 
    } else if (status === 'verified') {
      return <Typography className={classes.verified}>Verified</Typography>; 
    } else if (status === 'In Progress') {
      return <Typography className={classes.submitted}>In Progress</Typography>;
    } else {
        return <Typography className={classes.default}>{status}</Typography>;
    }
};

  const getSummaryData = () =>
    summaryData?.map((item) => ({
      ...item,
      requested_on: item.assigned_on ? dayjs(item.assigned_on).format('DD/MM/YYYY') : '-',
      month: (
        <Box key={item.month}>
          {months.find((month) => month.key === item.month)?.value}
        </Box>
      ),
      status: getStatus(item.status),
    }));

  return (
    <Container className={classes.container}>
      <CeroTable
        columns={approvalMonthlySummaryColumns}
        data={getSummaryData()}
        hasMore={false}
        loading={false}
        onSelectRow={onSelectApprovalSummaryData}
        useExtraStyles={true}
        useModifiedHeader={true}
        classes={{ tableContainer: classes.tableContainer }}
      />
    </Container>
  );
};

export default MonthlySummaryTable;
