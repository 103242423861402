import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "calc(100vh - 140px)",
  },
  button: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.light,
    },
    marginRight: theme.spacing(2),
    borderRadius: 10,
    cursor: "pointer"
  },
  buttonPrimary:{
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.light,
    },
    marginRight: theme.spacing(2),
    padding: 0,
    borderRadius: 5,
    cursor: "pointer"
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    color: theme.palette.Primary.background,
    cursor: "pointer"
  },
  linkButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
    fontWeight: 400,
  },
  icon: {
    marginRight: "5px",
    fontSize: "x-large"
  },
  switchColor: {
    "& .MuiSwitch-track": {
      backgroundColor: "red !important",
      opacity: "5 !important",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white !important",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: `${theme.palette.status.closed} !important`,
      opacity: "1.5 !important",
    },
  },
  inactiveSwitchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "red",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "grey",
      opacity: 1.5,
    },
  },
  statusText: {
    fontSize: 13,
  },
  activeStatus:{
    color: theme.palette.status.closed,
    fontWeight:500,
    fontSize: 14
  },
  lockedStatus:{
    color:  '#E81111',
    fontWeight:500,
    fontSize: 14
  },
  invitedStatus:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  nonActiveStatus: {
    color: theme.palette.status.added,
    fontWeight:500,
    fontSize: 14
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer"
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 15,
    width: "40%",
    margin: "auto"
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
  buttonContainer:{
    display: "flex", 
    justifyContent: "flex-start",
    paddingTop: 20,
    paddingBottom: 15
  },
  textField: {
    backgroundColor: theme.palette.background.white,
  },
  noComments: {
    float: "left",
    fontFamily: "Arial, sans-serif",
    fontSize: 12
  },
  maxCharacters: {
    float: "right",
    fontSize: 12
  },
  dialog:{
    width: "30%",
    "& .MuiPaper-root.MuiDialog-paper": {
     width: "40vw",
     margin: 80,
   },
   },
  textLimit:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10
  },
}));

export default useStyles;
