import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    margin: theme.spacing(4, 0),
    position: "relative",
    lineHeight:"1rem",
    borderRadius: 8,
  },
  infoContainer: {
    maxWidth: 400,
    display:"flex !important",
    justifyContent: 'flex-start'
  },
  title:{
    width:"50%"
  },
  waitingIndicationContainer: {
    height: 10,
    width: 30,
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
  waitingIndication: {
    height: 30,
    width: 30,
    cursor: "pointer",
  },
  open:{
    color: theme.palette.status.open,
    textTransform: "capitalize",
  },
  closed:{
    color: theme.palette.status.closed,
    textTransform: "capitalize",
  },
  companyValue:{
    color: '#1D1D1D',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    width: "30px"
  }
}));

export default useStyles;
