import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    filterContainer: {
        height: 40
    },
    searchTextContainer: {
        height: 34,
        minHeight: 34,
        backgroundColor: theme.palette.background.white,
        "& .MuiTextField-root.MuiFormControl-root-MuiTextField-root":{
            height: 40
        }
    },
    searchTextInput:{
        border: "none"
    },
    button: {
        width: '100%',
        fontWeight: '400',
        textTransform: "capitalize",
        background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.background,
            color: theme.palette.Primary.light,
          },
    },
    container: {
        padding: 0,
        margin: 0,
    },
    select: {
        padding: theme.spacing(2),
    },
    input: {
        padding: 0,
    },
    autoComplete:{
        color:"blue",
        "& .MuiAutocomplete-root .MuiOutlinedInput-root":{
            padding: 0,
            marginTop: 13,
            backgroundColor: theme.palette.background.white,
        },

        "& .MuiOutlinedInput-root .MuiAutocomplete-input":{
            padding: '4.5px 4px 7.5px 10px',
        },
        "& .MuiFormControl-root > .MuiInputLabel-outlined ":{
            color: '#6a475a',
            opacity: 0.5,
            paddingTop: theme.spacing(1),
        },
        "& .MuiFormControl-root > .Mui-focused ":{
            color: '#6a475a',
            opacity: 10,
            marginTop:12
        },
        "& .MuiFormControl-root > .MuiFormLabel-filled ":{
            marginTop:12
        },
    },

    labels: {
        display: "flex",
        background: "white",
        marginRight: 2,
        alignItems: "center",
        letterSpacing: 0,
        "justCheckSelected-66 > fieldset > legend": {
          display: "none !important",
        },
      },
      labelText: {
        color: "black",
        fontSize: 14,
        letterSpacing: 0,
        fontWeight: 400,
      },
    
    
}));

export default useStyles;
