import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        height: 'fit-content',
        "@media (min-width: 1200px)": {
            maxWidth: "none",
          },
    }
}));

export default useStyles;