import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    // container: {
    //     padding: "auto 1%",
    //     '@media (min-width: 1200px)': {
    //         maxWidth: "none"
    //       }
    // },
    card:{
        borderRadius: 8,
        height: 130,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    },
    count:{
        fontWeight: 500,
    },
    loading:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiCircularProgress-root':{
            color: theme.palette.Primary.background,
        }
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        cursor: 'pointer',
        color: '#6A6969',
        whiteSpace: "nowrap"
    },
    titleReports:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: 14,
        cursor: 'pointer',
        color: '#6A6969',
        whiteSpace: "nowrap"
    },
    valueContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    value: {
        fontSize: 32,
        fontWeight: 500,
    },
    name: {
        fontSize: 12,
        color: '#6A6969'
    },
    gridItem:{
        width: "20%"
    },
    cardsContainer:{
        marginTop: theme.spacing(3),
    },
    companyName:{
        fontWeight: 600,
        padding: theme.spacing(2)
    },
    cardContent:{
        height: "100%",
        margin: "auto",
        display: "flex",
        alignItems: "center"
    },
    // gridContainer: {
    //     height: 'calc(100% - 64px)',
    //     marginTop: theme.spacing(4.5),
    // },
    container: {
        padding: 0,
        width: '100%',
        maxWidth: '100%',
    },
    gridContainer: {
        height: 'calc(100% - 64px)',
        marginBottom: theme.spacing(5),
    },
    companyCard:{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
        borderRadius: 8,
        marginBottom: theme.spacing(4)
    }
}));

export default useStyles;