import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "calc(100vh - 140px)",
  },
  actionContainer: {
    display: "flex",
  },
  button: {
    backgroundColor: theme.palette.Secondary.main,
  },
  editIcon: {
    marginLeft: theme.spacing(3),
  },
  Open: {
    color: theme.palette.status.open,
    fontWeight: 500,
    fontSize: 14,
  },
  Close: {
    color: theme.palette.status.closed,
    fontWeight: 500,
    fontSize: 14,
  },
}));

export default useStyles;
