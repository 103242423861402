import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CeroAutoComplete from "../../../../components/CeroAutoComplete";
import { sampleYear } from "../../../../constants";

import useStyles from "../styles";
import CeroChipSelect from "../../../../components/CeroChipSelect";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "./ReportTable";
import { getSuperAdminReport } from "../../../../redux/actions";
import ReactCountryFlag from "react-country-flag";

const Reports = ({companyId}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [country, setCountry] = useState();
  const [facility, setFacility] = useState();
  const [year, setYear] = useState();
  const [facilities, setFacilities] = useState([]);
  const reportData = useSelector(state => state.superAdmin.getSuperadminReportsData.data);
  const createData = (country, facility, year, period, reportCount, facility_id, country_code) => {
    return { country, facility, year, period, reportCount, facility_id, country_code };
  };
  const data = reportData.map(item=> createData(item.country, item.facility, item.year, item.period, item.count, item.facility_id, item.country_code));
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));
  const countryData = useSelector(state => state.superAdmin.getOrganizationCountry.data.country_data);
  const countryList = Object.keys(countryData).map((item) => ({
    id: countryData[item],
    label: item,
  }));

  const countryFacilityData = useSelector(state => state.superAdmin.getOrganizationCountry.data.country_facility_data);

useEffect(() => {
    const facilityList = [];
    for (const countryIns in countryFacilityData) {
      for (const facilityIns in countryFacilityData[countryIns]) {
          facilityList.push({
              id: countryFacilityData[countryIns][facilityIns],
              label: facilityIns
          });
      }
  }
  setFacilities(facilityList)
  }, [countryFacilityData]);

  useEffect(() => {
    const facilityList = [];
        if (country){
            for (const facility in countryFacilityData[country]) {
                facilityList.push({
                    id: countryFacilityData[country][facility],
                    label: facility
                });
        }
        }
        else{
            for (const countryIns in countryFacilityData) {
                for (const facilityIns in countryFacilityData[countryIns]) {
                    facilityList.push({
                        id: countryFacilityData[countryIns][facilityIns],
                        label: facilityIns
                    });
                }
            }
        }
      
  setFacilities(facilityList)
  }, [country]);
  
useEffect(() => {
    dispatch(getSuperAdminReport(companyId, parseInt(year), country, facility));
    // dispatch(getCompanyDetails(companyId))
  }, [dispatch, facility, country, year]);
  
  
  return (
    <Container className={classes.container}>
      <Grid
        className={classes.filterContainer}
        container
        columnSpacing={3}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs={3.5} className={classes.yearFilter}>
          <CeroAutoComplete 
          id="country"
          label="Country" 
          onChange={(e, item) => setCountry(item ? item.id : null)}
          formControlClass={classes.MultiSelectformControl} 
          options={countryList}
          />
        </Grid>
        <Grid item xs={3.5} className={classes.yearFilter}>
        <CeroAutoComplete
        id="facility"
        label="Facility" 
        onChange={(e, item) => setFacility(item ? item.id : null)}
        formControlClass={classes.MultiSelectformControl} 
        options={facilities}/>
        </Grid>
        <Grid item xs={3.5} className={classes.yearFilter}>
          <CeroAutoComplete
            id="year"
            label="Year"
            onChange={(e, item) => setYear(item ? item.id : null)}
            options={yearList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>
      </Grid>
      <TableComponent data={data}/>
    </Container>
  );
};

export default Reports;
