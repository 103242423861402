import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {  totalUserDetails, updateBusinessAdminStatus } from "../../../redux/actions";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Container, Stack, Switch, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const TotalUsersColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "company",
    columnId: "company",
    columnHeader: "Company",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

export const TotalUsersAdminColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "company",
    columnId: "company",
    columnHeader: "Company",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Action",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

export const ActiveUsersColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
    },
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
    },
    {
      columnKey: "company",
      columnId: "company",
      columnHeader: "Company",
    },
  ];
  export const ActiveUsersAdminColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
    },
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
    },
    {
      columnKey: "company",
      columnId: "company",
      columnHeader: "Company",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
    },
  ];

const UserDetailsTable = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userType, isActive } = useParams();
  const [userList, setUserList] = useState([]);
  const user = userType.replace(/ /g, "_");

  const userDetails = useSelector(
    (state) => state.listings.totalUsersDetail.data
  );
  useEffect(() => {
    setUserList(userDetails);
  }, [userDetails]);

  useEffect(() => {
      dispatch(totalUserDetails(user, isActive));
  }, [dispatch]);
  const handleToggleStatus = (id, status) => {
    console.log(id);
    const updatedUserList = userList.map((item) =>
      item.id === id ? { ...item, lock_status: status } : item
    );
    
    setUserList(updatedUserList);
    
    dispatch(updateBusinessAdminStatus(id, status));
  }
  const getTotalUsers = () =>
  userList.map((item) => ({
      ...item,
      name: item.name ? item.name : "",
      email: item.email ? item.email : "",
      company: item.company ? item.company : "",
      status: item.status ? (<Typography className={classes.activeStatus}>Active</Typography>) 
      : (<Typography className={classes.inActiveStatus}>Inactive</Typography>),
      action: user === 'admin' ?(
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Unlock
          </Typography>
          <Switch
            checked={item.lock_status}
            className={classes.switchColor}
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            disabled={!item.lock_status}
          />
          <Typography
            style={{ fontWeight: item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Lock
          </Typography>
        </Stack>
      ):"",    }));

    const getActiveUsers = () =>
    userList.map((item) => ({
      ...item,
      name: item.name ? item.name : "",
      email: item.email ? item.email : "",
      company: item.company ? item.company : "",
      action: user === 'admin' ?(
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Unlock
          </Typography>
          <Switch
            checked={item.lock_status}
            className={classes.switchColor}
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            disabled={!item.lock_status}
          />
          <Typography
            style={{ fontWeight: item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Lock
          </Typography>
        </Stack>
      ):"",
    }));


  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container>
        {isActive === "true" ? (
          <>
          <Typography variant="h6">Active {userType}s</Typography>
          <CeroTable
            columns={user === 'admin' ? ActiveUsersAdminColumns : ActiveUsersColumns}
            data={getActiveUsers()}
            hasMore={false}
            loading={false}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </>
        ) : (
          <>
            <Typography variant="h6">Total {userType}s</Typography>
            <CeroTable
              columns={user === 'admin' ? TotalUsersAdminColumns:TotalUsersColumns}
              data={getTotalUsers()}
              hasMore={false}
              loading={false}
              classes={{ tableContainer: classes.tableContainer }}
            />
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default UserDetailsTable;
