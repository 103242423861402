import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 110px)",
    marginBottom: theme.spacing(5 ),
  },
  tableHeader: {
    backgroundColor: "#D0C5CA",
    fontWeight: "bold",
    textAlign: "center",
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  tableCell: {
    textAlign: "left",
    margin: "auto",
  },
  noDataWarning: {
    textAlign: "center",
    background: "white",
    verticalAlign: "center"
  }
}));

export default useStyles;
