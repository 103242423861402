import React, { useEffect, useState } from "react";
import { Box, Container, IconButton, InputAdornment, Typography } from "@mui/material";
import useStyles from "./styles";
import CeroButton from "../../components/CeroButton";
import OTPInput from "react-otp-input";
import { adminPasswordSubmit, adminTwofaOtpGenerate, adminTwofaOtpSubmit, superAdminTwofaValidation } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { resendOtp } from "../../redux/actions";
import { clearOtp } from "../../redux/actions";
import ceroed from "../../assets/ceroed.png";
import { STATUS } from "../../redux/constants";
import { getCookie } from "../../services/cookie";
import CeroInput from "../../components/CeroInput";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormik } from "formik";
import ValidationSchema from './ValidationSchema';
import { useNavigate } from "react-router-dom";


const AdminTwoFaOtpValidation = () => {
  const classes = useStyles();
  const [otp, setOTP] = useState("");
  const [currentTime, setCurrentTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isPasswordEnter, setIsPasswordEnter] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state) => state.auth.userInfo.id);
  const adminOtpSubmitData = useSelector((state) => state.auth.adminOtpSubmit);
  const adminOtpSubmitStatus = useSelector((state) => state.auth.adminOtpSubmit.status);
  const adminPasswordSubmitData = useSelector((state) => state.auth.adminPasswordSubmit);
  const adminPasswordSubmitStatus = useSelector((state) => state.auth.adminPasswordSubmit.status);
  // const adminPasswordSubmitStatus = useSelector((state) => state.auth.adminPasswordSubmit.status);

  const userInfoMessage = useSelector((state) => state.auth.message);
  const authStatus =  useSelector((state) => state.auth.status);
  // const otpInfo = useSelector((state) => state.auth.adminOtpSubmitData);
  console.log('userInfoStatus', authStatus)
  const userEmail = getCookie('tfa-admin');
  // dispatch(adminTwofaOtpGenerate(getCookie('tfa-admin')))

  const expiryTime = adminOtpSubmitData.data.expiry_time;
  useEffect(() => {
    dispatch(adminTwofaOtpGenerate(getCookie('tfa-admin')))
  }, [dispatch, userEmail]);
  const handleChange = (otp) => {
    setOTP(otp);
  };

  const handleSubmit = () => {
    if (otp.length !== 6) {
      enqueueSnackbar("Please input complete OTP", { variant: "error" });
      return;
    }
    
    dispatch(adminTwofaOtpSubmit(userEmail, Number(otp)));
  };
  const handlePasswordSubmit = () => {
    
    dispatch(adminPasswordSubmit(userEmail, changePasswordForm.values.password));
  };
  

  const handleClear = () => {
    setOTP("");
  };


  const handleResend = () => {
    const currentTime = Date.now();
    const newRemainingTime = expiryTime - currentTime;

    setCurrentTime(currentTime);
    setRemainingTime(newRemainingTime);
    setIsResendDisabled(true);

    dispatch(adminTwofaOtpGenerate(getCookie('tfa-admin')))
    // dispatch(clearOtp(userId));
  };

  const isOtpComplete = otp.length === 6;

  useEffect(() => {
    if (adminOtpSubmitStatus === STATUS.SUCCESS) {
      setIsPasswordEnter(true)
    } else if(adminOtpSubmitStatus === STATUS.ERROR) {
      enqueueSnackbar(adminOtpSubmitData.message, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  }, [adminOtpSubmitStatus, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (adminPasswordSubmitStatus === STATUS.SUCCESS) {
    navigate("/dashboard");
    } else if(adminPasswordSubmitStatus === STATUS.ERROR) {
      enqueueSnackbar(adminPasswordSubmitData.message, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  }, [adminPasswordSubmitStatus, enqueueSnackbar, dispatch]);

  useEffect(() => {
    let interval = null;

    if (remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime((prevRemainingTime) => prevRemainingTime - 1000);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }

    return () => clearInterval(interval); 
  }, [remainingTime]);

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  useEffect(()=>{
    dispatch(clearOtp(userId))
  },[])
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
};
const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
};
const handleMouseDownPassword = (event) => {
  event.preventDefault();
};
  const changePasswordForm = useFormik({
    initialValues: {
        password: '',
        confirmPassword: ''
    },
    validationSchema: ValidationSchema
});

  return (
    <Box className={classes.mainBody}>
      <Box>
            <img src={ceroed} alt="ceroED Logo" className={classes.logo}/>
          </Box>
      <Container className={classes.container}>
      {!isPasswordEnter?
          (<Box className={classes.box}>
            <Typography variant="h6" className={classes.title}>
              Enter Verification Code
            </Typography>
            <Box>
              <OTPInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                renderSeparator={
                  <span style={{ color: "#8d2249" }}>&nbsp;•&nbsp;</span>
                }
                isInputNum={true}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "40px",
                  height: "40px",
                  margin: "0 5px",
                  textAlign: "center",
                  border: "1.5px solid #8d2249",
                  borderRadius: "8px",
                }}
              />
            </Box>
            <Typography className={classes.resend}>
              <span> Didn't receive OTP? </span>&nbsp;&nbsp;
              {remainingTime > 0 ? (
                <span>
                  Try again in {millisToMinutesAndSeconds(remainingTime)}
                </span>
              ) : (
                <span
                  className={classes.resendText}
                  onClick={isResendDisabled ? null : handleResend}
                >
                  Resend
                </span>
              )}
            </Typography>
  
            <div className={classes.buttonContainer}>
              <CeroButton
                color="secondary"
                onClick={handleClear}
                className={classes.buttonClear}
                buttonText="Clear"
              ></CeroButton>
              <CeroButton
                onClick={handleSubmit}
                disabled={!isOtpComplete}
                className={classes.buttonSave}
                buttonText="Submit"
              ></CeroButton>
            </div>
          </Box>):(
            <Box className={classes.mainBodyPassword}>
              <CeroInput
                        type={showNewPassword ? "text" : "password"}
                        id='password'
                        label='New password'
                        value={changePasswordForm.values.password}
                        onChange={changePasswordForm.handleChange}
                        onBlur={changePasswordForm.handleBlur}
                        error={changePasswordForm.errors.password}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleNewPasswordVisibility}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            </InputAdornment>
                          }
                    />
                    <CeroInput
                        type={showConfirmPassword ? "text" : "password"}
                        id='confirmPassword'
                        label='Confirm password'
                        value={changePasswordForm.values.confirmPassword}
                        onChange={changePasswordForm.handleChange}
                        onBlur={changePasswordForm.handleBlur}
                        error={changePasswordForm.errors.confirmPassword}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleConfirmPasswordVisibility}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            </InputAdornment>
                          }
                    />
                    <Box className={classes.buttonContainer}>
                        <CeroButton
                            variant="contained"
                            onClick={handlePasswordSubmit}
                            buttonText="Submit"
                            className={classes.buttonPrimary}
                            disabled={!changePasswordForm.dirty || !changePasswordForm.isValid }
                            fullWidth
                        />
                    </Box>
            </Box>
          )
          }
        
      </Container>
    </Box>
  );
};

export default AdminTwoFaOtpValidation;