import { Typography, Container, Grid } from "@mui/material";
import React from "react";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";

const EmployeeDiversityApproval = (props) => {
  const classes = useStyles();
  const { emissionData } = props;

  return (
          <Container className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
              <Typography className={classes.heading}>Employee Diversity by Gender</Typography>
            {emissionData && emissionData?.gender_distribution?.map((item) => (
            <>
            <CeroItemPair
              title="Percentage of employees identifying as Male:"
              value={item.male ? item.male : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Percentage of employees identifying as Female:"
              value={item.female ? item.female : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Percentage of employees identifying as Others:"
              value={item.other ? item.other : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            </>
            ))}
              </Grid>
              <Grid item xs={6}>
              {emissionData && emissionData?.age_distribution?.map((item) => (
            <>
            <Typography className={classes.heading}>Employee Diversity by Age Group</Typography>
            <CeroItemPair
              title="Percentage of employees under 30 years:"
              value={item.under_30 ? item.under_30 : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Percentage of employees between 30-50 years of age:"
              value={item.between_30_50 ? item.between_30_50 : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Percentage of employees over 50 years:"
              value={item.over_50 ? item.over_50 : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            </>
            ))}
              </Grid>
            </Grid> 
            {emissionData && emissionData?.ratio_salary?.map((item) => (
            <>
            <Typography className={classes.heading}>Ratio of Basic Salary</Typography>
            <CeroItemPair
              title="Basic Salary Ratio of Male employees:"
              value={item.basic_salary_ratio_for_male ? item.basic_salary_ratio_for_male : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Basic Salary Ratio of Female employees:"
              value={item.basic_salary_ratio_for_female ? item.basic_salary_ratio_for_female : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            </>
            ))}
          </Container>
  );
};

export default EmployeeDiversityApproval;
