import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";
import { getCookie } from "../../services/cookie";

/**
 * Login
 */
export function* getAuditSummaryList() {
  try {
    const response = yield call(request, APIEndpoints.GET_AUDIT_SUMMARY, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_AUDIT_SUMMARY_SUCCESS,
      payload: response.company_audit_history,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_AUDIT_SUMMARY_FAILURE,
      payload: err,
    });
  }
}

export function* getYearlyAuditSummary({ payload }) {
  try {
    const { year, statusId } = payload;
    let apiEndpoint = null
    if (statusId)
      apiEndpoint = APIEndpoints.GET_AUDIT_SUMMARY_BY_ID(statusId)
    else if (year)
      apiEndpoint = APIEndpoints.GET_YEARLY_AUDIT_SUMMARY(year)
    if (!apiEndpoint)
      throw "API not valid"
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_YEARLY_AUDIT_SUMMARY_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_YEARLY_AUDIT_SUMMARY_FAILURE,
      payload: err,
    });
  }
}

export function* getYearlyAuditSummaryOverview({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW(payload.year,payload.statusId),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_SUCCESS,
      payload: response.yearly_audit_summary,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_FAILURE,
      payload: err,
    });
  }
}

export function* getAllquestions({ payload }) {
  try {
    const apiEndpoint = payload.isAuditor
      ? APIEndpoints.GET_ALL_AUDITOR_QUESTIONS(payload)
      : APIEndpoints.GET_ALL_QUESTIONS(payload);
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_ALL_QUESTIONS_SUCCESS,
      payload: {
        data: response.response,
        auditStatus: response.audit_status,
      }
    });
  } catch (err) {
    yield put({
      type: ActionTypes.GET_ALL_QUESTIONS_FAILURE,
      payload: err,
    });
  }
}

export function* getYearlyAuditStatusSummaryOverview({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW(payload.companyId),
      {
        method: "GET",
        payload: {
          company_id: payload.companyId,
          audit_status_id: payload.auditId,
        },
      }
    );
    yield put({
      type: ActionTypes.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_SUCCESS,
      payload: response.yearly_audit_summary,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_FAILURE,
      payload: err,
    });
  }
}

export function* requestAudit({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.REQUEST_AUDIT(payload.audit_status_id),
      {
        method: "POST",
        payload: { auditor_id: payload.auditor_id },
      }
    );

    yield put({
      type: ActionTypes.REQUEST_AUDIT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.REQUEST_AUDIT_FAILURE,
      payload: err,
    });
  }
}

export function* answerQualitativeQuestion({ payload }) {
  try {
    const role = getCookie('role')
    const apiEndpoint = role === "auditor" ? APIEndpoints.AUDITOR_QUALITATIVE_QUESTION(payload.companyId,
      payload.audit_status_id
    ) : APIEndpoints.ANSWER_QUALITATIVE_QUESTION(
      payload.audit_status_id
    );
    delete payload.audit_status_id;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload,
    });

    yield put({
      type: ActionTypes.ANSWER_QUALITATIVE_QUESTION_SUCCESS,
      payload: {...response, ...{answer: payload.answer, answer_flag: true}},
    });

    yield put({
      type: ActionTypes.RESET_QUESTION_ANSWER_STATUS,
      payload: {},
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ANSWER_QUALITATIVE_QUESTION_FAILURE,
      payload: err,
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.RESET_QUESTION_ANSWER_STATUS,
      payload: {},
    });
  }
}

export function* getAuditTrails({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_AUDIT_TRAILS(payload.companyId, payload.auditId),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_AUDIT_TRAILS_SUCCESS,
      payload: response.audit_trail_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_AUDIT_TRAILS_FAILURE,
      payload: err,
    });
  }
}

export function* getAuditorList() {
  try {
    const response = yield call(request, APIEndpoints.GET_AUDITOR_LIST, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_AUDITOR_LIST_SUCCESS,
      payload: response.auditor_response_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_AUDITOR_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* updateAuditorStatus(action) {
  try {
    const { requestData } = action.payload; 
    const response = yield call(request, APIEndpoints.UPDATE_AUDITOR_STATUS(requestData.id, requestData.status), {
      method: 'PUT',
      payload: requestData,
    });
    yield put({
      type: ActionTypes.UPDATE_AUDITOR_STATUS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_AUDITOR_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* approveAuditBySusmgr(action) {
  try {
      const { auditId, comment } = action.payload
      const response = yield call(request, APIEndpoints.APPROVE_AUDIT_BY_SUSMGR(auditId), {
          method: 'POST',
          payload: { audit_status_id: auditId, comment:comment}
      })
      yield put({
          type: ActionTypes.APPROVE_AUDIT_BY_SUSMGR_SUCCESS,
          payload: response,
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.APPROVE_AUDIT_BY_SUSMGR_FAILURE,
          payload: err
      })
  }
}

export function* setAuditTopics(action) {
  try {
      const { auditId, topics } = action.payload
      const response = yield call(request, APIEndpoints.SET_AUDIT_TOPICS(auditId), {
          method: 'PUT',
          payload: { topics: topics}
      })
      yield put({
          type: ActionTypes.SET_AUDIT_TOPICS_SUCCESS,
          payload: response,
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.SET_AUDIT_TOPICS_FAILURE,
          payload: err
      })
  }
}

export function* assignAuditor(action) {
  try {
      const { requestData } = action.payload
      const response = yield call(request, APIEndpoints.ASSIGN_AUDITOR, {
          method: 'POST',
          payload: requestData
      })
      yield put({
          type: ActionTypes.ASSIGN_AUDITOR_SUCCESS,
          payload: response,
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.ASSIGN_AUDITOR_FAILURE,
          payload: err
      })
  }
}

export function* auditorReaction(action) {
  try {
      const { companyId, auditid, topics, comment, status } = action.payload
      const response = yield call(request, APIEndpoints.AUDITOR_REACTION(companyId, auditid), {
          method: 'POST',
          payload: { topics: topics, comment:comment, status: status}
      })
      yield put({
          type: ActionTypes.AUDITOR_REACTION_SUCCESS,
          payload: response,
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.AUDITOR_REACTION_FAILURE,
          payload: err
      })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_AUDIT_SUMMARY, getAuditSummaryList),
    takeLatest(ActionTypes.GET_YEARLY_AUDIT_SUMMARY, getYearlyAuditSummary),
    takeLatest(ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW, getYearlyAuditSummaryOverview),
    takeLatest(ActionTypes.GET_ALL_QUESTIONS, getAllquestions),
    takeLatest(ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW, getYearlyAuditSummaryOverview),
    takeLatest(ActionTypes.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW, getYearlyAuditStatusSummaryOverview),
    takeLatest(ActionTypes.REQUEST_AUDIT, requestAudit),
    takeLatest(ActionTypes.ANSWER_QUALITATIVE_QUESTION,answerQualitativeQuestion),
    takeLatest(ActionTypes.GET_AUDIT_TRAILS, getAuditTrails),
    takeLatest(ActionTypes.GET_AUDITOR_LIST, getAuditorList),
    takeLatest(ActionTypes.UPDATE_AUDITOR_STATUS, updateAuditorStatus),
    takeLatest(ActionTypes.APPROVE_AUDIT_BY_SUSMGR, approveAuditBySusmgr),
    takeLatest(ActionTypes.SET_AUDIT_TOPICS, setAuditTopics),
    takeLatest(ActionTypes.ASSIGN_AUDITOR, assignAuditor),
    takeLatest(ActionTypes.AUDITOR_REACTION, auditorReaction),

  ]);
}
