import { BorderColor } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.white,
    height: "fit-content",
    width: "98%",
    borderRadius: 8,
    margin: theme.spacing("auto"),
    padding: theme.spacing(5),
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  visionBox: {
    width: "90%",
    margin: theme.spacing(5, "auto"),
    border: `0.5px solid ${theme.palette.Primary.main}`,
    borderRadius: 6,
    padding: theme.spacing(4),
  },
  label: {
    fontWeight: 600,
    marginRight: theme.spacing(2),
  },
  visionStatement: {
    borderRadius: 8,
    backgroundColor: "white",
    "& .MuiInputBase-input.MuiInput-input":{
      fontWeight: 500
    }
  },
  closeIcon: {
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: 'pointer'
  },
  buttonContainer: {
    display: "flex",
    paddingTop: 20,
    paddingBottom: 15,
    width: "100%",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginLeft: theme.spacing(5),
  },
  suggestionBox:{
    border: "0.5px solid black",
    borderRadius: 8,
    padding: theme.spacing(2),
    margin: theme.spacing(5, 0),
    backgroundColor: theme.palette.Primary.light,
  },
  dialog:{
    "& .MuiPaper-root.MuiDialog-paper": {
      backgroundColor: theme.palette.background.white
    },
  },
  quote:{
    borderRadius: 20,
    border: "0.5px solid black",
    width: "fit-content",
    padding: theme.spacing( 1, 3 ),
    margin: theme.spacing(2),
  },
  quoteContainer:{
    display: "flex",
    flexWrap: "wrap",
  },
  suggestionText:{
    fontWeight: 500
  },
  icon: {
    color: theme.palette.Primary.background,
    cursor: "pointer",
  },
  editMission:{
    fontWeight: 500,
  },
  missionTitle:{
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  hidden :{
    display: "none"
  },
  editPopup:{
    borderBottom: "0.5px solid grey",
    padding: theme.spacing( 0, 0, 4, 6),
  },
  visionInput:{
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline" :{
        borderColor:"#5A404E !important",
    }
  },
  textField: {
    // width: "25%",
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
        // backgroundColor: theme.palette.background.white,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
}));

export default useStyles;
