import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { notificationValidation } from "./schema";
import { STATUS } from "../../../redux/constants";
import {
  resetSuperAdminStatus,
  getTotalCompanies,
  getCountryList,
  generateAnnouncement,
  listAnnouncement,
} from "../../../redux/actions";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import CeroSelect from "../../../components/CeroSelect";
import CeroDateTime from "../../../components/CeroDateTime";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import DashboardLayout from "../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import CeroButton from "../../../components/CeroButton";

const AddNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [company, setCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const navigate = useNavigate();

  const addNotificationStatus = useSelector(
    (state) => state.superAdmin.generateAnnouncement
  );
  const countryListData = useSelector(
    (state) => state.listings.countryList.data
  );
  const totalCompanies = useSelector(
    (state) => state.company.totalCompanies.data
  );
  const countryList = countryListData.map((item) => ({
    id: item.code,
    label: item.name,
  }));
  const companyListOption = totalCompanies.map((item) => ({
    id: item.company_id,
    label: item.company_name,
  }));

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getTotalCompanies());
  }, [dispatch]);

  const notificationForm = useFormik({
    initialValues: {
      notificationType:  "",
      messageType:  "",
      message: "",
      startTime: 0,
      endTime: 0,
      messageStatus:  "",
      company:  "",
      country:  "",
    },
    validationSchema: notificationValidation,
    enableReinitialize: true,
    onSubmit: () => {},
  });


  useEffect(() => {
    if (addNotificationStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Announcement added successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetSuperAdminStatus());
      setCompany("");
      setCompanyName("");
      dispatch(listAnnouncement());
      navigate("/notifications")
    } else if (addNotificationStatus.status === STATUS.ERROR) {
      enqueueSnackbar(addNotificationStatus.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch(resetSuperAdminStatus());
    }
  }, [addNotificationStatus, enqueueSnackbar, dispatch]);

  const onSubmitData = () => {
    const requestData = {
      message: notificationForm.values.message,
      country: notificationForm.values.country,
      company_id: notificationForm.values.company,
      start_ts: notificationForm.values.startTime,
      end_ts: notificationForm.values.endTime,
      status: notificationForm.values.messageStatus,
      type_of_notification: notificationForm.values.notificationType,
      type_of_message: notificationForm.values.messageType,
    };
      dispatch(generateAnnouncement(requestData));
  };

  const filterOptions = (options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    return options.filter((option) =>
      option.label.toLowerCase().startsWith(inputValue)
    );
  };

  const messageTypes = [
    { key: "news", value: "News" },
    { key: "outage", value: "Outage" },
    { key: "release_update", value: "Release Update" },
    { key: "emergency", value: "Emergency" },
  ];

  const notificationTypes = [
    { key: "prompt", value: "Prompt" },
    { key: "scroll", value: "Scroll" },
    { key: "status_bar", value: "Status Bar" },
  ];

  const messageStatuses = [
    { key: "enable", value: "Enable" },
    { key: "disable", value: "Disable" },
  ]

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Notification
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="messageType"
                  name="messageType"
                  label="Type of Message"
                  fullWidth
                  options={messageTypes}
                  selectedValue={notificationForm.values.messageType}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.messageType &&
                    notificationForm.errors.messageType
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="notificationType"
                  name="notificationType"
                  label="Type of Notification"
                  fullWidth
                  options={notificationTypes}
                  selectedValue={notificationForm.values.notificationType}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.notificationType &&
                    notificationForm.errors.notificationType
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item container direction="column" md={12} xs={12}>
                <CeroInput
                  required
                  id="message"
                  name="message"
                  label="Message"
                  fullWidth
                  multiline
                  rows={4}
                  value={notificationForm.values.message}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.errors.message &&
                    notificationForm.touched.message
                  }
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroDateTime
                  useDayjs={true}
                  id="startTime"
                  name="startTime"
                  label="Start Time"
                  fullWidth
                  value={notificationForm.values.startTime || null}
                  onChange={(newValue) =>
                    notificationForm.setFieldValue("startTime", newValue)
                  }
                  error={
                    notificationForm.errors.startTime &&
                    notificationForm.touched.startTime
                  }
                  helperText={
                    notificationForm.touched.startTime &&
                    notificationForm.errors.startTime
                  }
                />
                <CeroAutoComplete
                  id="country"
                  name="Country"
                  label="Country"
                  onChange={(event, newValue) =>
                    notificationForm.setFieldValue(
                      "country",
                      newValue?.id || ""
                    )
                  }
                  onBlur={notificationForm.handleBlur}
                  options={countryList}
                  value={
                    countryList.find(
                      (option) => option.id === notificationForm.values.country
                    ) || null
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
                <CeroSelect
                  required
                  id="messageStatus"
                  name="messageStatus"
                  label="Message Status"
                  fullWidth
                  options={messageStatuses}
                  selectedValue={notificationForm.values.messageStatus}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.messageStatus &&
                    notificationForm.errors.messageStatus
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroDateTime
                  useDayjs={true}
                  id="endTime"
                  name="endTime"
                  label="End Time"
                  fullWidth
                  value={notificationForm.values.endTime || null}
                  onChange={(newValue) =>
                    notificationForm.setFieldValue("endTime", newValue)
                  }
                  error={
                    notificationForm.errors.endTime &&
                    notificationForm.touched.endTime
                  }
                  helperText={
                    notificationForm.touched.endTime &&
                    notificationForm.errors.endTime
                  }
                />
                <CeroAutoComplete
                  id="company"
                  label="Company"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCompany(newValue.id);
                      setCompanyName(newValue.label);
                      notificationForm.setFieldValue(
                        "company",
                        newValue?.id || ""
                      );
                    }
                  }}
                  options={companyListOption}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.company_name
                  }
                  onInputChange={(event, newInputValue) =>
                    setCompanyName(newInputValue)
                  }
                  filterOptions={filterOptions}
                  value={companyName}
                  getOptionLabel={(option) => option.label || ""}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add"
            disabled={!notificationForm.dirty || !notificationForm.isValid}
            className={classes.buttonPrimary}
            onClick={onSubmitData}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={notificationForm.resetForm}
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default AddNotification;
