import React from "react";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import useStyles from "./styles";
import dayjs from "dayjs"; 

const CeroDate = (props) => {
  
  const classes = useStyles();
  const defaultValue = dayjs(props.value || new Date());

  const getMonthIndex = (month) => {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return months.indexOf(month);
  };

  const getStartDate = (year, assessmentPeriod) => {
    let [startMonth, endMonth] = assessmentPeriod?.split("-");
    if (!endMonth) {
        endMonth = startMonth; 
    }
    if (props.lastYearMonths && props.lastYearMonths.includes(getMonthIndex(startMonth)))
    {
      year = year - 1
    }
    const startDate = dayjs(`${year}-${getMonthIndex(startMonth) + 1}-01`);
    return startDate;
  };

  const getEndDate = (year, assessmentPeriod) => {
    let [startMonth, endMonth] = assessmentPeriod?.split("-");
    if (!endMonth) {
        endMonth = startMonth; 
    }
    const daysInMonth = dayjs(`${year}-${getMonthIndex(endMonth) + 1}-01`).daysInMonth();
    const endDate = dayjs(`${year}-${getMonthIndex(endMonth) + 1}-${daysInMonth}`);
    return endDate;
  };
 
  const minDate = props.year && props.assessmentPeriod ? getStartDate(props.year, props.assessmentPeriod) : undefined;
  const maxDate = props.year && props.assessmentPeriod ? getEndDate(props.year, props.assessmentPeriod) : undefined;
  
  return (
    <Box className={clsx(classes.container, props.classes?.container)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label={props.label || 'Date Picker'}
            value={defaultValue}
            onChange={props.onChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DemoContainer>
      </LocalizationProvider>
      {!!props.helperText && <Typography className={clsx(classes.text, props.classes?.helperText)}>{props.helperText}</Typography>}
      {!!props.error && <Typography className={clsx(classes.text, classes.error, props.classes?.error)}>{props.error}</Typography>}
    </Box>
  );
};

export default CeroDate;
