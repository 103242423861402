import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  childContainer: {
    backgroundColor: "#EDFFF8 !important",
  },
  backContainer: {
    color: "#387A8A",
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: theme.spacing(3),
    paddingTop: 10,
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: theme.spacing(5),
  },
  tableContainer: {
    maxHeight: "calc(100vh - 144px)",
  },
  headerCell: {
    height: 70,
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1),
    color: "#333333",
    backgroundColor: "#BAC6C6 !important",
    "&:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "&:last-child": {
      paddingRight: theme.spacing(4),
    },
  },
  searchTextContainer: {
    height: 34,
    minHeight: 34,
    width: "20%",
    marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.background.white,
    "& .MuiTextField-root.MuiFormControl-root-MuiTextField-root": {
      height: 40,
    },
  },
}));

export default useStyles;
