import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography, CircularProgress, Grid } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroTable from "../../components/CeroTable";
import { auditStatus, sampleYear } from "../../constants";
import { useNavigate } from "react-router-dom";

import { getAuditSummary } from "../../redux/actions/audit";
import { STATUS } from "../../redux/constants";

import useStyles from "./styles";
// import CeroAutoComplete from "../../components/CeroAutoComplete";
import CeroDropdown from "../../components/CeroDropdown";

export const auditSummaryColumns = [
  {
    columnKey: "assessment_year",
    columnId: "assessment_year",
    columnHeader: "Year",
  },
  {
    columnKey: "countryVal",
    columnId: "country",
    columnHeader: "Country",
  },
  {
    columnKey: "audit_cycle",
    columnId: "audit_cycle",
    columnHeader: "Audit Cycle",
  },
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Peroid",
  },
  {
    columnKey: "audited_by",
    columnId: "audited_by",
    columnHeader: "Audited by",
  },
  {
    columnKey: "audited_on",
    columnId: "audited_on",
    columnHeader: "Audited on",
  },
  // {
  //   columnKey: "assigned_by",
  //   columnId: "assigned_by",
  //   columnHeader: "Audit request raised by",
  // },
  // {
  //   columnKey: "assigned_on",
  //   columnId: "assigned_on",
  //   columnHeader: "Audit request raised on",
  // },
  {
    columnKey: "audited_status",
    columnId: "audited_status",
    columnHeader: "Status",
  },
];

const AuditSummaryYearly = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const yearList = sampleYear().map(item => ({ id: item.key, label: item.value }));
  const [year, setYear] = useState(null);
  const [country, setCountry] = useState(null);
  const auditSummary = useSelector(
    (state) => state.audit.auditSummaryList.data
  );
  const auditSummaryStatus = useSelector(
    (state) => state.audit.auditSummaryList.status
  );

  const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
  // const countryOptions = [key:null, value: 'Select Option']
  const countryMap = {};
  let countryOptions = []
for (const [key, value] of Object.entries(countryData)) {
    countryMap[value] = key;
    countryOptions = countryOptions.concat({key:value,value:key})
}
  // let countryOptions = Object.keys(countryData).map(item=>({key:countryData[item],value:item}))
  countryOptions.unshift({key:null, value: 'Select Option'})
  // const countryMap = Object.keys(countryData).map(item=>({countryData[item]:item}))
  // yearList.unshift({key:null, value: 'Select Option'})

  const onSelectAuditSummaryData = (row) => {
    navigate(`/audit-status/current-year-approval/${row.assessment_year}?&id=${row.audit_status_id}`);
  };

  const getAuditData = () => {
    if (year !== null && country !== null) {
      const filteredData = auditSummary.filter((item) => item.assessment_year === year && item.country === country);
      return filteredData.map((item) => ({
        ...item,
        audited_status: getStatus(item.audited_status),
        audited_on: item.audited_on ? new Date(item.audited_on).toDateString() : "",
        assigned_on: item.assigned_on ? new Date(item.assigned_on).toDateString() : "",
        countryVal: item.country ? countryMap[item.country] : "",
        audit_cycle: item.audit_cycle ? item.audit_cycle.split("_").join(" ") : ""
      }));
    }
    else if (year !== null) {
      const filteredData = auditSummary.filter((item) => item.assessment_year === year);
      return filteredData.map((item) => ({
        ...item,
        audited_status: getStatus(item.audited_status),
        audited_on: item.audited_on ? new Date(item.audited_on).toDateString() : "",
        assigned_on: item.assigned_on ? new Date(item.assigned_on).toDateString() : "",
        countryVal: item.country ? countryMap[item.country] : "",
        audit_cycle: item.audit_cycle ? item.audit_cycle.split("_").join(" ") : ""
      }));
    }
    else if (country !== null) {
      const filteredData = auditSummary.filter((item) => item.country === country);
      return filteredData.map((item) => ({
        ...item,
        audited_status: getStatus(item.audited_status),
        audited_on: item.audited_on ? new Date(item.audited_on).toDateString() : "",
        assigned_on: item.assigned_on ? new Date(item.assigned_on).toDateString() : "",
        countryVal: item.country ? countryMap[item.country] : "",
        audit_cycle: item.audit_cycle ? item.audit_cycle.split("_").join(" ") : ""
      }));
    }

    return auditSummary.map((item) => ({
      ...item,
      audited_status: getStatus(item.audited_status),
      audited_on: item.audited_on ? new Date(item.audited_on).toDateString() : "",
      assigned_on: item.assigned_on ? new Date(item.assigned_on).toDateString() : "",
      countryVal: item.country ? countryMap[item.country] : "",
      audit_cycle: item.audit_cycle ? item.audit_cycle.split("_").join(" ") : ""
    }));
  };
  

    const getStatus = (status) => {
      if (status === 'audited') {
        return <Typography className={classes.audited}>{auditStatus[status]}</Typography>;
      } else if (status === 'added') {
        return <Typography className={classes.added}>{auditStatus[status]}</Typography>;
      }else if (status === 'pending') {
        return <Typography className={classes.pending}>{auditStatus[status]}</Typography>;
      }else if (status === 'approved') {
        return <Typography className={classes.approved}>{auditStatus[status]}</Typography>; 
      }else if (status === 'in progress') {
        return <Typography className={classes.pending}>In Progress</Typography>; 
      }else if (status === 'verified') {
        return <Typography className={classes.verified}>Verified</Typography>; 
      }else if (status === 'review') {
        return <Typography className={classes.review}>Review</Typography>; 
      }else {
        return <Typography className={classes.default}>{auditStatus[status]}</Typography>;
      }
    }

  useEffect(() => {
    dispatch(getAuditSummary());
  }, [dispatch]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const handleCountryChange = (event) => {
    console.log(event.target.value);
    setCountry(event.target.value);
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid container spacing={2}>
        {/* <Typography variant="h7" component="span">
          Yearly aggregate
        </Typography> */}
          <Grid item xs={2} className={classes.filterContainer}>
            <CeroDropdown
                  id="year"
                  label="Assessment Year"
                  fullWidth
                  options={sampleYear(true)}
                  onChange={handleYearChange}
                  selectedValue={year}
                />
          </Grid>
          <Grid item xs={1.5} className={classes.filterContainer}>
            <CeroDropdown
                  id="country"
                  label="Country"
                  fullWidth
                  options={countryOptions}
                  onChange={handleCountryChange}
                  selectedValue={country}
                />
          </Grid>
        </Grid>
        {auditSummaryStatus === STATUS.SUCCESS ? (
          <CeroTable
            columns={auditSummaryColumns}
            data={getAuditData()}
            hasMore={false}
            loading={false}
            onSelectRow={onSelectAuditSummaryData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {auditSummaryStatus === STATUS.RUNNING
                ? <CircularProgress/>
                : auditSummaryStatus === STATUS.ERROR
                ? "We couldn't process your request. Please try again later."
                : ""}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default AuditSummaryYearly;
