import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import {
  Container,
  Typography,
  Box,
  Chip,
  CircularProgress,
  Stack,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroButton from "../../components/CeroButton";
import CeroTable from "../../components/CeroTable";
import dayjs from "dayjs";
import { getSuperadminTickets, listAnnouncement } from "../../redux/actions";
import AddNotification from "./AddNotification";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const TableColumns = [
  {
    columnKey: "start_ts",
    columnId: "start_ts",
    columnHeader: "Start Time",
  },
  {
    columnKey: "end_ts",
    columnId: "end_ts",
    columnHeader: "End Time",
  },
  {
    columnKey: "type_of_message",
    columnId: "type_of_message",
    columnHeader: "Type of Message",
  },
  {
    columnKey: "type_of_notification",
    columnId: "type_of_notification",
    columnHeader: "Notification Type",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Message Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

const SuperAdminNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const announcementList = useSelector(
    (state) => state.superAdmin.listAnnouncement.data
  );

  const handleEditNotification = (item) => {
    navigate(`edit/${item}`)
  };

  useEffect(() => {
    dispatch(listAnnouncement());
  }, [dispatch]);

  const getTicketsList = () =>
    announcementList.map((item) => ({
      ...item,
      start_ts: item.start_ts
        ? dayjs(item.start_ts).format("DD MMM YYYY HH:mm A")
        : "-",
        end_ts: item.end_ts
        ? dayjs(item.end_ts).format("DD MMM YYYY HH:mm A")
        : "-",
        action: (
          <>
            <div className={classes.actions}>
              <div
                className={classes.linkButton}
                onClick={(e) => {
                  handleEditNotification(item.id);
                  e.stopPropagation();
                }}
              >
                Edit
                <EditOutlinedIcon className={classes.editIcon} />
              </div>
            </div>
          </>
        ) 
    }));

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Stack direction="row" justifyContent="flex-end">
          <CeroButton
            buttonText="Add notification"
            className={classes.buttonPrimary}
            onClick={()=>navigate("/add-notifications")}
          />
        </Stack>
        <CeroTable
          columns={TableColumns}
          data={getTicketsList()}
          hasMore={false}
          loading={false}
          // onSelectRow={rowData => onSelectCompanyData(rowData)}
          classes={{ tableContainer: classes.tableContainer }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default SuperAdminNotifications;
