import { getCookie } from "./cookie";

let role = getCookie("role");

export const getRole = () => {
  return role;
};

export const setRole = (newRole) => {
  role = newRole;
  document.cookie = `role=${newRole};path=/`;
  window.dispatchEvent(new Event('roleChange'));
};

export const initializeRoleListener = (callback) => {
  const handleRoleChange = () => {
    role = getCookie("role");
    callback(role);
  };

  window.addEventListener('roleChange', handleRoleChange);

  return () => {
    window.removeEventListener('roleChange', handleRoleChange);
  };
};
