import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addAntiCompetitiveDisclosure,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";

const AddAntiCompetitiveDisclosureForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const addEmissionData = useSelector(
    (state) => state.emission.addCompetitiveDisclosure
  );

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      legalActions: "",
      description: "",
      correctiveAction: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'anti_competitive_disclosure'));
    }
  }, [formik.values, dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Anti competitive Disclosure added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddAntiCompetitiveDisclosure = () => {
    const requestData = {
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      number_of_legal_action: formik.values.legalActions,
      outcomes_of_legal_action: formik.values.description,
      corrective_action: formik.values.correctiveAction,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addAntiCompetitiveDisclosure(requestData));
  };

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Anti-competitive Disclosure
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <Typography variant="body1">
              Enter the number of legal actions pending or completed during the
              reporting period regarding anti-competitive behaviour and
              violations of anti-trust and monopoly legislation in which the
              organization has been identified as a participant.
            </Typography>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroInput
                  required
                  id="legalActions"
                  name="legalActions"
                  label="Number of legal actions"
                  value={formik.values.legalActions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.legalActions && formik.errors.legalActions
                  }
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}></Grid>
              <Grid item container direction="column" md={6} xs={12}></Grid>
            </Grid>
            <CeroInput
              required
              id="description"
              name="description"
              label="Describe the main outcomes of completed legal actions, including any decisions or judgments."
              fullWidth
              multiline
              rows="2"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && formik.errors.description}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="correctiveAction"
              name="correctiveAction"
              label="Corrective Action"
              fullWidth
              multiline
              rows="2"
              value={formik.values.correctiveAction}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.correctiveAction &&
                formik.errors.correctiveAction
              }
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddAntiCompetitiveDisclosure}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddAntiCompetitiveDisclosureForm;
