import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import useStyles from "./styles";
import CeroButton from "../../components/CeroButton";
import OTPInput from "react-otp-input";
import { superAdminTwofaValidation } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { resendOtp } from "../../redux/actions";
import { clearOtp } from "../../redux/actions";
import ceroed from "../../assets/ceroed.png";
import { STATUS } from "../../redux/constants";


const TwoFaOtpValidation = () => {
  const classes = useStyles();
  const [otp, setOTP] = useState("");
  const [currentTime, setCurrentTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state) => state.auth.userInfo.id);
  const otpInfo = useSelector((state) => state.auth.otpInfo);

  const userInfoMessage = useSelector((state) => state.auth.message);
  const authStatus =  useSelector((state) => state.auth.status);

  console.log('userInfoStatus', authStatus)

  const expiryTime = otpInfo.data.expiry_time;

  const handleChange = (otp) => {
    setOTP(otp);
  };

  const handleSubmit = () => {
    if (otp.length !== 6) {
      enqueueSnackbar("Please input complete OTP", { variant: "error" });
      return;
    }
    const payload = {
      otp,
      userId,
    };
    dispatch(superAdminTwofaValidation(payload));
  };

  const handleClear = () => {
    setOTP("");
  };


  const handleResend = () => {
    const currentTime = Date.now();
    const newRemainingTime = expiryTime - currentTime;

    setCurrentTime(currentTime);
    setRemainingTime(newRemainingTime);
    setIsResendDisabled(true);

    dispatch(resendOtp(userId));
    dispatch(clearOtp(userId));
  };

  const isOtpComplete = otp.length === 6;

  useEffect(() => {
    // if (authStatus === STATUS.SUCCESS) {
    //   enqueueSnackbar(userInfoMessage, {
    //     variant: "success",
    //     autoHideDuration: 1000,
    //   });
    // } else 
    if (authStatus === STATUS.ERROR) {
      enqueueSnackbar(userInfoMessage, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  }, [authStatus, enqueueSnackbar, dispatch]);

  useEffect(() => {
    let interval = null;

    if (remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime((prevRemainingTime) => prevRemainingTime - 1000);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }

    return () => clearInterval(interval); 
  }, [remainingTime]);

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  useEffect(()=>{
    dispatch(clearOtp(userId))
  },[])

  return (
    <Box className={classes.mainBody}>
      <Box>
            <img src={ceroed} alt="ceroED Logo" className={classes.logo}/>
          </Box>
      <Container className={classes.container}>
        <Box className={classes.box}>
          <Typography variant="h6" className={classes.title}>
            Enter Verification Code
          </Typography>
          <Box>
            <OTPInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              renderSeparator={
                <span style={{ color: "#8d2249" }}>&nbsp;•&nbsp;</span>
              }
              isInputNum={true}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "40px",
                height: "40px",
                margin: "0 5px",
                textAlign: "center",
                border: "1.5px solid #8d2249",
                borderRadius: "8px",
              }}
            />
          </Box>
          <Typography className={classes.resend}>
            <span> Didn't receive OTP? </span>&nbsp;&nbsp;
            {remainingTime > 0 ? (
              <span>
                Try again in {millisToMinutesAndSeconds(remainingTime)}
              </span>
            ) : (
              <span
                className={classes.resendText}
                onClick={isResendDisabled ? null : handleResend}
              >
                Resend
              </span>
            )}
          </Typography>

          <div className={classes.buttonContainer}>
            <CeroButton
              color="secondary"
              onClick={handleClear}
              className={classes.buttonClear}
              buttonText="Clear"
            ></CeroButton>
            <CeroButton
              onClick={handleSubmit}
              disabled={!isOtpComplete}
              className={classes.buttonSave}
              buttonText="Submit"
            ></CeroButton>
          </div>
        </Box>
      </Container>
    </Box>
  );
};

export default TwoFaOtpValidation;