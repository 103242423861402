import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
  },
  textAreaContainer: {
    height: "fit-content",
  },
  drawerContainer: {
    maxWidth: "30vw",
    width: "30vw",
  },
  drawerContentArea: {
    padding: theme.spacing(4),
    overflow: "auto",
  },
}));

export default useStyles;
