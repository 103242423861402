import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from "@mui/material"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables as registerablesJS
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

// import { getEmissionYear } from '../../../redux/actions';

import useStyles from './styles'
import Cookies from 'js-cookie';
import { getStakeholderEmissionYearData } from '../../../redux/actions';

ChartJS.register(...registerablesJS)
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const TotalEmissionChart = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();

    const companyId = Cookies.get("companyId");

    const emissionData = useSelector(state => state.dashboard.getStakeholderEmissionYearData.data.response);

    useEffect(() => {
        dispatch(getStakeholderEmissionYearData(companyId));
    }, [dispatch,companyId]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    fontColor: 'rgba(0,0,0,.4)'
                },
                align: 'end',
                position: 'bottom',
            },
            title: {
                align: 'start',
                display: true,
                text: 'Total CO2e emissions per year',
                color: "black",
                font: {
                    size: 18,
                    family: 'Inter',
                    weight: "500"
                }
            },
            scales: {
            y: {
                title: {
                    display: true,
                    text: 'CO2e  Emitted',
                    color: '#4F4F4F',
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: '400',
                    },
                },
                ticks:{
                    color: "black",
                }
            },
            x:{
                ticks:{
                    color: "black"
                }
            }
            
        }
        }
    };

    const data = {
        labels: _.map(emissionData, (item) => item.year),
        datasets: [
            {
                label: 'Total Emission(tonnes)',
                data: _.map(emissionData, (item) => item.total_co2e),
                borderColor: '#846BB5',
                backgroundColor: '#D56763',
            },
        ],
    };


    return (
        <Paper className={classes.container} >
            {emissionData ? <Line options={options} data={data} height={150}/> : ""}
        </Paper >
    )
}

export default TotalEmissionChart
