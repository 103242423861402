import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        height: '350px',
        borderRadius: 8,
        margin: theme.spacing(4, 0),
        position: "relative",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    },
    graphTitle: {
        fontSize: 18,
        fontWeight: 400,
        padding: theme.spacing(2,0),
    },
    chart:{
        height: "80%",
        width: "100%"
    },
    colorPrimary:{
        backgroundColor: "#D9D9D9"
    },
    barColorPrimary:{
        backgroundColor: "#846BB5"
    },
    linearProgress:{
        width: "100%", 
        marginBottom: 15,
        borderRadius: 5,
        height: 5
    },
    initiativeName:{
        fontWeight: 500,
        padding: theme.spacing(2,0),
    },
    arrowButton:{
        cursor: "pointer",
        color: theme.palette.Primary.background,
        fontSize: "xxx-large"
    },
    moreButton:{
        cursor: "pointer",
        color: theme.palette.Primary.background,
        fontSize: "small",
        textAlign: "center",
    },
    cardContent:{
        width: "90%",
        margin: "auto"
    },
    moreHorizon:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        bottom: "5%",
        left: "45%"
    },
    text:{
        fontSize: 14
    }
}));

export default useStyles;
