import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CeroTable from "../../../../components/CeroTable";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { companyFacilityDetails } from "../../../../redux/actions";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Container, Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { STATUS } from "../../../../redux/constants";

export const TableColumns = [
  {
    columnKey: "company",
    columnId: "company",
    columnHeader: "Company",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility Name",
  },
  {
    columnKey: "address",
    columnId: "address",
    columnHeader: "Address",
  },
  {
    columnKey: "phone_number",
    columnId: "phone_number",
    columnHeader: "Phone Number",
  },
  {
    columnKey: "total_users",
    columnId: "total_users",
    columnHeader: "Total Users",
  },
];

const CompanyFacilityDetailsTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId, countryName } = useParams();
  const { onLoadMore } = props;
  const facilityDetails = useSelector(
    (state) => state.listings.facilityDetails.data
  );
  const detailsStatus = useSelector(
    (state) => state.listings.facilityDetails.status
  );

  useEffect(() => {
    dispatch(companyFacilityDetails(companyId, countryName));
  }, [dispatch]);

  const getFacilityDetails = () =>
    facilityDetails.map((item) => ({
      ...item,
      company: item.company ? item.company : "-",
      facility_name: item.facility_name ? item.facility_name : "-",
      address: item.address ? item.address : "-",
      phone_number: item.phone_number ? item.phone_number : "-",
      total_users: item.total_users ? item.total_users : "-",
    }));
    
  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container>
        <Typography variant="h6">{countryName}</Typography>
        {detailsStatus === STATUS.SUCCESS && (
        <CeroTable
          columns={TableColumns}
          data={getFacilityDetails()}
          hasMore={false}
          loading={false}
          loadMore={onLoadMore}
          classes={{ tableContainer: classes.tableContainer }}
        />)}
      </Container>
    </DashboardLayout>
  );
};

export default CompanyFacilityDetailsTable;
