import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { listUsers } from "../../redux/actions";

import UserTable from "./UserTable";
import UserHeader from "./UserHeader";
import AddUserDrawer from "./AddUserDrawer";
import DashboardLayout from "../../layouts/DashboardLayout";

import useStyles from "./styles";
import { STATUS } from "../../redux/constants";

const savedPage = {
  // To be used from the api response
  pageNumber: 1,
};
const DEFAULT_ITEMS_PER_PAGE = 20;

const Users = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userList = useSelector((state) => state.users.userList.data);
  const userListStatus = useSelector((state) => state.users.userList.status);

  const [isOpenAddUser, setOpenAddUser] = useState(false);
  const [editUser, setEditUser] = useState(null);

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  const onLoadMore = (pageSize = DEFAULT_ITEMS_PER_PAGE, pageNumber) => {
    const filter = {
      emissionFilter: {},
      page: {
        pageSize,
        pageNumber: pageNumber || (savedPage.pageNumber || 0) + 1,
      },
    };
    // api call with filter
  };

  const handleDrawerClose = () => {
    setOpenAddUser(false);
    setEditUser(null);
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <UserHeader onAddData={() => setOpenAddUser(true)} />
        {userListStatus === STATUS.SUCCESS ? (
          <UserTable
            userList={userList}
            onLoadMore={onLoadMore}
            onClickEdit={(user) => setEditUser(user)}
          />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {userListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : userListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
        {(isOpenAddUser || editUser) && (
          <AddUserDrawer
            isOpen={isOpenAddUser || editUser}
            onClose={handleDrawerClose}
            editUser={editUser}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default Users;
