import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { sampleYearArray } from "../../../../constants";
import {
  addReviewComments,
  assignToSubmit,
  getApprovalEmission,
  getApprovedData,
  getEmissionList,
  getTopicBasedApproval,
  pendingApprovalEmission,
  resetApprovalData,
  resetDataApproved,
} from "../../../../redux/actions";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { STATUS } from "../../../../redux/constants";
import useStyles from "./styles";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CeroButton from "../../../../components/CeroButton";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import EmployeeHireTable from "../../../EmissionList/EmployeeHireTable";
import PendingApprovalCard from "./PendingApprovalCard";
import { useSnackbar } from "notistack";
import CeroPopUp from "../../../../components/CeroPopUp";
import { getCookie } from "../../../../services/cookie";

const PendingApprovalDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type, subType, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");
  const role = useSelector((state) => state.auth.role);
  const isAuditor = role.includes(rolesEnum.AUDITOR);
  const isFacMgr = role.includes(rolesEnum.FACILITY_MANAGER);
  const isSusMgr = (role.includes(rolesEnum.SUSTAINABILITY_MANAGER) || role.includes(rolesEnum.PROXY_SUSTAINABILITY_MANAGER));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [approveConfirm, setApproveConfirm] = useState(false);
  const [ reviewId, setReviewId] = useState([]);
  const [comment, setComment] = useState("");
  const [ approveId, setApproveId] = useState([]);
  const [isApprovalAll , setIsApprovalAll] = useState(false);
  const [isReview,setIsReview] = useState(false)
  const currentRole = getCookie("role");
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const emissionData = useSelector(
    (state) => state.approval.pendingApprovalEmission.data
  );
  const emissionTableData = useSelector(
    (state) => state.emission.emissionList.data.emissions
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionList.status
  );
  const submitStatus = useSelector(
    (state) => state.approval.assignToSubmit
  );
  const approveStatus = useSelector(
    (state) => state.approval.getApprovedData
  );
  const data = emissionData.overview;
  const approvalTopicData = useSelector(
    (state) => state.approval.getTopicBasedApproval.data
  );

  const multiRecordTopics = ["employees_benefit", "employee_health_safety_incident_record","worker_safety_training_procedures",
  "discrimination_incident_record","training_hours_per_employee","performance_and_career_development_programs"]

const showReview = !multiRecordTopics.includes(type);
  const approvalTopicDataStatus = useSelector(
    (state) => state.approval.getTopicBasedApproval.status
  );

  const reviewStatus = useSelector(
    (state) => state.approval.reviewComments
  );
  const approvalData = approvalTopicData?.topic_list;
  const dataCount = approvalData?.length
  const distributionIds = {};
  for (const key in approvalData) {
    if (Object.prototype.hasOwnProperty.call(approvalData, key) && Array.isArray(approvalData[key])) {
      distributionIds[key] = approvalData[key].map(item => item.id);
    }
  }
  
 const filteredIds = Object.values(distributionIds).flat()
 const duplicateIds =  filteredIds.filter((item, index) => filteredIds.indexOf(item) === index);

 const topicIds = Array.isArray(approvalData) ? (approvalData && approvalData?.map((item) => item.id)) : duplicateIds;

  useEffect(() => {
    if (id && type && subType) {
      dispatch(getTopicBasedApproval(id, type, subType));
    }
  }, [dispatch, id, type, subType, isReview]);

  const onSubmitdata = () => {
    dispatch(assignToSubmit(id, subType, topicIds))
  }

   useEffect(() => {
     if (id && type && subType) {
       dispatch(pendingApprovalEmission(id, type, subType));
     }
   }, [dispatch, dataCount, id, type, subType, isReview]);

  const approvalDataLength = getCookie('approvalDataLength')?parseInt(getCookie('approvalDataLength')):0;

  const onLoadMore = (limit, pageNumber) => {
    const filterRequest = {
          // limit: newLimit,
          // skip,
          month: selectedMonth,
          year: selectedYear || sampleYearArray[0].key,
          facility_id: selectedFacility,
          period: period,
          // country:country
        };
        if (!filterRequest.facility_id) {
          delete filterRequest.facility_id;
        }
    dispatch(getEmissionList(type, filterRequest, isAuditor, company, typeof selectedFacility === "string" ? selectedFacility === "null" ? null : selectedFacility.split(",") : selectedFacility, period, country));
};

useEffect(() => {
  if (submitStatus.status === STATUS.SUCCESS) {
    if(approvalDataLength > 1){
    dispatch(resetApprovalData())
    navigate(-1);
    }else{
      dispatch(resetApprovalData())
      navigate('/pending-approvals')
    }
    enqueueSnackbar(submitStatus?.data?.message, { variant: "success" , autoHideDuration: 3000 });
  } else if (submitStatus.status === STATUS.ERROR) {
    dispatch(resetApprovalData())
    enqueueSnackbar("Please try again later", { variant: "error" });
  }
}, [submitStatus, enqueueSnackbar, id, type, subType]);

useEffect(() => {
  if (approveStatus.status === STATUS.SUCCESS) {
    if (isApprovalAll === true) {
      if (approvalDataLength > 1) {
        // if (dataCount <= 1) {
          dispatch(resetDataApproved());
          navigate(-1);
        // } else {
        //   dispatch(resetDataApproved());
        // }
      } else {
        dispatch(resetDataApproved());
        navigate("/pending-approvals");
      }
    } else {
      if (dataCount <= 1) {
        if (approvalDataLength > 1) {
          dispatch(resetDataApproved());
          navigate(-1);
        } else {
          dispatch(resetDataApproved());
          navigate("/pending-approvals");
        }
      }else{
        dispatch(resetDataApproved());
        dispatch(getTopicBasedApproval(id, type, subType));
      }
    }
    //  dispatch(getTopicBasedApproval(id, type, subType));
    enqueueSnackbar(approveStatus?.data?.message, { variant: "success" ,  autoHideDuration: 3000 });
  } else if (approveStatus.status === STATUS.ERROR) {
    dispatch(resetApprovalData())
    enqueueSnackbar("Please try again later", { variant: "error" });
  }
}, [approveStatus, enqueueSnackbar, id, type, subType]);

useEffect(() => {
  if (reviewStatus.status === STATUS.SUCCESS) {
    enqueueSnackbar(reviewStatus?.data?.message, { variant: 'success', key: 'review' });
    if(approvalDataLength > 1){
      if(dataCount > 1){
        dispatch(resetApprovalData())
        dispatch(pendingApprovalEmission(id, type, subType));
      }else{
        setIsReview(true)
        dispatch(resetApprovalData())
        navigate(-1);
      }
      }else{
        dispatch(resetApprovalData())
        navigate('/pending-approvals')
      }
    dispatch(getTopicBasedApproval(id, type, subType));
  } else if (reviewStatus.status === STATUS.ERROR) {
    dispatch(resetApprovalData())
    enqueueSnackbar(reviewStatus.data.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
  }
}, [enqueueSnackbar, reviewStatus, dispatch, id, type, dataCount,isReview])

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleReviewPopup = (item) => {
    setReviewPopup(true);
    setReviewId(item)
  }
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setReviewPopup(false);
  };

  const handleAction = () => {
    // isApprovalAll = true
    setIsApprovalAll(true)
    dispatch(getApprovedData(id, subType, topicIds));
    handleClosePopup()
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  
  const onSubmitReview = () => {
    setReviewPopup(false);
    if(dataCount > 1){
      dispatch(addReviewComments(id, [reviewId], comment))
    }else{
    dispatch(addReviewComments(id, topicIds, comment))
    }
  }

  const getConfirmation = () => {
    return (
      <Box className={classes.popUp}>
          <Typography variant="body1" style={{fontWeight: 500}}>Are you sure you want to approve?</Typography>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleAction}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleClosePopup}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const getReviewComments = () => {
    return (
      <>
      <TextField
        id="outlined-multiline-static"
        multiline
        rows={5}
        placeholder="Comment"
        style={{ width: "100%" }}
        value={comment}
        onChange={handleCommentChange}
        className={classes.textField}
      />
      <div className={classes.textLimit}>
        <div className={classes.noComments}>
          {comment.trim().length === 0 ? "No comments" : ""}
        </div>
        <div className={classes.maxCharacters}>
          {"Maximum 200 characters"}
        </div>
      </div>
      <div className={classes.reviewButtons}>
        <CeroButton
              className={classes.buttonPrimary}
              variant="contained"
              onClick={onSubmitReview}
              buttonText="Save"
            />
            <CeroButton
              className={classes.buttonSecondary}
              variant="outlined"
              onClick={() => setReviewPopup(false)}
              buttonText="Cancel"
            />
      </div>
      </>)}

   const handleIndividualApprove = () => {
    dispatch(getApprovedData(id, subType, [approveId]))
  }

  const getApproveConfirm = () => {
    return (
      <Box className={classes.popUp}>
          <Typography variant="body1" style={{fontWeight: 500}}>Are you sure you want to approve?</Typography>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {setApproveConfirm(false);handleIndividualApprove()}}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setApproveConfirm(false)}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const handleApprove = (item) => {
    setApproveConfirm(true)
    setApproveId(item)
  }

  const getTopicTitle = (type) => {
    if(type === "social_engagement_human_rights_training")
    {
      return "Human Rights Training"
    }else if(type === "supplier_screening_using_enviormental_social_criteria"){
      return "Supplier Screening using Environmental & Social Criteria"
    }else if(type === "suppliers_human_rights"){
      return "Human Rights Training"
    }else if(type === "employee_health_safety_incident_record"){
      return "Employee Health and Safety Incident Record"
    }else {
      return type
    }
  }

  return (
    <DashboardLayout>
      <Box className={classes.tableTopic}>
        <Typography variant="h7" component="span">
          <span className={classes.tableTitle}>
            {type && getTopicTitle(type).replace(/_/g, " ")}
          </span>
        </Typography>
        <Box>
          <div className={classes.buttonsContainer}>
            {isFacMgr &&
              data &&
              data.status === "added" && (
                <CeroButton
                  buttonText={dataCount > 1 ? "Submit All" : "Submit"}
                  variant="contained"
                  className={classes.buttonPrimary}
                  onClick={onSubmitdata}
                  disabled={readOnlyMode}
                />
              )}
            {isSusMgr &&
              data &&
              (data.status === "pending_approval" || data.status === "submitted") &&
              data.status !== "review" && (
                <CeroButton
                  buttonText={dataCount > 1 ? "Approve All" : "Approve"}
                  variant="contained"
                  className={classes.buttonPrimary}
                  onClick={handleOpenPopup}
                  disabled={readOnlyMode}
                />
              )}
            {isSusMgr &&
              data &&
              data.status === "pending_approval" &&
              data.status !== "review" &&
              data.is_not_added_by_sustainability_manager === true &&
              
              (showReview || dataCount === 1 ) && (
                <CeroButton
                  buttonText="Review"
                  variant="contained"
                  className={classes.buttonPrimary}
                  onClick={handleReviewPopup}
                  disabled={readOnlyMode}
                />
              )}
            <CeroButton
              buttonText="Back"
              variant="outlined"
              className={classes.buttonSecondary}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            />
          </div>
        </Box>
      </Box>
      {showReview && (
        <Container className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CeroInfoPair
                title="Status"
                value={data?.status === "pending_approval" ? "Pending Approval" : data?.status }
                classes={{
                  container: classes.infoContainer,
                  value:
                    data?.status === "In Progress" || data?.status === "pending_approval"
                      ? classes.Inprogress
                      : classes[data?.status],
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Audited By"
                value={data?.audited_by_name ? data?.audited_by_name : "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Audited On"
                value={data?.audited_on ? data?.audited_on : "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Approval Cycle"
                value={data?.approval_cycle_type?.split("_").join(" ")}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Audit Cycle"
                value={data?.audit_cycle_type?.split("_").join(" ")}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CeroInfoPair
                title="Period"
                value={data?.assessment_period}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Assessment Year"
                value={data?.year}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Facility"
                value={data?.facility_name}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="No of tickets"
                value={data?.ticket_count}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      )}
      {type === "employees_hire_and_turnover" ? (
        <EmployeeHireTable
          emissionData={approvalData}
          dataStatus={approvalTopicDataStatus}
          onLoadMore={onLoadMore}
          emissionType={type}
          isAuditor={isAuditor}
          company={company}
          isOnlyAdmin={false}
          period={period}
        />
      ) : (
        <PendingApprovalCard
          topicType={type}
          onLoadMore={onLoadMore}
          approvalData={approvalData}
          approvalTopicDataStatus={approvalTopicDataStatus}
          onApproveId={handleApprove}
          onReviewId={handleReviewPopup}
        />
      )}
      <CeroPopUp
        primaryPopUp={{
          open: isPopupOpen,
          onClose: () => setIsPopupOpen(false),
          content: getConfirmation(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: reviewPopup,
          onClose: () => setReviewPopup(false),
          content: getReviewComments(),
          header: { title: "Review Comments" },
        }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: approveConfirm,
          onClose: () => setApproveConfirm(false),
          content: getApproveConfirm(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
    </DashboardLayout>
  );
};

export default PendingApprovalDetails;
