import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CeroPopUp from "../../../components/CeroPopUp";
import CeroButton from "../../../components/CeroButton";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import DashboardNews from "./DashboardNews";
import { clearDashboardData, clearNotifications, getNews } from "../../../redux/actions";
import WarningIcon from "../../../assets/icons/WarningIcon.png";

const DashboardNotification = (props) => {
  const { notificationData } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [popupOpen, setPopupOpen] = useState({});
  const [assessmentPopup, setAssessmentPopup] = useState(false);
  const [showPromptList, setShowPromptList] = useState(false);
  const newsHeadline = useSelector((state) => state.dashboard.sustainabilityNews.data);

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);

  useEffect(() => {
    if (
      notificationData?.status_bar &&
      Object.keys(notificationData.status_bar).length > 0
    ) {
      let delay = 0;
      Object.keys(notificationData.status_bar).forEach((key) => {
        notificationData.status_bar[key]?.forEach((msg) => {
          setTimeout(() => {
            enqueueSnackbar(msg, { 
              variant: "success",
              autoHideDuration: 7000, 
            });
          }, delay);
          delay += 8000; 
        });
      });
      if(!notificationData?.prompt && !notificationData?.assessment_prompt){
        dispatch(clearNotifications())
      }
    }

    if (
      notificationData?.prompt &&
      Object.keys(notificationData.prompt).length > 0 
    ) {
      const newPopupState = { ...popupOpen }; 
      Object.keys(notificationData.prompt).forEach((key) => {
        if (notificationData.prompt[key]?.length > 0) {
          newPopupState[key] = true; 
        }
      });
      setPopupOpen(newPopupState); 
    }
     
    if(notificationData?.assessment_prompt){
      setAssessmentPopup(true)
    }
  }, [notificationData, dispatch, enqueueSnackbar]);

  const getPromptContent = (type) => {
    const contentMessages = notificationData?.prompt[type] || [];

    return (
      <div className={classes.popupContent}>
        {contentMessages.map((msg, index) => (
          <Typography
            key={index}
            variant="body1"
            className={classes.promptMessage}
          >
            {msg}
          </Typography>
        ))}
        <CeroButton
          className={classes.buttonPrimary}
          variant="contained"
          onClick={() => {
            setPopupOpen((prev) => ({ ...prev, [type]: false }));dispatch(clearNotifications());
          }}
          buttonText="OK"
        />
      </div>
    );
  };

  const getAssessmentContent = () => {
    return(
      <div className={classes.assessementContent}>
        <div className={classes.infoContainer}>
        <img src={WarningIcon} alt="Logo" width="40" height="40"/>
        <Typography
          variant="subtitle1"
          className={classes.subTitle}
        >
          {notificationData?.assessment_prompt?.generic_message}
        </Typography>
      </div>
      {showPromptList && (
        <ul className={classes.promptList}>
          {notificationData?.assessment_prompt?.prompt_lst?.map((item, index) => (
            <li key={index}>
              <Typography variant="body1" className={classes.listItem}>{item}</Typography>
            </li>
          ))}
        </ul>
      )}
     <Typography
        className={classes.viewMoreLink}
        onClick={() => setShowPromptList(!showPromptList)}>
        {showPromptList ? 'View Less' : 'View More'}
      </Typography>
      </div>
    )
  }

  const handleClose = () => {
    setAssessmentPopup(false)
    if(!notificationData?.prompt){
      dispatch(clearDashboardData())
    }
  }

  return (
    <>
      {notificationData?.prompt &&
        Object.keys(notificationData.prompt).map((key) => (
          <CeroPopUp
            key={key}
            primaryPopUp={{
              open: !!popupOpen[key],
              onClose: () =>
                setPopupOpen((prev) => ({ ...prev, [key]: false })),
              content: getPromptContent(key),
              header: {
                title:
                <Typography variant="h5" className={classes.title}>
                  {key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")}</Typography>
              }
            }}
            disableBackdropClick 
            disableEscapeKeyDown
            classes={{ dialog: classes.dialog }}
          />
        ))}
      {newsHeadline && newsHeadline.length > 0 &&  
      <DashboardNews newsHeadline={newsHeadline}/>}
      {notificationData?.assessment_prompt &&
          <CeroPopUp
          primaryPopUp={{
            open: assessmentPopup,
            onClose: handleClose,
            content: getAssessmentContent(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        }
      </>
  );
};

export default DashboardNotification;
