import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Email is required"),
  name: yup.string().required("Name is required"),
  roles: yup.array().of(
    yup.object().shape({
      role: yup.string().required("Role is required"),
      countries: yup.array().when('role', {
        is: (role) => role !== 'admin',
        then: yup.array().of(
          yup.object().shape({
            country_code: yup.string().required("Country is required"),
            facilities: yup.array().of(yup.string()).min(1, "Select at least one facility").required("Facility is required"),
          })
        ).required("At least one country and facility are required"),
        otherwise: yup.array().default([{ country_code: "", facilities: [] }]),
      }),
    })
  ),
});
