import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { STATUS } from "../../../redux/constants";
import {
  resetAddCombustionStatus,
  deleteEmissions,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import ListComments from "../ListComment";
import ListAuditTrails from "../ListAuditTrails";
import ListEmissionFiles from "../ListEmissionFiles";
import ListTicketDrawer from "../../common/ListTicketsDrawer";
import CeroConfirmDrawer from "../../../components/CeroConfirmDrawer";
import useStyles from "./styles";
import TicketIcon from "../../../assets/icons/TicketIcon.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getCookie } from "../../../services/cookie";
import CeroInfoPair from "../../../components/CeroInfoPair";
import CeroItemPair from "../../../components/CeroItemPair";

const AntiCorruptionTraining = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const role = getCookie('role');
  const {
    emissionId,
    emissionData,
    onCancel,
    isDeleteEnable,
    setIsDrawerOpen,
    isSusManager,
    isAuditor,
    company
  } = props;

  const [value, setValue] = useState(0);
  const [showTickets, setShowTickets] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const isFacMgr = getCookie('role') === "facility_manager"
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );

  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Anti Corruption Training deleted successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(deleteEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetAddCombustionStatus());
    }
  }, [deleteEmissionData, enqueueSnackbar, onCancel, dispatch]);

  const onUpdatePurchasedElectricity = () => {
    navigate(`/emissions/edit/anti_corruption_training/${emissionId}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };

  const onclickShowTickets = () => {
    setShowTickets(true);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  return (
    <>
      <div className={classes.headerContainer}>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
        </div>
              <Box>
                <CeroButton
                  buttonText="Raise a ticket"
                  className={classes.buttonPrimary}
                  onClick={() => setIsDrawerOpen(true)}
                  disabled={readOnlyMode}
                />
                <IconButton onClick={onclickShowTickets} disabled={readOnlyMode}>
                  <img src={TicketIcon} alt="Logo" width="25" height="25" />
                </IconButton>
              </Box>
            </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Anti Corruption Training
            </Typography>
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              spacing={12}
              display={"contents"}
            >
              <Grid
                item
                container
                direction={"column"}
                display={"contents"}
                xs={10}
              >
                <Box className={classes.actionBox}>
                  <CeroInfoPair
                    title="Status:"
                    value={emissionData.status ? emissionData.status === 'pending_approval' ? 'pending approval' : emissionData.status : "NA"}
                    classes={{
                      container: classes.statusContainer,
                      title: classes.statusTitle,
                      value: emissionData.status === "In Progress" || emissionData.status === "pending_approval" ? classes.Inprogress : classes[emissionData?.status]
                    }}
                  />
                </Box>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Number of Attendees:"
                  value={emissionData.attended || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Date:"
                  value={emissionData.training_date || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Methods:"
                  value={emissionData.training_method || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Description:"
                  value={emissionData.description_of_training || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                {emissionData?.audited_by && (<>
                <CeroItemPair
                  title="Audited by:"
                  value={
                    emissionData.audited_by ? emissionData.audited_by : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Audited on:"
                  value={
                    emissionData.audited_on
                      ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.antiCorruptionDisclosure,
                    value: classes.value,
                  }}
                /></>)}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          {isDeleteEnable && (
            <CeroButton
              buttonText={<DeleteOutlineIcon />}
              className={clsx(classes.button, classes.deleteButton)}
              onClick={() => setDisplayWarning(true)}
              disabled={readOnlyMode}
            />
          )}
          {emissionData?.is_able_to_update === true && (
              <CeroButton
                buttonText="Update"
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={onUpdatePurchasedElectricity}
                disabled={readOnlyMode}
              />
            )}
        </Box>
        <Box
          className={classes.tabContainer}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            aria-label="emission tabs"
          >
            <Tab
              label="Comments"
              id="comments"
            />
            <Tab
              label="Attachment"
              id="files"
            />
            <Tab label="Change Logs" id="audit-history" />
          </Tabs>
        </Box>
        {value === 0  && (
          <ListComments emissionId={emissionId} isAuditor={isAuditor} company={company}/>
        )}
        {value === 2 && <ListAuditTrails emissionId={emissionId} isAuditor={isAuditor} company={company}/>}
        {value === 1 && (
          <ListEmissionFiles emissionId={emissionId} isAuditor={isAuditor} company={company}/>
        )}
        {displayWarning && (
          <CeroConfirmDrawer
            isOpen={displayWarning}
            onClose={() => setDisplayWarning(false)}
            onConfirm={onConfirmDelete}
          />
        )}
        {showTickets && (
          <ListTicketDrawer
            isOpen={showTickets}
            scope="emission"
            scopeId={emissionId}
            onClose={onCloseTickets}
          />
        )}
      </Container>
    </>
  );
};

export default AntiCorruptionTraining;
