import { Grid } from "@mui/material"

import CeroButton from "../../../components/CeroButton"
import useStyles from './styles'
import { getCookie } from "../../../services/cookie"
import { useNavigate } from "react-router-dom"

const UserHeader = ({ onAddData }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

    return (
        <Grid container className={classes.container} alignItems="center" justifyContent="flex-end">
            <Grid item xs={1.5}>
                <CeroButton
                    buttonText="Add User"
                    className={classes.button}
                    // onClick={onAddData}
                    onClick={()=>navigate('/users/add-users')}
                    disabled={readOnlyMode}
                />
            </Grid>
        </Grid>
    )
}

export default UserHeader
