import React, { useEffect, useState } from "react";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const AuditedReports = ({ auditedReports, filterYear }) => {
  const [internalFilterYear, setInternalFilterYear] = useState(filterYear);
  const classes = useStyles();
  const navigate = useNavigate();
  const auditSummaryColumns = [
    {
      columnKey: "assessment_year",
      columnId: "assessment_year",
      columnHeader: "Year",
      // align : 'left',
      width : '10vw'
    },
    {
      columnKey: "audited_by",
      columnId: "audited_by",
      columnHeader: "Audited by",
      width : '15vw'
    },
    {
      columnKey: "audited_on",
      columnId: "audited_on",
      columnHeader: "Audited on",
      width : '15vw'
    },
    {
      columnKey: "assigned_on",
      columnId: "assigned_on",
      columnHeader: "Assigned on",
      width : '15vw'
    },
  ];

  useEffect(() => {
    setInternalFilterYear(filterYear !== undefined ? filterYear : null);
  }, [filterYear]);

  const onSelectAuditSummaryData = (row) => {
    navigate(
      `details/${row.audit_status_id}?year=${row.assessment_year}&type=${row.report_type}`
    );
  };

  const getReportData = () =>
    auditedReports
      ?.filter(
        (item) =>
          internalFilterYear === null ||
          item.assessment_year == internalFilterYear
      )
      ?.map((item) => ({
        ...item,
        assessment_year: item.assessment_year || "",
        audited_by: item.audited_by || "",
        audited_on: item.audited_on
          ? dayjs(item.audited_on).format("DD MMM YYYY HH:mm")
          : "-",
        assigned_on: item.assigned_on
          ? dayjs(Number(item.assigned_on)).format("DD MMM YYYY HH:mm")
          : "-",
      }));

  return (
    <>
      {auditedReports && (
        <CeroTable
          columns={auditSummaryColumns}
          data={getReportData()}
          hasMore={false}
          loading={false}
          onSelectRow={onSelectAuditSummaryData}
          classes={{ tableContainer: classes.tableContainer }}
        />
      )}
    </>
  );
};

export default AuditedReports;