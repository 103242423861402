import React from "react";
import { Card, CardContent, Grid, Tooltip, Zoom } from "@mui/material";
import { Box } from "@mui/system";

import useStyles from "./styles";

const TicketStatistics = (props) => {
  const classes = useStyles();

  return (
    <Card>
      <Tooltip title={props.subtitle} placement="top" arrow TransitionComponent={Zoom}>
        <CardContent className={classes.cardComponent}>
          <Box className={classes.title}>
            <Box>{props.title}</Box>
          </Box>
          <Grid container justifyContent="space-between" className={classes.container}>
            <Grid item xs={6}>
              <Box className={classes.valueContainer}>
                <Box className={classes.value}>{props.attributes[0].value}</Box>
                <Box className={classes.name}>{props.attributes[0].name}</Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.valueContainer}>
                <Box className={classes.value}>{props.attributes[1].value}</Box>
                <Box className={classes.name}>{props.attributes[1].name}</Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Tooltip>
    </Card>
  );
};

export default TicketStatistics;
