import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
  } from "@mui/material";
  
  import useStyles from "./styles";
  import DashboardLayout from "../../../layouts/DashboardLayout";
  import { useEffect, useState } from "react";
  import CeroTable from "../../../components/CeroTable";
  import { useDispatch, useSelector } from "react-redux";
  import { useNavigate } from "react-router-dom";
  import { addTargetActual, listInitiative, resetGoals } from "../../../redux/actions";
  import { STATUS } from "../../../redux/constants";
  import CeroAutoComplete from "../../../components/CeroAutoComplete";
  import CeroChipSelect from "../../../components/CeroChipSelect";
  import { sampleYear } from "../../../constants";
  import CeroDropdown from "../../../components/CeroDropdown";
import { useSnackbar } from "notistack";
import CeroButton from "../../../components/CeroButton";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import { getCookie } from "../../../services/cookie";

  
  export const initiativesColumns = [
    {
      columnKey: "initiative_id",
      columnId: "initiative_id",
      columnHeader: "Initiative ID",
      width: "15vw",
    },
    {
      columnKey: "initiative",
      columnId: "initiative",
      columnHeader: "Initiatives",
      width: "10vw",
    },
    {
      columnKey: "target",
      columnId: "target",
      columnHeader: "Target",
      width: "10vw",
    },
    {
        columnKey: "actual",
        columnId: "actual",
        columnHeader: "Actuals",
        width: "10vw",
    },
    {
        columnKey: "notes",
        columnId: "notes",
        columnHeader: "Notes",
        width: "10vw",
    },    
    {
        columnKey: "edit",
        columnId: "edit",
        columnHeader: "",
        width: "3vw",
      },
  ];
  
  const TargetVsActuals = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [filterYear, setYear] = useState();
    const [facility, setFacility] = useState();
    const [country, setCountry] = useState();
    const [selectedId, setSelectedId] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [targetValue, setTargetValue] = useState({});
    const [actualValue, setActualValue] = useState({});
    const [notesValue, setNotesValue] = useState({});
    const [editingTasks, setEditingTasks] = useState({});
    const [editedTaskIds, setEditedTaskIds] = useState(new Set());
    const role = getCookie("role")
    const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

    const initiativeList = useSelector(
      (state) => state.goalSettings.listInitiative.data
    );
  
    const initiativeListStatus = useSelector(
      (state) => state.goalSettings.listInitiative.status
    );
  
    const countryData = useSelector(
      (state) => state.listings.getOrganizationCountry.data.country_data
    );

    const saveInitiative = useSelector((state) => state.goalSettings.addTargetActual);

    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };
  
    const handleYearChange = (event) => {
      setYear(event.target.value);
    };
  
    const handleFacilityChange = (event) => {
      setFacility(event.target.value);
    };
  
    const countryOptions = Object.keys(countryData).map((item) => ({
      key: countryData[item],
      value: item,
    }));
  
    const facilitiesData = useSelector(
      (state) => state.listings.listFacilities.data
    );
  
    const facilitiesList = facilitiesData.map((item) => ({
      key: item.id,
      value: item.name,
    }));
  
    const handleTargetChange = (e, taskId) => {
        setIsEditing(true)
        setSelectedId(taskId)
        var target = e.target.value;
        setTargetValue((prevMap) => ({
          ...prevMap,
          [taskId]: target,
        }));
        handleEdit(e, taskId)
      };
    
      const handleActualChange = (e, taskId) => {
        setIsEditing(true)
          setSelectedId(taskId)
        var actual = e.target.value;
        setActualValue((prevMap) => ({
          ...prevMap,
          [taskId]: actual,
        }));
        handleEdit(e, taskId)
      };

      const handleNotesChange = (e, taskId) => {
          setSelectedId(taskId)
        setIsEditing(true)
        setSelectedId(taskId)
        var note = e.target.value;
        setNotesValue((prevMap) => ({
          ...prevMap,
          [taskId]: note,
        }));
        handleEdit(e, taskId)
      };

      const handleEdit = (e, taskId) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEditing(true)
        setEditedTaskIds((prevSet) => new Set(prevSet.add(taskId)));
          setEditingTasks(prevState => ({
            ...prevState,
            [taskId]: true,
          }));
      };

      const onSaveTaskDetails = () => {
        const updatedTasks = initiativeList.map((initiative) => {
        let target = initiative.target;
        let actual = initiative.actual;
        let note = initiative.note;
        let id = initiative.id;
        setSelectedId("")
        if (editedTaskIds.has(initiative.id)) {
            target = targetValue[initiative.id] || initiative.target;
            actual = actualValue[initiative.id] || initiative.actual
            note = notesValue[initiative.id] || initiative.note;
            id = initiative.id 
        }
        else if (initiative.id === selectedId){
          id = selectedId || initiative.id;
          target = targetValue[selectedId] || initiative.target;
          actual = actualValue[selectedId] || initiative.actual
          note = notesValue[selectedId] || initiative.note;
      }

            return {
              ...initiative,
              id,
              target,
              actual,
              note,
            }
            
        }); 
        dispatch(addTargetActual(updatedTasks));
      };    

    useEffect(() => {
      dispatch(listInitiative(filterYear, country, facility));
    }, [dispatch, filterYear, facility, country]);
  
    useEffect(() => {
        if (saveInitiative.status === STATUS.SUCCESS) {
          dispatch(resetGoals());
          dispatch(listInitiative());
          setEditingTasks(prevState => {
            const newState = {};
            for (const key in prevState) {
              newState[key] = false;
            }
            return newState;
          });
          
          enqueueSnackbar("Saved successfully", { variant: "success", autoHideDuration: 3000, });
        } else if (saveInitiative.status === STATUS.ERROR) {
          dispatch(resetGoals());
          enqueueSnackbar(
            "We couldn't process your request. Please try again later.",
            { variant: "error", autoHideDuration: 3000, }
          );
        }
      }, [saveInitiative, dispatch]);

      const getInitiativesList = () => {
      if (!Array.isArray(initiativeList)) {
        return [];
      }
  
      return initiativeList.map((item) => ({
        ...item,
        target: editingTasks[item.id] ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={targetValue[item.id] || item.target}
            onChange={(e) => handleTargetChange(e, item.id)}
            placeholder="Enter"
            disabled={readOnlyMode}
          />
        ) : item.target === null ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={targetValue[item.id] || item.target}
            onChange={(e) => handleTargetChange(e, item.id)}
            placeholder="Enter"
            disabled={readOnlyMode}
          />
        ) : (
          item.target
        ),
        actual: editingTasks[item.id] ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={actualValue[item.id] || item.actual}
            onChange={(e) => handleActualChange(e, item.id)}
            placeholder="Enter"
            disabled={readOnlyMode}
          />
        ) : item.actual === null ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={actualValue[item.id] || item.actual}
            onChange={(e) => handleActualChange(e, item.id)}
            placeholder="Enter"
            disabled={readOnlyMode}
          />
        ) : (
          item.actual
        ),
        notes: editingTasks[item.id] ? (
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={2}
            value={notesValue[item.id] || item.note}
            onChange={(e) => handleNotesChange(e, item.id)}
            placeholder="Enter details"
            disabled={readOnlyMode}
          />
        ) : item.note === null ? (
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={2}
            value={notesValue[item.id] || item.note}
            onChange={(e) => handleNotesChange(e, item.id)}
            placeholder="Enter details"
            disabled={readOnlyMode}
          />
        ) : (
          item.note
        ),
        edit:
          !readOnlyMode && (
            <CreateIcon
              onClick={(e) => handleEdit(e, item.id)}
              className={classes.editIcon}
            />
          ),
      }));
    };
  
    return (
      <DashboardLayout>
        <Container className={classes.container}>
        <div style={{display: "flex"}}>
          <Grid
            className={classes.filterContainer}
            container
            columnSpacing={2}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={3} className={classes.yearFilter}>
              <CeroDropdown
                id="year"
                label="Year"
                fullWidth
                options={sampleYear()}
                onChange={handleYearChange}
                selectedValue={filterYear}
                classes={{ container : classes.dropdown}}
              />
            </Grid>
            <Grid item xs={3}>
              <CeroDropdown
                id="country"
                label="Country"
                fullWidth
                options={countryOptions}
                onChange={handleCountryChange}
                selectedValue={country}
                classes={{ container : classes.dropdown}}
              />
            </Grid>
            <Grid item xs={3}>
              <CeroDropdown
                id="facility"
                label="Facility"
                fullWidth
                options={facilitiesList}
                onChange={handleFacilityChange}
                selectedValue={facility}
                classes={{ container : classes.dropdown}}
              />
            </Grid>
          </Grid>
          <div>
          {isEditing && <CeroButton
              buttonText="Save"
              className={classes.buttonPrimary}
              onClick={onSaveTaskDetails}
              disabled={readOnlyMode}
            />}
            </div>
            </div>
          {initiativeListStatus === STATUS.SUCCESS ? (
            <Container className={classes.tableContainer}>
              <CeroTable
                columns={initiativesColumns}
                data={getInitiativesList()}
                hasMore={false}
                loading={false}
                classes={{ tableContainer: classes.tableInnerContainer }}
              />
            </Container>
          ) : (
            <Box className={classes.loader}>
              <Typography variant="h7" component="span">
                {initiativeListStatus === STATUS.RUNNING ? (
                  <CircularProgress />
                ) : initiativeListStatus === STATUS.ERROR ? (
                  "We couldn't process your request. Please try again later"
                ) : (
                  ""
                )}
              </Typography>
            </Box>
          )}
        </Container>
      </DashboardLayout>
    );
  };
  
  export default TargetVsActuals;
  