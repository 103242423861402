import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "101%",
    height: "60px",
    padding: "0px 30px;",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: `0 4px 4px rgb(0 0 0 / 10%)`,
    background: `${theme.palette.Primary.main}!important`,
  },
  tradeHeader: {
    width: "101%",
    height: "60px",
    padding: "0px 30px;",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: `0 4px 4px rgb(0 0 0 / 10%)`,
    background: `${theme.palette.trade.header}!important`,
  },
  icon: {
    color: `${theme.palette.icon.gray}!important`,
  },
  searchBoxContainer: {
    display: "flex",
    alignItems: "center",
  },
  searchBox: {
    border: "none",
    outline: "none",
  },
  notificationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    color: theme.palette.Primary.contrastText,
    fontWeight: 600,
    letterSpacing: 1.5,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.contrastText,
    background: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      color: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
}));

export default useStyles;