import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";

import useStyles from "./styles";
import CeroChipSelect from "../../../components/CeroChipSelect";
import { useDispatch, useSelector } from "react-redux";
import { getAssessmentYear } from "../../../redux/actions";

const Filter = ({ setFilter, frameworkList = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [framework_id, setFramework] = useState(null);
  const [filterYear, setYear] = useState(null);
  
  const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
  const countryFacilityData = useSelector(state => state.listings.getOrganizationCountry.data.country_facility_data);
  const countryFacilityDataObject = {};
  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }
  
  const yearData = useSelector(state => state.listings.getAssessmentYear.data);
  const yearList = yearData.map((item) => ({
    id: item,
    label: item,
  }));

  const [filterCountry, setCountry] = useState([]);
  const [facilitites, setFacility] = useState(countryFacilityDataObject);
  const [filterCountryFacility, setCountryFacility] = useState([]);
  
  
  useEffect(() => {
    const countryCodes = Array.isArray(filterCountry)
    ? filterCountry.map(country => countryData[country]).filter(Boolean)
    : [countryData[filterCountry]];    
    dispatch(getAssessmentYear(countryCodes));
}, [dispatch, filterCountry]);

  const getFacility= (countries) => {
    const countryFacilityDataObject = {};
    var availableFacility = []
    for (const item of countries) {
      Object.assign(countryFacilityDataObject,countryFacilityData[countryData[item]]);
      availableFacility = availableFacility.concat(Object.keys(countryFacilityData[countryData[item]])) 
    }
    const persistedfacilitySelection = availableFacility.filter(element => filterCountryFacility.includes(element));
    setFacility(countryFacilityDataObject);
    const needRefresh = filterCountryFacility.length === persistedfacilitySelection.length
    setCountryFacility(persistedfacilitySelection);
    if (!needRefresh){
        refreshTableWithApi('facility',persistedfacilitySelection)
    }
  }
    const countryHandleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          setCountry(filterCountry.length === Object.keys(countryData).length ? [] : Object.keys(countryData));
          getFacility(filterCountry.length === Object.keys(countryData).length ? [] : Object.keys(countryData))
          return;
        }
        setCountry(value);
        getFacility(value)
      };

      const FacilityHandleChange = (event) => {
        const value = event.target.value;
        var avlData = []
        if (value[value.length - 1] === "all") {
          avlData = filterCountryFacility.length === Object.keys(facilitites).length ? [] : Object.keys(facilitites)
        }
        else{
          avlData = value
        }
        setCountryFacility(avlData);
        refreshTableWithApi('facility', avlData)
        return;
        }

  const onChangeFilter = (type, selectedValue) => {
    const value = selectedValue ? selectedValue.id : null;
    const filter = {
      framework_id,
    };
    if (type === "year") {
      setYear(value);
      refreshTableWithApi('year', value)
    } else {
      filter.framework_id = value;
      setFramework(value);
    }
  };

  const refreshTableWithApi = (type,data)=>{
    var filter = {}
    if (type === 'facility'){
    if (filterYear){
      filter.year = filterYear
    }
    if(data.length>0){
      const apiFacilitites = []
      for (const item of data){
        apiFacilitites.push(countryFacilityDataObject[item])
      }
      filter.facilities = apiFacilitites
    }
  }
  else if(type === 'year'){
    if(filterCountryFacility.length>0){
      const apiFacilitites = []
      for (const item of filterCountryFacility){
        apiFacilitites.push(countryFacilityDataObject[item])
      }
      filter.facilities = apiFacilitites
    }
    filter.year = data
  }
  setFilter(filter)
}

  return (
    <Container className={classes.container}>
      <Grid
        className={classes.filterContainer}
        container
        columnSpacing={3}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs={3.5}>
          <CeroChipSelect label={'Country'} formControlClass={classes.MultiSelectformControl} optionsde={countryData} selected={filterCountry} handleChange={countryHandleChange}/>
        </Grid>
        <Grid item xs={3.5}>
        <CeroChipSelect label={'Facility'} formControlClass={classes.MultiSelectformControl} optionsde={Object.keys(facilitites).length>0?facilitites:countryFacilityDataObject} selected={filterCountryFacility} handleChange={FacilityHandleChange} sortOption={true}/>
        </Grid>
        <Grid item xs={3.5} className={classes.yearFilter}>
          <CeroAutoComplete
            id="year"
            label="Year"
            onChange={(e, value) => onChangeFilter("year", value)}
            options={yearList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Filter;
