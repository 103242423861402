import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(4),
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    }
  },
  tabsCard: {
    "& .MuiTab-root": {
      color: theme.palette.text.dark,
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: 16,
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.Primary.background,
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: 16,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.Primary.background,
      height: 3,
    },
  },
  countCard: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  countContainer: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.Other.titleText,
  },
  count: {
    fontWeight: 600,
    paddingLeft: theme.spacing(3),
  },
  name: {
    fontWeight: 600,
    paddingTop: theme.spacing(2),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 180,
    borderRadius: 5,
    marginLeft: theme.spacing(30),
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
}));

export default useStyles;
