import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, FormControlLabel, FormGroup, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import _ from "lodash";

import CeroInput from "../../../components/CeroInput";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroSideSheetDrawer from "../../../components/CeroSideSheetDrawer";
import { months, sampleYear } from "../../../constants";
import { createReportValidation } from "./schema";
import {
  resetReportStatus,
  createReport,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";

import useStyles from "./styles";
import CeroChipSelect from "../../../components/CeroChipSelect";
import CeroSelect from "../../../components/CeroSelect";

const CreateReportDrawer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectAll, setSelectAll] = useState(false);


  const { reportData, isOpen } = props;
  const topicList = useSelector((state) => state.listings.topicList.data);
  const topicFetchStatus = useSelector((state) => state.listings.topicList.status);
  const createReportStatus = useSelector((state) => state.reports.createReport.status);
  const createReportError = useSelector((state) => state.reports.createReport.message);
  const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
  const countryFacilityData = useSelector(state => state.listings.getOrganizationCountry.data.country_facility_data);
  const assessmentYearData = useSelector(state => state.listings.getAssessmentYear.data);
  const countryFacilityDataObject = {};

  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }
  const isButtonLoading = createReportStatus === STATUS.RUNNING;

  // const frameworkOptionList = frameworkList.map((item) => ({
  //   key: item.id,
  //   value: item.name,
  // }));
  // const filteredFrameworkOptions = frameworkOptionList.filter(option => option.value === 'GRI');
  // const currentYear = new Date().getFullYear()

  const topicOptionList = topicList.map((item) => ({
    id: item.id,
    label: item.name,
  }));

  const yearList = assessmentYearData.map((item) => ({
    id: item,
    label: item,
  }));

  const getReportTopic = (data = []) =>
    data.map((item) => {
      const innerItem = topicOptionList.find((inner) => inner.id === item);
      return _.isEmpty(innerItem) ? { id: item, label: item } : innerItem;
    });

  const createReportForm = useFormik({
    initialValues: {
      name: reportData ? reportData.name : "",
      year: reportData ? dayjs(reportData.created_ts).get("year") : yearList? yearList[0] : "",
      framework: reportData ? reportData.framework_id : "",
      topic: reportData ? getReportTopic(reportData.topics) : topicOptionList,
      start_month: reportData ? reportData.start_month : "",
      end_month: reportData ? reportData.end_month : "",
    },
    validationSchema: createReportValidation,
    enableReinitialize: true,
    onSubmit: () => { },
  });

const mapping = {};
topicList.forEach(obj => {
  mapping[obj.name] = obj.id;
});
const topicIdList = selectedValues.map(name => mapping[name]);

  const onSubmitReportData = () => {
    const payload = {
      // framework_id: createReportForm.values.framework,
      name: createReportForm.values.name,
      year: createReportForm.values.year,
      topic_id: topicIdList,
      start_month: createReportForm.values.start_month,
      end_month: createReportForm.values.end_month
    };
    if(filterCountryFacility.length>0){
      var apiFacilitites = []
      for (const data of filterCountryFacility){
        apiFacilitites.push(countryFacilityDataObject[data])
      }
      payload.facilities = apiFacilitites
    }
    dispatch(createReport(payload));
  };
  const onClose = useCallback(() => {
    createReportForm.resetForm();
    props.onClose();
  }, [createReportForm, props]);

  useEffect(() => {
    if (createReportStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Report created successfully", { variant: "success" });
      dispatch(resetReportStatus());
      setSelectedValues([]);
      setCountry([]);
      setCountryFacility([]);
      onClose();
    } else if (createReportStatus === STATUS.ERROR) {
      enqueueSnackbar(createReportError.message || "We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetReportStatus());
      setSelectedValues([]);
      setCountry([]);
      setCountryFacility([]);
    }
  }, [createReportStatus, enqueueSnackbar, onClose, dispatch, createReportError.message]);

  useEffect(() => {
    if (topicFetchStatus === STATUS.SUCCESS) {
      createReportForm.setFieldValue('topic_id', topicOptionList);
    }
  }, [topicFetchStatus]);

  

  /////
  const [personName, setPersonName] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (value === 'all'){
      if (selectedValues.length === topicOptionList.length){
        setSelectedValues([]);
        setSelectAll(false);
      }
      else{
        setSelectedValues(topicOptionList.map(item=>item.label));
        setSelectAll(true);
      }
    }
    else{
      const updatedArray = selectedValues.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...selectedValues, value]
      setSelectedValues(updatedArray);
      if (updatedArray.length === topicOptionList.length)
        setSelectAll(true);
      else
        setSelectAll(false);
  };
  }

  const [filterCountry, setCountry] = useState([]);
  const [facilitites, setFacility] = useState(countryFacilityDataObject);
  const [filterCountryFacility, setCountryFacility] = useState([]);
  const getFacility= (countries) => {
    const countryFacilityDataObject = {};
    var availableFacility = []
    for (const item of countries) {
      Object.assign(countryFacilityDataObject,countryFacilityData[countryData[item]]);
      availableFacility = availableFacility.concat(Object.keys(countryFacilityData[countryData[item]])) 
    }
    const persistedfacilitySelection = availableFacility.filter(element => filterCountryFacility.includes(element));
    setFacility(countryFacilityDataObject);
    setCountryFacility(persistedfacilitySelection);
  }
    const countryHandleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          setCountry(filterCountry.length === Object.keys(countryData).length ? [] : Object.keys(countryData));
          getFacility(filterCountry.length === Object.keys(countryData).length ? [] : Object.keys(countryData))
          return;
        }
        setCountry(value);
        getFacility(value)
      };

      const FacilityHandleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          setCountryFacility(filterCountryFacility.length === Object.keys(facilitites).length ? [] : Object.keys(facilitites));
          return;
        }
        setCountryFacility(value);
      };

  const getPrimaryPaymentDrawer = () => {
    return (
      <Box className={classes.mainContainer}>
        <CeroInput
          required
          id="name"
          name="name"
          label="Report Name"
          fullWidth
          value={createReportForm.values.name}
          onChange={createReportForm.handleChange}
          onBlur={createReportForm.handleBlur}
          error={createReportForm.touched.year && createReportForm.errors.name}
        />
        <CeroChipSelect label={'Country'} formControlClass={classes.MultiSelectformControl} optionsde={countryData} selected={filterCountry} handleChange={countryHandleChange}/>
        <CeroChipSelect label={'Facility'} formControlClass={classes.MultiSelectformControl} optionsde={Object.keys(facilitites).length>0?facilitites:countryFacilityDataObject} selected={filterCountryFacility} handleChange={FacilityHandleChange}/>
        {/* <CeroSelect
          classes={{ container: classes.selectContainer }}
          required
          id="framework"
          name="framework"
          label="Framework"
          fullWidth
          options={filteredFrameworkOptions}
          selectedValue={createReportForm.values.framework}
          onChange={createReportForm.handleChange}
          onBlur={createReportForm.handleBlur}
          error={
            createReportForm.touched.year && createReportForm.errors.framework
          }
        /> */}
        <CeroAutoComplete
         className ={classes.yearFilter}
          required
          id="year"
          name="year"
          label="Assessment Year"
          fullWidth
          options={yearList}
          value={createReportForm.values.year}
          onChange={(e, value) => {
            createReportForm.setFieldValue("year", value.id);
          }}
          onBlur={createReportForm.handleBlur}
          error={createReportForm.touched.year && createReportForm.errors.year}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          // value={currentYear}
        />
        <CeroSelect
          classes={{ container: classes.selectContainer }}
          id="start_month"
          name="start_month"
          label="Start Month"
          fullWidth
          options={months}
          selectedValue={
            createReportForm.values.start_month || ""
          }
          onChange={createReportForm.handleChange}
          onBlur={createReportForm.handleBlur}
          error={
            createReportForm.touched.start_month &&
            createReportForm.errors.start_month
          }
        />
        <CeroSelect
          classes={{ container: classes.selectContainer }}
          id="end_month"
          name="end_month"
          label="End Month"
          fullWidth
          options={months}
          selectedValue={createReportForm.values.end_month || ""}
          onChange={createReportForm.handleChange}
          onBlur={createReportForm.handleBlur}
          error={
            createReportForm.touched.end_month &&
            createReportForm.errors.end_month
          }
        />
        <InputLabel className={createReportForm.errors.end_month?classes["input-padding"]:classes["no-padding"]} id="demo-multiple-name-label">Select Topics</InputLabel>
        <Box className={classes.checkbox}>
          <FormGroup>
          <FormControlLabel
                key={"all"}
                control={
                  <Checkbox className={classes.selectCheckbox}
                    checked={selectAll}
                    onChange={handleCheckboxChange}
                    value={'all'}
                    style ={{
                      color: '#8C2148',
                    }}
                  />
                }
                label={"Select All"}
              />
            {topicOptionList.map((topic) => (
              <FormControlLabel
                key={topic.id}
                control={
                  <Checkbox className={classes.selectCheckbox}
                    checked={selectedValues.includes(topic.label)}
                    onChange={handleCheckboxChange}
                    value={topic.label}
                    style ={{
                      color: '#8C2148',
                    }}
                  />
                }
                label={topic.label}
              />
            ))}
          </FormGroup>
        </Box>

      </Box>
    );
  };
  return (
    <CeroSideSheetDrawer
      primaryDrawer={{
        drawerOpen: isOpen,
        onClose: onClose,
        content: getPrimaryPaymentDrawer(),
        header: { title: "Create report" },
        footer: {
          primaryBtnTitle: isButtonLoading ? "Saving..." : "Save",
          secondaryBtnTitle: "Cancel",
          primaryBtnAction: onSubmitReportData,
          secondaryBtnAction: onClose,
          disablePrimaryBtn:
            !createReportForm.dirty ||
            !createReportForm.isValid ||
            isButtonLoading,
        },
        classes: {
          drawerContainer: classes.drawerContainer,
          contentArea: classes.drawerContentArea,
        },
      }}
    />
  );
};

export default CreateReportDrawer;
