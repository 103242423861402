import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    uploadBox:{
        border:"dotted 2px",
        padding:2,
        textAlign:"center",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
    },
    linearProgress:{
        width: "100%", 
        marginBottom: 15,
        borderRadius: 5
    },
    progressBox:{
        marginTop: theme.spacing(5),
    },
    colorPrimary: {
        backgroundColor: 'white',
      },
      barColorPrimary: {
        backgroundColor: theme.palette.Primary.background
      },
      fileBox:{
        display: "flex", 
        justifyContent: "space-between", 
        color: "black", 
        alignItems: "center", 
        padding: 10
      },
      fileDetails:{
        float: "right", 
        color: "black", 
        display: "flex", 
        alignItems: "center", 
        fontSize: 12
      }
}));

export default useStyles;

