import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import {
  schemeValidation,
} from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updateEmployeeDiversity,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import CeroSelect from "../../../components/CeroSelect";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";

const EditEmployeeDiversity = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const updateEmissionData = useSelector(
    (state) => state.emission.updateEmployeeDiversity
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || "",
      assessmentPeriod: emissionData.assessment_period || "",
      salaryPaid: emissionData.salary_paid || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'employees_diversity_and_ratio_of_basic_salary', emissionData.id));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Diversity updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message || "Something went wrong",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateEmployeeDiversity= () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      salary_paid: formik.values.salaryPaid,
      save: true,
    };
    dispatch(updateEmployeeDiversity(requestData));
  };

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Employee Diversity
          </Typography>
          <Box>
          <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroInput
                  required
                  id="salaryPaid"
                  name="salaryPaid"
                  label="Total amount of salary payed"
                  value={formik.values.salaryPaid}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.salaryPaid && formik.errors.salaryPaid}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.buttonContainer}>
            <CeroButton
              buttonText="Update"
              disabled={!formik.dirty || !formik.isValid}
              className={clsx(classes.button, classes.buttonPrimary)}
              onClick={onUpdateEmployeeDiversity}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={clsx(classes.button, classes.buttonSecondary)}
              onClick={formik.resetForm}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default EditEmployeeDiversity;
