import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRefreshToken, userAutoLogout, userLogout } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import useIdle from "../useIdle";
import CeroButton from "../../components/CeroButton";
import { Box } from "@mui/material";
import useStyles from "./styles";
import { getCookie } from "../../services/cookie";

function SessionTimeOut(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalOnLogout, setModalOnLogout] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [offlineTimer, setOfflineTimer] = useState(null);

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);

  const expiryTime = getCookie("access_token_expiry");
  const currentTime = new Date().getTime() / 1000;

  const handleIdle = () => {
    setShowModal(true); //show modal
    setRemainingTime(60); //set 15 seconds as time remaining
  };

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 10 });
  console.log("is user idle?", isIdle);

  useEffect(() => {
    let interval;

    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      // alert("Time out!");
      setShowModal(false);
      dispatch(userLogout(userInfo.email));
      dispatch(userAutoLogout(true));
    }
  }, [remainingTime, showModal]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything

  const handleLogOut = () => {
    setShowModal(false);
    navigate("/");
    dispatch(userLogout(userInfo.email));
  };

  const handleStayLoggedIn = () => {
    setShowModal(false);
    if (Number(expiryTime) - currentTime < 60000) {
      dispatch(getRefreshToken());
    }
  };

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  useEffect(() => {
    const handleOffline = () => {
      // Start a timer for 5 minutes (600,000 milliseconds)
      const timer = setTimeout(() => {
        handleLogOut()
      }, 300000);

      setOfflineTimer(timer);
    };

    const handleOnline = () => {
      if (offlineTimer) {
        clearTimeout(offlineTimer);
        setOfflineTimer(null);
      }
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
      if (offlineTimer) clearTimeout(offlineTimer);
    };
  }, [dispatch, offlineTimer]);

  return (
    <>
      {/* handle isIdle for the modal */}
      {isIdle && showModal && (
        <>
          <Box className={classes.backdrop}></Box>
          <Box className={classes.modal}>
            <Box className={classes.content}>
              <h2>Idle Timeout Warning</h2>
              <p>You are about to be logged out due to inactivity.</p>
              <br />
             <span style={{color:"red"}}>Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}</span>
              <br />
              <Box display="flex" justifyItems="space-between">
                <CeroButton
                  className={classes.btn}
                  onClick={() => handleLogOut()}
                  buttonText="Logout"
                />
                <CeroButton
                  className={classes.btn}
                  onClick={() => handleStayLoggedIn()}
                  buttonText="Stay Logged In"
                />
              </Box>
            </Box>
          </Box>
        </>
      )}

      {props && props.children && (
        <div
          style={{
            opacity: (isIdle && showModal) || modalOnLogout ? 0.4 : 1,
            pointerEvents: (isIdle && showModal) || modalOnLogout ? "none" : "",
          }}
        >
          {props.children}
        </div>
      )}
    </>
  );
}

export default SessionTimeOut;
