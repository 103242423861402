import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import { EmployeeHireAndTurnover, EmployeeHireAndTurnoverByAge} from "../EmissionTable/TableColumns";
import CeroTable from "../../../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../../../redux/constants";
import { ApprovalEmployeeHireAndTurnover, ApprovalEmployeeHireAndTurnoverByAge } from "../../../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusDetails/ApprovalTableColumns";

const EmployeeHireTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    backToEmission,
    editOff,
    reportTable,
    isOnlyAdmin,
    period,
    distribution
  } = props;


  const currentPath = window.location.pathname;
  const approvalTable = currentPath.includes('approval') 

  const onSelectEmissionData = (rowData) => {
    const currentFilter = encodeURI(
      `?${rowData.gender ? `&distribution=${rowData.gender}` : ""}${rowData.employee_classification ? `&classification=${rowData.employee_classification}` : ""}`
    );
    isAuditor
      ? navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}/?company=${company}`
        )
      : navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}${currentFilter}`
        );
  };
  
  const onSelectData = (rowData) => {
    let ageFilter = rowData.age;
    if(rowData.age === "Under 30"){
      ageFilter = "under_30";
    }else if(rowData.age === "Between 30 and 50"){
      ageFilter = "between_30_50"
    }else{
      ageFilter = "over_50"
    }
    const currentFilter = encodeURI(
      `?${ageFilter ? `&distribution=${ageFilter}` : ""}${rowData.employee_classification ? `&classification=${rowData.employee_classification}` : ""}`
    );
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };

  const getStatus = (status) => {
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    }else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };
  const onEditEmissionData = (e, emission) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/emissions/edit/employees_hire_and_turnover/${emission}`);
  };
  const getGenderDistribution = () =>
  emissionData && emissionData?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      turnover_rate : item.turnover_rate ? `${item.turnover_rate}%` : "",
      period: period,
      action: (
        <Box className={classes.actionContainer}>
          {item.status == "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }));

    const getByAgeDistribution = () =>
    emissionData && emissionData?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      turnover_rate : item.turnover_rate ? `${item.turnover_rate}%` : "",
      action: (
        <Box className={classes.actionContainer}>
          {item.status == "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }));

  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          {distribution === "gender" &&
          <>
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Employees Hire And Turnover by Gender
              </span>
              </Typography>
          <CeroTable
            columns={(approvalTable ? ApprovalEmployeeHireAndTurnover : EmployeeHireAndTurnover) || []}
            data={getGenderDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={approvalTable ? '#' :onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          /></>}
          {distribution === "age" && 
          <>
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Employees Hire And Turnover by Age
              </span>
              </Typography>
          <CeroTable
            columns={(approvalTable ? ApprovalEmployeeHireAndTurnoverByAge : EmployeeHireAndTurnoverByAge) || []}
            data={getByAgeDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={approvalTable ? '#' : onSelectData}
            classes={{ tableContainer: classes.tableContainer }}
          /></>}
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default EmployeeHireTable;
