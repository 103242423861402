import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopicBasedApproval } from "../../../../../redux/actions";
import EmployeeDiversityApproval from "../../../../ApprovalDetailsCard/EmployeeDiversityApproval";
import EmployeeHealthApproval from "../../../../ApprovalDetailsCard/EmployeeHealthApproval";
import WorkerSafetyApproval from "../../../../ApprovalDetailsCard/WorkerSafetyApproval";
import DiscriminationIncidentApproval from "../../../../ApprovalDetailsCard/DiscriminationIncidentApproval";
import EmployeeBenefitsApproval from "../../../../ApprovalDetailsCard/EmployeeBenefitsApproval";
import SupplierScreeningApproval from "../../../../ApprovalDetailsCard/SupplierScreeningApproval";
import { STATUS } from "../../../../../redux/constants";
import SupplierHumanRightsApproval from "../../../../ApprovalDetailsCard/SupplierHumanRightsApproval";
import LocalCommunitiesApproval from "../../../../ApprovalDetailsCard/LocalCommunitiesApproval";
import SocialHumanRightsApproval from "../../../../ApprovalDetailsCard/SocialHumanRightsApproval";
import PoliticalContributionsApproval from "../../../../ApprovalDetailsCard/PoliticalContributionsApproval";
import BoardDiversityApproval from "../../../../ApprovalDetailsCard/BoardDiversity";
import ManagementDiversityApproval from "../../../../ApprovalDetailsCard/MangementDiversity";
import AntiCorruptionDisclosureApproval from "../../../../ApprovalDetailsCard/AntiCorruptionDisclosure";
import AntiCompatitiveDisclosureApproval from "../../../../ApprovalDetailsCard/AntiCompatitiveDisclosure";
import EconomicImpactApproval from "../../../../ApprovalDetailsCard/EconomicImpact";
import SubsidiesAndFinancialAssistanceApproval from "../../../../ApprovalDetailsCard/SubsidiesAndFinancialAssistance";
import AntiCorruptionTrainingApproval from "../../../../ApprovalDetailsCard/AntiCorruptionTraining";
import TrainingHoursApproval from "../../../../ApprovalDetailsCard/TrainingHoursApproval";
import PerformanceAndCareerApproval from "../../../../ApprovalDetailsCard/PerformanceAndCareerApproval";


const PendingApprovalCard = (props) => {
  const { topicType, approvalData, approvalTopicDataStatus, onApproveId, onReviewId}  = props;
  const handleCallback = (item) => {
    onApproveId(item)
  }
 
  const handleReviewCallback = (item) => {
    onReviewId(item)
  }
  return (
    <>
      {approvalTopicDataStatus === STATUS.SUCCESS &&
        <>
        {topicType === "employees_benefit" && (
              <EmployeeBenefitsApproval 
              emissionData={approvalData}
              handleCallback={handleCallback}
              handleReviewCallback={handleReviewCallback}
              />
            )}
            {topicType === "employee_health_safety_incident_record" && (
            <EmployeeHealthApproval
              emissionData={approvalData}
              handleCallback={handleCallback}
              handleReviewCallback={handleReviewCallback}
            />
          )}
          {topicType === "worker_safety_training_procedures" && (
            <WorkerSafetyApproval
              emissionData={approvalData}
              handleCallback={handleCallback}
              handleReviewCallback={handleReviewCallback}
            />
          )}
          {topicType === "employees_diversity_and_ratio_of_basic_salary" && (
            <EmployeeDiversityApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "discrimination_incident_record" && (
            <DiscriminationIncidentApproval
              emissionData={approvalData}
              handleCallback={handleCallback}
              handleReviewCallback={handleReviewCallback}
            />
          )}
          {topicType === "supplier_screening_using_enviormental_social_criteria" && (
            <SupplierScreeningApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "suppliers_human_rights" && (
            <SupplierHumanRightsApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "local_communities" && (
            <LocalCommunitiesApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "social_engagement_human_rights_training" && (
            <SocialHumanRightsApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "political_contributions" && (
            <PoliticalContributionsApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "board_diversity" && (
            <BoardDiversityApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "management_diversity" && (
            <ManagementDiversityApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "anti_corruption_disclosure" && (
            <AntiCorruptionDisclosureApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "anti_competitive_disclosure" && (
            <AntiCompatitiveDisclosureApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "economic_impact" && (
            <EconomicImpactApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "subsidies_financial_assistance" && (
            <SubsidiesAndFinancialAssistanceApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "anti_corruption_training" && (
            <AntiCorruptionTrainingApproval
              emissionData={approvalData}
            />
          )}
                {topicType === "training_hours_per_employee" && (
            <TrainingHoursApproval
              emissionData={approvalData}
              handleCallback={handleCallback}
              handleReviewCallback={handleReviewCallback}
            />
          )}
          {topicType === "performance_and_career_development_programs" && (
            <PerformanceAndCareerApproval
              emissionData={approvalData}
              handleCallback={handleCallback}
              handleReviewCallback={handleReviewCallback}
            />
          )}
        </>
      }
    </>
  );
};

export default PendingApprovalCard;
