import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  addEmployeeDiversity,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { schemeValidation } from "./schema";

const AddEmployeeDiversity = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const addDataStatus = useSelector(
    (state) => state.emission.addEmployeeDiversity
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      salaryPaid: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (addDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Diversity records added successfully", {variant: "success",});
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addDataStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        addDataStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error",}
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addDataStatus, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'employees_diversity_and_ratio_of_basic_salary'));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const onAddData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      salary_paid: formik.values.salaryPaid,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addEmployeeDiversity(requestData));
  };

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Employee Diversity and Ratio of Basic Salary
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroInput
                  required
                  id="salaryPaid"
                  name="salaryPaid"
                  label="Total amount of salary payed"
                  value={formik.values.salaryPaid}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.salaryPaid && formik.errors.salaryPaid}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid}
            className={classes.buttonPrimary}
            onClick={() => onAddData(formik.values)}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddEmployeeDiversity;
