
import { Grid, Typography, Container, Stack,Card } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

import CeroButton from "../../../components/CeroButton";
import Logo from "../../../assets/images/Logo";
import useStyles from "./styles";

const SignupResponse = () => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.wrapper}
      >
        <Container component="main" maxWidth="xs">
          <Box className={classes.logo}>
            <Logo/>
          </Box>
          <Box className={classes.message}>
            <Typography className={classes.messageText}>Thanks for signing up.</Typography>
            <Typography className={classes.messageText}>We're glad you're here.</Typography>
          </Box>
          <Link to="/login/business" style={{textDecoration:"none"}}>
            <CeroButton
              className={classes.button}
              buttonText="Login to Get Started !"
            />
          </Link>
          
        </Container>
      </Grid>
    </Card>
  );
};

export default SignupResponse;
