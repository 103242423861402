import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updateEmployeeBenefits,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { sampleYear } from "../../../constants";
import CeroDate from "../../../components/CeroDate";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroChipSelect from "../../../components/CeroChipSelect";

const EditEmployeeBenefits = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showOtherDetails, setShowOtherDetails] = useState(false);

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const updateEmissionData = useSelector(
    (state) => state.emission.updateEmployeeBenefits
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || "",
      assessmentPeriod: emissionData.assessment_period || "",
      employeeClassification: emissionData.employees_classification || "",
      noOfEmployees: emissionData.number_of_employees || 0,
      benefitsProvided: emissionData.benefit_provided ? emissionData.benefit_provided : [],
      otherDetails: emissionData.details || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

    const emissionInputs = useSelector(
        (state) => state.emission.emissionInputs.data
      );
      
    const classificationTypes = [
        { key: "Permanent", value: "Permanent" },
        { key: "Temporary", value: "Temporary" },
      ];
    
      const benefitsList = {
        "Disability and invalidity coverage": "Disability and invalidity coverage",
        "Health care": "Health care",
        "Life insurance": "Life insurance",
        "Parental leave": "Parental leave",
        "Retirement provision": "Retirement provision",
        "Stock ownership": "Stock ownership",
        "Others": "Others"
    };

      const benefitHandleChange = (event) => {
        const value = event.target.value;
        formik.setFieldValue("benefitsProvided", value);
        setShowOtherDetails(value.includes("Others"));
      };
    
      const empHandleChange = (event, value) => {
        if(emissionInputs.permanent_employees === null || emissionInputs.temporary_employees === null){
         formik.handleChange(event);
        }else{
          formik.setFieldValue("noOfEmployees", value.id)
        }
      }

      useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Benefits record updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateEmployeeBenefits = () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      employees_classification: formik.values.employeeClassification,
      number_of_employees:  formik.values.noOfEmployees,
      benefit_provided: formik.values.benefitsProvided,
      details: formik.values.otherDetails,
      save: true,
    };
    dispatch(updateEmployeeBenefits(requestData));
  };

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Employee Benefits
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroSelect
                  required
                  id="employeeClassification"
                  name="employeeClassification"
                  label="Employee Classification"
                  options={classificationTypes}
                  selectedValue={formik.values.employeeClassification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.employeeClassification &&
                    formik.errors.employeeClassification
                  }
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <Box className={classes.yearFilter}>
                  <CeroAutoComplete
                    required
                    id="year"
                    label="Year"
                    onChange={(e, value) =>
                      formik.setFieldValue("year", value.id)
                    }
                    onBlur={formik.handleBlur}
                    error={formik.touched.year && formik.errors.year}
                    options={yearList}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={formik.values.year}
                  />
                </Box>
                <CeroInput
                  required
                  id="noOfEmployees"
                  name="noOfEmployees"
                  label="Number of Employees"
                  value={formik.values.noOfEmployees}
                  fullWidth
                  onChange={(e, value) => empHandleChange(e, value)}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.noOfEmployees && formik.errors.noOfEmployees
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
                <CeroChipSelect
                  required
                  id="benefitsProvided"
                  name="benefitsProvided"
                  label={"Benefits Provided"}
                  formControlClass={classes.MultiSelectformControl}
                  optionsde={benefitsList}
                  selected={formik.values.benefitsProvided}
                  handleChange={(e) => benefitHandleChange(e)}
                  error={
                    formik.touched.benefitsProvided &&
                    formik.errors.benefitsProvided
                  }
                  value={formik.values.benefitsProvided}
                  classes={{ justCheckSelected: classes.justCheckSelected }}
                />
              </Grid>
            </Grid>
          </Box>
          {formik.values.benefitsProvided.includes("Others") && (
            <CeroInput
              id="otherDetails"
              name="otherDetails"
              label="Details of benefits provided"
              fullWidth
              value={formik.values.otherDetails}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.otherDetails && formik.errors.otherDetails}
            />
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={!formik.dirty || !formik.isValid}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onUpdateEmployeeBenefits}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={clsx(classes.button, classes.buttonSecondary)}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default EditEmployeeBenefits;
