import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { superAdminValidation } from "./schema";
import { STATUS } from "../../../redux/constants";
import { addSuperAdmin, editSuperAdmin, getSuperadminList, getSuperadmin, resetSuperAdminStatus } from "../../../redux/actions";
import CeroInput from "../../../components/CeroInput";
import CeroSideSheetDrawer from "../../../components/CeroSideSheetDrawer";
import useStyles from "./styles";

const AddSuperAdmin = (props) => {
  const { editItem, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const addSuperadminStatus = useSelector((state) => state.superAdmin.addSuperAdmin);
  const superAdminData = useSelector((state) => state.superAdmin.superadminDetails.data);
  const editSuperadminStatus = useSelector((state) => state.superAdmin.editSuperAdmin);

  const superAdminForm = useFormik({
    initialValues: {
      email: superAdminData.email || '',
      name: superAdminData.name || '',
    },
    validationSchema: superAdminValidation,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (editItem) {
      dispatch(getSuperadmin(editItem));
    }
    else{
      superAdminForm.setValues({
        email:  "",
        name:  "",
      });
      dispatch(resetSuperAdminStatus());
    };
  }, [editItem, dispatch]);

  useEffect(() => {
    if (addSuperadminStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Super Admin added successfully", { variant: "success" });
      dispatch(resetSuperAdminStatus());
      onClose(false);
      dispatch(getSuperadminList());
    } else if (addSuperadminStatus.status === STATUS.ERROR) {
      enqueueSnackbar(addSuperadminStatus.message, { variant: "error" });
    }
  }, [addSuperadminStatus, enqueueSnackbar, dispatch, onClose]);

  useEffect(() => {
    if (editSuperadminStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Super Admin edited successfully", {
        variant: "success",
      });
      dispatch(getSuperadminList());
      dispatch(resetSuperAdminStatus());
      onClose(false);
    } else if (editSuperadminStatus.status === STATUS.ERROR) {
      enqueueSnackbar(editSuperadminStatus.message, { variant: "error" });
    }
  }, [editSuperadminStatus, enqueueSnackbar, onClose, dispatch]);

  const onSubmitData = () => {
    if (props.editItem) {
      dispatch(
        editSuperAdmin(
          props.editItem,
          superAdminForm.values.email,
          superAdminForm.values.name
        )
      );
    } else {
      dispatch(
        addSuperAdmin(superAdminForm.values.email, superAdminForm.values.name)
      );
    }
  };

  const drawerTitle = props.editItem ? "Edit Super Admin" : "Add Super Admin";

  const getPrimaryPaymentDrawer = () => {
    return (
      <Box className={classes.mainContainer}>
        <CeroInput
          required
          id="email"
          name="email"
          label="Email"
          fullWidth
          value={superAdminForm.values.email}
          onChange={superAdminForm.handleChange}
          onBlur={superAdminForm.handleBlur}
          error={superAdminForm.errors.email}
          classes={{ container: classes.textAreaContainer }}
        />
        <CeroInput
          required
          id="name"
          name="name"
          label="Name"
          fullWidth
          value={superAdminForm.values.name}
          onChange={superAdminForm.handleChange}
          onBlur={superAdminForm.handleBlur}
          error={superAdminForm.errors.name}
        />
      </Box>
    );
  };

  return (
    <CeroSideSheetDrawer
      primaryDrawer={{
        drawerOpen: props.isOpen,
        onClose: () => props.onClose(false),
        content: getPrimaryPaymentDrawer(),
        header: { title: drawerTitle },
        footer: {
          primaryBtnTitle: "Save",
          secondaryBtnTitle: "Cancel",
          primaryBtnAction: onSubmitData,
          secondaryBtnAction: () => props.onClose(false),
          disablePrimaryBtn: !superAdminForm.dirty || !superAdminForm.isValid,
        },
        classes: {
          drawerContainer: classes.drawerContainer,
          contentArea: classes.drawerContentArea,
        },
      }}
    />
  );
};

export default AddSuperAdmin;
