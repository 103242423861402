import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CeroTable from '../../../components/CeroTable';
import useStyles from "./styles";
import { useDispatch, useSelector } from 'react-redux';
import { companyUsersList, getUsersList } from "../../../redux/actions";
import { STATUS } from '../../../redux/constants';
import { Box, CircularProgress, Typography } from '@mui/material';

 

const UsersTable = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {  onLoadMore } = props;

    const TableColumns = [
        {
            columnKey: 'user_type',
            columnId: 'user_type',
            columnHeader: 'User Type',
        },
        {
            columnKey: 'number',
            columnId: 'number',
            columnHeader: props.isActive ? 'Total Number of Active Users' : 'Total Number of Users',
        }
    ];
    
    const companyUsers = useSelector(state => state.listings.companyUsers.data);
    const usersList = useSelector(state => state.listings.usersList.data);
    const usersListStatus = useSelector(state => state.listings.usersList.status);
    const companyUsersStatus = useSelector(state => state.listings.companyUsers.status);

    console.log('usersListStatus', usersListStatus)

    useEffect(() => {
        if(props.companyId){
        dispatch(companyUsersList(props.companyId, props.isActive))}
        else {
            dispatch(getUsersList(props.isActive))
        }
    }, [dispatch])

    const onSelectCompanyData = (company) => {
        if(props.companyId){
        navigate(`/companies/${props.companyId}/${props.tab}/${company.user_type}/user-details/${props.isActive}`);
    }
        else{
            navigate(`/superadmin-users/${props.tab}/${company.user_type}/user-details/${props.isActive}`);
        }
    };

    const Users = () => {
       if(props.companyId){
        return Object.entries(companyUsers).map(([user_type, number]) => ({
            user_type,
            number,
        }));
       } else{
        return Object.entries(usersList).map(([user_type, number]) => ({
            user_type,
            number,
        }));
       }
    };

    return (
        <>
        {companyUsersStatus === STATUS.SUCCESS || usersListStatus === STATUS.SUCCESS  ?<CeroTable
            columns={TableColumns}
            data={Users()}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectCompanyData}
            classes={{ tableContainer: classes.tableContainer }}
        />:<Box className={classes.loader}>
        <Typography variant="h7" component="span">
          {companyUsersStatus  === STATUS.RUNNING || usersListStatus  === STATUS.RUNNING
            ? <CircularProgress/>
            : companyUsersStatus === STATUS.ERROR
            ? "We couldn't process your request. Please try again later"
            : ""}
        </Typography>
      </Box>}
        </>
    );
};

export default UsersTable
