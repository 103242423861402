import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.white,
        height: '100%',
        width: '90%',
        borderRadius: 14,
        padding: theme.spacing(5, 7),
        position: 'relative',
    },
    innerContainer: {
        // height: 'calc(100% - 80px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.background.darkGray
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.background.darkGray
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonContainer: {
        marginTop: theme.spacing(4),
    },
    button: {
        width: 'fit-content',
        height: 42
    },
    buttonPrimary: {
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    },
    buttonSecondary: {
        color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Secondary.hoverButton,
    },
        marginRight: theme.spacing(4),
    },
    deleteButton: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(4),
        backgroundColor: theme.palette.Primary.main,
        '&:hover': {
            backgroundColor: theme.palette.Primary.dark,
        }
    },
    topContainer: {
        width: '100%',
        margin: theme.spacing(3, 0)
    },
    bottomContainer: {
        width: '75%',
        marginTop: theme.spacing(5),
    },
    previewTitle: {
        margin: theme.spacing(2, 0)
    },
    previewItem: {
        padding: theme.spacing(1, 0)
    },
    tabContainer: {
        marginTop: theme.spacing(4),
    },
    tabs:{
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.login.loginButton,
          },
         "& .MuiTab-root.Mui-selected":{
            color: `${theme.palette.login.loginButton} !important`
         }
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: 35,
        paddingTop: 10,
        alignItems: 'center',
        cursor: "pointer"
    },
    infoContainer: {
        display:"flex !important",
        alignItems: 'start', 
        gap: '46px',
        padding: theme.spacing(1, 0),
    },
    supplierScreenTitle:{
        width:"300px",
        marginRight: theme.spacing(5),
        fontWeight: 500,
        whiteSpace: "break-spaces",
        fontSize: 14
    },
    value:{
        fontWeight: 500,
        fontSize: 14
    },
    list:{
        listStyleType: "none",
    },
    approved: {
        color: theme.palette.status.approved,
        fontWeight: 600,
        fontSize: 16,
      },
      assigned: {
        color: theme.palette.status.pendingApproval,
        fontWeight: 600,
        fontSize: 16,
      },
      audited: {
        color: theme.palette.status.audited,
        fontWeight: 600,
        fontSize: 16,
      },
      submitted: {
        color: theme.palette.status.pendingApproval,
        fontWeight: 600,
        fontSize: 16,
      },
      added: {
        color: theme.palette.status.added,
        fontWeight: 600,
        fontSize: 16,
      },
      review: {
        color: theme.palette.status.review,
        fontWeight: 600,
        fontSize: 16,
      },
      verified: {
        color: theme.palette.status.verified,
        fontWeight: 600,
        fontSize: 16,
      },
      Inprogress: {
        color: theme.palette.status.pendingApproval,
        fontWeight: 600,
        fontSize: 16,
      },
    statusContainer:{
        display:"flex !important",
        alignItems: 'start', 
        gap: '46px',
        paddingBottom: theme.spacing(5),
      },
      statusTitle:{
        width: "10px !important",
        fontSize: 16,
      },
      actionBox:{
        position: "absolute",
        right: "15%",
        top: "15%"
      },
}));

export default useStyles;