import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroButton from "../../components/CeroButton";
import { useNavigate } from "react-router-dom";

const TradeDashboardInternal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate()

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer}}>
      <Container className={classes.container}>
        <Paper className={classes.card}>
        <Typography style={{fontWeight: 500}}>Internal user dashboard</Typography>
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default TradeDashboardInternal;
