import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    margin: theme.spacing(3, 0, 5, 0),
    borderRadius: 8,
  },
  infoContainer: {
    // width : '30%',
    display : 'flex',
    justifyContent : 'start',
    columnGap: '15px',
    marginLeft: '10px'
  //   "& p:first-child" : {
  //     paddingRight : '25px'
  //   },
  //   "& p:last-child" : {
  //     overflow : 'hidden',
  //     whiteSpace : 'nowrap',
  //     textOverflow : 'ellipsis'
  //   }
  }
}));

export default useStyles;
