import { Box, Container, Grid, Typography } from "@mui/material";
import CeroButton from "../../../components/CeroButton";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroInput from "../../../components/CeroInput";
import DashboardLayout from "../../../layouts/DashboardLayout";
import CeroSelect from "../../../components/CeroSelect";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroChipSelect from "../../../components/CeroChipSelect";
import AddIcon from "@mui/icons-material/Add";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { addUser, getOrganizationCountry, resetUserStatus } from "../../../redux/actions";
import { validationSchema } from "./schema";
import { STATUS } from "../../../redux/constants";
import { useSnackbar } from "notistack";

const AddUser = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [roleBoxes, setRoleBoxes] = useState([{ visible: false }]);
  const [countryFacBox, setCountryFacBox] = useState([{ visible: true }]);

  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const countries = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_code_corresponding_name
  );
  const addUserStatus = useSelector((state) => state.users.addUser);

  const countryOptions = countries && Object.entries(countries).map(([countryCode, countryName]) => ({
    id: countryCode,
    label: countryName,
  }));
  
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      roles: [
        {
          role: "",
          countries: [{ country_code: "", facilities: [] }],
        },
      ],
      roleType: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true, 
    validateOnBlur: true,
    onSubmit: () => {},
  });
  
  const onAddData = () => {
    const formattedRoles = formik.values.roles.reduce((acc, role) => {
      if (role.role.trim() === "") {
        return acc;
      } 
      
      const roleName = role.role.replace(/\s+/g, "_").toLowerCase();
      if (!acc[roleName]) {
        acc[roleName] = [];
      }
      role.countries.forEach((country) => {
        const facilitiesIds = country.facilities.map(facilityName => facilitiesObject(country.country_code)[facilityName]);

        acc[roleName].push({
          country_code: country.country_code,
          facilities: facilitiesIds,
        });
      });
      return acc;
    }, {});

    const requestData = {
      email: formik.values.email,
      name: formik.values.name,
      role: formattedRoles,
      stakeholder_type: formattedRoles.hasOwnProperty("read_only_user")
        ? formik.values.roleType
        : "",
    };
    dispatch(addUser(requestData))
  };

  useEffect(() => {
    if (addUserStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("User added successfully", { variant: "success", autoHideDuration: 3000 });
      dispatch(resetUserStatus());
      navigate('/users')
    } else if (addUserStatus.status === STATUS.ERROR) {
      enqueueSnackbar(addUserStatus.message.message, { variant: "error", autoHideDuration: 3000 });
      dispatch(resetUserStatus());
    }
  }, [addUserStatus, enqueueSnackbar, dispatch ]);

  const resetData = () => {
    formik.resetForm()
    setRoleBoxes([{ visible: false }]);
    setCountryFacBox([{ visible: true }]);
  }

  const handleAddRole = (index) => {
    const newRoleBoxes = [...roleBoxes];
    newRoleBoxes[index].visible = true;
    newRoleBoxes.push({ visible: false });
    setRoleBoxes(newRoleBoxes);
    const newCountryFacBoxes = [...countryFacBox];
    newCountryFacBoxes.push({ visible: true });
    setCountryFacBox(newCountryFacBoxes);
    if (formik.values.roles.length === 0 || formik.values.roles[formik.values.roles.length - 1].role !== "") {
      const newRoles = [
        ...formik.values.roles,
        {
          role: "",
          countries: [{ country_code: "", facilities: [] }],
        },
      ];
      formik.setFieldValue("roles", newRoles);
    }
    if (formik.values.roles[index]?.role === "read_only_user") {
      for (let i = index + 1; i < newRoleBoxes.length; i++) {
        newRoleBoxes[i].visible = false;
      }
      setRoleBoxes(newRoleBoxes);
    }
  };


  const addCountryFacility = (index) => {
    const updatedCountryFacBoxes = [...countryFacBox];
    updatedCountryFacBoxes[index] = { visible: false };
    updatedCountryFacBoxes.push({ visible: true });
    setCountryFacBox(updatedCountryFacBoxes);
    formik.setFieldValue(`roles[${index}].countries`, [
      ...formik.values.roles[index].countries,
      { country_code: "", facilities: [] },
    ]);
  };

  useEffect(() => {
    dispatch(getOrganizationCountry());
  }, [dispatch]);

  const roles = [
    { key: "admin", value: "Business Admin" },
    { key: "approver", value: "Business Approver" },
    { key: "facility_manager", value: "Business Facility Manager" },
    { key: "sustainability_manager", value: "Business Sustainability Manager" },
    { key: "business_user", value: "Business User" },
    { key: "read_only_user", value: "Stakeholder" },
  ];

  const roleTypeOptions = [
    { key: "internal", value: "Internal" },
    { key: "external", value: "External" },
  ];

  const handleCountryChange = (e, value, index, countryIndex) => {
    const countryCode = value ? value.id : undefined;
    formik.setFieldValue(
      `roles[${index}].countries[${countryIndex}].country_code`,
      countryCode
    );
    formik.setFieldValue(
      `roles[${index}].countries[${countryIndex}].facilities`,
      []
    );
  };

  const facilityHandleChange = (event, index, countryIndex) => {
    const value = event.target.value;
    let avlData = [];
    if (value[value.length - 1] === "all") {
      avlData =
        formik.values.roles[index].countries[countryIndex].facilities.length ===
        Object.keys(facilitiesObject).length
          ? []
          : Object.keys(facilitiesObject);
    } else {
      avlData = value;
    }
    formik.setFieldValue(
      `roles[${index}].countries[${countryIndex}].facilities`,
      avlData
    );
  };

  const facilitiesOptions = (countryCode) =>
    countryCode
      ? Object.entries(countryFacilityData[countryCode]).map(([label, id]) => ({
          id,
          label,
        }))
      : [];

  const facilitiesObject = (countryCode) =>
    facilitiesOptions(countryCode).reduce((acc, { id, label }) => {
      acc[label] = id;
      return acc;
    }, {});

    const filterRoles = (currentRoleIndex) => {
      const selectedRoles = formik.values.roles.map((r, index) => {
        if (index !== currentRoleIndex) {
          return r.role;
        }
        return null;
      });

      const filteredRoles = roles.filter((role) => !selectedRoles.includes(role.key))
      if (currentRoleIndex === 0){
        return roles.filter((role) => !selectedRoles.includes(role.key));
      }else{
      return roles.filter((role) => role.key !== "read_only_user" && !selectedRoles.includes(role.key));
      }
    };
    
  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add User
          </Typography>
          <Box>
            <div style={{ display:"flex", width: "85%"}}>
                <CeroInput
                  required
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                  classes={{ container: classes.textAreaContainer }}
                />
                <CeroInput
                  required
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                  classes={{ container: classes.textAreaContainer }}
                />
            </div>

            {roleBoxes.map((roleBox, index) => (
              <Box key={index}>
                {roleBox.visible && (
                  <Box className={classes.roleBox}>
                    <Grid container spacing={2} display="flex">
                      <Grid item xs={6}>
                        <CeroSelect
                          required
                          id={`role-${index}`}
                          name={`roles[${index}].role`}
                          label="Role"
                          fullWidth
                          options={filterRoles(index)} 
                      value={formik.values.roles[index]?.role || ""}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue(
                          `roles[${index}].countries`,
                          [{ country_code: "", facilities: [] }]
                        );
                      }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.roles &&
                            formik.errors.roles &&
                            formik.touched.roles[index]?.role &&
                            formik.errors.roles[index]?.role
                          }
                          classes={{ root: classes.select }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {formik.values.roles[index]?.role ===
                          "read_only_user" && (
                          <CeroSelect
                            required
                            id={`roleType`}
                            name={`roleType`}
                            label="Role Type"
                            fullWidth
                            options={roleTypeOptions}
                            selectedValue={formik.values.roleType || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.roleType && formik.errors.roleType
                            }
                            classes={{ root: classes.roleType}}
                          />
                        )}
                      </Grid>
                    </Grid>

                    {formik.values.roles[index].role !== "admin" && 
                    formik.values.roles[index].countries.map(
                      (country, countryIndex) => (
                        <Box key={countryIndex}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <CeroAutoComplete
                                id={`country-${countryIndex}`}
                                name={`roles[${index}].countries[${countryIndex}].country_code`}
                                label="Country"
                                fullWidth
                                onBlur={formik.handleBlur}
                                options={countryOptions.filter(
                                  (option) =>
                                    !formik.values.roles[index].countries.some(
                                      (c) => c.country_code === option.id
                                    )
                                )}
                                onChange={(e, value) =>
                                  handleCountryChange(
                                    e,
                                    value,
                                    index,
                                    countryIndex
                                  )
                                }
                                selectedValue={
                                  formik.values.roles[index]?.countries[
                                    countryIndex
                                  ]?.country_code || ""
                                }
                                classes={{ root: classes.autoComplete }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CeroChipSelect
                                label="Facility"
                                formControlClass={classes.multiSelect}
                                optionsde={facilitiesObject(
                                  formik.values.roles[index]?.countries[
                                    countryIndex
                                  ]?.country_code
                                )}
                                selected={
                                  formik.values.roles[index]?.countries[
                                    countryIndex
                                  ]?.facilities || []
                                }
                                handleChange={(value) =>
                                  facilityHandleChange(
                                    value,
                                    index,
                                    countryIndex
                                  )
                                }
                                classes={{
                                  justCheckSelected: classes.justCheckSelected,
                                  label: classes.label,
                                }}
                                shrink={false}
                                disabled={
                                  !formik.values.roles[index]?.countries[
                                    countryIndex
                                  ]?.country_code
                                }
                                showSelectAll={false}
                                sortOption={true}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    )}

                    <div className={classes.addCountryFacility}>
                      {formik.values.roles[index].role !== "admin" && countryFacBox[index]?.visible && (
                        <div
                          className={classes.addRole}
                          onClick={() => addCountryFacility(index)}
                        >
                          <AddIcon />
                          <Typography>Add Country and Facilities</Typography>
                        </div>
                      )}
                    </div>

                    {formik.values.roles[index]?.countries.length > 1 && countryOptions.filter(
                      (option) =>!formik.values.roles[index].countries.some((c) => c.country_code === option.id)).length !== 0 && (
                      <div
                        className={classes.addRole}
                        onClick={() => addCountryFacility(index)}
                      >
                        <AddIcon />
                        <Typography>Add Country and Facilities</Typography>
                      </div>
                    )}
                  </Box>
                )}

                {!roleBox.visible &&
                  !formik.values.roles.some(
                    (role) => role.role === "read_only_user"
                  ) && filterRoles(roles).length !== 0 &&(
                    <div
                      className={classes.addRole}
                      onClick={() => handleAddRole(index)}
                    >
                      <AddIcon />
                      <Typography>Add Role</Typography>
                    </div>
                  )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Save"
            className={classes.buttonPrimary}
            onClick={() => onAddData()}
            disabled={!formik.values.roles.some(
              (role) => role.role === "admin"
            ) ? !formik.dirty || !formik.isValid : ""}
          />
          <CeroButton
            buttonText="Cancel"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={resetData}
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default AddUser;
