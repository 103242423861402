import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: "100%",
    padding: 0,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 'fit-content',
    height: 37,
    fontSize: 13,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Primary.contrastText,
      border:"2px solid"
    },
    width: 'fit-content',
    height: 37,
    fontSize: 13,
    margin: theme.spacing(0, 2),
  },
  requestApproval: {
    width: 163,
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 10,
    alignItems: 'center',
    cursor: "pointer"
  },
  gridContainer: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center', 
    marginBottom: 15
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end', 
    gap: theme.spacing(2), 
  },
}));

export default useStyles;
