import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 20,
    alignItems: "center",
    cursor: "pointer",
  },
  tabsCard: {
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    "& .MuiTab-root": {
      color: theme.palette.text.dark,
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: 16,
      whiteSpace: "nowrap !important",
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.Primary.background,
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: 16,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.Primary.background,
      height: 3,
    },
  },
  companyName: {
    padding: theme.spacing(4),
    fontWeight: 700,
  },
  countCard: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  countContainer: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.Other.titleText,
  },
  count: {
    fontWeight: 600,
    paddingLeft: theme.spacing(3),
  },
  name: {
    fontWeight: 600,
    paddingTop: theme.spacing(2),
  },
  detailsCard: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  licenseCard: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(4),
  },
  companyCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(4),
  },
  actionBox: {
    position: "absolute",
    right: "10%",
    top: "20%",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.Primary.background,
    marginRight: 15,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  popUp: {
    margin: "auto",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 15,
    width: "40%",
    margin: "auto",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
  },
  infoContainer: {
    display: "flex !important",
    alignItems: "start",
    gap: "46px",
    padding: theme.spacing(1, 0),
  },
  title: {
    width: "120px",
    marginRight: theme.spacing(5),
    fontWeight: 400,
  },
  value: {
    fontWeight: 400,
    whiteSpace: "break-spaces",
  },
  MultiSelectformControl: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: 46,
    "& .MuiFormLabel-root":{
      left:theme.spacing(2),
      top:theme.spacing(1)
    },
    background:'white',
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: '100%',
      paddingLeft:55
  },
    "& .Mui-focused > fieldset > legend": {
        maxWidth: '100%',
        paddingLeft:55
    },
  },
  filterContainer:{
    marginTop: 20
  },
  yearFilter:{
    
    "& .MuiBox-root": {
      height: '42px !important',
      margin: theme.spacing(0.6, 0, 0, 0),
    },
    "& .MuiBox-root > .MuiAutocomplete-root ":{
      background:'white',
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root":{
      height:46
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > input[type=text]":{
      paddingBlock:0,
      paddingInline:0
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":{
      color:theme.palette.Primary.main,
      marginTop: '0 !important'
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":{
      color:theme.palette.Primary.main,
      marginTop: '0 !important'
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-outlined":{
      marginTop:-3
    }
  },
}));

export default useStyles;