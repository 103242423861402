import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
container:{
    diplay:"flex",
    padding:theme.spacing(10,0,10,0),
    justifyContent:"center",
    maxWidth:400,
    margin:theme.spacing(20,130)

},
wrapper:{

},
logo: {
    display: 'flex',
    justifyContent: 'center'
},
logoText:{
    display: 'flex',
    justifyContent: 'center',
    marginTop:4,
    fontWeight:800,
    fontSize:40,
    color:theme.palette.Primary.dark,
},
message:{
    display: 'flex',
    flexDirection:"column",
    alignItems: 'center',
    color:theme.palette.Primary.main,
    marginTop:60,
    lineHeight:0.8
},
messageText:{
    fontSize:30 ,
    fontWeight:500,
},
button:{
    display:"flex",
    justifycontent: 'center',
    background:theme.palette.Primary.background,
    marginTop:80,
    borderRadius:15,
    marginLeft:80,
    "&:hover": {
        backgroundColor:theme.palette.Primary.dark,
        color:theme.palette.Primary.contrastText
      },
}
}));

export default useStyles;