import {
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import ActiveUserIcon from "../../assets/icons/ActiveUserIcon.png";
import TotalUsersIcon from "../../assets/icons/TotalUsersIcon.png";
import superAdminIcon from "../../assets/icons/superAdminIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatistics } from "../../redux/actions";
import UsersTable from "../SuperAdminCompanies/UsersTable";
import SuperAdminTable from "./SuperAdminTable";
import CeroButton from "../../components/CeroButton";
import AddSuperAdmin from "../SuperAdminUsers/AddSuperAdmin";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SuperAdminUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState("statistics");
  const [isOpenAddSuperAdmin, setOpenAddSuperadmin] = useState(false);
  const [editSuperadmin, setEditSuperadmin] = useState(null);
  const selectedTabFromUrl = location.pathname.split("/superadmin-users/")[1];

  const userStatistics = useSelector(
    (state) => state.listings.userStatistics.data
  );

  useEffect(() => {
    if (selectedTabFromUrl) {
      setSelectedTab(selectedTabFromUrl);
    }
  }, [selectedTabFromUrl]);

  useEffect(() => {
    dispatch(getUserStatistics());
  }, [dispatch]);

  const handleDrawerClose = () => {
    setOpenAddSuperadmin(false);
    setEditSuperadmin(null);
  };

  const handleOpenAddSuperAdmin = () => {
    setOpenAddSuperadmin(true);
    setEditSuperadmin(null);
  };
 
  const handleChange = (event, newValue) => {
     setSelectedTab(newValue);
    navigate(`/superadmin-users/${newValue}`);
  
  };

  const handleEditSuperAdmin = (item) => {
    setEditSuperadmin(item);
    setOpenAddSuperadmin(true);
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Card className={classes.tabsCard}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="User Statistics" value="statistics"/>
            <Tab label="Total Users" value="total-users" />
            <Tab label="Active Users" value="active-users" />
            <Tab label="Super Admin" value="superadmin" />
            {selectedTab === "superadmin" ? (
              <CeroButton
                buttonText="Add Super Admin"
                className={classes.buttonPrimary}
                onClick={handleOpenAddSuperAdmin}
              />
            ) : (
              <Tab label="" />
            )}
          </Tabs>
        </Card>
        {selectedTab === 'statistics' && (
          <>
            <Card className={classes.countCard}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.gridContainer}
                    >
                      <div className={classes.countContainer}>
                        <img
                          src={TotalUsersIcon}
                          alt="Total Users"
                          width="40"
                        />
                        <Typography variant="h4" className={classes.count}>
                          {userStatistics.total_user_count}
                        </Typography>
                      </div>
                      <div className={classes.name}>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          Total Users
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.gridContainer}
                    >
                      <div className={classes.countContainer}>
                        <img
                          src={ActiveUserIcon}
                          alt="Active Users"
                          height="40"
                          width="35"
                        />
                        <Typography variant="h4" className={classes.count}>
                          {userStatistics.active_user_count}
                        </Typography>
                      </div>
                      <div className={classes.name}>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          Active Users
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.gridContainer}
                    >
                      <div className={classes.countContainer}>
                        <img
                          src={superAdminIcon}
                          alt="Super Admin"
                          height="40"
                          width="40"
                        />
                        <Typography variant="h4" className={classes.count}>
                          {userStatistics.super_admin_count}
                        </Typography>
                      </div>
                      <div className={classes.name}>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          Super Admin
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        )}
        {selectedTab === "total-users" && <UsersTable isActive={false} tab={selectedTab} />}
        {selectedTab === "active-users" && <UsersTable isActive={true} tab={selectedTab} />}
        {(selectedTab === "superadmin" || selectedTab === "add-superadmin") && (
          <SuperAdminTable onClickEdit={(item) => handleEditSuperAdmin(item)} />
        )}
        {(isOpenAddSuperAdmin || editSuperadmin) && (
          <AddSuperAdmin
            isOpen={isOpenAddSuperAdmin || editSuperadmin}
            onClose={handleDrawerClose}
            editItem={editSuperadmin}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default SuperAdminUsers;
