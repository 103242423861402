import { Typography, Box, CircularProgress } from "@mui/material";
import clsx from "clsx";
import CeroEdLogo from "../../assets/ceroedLogo.png";

import useStyles from "./styles";

const CeroLoader = (props) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.container, props.classes?.container)} >
            <Box className={clsx(classes.loaderContainer, props.classes?.loaderContainer)}>
            <img src={CeroEdLogo} alt="Logo" className={classes.rotatingLogo} />
                { props.loadingText && <Typography className={classes.loadingText}>{props.loadingText}</Typography> }
            </Box>
        </Box>
    );
};

export default CeroLoader;
