import React, { useEffect, useState } from 'react';

import CeroButton from '../../../components/CeroButton';
import CeroTable from '../../../components/CeroTable';
import { userRoles } from '../../../constants';
import UserDeletePrompt from '../UserDeletePrompt';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useStyles from "./styles";
import { Box, Button, CircularProgress, Link, Stack, Switch, TextField, Typography } from '@mui/material';
import { listUsers, proxyLoginAccessSuperadmin, resetProxyLoginAccessSuperadmin, getUserLogs, lockUnlockUser, getRecentLogs, resetListUsers } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../../redux/constants';
import { useSnackbar } from 'notistack';
import { getCookie } from '../../../services/cookie';
import CeroPopUp from '../../../components/CeroPopUp';
import CloseIcon from "@mui/icons-material/Close";
import UserLogTable from './UserLogTable';
import { useNavigate } from 'react-router-dom';

const UserTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [deleteUser, setDeleteUser] = useState();
  const [userId, setUserId] = useState();
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [lockPopup, setLockPopup] = useState(false);
  const [comment, setComment] = useState("");
  const [commentPopup, setCommentPopup] = useState(false);
  const [recentLog, setRecentLog] = useState(false);
  const [logAction, setLogAction] = useState();
  const proxyLoginStatus = useSelector((state) => state.users.proxyLoginAccessSuperadmin);
  const logData = useSelector((state) => state.users.getUserLogs.data);
  const recentLogs = useSelector((state) => state.users.getRecentLogs.data);

  const tableColumns = [
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
      
    },
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
    },
    {
      columnKey: "role",
      columnId: "role",
      columnHeader: "Role",
    },
    {
      columnKey: "logged_in",
      columnId: "logged_in",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
      align: "right",
    },
  ];

  const handleDelete = (user) => {
    setOpenDeletePrompt(true);
    setDeleteUser(user);
  };

  const handleToggleStatus = (id, status) => {
    const updatedUserList = props.userList?.map((item) =>
      item.id === id ? { ...item, status: status } : item
    );
    const requestData = {
      id: id,
      status: status,
    };
    // setAuditSummary(updatedAuditSummary);
    dispatch(proxyLoginAccessSuperadmin(requestData));
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

 useEffect(() => {
  if(userId){
  dispatch(getUserLogs(userId))
  dispatch(getRecentLogs(userId))}
}, [userId, dispatch]);

  const userLogs = () => {
    return(
    <>
    <CloseIcon onClick={handleClosePopup} className={classes.closeIcon}/>
    <UserLogTable logData={logData}/>
    </>)
  }

  const handleView = (id) => {
    setUserId(id)
    setLogAction(null)
    setRecentLog(false)
  }

  const userLock = () => {
    const hasRecentLogs = Object.keys(recentLogs).length !== 0;
    return(
      <>
      {hasRecentLogs ? (
        <>
      <CloseIcon onClick={()=> setLockPopup(false)} className={classes.closeIcon}/>
      {logAction === "Active" ? <Typography style={{ textAlign: "center", paddingTop: 10}}>Do you want to lock the user?</Typography> :
      (Object.keys(recentLogs).length!==0 ? (recentLogs.is_locked_by_admin === true ?
        (!recentLog ? 
          <>
        <Typography style={{ paddingTop: 20, whiteSpace: "nowrap"}}>The user has been locked by the admin. Do you want to unlock the user?</Typography>
          <Typography style={{ color: "grey"}}>To know why the user has been Locked, <Link onClick={()=>setRecentLog(true)}>Click here</Link>
        </Typography></>:
        <>
        <Typography style={{ paddingTop: 20}}>The user has been locked by admin. Do you want to unlock the user?</Typography>
        <UserLogTable logData={recentLogs?.logs}/>
        </>) :
      (<Typography style={{ paddingTop: 20}}>The user has been locked due to multiple unsuccessful password attempts. 
        Do you want to unlock the user?</Typography>)) : <Typography style={{ paddingTop: 20}}></Typography>)}
      <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {setLockPopup(false);setCommentPopup(true)}}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setLockPopup(false)}
            buttonText="No"
          />
        </div>
        </>
      ) : <CircularProgress />}
    </>
  );
};

  const handleSaveComment = () => {
    const status = (logAction === "Active" ? "lock" : "unlock");
    let data= {}
    if(recentLog){
     data = {
      is_locked_by_admin:true,
      comment:comment,
    }}else{
       data = {
        is_locked_by_admin:false,
        comment:comment,
      }
    }
    dispatch(lockUnlockUser(userId,status,data))
    setComment("");
    setCommentPopup(false)
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const getComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleSaveComment}
            disabled={comment.trim().length === 0}
          >
            Save
          </Button>
          <Button
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={()=> setCommentPopup(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const getUserStatus = (status) => {
    if(status === "Active"){
      return <Typography className={classes.activeStatus}>Active</Typography>
    }else if(status === "Locked"){
      return <Typography className={classes.lockedStatus}>Locked</Typography>
    }else if(status === "Non active"){
      return <Typography className={classes.nonActiveStatus}>Inactive</Typography>
    }else {
      return <Typography className={classes.invitedStatus}>Invited</Typography>
    }
  }
  
  const getUserData = () =>
    props.userList.map((user) => ({
      ...user,
      role: user.role
        .map((key) => {
          const role = userRoles.find((role) => role.key === key);
          return role ? role.value : null;
        })
        .join(", "),
      lockAction: ( !readOnlyMode &&
        <CeroButton 
        buttonText={user.logged_in === "Active" ? "Lock" : "Unlock"}
        className={classes.buttonPrimary}
        onClick={()=>{setLockPopup(true);setLogAction(user.logged_in);setUserId(user.id)}}
        />
      ),
      logs: ( !readOnlyMode &&
        <Link onClick={(e)=>{handleView(user.id);setIsPopupOpen(true)}} style={{ cursor: "pointer"}}>View</Link>
      ),
      logged_in: getUserStatus(user.logged_in),
      action: ( !readOnlyMode &&
        <>
          <div className={classes.actions}>
            <div
              className={classes.linkButton}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/users/edit-users/${user.id}${user.logged_in && `?status=${user.logged_in}`}`)
              }}
            >
              <EditOutlinedIcon className={classes.icon} />
            </div>
          </div>
        </>
      ),
      proxy: user.role.includes("sustainability_manager") && user.logged_in === "Active" && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !user.enable_proxy_login ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Deny
          </Typography>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleToggleStatus(user.id, !user.enable_proxy_login);
            }}
          >
            <Switch
              checked={user.enable_proxy_login}
              className={classes.switchColor}
              inputProps={{ "aria-label": "Toggle Status" }}
              onChange={(e) => handleToggleStatus(user.id, e.target.checked)}
            />
          </Box>
          <Typography
            style={{ fontWeight: user.enable_proxy_login ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Allow
          </Typography>
        </Stack>
      ),
    }));

  useEffect(() => {
    if (proxyLoginStatus.status === STATUS.SUCCESS) {
      dispatch(listUsers());
      enqueueSnackbar(proxyLoginStatus.data.message, {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetProxyLoginAccessSuperadmin());
    } else if (proxyLoginStatus.status === STATUS.ERROR) {
      dispatch(listUsers());
      enqueueSnackbar(proxyLoginStatus.message.message, {
        variant: "error",
        autoHideDuration: 1000,
      });
      dispatch(resetProxyLoginAccessSuperadmin());
    }
  }, [proxyLoginStatus, enqueueSnackbar, dispatch]);

  return (
    <>
      <CeroTable
        columns={tableColumns}
        data={getUserData()}
        hasMore={false}
        loading={false}
        loadMore={props.onLoadMore}
        classes={{ tableContainer: classes.tableContainer }}
      />
      <UserDeletePrompt
        userId={deleteUser}
        isOpen={openDeletePrompt}
        setOpenDeletePrompt={setOpenDeletePrompt}
      />
      <CeroPopUp
        primaryPopUp={{
          open: isPopupOpen,
          onClose: () => setIsPopupOpen(false),
          content: userLogs(),
          header: { title: "Logs" },
        }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: lockPopup,
          onClose: () => setLockPopup(false),
          content: userLock(),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog,
        }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: commentPopup,
          onClose: () => setCommentPopup(false),
          content: getComments(),
          header: { title: "Comments*" },
        }}
        classes={{ dialog: classes.dialog,
        }}
      />
    </>
  );
}

export default UserTable;
