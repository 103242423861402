import { Typography, Box, Container, Grid } from '@mui/material';
import React from 'react'
import CeroInfoPair from '../../../components/CeroInfoPair';
import useStyles from '../styles';
import CeroItemPair from '../../../components/CeroItemPair';

const LocalCommunitiesApproval = (props) => {
    const classes = useStyles();
    const { emissionData } = props;

    return (
        <Container className={classes.mainContainer}>
        {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Name of the Local Community:"
              value={item.local_community ? item.local_community : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Total Contributions:"
              value={item.amount ? item.amount : "NA"} 
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Projects Undertaken:"
              value={item.project_undertaken ? item.project_undertaken : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Objective of the Project:"
              value={item.objective ? item.objective : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Activities Executed:"
              value={item.activities_executed ? item.activities_executed : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Results of the Projects:"
              value={item.result_achieved ? item.result_achieved : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            </Container>
        ))}
        </Container>
  )
}

export default LocalCommunitiesApproval