import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addAntiCorruptionDisclosure,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import CeroEpochDatePicker from "../../../components/CeroDateTimePicker/CeroEpochDatePicker";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";

const AddAntiCorruptionDisclosureForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const addEmissionData = useSelector(
    (state) => state.emission.addCorruptionDisclosure
  );

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      isOrganizationCorruption: "",
      numberOfOperation: 0,
      incidentNumber: 0,
      riskRelated: "",
      corruptionDescription: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'anti_corruption_disclosure'));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Anti-corruption Disclosure added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddAntiCorruptionDisclosure = () => {
    const requestData = {
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      is_organisation_accessed_for_corruption:
        formik.values.isOrganizationCorruption,
      number_of_operation: formik.values.numberOfOperation,
      number_of_incident: formik.values.incidentNumber,
      risk_related_corruption: formik.values.riskRelated,
      description_of_corruption: formik.values.corruptionDescription,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addAntiCorruptionDisclosure(requestData));
  };

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Anti-corruption Disclosure
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroQuestionWithCheckbox
             required
              question="Indicate if your organization has assessed its operations for risks related to corruption."
              formik={formik}
              fieldName="isOrganizationCorruption"
              onChange={handleAnswerChange}
              checkedField={formik.values.isOrganizationCorruption}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.isOrganizationCorruption === "Yes" && 
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  id="numberOfOperation"
                  name="numberOfOperation"
                  label="Total Number of Operations Assessed for Risks Related to Corruption"
                  fullWidth
                  value={formik.values.numberOfOperation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incidentNumber &&
                    formik.errors.incidentNumber
                  }
                />
              </Grid>
              <Grid item container direction="column" md={4} xs={6}>
                <CeroInput
                  id="incidentNumber"
                  name="incidentNumber"
                  label="Number of Incidents reported"
                  fullWidth
                  value={formik.values.incidentNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incidentNumber &&
                    formik.errors.incidentNumber
                  }
                />
              </Grid>
            </Grid>}
            <CeroInput
              required
              id="riskRelated"
              name="riskRelated"
              label="Describe any significant risks related to corruption identified during the assessment."
              fullWidth
              multiline
              rows="2"
              value={formik.values.riskRelated}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.riskRelated && formik.errors.riskRelated}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="corruptionDescription"
              name="corruptionDescription"
              label="Describe any confirmed incidents of corruption within your organization and actions taken to address them."
              fullWidth
              multiline
              rows="2"
              value={formik.values.corruptionDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.corruptionDescription &&
                formik.errors.corruptionDescription
              }
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid || addEmissionData.status === STATUS.RUNNING}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddAntiCorruptionDisclosure}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddAntiCorruptionDisclosureForm;
