import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        width: "70%",
        margin: "auto"
    },
    tableRow:{
        boxShadow: "inset 0px -1px 0px #5A404E",
    },

}));

export default useStyles;