import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";
import CeroTable from "../../../components/CeroTable";
import { useDispatch, useSelector } from "react-redux";
import { getCarbonCreditsAdded } from "../../../redux/actions";
import { Link } from "@mui/material";
import SearchBox from "../../../components/SearchBox";

const CarbonCreditsTable = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const addCarbonCreditsData = useSelector(
    (state) => state.trade.getCarbonCreditsAdded.data
  );

  useEffect(() => {
    dispatch(getCarbonCreditsAdded());
  }, [dispatch]);

  useEffect(() => {
    setFilteredData(
      addCarbonCreditsData.filter((item) =>
        item.project_name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, addCarbonCreditsData]);

  const columns = [
    {
      columnKey: "project_name",
      columnId: "project_name",
      columnHeader: "Project Name",
    },
    {
      columnKey: "location",
      columnId: "location",
      columnHeader: "Location",
    },
    {
      columnKey: "certification",
      columnId: "certification",
      columnHeader: "Certification",
    },
    {
      columnKey: "project_type",
      columnId: "project_type",
      columnHeader: "Project Type",
    },
    {
      columnKey: "co2_reduction",
      columnId: "co2_reduction",
      columnHeader: "Co2 Reduction (tons)",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "More Information",
    },
  ];

  const onSelectData = (Id) => {
    navigate(`/trade/carboncredits-added/${Id}`);
  };

  const getAddedData = () =>
    filteredData.map((item) => ({
      ...item,
      action: <Link onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSelectData(item.id);
      }} style={{ cursor: "pointer"}}>Click Here</Link>,
    }));

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <SearchBox
        placeholder="Search Projects"
        onChange={({ target }) => setSearchText(target.value)}
        value={searchText}
        classes={{ container: classes.searchTextContainer }}
      />
      <CeroTable
        columns={columns}
        data={getAddedData()}
        hasMore={false}
        loading={false}
        loadMore={props.onLoadMore}
        classes={{
          tableContainer: classes.tableContainer,
          headerCell: classes.headerCell,
        }}
      />
    </DashboardLayout>
  );
};

export default CarbonCreditsTable;
