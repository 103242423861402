import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

export function* listUsers(action) {
  try {
    const response = yield call(request, APIEndpoints.LIST_USERS, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_USERS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_USERS_FAILURE,
      payload: err.message,
    });
  }
}

export function* addUser(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(request, APIEndpoints.ADD_USER, {
      method: "POST",
      payload: requestData,
    });
    yield put({
      type: ActionTypes.ADD_USER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_USER_FAILURE,
      payload: err,
    });
  }
}

export function* getUserDetails(action) {
  try {
    const { userId } = action.payload;
    const response = yield call(request, APIEndpoints.GET_USER(userId), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_USER_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_USER_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* editUser(action) {
  try {
    const { userId, requestData } = action.payload;
    const response = yield call(request, APIEndpoints.EDIT_USER(userId), {
      method: "PUT",
      payload: requestData,
    });
    yield put({
      type: ActionTypes.EDIT_USER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_USER_FAILURE,
      payload: err,
    });
  }
}
export function* editUserStakeholder(action) {
  try {
    const { userId, requestData } = action.payload;    
    const response = yield call(request, APIEndpoints.EDIT_USER_STAKEHOLDER(userId), {
      method: "PUT",
      payload: requestData,
    });
    yield put({
      type: ActionTypes.EDIT_USER_STAKEHOLDER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_USER_STAKEHOLDER_FAILURE,
      payload: err,
    });
  }
}

export function* deleteUser(action) {
  try {
    const { userId } = action.payload;
    const response = yield call(request, APIEndpoints.DELETE_USER(userId), {
      method: "DELETE",
    });
    yield put({
      type: ActionTypes.DELETE_USER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_USER_FAILURE,
      payload: err,
    });
  }
}

export function* confirmEmail(action) {
  try {
    const { userId, code, userType } = action.payload;
    const apiEndpoint = (userType === 'stakeholder') ? APIEndpoints.SET_STAKEHOLDER_EMAIL(userId, code) : APIEndpoints.SET_EMAIL_CONFIRMED(userId, code)
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.SET_EMAIL_CONFIRMED_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SET_EMAIL_CONFIRMED_FAILURE,
      payload: err?.detail,
    });
  }
}

export function* getAssignedStakeholders(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_ASSIGNED_STAKEHOLDERS, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_ASSIGNED_STAKEHOLDERS_SUCSESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ASSIGNED_STAKEHOLDERS_FAILURE,
      payload: err.message,
    });
  }
}

export function* updateStakeholderStatus(action) {
  try {
    const { requestData } = action.payload; 
    const response = yield call(request, APIEndpoints.ACTIVATE_DEACTIVATE_STAKEHOLDERS(requestData.id, requestData.status), {
      method: 'POST',
      payload: {},
    });
    yield put({
      type: ActionTypes.UPDATE_STAKEHOLDER_STATUS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_STAKEHOLDER_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* getReadOnlyUserAssignedReports(action) {
  try {
    const { id } = action.payload;
    const response = yield call(request, APIEndpoints.GET_READ_ONLY_USER_ASSIGNED_REPORTS(id), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_READ_ONLY_USER_ASSIGNED_REPORTS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_READ_ONLY_USER_ASSIGNED_REPORTS_FAILURE,
      payload: err,
    });
  }
}

export function* revokeGrantReportAccess(action) {
  try {
    const { requestData } = action.payload; 
    const response = yield call(request, APIEndpoints.REVOKE_GRANT_REPORT_ACCESS(requestData.stakeholder_id,requestData.report_id, requestData.status), {
      method: 'POST',
      payload: {},
    });
    yield put({
      type: ActionTypes.REVOKE_GRANT_REPORT_ACCESS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.REVOKE_GRANT_REPORT_ACCESS_FAILURE,
      payload: err,
    });
  }
}

export function* proxyLoginAccessSuperadmin(action) {
  try {
    console.log("action.payload", action.payload);
    const { id, status } = action.payload.userId;
    const response = yield call(
      request,
      APIEndpoints.PROXY_LOGIN_ACCESS_SUPERADMIN(id, status),
      {
        method: "POST",
        payload: {},
      }
    );
    console.log("response", response);
    yield put({
      type: ActionTypes.PROXY_LOGIN_ACCESS_SUPERADMIN_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.PROXY_LOGIN_ACCESS_SUPERADMIN_FAILURE,
      payload: err,
    });
  }
}

export function* getUserLogs(action) {
  try {
    const { userId } = action.payload;
    const response = yield call(request, APIEndpoints.GET_USER_LOGS(userId), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_USER_LOGS_SUCCESS,
      payload: response.logs,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_USER_LOGS_FAILURE,
      payload: err,
    });
  }
}

export function* lockUnlockUser(action) {
  try {
    const { userId, status, data } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.LOCK_UNLOCK_USER(userId, status),
      {
        method: "POST",
        payload: data,
      }
    );
    yield put({
      type: ActionTypes.LOCK_UNLOCK_USER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOCK_UNLOCK_USER_FAILURE,
      payload: err,
    });
  }
}

export function* getRecentLogs(action) {
  try {
    const { userId } = action.payload;
    const response = yield call(request, APIEndpoints.GET_RECENT_LOGS(userId), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_RECENT_LOGS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_RECENT_LOGS_FAILURE,
      payload: err,
    });
  }
}

export function* getSubscriptionDetails(action) {
  try {
    const { role,companyId } = action.payload;
    const apiEndpoint = role === "read_only_user" ?  APIEndpoints.GET_SUBSCRIPTION_DETAILS_STAKEHOLDER(companyId) : APIEndpoints.GET_SUBSCRIPTION_DETAILS
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_SUBSCRIPTION_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SUBSCRIPTION_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.LIST_USERS, listUsers),
    takeLatest(ActionTypes.ADD_USER, addUser),
    takeLatest(ActionTypes.GET_USER_DETAILS, getUserDetails),
    takeLatest(ActionTypes.EDIT_USER, editUser),
    takeLatest(ActionTypes.EDIT_USER_STAKEHOLDER, editUserStakeholder),
    takeLatest(ActionTypes.DELETE_USER, deleteUser),
    takeLatest(ActionTypes.SET_EMAIL_CONFIRMED, confirmEmail),
    takeLatest(ActionTypes.GET_ASSIGNED_STAKEHOLDERS, getAssignedStakeholders),
    takeLatest(ActionTypes.UPDATE_STAKEHOLDER_STATUS, updateStakeholderStatus),
    takeLatest(ActionTypes.GET_READ_ONLY_USER_ASSIGNED_REPORTS, getReadOnlyUserAssignedReports),
    takeLatest(ActionTypes.REVOKE_GRANT_REPORT_ACCESS, revokeGrantReportAccess),
    takeLatest(ActionTypes.PROXY_LOGIN_ACCESS_SUPERADMIN, proxyLoginAccessSuperadmin),
    takeLatest(ActionTypes.GET_USER_LOGS, getUserLogs),
    takeLatest(ActionTypes.LOCK_UNLOCK_USER, lockUnlockUser),
    takeLatest(ActionTypes.GET_RECENT_LOGS, getRecentLogs),
    takeLatest(ActionTypes.GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails),

  ]);
}
