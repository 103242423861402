import React from "react";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";

const UploadHistoryDetailsTable = ({ historyDetails }) => {
  const classes = useStyles();

  const dynamicColumns =
  historyDetails.length > 0
    ? Object.keys(historyDetails[0])
        .filter((key) => !["Comments", "Line number", "Status"].includes(key))
        .map((key) => ({
          columnKey: key,
          columnId: key,
          columnHeader: key,
        }))
    : [];


const tableData = historyDetails.map((item, index) => {
  const rowData = { sector: index + 1 };
  dynamicColumns.forEach((column) => {
    rowData[column.columnKey] =
      item[column.columnKey] != null ? item[column.columnKey] : "";
  });
  return rowData;
});


  return (
    <CeroTable
      columns={dynamicColumns}
      data={tableData}
      hasMore={false}
      loading={false}
      classes={{
        tableDataRow: classes.tableRow,
        tableContainer: classes.tableContainer,
      }}
    />
  );
};

export default UploadHistoryDetailsTable;
