import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';

export const materialityState = {
    setIndustryType: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getSurvey: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getMaterialityIndustries: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getMaterialityQuestions: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    createMaterialityQuestions: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    sendQuestionnaires: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getSurveyQuestions: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    answerSurveyQuestions: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getSurveyList: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    trackSurvey: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    sendReminder: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    surveyResponse:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    surveyQuestions:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    }
}

const materialityActions = {
    materiality: handleActions(
        {

            [ActionTypes.SET_INDUSTRY_TYPE]: (state, { payload }) =>
                immutable(state, {
                    setIndustryType: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.SET_INDUSTRY_TYPE_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    setIndustryType: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.SET_INDUSTRY_TYPE_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    setIndustryType: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),


            [ActionTypes.GET_SURVEY]: (state, { payload }) =>
                immutable(state, {
                    getSurvey: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_SURVEY_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    getSurvey: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_SURVEY_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    getSurvey: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_MATERIALITY_INDUSTRIES]: (state, { payload }) =>
                immutable(state, {
                    getMaterialityIndustries: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_MATERIALITY_INDUSTRIES_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    getMaterialityIndustries: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_MATERIALITY_INDUSTRIES_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    getMaterialityIndustries: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_MATERIALITY_QUESTIONS]: (state, { payload }) =>
                immutable(state, {
                    getMaterialityQuestions: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_MATERIALITY_QUESTIONS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    getMaterialityQuestions: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_MATERIALITY_QUESTIONS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    getMaterialityQuestions: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

                [ActionTypes.CREATE_MATERIALITY_QUESTIONS]: (state, { payload }) =>
                immutable(state, {
                    createMaterialityQuestions: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.CREATE_MATERIALITY_QUESTIONS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    createMaterialityQuestions: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.CREATE_MATERIALITY_QUESTIONS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    createMaterialityQuestions: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

                [ActionTypes.RESET_CREATE_QUESTIONS_STATUS]: (state, { payload }) =>
                immutable(state, {
                    createMaterialityQuestions: {
                        status: { $set: STATUS.IDLE },
                        message: { $set: '' }
                  },
                }),

                [ActionTypes.SEND_QUESTIONNAIRES]: (state, { payload }) =>
                immutable(state, {
                    sendQuestionnaires: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.SEND_QUESTIONNAIRES_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    sendQuestionnaires: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.SEND_QUESTIONNAIRES_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    sendQuestionnaires: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

                [ActionTypes.GET_SURVEY_QUESTIONS]: (state, { payload }) =>
                    immutable(state, {
                        getSurveyQuestions: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.GET_SURVEY_QUESTIONS_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        getSurveyQuestions: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.GET_SURVEY_QUESTIONS_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        getSurveyQuestions: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),

                    [ActionTypes.ANSWER_SURVEY_QUESTIONS]: (state, { payload }) =>
                        immutable(state, {
                            answerSurveyQuestions: {
                                status: { $set: STATUS.RUNNING }
                            }
                        }),
                    [ActionTypes.ANSWER_SURVEY_QUESTIONS_SUCCESS]: (state, { payload }) =>
                        immutable(state, {
                            answerSurveyQuestions: {
                                status: { $set: STATUS.SUCCESS },
                                data: { $set: payload },
                            }
                        }),
                    [ActionTypes.ANSWER_SURVEY_QUESTIONS_FAILURE]: (state, { payload }) =>
                        immutable(state, {
                            answerSurveyQuestions: {
                                status: { $set: STATUS.ERROR },
                                message: { $set: parseError(payload) }
                            }
                        }),

                        [ActionTypes.GET_SURVEY_LIST]: (state, { payload }) =>
                            immutable(state, {
                                getSurveyList: {
                                    status: { $set: STATUS.RUNNING }
                                }
                            }),
                        [ActionTypes.GET_SURVEY_LIST_SUCCESS]: (state, { payload }) =>
                            immutable(state, {
                                getSurveyList: {
                                    status: { $set: STATUS.SUCCESS },
                                    data: { $set: payload },
                                }
                            }),
                        [ActionTypes.GET_SURVEY_LIST_FAILURE]: (state, { payload }) =>
                            immutable(state, {
                                getSurveyList: {
                                    status: { $set: STATUS.ERROR },
                                    message: { $set: parseError(payload) }
                                }
                            }),

                            [ActionTypes.TRACK_SURVEY]: (state, { payload }) =>
                                immutable(state, {
                                    trackSurvey: {
                                        status: { $set: STATUS.RUNNING }
                                    }
                                }),
                            [ActionTypes.TRACK_SURVEY_SUCCESS]: (state, { payload }) =>
                                immutable(state, {
                                    trackSurvey: {
                                        status: { $set: STATUS.SUCCESS },
                                        data: { $set: payload },
                                    }
                                }),
                            [ActionTypes.TRACK_SURVEY_FAILURE]: (state, { payload }) =>
                                immutable(state, {
                                    trackSurvey: {
                                        status: { $set: STATUS.ERROR },
                                        message: { $set: parseError(payload) }
                                    }
                                }),

                                [ActionTypes.SEND_REMINDER]: (state, { payload }) =>
                                    immutable(state, {
                                        sendReminder: {
                                            status: { $set: STATUS.RUNNING }
                                        }
                                    }),
                                [ActionTypes.SEND_REMINDER_SUCCESS]: (state, { payload }) =>
                                    immutable(state, {
                                        sendReminder: {
                                            status: { $set: STATUS.SUCCESS },
                                            data: { $set: payload },
                                        }
                                    }),
                                [ActionTypes.SEND_REMINDER_FAILURE]: (state, { payload }) =>
                                    immutable(state, {
                                        sendReminder: {
                                            status: { $set: STATUS.ERROR },
                                            message: { $set: parseError(payload) }
                                        }
                                    }),

                                    [ActionTypes.SURVEY_RESPONSE]: (state, { payload }) =>
                                        immutable(state, {
                                            surveyResponse: {
                                                status: { $set: STATUS.RUNNING }
                                            }
                                        }),
                                    [ActionTypes.SURVEY_RESPONSE_SUCCESS]: (state, { payload }) =>
                                        immutable(state, {
                                            surveyResponse: {
                                                status: { $set: STATUS.SUCCESS },
                                                data: { $set: payload },
                                            }
                                        }),
                                    [ActionTypes.SURVEY_RESPONSE_FAILURE]: (state, { payload }) =>
                                        immutable(state, {
                                            surveyResponse: {
                                                status: { $set: STATUS.ERROR },
                                                message: { $set: parseError(payload) }
                                            }
                                        }),
                            
                                        [ActionTypes.SURVEY_QUESTIONS]: (state, { payload }) =>
                                            immutable(state, {
                                                surveyQuestions: {
                                                    status: { $set: STATUS.RUNNING }
                                                }
                                            }),
                                        [ActionTypes.SURVEY_QUESTIONS_SUCCESS]: (state, { payload }) =>
                                            immutable(state, {
                                                surveyQuestions: {
                                                    status: { $set: STATUS.SUCCESS },
                                                    data: { $set: payload },
                                                }
                                            }),
                                        [ActionTypes.SURVEY_QUESTIONS_FAILURE]: (state, { payload }) =>
                                            immutable(state, {
                                                surveyQuestions: {
                                                    status: { $set: STATUS.ERROR },
                                                    message: { $set: parseError(payload) }
                                                }
                                            }),

                [ActionTypes.RESET_CREATE_QUESTIONS_STATUS]: (state, { payload }) =>
                immutable(state, {
                    sendQuestionnaires: {
                        status: { $set: STATUS.IDLE },
                        message: { $set: '' }
                  },
                  answerSurveyQuestions: {
                    status: { $set: STATUS.IDLE },
                    message: { $set: '' }
                },
                createMaterialityQuestions: {
                    status: { $set: STATUS.IDLE },
                    message: { $set: '' }
                },
                sendReminder:{
                    status: { $set: STATUS.IDLE },
                    message: { $set: '' }
                }
                }),
        },
        materialityState
    )
}

export default materialityActions;
