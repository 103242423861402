import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    background: theme.palette.background.white,
    marginTop: theme.spacing(5),
  },
  cellContainer: {
    boxShadow: "none",
  },
  generalCellContainer: {
    width: '50%',
    boxShadow: "none",
  },
  tableHeaderContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: theme.palette.Secondary.background,
    fontWeight: 500,
  },
  tableTitle:{
    color: theme.palette.Primary.main,
    fontWeight: 600
  },
  Inprogress: {
    color: "orange",
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  added:{
    color: theme.palette.status.added,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  verified:{
    color: theme.palette.status.verified,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  approved:{
    color: theme.palette.status.approved,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  audited:{
    color: theme.palette.status.audited,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  review:{
    color: theme.palette.status.review,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  columnWidth:{
    width: "50%"
  }
}));

export default useStyles;
