import { Container, Grid, Link, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../services/cookie";

const Status = ({ ticketDetails, userInfo, companyId }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const isSupportTicket = window.location.pathname.includes("/support-tickets") || role === "super_admin";

  const onUpdate = () => {
    // if (ticketDetails.topic_type === 'board_diversity' || ticketDetails.topic_type === 'management_diversity')
    navigate(
      `/emissions/${ticketDetails.topic_type}/details/${ticketDetails.ticket_scope_id}/?company=${companyId}`
    );
    // else
    // navigate(`/emissions/${ticketDetails.topic_type}/details/${ticketDetails.ticket_scope_id}`)
  };

  const getStatusColorClass = () => {
    const status = ticketDetails.status.toLowerCase();

    if (status === "closed") {
      return classes.closed;
    } else {
      return classes.open;
    }
  };

  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {isSupportTicket ? (
            <>
              <CeroInfoPair
                title="Status"
                value={ticketDetails.status}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: getStatusColorClass(),
                }}
              />
              <CeroInfoPair
                title="Ticket type"
                value={ticketDetails.ticket_type}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Severity"
                value={ticketDetails.severity}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Ticket Raised By"
                value={ticketDetails.ticket_owner_name}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
                ticketDetails={ticketDetails}
              />
            </>
          ) : (
            <>
              <CeroInfoPair
                title="Status"
                value={ticketDetails.status}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: getStatusColorClass(),
                }}
              />
              <CeroInfoPair
                title="Facility"
                value={ticketDetails.facility_name}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Assignee"
                value={ticketDetails.assignee_name}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Scope"
                value={ticketDetails.ticket_scope}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          {isSupportTicket ? (
            <>
              <CeroInfoPair
                title="Company"
                value={ticketDetails.company ? ticketDetails.company : "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.companyValue,
                }}
              />
              <CeroInfoPair
                title="Assigned on"
                value={
                  ticketDetails.assigned_on &&
                  dayjs(ticketDetails.assigned_on).format("DD MMM YYYY HH:mm")
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Last updated on"
                value={
                  ticketDetails.last_updated &&
                  dayjs(ticketDetails.last_updated).format(
                    "DD MMM YYYY HH:mm"
                  )
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </>
          ) : (
            <>
              <CeroInfoPair
                title="Created By"
                value={ticketDetails.ticket_owner_name ? ticketDetails.ticket_owner_name : "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
                ticketDetails={ticketDetails}
              />
              <CeroInfoPair
                title="Created on"
                value={
                  ticketDetails.assigned_on &&
                  dayjs(ticketDetails.assigned_on).format("DD MMM YYYY HH:mm")
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Last updated on"
                value={
                  ticketDetails.last_updated &&
                  dayjs(ticketDetails.last_updated).format(
                    "DD MMM YYYY HH:mm"
                  )
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Stack sx={{ marginTop: "1rem" }}>
        <Typography variant="h6" component="span">
          {`Description`}
        </Typography>
        <Typography
          sx={{ marginTop: "0.5rem", marginBottom: "0.7rem", fontWeight: 500 }}
          variant="body2"
          component="span"
        >
          {ticketDetails.description}
        </Typography>
        {ticketDetails?.ticket_scope === "emission" && !readOnlyMode && !isSupportTicket ? (
          <Typography sx={{ fontWeight: 500 }} variant="body2">
            <span>
              <Link onClick={onUpdate} style={{ cursor: "pointer" }}>
                Click here
              </Link>
            </span>{" "}
            to update
          </Typography>
        ) : (
          ""
        )}
      </Stack>
    </Container>
  );
};

export default Status;
