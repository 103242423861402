import * as yup from "yup";

export const schemeValidation = yup.object({
  facility: yup.string().required("Facility is required"),
  year: yup.string().required("Year is required"),
  assessmentPeriod: yup.string().required("Assessment Period is required"),
  riskOfChildLabor: yup.string()
  .required("Indicate if the supplier is at significant risk for incidents of child labor."),
  childLaborDetails: yup
    .string()
    .when("riskOfChildLabor", {
    is: "Yes",
    then: yup.string().required("If Yes, provide an explanation of the risk and the measures taken to address it."),
    otherwise: yup.string(),}),
  riskOfCompulsoryLabor: yup.string()
  .required("Indicate if the supplier is at significant risk for incidents of forced or compulsory labor."),
  compulsoryLaborDetails: yup.string()
  .when("riskOfCompulsoryLabor", {
    is: "Yes",
    then: yup.string().required("If Yes, provide an explanation of the risk and the measures taken to address it."),
    otherwise: yup.string(),}),
  actionRequired: yup.string()
  .required("Capture any incidents and actions taken related to human rights that your organization is aware of."),
});
