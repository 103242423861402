import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';

export const {
    listCarbonOffsetPrograms,
    addCarbonOffset,
    getProgramTypes,
    getPrograms,
    resetCarbonOffset,
    getOffsetDetails,
    getIotData,
    updateIotData,
    resetIotData,
    refreshIotData,
    getOffsetCountries
} = createActions({
    [ActionTypes.LIST_CARBON_OFFSET_PROGRAMS]: (location, program) => ({location, program }),
    [ActionTypes.ADD_CARBON_OFFSET]: (requestData) => ({requestData}),
    [ActionTypes.GET_PROGRAM_TYPES]: ( ) => ({ }),
    [ActionTypes.GET_PROGRAMS]: ( programId ) => ({ programId }),
    [ActionTypes.RESET_CARBON_OFFSET]: () => {},
    [ActionTypes.GET_OFFSET_DETAILS]: ( programId ) => ({ programId }),
    [ActionTypes.GET_IOT_DATA]: ( programId , year) => ({ programId , year}),
    [ActionTypes.UPDATE_IOT_DATA]: ( dataId , requestData ) => ({ dataId, requestData }),
    [ActionTypes.RESET_IOT_DATA]: () => {},
    [ActionTypes.REFRESH_IOT_DATA]: ( programId ) => ({ programId }), 
    [ActionTypes.GET_OFFSET_COUNTRIES]: ( ) => ({ }),
});
