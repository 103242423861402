import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addSupplierScreening,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import CeroEpochDatePicker from "../../../components/CeroDateTimePicker/CeroEpochDatePicker";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroSelect from "../../../components/CeroSelect";
import { sampleYear } from "../../../constants";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroChipSelect from "../../../components/CeroChipSelect";

const AddSupplierScreeningForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const addEmissionData = useSelector(
    (state) => state.emission.addSupplierScreening
  );

  const [selectedEnvironmental, setSelectedEnvironmental] = useState([]);
  const [selectedSocial, setSelectedSocial] = useState([]);
  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const [showOtherDetailsSocial, setShowOtherDetailsSocial] = useState(false);
  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      environmentalCriteria: [],
      environmentalSuppliers: 0,
      otherDetails: "",
      describeEnvironmentalImpact: "",
      actionsToNegative: "",
      socialCriteria: [],
      socialSuppliers: 0,
      otherDetailsSocial: "",
      describeSocialImpact: "",
      actionsToNegativeImpact: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'supplier_screening_using_enviormental_social_criteria'));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    setShowOtherDetails(selectedEnvironmental.includes("Others"));

  }, [selectedEnvironmental]);

  useEffect(() => {
    setShowOtherDetailsSocial(selectedSocial.includes("Others"));

  }, [selectedSocial]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Supplier Screening using Environmental and Social criteria added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddSupplierScreening = () => {
    const requestData = {
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      environmental_criteria: selectedEnvironmental,
      enviormental_criteria_details: formik.values.otherDetails,
      suppliers_screened_using_env_criteria:
        formik.values.environmentalSuppliers,
      negative_env_impact: formik.values.describeEnvironmentalImpact,
      action_taken_address_env_criteria: formik.values.actionsToNegative,
      social_criteria: selectedSocial,
      social_criteria_details: formik.values.otherDetailsSocial,
      suppliers_screened_using_social_criteria: formik.values.socialSuppliers,
      negative_social_impact: formik.values.describeSocialImpact,
      action_taken_address_social_criteria:
        formik.values.actionsToNegativeImpact,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addSupplierScreening(requestData));
  };
  
  const environmentalCriterias = {
    "Emission Reduction" : "Emission Reduction",
    "Energy Efficiency" : "Energy Efficiency",
    "Environmental Complaince": "Environmental Complaince",
    "Renewable Energy Usage" : "Renewable Energy Usage",
    "Sustainable Sourcing": "Sustainable Sourcing",
    "Waste Management": "Waste Management",
    "Water Conservation" : "Water Conservation",
    "Others": "Others",
  };

  const socialCriterias = {
    "Child Labour": "Child Labour",
    "Employment": "Employment",
    "Forced or Compulsary Labor": "Forced or Compulsary Labor",
    "Occupational Health & Safety": "Occupational Health & Safety",
    "Others": "Others",
  };

  const environmentalHandleChange = (event) => {
    const value = event.target.value;
    setSelectedEnvironmental(value);
    formik.setFieldValue("environmental_criteria", value);
    setShowOtherDetails(value.includes("Others"));
    if (value[value.length - 1] === "all") {
      setSelectedEnvironmental(selectedEnvironmental.length === Object.keys(environmentalCriterias).length ? [] : Object.keys(environmentalCriterias));
      return;
    }
  };

  const socialHandleChange = (event) => {
    const value = event.target.value;
    setSelectedSocial(value);
    formik.setFieldValue("social_criteria", value);
    if (value[value.length - 1] === "all") {
      setSelectedSocial(selectedSocial.length === Object.keys(socialCriterias).length ? [] : Object.keys(socialCriterias));
      return;
    }
  };


  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Supplier Screening using Environmental and Social Criteria
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={4} xs={6}>
                <CeroChipSelect
                  required
                  id="environmentalCriteria"
                  name="environmentalCriteria"
                  label={"Environmental Criteria"}
                  formControlClass={classes.MultiSelectformControl}
                  optionsde={environmentalCriterias}
                  selected={selectedEnvironmental}
                  handleChange={environmentalHandleChange}
                  error={formik.touched.environmentalCriteria &&
                    formik.errors.environmentalCriteria}
                  classes={{justCheckSelected: classes.justCheckSelected}}
                />
              </Grid>
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  required
                  id="environmentalSuppliers"
                  name="environmentalSuppliers"
                  label="Number of Suppliers screened using Environmental Criteria"
                  fullWidth
                  value={formik.values.environmentalSuppliers}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.environmentalSuppliers &&
                    formik.errors.environmentalSuppliers}
                />
              </Grid>
            </Grid>
            {showOtherDetails && (
               <CeroInput
                id="otherDetails"
                name="otherDetails"
                label="Environmental Criteria Details"
                fullWidth
                value={formik.values.otherDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
              )}
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column">
                <CeroInput
                  required
                  id="describeEnvironmentalImpact"
                  name="describeEnvironmentalImpact"
                  label="Describe Negative Environmental Impacts"
                  fullWidth
                  multiline
                  rows="2"
                  value={formik.values.describeEnvironmentalImpact}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.describeEnvironmentalImpact &&
                    formik.errors.describeEnvironmentalImpact}
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <CeroInput
                required
                id="actionsToNegative"
                name="actionsToNegative"
                label="Actions Taken to Address Negative Environmental Impacts"
                fullWidth
                multiline
                rows="2"
                value={formik.values.actionsToNegative}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.actionsToNegative &&
                  formik.errors.actionsToNegative}
                classes={{ container: classes.textAreaContainer }}
              />
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={4} xs={6}>
                <CeroChipSelect
                  required
                  id="socialCriteria"
                  name="socialCriteria"
                  label={"Social Criteria"}
                  formControlClass={classes.MultiSelectformControlsocial}
                  optionsde={socialCriterias}
                  selected={selectedSocial}
                  handleChange={socialHandleChange}
                  error={formik.touched.socialCriteria && formik.errors.socialCriteria}
                  classes={{justCheckSelected: classes.justCheckSelectedSocial}}
                />
              </Grid>
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  required
                  id="socialSuppliers"
                  name="socialSuppliers"
                  label="Number of Suppliers screened using Social Criteria"
                  fullWidth
                  value={formik.values.socialSuppliers}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.socialSuppliers && formik.errors.socialSuppliers}
                />
              </Grid>
            </Grid>
            {showOtherDetailsSocial && (
               <CeroInput
                id="otherDetailsSocial"
                name="otherDetailsSocial"
                label="Social Criteria Details"
                fullWidth
                value={formik.values.otherDetailsSocial}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
              )}
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column">
                <CeroInput
                  required
                  id="describeSocialImpact"
                  name="describeSocialImpact"
                  label="Describe Negative Social Impacts"
                  fullWidth
                  multiline
                  rows="2"
                  value={formik.values.describeSocialImpact}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.describeSocialImpact && formik.errors.describeSocialImpact}
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <CeroInput
                required
                id="actionsToNegativeImpact"
                name="actionsToNegativeImpact"
                label="Actions Taken to Address Negative Social Impacts"
                fullWidth
                multiline
                rows="2"
                value={formik.values.actionsToNegativeImpact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.actionsToNegativeImpact && formik.errors.actionsToNegativeImpact}
                classes={{ container: classes.textAreaContainer }}
              />
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid || addEmissionData.status === STATUS.RUNNING}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddSupplierScreening}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddSupplierScreeningForm;
