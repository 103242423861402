import { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import useStyles from "./styles";
import CeroInfoPair from "../../../../../components/CeroInfoPair";
import CeroItemPair from "../../../../../components/CeroItemPair";

const Status = (props) => {
  const classes = useStyles();
  const initiativeDetails = props.details

  return (
    <Container className={classes.statusContainer}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CeroItemPair
            title="Mission:"
            value={initiativeDetails.mission}
            classes={{ container: classes.infoContainer, title: classes.title, value: classes.value, }}
          />
          <CeroItemPair
            title="Initiative:"
            value={
                initiativeDetails.initiative
                ? initiativeDetails.initiative
                : "NA"
            }
            classes={{ container: classes.infoContainer, title: classes.title, value: classes.value, }}
          />
          <CeroItemPair
            title="Initiative ID:"
            value={
                initiativeDetails.initiative_id
                ? initiativeDetails.initiative_id
                : "NA"
            }
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Assessment year:"
            value={initiativeDetails.year
                ? initiativeDetails.year
                : "NA"}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
        </Grid>
        <Grid item xs={4}>
          <CeroItemPair
            title=""
            value=""
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Country:"
            value={initiativeDetails.country
                ? initiativeDetails.country
                : "NA"}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Facility:"
            value={initiativeDetails.facility_name
                ? initiativeDetails.facility_name
                : "NA"}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Tracking Cycle:"
            value={initiativeDetails.tracking_cycle
                ? initiativeDetails.tracking_cycle
                : "NA"}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Status;
