import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 20,
    borderRadius: 8,
    marginLeft: 0,
    marginRight: 0,
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  infoContainer: {
    maxWidth: 400,
    display:"flex !important",
    justifyContent: 'flex-start',
    "& .MuiTypography-root": {
      minWidth: 140,
    },
    "& .MuiBox-root > .MuiTypography-root": {
      paddingBottom: 4,
    },
  },
  title:{
    width:"50%"
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight:500,
    fontSize: 14
  },
  submitted:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  added:{
    color: theme.palette.status.added,
    fontWeight:500,
    fontSize: 14
  },
  Inprogress:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  verified:{
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  review:{
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  },
  statusContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "15%",
    marginLeft: "2%",
  },
}));

export default useStyles;
