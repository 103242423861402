import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    height: "100%",
    width: "100%",
    maxWidth: "100%",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  closeIcon: {
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 15,
    width: "40%",
    margin: "auto"
  },
  buttonContainer:{
    display: "flex", 
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 15
  },
  textField: {
    backgroundColor: theme.palette.background.white,
  },
  noComments: {
    float: "left",
    fontFamily: "Arial, sans-serif",
    fontSize: 12
  },
  maxCharacters: {
    float: "right",
    fontSize: 12
  },
  dialog:{
    width: "30%",
    "& .MuiPaper-root.MuiDialog-paper": {
     width: "40vw",
     margin: 80,
   },
   },
  textLimit:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10
  },
  deleteBox:{
    width: "80%",
    margin: "auto",
  }
}));

export default useStyles;
