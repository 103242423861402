import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from '../../services/client';

export const auditState = {
  auditSummaryList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },

  auditYearlySummary: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },

  auditYearlySummaryOverview: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },

  questionsList: {
    data: [],
    auditStatus: '',
    status: STATUS.IDLE,
    message: "",
  },

  answerQuestion: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },

  auditStatusYearlySummaryOverview: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  requestAudit: {
    status: STATUS.IDLE,
    message: "",
  },
  auditTrails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },

  auditorList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },

  auditorStatus:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  approveAudit:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  setAuditTopics:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  assignAuditor:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  auditorReaction:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  }
};

const auditActions = {
  audit: handleActions(
    {
      [ActionTypes.GET_AUDIT_SUMMARY]: (state, { payload }) =>
        immutable(state, {
          auditSummaryList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_AUDIT_SUMMARY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditSummaryList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_AUDIT_SUMMARY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditSummaryList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_YEARLY_AUDIT_SUMMARY]: (state, { payload }) =>
        immutable(state, {
          auditYearlySummary: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_YEARLY_AUDIT_SUMMARY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditYearlySummary: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_YEARLY_AUDIT_SUMMARY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditYearlySummary: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW]: (state, { payload }) =>
        immutable(state, {
          auditYearlySummaryOverview: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditYearlySummaryOverview: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditYearlySummaryOverview: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_ALL_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          questionsList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ALL_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          questionsList: {
            status: { $set: STATUS.SUCCESS },
            auditStatus: { $set: payload.auditStatus },
            data: { $set: payload.data },
          },
        }),
      [ActionTypes.GET_ALL_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          questionsList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW]: (state, { payload }) =>
        immutable(state, {
          auditStatusYearlySummaryOverview: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditStatusYearlySummaryOverview: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditStatusYearlySummaryOverview: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ANSWER_QUALITATIVE_QUESTION]: (state, { payload }) =>
        immutable(state, {
          answerQuestion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ANSWER_QUALITATIVE_QUESTION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          answerQuestion: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
          questionsList: {
            data: {
              $set: state.questionsList.data.map(item => {
                if(item.id === payload.question_id) {
                  item = {...item, ...payload}
                }
                return item
              }),
            },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.ANSWER_QUALITATIVE_QUESTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          answerQuestion: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_QUESTION_ANSWER_STATUS]: (state, { payload }) =>
        immutable(state, {
          answerQuestion: {
            status: { $set: STATUS.IDLE },
            message: { $set: '' },
            data: { $set: [] },
          },
        }),

      [ActionTypes.REQUEST_AUDIT]: (state, { payload }) =>
        immutable(state, {
          requestAudit: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.REQUEST_AUDIT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          requestAudit: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
          auditYearlySummary: {
            data: {
              $set: {
                ...state.auditYearlySummary.data,
                status: payload.audited_status,
                actions: {
                  ...state.auditYearlySummary.data.actions,
                    perform_request_audit: false,
                },
              },
            },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.REQUEST_AUDIT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          requestAudit: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_REQUEST_AUDIT_DATA]: (state, { payload }) =>
        immutable(state, {
          requestAudit: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
        }),

        [ActionTypes.GET_AUDIT_TRAILS]: (state, { payload }) =>
        immutable(state, {
          auditTrails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_AUDIT_TRAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditTrails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_AUDIT_TRAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditTrails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_AUDITOR_LIST]: (state, { payload }) =>
        immutable(state, {
          auditorList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_AUDITOR_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditorList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_AUDITOR_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditorList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.UPDATE_AUDITOR_STATUS]: (state, { payload }) =>
        immutable(state, {
          auditorStatus: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_AUDITOR_STATUS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          auditorStatus: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.UPDATE_AUDITOR_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditorStatus: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.APPROVE_AUDIT_BY_SUSMGR]: (state, { payload }) =>
                immutable(state, {
                    approveAudit: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.APPROVE_AUDIT_BY_SUSMGR_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approveAudit: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.APPROVE_AUDIT_BY_SUSMGR_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approveAudit: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

                [ActionTypes.SET_AUDIT_TOPICS]: (state, { payload }) =>
        immutable(state, {
          setAuditTopics: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.SET_AUDIT_TOPICS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          setAuditTopics: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.SET_AUDIT_TOPICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          setAuditTopics: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.ASSIGN_AUDITOR]: (state, { payload }) =>
          immutable(state, {
            assignAuditor: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.ASSIGN_AUDITOR_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            assignAuditor: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.ASSIGN_AUDITOR_FAILURE]: (state, { payload }) =>
          immutable(state, {
            assignAuditor: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),

          [ActionTypes.AUDITOR_REACTION]: (state, { payload }) =>
            immutable(state, {
              auditorReaction: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.AUDITOR_REACTION_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              auditorReaction: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.AUDITOR_REACTION_FAILURE]: (state, { payload }) =>
            immutable(state, {
              auditorReaction: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),

          [ActionTypes.RESET_ASSIGN_AUDITOR]: (state, { payload }) =>
            immutable(state, {
              assignAuditor: {
                status: { $set: STATUS.IDLE },
                message: { $set: '' },
                data: { $set: [] },
              },
            }),

        [ActionTypes.RESET_UPDATE_AUDITOR_STATUS]: (state, { payload }) =>
        immutable(state, { $set: auditState }),

        [ActionTypes.RESET_AUDITED_DATA]: (state, { payload }) =>
          immutable(state, {
            auditorReaction: {
              status: { $set: STATUS.IDLE },
              message: { $set: '' },
              data: { $set: [] },
            },
          }),
    },
    auditState
  ),
};

export default auditActions;
