import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import {
  Typography,
  Badge,
  IconButton,
  Tooltip,
  Menu,
  Avatar,
} from "@mui/material";
import { Box } from "@mui/system";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useDispatch, useSelector } from "react-redux";
import {
  listNotifications,
  markAllRead,
  markAsRead,
} from "../../../redux/actions";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import { getPrefixRole } from "../../../services/utilityService";

const NotificationIcon = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(false);

  const role = useSelector((state) => state.auth.role);
  const activeRole = getCookie("role");
  const rolePrefix = getPrefixRole(activeRole)
  const notifications = useSelector(
    (state) => state.dashboard.notificationList.data
  );

  const getTimeAgo = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const timeDifference = now.getTime() - date.getTime();
  
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(timeDifference / (1000 * 60));
    const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeksAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
    const monthsAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30.44)); 
    const yearsAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
  
    if (secondsAgo < 60) {
      return `${secondsAgo} seconds ago`;
    } else if (minutesAgo < 60) {
      return `${minutesAgo} minutes ago`;
    } else if (hoursAgo < 24) {
      return `${hoursAgo} hours ago`;
    } else if (daysAgo < 7) {
      return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    } else if (weeksAgo < 4) {
      return `${weeksAgo} week${weeksAgo !== 1 ? 's' : ''} ago`;
    } else if (monthsAgo < 12) {
      return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
    } else {
      return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
    }
  };
  
  const unreadCount = notifications.filter((item) => !item.is_read).length;
  const isAuditor = role.includes(rolesEnum.AUDITOR);

  const newNotifications = `You have ${unreadCount} new notifications`;
  const noNotifications = "No new notifications";

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const markNotificationAsRead = (id) => {
    dispatch(markAsRead({ id, isAuditor, unread: !checked }));
  };

  const markAllAsRead = () => {
    dispatch(markAllRead({ unread: !checked, isAuditor }));
  };

  const viewAll = (viewMessages) => {
    setChecked(viewMessages);
    dispatch(
      listNotifications({ unread: !viewMessages, isAuditor, rolePrefix })
    );
  };

  const redirect = ({ id, form, form_id, is_read }) => {
    !is_read && markNotificationAsRead(id);
    if (form === "ticket") {
      navigate(`/tickets/id?ticketId=${form_id}`);
    } else if (form === "audit") {
      navigate(`/audit-status/audit-summary/?id=${form_id}`);
    } else if (form === "approval") {
      navigate(`/approval-status/null?&id=${form_id}`);
    }
    handleClose();
  };

  console.log('isAuditor', isAuditor)

  useEffect(() => {
    dispatch(listNotifications({ unread: true, isAuditor, rolePrefix }));
  }, [dispatch, isAuditor, rolePrefix]);
  
  const popoverStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  return (
    <Box>
      <Tooltip title={unreadCount ? newNotifications : noNotifications}>
        <IconButton
          onClick={handleOpen}
          aria-controls="notification-menu"
          aria-haspopup="true"
        >
          <Badge badgeContent={unreadCount} color="error" max={9}>
            <NotificationsNoneIcon style={{ color: "white" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        PaperProps={{
          elevation: 0,
          sx: { ...popoverStyle },
        }}
        className={classes.popover}
      >
        <Box className={classes.notificationContainer}>
          <Box className={classes.header}>Notifications</Box>
          <Box className={classes.scrollContainer}>
            {notifications.length ? (
              notifications.map((item) => (
                <Box
                  className={classes.menuItem}
                  key={item.id}
                  onClick={
                    item.need_redirection ? () => redirect(item) : () => {}
                  }
                >
                  <Avatar
                  alt={item.title.charAt(0).toUpperCase()}
                  src={"userInfo.logo"}
                  children={
                    item.title ? item.title.charAt(0).toUpperCase() : <Avatar />
                  }
                  className={classes.avatar}/>
                    <Typography
                      variant="body2"
                      className={
                        item.need_redirection
                          ? classes.menuItemEnabled
                          : classes.menuItemDisabled
                      }
                    >
                      {item.title}              
                    </Typography>
                    <Typography className={classes.timestamp}>{getTimeAgo(item.created_on)}</Typography>
                </Box>
              ))
            ) : (
              <Box className={classes.empty}>
                <Box>No notifications</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default NotificationIcon;
