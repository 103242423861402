import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from 'notistack';

import CeroInput from "../../../components/CeroInput";
import CeroSelect from "../../../components/CeroSelect";
import CeroSideSheetDrawer from "../../../components/CeroSideSheetDrawer";
import { listAssignee, createTicket, resetTicketStatus, updateTicket } from "../../../redux/actions";
import { createTicketValidation, createTicketAuditorValidation, supportTicketValidation } from "./schema";
import { STATUS } from "../../../redux/constants";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";

const CreateTicketDrawer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const role = getCookie("role") === "auditor" ? "auditor" : "business";

  const { ticketData, isOpen, scope, scopeId, companyId } = props;
  const userList = useSelector((state) => state.listings.assigneeList.data);
  const createTicketDetailsStatus = useSelector((state) => state.ticket.createTicketDetails.status);
  const createTicketError = useSelector((state) => state.ticket.createTicketDetails.message);
  const updateTicketDetails = useSelector((state) => state.ticket.updateTicketDetails);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const isButtonLoading = createTicketDetailsStatus === STATUS.RUNNING
  const isSupportPage = window.location.pathname.includes("/support-tickets")
  const isAuditor = userInfo && userInfo.role.includes(rolesEnum.AUDITOR);

  const usersOptionList = userList.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const ticketTypes =  [
    { key: "incident", value: "Incident" },
    { key: "request", value: "Request"}
  ]

  const severityTypes =  [
    { key: "high", value: "Sev 1- High" },
    { key: "medium", value: "Sev 2- Medium"},
    { key: "low", value: "Sev 3- Low"}
  ]

  const createTicketForm = useFormik({
    initialValues: {
      title: ticketData ? ticketData.title : "",
      details: ticketData ? ticketData.details || ticketData.description: "",
      assignee: ticketData ? ticketData.assignee || ticketData.assignee_id: "",
      ticketType: ticketData ? ticketData.ticketType || ticketData.ticketType: "",
      severity: ticketData ? ticketData.severity || ticketData.severity: "",
    },
    validationSchema: companyId ? createTicketAuditorValidation : ( isSupportPage ? supportTicketValidation : createTicketValidation ),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const onSubmitTicketData = () => {
   let payload;
  if (isSupportPage) {
    payload = {
      title: createTicketForm.values.title,
      description: createTicketForm.values.details,
      ticket_scope: scope,
      ticket_type: createTicketForm.values.ticketType,
      ticket_severity: createTicketForm.values.severity,
      internal: false,
      companyId,
      isAuditor
    };
  } else {
    payload = {
      title: createTicketForm.values.title,
      description: createTicketForm.values.details,
      assigned_to_id: createTicketForm.values.assignee,
      ticket_scope: scope,
      ticket_scope_id: scopeId,
      internal: true,
      companyId,
      isAuditor
    };
  }
    if (props.ticketId){
      payload.ticketId=props.ticketId
      dispatch(updateTicket(payload))
    }
    else{
    dispatch(createTicket(payload))
    }
  };

  const onClose = useCallback(() => {
    createTicketForm.resetForm();
    props.onClose();
  }, [createTicketForm, props]);

  useEffect(() => {
    if (createTicketDetailsStatus === STATUS.SUCCESS) {
        enqueueSnackbar('Ticket created successfully', { variant: 'success' });
        dispatch(resetTicketStatus())
        onClose();
    } else if (createTicketDetailsStatus === STATUS.ERROR) {
        enqueueSnackbar(createTicketError.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
        dispatch(resetTicketStatus())
    }
}, [createTicketDetailsStatus, enqueueSnackbar, onClose, dispatch, createTicketError.message])

useEffect(() => {
  if (updateTicketDetails.status === STATUS.SUCCESS) {
      enqueueSnackbar('Ticket updated successfully', { variant: 'success' });
      dispatch(resetTicketStatus())
      onClose();
  } else if (updateTicketDetails.status === STATUS.ERROR) {
      enqueueSnackbar(updateTicketDetails.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
      dispatch(resetTicketStatus())
  }
}, [updateTicketDetails, enqueueSnackbar, onClose, dispatch])

  useEffect(() => {
    !companyId && dispatch(listAssignee(role));
  }, [dispatch, companyId]);

  const getPrimaryPaymentDrawer = () => {
    return (
      <Box className={classes.mainContainer}>
        {isSupportPage && <CeroSelect
          required
          id="ticketType"
          name="ticketType"
          label="Ticket Type"
          fullWidth
          options={ticketTypes}
          selectedValue={createTicketForm.values.ticketType}
          onChange={createTicketForm.handleChange}
          onBlur={createTicketForm.handleBlur}
          error={createTicketForm.touched.ticketType && createTicketForm.errors.ticketType}
        />}
        <CeroInput
          required
          id="title"
          name="title"
          label="Ticket Title"
          fullWidth
          value={createTicketForm.values.title}
          onChange={createTicketForm.handleChange}
          onBlur={createTicketForm.handleBlur}
          error={createTicketForm.touched.title && createTicketForm.errors.title}
        />
        <CeroInput
          classes={{container: classes.textAreaContainer}}
          rows={6}
          placeholder={"Please enter ticket details"}
          required
          id="details"
          name="details"
          label="Ticket Details"
          value={createTicketForm.values.details}
          onChange={createTicketForm.handleChange}
          onBlur={createTicketForm.handleBlur}
          error={createTicketForm.touched.details && createTicketForm.errors.details}
          multiline
        />
        {isSupportPage && <CeroSelect
          required
          id="severity"
          name="severity"
          label="Severity"
          fullWidth
          options={severityTypes}
          selectedValue={createTicketForm.values.severity}
          onChange={createTicketForm.handleChange}
          onBlur={createTicketForm.handleBlur}
          error={createTicketForm.touched.severity && createTicketForm.errors.severity}
        />}
        {!companyId && !isSupportPage && <CeroSelect
          required
          id="assignee"
          name="assignee"
          label="Assignee"
          fullWidth
          options={usersOptionList}
          selectedValue={createTicketForm.values.assignee}
          onChange={createTicketForm.handleChange}
          onBlur={createTicketForm.handleBlur}
          error={createTicketForm.touched.assignee && createTicketForm.errors.assignee}
          sortOption={true}
        />}
      </Box>
    );
  };

  return (
    <CeroSideSheetDrawer
      primaryDrawer={{
        drawerOpen: isOpen,
        onClose: onClose,
        content: getPrimaryPaymentDrawer(),
        header: { title: "Raise a ticket" },
        footer: {
          primaryBtnTitle: isButtonLoading ? "Saving..." : "Save",
          secondaryBtnTitle: "Cancel",
          primaryBtnAction: onSubmitTicketData,
          secondaryBtnAction: onClose,
          disablePrimaryBtn:
            !createTicketForm.dirty || !createTicketForm.isValid || isButtonLoading, 
        },
        classes: {
          drawerContainer: classes.drawerContainer,
          contentArea: classes.drawerContentArea,
        },
      }}
    />
  );
};

export default CreateTicketDrawer;
