import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardLayout from "../../../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminReportDetails } from "../../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#E5DFE0",
    fontWeight: "bold",
    border: "1px solid #ddd",
    textAlign: "center",
  },
  tableCell: {
    border: "1px solid #ddd",
    textAlign: "center",
  },
  rowGroup: {
    "&:not(:last-of-type) td": {
      borderBottom: "2px solid #ddd",
    },
  },
  tableContainer: {
    maxHeight: "500px",
    overflow: "auto",
    marginTop: 20,
  },
  statusHeader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    textAlign: "center",
  },
  audited: {
    color: "green",
  },
  unaudited: {
    color: "orange",
  },
  container: {
    padding: 0,
    margin: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 20,
    alignItems: "center",
    cursor: "pointer",
  },
}));

const SuperAdminReportDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyId, country_code, facility_id, year } = useParams();

  useEffect(() => {
    dispatch(getAdminReportDetails(companyId, country_code, facility_id, year));
  }, [dispatch, companyId, country_code, facility_id, year]);

  const reportData = useSelector(
    (state) => state.superAdmin.getAdminReportDetails.data
  );
  const processData = (data) => {
    const result = [];
  
    data.forEach((item) => {
      const { audited, unaudited, period } = item;
  
      if (unaudited.length > 0) {
        result.push({
          status: "Unaudited",
          period: period,
          reports: unaudited,
        });
      }
  
      if (audited.length > 0) {
        result.push({
          status: "Audited",
          period: period,
          reports: audited,
        });
      }
    });
  
    return result;
  };

  const processedData = processData(reportData); 

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <div>
          <Typography variant="h6" gutterBottom>
            {reportData[0]?.country} > {reportData[0]?.facility} > {year}
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} stickyHeader>
            <TableHead>
                <TableRow>
                <TableCell className={classes.tableHeader}>Status</TableCell>
                <TableCell className={classes.tableHeader}>Period</TableCell>
                <TableCell className={classes.tableHeader}>Report Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {processedData.map((group, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell
                        className={`${classes.tableCell} ${group.status === 'Audited' ? classes.audited : classes.unaudited}`}
                        rowSpan={group.reports.length}
                      >
                        {group.status}
                      </TableCell>
                      <TableCell rowSpan={group.reports.length} className={classes.tableCell}>{group.period}</TableCell>
                      <TableCell className={classes.tableCell}>{group.reports[0]}</TableCell>
                    </TableRow>
                    {group.reports.slice(1).map((report, idx) => (
                      <TableRow key={idx}>
                        <TableCell className={classes.tableCell}>{report}</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </DashboardLayout>
  );
};

export default SuperAdminReportDetails;
