import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    containercard:{
        paddingLeft:0,
        paddingRight:0,
        "& .infinite-scroll-component__outerdiv > .infinite-scroll-component":{
            padding:"2px 8px"
        },
        "& .infinite-scroll-component__outerdiv > .infinite-scroll-component> .MuiPaper-root":{
            border: "1px solid #5a404e"
        }
    },
    containercardTable:{
      padding: theme.spacing(2),
      // backgroundColor: "#EAE2E6",
      backgroundColor: "#FFFFFF",
      "& .MuiPaper-root.MuiTableContainer-root":{
        border: "1px solid black"
      }
    },
    tableContainer:{
      border: "1px solid black"
    },
    signalPending:{
      backgroundColor: theme.palette.status.pendingApproval,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1) 
    },
    signalAudited:{
      backgroundColor: theme.palette.status.audited,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1) 
    },
    subTitle:{
      fontSize: 14,
      fontWeight: 500,
      padding: theme.spacing(5,0,3,0)
    },
    signalAdded:{
      backgroundColor: theme.palette.status.added,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1)       
    },
    signalApproved:{
      backgroundColor: theme.palette.status.approved,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1)       
    },
    signalReview:{
      backgroundColor: theme.palette.status.review,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1)   
    },
    signalVerified:{
      backgroundColor: theme.palette.status.verified,
      width: 16,
      height: 16,
      borderRadius: "50%",
      border: "1px solid black",
      marginRight: theme.spacing(1)
    },
    lastCard:{
      width: "90%",
      margin: "auto"
    }
}));

export default useStyles;