export const StationaryColumns = [
    {
      columnKey: "fuel",
      columnId: "fuel",
      columnHeader: "Fuel",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "biofuel_co2",
      columnId: "biofuel_co2",
      columnHeader: "BioFuel CO2\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const TransportationColumns = [
    {
      columnKey: "category_name",
      columnId: "category_name",
      columnHeader: "Category",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "mode_of_transport_name",
      columnId: "mode_of_transport_name",
      columnHeader: "Mode of Transport",
    },
    {
      columnKey: "vehicle_type_name",
      columnId: "vehicle_type_name",
      columnHeader: "Vehicle type",
    },
    {
      columnKey: "activity_type_name",
      columnId: "activity_type_name",
      columnHeader: "Unit",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "biofuel_co2",
      columnId: "biofuel_co2",
      columnHeader: "BioFuel CO2\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const MobileColumns = [
    {
      columnKey: "fuel_source",
      columnId: "fuel_source",
      columnHeader: "Fuel Source",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "activity_type",
      columnId: "activity_type",
      columnHeader: "Activity Type",
    },
    {
      columnKey: "vehicle_type",
      columnId: "vehicle_type",
      columnHeader: "Vehicle Type",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "biofuel_co2",
      columnId: "biofuel_co2",
      columnHeader: "BioFuel CO2\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const PurchasedElectricityColumns = [
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "calculation_approach",
      columnId: "calculation_approach",
      columnHeader: "Calculation Approach",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WaterDischargeColumns = [
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "treatment_level",
      columnId: "treatment_level",
      columnHeader: "Treatment Level",
    },
    {
      columnKey: "treatment_required",
      columnId: "treatment_required",
      columnHeader: "Treatment Required",
    },
    {
      columnKey: "water_destination_name",
      columnId: "water_destination_name",
      columnHeader: "Destination Name",
    },
    {
      columnKey: "water_destination_stress_type_name",
      columnId: "water_destination_stress_type_name",
      columnHeader: "Stress Type",
    },
    {
      columnKey: "water_destination_type_name",
      columnId: "water_destination_type_name",
      columnHeader: "Destination type",
    },
    {
      columnKey: "usage",
      columnId: "usage",
      columnHeader: "Usage",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e (Ton)",
    },
    {
      columnKey: "month",
      columnId: "month",
      columnHeader: "Month",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WaterConsumptionColumns = [
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "water_source_stress_type_name",
      columnId: "water_source_stress_type_name",
      columnHeader: "Stress type",
    },
    {
      columnKey: "water_source_type_name",
      columnId: "water_source_type_name",
      columnHeader: "Source type",
    },
    {
      columnKey: "usage",
      columnId: "usage",
      columnHeader: "Usage",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e (Ton)",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const RefrigerantsColumns = [
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "gas_type_name",
      columnId: "gas_type_name",
      columnHeader: "Gas Type",
    },
    {
      columnKey: "refrigerant_name",
      columnId: "refrigerant_name",
      columnHeader: "Refrigerant",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e (Ton)",
    },
    {
      columnKey: "month",
      columnId: "month",
      columnHeader: "Month",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WasteCombustionColumns = [
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "waste_type_name",
      columnId: "waste_type_name",
      columnHeader: "Waste Type",
    },
    {
      columnKey: "waste_disposal_location_name",
      columnId: "waste_disposal_location_name",
      columnHeader: "Waste disposal location",
    },
    {
      columnKey: "waste_disposal_operation_name",
      columnId: "waste_disposal_operation_name",
      columnHeader: "Waste disposal operation",
    },
    {
      columnKey: "waste_disposal_option_name",
      columnId: "waste_disposal_option_name",
      columnHeader: "Waste disposal option",
    },
    {
      columnKey: "waste_hazard_option_name",
      columnId: "waste_hazard_option_name",
      columnHeader: "Waste hazard option",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "usage",
      columnId: "usage",
      columnHeader: "Usage",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e (Ton)",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const DevelopmentTrainingColumns = [
    {
      columnKey: "objective",
      columnId: "objective",
      columnHeader: "Objective",
    },
    {
      columnKey: "attended",
      columnId: "attended",
      columnHeader: "No of Attendee",
    },
    {
      columnKey: "hours",
      columnId: "hours",
      columnHeader: "No of Hours",
    },
    {
      columnKey: "content",
      columnId: "content",
      columnHeader: "Content",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const EmployeeHealthDetailsColumns = [
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "date",
      columnId: "date",
      columnHeader: "Date",
    },
    {
      columnKey: "type_of_incident",
      columnId: "type_of_incident",
      columnHeader: "Type of incident",
    },
    {
      columnKey: "affected",
      columnId: "affected",
      columnHeader: "Number of Affected",
    },
    {
      columnKey: "severity_of_incident",
      columnId: "severity_of_incident",
      columnHeader: "Severity of Incident",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WorkerSafetyTrainingColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "date",
      columnId: "date",
      columnHeader: "Training Date",
    },
    {
      columnKey: "training_method",
      columnId: "training_method",
      columnHeader: "Training Methods",
    },
    {
      columnKey: "attended",
      columnId: "attended",
      columnHeader: "Number of Attendees",
    },
    {
      columnKey: "number_of_hours",
      columnId: "number_of_hours",
      columnHeader: "Number of Hours",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const EmployeeDescriminationIncidentColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "date",
      columnId: "date",
      columnHeader: "Date",
    },
    {
      columnKey: "status_of_incident",
      columnId: "status_of_incident",
      columnHeader: "Status of Incident",
    },
    {
      columnKey: "types_of_discrimination",
      columnId: "types_of_discrimination",
      columnHeader: "Type of Discrimination",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const SupplierHumanRightsTrainigColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "supplier_name",
      columnId: "supplier_name",
      columnHeader: "Supplier Name",
    },
    {
      columnKey: "risk_of_child_labor",
      columnId: "risk_of_child_labor",
      columnHeader: "Risk of Child Labour",
    },
    {
      columnKey: "risk_of_compulsory_labor",
      columnId: "risk_of_compulsory_labor",
      columnHeader: "Risk of Forced or Compulsory Labour",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const SocialHumanRightsTrainigColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "risk_of_child_labor",
      columnId: "risk_of_child_labor",
      columnHeader: "Risk of Child Labour",
    },
    {
      columnKey: "risk_of_compulsory_labor",
      columnId: "risk_of_compulsory_labor",
      columnHeader: "Risk of Forced or Compulsory Labour",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const SupplierScreeningColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "suppliers_screened_using_env_criteria",
      columnId: "suppliers_screened_using_env_criteria",
      columnHeader: "Suppliers screened using Environmental Criteria",
    },
    {
      columnKey: "suppliers_screened_using_social_criteria",
      columnId: "suppliers_screened_using_social_criteria",
      columnHeader: "Suppliers screened using Social Criteria",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const LocalCommunitiesColumns = [
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "local_community",
      columnId: "local_community",
      columnHeader: "Name of local community",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Total Contributions",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const PoliticalContributionColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "any_political_contribution",
      columnId: "any_political_contribution",
      columnHeader: "Political Contributions",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Total Amount",
    },
    {
      columnKey: "beneficiary_of_contribution",
      columnId: "beneficiary_of_contribution",
      columnHeader: "Beneficiary",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const AntiCorruptionDisclosureColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "is_organisation_accessed_for_corruption",
      columnId: "is_organisation_accessed_for_corruption",
      columnHeader: "Is Organization assessed for risk of corruption",
    },
    {
      columnKey: "number_of_operation",
      columnId: "number_of_operation",
      columnHeader: "Total Number of operations assessed",
    },
    {
      columnKey: "number_of_incident",
      columnId: "number_of_incident",
      columnHeader: "Number of incidents Reported",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const AntiCorruptionTrainigColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "attended",
      columnId: "attended",
      columnHeader: "Number of Attendees",
    },
    {
      columnKey: "training_date",
      columnId: "training_date",
      columnHeader: "Training date",
    },
    {
      columnKey: "training_method",
      columnId: "training_method",
      columnHeader: "Training Methods",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const AntiCompetitiveDisclosureColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "number_of_legal_action",
      columnId: "number_of_legal_action",
      columnHeader: "Number of Legal Actions",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const FinancialAssistanceColumns = [
    {
      columnKey: "assessment_period",
      columnId: "assessment_period",
      columnHeader: "Period",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "financial_assistant_received",
      columnId: "financial_assistant_received",
      columnHeader: "Total Financial Assistance received",
    },
    {
      columnKey: "type_financial_assistant",
      columnId: "type_financial_assistant",
      columnHeader: "Type of Assistance",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const UploadFileEmissionColumns = [
    {
      columnKey: "content",
      columnId: "content",
      columnHeader: "Content",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const EmployeeHireAndTurnover = [
    {
        columnKey: 'assessment_period',
        columnId: 'assessment_period',
        columnHeader: 'Period',
    },{
        columnKey: 'year',
        columnId: 'year',
        columnHeader: 'Year',
    }, {
        columnKey: 'gender',
        columnId: 'gender',
        columnHeader: 'Gender',
    }, {
        columnKey: 'facility',
        columnId: 'facility',
        columnHeader: 'Facility',
    }, {
        columnKey: 'employee_classification',
        columnId: 'employee_classification',
        columnHeader: 'Employee Classification',
    }, {
        columnKey: 'resigned',
        columnId: 'resigned',
        columnHeader: 'Resigned',
    }, {
        columnKey: 'hired',
        columnId: 'hired',
        columnHeader: 'Hired',
    }, {
        columnKey: 'turnover_rate',
        columnId: 'turnover_rate',
        columnHeader: 'Turnover Rate',
    }, {
        columnKey: 'status',
        columnId: 'status',
        columnHeader: 'Status',
    },
    {
      columnKey: 'action',
      columnId: 'action',
      columnHeader: '',
  }
  ]
  
  export const EmployeeBenefits = [
    {
        columnKey: 'assessment_period',
        columnId: 'assessment_period',
        columnHeader: 'Period',
    },{
        columnKey: 'year',
        columnId: 'year',
        columnHeader: 'Year',
    }, {
        columnKey: 'facility_name',
        columnId: 'facility_name',
        columnHeader: 'Facility',
    }, {
        columnKey: 'employees_classification',
        columnId: 'employees_classification',
        columnHeader: 'Employee Classification',
    }, {
        columnKey: 'benefit_provided',
        columnId: 'benefit_provided',
        columnHeader: 'Benefits Provided',
    }, {
        columnKey: 'number_of_employees',
        columnId: 'number_of_employees',
        columnHeader: 'Number of Employees',
    }, {
        columnKey: 'status',
        columnId: 'status',
        columnHeader: 'Status',
    }, {
      columnKey: 'action',
      columnId: 'action',
      columnHeader: '',
  }
  ]
  
  export const TrainingHoursPerEmployee = [
    {
        columnKey: 'period',
        columnId: 'period',
        columnHeader: 'Period',
    },{
        columnKey: 'year',
        columnId: 'year',
        columnHeader: 'Year',
    }, {
        columnKey: 'facility',
        columnId: 'facility',
        columnHeader: 'Facility',
    }, {
        columnKey: 'date',
        columnId: 'date',
        columnHeader: 'Date',
    },{
        columnKey: 'total_employees_training_percentage',
        columnId: 'total_employees_training_percentage',
        columnHeader: 'Average Training Hours per Employee',
    },
    {
      columnKey: 'female_employees_training_percentage',
      columnId: 'female_employees_training_percentage',
      columnHeader: 'Average Training Hours per Female Employee',
  },
  {
    columnKey: 'male_employees_training_percentage',
    columnId: 'male_employees_training_percentage',
    columnHeader: 'Average Training Hours per Male Employee',
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
  ]
  
  export const PerformanceAndCareerDevelopmentProgramsColumns = [
    {
        columnKey: 'period',
        columnId: 'period',
        columnHeader: 'Period',
    }, {
        columnKey: 'facility',
        columnId: 'facility',
        columnHeader: 'Facility',
    },{
      columnKey: 'year',
      columnId: 'year',
      columnHeader: 'Year',
    },{
        columnKey: 'program_type',
        columnId: 'program_type',
        columnHeader: 'Type of programs',
    },{
        columnKey: 'employees_participation_percentage',
        columnId: 'employees_participation_percentage',
        columnHeader: 'Percentage of employees participated',
    },{
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
    },{
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
    },
  ]
  
  export const EconomicImpactColumns = [
    {
        columnKey: 'assessment_period',
        columnId: 'assessment_period',
        columnHeader: 'Period',
    }, {
        columnKey: 'facility_name',
        columnId: 'facility_name',
        columnHeader: 'Facility',
    },{
      columnKey: 'year',
      columnId: 'year',
      columnHeader: 'Year',
    },{
        columnKey: 'any_significant_infrastructure',
        columnId: 'any_significant_infrastructure',
        columnHeader: 'Infrastructure Investment',
    },{
        columnKey: 'nature_of_investments',
        columnId: 'nature_of_investments',
        columnHeader: 'Nature of Investment',
    },{
        columnKey: "status",
        columnId: "status",
        columnHeader: "Status",
    },{
        columnKey: "action",
        columnId: "action",
        columnHeader: "",
    },
  ]
  
  export const BoardDiversityByGenderColumns = [
    {
        columnKey: 'assessment_period',
        columnId: 'assessment_period',
        columnHeader: 'Period',
        width : '15vw',
    }, {
        columnKey: 'facility_name',
        columnId: 'facility_name',
        columnHeader: 'Facility',
        width : '15vw',
    },{
      columnKey: 'year',
      columnId: 'year',
      columnHeader: 'Year',
      width : '15vw',
    },{
        columnKey: 'male',
        columnId: 'male',
        columnHeader: '% of Board Members identifying as Male',
        width : '20vw',
    },{
        columnKey: 'female',
        columnId: 'female',
        columnHeader: '% of Board Members identifying as Female',
        width : '20vw',
    },
    {
      columnKey: 'other',
      columnId: 'other',
      columnHeader: '% of Board Members identifying as Others',
      width : '20vw',
  },
    {
        columnKey: "status",
        columnId: "status",
        columnHeader: "Status",
        width : '15vw',
    },{
        columnKey: "action",
        columnId: "action",
        columnHeader: "",
    },
  ]
  
  export const BoardDiversityByAgeGroupColumns = [
    {
        columnKey: 'assessment_period',
        columnId: 'assessment_period',
        columnHeader: 'Period',
        width : '15vw',
    }, {
        columnKey: 'facility_name',
        columnId: 'facility_name',
        columnHeader: 'Facility',
        width : '15vw',
    },{
      columnKey: 'year',
      columnId: 'year',
      columnHeader: 'Year',
      width : '15vw',
    },{
      columnKey: 'under_30',
      columnId: 'under_30',
      columnHeader: '% of Board Members under 30 years old',
      width : '20vw',
  },{
      columnKey: 'between_30_50',
      columnId: 'between_30_50',
      columnHeader: '% of Board Members under 30-50 years old',
      width : '20vw',
  },
  {
    columnKey: 'over_50',
    columnId: 'over_50',
    columnHeader: '% of Board Members over 50 years old',
    width : '20vw',
  },{
        columnKey: "status",
        columnId: "status",
        columnHeader: "Status",
        width : '15vw',
    },{
        columnKey: "action",
        columnId: "action",
        columnHeader: "",
    },
  ]
  export const ManagementDiversityByGenderColumns = [
    {
      columnKey: 'assessment_period',
      columnId: 'assessment_period',
      columnHeader: 'Period',
      width : '15vw',
  }, {
      columnKey: 'facility_name',
      columnId: 'facility_name',
      columnHeader: 'Facility',
      width : '15vw',
  },{
    columnKey: 'year',
    columnId: 'year',
    columnHeader: 'Year',
    width : '15vw',
  },{
      columnKey: 'male',
      columnId: 'male',
      columnHeader: '% of Board Members identifying as Male',
      width : '20vw',
  },{
      columnKey: 'female',
      columnId: 'female',
      columnHeader: '% of Board Members identifying as Female',
      width : '20vw',
  },
  {
    columnKey: 'other',
    columnId: 'other',
    columnHeader: '% of Board Members identifying as Others',
    width : '20vw',
  },
  {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      width : '15vw',
  },{
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
      // width : '5vw',
  },
  ]
  
  export const ManagementDiversityByAgeGroupColumns = [
    {
      columnKey: 'assessment_period',
      columnId: 'assessment_period',
      columnHeader: 'Period',
      width : '15vw',
  }, {
      columnKey: 'facility_name',
      columnId: 'facility_name',
      columnHeader: 'Facility',
      width : '15vw',
  },{
    columnKey: 'year',
    columnId: 'year',
    columnHeader: 'Year',
    width : '15vw',
  },{
    columnKey: 'under_30',
    columnId: 'under_30',
    columnHeader: '% of Board Members under 30 years old',
    width : '20vw',
  },{
    columnKey: 'between_30_50',
    columnId: 'between_30_50',
    columnHeader: '% of Board Members under 30-50 years old',
    width : '20vw',
  },
  {
  columnKey: 'over_50',
  columnId: 'over_50',
  columnHeader: '% of Board Members over 50 years old',
  width : '20vw',
  },{
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      width : '15vw',
  },{
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
      // width : '5vw',
  },
  ]
  
  
  export const stakeholderSectionConfig = {
    stationary_combustion: {
      title: "Stationary Combustion",
      headers: [
        "Amount",
        "Fuel",
        "Facility Name",
        "Year",
        "CO2",
        "N2O",
        "CH4",
        "Bio Fuel",
        "EF",
      ],
      keys: [
        "amount",
        "fuel",
        "facility",
        "year",
        "co2",
        "n2o",
        "ch4",
        "biofuel_co2",
        "ef",
      ],
    },
    mobile_combustion: {
      title: "Mobile Combustion",
      headers: [
        "Fuel",
        "Amount",
        "Facility Name",
        "Year",
        "CO2",
        "CH4",
        "N2O",
        "CO2e",
        "Bio Fuel",
        "EF",
      ],
      keys: [
        "fuel_source",
        "amount",
        "facility",
        "year",
        "co2",
        "ch4",
        "n2o",
        "co2e",
        "biofuel_co2",
        "ef",
      ],
    },
    refrigerants: {
      title: "Refrigerants",
      headers: [
        "Facility Name",
        "Amount",
        "Refrigerant",
        "Co2E",
        "Year",
      ],
      keys: [
        "facility",
        "amount",
        "refrigerant_name",
        "co2e",
        "year",
      ],
    },
    purchased_electricity: {
      title: "Purchased Electricity",
      headers: [
        "Facility Name",
        "Year",
        "Amount",
        "Calculation Approach",
        "CO2",
        "N2O",
        "CH4",
        "Co2E",
        "BioFuel CO2",
        "EF"
      ],
      keys: [
        "facility",
        "year",
        "amount",
        "calculation_approach",
        "co2",
        "n2o",
        "ch4",
        "co2e",
        "biofuel_co2",
        "ef"
      ],
    },
    transportation: {
      title: "Transportation",
      headers: [
        "Amount",
        "Facility Name",
        "Vehicle Type",
        "Year",
        "CO2",
        "CH4",
        "N2O",
        "Co2E",
        "BioFuel CO2",
        "EF",
      ],
      keys: [
        "amount",
        "facility",
        "vehicle_type_name",
        "year",
        "co2",
        "ch4",
        "n2o",
        "co2e",
        "biofuel_co2",
        "ef",
      ],
    },
    water_consumption: {
      title: "Water Consumption",
      headers: [
        "Source Name",
        "Year",
        "Facility",
        "Co2E",
        "Amount",
      ],
      keys: [
        "water_source_name",
        "year",
        "facility",
        "co2e",
        "amount",
      ],
    },
    waste: {
      title: "Waste",
      headers: [
        "Facility",
        "Amount",
        "Waste Type",
        "Year",
        "Co2E",
      ],
      keys: [
        "facility",
        "amount",
        "waste_type_name",
        "year",
        "co2e",
      ],
    },
    water_discharge: {
      title: "Water Discharge",
      headers: [
        "Destination Name",
        "Year",
        "Facility",
        "Co2E",
        "Amount",
      ],
      keys: [
        "water_destination_name",
        "year",
        "facility",
        "co2e",
        "amount",
      ],
    },
    employees_turnover: {
      title: "Employees Turnover",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
    employees_hire_and_turnover: {
      title: "Employee Hire & Turnover",
      headers: ["Period", "Year", "Gender", "Facility", "Employee Classification", "Resigned", "Hired", "Turnover Rate", "Status"],
      keys: ["period", "year", "gender", "facility", "employee_classification", "resigned", "hired", "turnover_rate", "status"],
    },
    employees_benefit: {
      title: "Employee Benefits",
      headers: ["Period", "Year", "Facility", "Employee Classification", "Benefits Provided", "Number of Employees", "Status"],
      keys: ["period", "year", "facility", "employee_classification", "benefits_provided", "no_of_employees", "status"],
    },
    development_training: {
      title: "Development Training",
      headers: [
        "Content",
        "Attended",
        "Hours",
        "Objective",
        "Year",
        "Month",
        "Status",
      ],
      keys: [
        "content",
        "attended",
        "hours",
        "objective",
        "year",
        "month",
        "status",
      ],
    },
    employee_health_safety_incident_record: {
      title: "Employee Health Safety Incident Record",
      headers: [
        "Department",
        "Affected",
        "Corrective Action",
        "Details",
        "Facility Name",
        "Year",
        "Month",
        "Status",
      ],
      keys: [
        "department",
        "affected",
        "corrective_action",
        "details",
        "facility_name",
        "year",
        "month",
        "status",
      ],
    },
    worker_safety_training_procedures: {
      title: "Working Safety Training Procedures",
      headers: ["Content", "Attended", "Objective", "Year", "Month", "Status"],
      keys: ["content", "attended", "objective", "year", "month", "status"],
    },
    age_based_statistics: {
      title: "Age Based Statistics",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
    gender_based_statistics: {
      title: "Gender Age Based Statistics",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
    discrimination_incident_record: {
      title: "Discrimination Incident Record",
      headers: [
        "Department",
        "Affected",
        "Corrective Action",
        "Details",
        "Facility",
        "Year",
        "Month",
        "Status",
      ],
      keys: [
        "department",
        "affected",
        "corrective_action",
        "details",
        "facility_name",
        "year",
        "month",
        "status",
      ],
    },
    supplier_screening: {
      title: "Supplier Screening",
      headers: ["Details", "Supplier", "Year", "Month"],
      keys: ["details", "supplier", "year", "month"],
    },
    operational_human_rights_training: {
      title: "Operational Human Rights Training",
      headers: ["Content", "Attended", "Objective", "Year", "Month", "Status"],
      keys: ["content", "attended", "objective", "year", "month", "status"],
    },
    social_engagement_human_rights_training: {
      title: "Social Human Rights Training",
      headers: ["Content", "Attended", "Objective", "Year", "Month", "Status"],
      keys: ["content", "attended", "objective", "year", "month", "status"],
    },
    local_communities: {
      title: "Local Communities",
      headers: [
        "Details",
        "Department",
        "Corrective Action",
        "Facility Name",
        "Year",
        "Month",
        "Status",
      ],
      keys: [
        "details",
        "department",
        "corrective_action",
        "facility_name",
        "year",
        "month",
        "status",
      ],
    },
    political_contributions: {
      title: "Political Contributions",
      headers: ["Amount", "Recipient", "Details", "Year", "Month", "Status"],
      keys: ["amount", "recipient", "details", "year", "month", "status"],
    },
    board_diversity: {
      title: "Board Diversity",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
    management_diversity: {
      title: "Management Diversity",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
    anti_corruption_disclosure: {
      title: "Anti Corruption Dislosure",
      headers: [
        "Details",
        "Corrective Action",
        "Recipient",
        "Facility Name",
        "Year",
        "Month",
        "Status",
      ],
      keys: [
        "details",
        "corrective_action",
        "recipient",
        "facility_name",
        "year",
        "month",
        "status",
      ],
    },
    anti_corruption_training: {
      title: "Anti Corruption Training",
      headers: ["Content", "Attended", "Objective", "Year", "Month", "Status"],
      keys: ["content", "attended", "objective", "year", "month", "status"],
    },
    anti_competitive_disclosure: {
      title: "Anti Competitive Dislosure",
      headers: [
        "Details",
        "Corrective Action",
        "Affected",
        "Facility Name",
        "Year",
        "Month",
        "Status",
      ],
      keys: [
        "details",
        "corrective_action",
        "affected_party",
        "facility_name",
        "year",
        "month",
        "status",
      ],
    },
    subsidies_financial_assistance: {
      title: "Subsidies Financial Assistance",
      headers: ["Details", "Amount", "Donor", "Year", "Month", "Status"],
      keys: ["details", "amount", "donor", "year", "month", "status"],
    },
    economic_impact: {
      title: "Economic Impact",
      headers: ["Period", "Facility", "Year", "Infrastructure Investment", "Nature of Investment", "Status"],
      keys: ["assessment_period", "facility_name", "year", "any_significant_infrastructure", "nature_of_investments", "status"],
    },
    direct_economic_impact: {
      title: "Direct Economic Impact",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
    indirect_economic_impact: {
      title: "Indirect Economic Impact",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
    tax: {
      title: "Tax",
      headers: ["Content", "Date", "Year", "Month", "Status"],
      keys: ["content", "date", "year", "month", "status"],
    },
  };
  
  export const topicNames = [
    "stationary_combustion",
    "mobile_combustion",
    "refrigerants",
    "purchased_electricity",
    "transportation",
    "water_consumption",
    "water_discharge",
    "waste",
    "employees_turnover",
    "employees_hire_and_turnover",
    "employees_benefit",
    "development_training",
    "employee_health_safety_incident_record",
    "worker_safety_training_procedures",
    "age_based_statistics",
    "gender_based_statistics",
    "employees_diversity_and_ratio_of_basic_salary",
    "discrimination_incident_record",
    "supplier_screening_using_enviormental_social_criteria",
    "supplier_screening",
    "operational_human_rights_training",
    "suppliers_human_rights",
    "social_engagement_human_rights_training",
    "local_communities",
    "political_contributions",
    "board_diversity",
    "management_diversity",
    "anti_corruption_disclosure",
    "anti_corruption_training",
    "anti_competitive_disclosure",
    "subsidies_financial_assistance",
    "direct_economic_impact",
    "indirect_economic_impact",
    "tax",
  ];
  