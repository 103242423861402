import React from "react";
import { Container, Grid } from "@mui/material";
import dayjs from "dayjs";

import CeroInfoPair from "../../../../components/CeroInfoPair";
import useStyles from "./styles";

const Status = (props) => {
  const classes = useStyles();
console.log(props?.auditOverallStatus?.toLowerCase())
  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CeroInfoPair
            title="Status"
            value={props.auditOverallStatus}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes[props?.auditOverallStatus?.toLowerCase() === "in progress" ? "inprogress" : props?.auditOverallStatus?.toLowerCase()
                || "default"],
            }}
          />
          <CeroInfoPair
            title="Audit requested by"
            value={props.details.assigned_by_name}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroInfoPair
            title="Audit request on"
            value={
              props.details.audited_req_on
                ? dayjs(props.details.audited_req_on).format("DD/MM/YYYY")
                : "NA"
            }
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroInfoPair
            title="Audited by"
            value={props.details.audited_by_name}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
        </Grid>
        <Grid item xs={6}>
          <CeroInfoPair
            title="Audited on"
            value={
              props.details.audited_on
                ? dayjs(props.details.assigned_on).format("DD/MM/YYYY")
                : ""
            }
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroInfoPair
            title="Audit Cycle"
            value={props.details.audit_cycle?.split("_").join(" ")}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroInfoPair
            title="Period"
            value={props.details.period}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroInfoPair
            title="Number of tickets "
            value={props.details.total_tickets}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Status;
