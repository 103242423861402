import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
    marginTop: theme.spacing(5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  audited: {
    color: theme.palette.Success.main,
    fontWeight: 500,
    fontSize: 14,
  },
  pending: {
    color: theme.palette.status.open,
    fontWeight: 500,
    fontSize: 14,
  },
  default: {
    color: theme.palette.Secondary.main,
    fontWeight: 500,
    fontSize: 14,
  },
  approved: {
    color: theme.palette.icon.blue,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    fontSize: 14,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusText: {
    fontSize: 14,
  },
  button: {
    fontWeight: "600",
    fontSize: "13px",
    textTransform: "capitalize",
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.light,
    },
  },
  headerButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  switchColor: {
    height:'45px',
    width:"66px",
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: theme.palette.status.closed,
      opacity: 1.5,
      borderRadius : '10px'
    },
    "& .MuiSwitch-switchBase > .MuiSwitch-thumb": {
      height: "25px",
      width: "25px"
    },
  },
  switchColorInactive: {
    height:'45px',
    width:"66px",
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "red",
      opacity: 1,
      borderRadius : '10px'
    },
    "& .MuiSwitch-switchBase > .MuiSwitch-thumb": {
      height: "25px",
      width: "25px"
    },
  },
  inactiveSwitchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "grey",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "grey",
      opacity: 1.5,
      borderRadius : '10px'
    },
  },
  checkboxContainer:{
    display: "flex",
    alignItems: "center",
  },
  checkbox:{
    color: "#8C2148 !important",
    borderWidth: 1    
  },
  topic: {
    fontSize: 14,
  },
  Active:{
    fontSize: 14,
    color:theme.palette.status.closed,
    fontWeight: "bold"
  },
  Inactive:{
    fontSize: 14,
    color:"red",
    fontWeight: "bold"
  }
}));

export default useStyles;
