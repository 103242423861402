import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "calc(100% - 20px)",
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  questionsListContainer: {
    height: "calc(100% - 40px)",
    overflow: "auto",
  },
  accordionHeader: {
    background: "rgba(90, 64, 78, 0.26)",
  },
  secondaryHeader: {
    background: theme.palette.Secondary.background,
    color: theme.palette.text.secondary,
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    backgroundColor:theme.palette.Primary.contrastText,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
},
title:{
  textTransform: "capitalize",
  color: theme.palette.Primary.main,
  fontSize: 16
},
icon:{
  color: theme.palette.Primary.main,
}
}));

export default useStyles;
