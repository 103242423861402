import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import CeroButton from '../../../../components/CeroButton';
import useStyles from './styles';
import CeroInput from '../../../../components/CeroInput';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { sendQuestionnaires } from '../../../../redux/actions';
import { STATUS } from '../../../../redux/constants';

const validationSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email format').required('Email is required'),
    })
  ),
});

const SendQuestions = ({ setOpen,sendQuestionnairesStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([{ name: '', email: '' }]);

  const formik = useFormik({
    initialValues: {
      users: fields,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        users: values.users,
      };
      dispatch(sendQuestionnaires(payload));
    },
  });

  
  const handleAddMore = () => {
    setFields([...fields, { name: '', email: '' }]);
    formik.setFieldValue('users', [...formik.values.users, { name: '', email: '' }]);
  };

  const canAddMore = formik.values.users[0]?.name !== '' && formik.values.users[0]?.email !== '' && formik.values.users.length < 5;

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (sendQuestionnairesStatus === STATUS.SUCCESS) {
      formik.resetForm();
    }
  }, [sendQuestionnairesStatus]);

  const handleCancel = () => {
    setOpen(false)
    formik.resetForm(); 
  };

  return (
    <Box className={classes.popUp}>
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        Send Questionnaire to Stakeholders
      </Typography>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <FieldArray name="users">
            {({ remove }) => (
              <Grid display={"flex"} flexDirection="column" mt={4} gap={2} justifyContent="flex-start">
                <Typography display={"flex"} fontSize={14} fontWeight={500} variant="h3">
                      Enter Name & Email ID:
                    </Typography>
                {formik.values.users.map((stakeholder, index) => (
                  <Box key={index} display={"flex"} flexDirection="column" gap={1}>
                    <Grid container spacing={2} display={"flex"}>
                      <Grid item xs={4}>
                      <CeroInput
                        required
                        id={`name-${index}`}
                        name={`users[${index}].name`}
                        label="Name"
                        value={formik.values.users[index].name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.users && formik.touched.users[index]?.name && Boolean(formik.errors.users && formik.errors.users[index]?.name)}
                        helperText={formik.touched.users && formik.touched.users[index]?.name && formik.errors.users && formik.errors.users[index]?.name}
                      />
                      </Grid>
                      <Grid item xs={8}>
                      <CeroInput
                        required
                        id={`email-${index}`}
                        name={`users[${index}].email`}
                        label="Email"
                        value={formik.values.users[index].email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.users && formik.touched.users[index]?.email && Boolean(formik.errors.users && formik.errors.users[index]?.email)}
                        helperText={formik.touched.users && formik.touched.users[index]?.email && formik.errors.users && formik.errors.users[index]?.email}
                      />
                      </Grid>
                      </Grid>
                  </Box>
                ))}
                <Box
                  display="flex"
                  paddingY={2}
                  paddingLeft={2}
                  gap={1}
                  onClick={canAddMore ? handleAddMore : null}
                  style={{ cursor: canAddMore ? 'pointer' : 'not-allowed', color: canAddMore ? 'blue' : 'gray' }}
                >
                  <AddIcon />
                  <Typography>Add More</Typography>
                </Box>
              </Grid>
            )}
          </FieldArray>
          <div className={classes.buttonContainerReview}>
            <CeroButton
              className={classes.buttonPrimary}
              variant="contained"
              type="submit"
              buttonText="Send Email"
              onClick={handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}
            />
            <CeroButton
              className={classes.buttonSecondary}
              variant="outlined"
              onClick={handleCancel} 
              buttonText="Cancel"
            />
          </div>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default SendQuestions;
