import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { STATUS } from "../../../redux/constants";
import {
  schemeValidation,
} from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updateAntiCompetitiveDisclosure,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import CeroSelect from "../../../components/CeroSelect";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { sampleYear } from "../../../constants";
import CeroAutoComplete from "../../../components/CeroAutoComplete";

const EditAntiCompetitiveDisclosureForm = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const updateEmissionData = useSelector(
    (state) => state.emission.updateAntiCompetitiveDisclosure
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || "",
      assessmentPeriod: emissionData.assessment_period || "",
      legalActions: emissionData.number_of_legal_action || "",
      description: emissionData.outcomes_of_legal_action || "",
      correctiveAction: emissionData.corrective_action || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'anti_competitive_disclosure', emissionData.id));
    }
  }, [formik.values, dispatch]);

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Anti competitive disclosure updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message || "Something went wrong",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateAntiCorruptionDisclosure = () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      number_of_legal_action: formik.values.legalActions,
      outcomes_of_legal_action: formik.values.description,
      corrective_action: formik.values.correctiveAction,
      save: true,
    };
    dispatch(updateAntiCompetitiveDisclosure(requestData));
  };

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Anti Competitive Disclosure
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <Typography variant="body1">
              Enter the number of legal actions pending or completed during the
              reporting period regarding anti-competitive behaviour and
              violations of anti-trust and monopoly legislation in which the
              organization has been identified as a participant.
            </Typography>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroInput
                  required
                  id="legalActions"
                  name="legalActions"
                  label="Number of legal actions"
                  value={formik.values.legalActions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.legalActions && formik.errors.legalActions
                  }
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}></Grid>
              <Grid item container direction="column" md={6} xs={12}></Grid>
            </Grid>
            <CeroInput
              required
              id="description"
              name="description"
              label="Describe the main outcomes of completed legal actions, including any decisions or judgments."
              fullWidth
              multiline
              rows="2"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && formik.errors.description}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="correctiveAction"
              name="correctiveAction"
              label="Corrective Action"
              fullWidth
              multiline
              rows="2"
              value={formik.values.correctiveAction}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.correctiveAction &&
                formik.errors.correctiveAction
              }
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
          <Box className={classes.buttonContainer}>
            <CeroButton
              buttonText="Update"
              disabled={!formik.dirty || !formik.isValid}
              className={clsx(classes.button, classes.buttonPrimary)}
              onClick={onUpdateAntiCorruptionDisclosure}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={clsx(classes.button, classes.buttonSecondary)}
              onClick={formik.resetForm}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default EditAntiCompetitiveDisclosureForm;
