import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from "@mui/material";
import dayjs from 'dayjs';

import CeroTable from '../../../components/CeroTable';
import useStyles from "./styles";

export const TableColumns = [{
    columnKey: 'assessment_year',
    columnId: 'assessment_year',
    columnHeader: 'Year',
}, {
    columnKey: 'country',
    columnId: 'country',
    columnHeader: 'Country',
}, {
    columnKey: 'audit_cycle',
    columnId: 'aucit_cycle',
    columnHeader: 'Audit Cycle',
}, {
    columnKey: 'period',
    columnId: 'period',
    columnHeader: 'Period',
},{
    columnKey: 'audited_by',
    columnId: 'audited_by',
    columnHeader: 'Audited by',
}, {
    columnKey: 'audited_on',
    columnId: 'audited_on',
    columnHeader: 'Audited on',
}, 
// {
//     columnKey: 'assigned_by',
//     columnId: 'assigned_by',
//     columnHeader: 'Assigned by',
// }, {
//     columnKey: 'assigned_to',
//     columnId: 'assigned_to',
//     columnHeader: 'Assigned to',
// }, {
//     columnKey: 'assigned_on',
//     columnId: 'assigned_on',
//     columnHeader: 'Assigned on',
// }, 
{
    columnKey: 'audited_status',
    columnId: 'audited_status',
    columnHeader: 'Status',
}]

const AuditHistory = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { auditData, onLoadMore } = props

    const onSelectAuditData = (audit) => {
        navigate(`/companies/${props.companyId}/audit/${audit.audit_status_id}`);
    };

    const getStatus = (status) => {
        if (status === 'approved') {
          return  <Typography className={classes.approved}>Approved</Typography>;
        } else if (status === 'submitted') {
            return  <Typography className={classes.submitted}>Submitted</Typography>;
        } else if (status === 'audited') {
            return <Typography className={classes.audited}>Audited</Typography>;
        } else if (status === 'pending') {
            return <Typography className={classes.pending}>Pending</Typography>;
        }else if (status === 'added') {
            return <Typography className={classes.added}>Added</Typography>;
        }else if (status === 'assigned') {
          return <Typography className={classes.default}>Assigned</Typography>;
        }else if (status === 'verified') {
            return <Typography className={classes.verified}>Verified</Typography>;
        }else if (status === 'review') {
            return <Typography className={classes.review}>Review</Typography>;
        }else if (status === 'in progress') {
            return <Typography className={classes.inprogress}>In progress</Typography>;
        }else {
            return <Typography className={classes.default}>{status}</Typography>;
        } 
      };

    const getAuditList = () => auditData.map((item) => ({
        ...item,
        audited_status: getStatus(item.audited_status),
        audited_on: item.audited_on ? dayjs(item.audited_on).format('DD/MM/YYYY') : '-',
        assigned_on: item.assigned_on ? dayjs(item.assigned_on).format('DD/MM/YYYY') : '-',
        audit_cycle: item.audit_cycle ? item.audit_cycle.split("_").join(" ") : '-',
    }));

    return (
        <CeroTable
            columns={TableColumns}
            data={getAuditList()}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectAuditData}
        />
    )
}

export default AuditHistory;
