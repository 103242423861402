import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CeroButton from '../../../components/CeroButton';
import CeroTable from '../../../components/CeroTable';
import FacilityDeletePrompt from '../FacilityDeletePrompt'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useStyles from "./styles";
import { getCookie } from '../../../services/cookie';

const FacilitiesTable = (props) => {
    const [openDeletePrompt, setOpenDeletePrompt] = useState(false)
    const [deleteFacility, setDeleteFacility] = useState({})
    const role = getCookie('role')
    const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const classes = useStyles();
  // const navigate = useNavigate();

    const columns = [{
        columnKey: 'name',
        columnId: 'name',
        columnHeader: 'Facility Name',
    },{
        columnKey: 'address',
        columnId: 'address',
        columnHeader: 'Address',
    },{
        columnKey: 'phone',
        columnId: 'phone',
        columnHeader: 'Phone Number',
    }, {
        columnKey: 'country_name',
        columnId: 'country_name',
        columnHeader: 'Location',
    }, {
        columnKey: 'action',
        columnId: 'action',
        columnHeader: '',
        align: 'right'
    }]

  const handleDelete = (item) => {
        setOpenDeletePrompt(true)
        setDeleteFacility(item)
    }

    const getEmissionData = () =>
      props.facilitiesData.map((item) => ({
        ...item,
        action: !readOnlyMode && (
          <>
            <div className={classes.actions}>
              <div
                className={classes.linkButton}
                onClick={(e) => {
                  props.onClickEdit(item.id);
                  e.stopPropagation();
                }}
              >
                Edit
                <EditOutlinedIcon className={classes.icon} />
              </div>
              <div
                className={classes.linkButton}
                onClick={(e) => {
                  handleDelete(item);
                  e.stopPropagation();
                }}
              >
                Delete
                <DeleteOutlineOutlinedIcon className={classes.icon} />
              </div>
            </div>
          </>
        ),
      }));

  return (
    <>
      <CeroTable
        columns={columns}
        data={getEmissionData()}
        hasMore={false}
        loading={false}
        loadMore={props.onLoadMore}
        classes={{ tableContainer: classes.tableContainer }}
        onSelectRow={(rowData)=>{console.log(rowData);}}
      />
            <FacilityDeletePrompt facility={deleteFacility} isOpen={openDeletePrompt} setOpenDeletePrompt={setOpenDeletePrompt} />
    </>
    )
}

export default FacilitiesTable;
