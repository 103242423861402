import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import {
  getAccountDetails,
  getStakeholderAccount,
  resetAccountStatus,
  updateAccountDetails,
  userLogout,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import { accountSchema } from "../schema";
import CeroInput from "../../../components/CeroInput";
import CeroButton from "../../../components/CeroButton";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const role = getCookie("role");
  const userInfo = useSelector((state) => state.auth.userInfo);

  const accountData = useSelector((state) => state.account.accountDetails);
  const stakeholderData = useSelector(
    (state) => state.account.stakeholderAccount.data
  );
  const updateAccountData = useSelector(
    (state) => state.account.updateAccountDetails
  );
  const isAdmin = !userInfo.role.includes(rolesEnum.AUDITOR);

  useEffect(() => {
    if (role === "read_only_user") {
      dispatch(getStakeholderAccount("stakeholder"));
    } else {
      dispatch(getAccountDetails(isAdmin ? role === 'super_admin' ? 'super_admin' : "business" : "auditor"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (updateAccountData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Account details updated", { variant: "success" });
      dispatch(resetAccountStatus());
      dispatch(getAccountDetails(isAdmin ? role === 'super_admin' ? 'super_admin' : "business" : "auditor"));
    } else if (updateAccountData.status === STATUS.ERROR) {
      enqueueSnackbar(updateAccountData.message?.message || updateAccountData.message || 'Someting went wrong', { variant: "error" });
      dispatch(resetAccountStatus());
    }
  }, [updateAccountData, dispatch, enqueueSnackbar]);

  const accountForm = useFormik({
    initialValues: {
      name: accountData.data.name || stakeholderData.name || "",
      email: accountData.data.email || stakeholderData.email || "",
    },
    enableReinitialize: true,
    validationSchema: accountSchema,
    onSubmit: (values) => {},
  });

  const logout = () => {
    dispatch(userLogout(userInfo?.email));
  };

  const handleUpdate = () => {
    dispatch(
      updateAccountDetails(
        isAdmin ? "business" : "auditor",
        accountForm.values.name,
        accountForm.values.email
      )
    );
  };

  return (
    <Paper className={classes.container}>
      <CeroInput
        required
        fullWidth
        id="name"
        label="Name"
        autoFocus
        value={accountForm.values.name}
        onChange={accountForm.handleChange}
        onBlur={accountForm.handleBlur}
        error={accountForm.errors.name}
      />
      <CeroInput
        required
        fullWidth
        id="email"
        label="Email address"
        autoFocus
        value={accountForm.values.email}
        onChange={accountForm.handleChange}
        onBlur={accountForm.handleBlur}
        error={accountForm.errors.email}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <div>
          <CeroButton
            variant="outlined"
            buttonText="Change Password"
            onClick={() => navigate("/change-password")}
            className={classes.buttonPrimary}
          />
           <CeroButton
              buttonText="Cancel"
              onClick={() => navigate(-1)}
              className={classes.buttonPrimary}
            />
          {/* {accountForm.dirty ? ( */}
            <CeroButton
              buttonText="Save"
              onClick={handleUpdate}
              disabled={!accountForm.dirty || !accountForm.isValid}
              className={classes.buttonPrimary}
            />
          {/* ) : ( */}
           
          {/* )} */}
        </div>
      </Stack>
    </Paper>
  );
};

export default AccountSettings;
