import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  innerContainer: {
    backgroundColor: theme.palette.background.white,
    width: "100%",
    // height: 'calc(100% - 75px)',
    borderRadius: 14,
    padding: theme.spacing(5, 7),
    marginTop: theme.spacing(5),
    position: "relative",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  header: {
    display:'flex',
    alignItems: 'end',
    flexDirection:"column",
    // justifyContent: 'space-between'
    paddingRight:"10px"
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 15,
    width: "100%"
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: 'center',
    cursor: "pointer"
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 100,
    height: 37,
    fontSize: 13,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Secondary.hoverButton,
    },
    width: 140,
    height: 37,
    fontSize: 13,
    margin: theme.spacing(0, 5),
  },
  buttonTeritiary: {
    width: 140,
    height: 37,
    fontSize: 13,
  },
  infoContainer: {
    marginTop: theme.spacing(3),
    width: "400px !important"
  },
  title: {
      width: '50%'
  }
}));

export default useStyles;
