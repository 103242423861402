import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "99%",
    // height: "100%",
    borderRadius: 8,
    margin: theme.spacing(0, 1, 3, 2),
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 450,
    color: theme.palette.Primary.background,
    padding: theme.spacing(4, 0),
  },
  gridBox:{
    padding: theme.spacing( 0 , 3 , 8 , 3),
  },
  subItemTitle: {
    fontSize: 18,
    fontWeight: 450,
    padding: theme.spacing(4, 0),
  },
  subItemValue: {
    padding: theme.spacing(0, 4, 3, 0),
    fontSize: 16,
    // whiteSpace: "pre-line",
    margin: 0,
  },
  consentText: {
    padding: theme.spacing(2, 0, 4, 0),
    margin: 0,
    fontWeight: 550,
    fontSize: 16,
  },
  letterContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(4),
  },
  available: {
    color: "#0088C7",
  },
  checkbox: {
    color: "#8C2148 !important",
    borderWidth: 0.1,
  },
  active: {},
  dialog: {
    width: "60% !important",
    textAlign: "center",
  },
  popUp: {
    margin: "auto",
    minHeight:150
  },
  popUpRedirect: {
    margin: "auto",
    minHeight:200,
    maxWidth:"50%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent:"center",
    gap:5,
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    backgroundColor: theme.palette.Primary.contrastText,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.contrastText,
    }},
    buttonPrimary: {
      background: theme.palette.Primary.background,
      textTransform: "capitalize",
      height: 35,
      borderColor: theme.palette.Primary.background,
      marginRight: 15,
      fontWeight: 400,
      color: theme.palette.background.white,
      "&:hover": {
        backgroundColor: theme.palette.Primary.hoverButton,
        borderColor: theme.palette.Primary.hoverButton,
        color: theme.palette.Primary.light,
      },
    },
}));

export default useStyles;
