import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableFooter,
  CircularProgress,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  IconButton,
  Stack,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import clsx from "clsx";
import useStyles from "./styles";
import { getCookie } from "../../services/cookie";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CeroCheckboxTable = (props) => {
  const classes = useStyles();
  const tableId = `CeroTable-${props.tableId || "table-id"}`;
  const [checkedRows, setCheckedRows] = useState([]);
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOrder, setSortOrder] = useState("none");
  const [checked, setChecked] = useState({
    approved: null,
    verified: null,
  });

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSelect = (value) => {
    props.onFilterSelect(value);
    handleFilterClose();
  };

  const handleSelectAllCheckbox = () => {
    const filteredData = props.data.filter(
      (rowData) => rowData.display_status !== "review"
    );
    const allSelected = checkedRows.length === filteredData.length;
    if (allSelected) {
      setCheckedRows([]);
    } else {
      const allRowIds = filteredData.map((rowData) => rowData.id);
      setCheckedRows(allRowIds);
    }
  };

  const handleCheckboxChange = (event, id) => {
    event.stopPropagation();
    const isChecked = checkedRows.includes(id);

    if (isChecked) {
      setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
    } else {
      setCheckedRows([...checkedRows, id]);
    }
  };

  const handleSortDataAscending = () => {
    props.setSortDataDescending(false);
    props.setSortDataAscending((state) => !state);
  };

  const handleSortDataDescending = () => {
    props.setSortDataAscending(false);
    props.setSortDataDescending((state) => !state);
  };

  useEffect(() => {
    props.onCheckedRowsChange(checkedRows);
  }, [checkedRows, props.onCheckedRowsChange]);

  const getCheckbox = () => {
    const allSelected = checkedRows.length === props.data.length;
    const selectAllCheckbox = props.showSelectAllCheckbox && (
      <TableCell
        colSpan={props.columns.length + 1}
        className={classes.checkboxCell}
      >
        <div className={classes.headerIcons}>
          {props.showDropdown && (
            <div>
              <span style={{ marginLeft: 5 }}>
                <Checkbox
                  className={classes.checkbox}
                  onChange={handleSelectAllCheckbox}
                  checked={checkedRows.length > 0}
                  indeterminate={!allSelected && checkedRows.length > 0}
                  disabled={readOnlyMode}
                />
              </span>
            </div>
          )}
          {checkedRows.length > 0 ? props.showIcon : ""}
        </div>
      </TableCell>
    );
    return [selectAllCheckbox];
  };


  const handleFilterCheckboxChange = (event) => {
    const { name, checked: isChecked } = event.target;
    const newValue = isChecked ? name.charAt(0).toUpperCase() + name.slice(1) : null;
    setChecked((prevChecked) => {
      const newState = { approved: null, verified: null };
      if (!prevChecked[name]) {
        newState[name] = isChecked;
      }
      return newState;
    });
    handleFilterSelect(newValue);
  };

  const getHeaders = () => [
    <TableCell key="checkbox-header" className={classes.emptyCell}>
      {props.showFilterIcon && (
        <div>
          <IconButton onClick={handleFilterClick}>
            <FilterAltIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleFilterClose}>
            <Typography style={{ fontWeight: 600, marginLeft: 20 }}>Filter Status</Typography>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.approved === true}
                    onChange={handleFilterCheckboxChange}
                    name="approved"
                    className={classes.checkbox}
                  />
                }
                label="Approved"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.verified === true}
                    onChange={handleFilterCheckboxChange}
                    name="verified"
                    className={classes.checkbox}
                  />
                }
                label="Verified"
              />
            </MenuItem>
          </Menu>
        </div>
      )}
    </TableCell>,
    ...props.columns.map((column, index) => {
      const isNumberColumn =
        props.data &&
        props?.data?.some((row) => typeof row[column.columnKey] === "number");

      return (
        <TableCell
          align={isNumberColumn ? "center" : "left"}
          style={{
            width: "auto",
            minWidth: '50px',
            maxWidth: '190px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          }}
          className={clsx(classes.headerCell, column.classes?.header)}
          key={column.columnId || index}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {column.columnHeader.split(/(\(.*?\))/).map((text, index) => (
              <div
                key={index}
                style={{
                  textAlign:
                    isNumberColumn || column.columnKey === "status"
                      ? "center"
                      : "left",
                }}
              >
                {text}
              </div>
            ))}
            {column.columnKey === "status" && props.showSort && (
              <Stack direction="column" spacing={-2} alignItems="center" borderColor={"black"}>
                <ExpandLessIcon style={{color: props.sortDataAscending && "blue"}} onClick={handleSortDataAscending} />
                <ExpandMoreIcon style={{color:props.sortDataDescending && "blue"}} onClick={handleSortDataDescending} />
              </Stack>
            )}
          </div>
        </TableCell>
      );
    }),
  ];

  const getTableRows = () =>
    props?.data?.map((rowData, rowIndex) => {
      const isReviewStatus = rowData.display_status === "review";
      const verifiedStatus = rowData?.status?.props?.title === "Verified";

      return (
        <TableRow
          key={rowData.id}
          className={clsx(
            classes.tableDataRow,
            props.classes?.tableDataRow,
            classes.checkboxSelected,
            props.isSelectable && classes.selectableTableRow
          )}
          onClick={(event) => {
            if (!event.defaultPrevented) {
              props?.onSelectRow
                ? props.onSelectRow(rowData)
                : console.log(rowData);
            }
          }}
        >
          <TableCell className={classes.selectRow}>
            {!isReviewStatus && !verifiedStatus && (
              <Checkbox
                className={classes.checkbox}
                onClick={(event) => handleCheckboxChange(event, rowData.id)}
                checked={checkedRows.includes(rowData.id)}
                disabled={readOnlyMode}
              />
            )}
          </TableCell>
          {props.columns.map((column, columnIndex) => {
            const isNumberColumn =
              typeof rowData[column.columnKey] === "number";

            return (
              <TableCell
                align={isNumberColumn ? "center" : "left"}
                style={{
                  width: "auto",
                }}
                key={`${rowData.id || rowIndex}-${
                  column.columnId || columnIndex
                }`}
                className={clsx(classes.tableBodyCell, column.classes?.column)}
              >
                {rowData[column.columnKey]}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });

  const noTableRows = () => (
    <TableRow>
      <TableCell className={classes.noDataWarning} align="center" colSpan={props.columns.length + 1} >
        <Typography align="center">No data</Typography>
      </TableCell>
    </TableRow>
  );

  const columns = props.columns.map((column) => column.columnHeader);

  return (
    <InfiniteScroll
      dataLength={props?.data?.length}
      hasMore={!!props.hasMore}
      next={props.loadMore}
      scrollableTarget={tableId}
      loader={<></>}
    >
      <TableContainer
        id={tableId}
        className={clsx(classes.tableContainer, props.classes?.tableContainer)}
        component={Paper}
      >
        <Table stickyHeader id="my-table">
          <TableHead>
            {getCheckbox()}
            <TableRow
              className={clsx(classes?.tableHeader, props.classes?.tableHeader)}
            >
              {getHeaders()}
            </TableRow>
          </TableHead>
          <TableBody className={props?.classes?.tableBody}>
            {getTableRows()}
            {!getTableRows()?.length && noTableRows()}
          </TableBody>
          {props.loading && (
            <TableFooter className={classes.tableFooter}>
              <TableRow>
                <TableCell
                  variant="footer"
                  colSpan={props.columns.length}
                  className={classes.footerCell}
                >
                  <CircularProgress
                    thickness={3}
                    size={20}
                    className={classes.progress}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
};

export default CeroCheckboxTable;
