import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography } from '@mui/material';
import _ from 'lodash';
import DashboardLayout from '../../../layouts/DashboardLayout';
import {
  getApprovalMonthlyDetails,
  getApprovalMonthlySummary
} from '../../../redux/actions';
import Header from './Header';
import Status from './Status';
import useStyles from './styles';
import { STATUS } from '../../../redux/constants';
import CeroTable from '../../../components/CeroTable';

const MonthlyFacilityDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const approvalMonthlyData = useSelector(
    (state) => state.approval.approvalMonthlyDetails.data?.emission_list
  );
  const approvalSummaryData = useSelector(
    (state) => state.approval.approvalMonthlySummary.data
  );

  const approvalMonthlyDataStatus = useSelector(
    (state) => state.approval.approvalMonthlyDetails.status
  );

  const cardStatus = useSelector(
    (state) => state.approval.approvalMonthlyDetails.data?.status
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name
  }));

  const { year } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get('month');
  const selectedFacility = queryParams.get('facility');
  const selectedId = queryParams.get('id');
  const period = queryParams.get('period');

  const onSelectData = (row) => {
    if(row.sub_category_type === "environmental"){
    navigate(
      `/approval-status-table/${row.type}${year && `?year=${year}`}${selectedMonth ? `&month=${selectedMonth}` : ''
      }${selectedFacility ? `&facility=${selectedFacility}` : ''}${period ? `&period=${period}` : ''}&redirect_table=${true}`
    );}else{
      navigate(`/approval-status-details/${selectedId}/${row.sub_category_type}/${row.type}${year && `?year=${year}`}${selectedMonth ? `&month=${selectedMonth}` : ''
    }${selectedFacility ? `&facility=${selectedFacility}` : ''}${period ? `&period=${period}` : ''}&redirect_table=${true}`)
    }
  };

  useEffect(() => {
    dispatch(
      getApprovalMonthlyDetails(
        selectedId,
        year,
        selectedMonth,
        selectedFacility
      )
    );
    dispatch(
      getApprovalMonthlySummary(
        selectedId,
        year,
        selectedMonth,
        selectedFacility
      )
    );
  }, [dispatch, selectedId, year, selectedMonth, selectedFacility]);

  const onApplyFilter = (filter) => {
    const currentFilter = encodeURI(
      `?${filter.month ? `&month=${filter.month}` : ''}${filter.facility ? `&facility=${filter.facility}` : ''
      }`
    );
    navigate(`/approval-status/${filter.year}${currentFilter}`);
  };

    const getStatus = (item) => {
      if (item === 'added') {
      return <div className={classes.added}>Added</div>
      }else if(item === 'approved'){
        return <div className={classes.approved}>Approved</div>
      }else if(item === 'audited'){
        return <div className={classes.audited}>Audited</div>
      }else if(item === 'verified'){
        return <div className={classes.verified}>Verified</div>
      }else if(item === 'review'){
        return <div className={classes.review}>Review</div>
      }else {
        return <div className={classes.Inprogress}>In Progress</div>    
      } 
      }

      const approvalTopicsColumn = [
        {
          columnKey: "sector",
          columnId: "sector",
          columnHeader: "Topic",
          width: "50vw"
        },
        {
          columnKey: "status",
          columnId: "status",
          columnHeader: "Status",
          width: "30vw"
        }
      ];
      
    
    const uniqueSubCategoryTypes = Array.isArray(approvalMonthlyData)
    ? [...new Set(approvalMonthlyData.map(item => item.sub_category_type))]
    : [];

      const getApprovalTopicsData = () =>
      approvalMonthlyDataStatus === STATUS.SUCCESS
      ? uniqueSubCategoryTypes.map(subCategoryType => {
          const dataForSubCategory = approvalMonthlyData
            .filter(item => item.sub_category_type === subCategoryType)
            .map(item => ({
              ...item,
              topic: item.sector ? item.sector : '',
              status: item.status ? getStatus(item.status) : '',
            }));
          return { subCategoryType, data: dataForSubCategory };
        })
      : [];
      const formatText = (text) => {
        return text.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
      };
    
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Header
          approveId={selectedId}
          selectedYear={year}
          selectedMonth={selectedMonth}
          selectedFacility={selectedFacility}
          facilitiesList={facilitiesList}
          actions={approvalMonthlyData?.actions}
          statusId={approvalMonthlyData?.monthly_approval_status_id}
        />
          <Status
            status={cardStatus}
            approvedBy={approvalSummaryData.approved_by_name}
            auditStatus={approvalSummaryData.audited_status}
            noOfTickets={approvalSummaryData.open_tickets}
            auditorAssigned={approvalSummaryData.audited_by_name}
            auditorStatus={approvalSummaryData.audited_status}
            actions={approvalMonthlyData?.actions}
            period={approvalSummaryData.period}
            approval_cycle_type={approvalSummaryData.approval_cycle_type}
            audit_cycle_type={approvalSummaryData.audit_cycle_type}
            auditedOn={approvalSummaryData?.audited_on}
            assessmentYear={approvalSummaryData?.assessment_year}
            facility={approvalSummaryData?.facility_name}
          />
        {getApprovalTopicsData().map(({ subCategoryType, data }) => (
          <div key={subCategoryType}>
            <Typography variant='body1' className={classes.tableTitle}>{formatText(subCategoryType)}</Typography>
            <CeroTable
              columns={approvalTopicsColumn}
              data={data}
              hasMore={false}
              loading={false}
              onSelectRow={onSelectData}
              classes={{ tableContainer: classes.tableContainer }}
            />
          </div>
        ))}
      </Container>
    </DashboardLayout>
  );
};

export default MonthlyFacilityDetails;