import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    listContainer: {
        maxWidth: 700,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(6, 0),
    },
    commentContainer: {
        padding: theme.spacing(4, 0),
    },
    commentBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
    },
    textArea: {
        height: "auto!important",
    },
    commentButton: {
        alignSelf: "flex-end",
    },
    buttonPrimary:{
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    },
    commentText:{
        display: "flex", 
        justifyContent: "space-between",
        paddingBottom: theme.spacing(3),
    }
}));

export default useStyles;