import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        height: '100%',
        width: '100%',
        maxWidth: '100%',
    },
    filterContainer: {
        display: 'flex',
        padding: theme.spacing(4),
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
          backgroundColor: theme.palette.background.white,
        },
    },
    topics: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        // padding: theme.spacing(4),
        width: "100%",
        margin: "auto",
    },
    containerBox: {
        boxShadow: "none !important",
        borderRadius: 5,
        height: "fit-content",
        width: "80%",
        margin: "auto",
        zIndex: 1,
        position: 'relative', 
        marginTop: '-5px',
        "& .MuiPaper-root":{
          boxShadow: "none !important"
        }
    },
    headings: {
        fontWeight: 700,
        fontSize: 20,
        paddingTop: theme.spacing(4),
        whiteSpace: "nowrap"
    },
    headingTitle: {
        color: theme.palette.Primary.background,
        fontWeight:600,
    },
    button: {
        width: '100%',
        backgroundColor: `${theme.palette.Primary.background}!important`,
        fontStyle: 'normal',
        fontWeight: '600!important',
        fontSize: '13px!important',
        textTransform: "capitalize"
    },
    buttonSecondary: {
        color: theme.palette.Primary.background,
        borderColor: theme.palette.Primary.background,
        "&:hover": {
          borderColor: theme.palette.Primary.background,
          backgroundColor:theme.palette.Primary.contrastText,
          border:"2px solid"
        },
        marginRight: theme.spacing(5),
    },
    tabs:{
        zIndex: 1,
        position: 'relative', 
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.login.loginButton,
            display: 'none',
          },
          "& .MuiTabs-flexContainer": {
            width: "85%",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
            position: 'relative',
            zIndex: 2,
            borderRadius: 5,
            backgroundColor: "#E0E0E0",
            borderTop: "1px solid #5A404E",
            borderLeft: "1px solid #5A404E",
            borderRight: "1px solid #5A404E",
            '@media screen and (min-width: 1920px) and (min-height: 563px)':{
              width: "60% !important",
            }
          },
          "& .MuiTab-root": {
            color: theme.palette.text.primary, 
          },
         "& .MuiTab-root.Mui-selected":{
            color: `${theme.palette.login.loginButton} !important`,
         }
    },
    tabOption:{
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: 24,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    mainTitle:{
        padding: 0,
        margin: theme.spacing(0, 0, 10, 20),
    },
    headingText: {
        position: 'relative',
      },
      underline: {
        position: 'absolute',
        bottom: theme.spacing(-2), 
        left: 0,
        width: '100%',
        height: 1,
        background: 'linear-gradient(90deg, #FFFFFF 0%, #8F3052 30%, #8F3052 70%, #FFFFFF 100%)',
        opacity: 0.5,
      },
      tabTitle:{
        marginLeft: 8
      },
      tabLabel:{
        display: "flex",
        alignItems: "center",
      },   
      subtopicLabel: {
        paddingLeft: theme.spacing(0),
        "& .subtopicTitle": {
          color: theme.palette.text.dark,
        },
        "&:hover .subtopicTitle": {
          color: theme.palette.Primary.background,
          cursor: "pointer",
          fontWeight: 700,
          fontSize: 16
        },
        "&.clicked .subtopicTitle": {
          color: theme.palette.Primary.background,
          fontWeight: 700,
          fontSize: 16
        },
        "&:not(:hover) .clicked .subtopicTitle": {
          color: theme.palette.text.dark,
        },
      },
      tabsContainer: {
        position: "relative",
        backgroundColor: "#F9F4F6"
      },
      tabBorder: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "3px",
        backgroundColor: "#5A404E",
      },
      bottomBox:{
        borderBottom: "3px solid #5A404E",
        borderLeft: "3px solid #5A404E",
        borderRight: "3px solid #5A404E",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        padding: theme.spacing( 1, 5, 3, 5),
      }
}));

export default useStyles;