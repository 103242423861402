import { MenuItem, Select, Typography, InputLabel, FormControl } from "@mui/material";
import clsx from "clsx";

import useStyles from "./styles";

const CeroDropdown = (props) => {
    const classes = useStyles();
    const options = props.options?.length > 0 ? props.sortOption ? props.options.sort((a, b) => a.value.localeCompare(b.value)) : props.options  : []
    return (
        <FormControl fullWidth className={clsx(classes.container, props.classes?.container)} margin="dense" size='small' >
            <InputLabel id={`label-${props.id}`} className = {classes.label}>{props.label}</InputLabel>
            <Select
                id={props.id}
                labelId={`label-${props.id}`}
                name={props.name}
                label={props.label}
                value={props.selectedValue}
                onChange={props.onChange}
                // className={classes.input}
                className={clsx(classes.input, props.classes?.input)}
                classes={{ select: classes.select }}
                disabled={props.disabled}
                MenuProps={{
                    classes: { paper: classes.paperRoot }
                }}
            >
                  {options && options.map((option) => (
                    <MenuItem key={option.key} value={option.key} className={clsx(classes.menuItem, props.classes?.menuItem)}>
                        <div className={clsx(classes.menuItemText, props.classes?.menuItemText)}>
                            {option.value}
                        </div>
                    </MenuItem>
                ))}
            </Select>
            {!!props.error && <Typography className={clsx(classes.text, classes.error, props.classes?.error)} >{props.error}</Typography>}
        </FormControl >
    );
};

export default CeroDropdown;
