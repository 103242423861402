import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Typography,
  IconButton,
  Tooltip,
  Zoom,
  Link,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Box } from "@mui/system";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import clsx from "clsx";

import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import CeroDropdown from "../../../components/CeroDropdown";
import CeroAutoComplete from "../../../components/CeroAutoComplete";

import { STATUS } from "../../../redux/constants";
import { answerQualitativeQuestion, getStates } from "../../../redux/actions";

import useStyles from "./styles";
import { answerSchema } from "./schema";
import CeroChipSelect from "../../../components/CeroChipSelect";
import { getCookie } from "../../../services/cookie";

const AnswerArea = ({
  questionItem,
  classes,
  onCancel,
  countryList,
  answerId,
}) => {
  const dispatch = useDispatch();
  const { id, company } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [textBox, setTextBox] = useState(false);
  const [editOptions, setEditOptions] = useState(false);
  const questionAnswerStatus = useSelector(
    (state) => state.audit.answerQuestion.status
  );
  const [selectedCountry, setSelectedCountry] = useState(null);
  const stateList = useSelector((state) => state.listings.getStates.data);

  const questionAnswerError = useSelector(
    (state) => state.audit.answerQuestion.message
  );
  
  const isAuditor = getCookie('role') === 'auditor'
  const { question_type, lookup_field, keys, question } = questionItem;
  const isMultiple = question_type === "multiselect";

  const defaultValue =
    isMultiple && questionItem.answer
      ? questionItem.answer.split(",").map((item) => ({ label: item }))
      : "";
  
  const [answer, setAnswer] = useState(
    questionItem.answer ? defaultValue ? defaultValue : questionItem.answer : isMultiple
      ? []
      : ""
  );

  const isLoading = questionAnswerStatus === STATUS.RUNNING;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length > 250) {
      setError("Input cannot exceed 250 characters");
    } else {
      setAnswer(value);
      setError(null);
      answerSchema
        .validate({ answer: value })
        .then(() => setError(null))
        .catch((validationError) => {
          setError(validationError.message);
        });
    }
  };

  const [scopes, setScopes] = useState({
    Scope_1: { type: "", answer: "" },
    Scope_2: { type: "", answer: "" },
    Scope_3: { type: "", answer: "" },
  });

  useEffect(() => {
    if (selectedCountry) {
      dispatch(getStates(selectedCountry));
    }
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    if (questionItem?.answer) {
      setTextBox(true);
      setScopes((prevScopes) => ({
        ...prevScopes,
        ...questionItem.answer,
      }));
    }
  }, [questionItem?.answer]);

  const handleValueChange = (event, scope, isType) => {
    const { value } = event.target;
    setScopes((prevState) => ({
      ...prevState,
      [scope]: {
        ...prevState[scope],
        [isType ? "type" : "answer"]: value,
      },
    }));
  };

  // const handleScopeTypeChange = (event, scope) => {
  //   const { value } = event.target;
  //   setScopes((prevScopes) => ({
  //     ...prevScopes,
  //     [scope]: {
  //       ...prevScopes[scope],
  //       type: value,
  //     },
  //   }));
  // };

  const handleScopeAnswerChange = (event, scope) => {
    const { value } = event.target;
    setScopes((prevScopes) => ({
      ...prevScopes,
      [scope]: {
        ...prevScopes[scope],
        answer: value,
      },
    }));
  };

  const [selectedValues, setSelectedValues] = useState(answer);
  const [selectedInput, setSelectedInput] = useState(answer);

  const handleSelectChange = (topic, selected) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [topic]: selected.join(","),
    }));
  };
  

  const handleInputChange = (event, key) => {
    const { value } = event.target;
    setSelectedInput((prevAnswer) => ({
      ...prevAnswer,
      [key]: value,
    }));
  };

  const handleScopeTypeChange = (scope, selectedValues) => {
    setScopes((prevAnswers) => ({
      ...prevAnswers,
      [scope]: {
        type: selectedValues.join(","),
      },
    }));
  };

  const onUpdateAnswer = (scope) => {
    let payload = {};
    if (question_type === "dropdown_with_text") {
      payload = {
        question_id: questionItem.id,
        answer: {
          Scope_1: { ...scopes.Scope_1 },
          Scope_2: { ...scopes.Scope_2 },
          Scope_3: { ...scopes.Scope_3 },
        },
        audit_status_id: id,
        companyId:company,
      };
    } else if (question_type === "dropdown_with_select") {
      payload = {
        question_id: questionItem.id,
        answer: selectedValues,
        audit_status_id: id,
        companyId:company,
      };
    } else if (question_type === "dropdown_with_input") {
      payload = {
        question_id: questionItem.id,
        answer: selectedInput,
        audit_status_id: id,
        companyId:company,
      };
    } else if (question_type === "dropdown_with_multi") {
      payload = {
        question_id: questionItem.id,
        answer: answer,
        audit_status_id: id,
        companyId:company,
      };
    }
   else if (question_type === "multiselect") {
    
    payload = {
      question_id: questionItem.id,
      answer: answer ? answer.map((item) => item.label).join(",") : [],
      audit_status_id: id,
      companyId:company,
    };

  } else {
      payload = {
        question_id: !answer  ? "" : questionItem.id,
        answer: isMultiple
          ? answer.map((item) => item.label).join(",")
          : answer,
        audit_status_id: id,
        companyId:company,
      };
    }
    setEditOptions(false)
    if (questionItem.answer_id) payload.answer_id = questionItem.answer_id;        
    dispatch(answerQualitativeQuestion(payload));
  };

  useEffect(() => {
    if (localLoading && questionAnswerStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Answer added successfully", { variant: "success" });
      onCancel();
      setLocalLoading(false);
    } else if (localLoading && questionAnswerStatus === STATUS.ERROR) {
      enqueueSnackbar(
        questionAnswerError.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      setLocalLoading(false);
    }
  }, [
    questionAnswerStatus,
    dispatch,
    enqueueSnackbar,
    onCancel,
    questionAnswerError.message,
  ]);
  const [localLoading, setLocalLoading] = useState(false);
  // const dropdownOptions = Array.isArray(keys) ? keys : Object.values(keys);
  let dropdownOptions;
  if (keys !== undefined && keys !== null) {
    dropdownOptions = Array.isArray(keys) ? keys : Object.values(keys);
  } else {
    dropdownOptions = [];
  }

  const Options = dropdownOptions.map((test, index) => ({
    key: `${answerId}-${index}`,
    value: test,
  }));

  const handleDropdownChange = (event, value) => {
    const countryName = value?.label;
    setAnswer(value?.label);
    let country = countryList.find((country) => country.label === countryName);
    const countryCode = country ? country.id : "";
    setSelectedCountry(countryCode);
  };

  useEffect(() => {
    
    if (
      (answer && answer !== "") || (answer && (Array.isArray(answer) && answer.length>0)) &&
      (question_type === "dropdown" ||
        question_type === "multiselect" ||
        question_type === "radio" ||
        question_type === "dropdown_with_multi")
    ) {      
      onUpdateAnswer();
    }
  }, [answer]);

  const showTextBox = () => {
    setTextBox(true);
  };
  const stateNames = Object.values(stateList).map(
    (stateObj) => Object.values(stateObj)[0]
  );
  return (
    <Box className={classes.answerArea}>
      <Box className={classes.answerAreaContainer}>
        {question_type === "text" &&
          question !== "What is your company's legal name?" && (
            <CeroInput
              classes={{ container: classes.textArea }}
              rows={3}
              multiline
              id="answer"
              name="answer"
              placeholder="Please type your answer"
              value={answer}
              fullWidth
              onChange={handleChange}
              disabled={isLoading || isAuditor}
            />
          )}
        {question_type === "radio" && (
          <Box>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="radio"
                name="radio-buttons-group"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                className={classes.radioContainer}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio className={classes.radio} />}
                  label="Yes"
                  disabled={isAuditor}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio className={classes.radio} />}
                  label="No"
                  disabled={isAuditor}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        {question_type === "dropdown_with_input" && (
          <Box>
            {keys.map((key, index) => (
              <div key={index}>
                <h4>{key.replace(/_/g, " ")}</h4>
                <CeroInput
                  classes={{ container: classes.textArea }}
                  rows={3}
                  multiline
                  id={`${key}_text`}
                  name={`${key}_text`}
                  placeholder="Please type your answer"
                  value={selectedInput[key]}
                  fullWidth
                  onChange={(event) => handleInputChange(event, key)}
                  disabled={isLoading || isAuditor}
                />
              </div>
            ))}
            <Box className={classes.buttonContainer}>
              <CeroButton
                buttonText="Cancel"
                variant="outlined"
                className={clsx(classes.button, classes.buttonSecondary)}
                onClick={onCancel}
                disabled={isLoading || isAuditor}
              />
              <CeroButton
                buttonText={isLoading ? "Saving..." : "Save"}
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={() => {
                  setLocalLoading(true);
                  onUpdateAnswer();
                }}
                disabled={isAuditor}
                // disabled={localLoading || !answer}
              />
            </Box>
          </Box>
        )}
        {error && <div className={classes.error}>{error}</div>}
        {question_type === "dropdown" &&
          !lookup_field &&
          questionItem?.placeholder !== "State" && (
            <CeroDropdown
              id="answer"
              label={questionItem?.placeholder}
              options={dropdownOptions.map((key, index) => ({
                key: key,
                value: key,
              }))}
              onChange={(event) => {
                const selectedKey = event.target.value;
                const selectedOption = Options.find(
                  (option) => option.value === selectedKey
                );
                const selectedValue = selectedOption.value;
                setAnswer(selectedValue);
              }}
              selectedValue={answer}
              disabled={isLoading || isAuditor}
              classes={{ container: classes.dropdownContainer }}
            />
          )}
        {question_type === "dropdown_with_select" && (
          <div>
            {Object.entries(keys).map(([topic, options], index) => (
              <div key={index}>
                <h4>{topic.replace(/_/g, " ")}</h4>
                <CeroChipSelect
              label={questionItem.placeholder}
              formControlClass={classes.MultiSelectformControl}
              optionsde={options.reduce((acc, option) => {
                acc[option] = option;
                return acc;
              }, {})}
              selected={
                selectedValues[topic] ? selectedValues[topic].split(",") : []
              }
              handleChange={(e) => {
                const selectedValues = Array.isArray(e.target.value)
                  ? e.target.value
                  : [e.target.value];
                  handleSelectChange(topic, selectedValues);
              }}
              showSelectAll={false}
              disabled={isAuditor}
            />
              </div>
            ))}
            {questionItem?.answer === null ?
            <Box style={{ marginTop: 10 }} className={classes.buttonContainer}>
            <CeroButton
              buttonText="Cancel"
              variant="outlined"
              className={clsx(classes.button, classes.buttonSecondary)}
              onClick={onCancel}
              disabled={isLoading || isAuditor}
            />
            <CeroButton
              buttonText={"Save"}
              className={clsx(classes.button, classes.buttonPrimary)}
              onClick={() => {
                setLocalLoading(true);
                onUpdateAnswer();
              }}
              disabled={isAuditor}
              // disabled={localLoading}
            /> 
          </Box> :

            editOptions ?
            <Box style={{ marginTop: 10 }} className={classes.buttonContainer}>
              <CeroButton
                buttonText="Cancel"
                variant="outlined"
                className={clsx(classes.button, classes.buttonSecondary)}
                onClick={onCancel}
                disabled={isLoading || isAuditor}
              />
              <CeroButton
                buttonText={"Save"}
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={() => {
                  setLocalLoading(true);
                  onUpdateAnswer();
                }}
                disabled={isAuditor}
                // disabled={localLoading}
              /> 
            </Box>:
            !isAuditor &&<div style={{ textAlign: "center"}}>
            <Tooltip
            title={"Edit answer"}
            placement="right"
            arrow
            TransitionComponent={Zoom}
          >
            <IconButton
              className={classes.editIcon}
              onClick={() => setEditOptions(true)}
              size="small"
            >
              <EditOutlinedIcon
                fontSize="small"
                color="primary"
                className={classes.editIcon}
              />
            </IconButton>
          </Tooltip></div>}
          </div>
        )}
        {question_type === "dropdown_with_multi" && (
          <>
            <CeroChipSelect
              label={
                questionItem?.keys.includes("Stakeholders")
                  ? "Choose different Bodies"
                  : "Committees"
              }
              formControlClass={classes.MultiSelectformControl}
              optionsde={questionItem?.keys.reduce((acc, option) => {
                acc[option] = option;
                return acc;
              }, {})}
              selected={
                typeof questionItem?.answer === 'string' ? questionItem.answer.split(",").map((item) => item) : []
              }
              handleChange={(e) => {
                const selectedValues = Array.isArray(e.target.value)
                  ? e.target.value
                  : [e.target.value];
                const values = selectedValues.join(",");
                setAnswer(values);
              }}
              showSelectAll={false}
              disabled={isAuditor}
            />
          </>
        )}
        {question_type === "dropdown_with_text" && (
          <>
            {/* <div>
              {Object.entries(scopes).map(([scope, { type, answer }]) => (
                <div key={scope} style={{ marginBottom: '20px' }}>
                  <h4>{scope.replace(/_/g, " ")}</h4>
                  <CeroChipSelect
                    label={questionItem.placeholder}
                    formControlClass={classes.MultiSelectform}
                    optionsde={questionItem?.keys[scope].reduce(
                      (acc, option) => {
                        acc[option] = option;
                        return acc;
                      },
                      {}
                    )}
                    selected={
                      scopes[scope].type ? scopes[scope].type.split(",") : []
                    }
                    handleChange={(e) => {
                      const selectedValues = Array.isArray(e.target.value)
                        ? e.target.value
                        : [e.target.value];
                      handleScopeTypeChange(scope, selectedValues);
                    }}
                    showSelectAll={false}
                    disabled={isAuditor}
                  />
                  <br />
                  {textBox ? (
                    <>
                      <CeroInput
                        classes={{ container: classes.textArea }}
                        rows={3}
                        multiline
                        id={`${scope}_text`}
                        name={`${scope}_text`}
                        placeholder="Please type your answer"
                        value={answer}
                        fullWidth
                        onChange={(event) =>
                          handleScopeAnswerChange(event, scope)
                        }
                        disabled={isLoading || isAuditor}
                      />
                    </>
                  ) : (
                    !isAuditor && <Link onClick={showTextBox}>Answer now</Link>
                  )}
                </div>
              ))}
               <div style={{ display: 'flex', gap: '10px' }}>
                        <CeroButton
                          buttonText="Cancel"
                          variant="outlined"
                          className={clsx(
                            classes.button,
                            classes.buttonSecondary
                          )}
                          onClick={onCancel}
                          disabled={isAuditor}
                        />
                        <CeroButton
                          buttonText={"Save"}
                          className={clsx(
                            classes.button,
                            classes.buttonPrimary
                          )}
                          onClick={() => {
                            setLocalLoading(true);
                            onUpdateAnswer();
                          }}
                          disabled={isAuditor}
                        />
                      </div>
            </div> */}
            <div>
  {Object.entries(scopes).map(([scope, { type, answer }]) => (
    <div key={scope} style={{ marginBottom: '20px', maxWidth: '600px' }}> 
      <h4>{scope.replace(/_/g, " ")}</h4>
      
      <div style={{ marginBottom: '10px' }}>
      <CeroChipSelect
                    label={questionItem.placeholder}
                    formControlClass={classes.MultiSelectformScope}
                    optionsde={questionItem?.keys[scope].reduce(
                      (acc, option) => {
                        acc[option] = option;
                        return acc;
                      },
                      {}
                    )}
                    selected={
                      scopes[scope].type ? scopes[scope].type.split(",") : []
                    }
                    handleChange={(e) => {
                      const selectedValues = Array.isArray(e.target.value)
                        ? e.target.value
                        : [e.target.value];
                      handleScopeTypeChange(scope, selectedValues);
                    }}
                    showSelectAll={false}
                    disabled={isAuditor}
                  />
      </div>

      {textBox ? (
        <CeroInput
          classes={{ container: classes.textAreaScope }}
          rows={3}
          multiline
          id={`${scope}_text`}
          name={`${scope}_text`}
          placeholder="Please type your answer"
          value={answer}
          fullWidth
          onChange={(event) => handleScopeAnswerChange(event, scope)}
          disabled={isLoading || isAuditor}
        />
      ) : (
        !isAuditor && <Link onClick={showTextBox}>Answer now</Link>
      )}
            {!isAuditor && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', maxWidth: '100%' }}>
          <CeroButton
            buttonText="Cancel"
            variant="outlined"
            className={clsx(classes.button, classes.buttonSecondary)}
            onClick={onCancel}
            disabled={isAuditor}
          />
          <CeroButton
            buttonText="Save"
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={() => {
              setLocalLoading(true);
              onUpdateAnswer();
            }}
            disabled={isAuditor}
            style={{ marginLeft: '10px' }} 
          />
        </div>
      )}
    </div>
  ))}
</div>

          </>
        )}
        {question_type === "dropdown" && lookup_field && questionItem.question !== "In which countries do you operate?" && (
          <Box className={classes.dropdownContainer}>
            <CeroAutoComplete
              required
              id="country"
              name="country"
              label="Country"
              fullWidth
              options={countryList}
              value={answer}
              onChange={(e, value) => handleDropdownChange(e, value)}
              isOptionEqualToValue={(option, value) => option.label === value}
              disabled={isAuditor}
            />
          </Box>
        )}
        {question_type === "dropdown" &&
          questionItem.placeholder === "State" && (
            <Box className={classes.dropdownContainer}>
              <CeroAutoComplete
                required
                id="state"
                name="state"
                label="State"
                fullWidth
                options={stateNames.map((state) => ({
                  label: state,
                  value: state,
                }))}
                value={answer}
                onChange={(e, value) => handleDropdownChange(e, value)}
                isOptionEqualToValue={(option, value) => option.label === value}
                disabled={isAuditor}
              />
            </Box>
          )}
        {isMultiple && lookup_field && (
          <Box className={classes.dropdownContainer}>
            <CeroAutoComplete
              required
              id="country"
              name="country"
              label="Country"
              fullWidth
              options={countryList}
              value={answer || ""}
              onChange={(e, value) => setAnswer(value)}
              isOptionEqualToValue={(option, value) => option.label === value}
              multiple
              disabled={isAuditor}
            />
          </Box>
        )}
      </Box>
      {question_type === "text" && (
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Cancel"
            variant="outlined"
            className={clsx(classes.button, classes.buttonSecondary)}
            onClick={onCancel}
            disabled={isLoading || isAuditor}
          />
          <CeroButton
            buttonText={localLoading ? "Saving..." : "Save"}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={() => {
              setLocalLoading(true);
              onUpdateAnswer();
            }}
            disabled={localLoading || !answer || isAuditor}
          />
        </Box>
      )}
    </Box>
  );
};
const QuestionAndAnswer = ({
  questionItem,
  countryList,
  isEditable,
}) => {
  const classes = useStyles();
  const [isAnswerEnabled, setIsAnswerEnabled] = useState(false);
  const isAuditor = getCookie('role') === 'auditor'

  return (
    <Box className={classes.questionItemContainer}>
      <Box className={classes.questionContainer}>
        <Box className={classes.number}>
          {questionItem.question_no_label
            ? `${questionItem.question_no_label}.`
            : ""}
        </Box>
        <Box className={classes.question}>{questionItem.question}</Box>
      </Box>
      <Box className={classes.answerContainer}>
        {isAnswerEnabled ? (
          <AnswerArea
            key={questionItem.id}
            answerId={questionItem.id}
            questionItem={questionItem}
            classes={classes}
            countryList={countryList}
            onCancel={() => setIsAnswerEnabled(false)}
          />
        ) : (
          <>
            {(questionItem.question_type === "dropdown_with_multi" ||
              questionItem.question_type === "dropdown_with_select") &&
            questionItem.answer !== null ? (
              <>
                <AnswerArea
                  key={questionItem.id}
                  answerId={questionItem.id}
                  questionItem={questionItem}
                  classes={classes}
                  countryList={countryList}
                  onCancel={() => setIsAnswerEnabled(false)}
                />
              </>
            ) : questionItem.question_type === "dropdown_with_input" ? (
              <>
                {questionItem?.answer !== null &&
                questionItem?.answer !== undefined ? (
                  <>
                    {Object.keys(questionItem?.answer).map((key, index) => (
                      <div key={index}>
                        <h4 className={classes.inputTitle}>
                          {key.replace(/_/g, " ")}
                        </h4>
                        <CeroInput
                          classes={{ container: classes.textAreaInput }}
                          rows={3}
                          multiline
                          id={`${key}_text`}
                          name={`${key}_text`}
                          placeholder={questionItem.placeholder}
                          value={questionItem?.answer[key]}
                          fullWidth
                        />
                      </div>
                    ))}
                    {!isAuditor && <div style={{ textAlign: "center"}}>
                    <Tooltip
                      title={"Edit answer"}
                      placement="right"
                      arrow
                      TransitionComponent={Zoom}
                    >
                      <IconButton
                        className={classes.editIcon}
                        onClick={() => setIsAnswerEnabled(true)}
                        size="small"
                      >
                        <EditOutlinedIcon
                          fontSize="small"
                          color="primary"
                          className={classes.editIcon}
                        />
                      </IconButton>
                    </Tooltip></div>}
                  </>
                ) : (questionItem.question_type === "dropdown_with_input"  &&
                  <AnswerArea
                    key={questionItem.id}
                    answerId={questionItem.id}
                    questionItem={questionItem}
                    classes={classes}
                    countryList={countryList}
                    onCancel={() => setIsAnswerEnabled(false)}
                  />
                )}
              </>
            ) : questionItem.question_type === "dropdown_with_text" ? (
              <>
                {questionItem?.answer !== null &&
                questionItem?.answer !== undefined ? (
                  <div className={classes.dropdownText}>
                    {Object.entries(questionItem?.answer).map(([scope, { type, answer }]) => (
                      <>
                    <h4>{scope.replace(/_/g, " ")}</h4>
                    <CeroChipSelect
                      label={questionItem.placeholder}
                      formControlClass={classes.MultiSelectform}
                      optionsde={questionItem?.keys[scope].reduce(
                        (acc, option) => {
                          acc[option] = option;
                          return acc;
                        },
                        {}
                      )}
                      selected={
                        type.split(",")
                      }
                      showSelectAll={false}
                    />
                    <br />
                    {answer && <Box className={classes.answerScope}>{answer}</Box>}
                    </>))}
                    {!isAuditor && <div style={{ textAlign: "center"}}>
                    <Tooltip
                      title={"Edit answer"}
                      placement="right"
                      arrow
                      TransitionComponent={Zoom}
                    >
                      <IconButton
                        className={classes.editIcon}
                        onClick={() => setIsAnswerEnabled(true)}
                        size="small"
                      >
                        <EditOutlinedIcon
                          fontSize="small"
                          color="primary"
                          className={classes.editIcon}
                        />
                      </IconButton>
                    </Tooltip></div>}
                  </div>
                ) : (
                  <AnswerArea
                    key={questionItem.id}
                    answerId={questionItem.id}
                    questionItem={questionItem}
                    classes={classes}
                    countryList={countryList}
                    onCancel={() => setIsAnswerEnabled(false)}
                  />
                )}
              </>
            ) : (
              questionItem.question_type !== "label" && (
                <Box className={classes.answer}>
                  {questionItem.answer_flag
                    ? questionItem.question_type !== "multiselect" && (
                        <Box className={classes.answerBox}>
                          {questionItem.answer ? (
                            <Typography className={classes.value}>
                              {questionItem.answer}
                            </Typography>
                          ) : (
                            ""
                          )}
                          {!isAuditor &&
                            isEditable &&
                            questionItem.question !==
                              "What is your company's legal name?" &&
                            questionItem.answer && (
                              !isAuditor && <Tooltip
                                title={"Edit answer"}
                                placement="right"
                                arrow
                                TransitionComponent={Zoom}
                              >
                                <IconButton
                                  className={classes.editIcon}
                                  onClick={() => setIsAnswerEnabled(true)}
                                  size="small"
                                >
                                  <EditOutlinedIcon
                                    fontSize="small"
                                    color="primary"
                                    className={classes.editIcon}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                        </Box>
                      )
                    : ""}
                  {questionItem.answer_flag && questionItem.question_type === "multiselect" ? (
                    <AnswerArea
                      key={questionItem.id}
                      answerId={questionItem.id}
                      questionItem={questionItem}
                      classes={classes}
                      countryList={countryList}
                      onCancel={() => setIsAnswerEnabled(false)}
                    />
                  ) : (
                    ""
                  )}
                  {!questionItem.answer_flag &&
                    !isAuditor &&
                    (questionItem.question_type === "text" ? (
                      questionItem.question ===
                      "What is your company's legal name?" ? (
                        questionItem.answer
                      ) : (
                        <Typography
                          sx={{ textDecoration: "underline" }}
                          display="inline"
                          className={classes.link}
                          onClick={() => setIsAnswerEnabled(true)}
                        >
                          Answer now
                        </Typography> 
                      )
                    ) : (
                      <AnswerArea
                        key={questionItem.id}
                        answerId={questionItem.id}
                        questionItem={questionItem}
                        classes={classes}
                        countryList={countryList}
                        onCancel={() => setIsAnswerEnabled(false)}
                      />
                    ))}
                </Box>
              )
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default QuestionAndAnswer;
