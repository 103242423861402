import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography } from "@mui/material"
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, ArcElement, registerables as registerablesJS } from 'chart.js';
import _ from 'lodash';
import { getEmissionTypes, getRecordStatus } from '../../../redux/actions';
import { emissionTypes } from '../../../constants';

import useStyles from './styles'
import EmissionTypeChart from './EmissionTypeChart';
import { getCookie } from '../../../services/cookie';

ChartJS.register(...registerablesJS)
ChartJS.register(ArcElement, Tooltip, Legend);

const EmissionChart = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const role = getCookie('role');
    const companyId = getCookie("companyId");
    const emissionData = useSelector(state => state.dashboard.getEmissionTypes.data);
    const recordStatus = useSelector(state => state.dashboard.getRecordStatus.data);

    const emissionDataStatus = useSelector(state => state.dashboard.getEmissionTypes.status);
    const recordStatusStatus = useSelector(state => state.dashboard.getRecordStatus.status);

    useEffect(() => {
        if (
          role === "sustainability_manager" || role === "proxy_sustainability_manager" ||
          role === "read_only_user"
        ) {
          dispatch(getEmissionTypes(props.filter,role,companyId));
        } else {
          dispatch(getRecordStatus(props.filter));
        }
    }, [dispatch, props.filter]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    fontColor: 'rgba(0,0,0,.4)'
                },
                align: 'end',
                position: 'top',
            },
            title: {
                align: 'start',
                display: true,
                text: 'Emission Types (tonnes)',
                font: {
                    size: 18,
                    family: 'Inter'
                }
            },
        },
        maintainAspectRatio: false
    };

    const data = {
        labels: _.map(emissionData, (item) => emissionTypes.find(data => data.id === item.type).title),
        datasets: [
            {
                label: 'Emissions',
                data: _.map(emissionData, item => item.total_co2e),
                backgroundColor: [
                    '#DC7A48',
                    '#E69D78',
                    '#F5D9CB',
                    '#488f31',
                    '#6aa040',
                    '#89b050',
                    '#a7c162',
                    '#c5d275',
                    '#de425b',
                    '#faa15e'
                ]
            },
        ],
    };

    return (
      <Paper className={classes.container}>
        {/* <Pie data={data} options={options} height={300} /> */}
        {role === "sustainability_manager" || role === "proxy_sustainability_manager" ||
        role === "read_only_user"  ? (
          <>
            <Typography className={classes.graphTitle}>
              Emission Types(tonnes)
            </Typography>
            <div className={classes.emissionChart}>
              {emissionData?.length > 0 ? (
                <EmissionTypeChart data={emissionData} />
              ) :emissionDataStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <Typography className={classes.graphTitle}>
              Record Status
            </Typography>
            <div className={classes.chart}>
              {recordStatus?.length > 0 ? (
                <EmissionTypeChart data={recordStatus} />
              ) :recordStatusStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
        )}
      </Paper>
    );
}

export default EmissionChart
