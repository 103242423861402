import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroButton from "../../../components/CeroButton";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import CeroDate from "../../../components/CeroDate";
import clsx from "clsx";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { getLocations, resetSignup, sellCarbonCredits } from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { schemeValidation } from "./schema";
import { STATUS } from "../../../redux/constants";
import { useSnackbar } from "notistack";

const TradeSell = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [location, setLocation] = useState('');
  const countryListData = useSelector(state => state.trade.getLocations.data)
  const addCarbonCreditsData = useSelector(
    (state) => state.trade.sellCarbonCredits
  );
    const countryList = countryListData.map(item => ({ id: item.code, label: item.name }));

    const formik = useFormik({
    initialValues: {
      projectName: "",
      projectType: "",
      startDate: "",
      location: "",
      co2Reduction: "",
      endDate: "",
      certification: "",
      totalInvestment: "",
      associatedCompany: "",
      details: "",
      carbonVerified: false,
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const onSubmitData = () => {
    const requestData = {
      project_name: formik.values.projectName,
      location: formik.values.location,
      certification: formik.values.certification,
      project_type: formik.values.projectType,
      co2_reduction: formik.values.co2Reduction,
      price_per_ton: formik.values.totalInvestment,
      start_date: dayjs(formik.values.startDate).format("DD/MM/YYYY"),
      end_date: dayjs(formik.values.endDate).format("DD/MM/YYYY"),
      associated_company: formik.values.associatedCompany,
      has_verified_by_third_party: formik.values.carbonVerified,
      additional_details: formik.values.details,
      save: true,
    };
    dispatch(sellCarbonCredits(requestData));
  };

  useEffect(() => {
    if (addCarbonCreditsData.status === STATUS.SUCCESS) {
      enqueueSnackbar( addCarbonCreditsData.data.message, { variant: "success", autoHideDuration: 1000, });
      dispatch(resetSignup());
      navigate('/trade/carboncredits-added')
    } else if (addCarbonCreditsData.status === STATUS.ERROR) {
      enqueueSnackbar(addCarbonCreditsData.message.message || "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 1000, });
      dispatch(resetSignup());
    }
  }, [addCarbonCreditsData, dispatch, enqueueSnackbar]);

  const projectTypes = [
    { key: "Renewable Energy", value: "Renewable Energy" },
    { key: "Reforestation", value: "Reforestation" },
    { key: "Afforestation", value: "Afforestation" },
    { key: "Energy Efficiency", value: "Energy Efficiency" },
    { key: "Methane Capture", value: "Methane Capture" },
  ];

  const certificationTypes = [
    { key: "Verified Carbon Standard", value: "Verified Carbon Standard" },
    { key: "Gold Standard", value: "Gold Standard" },
    { key: "American Carbon Registry", value: "American Carbon Registry" },
    { key: "Climate Action Reserve", value: "Climate Action Reserve" },
    { key: "Clean Development Mechanism", value: "Clean Development Mechanism" },
  ];

  useEffect(() => {
    dispatch(getLocations())
  }, [dispatch])

  const handleToggleChange = (checked) => {
    formik.setFieldValue("carbonVerified", checked);
  };

  const handleCountryChange = (event, value) => {
    formik.setFieldValue("location", value ? value.id : "");
    setLocation(value.label)
  };
  
  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
      <>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        ><ArrowBackIosIcon />Back</div>
        <Container className={classes.container}>
          <Box className={classes.innerContainer}>
            <Box className={classes.topContainer}>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                spacing={8}
              >
                <Grid item container direction="column" md={6} xs={12}>
                  <CeroInput
                    required
                    id="projectName"
                    name="projectName"
                    label="Project Name"
                    value={formik.values.projectName}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.projectName && formik.errors.projectName
                    }
                    classes={{ container: classes.textAreaContainer }}
                  />
                  <CeroSelect
                    required
                    id="projectType"
                    name="projectType"
                    label="Project Type"
                    options={projectTypes}
                    selectedValue={formik.values.projectType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <CeroDate
                    label="Start Date"
                    value={formik.values.startDate}
                    onChange={(date) => formik.setFieldValue("startDate", date)}
                    year={dayjs().year()} 
                    error={formik.touched.startDate && formik.errors.startDate}
                  />
                </Grid>
                <Grid item container direction={"column"} md={6} xs={12}>
                  <CeroAutoComplete
                    id="location"
                    label="Location"
                    required
                    value={location}
                    onChange={handleCountryChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.location}
                    options={countryList}
                    isOptionEqualToValue={(option, value) => option.id === value}
                />
                  <CeroInput
                    required
                    id="co2Reduction"
                    name="co2Reduction"
                    label="CO2 Reduction (tons)"
                    value={formik.values.co2Reduction}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.co2Reduction && formik.errors.co2Reduction
                    }
                    classes={{ container: classes.textAreaContainer }}
                  />
                  <CeroDate
                    required
                    label="End Date"
                    value={formik.values.endDate}
                    onChange={(date) => formik.setFieldValue("endDate", date)}
                    year={dayjs().year()} 
                    error={formik.touched.endDate && formik.errors.endDate}
                  />
                </Grid>
                <Grid item container direction="column" md={6} xs={12}>
                  <CeroSelect
                    required
                    id="certification"
                    name="certification"
                    label="Certification"
                    fullWidth
                    options={certificationTypes}
                    selectedValue={formik.values.certification}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.certification &&
                      formik.errors.certification
                    }
                  />
                  <CeroInput
                    id="totalInvestment"
                    name="totalInvestment"
                    label="Price per ton (USD)"
                    fullWidth
                    value={formik.values.totalInvestment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                   <CeroInput
                    id="associatedCompany"
                    name="associatedCompany"
                    label="Associated Company"
                    value={formik.values.associatedCompany}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.associatedCompany && formik.errors.associatedCompany
                    }
                    classes={{ container: classes.textAreaContainer }}
                  />
                </Grid>
              </Grid>
              <Grid spacing={2} container direction="column">
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  md={6}
                  xs={10}
                >
                  <Typography style={{ paddingRight: 10 }}>
                    Has the carbon offset project been verified by a third-party
                    organization
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      style={{
                        fontWeight:
                          formik.values.carbonVerified === false
                            ? "bold"
                            : "normal",
                      }}
                    >
                      No
                    </Typography>
                    <Switch
                      checked={formik.values.carbonVerified === true}
                      className={classes.switchColorInactive}
                      inputProps={{ "aria-label": "Toggle Status" }}
                      onChange={(e) => handleToggleChange(e.target.checked)}
                    />
                    <Typography
                      style={{
                        fontWeight:
                          formik.values.carbonVerified === true
                            ? "bold"
                            : "normal",
                      }}
                    >
                      Yes
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item container direction="column" md={6} xs={10}>
                  <TextField
                    id="details"
                    name="details"
                    placeholder="Specify any additional environmental or social co-benefits associated with the carbon offset project"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    InputLabelProps={{ shrink: undefined }}
                    className={classes.textField}
                    value={formik.values.details}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.buttonContainer}>
            <CeroButton
              buttonText="Submit"
              disabled={!formik.dirty || !formik.isValid}
              className={clsx(classes.button, classes.buttonPrimary)}
              onClick={onSubmitData}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
          </Box>
        </Container>
      </>
    </DashboardLayout>
  );
};

export default TradeSell;
