import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import { Box, Container, Link, Typography } from '@mui/material';
import useStyles from './styles';
import CeroTable from '../../../components/CeroTable';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyList } from '../../../redux/actions';
import dayjs from 'dayjs';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import { sampleYear } from '../../../constants';

const TrackProgress = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [year, setYear] = useState(0);
    const isHistorypage = window.location.pathname.includes("/history")
    const surveyList = useSelector(
      (state) => state.materiality.getSurveyList.data
    );

    const progressTrackColumns = [
      {
        columnKey: "name",
        columnId: "name",
        columnHeader: "Survey Name",
        width: "40vw"
      },
      {
        columnKey: "created_ts",
        columnId: "created_ts",
        columnHeader: "Date",
        width: "20vw"
      },
      {
        columnKey: "status",
        columnId: "status",
        columnHeader: "Status",
        width: "20vw"
      },
      {
        columnKey: "action",
        columnId: "action",
        columnHeader: "Action",
        width: "20vw"
      }
    ];

    useEffect(() => {
      dispatch(getSurveyList(year));
    }, [dispatch, year]);

    const yearList = sampleYear().map((item) => ({
      id: item.key,
      label: item.value,
    }));
    
    const getStatus = (status) => {
      if(status === "pending"){
        return <Typography className={classes.pending}>Pending</Typography>
      }else if(status === "completed"){
        return <Typography className={classes.completed}>Completed</Typography>
      }
    }

    const onSelectData = (id) => {
      if(isHistorypage){
        navigate(`/history/${id}`)
      }else{
      navigate(`/track-progress/${id}`)
      }
    }

    const getData = () => {
      if (!Array.isArray(surveyList)) {
        return [];
      }

      return surveyList?.map((item) => ({
        ...item,
        created_ts: item.created_ts ? dayjs(item.created_ts).format("DD/MM/YYYY") : "NA",
        status: getStatus(item.status),
        action: <Link onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelectData(item.id);
        }} style={{ cursor: "pointer"}}>View Details</Link>,
      }))
    }

    return (
        <DashboardLayout>
          <Container className={classes.container}>
          {isHistorypage && <Box className={classes.yearFilter}>
          <CeroAutoComplete
            id="year"
            label="Assessment Year"
            onChange={(e, item) => setYear(item ? item.id : null)}
            options={yearList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          </Box>}
          <CeroTable
              columns={progressTrackColumns}
              data={getData}
              hasMore={false}
              loading={false}
              useExtraStyles={true}      
              classes={{ tableContainer: classes.tableContainer }}
            />
          </Container>
        </DashboardLayout>
      );
}

export default TrackProgress