import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ManagementDiversityByAgeGroupColumns,ManagementDiversityByGenderColumns} from "../../EmissionTable/TableColumns";
import CeroTable from "../../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../../redux/constants";
import CeroButton from "../../../../components/CeroButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { deleteEmissions, resetEmissionDelete } from "../../../../redux/actions";
import EmissionDeletePrompt from "../../../EmissionDeletePrompt";

const ManagementDiversityTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    backToEmission,
    headerOff,
    editOff,
    reportTable,
    isOnlyAdmin,
    period,
    isDeleteActionRequired
  } = props;

  const queryParams = new URLSearchParams(window.location.search);
  const totalEntriesForGender = emissionData?.based_on_gender?.length;
  const totalEntriesForAge= emissionData?.based_on_age?.length;
  const role = useSelector((state) => state.auth.role);
  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );
  const [page, setPage] = useState(1);
  const DEFAULT_ITEMS_PER_PAGE = 12;
  const [displayWarning, setDisplayWarning] = useState(false);
  const [emissionId, setEmissionId] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };
  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Topic deleted successfully",
        {
          variant: "success",
        }
      );
      dispatch(resetEmissionDelete());
      
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar("We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetEmissionDelete());
    }
    setDisplayWarning(false)
  }, [deleteEmissionData, enqueueSnackbar,  dispatch]);
  const onSelectEmissionData = (rowData) => {
    const currentFilter = `?distribution=gender`
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}&distribution=gender`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };

  const onEditEmissionData = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/business/emissions/management_diversity/${id}`);
  }; 
  
  const onSelectData = (rowData) => {
    let ageFilter = rowData.age;
    if(rowData.age === "Under 30"){
      ageFilter = "under_30";
    }else if(rowData.age === "Between 30 and 50"){
      ageFilter = "between_30_50"
    }else{
      ageFilter = "over_50"
    }
    const currentFilter = `?distribution=age`
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}&distribution=age`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };

  const getStatus = (status) => {
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };
  
  const getGenderDistribution = () =>
    emissionData?.based_on_gender?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      male: `${item.male}%`,
      female: `${item.female}%`,
      other: `${item.other}%`,
      status: getStatus(item.status),
      action: (
      <Box className={classes.actionContainer}>
        {item.is_able_to_update === true &&
          !editOff && isOnlyAdmin &&(
            <IconButton
              className={classes.editIcon}
              onClick={(e) => onEditEmissionData(e, item.id)}
            >
              <CreateIcon />
            </IconButton>
          )}
          {isDeleteActionRequired && (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager') || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
              // onClick={(e) => onEditEmissionData(e, item.id)}
            >
              <DeleteOutlineIcon />
            </IconButton>}
      </Box>
    ), 
    }));

    const getByAgeDistribution = () =>
    emissionData?.based_on_age?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      under_30: `${item.under_30}%`,
      between_30_50: `${item.between_30_50}%`,
      over_50: `${item.over_50}%`,
      status: getStatus(item.status),
      action: (
      <Box className={classes.actionContainer}>
        {item.status == "added" &&
          !editOff && isOnlyAdmin &&(
            <IconButton
              className={classes.editIcon}
              onClick={(e) => onEditEmissionData(e, item.id)}
            >
              <CreateIcon />
            </IconButton>
          )}
          {isDeleteActionRequired && (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager') || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
              // onClick={(e) => onEditEmissionData(e, item.id)}
            >
              <DeleteOutlineIcon />
            </IconButton>}
      </Box>
    ), 
    }));

    const handlePageChange = (event, newPage) => {
      setPage(newPage);
      onLoadMore(DEFAULT_ITEMS_PER_PAGE,newPage);
    };
    const startItem = (page - 1) * DEFAULT_ITEMS_PER_PAGE + 1;
    const endItem = Math.min(startItem + DEFAULT_ITEMS_PER_PAGE - 1, totalEntriesForGender);
    const endItemAge = Math.min(startItem + DEFAULT_ITEMS_PER_PAGE - 1, totalEntriesForAge);
  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Management Diversity by Gender
              </span>
              </Typography>
          <CeroTable
            columns={ManagementDiversityByGenderColumns}
            data={getGenderDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          />
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Management Diversity by Age
              </span>
              </Typography>
          <CeroTable
            columns={ManagementDiversityByAgeGroupColumns}
            data={getByAgeDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
      {displayWarning && (
          <EmissionDeletePrompt
          isOpen={displayWarning}
          onClose={() => setDisplayWarning(false)}
          onConfirm={onConfirmDelete}
          setOpenDeletePrompt = {displayWarning}
        />
        )}

    </>
  );
};

export default ManagementDiversityTable;
