import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "10px",
    marginTop: "30px",
    marginBottom: "20px",
  },

  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    color: theme.palette.icon.blue,
  },
  linkButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.icon.blue,
    cursor: "pointer",
    fontWeight: 400,
  },
  noDataWarning: {
    textAlign: "center",
    background: "white",
    verticalAlign: "center"
  },
  tableHeader: {
    backgroundColor: "#D0C5CA",
    fontWeight: "bold",
    textAlign: "center",
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
}));

export default useStyles;