import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  getAuditSummary,
  getYearlyAuditSummary,
  getYearlyAuditSummaryOverview,
  getYearlyAuditStatusSummaryOverview,
  requestAudit,
  resetRequestAuditData,
  getAllQuestions,
  answerQualitativeQuestion,
  resetQuestionAnswerStatus,
  getAuditTrails,
  getAuditorList,
  updateAuditorStatus,
  emailValidate,
  resetUpdateAuditorStatus,
  approveAuditBySusmgr,
  setAuditTopics,
  assignAuditor,
  resetAssignAuditor,
  auditorReaction,
  resetAuditedData
} = createActions({
  [ActionTypes.GET_AUDIT_SUMMARY]: () => {},
  [ActionTypes.GET_YEARLY_AUDIT_SUMMARY]: (year,statusId) => ({ year, statusId }),
  [ActionTypes.GET_YEARLY_AUDIT_SUMMARY_OVERVIEW]: (year,statusId) => ({ year, statusId }),
  [ActionTypes.GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW]: (companyId, auditId, year) => ({ companyId, year, auditId }),
  [ActionTypes.REQUEST_AUDIT]: (payload) => (payload),
  [ActionTypes.RESET_REQUEST_AUDIT_DATA]: () => {},
  [ActionTypes.GET_ALL_QUESTIONS]: (payload) => (payload),
  [ActionTypes.ANSWER_QUALITATIVE_QUESTION]: (payload) => (payload),
  [ActionTypes.RESET_QUESTION_ANSWER_STATUS]: () => {
  },
  [ActionTypes.GET_AUDIT_TRAILS]: (payload) => (payload),
  [ActionTypes.GET_AUDITOR_LIST]: () => {},
  [ActionTypes.UPDATE_AUDITOR_STATUS]: (requestData) => ({requestData}),
  [ActionTypes.EMAIL_VALIDATE]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_UPDATE_AUDITOR_STATUS]: () => ({}),
  [ActionTypes.APPROVE_AUDIT_BY_SUSMGR]: ( auditId, comment) => ({ auditId, comment }),
  [ActionTypes.SET_AUDIT_TOPICS]: ( auditId, topics) => ({ auditId, topics }),
  [ActionTypes.ASSIGN_AUDITOR]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_ASSIGN_AUDITOR]: () => {},
  [ActionTypes.AUDITOR_REACTION]: (companyId, auditid, topics, comment, status) => ({ companyId, auditid, topics, comment, status }),
  [ActionTypes.RESET_AUDITED_DATA]: () => {},

});
