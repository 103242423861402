import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';

export const {
  signupTrade,
  loginTrade,
  resetSignup,
  industryTypes,
  getLocations,
  sellCarbonCredits,
  getCarbonCreditsAdded,
  buyCarbonCreditList,
  getProjectDetails,
  getTradeAccount,
  updateTradeAccount
} = createActions({
  [ActionTypes.SIGNUP_TRADE]: (signupDetails) => ({ signupDetails }),
  [ActionTypes.LOGIN_TRADE]: (email, password) => ({ email, password }),
  [ActionTypes.RESET_SIGNUP]: () => ({}),
  [ActionTypes.INDUSTRY_TYPES]: () => ({}),
  [ActionTypes.GET_LOCATIONS]: () => ({}),
  [ActionTypes.SELL_CARBON_CREDITS]: (requestData) => ({ requestData }),
  [ActionTypes.GET_CARBON_CREDITS_ADDED]: () => ({}),
  [ActionTypes.BUY_CARBON_CREDIT_LIST]: (requestData) => ({requestData}),
  [ActionTypes.GET_PROJECT_DETAILS]: (projectId) => ({projectId}),
  [ActionTypes.GET_TRADE_ACCOUNT]: () => ({}),
  [ActionTypes.UPDATE_TRADE_ACCOUNT]: (requestData) => ({requestData}),
});
