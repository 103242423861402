import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 8,
    margin: theme.spacing(5, 0),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  ticket: {
    color: theme.palette.text.accent,
  },
  infoContainer: {
    maxWidth: 400,
    display:"flex !important",
    justifyContent: 'flex-start'
  },
  title:{
    width:"50%"
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight:500,
    fontSize: 14
  },
  submitted:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  added:{
    color: theme.palette.status.added,
    fontWeight:500,
    fontSize: 14
  },
  Inprogress:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  verified:{
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  review:{
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  },
  tableTitle:{
    fontWeight: 700, 
    fontSize: 18,
    textTransform: 'capitalize',
  },
  tableTopic:{
    display:"flex",
    justifyContent:"space-between",
    paddingBottom: 20,
    alignItems: "center"
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
},
}));

export default useStyles;
