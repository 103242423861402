import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    height: 60,
  },
  MultiSelectformControl: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 46,
    "& .MuiFormLabel-root": {
      left: theme.spacing(2),
      top: theme.spacing(1),
    },
    background: "white",
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
    "& .Mui-focused > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
  },
  filterContainer: {},
  yearFilter: {
    "& .MuiBox-root": {
      height: "42px !important",
      margin: theme.spacing(0.6, 0, 0, 0),
    },
    "& .MuiBox-root > .MuiAutocomplete-root ": {
      background: "white",
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root":
      {
        height: 46,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > input[type=text]":
      {
        paddingBlock: 0,
        paddingInline: 0,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-outlined":
      {
        marginTop: -3,
      },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: "70%",
    marginRight: 15,
    fontWeight: 400,
    height: 46,
    marginTop: 5,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  input:{
    background: "white",
  }
}));

export default useStyles;
