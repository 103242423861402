import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: "100%",
    display: 'flex',
    alignItems:'center',
  },
  filterContainer: {
    paddingTop: 20,
    width: "100%",
  },
  yearContainer: {
    display: "flex",
  },
  ticketContainer:{
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdown: {
    width: 82,
    marginRight: theme.spacing(6),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 150,
    marginRight: theme.spacing(6),
  },
  buttonSecondary: {
    color:theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 140,
    borderColor:theme.palette.Primary.background,
    '&:hover': {
      borderColor: theme.palette.Primary.background, 
    }
  },
  backbuttonContainer: {
    marginRight: theme.spacing(2),
  },
  textField: {
    backgroundColor: theme.palette.background.white,
  },
  noComments: {
    float: "left",
    fontFamily: "Arial, sans-serif",
    fontSize: 12
  },
  maxCharacters: {
    float: "right",
    fontSize: 12
  },
  dialogActions: {
    justifyContent: "flex-start", 
    display: "flex",
    paddingLeft: 30,
    paddingBottom: 30
  },
  textLimit:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10
  },
  buttonContainer:{
    display: "flex", 
    justifyContent: "flex-start",
    paddingTop: 20,
    paddingBottom: 15
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 10,
    alignItems: 'center',
    cursor: "pointer"
  },
  dialog:{
   width: "65%",
   "& .MuiPaper-root.MuiDialog-paper": {
    width: "50vw",
    margin: 80,
  },
  },
  underline:{
    height: 1,
    backgroundColor: theme.palette.Primary.main, 
    marginBottom: 25,
  }
}));

export default useStyles;
