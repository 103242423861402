import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../../components/CeroItemPair";
import dayjs from "dayjs";
import CeroInfoPair from "../../../../../../components/CeroInfoPair";

const EmployeeHireDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {  emissionData } = props;


  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            {emissionData.distribution === "female" ||
            emissionData.distribution === "male" ? (
              <Typography variant="h6" component="div">
                Employees Hire & Turnover by Gender
              </Typography>
            ) : (
              <Typography variant="h6" component="div">
                Employees Hire & Turnover by Age Group
              </Typography>
            )}

          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              spacing={12}
            >
              <Grid item container direction={"column"} xs={10}>
                <Box className={classes.actionBox}>
                  <CeroInfoPair
                    title="Status:"
                    value={emissionData.status ? emissionData.status : "NA"}
                    classes={{
                      container: classes.statusContainer,
                      title: classes.statusTitle,
                      value:
                        emissionData.status === "In Progress"
                          ? classes.Inprogress
                          : classes[emissionData?.status],
                    }}
                  />
                </Box>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                {emissionData.distribution === "female" ||
                emissionData.distribution === "male" ? (
                  <CeroItemPair
                    title="Gender:"
                    value={
                      emissionData.distribution
                        ? emissionData.distribution
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                ) : (
                  <CeroItemPair
                    title="Age Group:"
                    value={
                      emissionData.distribution
                        ? emissionData.distribution
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                )}
                <CeroItemPair
                  title="Employee Classification:"
                  value={
                    emissionData.classification
                      ? emissionData.classification
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Resigned:"
                  value={emissionData.resigned ? emissionData.resigned : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Hired:"
                  value={emissionData.hired ? emissionData.hired : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Turnover Rate:"
                  value={
                    emissionData.turnover_rate
                      ? `${emissionData.turnover_rate}%`
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Audited on:"
                  value={
                    emissionData.audited_by ? emissionData.audited_by : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Audited by:"
                  value={
                    emissionData.audited_on
                      ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
       
      </Container>
    </>
  );
};

export default EmployeeHireDetails;
