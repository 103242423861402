import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    containercard:{
        paddingLeft:0,
        paddingRight:0,
        "& .infinite-scroll-component__outerdiv > .infinite-scroll-component":{
            padding:"2px 8px"
        },
        "& .infinite-scroll-component__outerdiv > .infinite-scroll-component> .MuiPaper-root":{
            border: "1px solid #5a404e"
        }
    },
    containercardTable:{
      padding: theme.spacing(0,2),
      backgroundColor: "#EAE2E6",
      "& .MuiPaper-root.MuiTableContainer-root":{
        border: "1px solid black"
      },
    },
    tableContainer:{
      border: "1px solid black"
    },
    added: {
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        backgroundColor: "#7B7B7B",
        fontWeight:500,
        fontSize: 14
      },
  'pending-approval': {
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        backgroundColor: "#F7931D",
        fontWeight:500,
        fontSize: 14
      },
    'in-progress': {
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        backgroundColor: "#F7931D",
        fontWeight:500,
        fontSize: 14
      },
    approved: {
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        backgroundColor: "#48742C",
        fontWeight:500,
        fontSize: 14
      },
    review: {
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        backgroundColor: "#D3291D",
        fontWeight:500,
        fontSize: 14
      },
      verified: {
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        backgroundColor: "#00FFFF",
        fontWeight:500,
        fontSize: 14
      },
    audited:{
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        backgroundColor: "#024FE4",
        fontWeight:500,
        fontSize: 14
      },
    default:{
        width: 16, height: 16, borderRadius: '50%',
        border: "1px solid black",
        // backgroundColor: "black",
        fontWeight:500,
        fontSize: 14
      },
}));

export default useStyles;