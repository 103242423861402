import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography } from "@mui/material"
import { Chart as ChartJS, Tooltip, Legend, ArcElement, registerables as registerablesJS } from 'chart.js';
import _ from 'lodash';
import { roleDistribution } from '../../../redux/actions';
import useStyles from './styles'
import { getCookie } from '../../../services/cookie';
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";

ChartJS.register(...registerablesJS);
ChartJS.register(ArcElement, Tooltip, Legend);

const RoleDistributionGraph = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();

    const role = getCookie('role')
    const roleData = useSelector(state => state.dashboard.roleDistribution.data);

    useEffect(() => {
        dispatch(roleDistribution(props.filter));
    }, [props.filter, dispatch]);

    const formattedLabels = _.keys(roleData).map((label) => {
        return label.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    });

    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    useEffect(() => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        const myChartRef = chartRef.current.getContext("2d");
    
        chartInstance.current = new Chart(myChartRef, {
          type: "pie",
          data: {
            labels: formattedLabels,
            datasets: [
              {
                data: _.values(roleData),
                backgroundColor: ["#846BB5","#FBCE74", "#6FBFBA", "#C5B9DB", "#F1B2C4","#D291BC","#FFDAB9"],
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: true,
                position: "right",
                labels: {
                  usePointStyle: true,
                  color: 'black',
                  padding: 20,
                },
              },
              datalabels: {
                color: "white",
                font: {
                  weight: "bold",
                  size: 12,
                },
                formatter: (value, context) => {
                    return value.toFixed(0);
                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: 2,
            layout: {
              padding: {
                bottom: 20,
              },
            },
          },
          plugins: [ChartDataLabels],
        });
    
        return () => {
          if (chartInstance.current) {
            chartInstance.current.destroy();
          }
        };
      }, [roleData]);

    return (
        <Paper className={classes.container}>
            {role === "admin" && 
                <>
                    <Typography className={classes.graphTitle} >Role Distribution</Typography>
                    <div className={classes.chart}>
                    <canvas ref={chartRef} />
                    </div>
                </>
            }
        </Paper>
    )
}

export default RoleDistributionGraph;
