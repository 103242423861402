import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.white,
    height: "fit-content",
    width: "95%",
    borderRadius: 14,
    padding: theme.spacing(5, 7),
    paddingBottom: 0,
    position: "relative",
  },
  innerContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.darkGray,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.background.darkGray,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    padding: theme.spacing(4, 0),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 140,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  bulkButton: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    marginRight: 15,
    fontWeight: 400,
    width: 170,
    height: 42,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  bottomContainer: {
    maxWidth: 1000,
    marginTop: theme.spacing(5),
  },
  previewTitle: {
    marginBottom: theme.spacing(3),
  },
  previewItem: {
    margin: theme.spacing(1, 0),
    fontSize: 15,
  },
  secondResultContainer: {
    paddingLeft: `${theme.spacing(4)} !important`,
    marginLeft: theme.spacing(4),
    borderLeft: `solid 1px ${theme.palette.Other.stroke}`,
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 35,
    paddingTop: 10,
    alignItems: "center",
    cursor: "pointer",
  },
  filterContainer: {
    margin: theme.spacing(5, 0),
  },
  MultiSelectformControl: {
    marginTop: theme.spacing(3),
    width: "100%",
    height: 60,
    maxWidth: "338px",
    "& .MuiFormLabel-root": {
      left: theme.spacing(2),
      top: theme.spacing(1),
      background: "white",
    },
    background: "white",
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
    "& .Mui-focused > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
  },
  yearFilter: {
    "& .MuiBox-root > .MuiAutocomplete-root ": {
      background: "white",
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":
      {
        color: theme.palette.Primary.main,
      },
  },
}));

export default useStyles;