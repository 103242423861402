import React from 'react';
import { useNavigate } from 'react-router-dom';
import CeroTable from '../../../components/CeroTable';
import useStyles from "./styles";

export const TableColumns = [{
    columnKey: 'company_name',
    columnId: 'company_name',
    columnHeader: 'Company',
}, {
    columnKey: 'total_countries',
    columnId: 'total_countries',
    columnHeader: 'Total Countries',
}, {
    columnKey: 'total_facilities',
    columnId: 'total_facilities',
    columnHeader: 'Total Facilities',
}, {
    columnKey: 'total_users',
    columnId: 'total_users',
    columnHeader: 'Total Users',
}]

export const onSelectCompanyData = (company, companyId, tabName, navigate) => {
    navigate(`/companies/${companyId}/${tabName}`);
};

const CompaniesTable = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { totalCompanies, onLoadMore } = props

    const getCompanyList = () => totalCompanies.map((item) => ({
        ...item,
    }));

    return (
        <CeroTable
            columns={TableColumns}
            data={getCompanyList()}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={company => onSelectCompanyData(company, company.company_id, "company-details", navigate)}
            classes={{ tableContainer: classes.tableContainer }}
        />
    )
}


export default CompaniesTable;
