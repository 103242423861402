import { useState } from "react";
import { Grid, Typography } from "@mui/material";

import SearchBox from "../../../components/SearchBox";
import CeroButton from "../../../components/CeroButton";

import useStyles from "./styles";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { addFacilityValidation } from '../AddFacilityDrawer/schema';
import { useFormik } from 'formik';
import { getCookie } from "../../../services/cookie";
import CountriesIcon from "../../../assets/icons/CountriesIcon.png";


const FacilitiesTableFilter = (props) => {
    const { onAddData, onApplyFilter } = props;
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');
    const [filterCountry, setCountry] = useState('');
    const role = getCookie('role');
    const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
    const formik = useFormik({
        initialValues: {
            country: ''
        },
        validationSchema: addFacilityValidation,
        onSubmit: () => { },
    });

    const onApply = () => {
        const filterValue = {};
        if (searchText) {
            filterValue.searchText = searchText;
        }
        if (filterCountry) {
            filterValue.filterCountry = filterCountry;
        }
        onApplyFilter(filterValue);
    };

    return (
      <Grid
        className={classes.filterContainer}
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid container columnSpacing={2} alignItems="center" wrap="nowrap">
          <Grid item xs={3} className={classes.autoComplete}>
            <CeroAutoComplete
              id="country"
              name="Country"
              label={
                <div className={classes.labels}>
                  <img
                    src={CountriesIcon}
                    alt="Countries"
                    height="16"
                    width="16"
                    style={{ marginRight: 5, marginLeft: 5 }}
                  />
                  <Typography className={classes.labelText}>
                    Country
                  </Typography>
                </div>
              }
              onChange={(e, value) => setCountry(value ? value.id : undefined)}
              onBlur={formik.handleBlur}
              options={props.countryList}
            />
          </Grid>
          <Grid item xs={3}>
            <SearchBox
              placeholder="Search"
              onChange={({ target }) => setSearchText(target.value)}
              value={searchText}
              classes={{ container: classes.searchTextContainer }}
            />
          </Grid>
          <Grid item xs={1.5}>
            <CeroButton
              buttonText="Apply"
              className={classes.button}
              onClick={onApply}
              disabled={readOnlyMode}
            />
          </Grid>
        </Grid>
          <Grid item xs={2.5}>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item xs={9}>
                <CeroButton
                  buttonText="Add Facility"
                  className={classes.button}
                  onClick={onAddData}
                  disabled={readOnlyMode}
                />
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    );
};

export default FacilitiesTableFilter;
