import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import { glossaryContent } from "../../constants";

import useStyles from "./styles";
import { useState } from "react";

const Glossary = () => {
  const classes = useStyles();

    const [highlightedLetters, setHighlightedLetters] = useState([]);
    const [selectedLetter, setSelectedLetter] = useState("All");

   const availableLetters = glossaryContent.map((item) => item.title);

     const handleLetterClick = (letter) => {
       setSelectedLetter(letter);
     };

     const filteredContent = () => {
       if (selectedLetter === "All") {
         return glossaryContent;
       } else {
         return glossaryContent.filter((item) => item.title === selectedLetter);
       }
     };

  glossaryContent.forEach(item => {
    const firstLetters = item.title.match(/[A-Za-z]/g);
    if (firstLetters) {
      firstLetters.forEach(letter => {
        if (!highlightedLetters.includes(letter.toUpperCase())) {
          setHighlightedLetters(prevState => [...prevState, letter.toUpperCase()]);
        }
      });
    }
  });


  return (
    <DashboardLayout>
      <Paper className={classes.container}>
        <Typography
          variant="h1"
          fontSize={24}
          fontWeight={500}
          display={"flex"}
          justifyContent={"center"}
          p={8}
        >
          Terms And Definitions
        </Typography>
        <Box className={classes.letterContainer}>
          <button
            style={{
              backgroundColor:
                selectedLetter === "All" ? "#F4E7EC" : "transparent",
              color: selectedLetter === "All" ? "#8C2148" : "#0088C7",
              border:
                selectedLetter === "All"
                  ? "2px solid #8C2148"
                  : "2px solid #5A404E",
              width: 50,
              fontSize: "14px",
              borderRadius: 5,
              padding: "4px",
            }}
            onClick={() => handleLetterClick("All")}
          >
            All
          </button>
          {Array.from(Array(26)).map((_, index) => {
            const letter = String.fromCharCode(65 + index);
            return (
              <button
                key={index}
                disabled={!availableLetters.includes(letter)}
                onClick={() => handleLetterClick(letter)}
                style={{
                  backgroundColor:
                    selectedLetter === letter ? "#F4E7EC" : "transparent",
                  color:
                    availableLetters.includes(letter) &&
                    selectedLetter === letter
                      ? "#8C2148"
                      : availableLetters.includes(letter)
                      ? "#0088C7"
                      : "#5A404E",
                  border:
                    selectedLetter === letter
                      ? "2px solid #8C2148"
                      : "2px solid #5A404E",
                  width: 40,
                  fontSize: "14px",
                  borderRadius: 5,
                  padding: "6px",
                }}
              >
                {letter}
              </button>
            );
          })}
        </Box>
        {filteredContent().map((item) => (
          <Grid key={item.id} paddingLeft={8}>
            <Typography className={classes.sectionTitle} key={item.id}>
              {item.title}
            </Typography>
            {item.subItems.map((subItem) => (
              <>
                <Typography className={classes.subItemTitle} key={subItem.id}>
                  {subItem.title}
                </Typography>
                <Typography className={classes.subItemValue} key={subItem.id}>
                  {subItem.value}
                </Typography>
              </>
            ))}
          </Grid>
        ))}
      </Paper>
    </DashboardLayout>
  );
};

export default Glossary;
