import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(10),
    margin: theme.spacing(10, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(5),
    },
    borderRadius: 8,
  },
  buttonContainer: {
    padding: theme.spacing(2, 0),
  },
  cardTitle: {
    marginBottom: theme.spacing(8),
    fontWeight: 500,
    fontSize: 20,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  infoContainer: {
    display: "flex !important",
    alignItems: "start",
    gap: "46px",
    padding: theme.spacing(1, 0),
  },
  title: {
    width: "80px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  value: {
    fontWeight: 500,
    whiteSpace: "break-spaces",
  },
  planvalue: {
    fontWeight: 500,
    whiteSpace: "break-spaces",
    lineHeight:2.5,
    marginTop:-2.5
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default useStyles;
