import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(4),
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropdown: {
    background: "white",
  },
  autoComplete:{
    "& .MuiAutocomplete-root .MuiOutlinedInput-root":{
        padding: 0,
        marginTop: 13,
        backgroundColor: theme.palette.background.white,
    },

    "& .MuiOutlinedInput-root .MuiAutocomplete-input":{
        padding: '6.5px 4px 7.5px 10px',
    },
    "& .MuiFormControl-root > .MuiInputLabel-outlined ":{
        color: '#6a475a',
        paddingTop: theme.spacing(1),
    },
    "& .MuiFormControl-root > .Mui-focused ":{
        color: '#6a475a',
        opacity: 10,
        marginTop:12
    },
    "& .MuiFormControl-root > .MuiFormLabel-filled ":{
        marginTop:12
    },
},
}));

export default useStyles;
