import { Container, Grid } from "@mui/material";

import CeroInfoPair from "../../../../components/CeroInfoPair";
import useStyles from "./styles";
import dayjs from "dayjs";

const Status = (props) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
      <Grid item xs={6}>
      <CeroInfoPair title="Status" value={props.status} classes={{ container: classes.infoContainer, value: props.status === "In Progress" ? classes.Inprogress : classes[props.status] , title:classes.title}} />
      <CeroInfoPair title="Audited By" value={props.auditorAssigned  ? props.auditorAssigned : "NA"} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      <CeroInfoPair title="Audited On" value={props.auditedOn  !== null ? dayjs(props.auditedOn).format("DD MMM YYYY"): "NA"} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      <CeroInfoPair title="Approval Cycle" value={props.approval_cycle_type?.split("_").join(" ")} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      <CeroInfoPair title="Audit Cycle" value={props.audit_cycle_type?.split("_").join(" ")} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      </Grid>
      <Grid item xs={6}>
      <CeroInfoPair title="Period" value={props.period} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      <CeroInfoPair title="Assessment Year" value={props.assessmentYear} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      <CeroInfoPair title="Facility" value={props.facility} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      <CeroInfoPair title="No of tickets" value={props.noOfTickets} classes={{ container: classes.infoContainer, title:classes.title, value:classes.value}} />
      </Grid>
      </Grid>
    </Container>
  );
};

export default Status;
