import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        height: '320px',
        borderRadius: 8,
        margin: theme.spacing(4, 0),
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    },
    graphTitle: {
        fontSize: 18,
        fontWeight: 400,
        padding: theme.spacing(2,0),
    },
    chart:{
        height: "80%",
        width: "100%"
    },
    circle:{
        width: "160px",
        height: "160px",
        borderRadius: "50%", 
        border: "15px solid #6FBFBA",
        marginBottom: theme.spacing(4)
    },
    value:{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    }
}));

export default useStyles;
