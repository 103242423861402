import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from 'notistack';

import { STATUS } from "../../../redux/constants";
import { schemeValidation, updateAntiCompetitiveDisclosureValidation } from './schema';
import { getAssessmentPeriod, resetAddCombustionStatus, updateAntiCorruptionDisclosure } from '../../../redux/actions';
import CeroButton from '../../../components/CeroButton';
import CeroInput from '../../../components/CeroInput';
import useStyles from "./styles";
import CeroEpochDatePicker from '../../../components/CeroDateTimePicker/CeroEpochDatePicker';
import dayjs from 'dayjs';
import CeroSelect from '../../../components/CeroSelect';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import CeroQuestionWithCheckbox from '../../../components/CeroQuestionWithCheckbox';
import { sampleYear } from '../../../constants';

const EditAntiCorruptionDisclosureForm = (props) => {
    const { emissionData, onCancel } = props
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const facilitiesData = useSelector(state => state.listings.listFacilities.data);
    const updateEmissionData = useSelector(state => state.emission.updateAntiCorruptionDisclosure);
    const facilitiesList = facilitiesData.map(item => ({ key: item.id, value: item.name }));

    const assessmentData = useSelector(
        (state) => state.assessment.assessmentPeriod.data
      );
    
      const assessmentOptions = Array.isArray(assessmentData)
        ? assessmentData.map((item) => ({
            key: item.period,
            value: item.period,
          }))
        : [];
    
      const yearList = sampleYear().map((item) => ({
        id: item.key,
        label: item.value,
      }));
    
      const handleAnswerChange = (fieldName, answer) => {
        formik.handleChange({ target: { name: fieldName, value: answer } });
      };

    const formik = useFormik({
        initialValues: {
      facility: emissionData.facility_id || '',
      year: emissionData.year || '',
      assessmentPeriod: emissionData.assessment_period || '',
      numberOfOperation: emissionData.number_of_operation || '',
      incidentNumber: emissionData.number_of_incident || '',
      riskRelated: emissionData.risk_related_corruption || '',
      isOrganizationCorruption: emissionData.is_organisation_accessed_for_corruption || '',
      corruptionDescription: emissionData.description_of_corruption || '',
        },
        validationSchema: schemeValidation,
        onSubmit: () => {},
    });

    useEffect(() => {
        const { facility, year } = formik.values;
        if (facility && year) {
          dispatch(getAssessmentPeriod(year, facility, 'anti_corruption_disclosure', emissionData.id));
        }
      }, [formik.values.facility, formik.values.year, dispatch]);

    useEffect(() => {
        if (updateEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Anti corruption disclosure updated successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus());
            onCancel();
        } else if (updateEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(updateEmissionData.message.message || "Something went wrong", { variant: 'error' });
            dispatch(resetAddCombustionStatus());
        }
    }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

    const onUpdateAntiCorruptionDisclosure = () => {
        const requestData = {
        id: emissionData.id,
        facility_id: formik.values.facility,
        assessment_period: formik.values.assessmentPeriod,
        year: formik.values.year,
        is_organisation_accessed_for_corruption:formik.values.isOrganizationCorruption,
        number_of_operation: formik.values.numberOfOperation,
        number_of_incident: formik.values.incidentNumber,
        risk_related_corruption: formik.values.riskRelated,
        description_of_corruption: formik.values.corruptionDescription,
        save: true,
        };
        dispatch(updateAntiCorruptionDisclosure(requestData));
    };

    return (
        <>
      <div className={classes.backContainer} onClick={onCancel}>
      <ArrowBackIosIcon />
      Back
      </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Edit Anti Corruption Disclosure </Typography>
                <Box className={classes.topContainer}>
                <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroQuestionWithCheckbox
              question="Indicate if your organization has assessed its operations for risks related to corruption."
              formik={formik}
              fieldName="isOrganizationCorruption"
              onChange={handleAnswerChange}
              checkedField={formik.values.isOrganizationCorruption}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.isOrganizationCorruption === "Yes" &&
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  required
                  id="numberOfOperation"
                  name="numberOfOperation"
                  label="Total Number of Operations Assessed for Risks Related to Corruption"
                  fullWidth
                  value={formik.values.numberOfOperation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incidentNumber &&
                    formik.errors.incidentNumber
                  }
                />
              </Grid>
              <Grid item container direction="column" md={4} xs={6}>
                <CeroInput
                  required
                  id="incidentNumber"
                  name="incidentNumber"
                  label="Number of Incidents reported"
                  fullWidth
                  value={formik.values.incidentNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incidentNumber &&
                    formik.errors.incidentNumber
                  }
                />
              </Grid>
            </Grid>}
            <CeroInput
              required
              id="riskRelated"
              name="riskRelated"
              label="Describe any significant risks related to corruption identified during the assessment."
              fullWidth
              multiline
              rows="2"
              value={formik.values.riskRelated}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.riskRelated && formik.errors.riskRelated}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="corruptionDescription"
              name="corruptionDescription"
              label="Describe any confirmed incidents of corruption within your organization and actions taken to address them."
              fullWidth
              multiline
              rows="2"
              value={formik.values.corruptionDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.corruptionDescription &&
                formik.errors.corruptionDescription
              }
              classes={{ container: classes.textAreaContainer }}
            />
                </Box>
                <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Update"
                    disabled={!formik.dirty || !formik.isValid}
                    className={clsx(classes.button, classes.buttonPrimary)}
                    onClick={onUpdateAntiCorruptionDisclosure}
                />
                <CeroButton
                    buttonText="Clear"
                    variant="outlined"
                    className={clsx(classes.button, classes.buttonSecondary)}
                    onClick={formik.resetForm}
                />
            </Box>
            </Box>
        </Container>
        </>
    );
};

export default EditAntiCorruptionDisclosureForm;
