import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import useStyles from "../styles";

const MenuItems = ({topic}) => {
  const classes = useStyles();



  return (
    <Box>
      <Box display="flex" justifyContent="center" gap={4} mb={2} mt={2}>
        <DoneIcon sx={{ color: "#8C2148" }} />
        <Typography className={classes.menuItemText}>
          {topic}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};

export default MenuItems;
