import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    height: "40px",
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.white,
    },
    "& .MuiGrid-root.MuiGrid-item": {
      margin: 0,
    },
  },
  container: {
    padding: 0,
    margin: 0,
  },
  select: {
    padding: theme.spacing(2),
  },
  input: {
    padding: 0,
  },
  dropdown: {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "pink !important",
    },
    ".Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "green !important",
    },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: "100%",
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    background: theme.palette.background.white,
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  card: {
    background: "white",
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  filterText: {
    whiteSpace: "nowrap",
    fontWeight: 500,
    paddingRight: theme.spacing(3),
  },
  multiSelect: {
    width: "80%",
    margin: theme.spacing(0, 1),
    height: 33,
    borderColor: "#D1BDC8 !important",
    borderRadius: 8,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D1BDC8 !important",
      borderRadius: 8,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D1BDC8 !important",
      borderRadius: 8,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D1BDC8 !important",
      borderRadius: 8,
    },
  },
  labels: {
    display: "flex",
    background: "white",
    marginRight: 2,
    alignItems: "center",
    letterSpacing: 0,
    "justCheckSelected-66 > fieldset > legend": {
      display: "none !important",
    },
  },
  labelText: {
    color: "black",
    fontSize: 14,
    letterSpacing: 0,
    fontWeight: 400,
  },
  justCheckSelected: {
    "& > fieldset > legend": {
      display: "none !important",
    },
  },
  label: {
    margin: "-10px !important",
    "&. MuiFormLabel-filled": {
      margin: "-10 !important",
    },
  },
  tabsContainer: {
    // position: "relative",
    // backgroundColor: "#F9F4F6",
    // width:"50%"
  },
  tabs: {
    zIndex: 1,
    position: "relative",
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.login.loginButton,
      display: "none",
    },
    borderRadius: 10,
    // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    "& .MuiTabs-flexContainer": {
      margin: "auto",
      display: "flex",
      zIndex: 2,
      borderRadius: 5,
      // backgroundColor: "#E0E0E0",
      // "@media screen and (min-width: 1706px) and (min-height: 377px)": {
      //   width: "55% !important",
      // },
    },
    "& .MuiTab-root.Mui-selected": {
      color: `${theme.palette.login.loginButton} !important`,
    },
  },
  tabOption: {
    textTransform: "capitalize",
    // fontSize: 10,
  },
  tabLabel: {
    display: "flex",
    alignItems: "center",
  },
  tabTitle: {
    marginLeft: 8,
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft:20,
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default useStyles;

