import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Stepper,
  StepLabel,
  Step,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
// import { schemeValidation } from './schema';
import {
  addEmployeeHire,
  getAssessmentPeriod,
  getEmissionInputFormat,
  resetAddCombustionStatus,
  resetGetEmissionInputFormatStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroDropdown from "../../../components/CeroDropdown";
import { sampleYear } from "../../../constants";
import CeroAddTopic from "../../../components/CeroAddtopic";

export const dynamicColumns = [
  {
    columnKey: "topic",
    columnId: "topic",
    columnHeader: "Title",
  },
  {
    columnKey: "total",
    columnId: "total",
    columnHeader: "Total",
  },
  {
    columnKey: "female",
    columnId: "female",
    columnHeader: "Female",
  },
  {
    columnKey: "male",
    columnId: "male",
    columnHeader: "Male",
  },
  {
    columnKey: "other",
    columnId: "other",
    columnHeader: "Others",
  },
];
const AddEmployeeAndTurnover = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [facility, setFacility] = useState("");
  const [filterAssessmentPeriod, setFilterAssessmentPeriod] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      assessment_period: "",
      year: currentYear,
      facility_id: "",
      employees_at_start_period_gender: "",
      permanent_employees_at_start_period_gender: "",
      temporary_employees_at_start_period_gender: "",
      employees_resigned_gender: "",
      permanent_employees_resigned_gender: "",
      temporary_employees_resigned_gender: "",
      employees_hired_gender: "",
      permanent_employees_hired_gender: "",
      temporary_employees_hired_gender: "",
      employees_at_start_period_age: "",
      permanent_employees_at_start_period_age: "",
      temporary_employees_at_start_period_age: "",
      employees_resigned_age: "",
      permanent_employees_resigned_age: "",
      temporary_employees_resigned_age: "",
      employees_hired_age: "",
      permanent_employees_hired_age: "",
      temporary_employees_hired_age: "",
    },
    // validationSchema: addStationaryCombustionValidation,
    onSubmit: () => { },
  });
  const [filterYear, setYear] = useState(currentYear);

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  function setData(type, value) {
    if (type === "year") {
      setYear(value);
    } else if (type === "facility") {
      setFacility(value);
    } else if (type === "assessmentPeriod") {
      setFilterAssessmentPeriod(value);
    }
  }

  const emissionInputs = useSelector(
    (state) => state.emission.emissionInputs.data
  );

  useEffect(() => {
    if (facility && filterYear) {
      dispatch(getAssessmentPeriod(filterYear, facility, 'employees_hire_and_turnover'));
    }
  }, [filterYear, facility, dispatch]);

  useEffect(() => {
    if (facility && filterYear && filterAssessmentPeriod) {
      dispatch(getEmissionInputFormat("employees_hire_and_turnover", filterAssessmentPeriod, filterYear, facility));
    }
    else{
      dispatch(resetGetEmissionInputFormatStatus());
    }
  }, [facility,filterYear, filterAssessmentPeriod, dispatch]);
  
 

  useEffect(() => {
    setEmployeesAtStartPeriodGender({ total: emissionInputs.number_of_employees || 0, male: emissionInputs.number_of_male_employees || 0, female: emissionInputs.number_of_female_employees || 0, other: emissionInputs.number_of_other_employees || 0 })
    setPermanentEmployeesAtStartPeriodGender({ total: emissionInputs.number_of_permanent_employees || 0, male: emissionInputs.number_of_male_employees_permanent || 0, female: emissionInputs.number_of_female_employees_permanent || 0, other: emissionInputs.number_of_other_employees_permanent || 0 })
    setTemporaryEmployeesAtStartPeriodGender({ total: emissionInputs.number_of_temporary_employees || 0, male: emissionInputs.number_of_male_employees_temporary || 0, female: emissionInputs.number_of_female_employees_temporary || 0, other: emissionInputs.number_of_other_employees_temporary || 0 })
    setEmployeesAtStartPeriodAge({ total: emissionInputs.number_of_employees || 0 || 0, under_30: emissionInputs.number_of_under_30_employees || 0, between_30_50: emissionInputs.number_of_between_30_50_employees || 0, over_50: emissionInputs.number_of_over_50_employees || 0 })
    setPermanentEmployeesAtStartPeriodAge({ total: emissionInputs.number_of_permanent_employees || 0, under_30: emissionInputs.number_of_under_30_employees_permanent || 0, between_30_50: emissionInputs.number_of_between_30_50_employees_permanent || 0, over_50: emissionInputs.number_of_over_50_employees_permanent || 0 });
    seTemporaryEmployeesAtStartPeriodAge({ total: emissionInputs.number_of_temporary_employees || 0, under_30: emissionInputs.number_of_under_30_employees_temporary || 0, between_30_50: emissionInputs.number_of_between_30_50_employees_temporary || 0, over_50: emissionInputs.number_of_over_50_employees_temporary || 0 });
  }, [emissionInputs]);

  const [employeesAtStartPeriodGender, setEmployeesAtStartPeriodGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [permanentEmployeesAtStartPeriodGender, setPermanentEmployeesAtStartPeriodGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [temporaryEmployeesAtStartPeriodGender, setTemporaryEmployeesAtStartPeriodGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [employeesResignedGender, setEmployeesResignedGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [permanentEmployeesResignedGender, setPermanentEmployeesResignedGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [temporaryEmployeesResignedGender, setTemporaryEmployeesResignedGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [employeesHiredGender, setEmployeesHiredGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [permanentEmployeesHiredGender, setPermanentEmployeesHiredGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [temporaryEmployeesHiredGender, setTemporaryEmployeesHiredGender] = useState({ total: 0, male: 0, female: 0, other: 0 });
  const [employeesAtStartPeriodAge, setEmployeesAtStartPeriodAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [permanentEmployeesAtStartPeriodAge, setPermanentEmployeesAtStartPeriodAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [temporaryEmployeesAtStartPeriodAge, seTemporaryEmployeesAtStartPeriodAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [employeesResignedAge, setEmployeesResignedAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [permanentEmployeesResignedAge, setPermanentEmployeesResignedAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [temporaryEmployeesResignedAge, setTemporaryEmployeesResignedAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [employeesHiredAge, setEmployeesHiredAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [permanentEmployeesHiredAge, setPermanentEmployeesHiredAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [temporaryEmployeesHiredAge, setTemporaryEmployeesHiredAge] = useState({ total: 0, under_30: 0, between_30_50: 0, over_50: 0 });
  const [showNextButton, setShowNextButton] = useState(true)
  const [showActionButton, setShowActionButton] = useState(true)
  const [isFilterValid, setIsFilterValid] = useState(false)
  const [preDataValid, setPreDataValid] = useState(false)

  const handleRowSelection = (selectedRow) => {
  };
  const byGender = [
    { columnKey: "title", columnId: "title", columnHeader: props.columnHeader },
    { columnKey: "total", columnId: "total", columnHeader: "Total" },
    { columnKey: "female", columnId: "female", columnHeader: "Female" },
    { columnKey: "male", columnId: "male", columnHeader: "Male" },
    { columnKey: "other", columnId: "other", columnHeader: "Others" },
  ];

  const byAgegroup = [
    { columnKey: "title", columnId: "title", columnHeader: props.columnHeader },
    { columnKey: "total", columnId: "total", columnHeader: "Total" },
    { columnKey: "under_30", columnId: "under_30", columnHeader: "Under 30 years" },
    { columnKey: "between_30_50", columnId: "between_30_50", columnHeader: "30-50 years" },
    { columnKey: "over_50", columnId: "over_50", columnHeader: "Over 50 years" },
  ];

  const questionsForGenderStart = [
    { id: 10, topic: "Number of Employees", total: 0, female: 0, male: 0, other: 0 , dataset:employeesAtStartPeriodGender},
    { id: 11, topic: "Number of Permanent Employees", total: 0,female: 0,male: 0,other: 0, dataset:permanentEmployeesAtStartPeriodGender },
    { id: 12, topic: "Number of Temporary Employees", total: 0, female: 0, male: 0, other: 0, dataset:temporaryEmployeesAtStartPeriodGender
   },
  ];

  const questionsForGenderResigned= [
    { id: 13, topic: "Number of Employees", total: 0, female: 0, male: 0, other: 0, dataset:employeesResignedGender},
    { id: 14, topic: "Number of Permanent Employees", total: 0,female: 0,male: 0,other: 0, dataset:permanentEmployeesResignedGender },
    { id: 15, topic: "Number of Temporary Employees", total: 0, female: 0, male: 0, other: 0, dataset:temporaryEmployeesResignedGender },
  ];

  const questionsForGenderHired= [
    { id: 16, topic: "Number of Employees", total: 0, female: 0, male: 0, other: 0, dataset:employeesHiredGender },
    { id: 17, topic: "Number of Permanent Employees", total: 0,female: 0,male: 0,other: 0, dataset:permanentEmployeesHiredGender },
    { id: 18, topic: "Number of Temporary Employees", total: 0, female: 0, male: 0, other: 0, dataset:temporaryEmployeesHiredGender },
  ];
  

  const questionsForAgeStart = [
    { id: 1, topic: "Number of Employees", total: 0, female: 0, male: 0, other: 0, dataset:employeesAtStartPeriodAge },
    { id: 2, topic: "Number of Permanent Employees", total: 0,female: 0,male: 0,other: 0, dataset:permanentEmployeesAtStartPeriodAge },
    { id: 3, topic: "Number of Temporary Employees", total: 0, female: 0, male: 0, other: 0, dataset:temporaryEmployeesAtStartPeriodAge },
  ];

  const questionsForAgeResigned= [
    { id: 4, topic: "Number of Employees", total: 0, female: 0, male: 0, other: 0, dataset:employeesResignedAge },
    { id: 5, topic: "Number of Permanent Employees", total: 0,female: 0,male: 0,other: 0, dataset:permanentEmployeesResignedAge },
    { id: 6, topic: "Number of Temporary Employees", total: 0, female: 0, male: 0, other: 0, dataset:temporaryEmployeesResignedAge },
  ];

  const questionsForAgeHired= [
    { id: 7, topic: "Number of Employees", total: 0, female: 0, male: 0, other: 0, dataset:employeesHiredAge },
    { id: 8, topic: "Number of Permanent Employees", total: 0,female: 0,male: 0,other: 0, dataset:permanentEmployeesHiredAge },
    { id: 9, topic: "Number of Temporary Employees", total: 0, female: 0, male: 0, other: 0, dataset:temporaryEmployeesHiredAge },
  ];

  const handleNext = (step, userType, data) => {
    checkBasicData()
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    checkBasicData()
  }, [permanentEmployeesAtStartPeriodAge, temporaryEmployeesAtStartPeriodAge, permanentEmployeesResignedAge, temporaryEmployeesResignedAge, permanentEmployeesHiredAge, temporaryEmployeesHiredAge]);
  
  useEffect(() => {
    setIsFilterValid((filterAssessmentPeriod&&filterAssessmentPeriod.trim() !== "")&&(filterYear)&&(facility&&facility.trim() !== ""))
  },[filterAssessmentPeriod, filterYear, facility]);
  
  const onAddData = () => {
    const requestData = {
      assessment_period: filterAssessmentPeriod,
      year: filterYear,
      facility_id: facility,
      employees_at_start_period_gender: employeesAtStartPeriodGender,
      permanent_employees_at_start_period_gender: permanentEmployeesAtStartPeriodGender,
      temporary_employees_at_start_period_gender: temporaryEmployeesAtStartPeriodGender,
      employees_resigned_gender: employeesResignedGender,
      permanent_employees_resigned_gender: permanentEmployeesResignedGender,
      temporary_employees_resigned_gender: temporaryEmployeesResignedGender,
      employees_hired_gender: employeesHiredGender,
      permanent_employees_hired_gender: permanentEmployeesHiredGender,
      temporary_employees_hired_gender: temporaryEmployeesHiredGender,
      employees_at_start_period_age: employeesAtStartPeriodAge,
      permanent_employees_at_start_period_age: permanentEmployeesAtStartPeriodAge,
      temporary_employees_at_start_period_age: temporaryEmployeesAtStartPeriodAge,
      employees_resigned_age: employeesResignedAge,
      permanent_employees_resigned_age: permanentEmployeesResignedAge,
      temporary_employees_resigned_age: temporaryEmployeesResignedAge,
      employees_hired_age: employeesHiredAge,
      permanent_employees_hired_age: permanentEmployeesHiredAge,
      temporary_employees_hired_age: temporaryEmployeesHiredAge,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addEmployeeHire(requestData));
  };

  const addDataStatus = useSelector(
    (state) => state.emission.addEmployeeHire
  );

  useEffect(() => {
    if (addDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Hire and Turnover added successfully", {variant: "success",});
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addDataStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
       addDataStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error",}
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addDataStatus, dispatch, enqueueSnackbar, onCancel]);

  const checkTotals = (totalEmp, permanentEmp, tempEmp, type) =>{
    let isValid = false;
    if (Object.keys(totalEmp).includes('male')){
      const horizCheck = [totalEmp, permanentEmp, tempEmp].map(data=>{
        const { total, male, female, other } = data;
        return total === (male + female + other) 
      }
      )
      if (horizCheck.every(item => item === true)) {
        const isAllTotal = totalEmp.total === permanentEmp.total + tempEmp.total;
        const isAllFemale = totalEmp.female === permanentEmp.female + tempEmp.female;
        const isAllMale = totalEmp.male === permanentEmp.male + tempEmp.male;
        if(isAllTotal&&isAllFemale&&isAllMale)
          isValid = true;
      }
      setShowNextButton(isValid);
    }
    else{
      const horizCheck = [totalEmp, permanentEmp, tempEmp].map(data=>{
        const { total, over_50, under_30, between_30_50 } = data;
        return total === (under_30 + between_30_50 + over_50) 
      }
      )
      if (horizCheck.every(item => item === true)) {
        const isAllTotal = totalEmp.total === permanentEmp.total + tempEmp.total;
        const isAllUnder30 = totalEmp.under_30 === permanentEmp.under_30 + tempEmp.under_30;
        const isAllBetween3050 = totalEmp.between_30_50 === permanentEmp.between_30_50 + tempEmp.between_30_50;
        if(isAllTotal&&isAllUnder30&&isAllBetween3050)
          isValid = true;
      }
      setShowActionButton(isValid);
    }
    return isValid
    
}
  const checkBasicData = () =>{
    console.log("its printing");
    var flag = true
    if (employeesAtStartPeriodAge.total) {
        if (!checkTotals(employeesAtStartPeriodAge, permanentEmployeesAtStartPeriodAge, temporaryEmployeesAtStartPeriodAge, 'total'))
            flag = false
    }
    if (employeesResignedAge.total){
      if (!checkTotals(employeesResignedAge, permanentEmployeesResignedAge, temporaryEmployeesResignedAge, 'total'))
          flag = false
    }
    if (employeesHiredAge.total){
      if (!checkTotals(employeesHiredAge, permanentEmployeesHiredAge, temporaryEmployeesHiredAge, 'total'))
          flag = false
    }
    setPreDataValid(flag)
  }
  


  const handleDataChange = (questionId, columnKey, value, name) => {  
    if (name === '10-total') {
      setEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesAtStartPeriodGender, [columnKey]:parseInt(value)}, permanentEmployeesAtStartPeriodGender, temporaryEmployeesAtStartPeriodGender, columnKey)
    } else if (name === '10-female') {
      setEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals({...employeesAtStartPeriodGender, [columnKey]:parseInt(value), other: Math.max(0, (employeesAtStartPeriodGender.total || 0) - (parseInt(value) || 0) - (employeesAtStartPeriodGender.male || 0))}, permanentEmployeesAtStartPeriodGender, temporaryEmployeesAtStartPeriodGender, columnKey)
    } else if (name === '10-male') {
      setEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals({...employeesAtStartPeriodGender, [columnKey]:parseInt(value),other: Math.max(0, (employeesAtStartPeriodGender.total || 0) - (parseInt(value) || 0) - (employeesAtStartPeriodGender.female || 0))}, permanentEmployeesAtStartPeriodGender, temporaryEmployeesAtStartPeriodGender, columnKey)
    } else if (name === '10-others') {
      setEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesAtStartPeriodGender, [columnKey]:parseInt(value)}, permanentEmployeesAtStartPeriodGender, temporaryEmployeesAtStartPeriodGender)
    } 

    if (name === '11-total') {
      setPermanentEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setPermanentEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodGender, {...permanentEmployeesAtStartPeriodGender, [columnKey]:parseInt(value)}, temporaryEmployeesAtStartPeriodGender, columnKey)
    } else if (name === '11-female') {
      setPermanentEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals(employeesAtStartPeriodGender, {...permanentEmployeesAtStartPeriodGender, [columnKey]:parseInt(value), other: Math.max(0, (permanentEmployeesAtStartPeriodGender.total || 0) - (parseInt(value) || 0) - (permanentEmployeesAtStartPeriodGender.male || 0))}, temporaryEmployeesAtStartPeriodGender, columnKey)
    } else if (name === '11-male') {
      setPermanentEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals(employeesAtStartPeriodGender, {...permanentEmployeesAtStartPeriodGender, [columnKey]:parseInt(value), other: Math.max(0, (permanentEmployeesAtStartPeriodGender.total || 0) - (parseInt(value) || 0) - (permanentEmployeesAtStartPeriodGender.female || 0))}, temporaryEmployeesAtStartPeriodGender, columnKey)
    } else if (name === '11-others') {
      setPermanentEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodGender, {...permanentEmployeesAtStartPeriodGender, [columnKey]:parseInt(value)}, temporaryEmployeesAtStartPeriodGender, columnKey)
    } 

    if (name === '12-total') {
      setTemporaryEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      seTemporaryEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodGender, permanentEmployeesAtStartPeriodGender, {...temporaryEmployeesAtStartPeriodGender, [columnKey]:parseInt(value)}, columnKey)
    } else if (name === '12-female') {
      setTemporaryEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals(employeesAtStartPeriodGender, permanentEmployeesAtStartPeriodGender, {...temporaryEmployeesAtStartPeriodGender, [columnKey]:parseInt(value), other: Math.max(0, (temporaryEmployeesAtStartPeriodGender.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesAtStartPeriodGender.male || 0))}, columnKey)
    } else if (name === '12-male') {
      setTemporaryEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals( employeesAtStartPeriodGender, permanentEmployeesAtStartPeriodGender, {...temporaryEmployeesAtStartPeriodGender, [columnKey]:parseInt(value), other: Math.max(0, (temporaryEmployeesAtStartPeriodGender.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesAtStartPeriodGender.female || 0))}, columnKey)
    } else if (name === '12-others') {
      setTemporaryEmployeesAtStartPeriodGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodGender, permanentEmployeesAtStartPeriodGender, {...temporaryEmployeesAtStartPeriodGender, [columnKey]:parseInt(value)}, columnKey)
    } 

    if (name === '13-total') {
      setEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesResignedGender, [columnKey]:parseInt(value)}, permanentEmployeesResignedGender, temporaryEmployeesResignedGender, columnKey)

    } else if (name === '13-female') {
      setEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals({...employeesResignedGender, [columnKey]:parseInt(value), other: Math.max(0, (employeesResignedGender.total || 0) - (parseInt(value) || 0) - (employeesResignedGender.male || 0))}, permanentEmployeesResignedGender, temporaryEmployeesResignedGender, columnKey)

    } else if (name === '13-male') {
      setEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals({...employeesResignedGender, [columnKey]:parseInt(value), other: Math.max(0, (employeesResignedGender.total || 0) - (parseInt(value) || 0) - (employeesResignedGender.female || 0))}, permanentEmployeesResignedGender, temporaryEmployeesResignedGender, columnKey)

    } else if (name === '13-others') {
      setEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesResignedGender, [columnKey]:parseInt(value)}, permanentEmployeesResignedGender, temporaryEmployeesResignedGender, columnKey)

    } 

    if (name === '14-total') {
      setPermanentEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setPermanentEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedGender, {...permanentEmployeesResignedGender, [columnKey]:parseInt(value)}, temporaryEmployeesResignedGender, columnKey)

    } else if (name === '14-female') {
      setPermanentEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals(employeesResignedGender, {...permanentEmployeesResignedGender, [columnKey]:parseInt(value), other: Math.max(0, (permanentEmployeesResignedGender.total || 0) - (parseInt(value) || 0) - (permanentEmployeesResignedGender.male || 0))}, temporaryEmployeesResignedGender, columnKey)

    } else if (name === '14-male') {
      setPermanentEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals(employeesResignedGender, {...permanentEmployeesResignedGender, [columnKey]:parseInt(value), other: Math.max(0, (permanentEmployeesResignedGender.total || 0) - (parseInt(value) || 0) - (permanentEmployeesResignedGender.female || 0))}, temporaryEmployeesResignedGender, columnKey)

    } else if (name === '14-others') {
      setPermanentEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedGender, {...permanentEmployeesResignedGender, [columnKey]:parseInt(value)}, temporaryEmployeesResignedGender, columnKey)

    } 

    if (name === '15-total') {
      setTemporaryEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setTemporaryEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedGender, permanentEmployeesResignedGender, {...temporaryEmployeesResignedGender, [columnKey]:parseInt(value)}, columnKey)
    } else if (name === '15-female') {
      setTemporaryEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals(employeesResignedGender, permanentEmployeesResignedGender, {...temporaryEmployeesResignedGender, [columnKey]:parseInt(value), other: Math.max(0, (temporaryEmployeesResignedGender.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesResignedGender.male || 0))}, columnKey)

    } else if (name === '15-male') {
      setTemporaryEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals(employeesResignedGender, permanentEmployeesResignedGender, {...temporaryEmployeesResignedGender, [columnKey]:parseInt(value), other: Math.max(0, (temporaryEmployeesResignedGender.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesResignedGender.female || 0))}, columnKey)

    } else if (name === '15-others') {
      setTemporaryEmployeesResignedGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedGender, permanentEmployeesResignedGender, {...temporaryEmployeesResignedGender, [columnKey]:parseInt(value)}, columnKey)

    } 
    
    if (name === '16-total') {
      setEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesHiredGender, [columnKey]:parseInt(value)}, permanentEmployeesHiredGender, temporaryEmployeesHiredGender, columnKey)

    } else if (name === '16-female') {
      setEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals({...employeesHiredGender, [columnKey]:parseInt(value), other: Math.max(0, (employeesHiredGender.total || 0) - (parseInt(value) || 0) - (employeesHiredGender.male || 0))}, permanentEmployeesHiredGender, temporaryEmployeesHiredGender, columnKey)
    } else if (name === '16-male') {
      setEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals({...employeesHiredGender, [columnKey]:parseInt(value), other: Math.max(0, (employeesHiredGender.total || 0) - (parseInt(value) || 0) - (employeesHiredGender.female || 0))}, permanentEmployeesHiredGender, temporaryEmployeesHiredGender, columnKey)
    } else if (name === '16-others') {
      setEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesHiredGender, [columnKey]:parseInt(value)}, permanentEmployeesHiredGender, temporaryEmployeesHiredGender, columnKey)

    } 

    if (name === '17-total') {
      setPermanentEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setPermanentEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredGender, {...permanentEmployeesHiredGender, [columnKey]:parseInt(value)}, temporaryEmployeesHiredGender, columnKey)
    } else if (name === '17-female') {
      setPermanentEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals(employeesHiredGender, {...permanentEmployeesHiredGender, [columnKey]:parseInt(value), other: Math.max(0, (permanentEmployeesHiredGender.total || 0) - (parseInt(value) || 0) - (permanentEmployeesHiredGender.male || 0))}, temporaryEmployeesHiredGender, columnKey)

    } else if (name === '17-male') {
      setPermanentEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals(employeesHiredGender, {...permanentEmployeesHiredGender, [columnKey]:parseInt(value), other: Math.max(0, (permanentEmployeesHiredGender.total || 0) - (parseInt(value) || 0) - (permanentEmployeesHiredGender.female || 0))}, temporaryEmployeesHiredGender, columnKey)

    } else if (name === '17-others') {
      setPermanentEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredGender, {...permanentEmployeesHiredGender, [columnKey]:parseInt(value)}, temporaryEmployeesHiredGender, columnKey)

    } 

    if (name === '18-total') {
      setTemporaryEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      setTemporaryEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredGender, permanentEmployeesHiredGender, {...temporaryEmployeesHiredGender, [columnKey]:parseInt(value)}, columnKey)
    } else if (name === '18-female') {
      setTemporaryEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.male || 0)),
      }));
      checkTotals(employeesHiredGender, permanentEmployeesHiredGender, {...temporaryEmployeesHiredGender, [columnKey]:parseInt(value), other: Math.max(0, (temporaryEmployeesHiredGender.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesHiredGender.male || 0))}, columnKey)

    } else if (name === '18-male') {
      setTemporaryEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        other: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.female || 0)),
      }));
      checkTotals(employeesHiredGender, permanentEmployeesHiredGender, {...temporaryEmployeesHiredGender, [columnKey]:parseInt(value), other: Math.max(0, (temporaryEmployeesHiredGender.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesHiredGender.female || 0))}, columnKey)

    } else if (name === '18-others') {
      setTemporaryEmployeesHiredGender(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredGender, permanentEmployeesHiredGender, {...temporaryEmployeesHiredGender, [columnKey]:parseInt(value)}, columnKey)

    } 

    if (name === '1-total') {
      setEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesAtStartPeriodAge, [columnKey]:parseInt(value)}, permanentEmployeesAtStartPeriodAge, temporaryEmployeesAtStartPeriodAge, columnKey)
      
    } else if (name === '1-under_30') {
      setEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals({...employeesAtStartPeriodAge, [columnKey]:parseInt(value), over_50: Math.max(0, (employeesAtStartPeriodAge.total || 0) - (parseInt(value) || 0) - (employeesAtStartPeriodAge.between_30_50 || 0))}, permanentEmployeesAtStartPeriodAge, temporaryEmployeesAtStartPeriodAge, columnKey)

    } else if (name === '1-between_30_50') {
      setEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals({...employeesAtStartPeriodAge, [columnKey]:parseInt(value), over_50: Math.max(0, (employeesAtStartPeriodAge.total || 0) - (parseInt(value) || 0) - (employeesAtStartPeriodAge.under_30 || 0))}, permanentEmployeesAtStartPeriodAge, temporaryEmployeesAtStartPeriodAge, columnKey)

    } else if (name === '1-over_50') {
      setEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesAtStartPeriodAge, [columnKey]:parseInt(value)}, permanentEmployeesAtStartPeriodAge, temporaryEmployeesAtStartPeriodAge, columnKey)

    }

    
    if (name === '2-total') {
      setPermanentEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodAge, {...permanentEmployeesAtStartPeriodAge, [columnKey]:parseInt(value)}, temporaryEmployeesAtStartPeriodAge, columnKey)

    } else if (name === '2-under_30') {
      setPermanentEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals(employeesAtStartPeriodAge, {...permanentEmployeesAtStartPeriodAge, [columnKey]:parseInt(value), over_50: Math.max(0, (permanentEmployeesAtStartPeriodAge.total || 0) - (parseInt(value) || 0) - (permanentEmployeesAtStartPeriodAge.between_30_50 || 0))}, temporaryEmployeesAtStartPeriodAge, columnKey)

    } else if (name === '2-between_30_50') {
      setPermanentEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals(employeesAtStartPeriodAge, {...permanentEmployeesAtStartPeriodAge, [columnKey]:parseInt(value), over_50: Math.max(0, (permanentEmployeesAtStartPeriodAge.total || 0) - (parseInt(value) || 0) - (permanentEmployeesAtStartPeriodAge.under_30 || 0))}, temporaryEmployeesAtStartPeriodAge, columnKey)

    } else if (name === '2-over_50') {
      setPermanentEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodAge, {...permanentEmployeesAtStartPeriodAge, [columnKey]:parseInt(value)}, temporaryEmployeesAtStartPeriodAge, columnKey)

    }

    if (name === '3-total') {
      seTemporaryEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodAge, temporaryEmployeesAtStartPeriodAge, {...temporaryEmployeesAtStartPeriodAge, [columnKey]:parseInt(value)}, columnKey)

    } else if (name === '3-under_30') {
      seTemporaryEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals(employeesAtStartPeriodAge, permanentEmployeesAtStartPeriodAge,{...temporaryEmployeesAtStartPeriodAge, [columnKey]:parseInt(value), over_50: Math.max(0, (temporaryEmployeesAtStartPeriodAge.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesAtStartPeriodAge.between_30_50 || 0))}, columnKey)

    } else if (name === '3-between_30_50') {
      seTemporaryEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals(employeesAtStartPeriodAge, permanentEmployeesAtStartPeriodAge, {...temporaryEmployeesAtStartPeriodAge, [columnKey]:parseInt(value), over_50: Math.max(0, (temporaryEmployeesAtStartPeriodAge.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesAtStartPeriodAge.under_30 || 0))}, columnKey)

    } else if (name === '3-over_50') {
      seTemporaryEmployeesAtStartPeriodAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesAtStartPeriodAge, permanentEmployeesAtStartPeriodAge, {...temporaryEmployeesAtStartPeriodAge, [columnKey]:parseInt(value)}, columnKey)

    }

    if (name === '4-total') {
      setEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesResignedAge, [columnKey]:parseInt(value)}, permanentEmployeesResignedAge, temporaryEmployeesResignedAge, columnKey)

    } else if (name === '4-under_30') {
      setEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals({...employeesResignedAge, [columnKey]:parseInt(value), over_50: Math.max(0, (employeesResignedAge.total || 0) - (parseInt(value) || 0) - (employeesResignedAge.between_30_50 || 0))}, permanentEmployeesResignedAge, temporaryEmployeesResignedAge, columnKey)

    } else if (name === '4-between_30_50') {
      setEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals({...employeesResignedAge, [columnKey]:parseInt(value), over_50: Math.max(0, (employeesResignedAge.total || 0) - (parseInt(value) || 0) - (employeesResignedAge.under_30 || 0))}, permanentEmployeesResignedAge, temporaryEmployeesResignedAge, columnKey)

    } else if (name === '4-over_50') {
      setEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesResignedAge, [columnKey]:parseInt(value)}, permanentEmployeesResignedAge,temporaryEmployeesResignedAge, columnKey)

    }

    if (name === '5-total') {
      setPermanentEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedAge, {...permanentEmployeesResignedAge, [columnKey]:parseInt(value)}, temporaryEmployeesResignedAge, columnKey)

    } else if (name === '5-under_30') {
      setPermanentEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals(employeesResignedAge, {...permanentEmployeesResignedAge, [columnKey]:parseInt(value), over_50: Math.max(0, (permanentEmployeesResignedAge.total || 0) - (parseInt(value) || 0) - (permanentEmployeesResignedAge.between_30_50 || 0))}, temporaryEmployeesResignedAge, columnKey)

    } else if (name === '5-between_30_50') {
      setPermanentEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals(employeesResignedAge, {...permanentEmployeesResignedAge, [columnKey]:parseInt(value), over_50: Math.max(0, (permanentEmployeesResignedAge.total || 0) - (parseInt(value) || 0) - (permanentEmployeesResignedAge.under_30 || 0))}, temporaryEmployeesResignedAge, columnKey)

    } else if (name === '5-over_50') {
      setPermanentEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedAge, {...permanentEmployeesResignedAge, [columnKey]:parseInt(value)}, temporaryEmployeesResignedAge, columnKey)

    }

    if (name === '6-total') {
      setTemporaryEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedAge, permanentEmployeesResignedAge, {...temporaryEmployeesResignedAge, [columnKey]:parseInt(value)}, columnKey)
    } else if (name === '6-under_30') {
      setTemporaryEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals(employeesResignedAge, permanentEmployeesResignedAge, {...temporaryEmployeesResignedAge, [columnKey]:parseInt(value), over_50: Math.max(0, (temporaryEmployeesResignedAge.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesResignedAge.between_30_50 || 0))}, columnKey)

    } else if (name === '6-between_30_50') {
      setTemporaryEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals(employeesResignedAge, permanentEmployeesResignedAge, {...temporaryEmployeesResignedAge, [columnKey]:parseInt(value), over_50: Math.max(0, (temporaryEmployeesResignedAge.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesResignedAge.under_30 || 0))}, columnKey)

    } else if (name === '6-over_50') {
      setTemporaryEmployeesResignedAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesResignedAge, permanentEmployeesResignedAge, {...temporaryEmployeesResignedAge, [columnKey]:parseInt(value)}, columnKey)

    }

    if (name === '7-total') {
      setEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesHiredAge, [columnKey]:parseInt(value)}, permanentEmployeesHiredAge, temporaryEmployeesHiredAge, columnKey)

    } else if (name === '7-under_30') {
      setEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals({...employeesHiredAge, [columnKey]:parseInt(value), over_50: Math.max(0, (employeesHiredAge.total || 0) - (parseInt(value) || 0) - (employeesHiredAge.between_30_50 || 0))}, permanentEmployeesHiredAge, temporaryEmployeesHiredAge, columnKey)

    } else if (name === '7-between_30_50') {
      setEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals({...employeesHiredAge, [columnKey]:parseInt(value), over_50: Math.max(0, (employeesHiredAge.total || 0) - (parseInt(value) || 0) - (employeesHiredAge.under_30 || 0))}, permanentEmployeesHiredAge, temporaryEmployeesHiredAge, columnKey)

    } else if (name === '7-over_50') {
      setEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals({...employeesHiredAge, [columnKey]:parseInt(value)}, permanentEmployeesHiredAge,temporaryEmployeesHiredAge, columnKey)

    }

    if (name === '8-total') {
      setPermanentEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredAge, {...permanentEmployeesHiredAge, [columnKey]:parseInt(value)}, temporaryEmployeesHiredAge, columnKey)

    } else if (name === '8-under_30') {
      setPermanentEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals(employeesHiredAge, {...permanentEmployeesHiredAge, [columnKey]:parseInt(value), over_50: Math.max(0, (permanentEmployeesHiredAge.total || 0) - (parseInt(value) || 0) - (permanentEmployeesHiredAge.between_30_50 || 0))}, temporaryEmployeesHiredAge, columnKey)

    } else if (name === '8-between_30_50') {
      setPermanentEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals(employeesHiredAge, {...permanentEmployeesHiredAge, [columnKey]:parseInt(value), over_50: Math.max(0, (permanentEmployeesHiredAge.total || 0) - (parseInt(value) || 0) - (permanentEmployeesHiredAge.under_30 || 0))}, temporaryEmployeesHiredAge, columnKey)

    } else if (name === '8-over_50') {
      setPermanentEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredAge, {...permanentEmployeesHiredAge, [columnKey]:parseInt(value)}, temporaryEmployeesHiredAge, columnKey)

    }

    if (name === '9-total') {
      setTemporaryEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredAge, permanentEmployeesHiredAge, {...temporaryEmployeesHiredAge, [columnKey]:parseInt(value)}, columnKey)

    } else if (name === '9-under_30') {
      setTemporaryEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.between_30_50 || 0)),
      }));
      checkTotals(employeesHiredAge, permanentEmployeesHiredAge, {...temporaryEmployeesHiredAge, [columnKey]:parseInt(value), over_50: Math.max(0, (temporaryEmployeesHiredAge.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesHiredAge.between_30_50 || 0))}, columnKey)

    } else if (name === '9-between_30_50') {
      setTemporaryEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
        over_50: Math.max(0, (prevState.total || 0) - (parseInt(value) || 0) - (prevState.under_30 || 0)),
      }));
      checkTotals(employeesHiredAge, permanentEmployeesHiredAge, {...temporaryEmployeesHiredAge, [columnKey]:parseInt(value), over_50: Math.max(0, (temporaryEmployeesHiredAge.total || 0) - (parseInt(value) || 0) - (temporaryEmployeesHiredAge.under_30 || 0))}, columnKey)

    } else if (name === '9-over_50') {
      setTemporaryEmployeesHiredAge(prevState => ({
        ...prevState,
        [columnKey]: parseInt(value) || 0,
      }));
      checkTotals(employeesHiredAge, permanentEmployeesHiredAge, {...temporaryEmployeesHiredAge, [columnKey]:parseInt(value)}, columnKey)

    }
    
  };

  const steps = ["Gender", "Age Group"];
  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          sx={{
            ".MuiStepLabel-root .Mui-active": { color: "#8C2148" },
            "& .MuiStepLabel-root .Mui-completed": { color: "#8C2148" },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <Typography variant="h6">Employee Hire & Turnover</Typography>
            <Grid
              className={classes.filterContainer}
              container
              columnSpacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs={3} style={{ paddingLeft: 0 }}>
                <CeroDropdown
                  id="year"
                  label="Assessment Year"
                  fullWidth
                  options={sampleYear()}
                  onChange={({ target }) => setData("year", target.value)}
                  selectedValue={filterYear}
                />
              </Grid>
              <Grid item xs={3}>
                <CeroDropdown
                  id="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  onChange={({ target }) => setData("facility", target.value)}
                  selectedValue={facility}
                />
              </Grid>
              <Grid item xs={3}>
                <CeroDropdown
                  id="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  onChange={({ target }) =>
                    setData("assessmentPeriod", target.value)
                  }
                  selectedValue={filterAssessmentPeriod}
                />
              </Grid>
            </Grid>
            <Typography variant="body1">
              Employee Distribution by Gender
            </Typography>
            <CeroAddTopic
              onSelectRow={handleRowSelection}
              firstHeader={"Total Employees at the start of the period"}
              questions={questionsForGenderStart}
              columns={byGender}
              onDataChange={handleDataChange}
              inputData={emissionInputs}
              isTotalDisabled={false}
            />
            <CeroAddTopic
              onSelectRow={handleRowSelection}
              firstHeader={"Employees resigned during the period"}
              questions={questionsForGenderResigned}
              columns={byGender}
              onDataChange={handleDataChange}
              isTotalDisabled={false}
            />
            <CeroAddTopic
              onSelectRow={handleRowSelection}
              firstHeader={"Employees hired during the period"}
              questions={questionsForGenderHired}
              columns={byGender}
              onDataChange={handleDataChange}
              isTotalDisabled={false}
            />
            <CeroButton
              className={classes.buttonPrimary}
              onClick={() => handleNext(activeStep)}
              buttonText="Next"
              disabled={!showNextButton || !isFilterValid}
            />
            <CeroButton
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
              buttonText="Clear"
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <Typography variant="body1">
              Employee Distribution by Age Group
            </Typography>
            <CeroAddTopic
              onSelectRow={handleRowSelection}
              firstHeader={"Total Employees at the start of the period"}
              questions={questionsForAgeStart}
              columns={byAgegroup}
              onDataChange={handleDataChange}
              inputData={emissionInputs}
              isTotalDisabled={true}
            />
            <CeroAddTopic
              onSelectRow={handleRowSelection}
              firstHeader={"Employees resigned during the period"}
              questions={questionsForAgeResigned}
              columns={byAgegroup}
              onDataChange={handleDataChange}
              isTotalDisabled={true}
            />
            <CeroAddTopic
              onSelectRow={handleRowSelection}
              firstHeader={"Employees hired during the period"}
              questions={questionsForAgeHired}
              columns={byAgegroup}
              onDataChange={handleDataChange}
              isTotalDisabled={true}
            />
            <CeroButton
              className={classes.buttonPrimary}
              onClick={onAddData}
              buttonText="Add data"
              disabled={!showActionButton || !preDataValid}
            />
            <CeroButton
              className={classes.buttonSecondary}
              onClick={handleBack}
              buttonText="Back"
            />
          </>
        )}
      </Container>
    </>
  );
};

export default AddEmployeeAndTurnover;
