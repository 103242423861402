import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const StackedColumnChart = ({ apiResponse, filter }) => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    const co2eData = apiResponse.total_co2e_per_year_scope;

    const categoryInfo = {
      "Scope 1": { legend: "Scope 1", color: "#846BB5" },
      "Scope 2": { legend: "Scope 2", color: "#6FBFBA" },
      "Scope 3": { legend: "Scope 3", color: "#FBCE74" },
    };

    const datasets = Object.keys(categoryInfo).map((scope) => {
      const categoryInfoEntry = categoryInfo[scope] || {
        legend: scope,
        color: getRandomColor(),
      };
      return {
        label: categoryInfoEntry.legend,
        data: co2eData.map(
          (yearData) => yearData[Object.keys(yearData)[0]][scope] || 0
        ),
        backgroundColor: categoryInfoEntry.color,
        grouped: true,
        barThickness: (co2eData.length > 10) ? 12 : 30,
      };
    });

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const splitLabel = (label) => {
      const words = label.split(' ');
      const firstLine = words[0];
      const secondLine = words.slice(1).join(' ');
      return [firstLine, secondLine];
    };
    
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const tickLabels = co2eData.map((yearData) => {
      const label = capitalizeFirstLetter(Object.keys(yearData)[0].replace(/_/g, " ")).replaceAll('_', ' ');
      return label.includes(' ') ? splitLabel(label) : label;
    });

    const data = {
      labels: tickLabels, 
      datasets,
    };

    const maxDataValue = Math.max(...datasets.flatMap(dataset => dataset.data));

    const options = {
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Duration",
          },
          type: "category",
          grouped: true,
          grid: {
            display: false,
          },
          ticks: {
            color: "black",
          },
        },
        y: {
          title: {
            display: true,
            text: "CO2e (tonnes)",
            color: "#4F4F4F",
            font: {
              weight: "500",
            },
            padding: {
              right: 20,
            },
          },
          grid: {
            display: false,
          },
          ticks: {
            color: "black",
            callback: function (value, index, values) {
              if (maxDataValue > 1000) {
                const suffixes = ["", "k", "M"];
                const suffixIndex = Math.floor(String(value).length / 3);
                const shortValue = parseFloat(
                  (suffixIndex !== 0
                    ? value / Math.pow(1000, suffixIndex)
                    : value
                  ).toPrecision(3)
                );
                return shortValue + suffixes[suffixIndex];
              }
              return value;
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 15,
            color: "black",
            padding: 30,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.raw
              return label;
            },
          },
        },
        datalabels: {
          color: "#4F4F4F",
          formatter: (value) => {
            return value !== 0 ? value.toFixed(0) : "";
          },
          anchor: "end",
          align: "top",
          rotation: "270",
          font: {
            size: (co2eData.length > 10) ? 12 : 14 
          },
        },
      },
      layout: {
        padding: {
          bottom: 10,
          top: 50,
        },
      },
    };
    const ctx = chartRef.current.getContext("2d");

    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });
  }, [apiResponse]);

  return <canvas ref={chartRef} height={286} />;
};

export default StackedColumnChart;
