import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableInnerContainer: {
    maxHeight: "100%",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 170,
    height: 46,
    fontSize: 14,
    marginLeft: theme.spacing(2),
    marginTop:0,
    fontWeight: 400,
    textTransform: "capitalize",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20
  },
  topContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  filterContainer:{
    display: "flex",
    width: "50%",
    justifyContent: "space-between"
  },
  selectContainer:{
    width: "100%",
    marginRight: theme.spacing(4),
  },
  textField:{
    backgroundColor: "white",
    marginRight: theme.spacing(4),
  },
  autoComplete:{
    width: "100%",
    marginRight: theme.spacing(4),
  }
}));

export default useStyles;
