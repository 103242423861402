import * as yup from 'yup';

export const schemeValidation = yup.object({
  facility: yup
    .string()
    .required('Facility is required'),
  year: yup
    .number()
    .required('Year is required'),
  assessmentPeriod: yup
    .string()
    .required('Assessment Period is required'),
  date: yup
    .date()
    .required('Date is required'),
  incidentStatus: yup
    .string()
    .required('Status of Incident action is required'),
  discriminationType: yup
    .array()
    .required('Discrimination type is required'),
  details: yup
    .string()
    .required('Details is required'),
  correctiveAction: yup
    .string()
    .required('Corrective Action is required'),
});
