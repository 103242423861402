import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Paper,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import clsx from "clsx";
import useStyles from "./styles";
import { useFormik } from "formik";

const CeroAddTopic = (props) => {
  const classes = useStyles();
  const tableId = "CeroTable";
  const [formikValues, setFormikValues] = useState({});

  const formik = useFormik({
    initialValues: formikValues,
    onSubmit: async (values) => {
    },
  });

  const handleInputChange = (event, questionId, columnKey) => {
    const {name, value } = event.target;
    formik.handleChange(event);
    console.log(props.questions);
    props.onDataChange(questionId, columnKey, value, name);
  };
   
  const getValue = (questionId, columnKey) => {
    const total = Number(formik.values[`${questionId}-total`]) || 0;
    if(columnKey === "other"){
    const female = Number(formik.values[`${questionId}-female`]) || 0;
    const male = Number(formik.values[`${questionId}-male`]) || 0;
    const result = total - (female + male);
    return isNaN(result) ? "" : result.toString();
    }else {
    const under30 = Number(formik.values[`${questionId}-under_30`]) || 0;
    const btw30 = Number(formik.values[`${questionId}-between_30_50`]) || 0;
    const result = total - (under30 + btw30);
    return isNaN(result) ? "" : result.toString();
    }
  }

  const getDataset = (question,columnKey) => {
    if(question === 10){
      switch (columnKey) {
        case "total":
          return props.inputData.number_of_employees;
        case "female":
          return props.inputData.number_of_female_employees;
        case "male":
          return props.inputData.number_of_male_employees;
        case "other":
          return props.inputData.number_of_other_employees;
        default:
          return "";
      }
    }else if(question === 11){
      switch (columnKey) {
        case "total":
          return props.inputData.number_of_permanent_employees;
        case "female":
          return props.inputData.number_of_female_employees_permanent;
        case "male":
          return props.inputData.number_of_male_employees_permanent;
        case "other":
          return props.inputData.number_of_other_employees_permanent;
        default:
          return "";
      }
    }else if(question === 12){
      switch (columnKey) {
        case "total":
          return props.inputData.number_of_temporary_employees;
        case "female":
          return props.inputData.number_of_female_employees_temporary;
        case "male":
          return props.inputData.number_of_male_employees_temporary;
        case "other":
          return props.inputData.number_of_other_employees_temporary;
        default:
          return "";
      }
    }else if(question === 1){
      switch (columnKey) {
        case "total":
          return props.inputData.number_of_employees;
        case "under_30":
          return props.inputData.number_of_under_30_employees;
        case "between_30_50":
          return props.inputData.number_of_between_30_50_employees;
        case "over_50":
          return props.inputData.number_of_over_50_employees;
        default:
          return "";
      }
    }else if(question === 2){
      switch (columnKey) {
        case "total":
          return props.inputData.number_of_permanent_employees;
        case "under_30":
          return props.inputData.number_of_under_30_employees_permanent;
        case "between_30_50":
          return props.inputData.number_of_between_30_50_employees_permanent;
        case "over_50":
          return props.inputData.number_of_over_50_employees_permanent;
        default:
          return "";
      }
    }else if(question === 3){
      switch (columnKey) {
        case "total":
          return props.inputData.number_of_temporary_employees;
        case "under_30":
          return props.inputData.number_of_under_30_employees_temporary;
        case "between_30_50":
          return props.inputData.number_of_between_30_50_employees_temporary;
        case "over_50":
          return props.inputData.number_of_over_50_employees_temporary;
        default:
          return "";
      }
    }
  }

  const getTableRows = () =>
    props.questions.map((question, questionIndex) => (
      <TableRow
        key={question.id}
        className={clsx(
          classes.tableDataRow,
          question.selected && classes.tableSelectedRow
        )}
        onClick={() => props.onSelectRow(question)}
      >
        <TableCell
          style={{
            width: "30%",
            backgroundColor: "#D0C5CA"
          }}
          className={clsx(
            classes.tableBodyCell,
            props.columns[0].classes?.column
          )}
        >
          <Typography variant="body2">{question.topic}</Typography>
        </TableCell>
        {props.columns.slice(1).map((column, columnIndex) => {
          return (
            <TableCell
              style={{
                width: `${70 / props.columns.slice(1).length}%`,
              }}
              key={`${question.id}-${column.columnId}`}
              className={clsx(classes.tableBodyCell, column.classes?.column)}
            >
              <input
                placeholder="Enter"
                type="text"
                disabled={props.isTotalDisabled && column.columnKey==='total'}
                name={`${question.id}-${column.columnKey}`} 
                value={ props.inputData && typeof props.inputData === 'object' && Object.keys(props.inputData).length > 0 ? getDataset(question.id,column.columnKey) :
                question.dataset?question.dataset[column.columnKey]:
                  ((column.columnKey.includes("other") || column.columnKey.includes("over_50")
                 ? getValue(question.id, column.columnKey)
                    : formik.values[`${question.id}-${column.columnKey}`] || "") )
                } 
                onChange={(e) => handleInputChange(e,question.id,column.columnKey)}
                onBlur={formik.handleBlur}
                className={classes.input}
              />
            </TableCell>
          );
        })}
      </TableRow>
    ));

  const noTableRows = () => (
    <TableRow>
      <TableCell className={classes.noDataWarning}>
        <Typography>No data</Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer
      id={tableId}
      className={clsx(classes.tableContainer)}
      component={Paper}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell
              style={{
                width: "auto",
              }}
              className={clsx(classes.headerCell)}
            >
              {props.firstHeader}
            </TableCell>
            {props.columns.slice(1).map((column) => (
              <TableCell
                style={{
                  width: "auto",
                }}
                className={clsx(classes.headerCell)}
                key={column.columnId}
              >
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getTableRows()}
          {!props.questions.length && noTableRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CeroAddTopic;