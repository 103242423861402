import { useEffect } from 'react'
import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'

import { getCompanyAuditHistory, getCompanyDetails } from '../../redux/actions'
import { STATUS } from '../../redux/constants'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DashboardLayout from "../../layouts/DashboardLayout"
import AuditHistory from './AuditHistory'
import useStyles from "./styles"

const CompanyDetails = () => {
    const classes = useStyles()
    const { companyId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const companyData = useSelector((state) => state.company.companyDetails)
    const auditHistory = useSelector((state) => state.company.auditHistory)

    const isLoading = companyData.status === STATUS.RUNNING || auditHistory.status === STATUS.RUNNING
    const isError = companyData.status === STATUS.ERROR || auditHistory.status === STATUS.ERROR

    useEffect(() => {
        dispatch(getCompanyDetails(companyId))
        dispatch(getCompanyAuditHistory(companyId))
    }, [dispatch, companyId])

    return (
        <DashboardLayout>
            
            <Container className={classes.container}>
            <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
            <ArrowBackIosIcon />Back</div>
                {!isLoading ? (
                    <>
                        <Box className={classes.topContainer}>
                            <Typography variant="h6" component="h6" className={classes.previewTitle}>{companyData.data.name}</Typography>
                            <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                                <Grid item container direction='column' xs={6}>
                                    <Typography className={classes.previewItem}>Email: {companyData.data.email}</Typography>
                                    <Typography className={classes.previewItem}>Phone: {companyData.data.phone}</Typography>
                                </Grid>
                                <Grid item container direction='column' xs={6}>
                                    <Typography className={classes.previewItem}>Website:  {companyData.data.website}</Typography>
                                    <Typography className={classes.previewItem}>Established year: {companyData.data.estd_year}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <AuditHistory auditData={auditHistory.data} companyId={companyId} />
                    </>
                ) : <Box className={classes.loader}>
                <Typography variant="h7" component="span">
                  {isLoading 
                    ? <CircularProgress/>
                    : isError
                    ? "We couldn't process your request. Please try again later"
                    : ""}
                </Typography>
              </Box>}
            </Container>
        </DashboardLayout>
    )
}

export default CompanyDetails