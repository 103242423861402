import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        height: '320px',
        borderRadius: 8,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    },
    graphTitle: {
        fontSize: 18,
        fontWeight: 400,
        padding: theme.spacing(2,0),
    },
    chart:{
        height: "80%",
        width: "100%"
    },
    colorPrimary:{
        backgroundColor: "#D9D9D9"
    },
    barColorPrimary:{
        backgroundColor: "#846BB5"
    },
    linearProgress:{
        width: "100%", 
        marginBottom: 15,
        borderRadius: 5,
        height: 5
    },
    value:{
        fontWeight: 500,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    bar:{
        position: "relative",
        float: "left",
        height: "100%",
        width: "33%",
    },
    caption:{
        display: "flex",
        justifyContent: "space-around",
        marginTop: "5px",  
    },
    subtitle:{
        fontSize: 16,
        padding: theme.spacing(5,0,3,0),
    },
    box:{
        height: "40px",
        display: "flex",
        justifyContent: "space-between"
    }
}));

export default useStyles;
