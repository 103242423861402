import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables as registerablesJS,
} from "chart.js";
import { Line } from "react-chartjs-2";
import _ from "lodash";

import useStyles from "./styles";
import CeroItemPair from "../../../../components/CeroItemPair";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EMCDetailsGraphs = (props) => {
  const classes = useStyles();
  const getOptions = (title) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.raw
              return label;
            },
          },
        },
        title: {
          align: "start",
          display: true,
          text: title,
          color: "black",
          padding: {
            bottom: 20,
          },
          font: {
            size: 18,
            family: "Inter",
            weight: "400",
          },
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "CO2e Emitted",
            color: "#4F4F4F",
            font: {
              size: 12,
              family: "Inter",
              weight: "400",
            },
          },
          ticks: {
            color: "black",
            // callback: formatAxisValue,z
          },
        },
        x: {
          title: {
            display: true,
            text: "Duration",
            color: "grey",
            font: {
              size: 12,
            },
          },
          ticks: {
            color: "black",
          },
        },
      },
    };
    return options;
  };

  const generateStationaryCombustionGraphs = (key, index) => {
    const data = {
      labels: _.map(
        props?.data?.stationary_combustion?.[key],
        (item) => item?.year
      ),
      datasets: [
        {
          label: "Total Emissions(tonnes)",
          data: _.map(
            props?.data?.stationary_combustion?.[key],
            (item) => item?.total_co2e
          ),
          color: "black",
          borderColor: [
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
          ][index],
          backgroundColor: [
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
          ][index],
        },
      ],
    };

    return (
      <Paper key={key} className={classes.container}>
        {props?.data?.stationary_combustion?.[key].length > 0 ? (
          <Line
            options={getOptions(key)}
            data={data}
            height={325}
            width={560}
          />
        ) : (
          "No Data"
        )}
      </Paper>
    );
  };

  const generateMobileCombustionGraphs = (key, index) => {
    const data = {
      labels: _.map(
        props?.data?.mobile_combustion?.[key],
        (item) => item?.year
      ),
      datasets: [
        {
          label: "Total Emissions(tonnes)",
          data: _.map(
            props?.data?.mobile_combustion?.[key],
            (item) => item?.total_co2e
          ),
          color: "black",
          borderColor: [
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
          ][index],
          backgroundColor: [
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
          ][index],
        },
      ],
    };

    return (
      <Paper key={key} className={classes.container}>
        {props?.data?.mobile_combustion?.[key].length > 0 ? (
          <Line
            options={getOptions(key)}
            data={data}
            height={325}
            width={560}
          />
        ) : (
          "No Data"
        )}
      </Paper>
    );
  };

  const generateRefrigerantsGraphs = (key, index) => {
    const data = {
      labels: _.map(props?.data?.refrigerants?.[key], (item) => item?.year),
      datasets: [
        {
          label: "Total Emissions(tonnes)",
          data: _.map(
            props?.data?.refrigerants?.[key],
            (item) => item?.total_co2e
          ),
          color: "black",
          borderColor: [
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
          ][index],
          backgroundColor: [
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
          ][index],
        },
      ],
    };

    return (
      <Paper key={key} className={classes.container}>
        {props?.data?.refrigerants?.[key].length > 0 ? (
          <Line
            options={getOptions(key)}
            data={data}
            height={325}
            width={560}
          />
        ) : (
          "No Data"
        )}
      </Paper>
    );
  };

  const generateTransportationGraphs = (key, index) => {
    const data = {
      labels: _.map(props?.data?.transportation?.[key], (item) => item?.year),
      datasets: [
        {
          label: "Total Emissions(tonnes)",
          data: _.map(
            props?.data?.transportation?.[key],
            (item) => item?.total_co2e
          ),
          color: "black",
          borderColor: [
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
          ][index],
          backgroundColor: [
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
          ][index],
        },
      ],
    };

    return (
      <Paper key={key} className={classes.container}>
        {props?.data?.transportation?.[key].length > 0 ? (
          <Line
            options={getOptions(key)}
            data={data}
            height={325}
            width={560}
          />
        ) : (
          "No Data"
        )}
      </Paper>
    );
  };

  const generatePurchasedElectricityGraphs = (key, index) => {
    const data = {
      labels: _.map(
        props?.data?.purchased_electricity?.[key],
        (item) => item?.year
      ),
      datasets: [
        {
          label: "Total Emissions(tonnes)",
          data: _.map(
            props?.data?.purchased_electricity?.[key],
            (item) => item?.total_co2e
          ),
          color: "black",
          borderColor: [
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
          ][index],
          backgroundColor: [
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
          ][index],
        },
      ],
    };

    return (
      <Paper key={key} className={classes.container}>
        {props?.data?.purchased_electricity?.[key].length > 0 ? (
          <Line
            options={getOptions(key.split("_").join(" "))}
            data={data}
            height={325}
            width={560}
          />
        ) : (
          "No Data"
        )}
      </Paper>
    );
  };

  const generateWaterGraphs = (key, index) => {
    const data = {
      labels: _.map(props?.data?.water?.[key], (item) => item?.year),
      datasets: [
        {
          label: "Total Emissions(tonnes)",
          data: _.map(props?.data?.water?.[key], (item) => item?.total_co2e),
          color: "black",
          borderColor: [
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
          ][index],
          backgroundColor: [
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
          ][index],
        },
      ],
    };

    return (
      <Paper key={key} className={classes.container}>
        {props?.data?.water?.[key].length > 0 ? (
          <Line
            options={getOptions(key.split("_").join(" "))}
            data={data}
            height={325}
            width={560}
          />
        ) : (
          "No Data"
        )}
      </Paper>
    );
  };

  const generateWasteGraphs = (key, index) => {
    const data = {
      labels: _.map(props?.data?.waste?.[key], (item) => item?.year),
      datasets: [
        {
          label: "Total Emissions(tonnes)",
          data: _.map(props?.data?.waste?.[key], (item) => item?.total_co2e),
          color: "black",
          borderColor: [
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
          ][index],
          backgroundColor: [
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
            "#FFC757",
            "#846BB5",
            "#6FBFBA",
          ][index],
        },
      ],
    };

    return (
      <Paper key={key} className={classes.container}>
        {props?.data?.waste?.[key].length > 0 ? (
          <Line
            options={getOptions(key)}
            data={data}
            height={325}
            width={560}
          />
        ) : (
          "No Data"
        )}
      </Paper>
    );
  };

  console.log(
    "props?.data?.stationary_combustion",
    props?.data?.stationary_combustion
  );

  return (
    <>
      {/* Iterate over the keys of stationary_combustion and generate Line components */}
      {props?.data?.stationary_combustion &&
        Object.keys(props?.data?.stationary_combustion).length > 0 && (
          <Typography variant="h6" fontWeight={500} p={2}>
            Stationary Combustion
          </Typography>
        )}
      {props?.data?.stationary_combustion &&
        Object.keys(props?.data?.stationary_combustion).map((key, index) =>
          generateStationaryCombustionGraphs(key, index)
        )}

      {props?.data?.mobile_combustion &&
        Object.keys(props?.data?.mobile_combustion).length > 0 && (
          <Typography variant="h6" fontWeight={500} p={2}>
            Mobile Combustion
          </Typography>
        )}
      {props?.data?.mobile_combustion &&
        Object.keys(props?.data?.mobile_combustion).map((key, index) =>
          generateMobileCombustionGraphs(key, index)
        )}

      {props?.data?.refrigerants &&
        Object.keys(props?.data?.refrigerants).length > 0 && (
          <Typography variant="h6" fontWeight={500} p={2}>
            Refrigerants
          </Typography>
        )}
      {props?.data?.refrigerants &&
        Object.keys(props?.data?.refrigerants).map((key, index) =>
          generateRefrigerantsGraphs(key, index)
        )}

      {props?.data?.transportation &&
        Object.keys(props?.data?.transportation).length > 0 && (
          <Typography variant="h6" fontWeight={500} p={2}>
            Transportation
          </Typography>
        )}
      {props?.data?.transportation &&
        Object.keys(props?.data?.transportation).map((key, index) =>
          generateTransportationGraphs(key, index)
        )}

      {props?.data?.purchased_electricity &&
        Object.keys(props?.data?.purchased_electricity).length > 0 && (
          <Typography variant="h6" fontWeight={500} p={2}>
            Purchased Electricity
          </Typography>
        )}
      {props?.data?.purchased_electricity &&
        Object.keys(props?.data?.purchased_electricity).map((key, index) =>
          generatePurchasedElectricityGraphs(key, index)
        )}

      {props?.data?.water && Object.keys(props?.data?.water).length > 0 && (
        <Typography variant="h6" fontWeight={500} p={2}>
          Water
        </Typography>
      )}
      {props?.data?.water &&
        Object.keys(props?.data?.water).map((key, index) =>
          generateWaterGraphs(key, index)
        )}

      {props?.data?.waste && Object.keys(props?.data?.waste).length > 0 && (
        <Typography variant="h6" fontWeight={500} p={2}>
          Waste
        </Typography>
      )}
      {props?.data?.waste &&
        Object.keys(props?.data?.waste).map((key, index) =>
          generateWasteGraphs(key, index)
        )}
    </>
  );
};

export default EMCDetailsGraphs;
