import * as yup from 'yup';

export const schemeValidation = yup.object({
    facility: yup
        .string()
        .required('Facility is required'),
    year: yup
        .number()
        .required('Year is required'),
    assessmentPeriod: yup
        .string()
        .required('Assessment Period is required'),
    numberOfOperation: yup
        .number()
        .required('Total number of operations is required'),
    incidentNumber: yup
        .number()
        .required('Number of incidents reported is required'),
    riskRelated: yup
        .string()
        .required('Risks related to corruption identified during the assessment is required'),
    corruptionDescription: yup
        .string()
        .required('Any confirmed incidents is required'),
    isOrganizationCorruption: yup
        .string()
        .required('Choose an option'),
});