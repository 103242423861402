import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    height: "100%",
    borderRadius: 8
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100% - 100px)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    marginTop: 20,
    overflow: "auto",
  },
  tableInnerContainer: {
    maxHeight: "100%",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  MultiSelectformControl: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 46,
    "& .MuiFormLabel-root": {
      left: theme.spacing(2),
      top: theme.spacing(1),
    },
    background: "white",
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
    "& .Mui-focused > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
  },
  filterContainer: {},
  yearFilter: {
    "& .MuiBox-root": {
      height: "42px !important",
      margin: theme.spacing(0.6, 0, 0, 0),
    },
    "& .MuiBox-root > .MuiAutocomplete-root ": {
      background: "white",
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root":
      {
        height: 46,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > input[type=text]":
      {
        paddingBlock: 0,
        paddingInline: 0,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-outlined":
      {
        marginTop: -3,
      },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: "45px",
    marginRight: 15,
    fontWeight: 400,
    height: 40,
    marginTop: 5,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  link: {
    cursor: "pointer",
  },
  percentField:{
    width: '40%',
    '& .MuiInputBase-root .MuiOutlinedInput-root':{
      height: '30px !important',
    },
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  editIcon:{
    color: theme.palette.Primary.hoverButton
  },
  dropdown:{
    backgroundColor: theme.palette.background.white
  },
  textField: {
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  }
}));

export default useStyles;
