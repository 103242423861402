import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(4),
        '@media (min-width: 1200px)': {
            maxWidth: "none"
        },
    },
    cardItem: {
        width: "20%",
        margin: 0,
    },
    cardsContainer:{
        marginBottom: theme.spacing(3),
        display: "flex",
        justifyContent: "space-between"
    },
    gridContainer: {
        height: 'calc(100% - 64px)',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(4)
    }
}));

export default useStyles;