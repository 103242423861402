import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const PublicRoute = ({ children, redirectTo = "/" }) => {
  let isAuthenticated = useSelector(
    (state) => state.auth.userInfo.access_token
  );
  const navigate = useNavigate();

  const isSuperAdmin = useSelector((state) =>
    state.auth.role.includes("super_admin")
  );
  const is2FAEnabled = useSelector(
    (state) => state.auth.userInfo.is_two_factor_auth_enabled
  );

  useEffect(() => {
    if (isSuperAdmin && is2FAEnabled && !isAuthenticated) {
      navigate("/twofa");
    }
  }, [navigate, isSuperAdmin, is2FAEnabled, isAuthenticated]);

  return !isAuthenticated ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
};

export default PublicRoute;
