import { makeStyles } from "@mui/styles";
import { pointer } from "d3";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
      display: "flex",
    },
  },
  box: {
    width: "40%",
    height: "auto",
    margin: "auto",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: theme.palette.text.dark,
    backgroundColor: theme.palette.background.white,
    position: "absolute",
    top: "35%",
    left: "30%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  mainBody: {
    width: "100%",
    height: "100vh",
    background: "#f9f4f6",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  resend: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    fontWeight: 200,
    fontSize: "14px"
  },
  resendText: {
    color: "#3185f3",
    cursor: "pointer"
  },
  buttonContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(8),
  },
  buttonClear: {
    width: "70px",
    border: "1px solid #a44d6d",
    color: "#a44d6d",
    background: theme.palette.background.white,
    margin: "10px",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSave: {
    width: "70px",
    border: "1px solid #a44d6d",
    color: theme.palette.background.white,
    background: "#a44d6d",
    margin: "10px",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
}));

export default useStyles;
