import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import CeroButton from "../../components/CeroButton";
import { getStakeholderCompany } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";

const StakeholderCompany = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCompany, setSelectedCompany] = useState("");

  const companiesList = useSelector(
    (state) => state.listings.stakeholderCompany.data
  );
  
  const companies = companiesList.map((company) => ({
    id: company.id,
    name: company.name,
  }));
  
  useEffect(() => {
    dispatch(getStakeholderCompany());
  }, [dispatch]);

  const onSelectCompany = () => {
    if (selectedCompany) {
      Cookies.set("companyId", selectedCompany); 
      navigate(`/dashboard`);
    } else {
      enqueueSnackbar("Please select a company before proceeding", {
        variant: "error",
      });
    }
  };  

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.box}>
        <Typography variant="h6" className={classes.title}>
          Select Company
        </Typography>
        <div className={classes.actionBox}>
          <FormControl className={classes.userBox}>
            <InputLabel
              htmlFor="company-select"
              classes={{
                focused: classes.focusedLabel,
              }}
            >
            {selectedCompany ? "" : 'Select Company'}
            </InputLabel>
            <Select
              value={selectedCompany}
              onChange={handleCompanyChange}
              inputProps={{
                name: "company",
                id: "company-select",
              }}
              className={classes.select}
            >
              {companies.map((company, index) => (
                <MenuItem key={index} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CeroButton
            buttonText="Proceed"
            className={classes.buttonPrimary}
            onClick={onSelectCompany}
          />
        </div>
      </Box>
    </Container>
  );
};

export default StakeholderCompany;
