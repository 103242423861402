import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from 'notistack';

import { STATUS } from "../../../redux/constants";
import { updateEmployeeHours } from './schema';
import { getAssessmentPeriod, resetEmployeeTrainingHours, updateEmployeeTrainingHours } from '../../../redux/actions';
import CeroButton from '../../../components/CeroButton';
import CeroInput from '../../../components/CeroInput';
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroSelect from '../../../components/CeroSelect';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import { sampleYear } from '../../../constants';
import CeroDate from '../../../components/CeroDate';

const EditEmployeeTrainingHoursForm = (props) => {
    const { emissionData, onCancel } = props
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const updateEmissionData = useSelector(state => state.emission.updateEmployeeTrainingHours);
    
    const facilitiesData = useSelector(
        (state) => state.listings.listFacilities.data
      );
      
    const facilitiesList = facilitiesData.map((item) => ({
        key: item.id,
        value: item.name,
      }));
    const yearList = sampleYear().map((item) => ({
        id: item.key,
        label: item.value,
      }));
      const assessmentData = useSelector(
        (state) => state.assessment.assessmentPeriod.data
      );
    const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];
    const TrainingMethods = [
        { key: "on the job training", value: "On the job Training" },
        { key: "online courses", value: "Online courses" },
        { key: "workshops", value: "Workshops" },
      ];  
    const formik = useFormik({
        initialValues: {
            facility: emissionData.facility_id || "",
            year: emissionData.year || "",
            assessmentPeriod: emissionData.assessment_period || "",
            trainingTitle: emissionData.training_title || "",
            trainingDetails: emissionData.training_details || "",
            totalTrainingHours: emissionData.total_training_hours || "",
            femaleTrainingHours: emissionData.female_training_hours || "",
            maleTrainingHours: emissionData.male_training_hours || "",
            date: emissionData.date || "",
            trainingMethods: emissionData.training_methods || "",
        },
        validationSchema: updateEmployeeHours,
        onSubmit: () => {},
    });
    useEffect(() => {
        const { facility, year } = formik.values;
        if (facility && year) {
          dispatch(getAssessmentPeriod(year, facility));
        }
      }, [formik.values.facility, formik.values.year, dispatch]);
    useEffect(() => {
        if (updateEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Employee training hours details updated successfully', { variant: 'success' });
            dispatch(resetEmployeeTrainingHours())
            onCancel();
        } else if (updateEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(updateEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
        }
    }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

    const onUpdateDevelopmentTrainingData = () => {
        const requestData = {
            id: emissionData.id,
            facility_id: formik.values.facility,
            year: formik.values.year,
            assessment_period : formik.values.assessmentPeriod,
            training_title :formik.values.trainingTitle,
            training_details :formik.values.trainingDetails,
            total_training_hours :formik.values.totalTrainingHours,
            female_training_hours :formik.values.femaleTrainingHours,
            male_training_hours :formik.values.maleTrainingHours,
            date : typeof formik.values.date === "string"? formik.values.date: formik.values.date.toISOString().split('T')[0],
            training_methods :formik.values.trainingMethods,
            save: true,
        };
        dispatch(updateEmployeeTrainingHours(requestData));

    };

    return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
      <ArrowBackIosIcon />
      Back
      </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Edit Employee Training Hours</Typography>
                <Box className={classes.topContainer}>
                        <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={8}
                    >
                    <Grid item container direction="column" lg={6} md={6} xs={4}>
                        <CeroSelect
                        required
                        id="facility"
                        name="facility"
                        label="Facility"
                        fullWidth
                        options={facilitiesList}
                        selectedValue={formik.values.facility}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.facility && formik.errors.facility}
                        />
                    </Grid>
                    <Grid item container direction={"column"} lg={6} md={6} xs={4}>
                        <CeroAutoComplete
                        id="year"
                        label="Year"
                        onChange={(e, value) =>
                            formik.setFieldValue("year", value.id)
                        }
                        onBlur={formik.handleBlur}
                        error={formik.errors.year}
                        options={yearList}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        value={formik.values.year}
                        />
                    </Grid>
                    <Grid item container direction="column" lg={6} md={6} xs={4}>
                        <CeroSelect
                        required
                        id="assessmentPeriod"
                        name="assessmentPeriod"
                        label="Assessment Period"
                        fullWidth
                        options={assessmentOptions}
                        selectedValue={formik.values.assessmentPeriod}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.assessmentPeriod &&
                            formik.errors.assessmentPeriod
                        }
                        />
                    </Grid>
                    </Grid>
                    <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={8}
                    >
                    <Grid item container direction="column"  lg={12} md={12} xs={12}>
                        <CeroInput 
                        name="trainingTitle" 
                        label="Training Title" 
                        id="trainingTitle"
                        value={formik.values.trainingTitle}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.trainingTitle && formik.errors.trainingTitle}
                        classes={{ container: classes.textAreaContainer }}/>
                    </Grid>
                    </Grid>
                    <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={8}
                    >
                    <Grid item container direction="column"  lg={12} md={12} xs={12}>
                        <CeroInput 
                        required
                        name="trainingDetails" 
                        label="Training Details" 
                        id="trainingDetails"
                        value={formik.values.trainingDetails}
                        fullWidth
                        multiline
                        rows="3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.trainingDetails && formik.errors.trainingDetails}
                        classes={{ container: classes.textAreaContainer }}/>
                    </Grid>
                    </Grid>
                    <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={8}
                    >
                    <Grid item container direction="column"  lg={6} md={6} xs={12}>
                        <CeroDate
                                        name="date"
                                        value={formik.values.date}
                                        label="Training Date"
                                        assessmentPeriod={formik.values.assessmentPeriod}
                                        year={formik.values.year}
                                        onChange={(date) => formik.setFieldValue("date", date)}
                                        error={formik.touched.date && formik.errors.date}
                                    />
                    </Grid>
                    <Grid item container direction="column"  lg={6} md={6} xs={12}>
                        <CeroSelect
                            required
                            id="trainingMethods"
                            name="trainingMethods"
                            label="Training Methods"
                            options={TrainingMethods}
                            selectedValue={formik.values.trainingMethods}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Grid>
                    </Grid>
                    <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={8}
                    >
                    <Grid item container direction="column"  lg={6} md={6} xs={12}>
                        <CeroInput 
                        name="totalTrainingHours" 
                        label="Total Number of training hours to employees" 
                        id="totalTrainingHours"
                        value={formik.values.totalTrainingHours}
                        fullWidth
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.totalTrainingHours && formik.errors.totalTrainingHours}
                        classes={{ container: classes.textAreaContainer }}
                        />
                    </Grid>
                    </Grid>
                    <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={8}
                    >
                    <Grid item container direction="column"  lg={6} md={6} xs={12}>
                        <CeroInput 
                        name="femaleTrainingHours" 
                        label="Total Number of training hours to female employees" 
                        id="femaleTrainingHours"
                        value={formik.values.femaleTrainingHours}
                        fullWidth
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.femaleTrainingHours && formik.errors.femaleTrainingHours}
                        classes={{ container: classes.textAreaContainer }}
                        />
                    </Grid>
                    </Grid>
                    <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={8}
                    >
                    <Grid item container direction="column"  lg={6} md={6} xs={12}>
                        <CeroInput 
                        name="maleTrainingHours" 
                        label="Total Number of training hours to male employees" 
                        id="maleTrainingHours"
                        value={formik.values.maleTrainingHours}
                        fullWidth
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.maleTrainingHours && formik.errors.maleTrainingHours}
                        classes={{ container: classes.textAreaContainer }}
                        />
                    </Grid>
                    </Grid>
                </Box>
                <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Update"
                    disabled={!formik.dirty || !formik.isValid}
                    className={clsx(classes.button, classes.buttonPrimary)}
                    onClick={onUpdateDevelopmentTrainingData}
                />
                <CeroButton
                    buttonText="Clear"
                    variant="outlined"
                    className={clsx(classes.button, classes.buttonSecondary)}
                    onClick={formik.resetForm}
                />
            </Box>
            </Box>
        </Container>
        </>
    );
};

export default EditEmployeeTrainingHoursForm;
