import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  box: {
    width: "40%",
    height: "auto",
    margin: "auto",
    border: "solid",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: theme.palette.text.dark,
    backgroundColor: theme.palette.Primary.light,
    position: "absolute",
    top: "35%",
    left: "30%",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    marginBottom: theme.spacing(5),
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  actionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dropdown: {
    width: "60%",
    backgroundColor: theme.palette.background.white,
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    height: "40px",
    color: "rgba(0, 0, 0, 0.5)",
    paddingLeft: theme.spacing(2),
    font: "inherit",
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  focusedLabel: {
    '&.MuiInputLabel-root.MuiInputLabel-root.Mui-focused': {
      display: 'none',
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor: theme.palette.text.dark,
    }
  },
  userBox: {
    width: "60%",
    backgroundColor: theme.palette.background.white,
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(5),
  },
  select:{
    '& fieldset': {
      borderColor: 'black !important', 
    },
  }
}));

export default useStyles;
