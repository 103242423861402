import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        width: "100%",
        maxWidth: "100%",
    },
    childContainer: {
        backgroundColor: "#EDFFF8 !important",
    },
    card: {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        padding: theme.spacing(4),
        width: "99%",
        minHeight: "87vh",
        gap:20,
        borderRadius: 8,
        margin: theme.spacing(0, 1, 0, 2),
        boxShadow:
          "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
      },
    cardItem: {
        width: "20%",
        margin: 0,
    },
    cardsContainer:{
        marginBottom: theme.spacing(3),
        display: "flex",
        justifyContent: "space-between"
    },
    gridContainer: {
        height: 'calc(100% - 64px)',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(4)
    },
    buttonPrimary: {
        background: theme.palette.trade.button,
        textTransform: "capitalize",
        height: 35,
        borderColor: theme.palette.trade.button,
        marginRight: 15,
        fontWeight: 400,
        color: theme.palette.background.white,
        "&:hover": {
          backgroundColor: theme.palette.trade.header,
          borderColor: theme.palette.trade.header,
          color: theme.palette.Primary.light,
        },
      },
}));

export default useStyles;