import * as yup from 'yup';

export const userSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    role:  yup.array()
    .min(1, 'At least one Role is required')
    .of(yup.string().required('Role elements must be strings')),
    facilities: yup.array().when("role", {
        is: (roles) => roles.includes("facility_manager") || roles.includes("business_user"),
        then: yup.array().of(yup.string()).min(1, "Facility is required").required("Facility is required"),
        otherwise: yup.array().of(yup.string()),
      }),
});
