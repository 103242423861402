import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Box, Container, IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { STATUS } from '../../redux/constants';
import { changePassword, userLogout, resetAuthStatus } from '../../redux/actions';
import CeroButton from '../../components/CeroButton'
import CeroInput from '../../components/CeroInput'
import DashboardLayout from '../../layouts/DashboardLayout'
import ValidationSchema from './ValidationSchema';
import useStyles from "./styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChangePassword = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const changePasswordData = useSelector((state) => state.auth.changeUserPassword);
    const userInfo = useSelector((state) => state.auth.userInfo);

    useEffect(() => {
        if (changePasswordData.status === STATUS.SUCCESS) {
            enqueueSnackbar(changePasswordData.data.message, { variant: 'success' });
            dispatch(userLogout(userInfo?.email))
            dispatch(resetAuthStatus())
        } else if (changePasswordData.status === STATUS.ERROR) {
            enqueueSnackbar(changePasswordData.message.message, { variant: 'error' });
            dispatch(resetAuthStatus())
        }
    }, [changePasswordData, enqueueSnackbar, navigate, dispatch])

    const changePasswordForm = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: ValidationSchema
    });

    const handleSubmit = () => {
        dispatch(changePassword(changePasswordForm.values.oldPassword, changePasswordForm.values.password))
    };

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    return (
        <DashboardLayout>
            <Container component="main" maxWidth="sm">
                <Paper variant="outlined" className={classes.container}>
                    <Typography component="h1" variant="h5" className={classes.cardTitle} align="center">
                        Change Password
                    </Typography>
                    <CeroInput
                        type={showOldPassword ? "text" : "password"}
                        id='oldPassword'
                        label='Old password'
                        value={changePasswordForm.values.oldPassword}
                        onChange={changePasswordForm.handleChange}
                        onBlur={changePasswordForm.handleBlur}
                        error={changePasswordForm.errors.oldPassword}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleOldPasswordVisibility}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            </InputAdornment>
                          }
                    />
                    <CeroInput
                        type={showNewPassword ? "text" : "password"}
                        id='password'
                        label='New password'
                        value={changePasswordForm.values.password}
                        onChange={changePasswordForm.handleChange}
                        onBlur={changePasswordForm.handleBlur}
                        error={changePasswordForm.errors.password}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleNewPasswordVisibility}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            </InputAdornment>
                          }
                    />
                    <CeroInput
                        type={showConfirmPassword ? "text" : "password"}
                        id='confirmPassword'
                        label='Confirm password'
                        value={changePasswordForm.values.confirmPassword}
                        onChange={changePasswordForm.handleChange}
                        onBlur={changePasswordForm.handleBlur}
                        error={changePasswordForm.errors.confirmPassword}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleConfirmPasswordVisibility}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            </InputAdornment>
                          }
                    />
                    <Box className={classes.buttonContainer}>
                        <CeroButton
                            variant="contained"
                            onClick={handleSubmit}
                            buttonText="Submit"
                            className={classes.buttonPrimary}
                            disabled={!changePasswordForm.dirty || !changePasswordForm.isValid || changePasswordData.status === STATUS.RUNNING}
                            fullWidth
                        />
                    </Box>
                </Paper>
            </Container>
        </DashboardLayout >
    );
}

export default ChangePassword;
