import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "30%",
    backgroundColor: "#EDFFF8",
  },
  signup: {
    backgroundColor: "#EDFFF8",
    minHeight: "100vh",
  },
  signupContainer: {
    padding: theme.spacing(6),
    boxShadow: "0px 6px 18px rgb(0 0 0 / 6%)",
    borderRadius: theme.spacing(2),
    margin: theme.spacing(10, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(5),
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(3, 0, 0, 0),
  },
  button: {
    margin: theme.spacing(0, 2),
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.light,
    },
  },
  buttonPrimary: {
    background: "#2A6565",
    textTransform: "capitalize",
    width: 80,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#2A6565",
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: "#2A6565",
    borderColor: "#2A6565",
    textTransform: "capitalize",
    width: 80,
    height: 42,
    "&:hover": {
      borderColor: "#2A6565",
      backgroundColor: "#2A6565",
      color: "#FFF",
    },
    marginRight: theme.spacing(5),
  }
}));

export default useStyles;
