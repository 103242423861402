import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
  },
  container: {
    padding: 0,
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    margin: 0,
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight:500,
    fontSize: 14
  },
  reported: {
    color: theme.palette.Error.main,
    fontWeight:500,
    fontSize: 14
  },
  submitted:{
    color: theme.palette.status.open,
    fontWeight:500,
    fontSize: 14
  },
  added:{
    color: theme.palette.status.added,
    fontWeight:500,
    fontSize: 14
  },
  review:{
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  }
}));

export default useStyles;
