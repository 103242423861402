import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";
import useStyles from "./styles";
import TicketIcon from "../../../../assets/icons/TicketIcon.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroButton from "../../../../components/CeroButton";
import ListTicketDrawer from "../../../common/ListTicketsDrawer";
import CeroConfirmDrawer from "../../../../components/CeroConfirmDrawer";
import { deleteEmissions, resetAddCombustionStatus } from "../../../../redux/actions";
import ListComments from "../../../EmissionsDetails/ListComment";
import ListAuditTrails from "../../../EmissionsDetails/ListAuditTrails";
import ListEmissionFiles from "../../../EmissionsDetails/ListEmissionFiles";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import { getCookie } from "../../../../services/cookie";
import CeroItemPair from "../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import dayjs from "dayjs";

const EmployeeDiversityDetails = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {  emissionData, setIsDrawerOpen} = props;
  const userRole = useSelector(state => state.auth.userInfo.role);
  const role = getCookie('role');
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const isDeleteEnable = [rolesEnum.FACILITY_MANAGER, rolesEnum.BUSINESS_USER, rolesEnum.APPROVER].includes(userRole)
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const [value, setValue] = useState(0);
  const [showTickets, setShowTickets] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const isFacMgr = getCookie('role') === "facility_manager"
  const isSusManager = (getCookie('role') === "sustainability_manager" || getCookie('role') === "proxy_sustainability_manager")

  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );

  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Benefits record deleted successfully",{ variant: "success",});
      dispatch(resetAddCombustionStatus());
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(deleteEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetAddCombustionStatus());
    }
  }, [deleteEmissionData, enqueueSnackbar, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onConfirmDelete = () => {
    const requestData = {
      id: emissionData.id,
    };
    dispatch(deleteEmissions(requestData));
  };

  const onclickShowTickets = () => {
    setShowTickets(true);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  const onUpdateRecord = () => {
    navigate(
      `/emissions/edit/employees_diversity_and_ratio_of_basic_salary/${emissionData.id}`
    );
  };

  return (
    <>
    <div className={classes.headerContainer}>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
        </div>
              <Box>
                <CeroButton
              buttonText="Raise a ticket"
              className={classes.buttonPrimary}
              onClick={() => setIsDrawerOpen(true)}
              disabled={readOnlyMode}
            />
            <IconButton onClick={onclickShowTickets} disabled={readOnlyMode}>
            <img src={TicketIcon} alt="Logo" width="25" height="25"/>
            </IconButton>
              </Box>
            </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
          {emissionData.basic_salary_ratio_male === null && emissionData.basic_salary_ratio_female === null ?
          <Typography variant="h6" component="div">
            Employee Diversity
          </Typography> :
          <Typography variant="h6" component="div">
          Ratio of Basic Salary
        </Typography>}
        </Box>
        <Box className={classes.topContainer}>
          <Grid
            container
            direction={"row"}
            wrap="nowrap"
            justifyContent={"space-between"}
            display={"contents"}
            spacing={12}
          >
            <Grid item container direction={"column"} display={"contents"} xs={10}>
            <Box className={classes.actionBox}>
            <CeroInfoPair 
                title="Status:"
                value={emissionData.status ? emissionData.status === 'pending_approval' ? 'pending approval' : emissionData.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: emissionData.status === "In Progress" || emissionData.status === "pending_approval" ? classes.Inprogress : classes[emissionData?.status]
                }}
              /></Box>
            <CeroItemPair
              title="Facility:"
              value={emissionData.facility_name ? emissionData.facility_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Period:"
              value={emissionData.assessment_period ? emissionData.assessment_period : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Year:"
              value={emissionData.year ? emissionData.year : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            {emissionData.distribution === "null" ? "" :
            <>
            {emissionData.percentage_of_employee1 === 0 ? "" : <CeroItemPair
              title={emissionData.percentage_of_employee1_title}
              value={emissionData.percentage_of_employee1 ? `${emissionData.percentage_of_employee1}%` : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />}
            {emissionData.percentage_of_employee2 === 0 ? "" : <CeroItemPair
              title={emissionData.percentage_of_employee2_title}
              value={emissionData.percentage_of_employee2 ? `${emissionData.percentage_of_employee2}%` : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />}
            {emissionData.percentage_of_employee3 === 0 ? "" : <CeroItemPair
              title={emissionData.percentage_of_employee3_title}
              value={emissionData.percentage_of_employee3 ? `${emissionData.percentage_of_employee3}%` : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />}
            {emissionData.basic_salary_ratio_male === null ? "" : <CeroItemPair
              title="Basic Salary Ratio (Male)"
              value={emissionData.basic_salary_ratio_male ? emissionData.basic_salary_ratio_male : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />}
            {emissionData.basic_salary_ratio_female === null ? "" : <CeroItemPair
              title="Basic Salary Ratio (Female)"
              value={emissionData.basic_salary_ratio_female ? emissionData.basic_salary_ratio_female : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />}</>}
              {/* {emissionData.percentage_of_employee1 === 0 ? "" : <Typography className={classes.previewItem}>
                {emissionData.percentage_of_employee1_title}: {emissionData.percentage_of_employee1}%
              </Typography>}
              {emissionData.percentage_of_employee2 === 0 ? "" :<Typography className={classes.previewItem}>
              {emissionData.percentage_of_employee2_title} : {emissionData.percentage_of_employee2}%
              </Typography>}
              {emissionData.percentage_of_employee3 === 0 ? "" : <Typography className={classes.previewItem}>
              {emissionData.percentage_of_employee3_title}: {emissionData.percentage_of_employee3}%
              </Typography>} */}
              {/* {emissionData.basic_salary_ratio_male === null ? "" : <Typography className={classes.previewItem}>
                Basic Salary Ratio (Male): {emissionData.basic_salary_ratio_male}
              </Typography>}
              {emissionData.basic_salary_ratio_female === null ? "" : <Typography className={classes.previewItem}>
              Basic Salary Ratio (Female): {emissionData.basic_salary_ratio_female}
              </Typography>} */}
            {emissionData.audited_by && <>
              <CeroItemPair
              title="Audited by:"
              value={emissionData.audited_by ? emissionData.audited_by : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Audited on:"
              value={emissionData.audited_on
                ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            </>}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        {isDeleteEnable && (
          <CeroButton
            buttonText={<DeleteOutlineIcon />}
            className={clsx(classes.button, classes.deleteButton)}
            onClick={() => setDisplayWarning(true)}
            disabled={readOnlyMode}
          />
        )}
        {emissionData?.is_able_to_update === true && (
          <CeroButton
            buttonText="Update"
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onUpdateRecord}
            disabled={readOnlyMode}
          />
        )}
      </Box>
      <Box
        className={classes.tabContainer}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs value={value} className={classes.tabs} onChange={handleChange} aria-label="emission tabs">
          <Tab label="Comments" id="comments" />
          <Tab label="Attachment" id="files" />
          <Tab label="Change Logs" id="audit-history" />
        </Tabs>
      </Box>
      {value === 0 && <ListComments emissionId={emissionData.id} isAuditor={props.isAuditor} company={props.company}/>}
      {value === 2 && <ListAuditTrails emissionId={emissionData.id} isAuditor={props.isAuditor} company={props.company}/>}
      {value === 1 && <ListEmissionFiles emissionId={emissionData.id} isAuditor={props.isAuditor} company={props.company}/>}
      {displayWarning && (
        <CeroConfirmDrawer
          isOpen={displayWarning}
          onClose={() => setDisplayWarning(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {showTickets && (
        <ListTicketDrawer
          isOpen={showTickets}
          scope="emission"
          scopeId={emissionData.id}
          onClose={onCloseTickets}
        />
      )}
    </Container>
    </>
  );
};

export default EmployeeDiversityDetails;
