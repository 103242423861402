import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    height: "100%",
    borderRadius: 8,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100% - 100px)",
    background: theme.palette.background.white,
    overflow: "auto",
    marginBottom: theme.spacing(5),
    borderRadius: 8,
    boxShadow: "none",
  },
  pending:{
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14
  },
  completed:{
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14
  },
  view:{
    color: theme.palette.status.approved,
    textDecoration: "underline",
    fontWeight: 500,
    fontSize: 14,
    cursor: "pointer"
  },
  reminder:{
    color: "#8C2148",
    textDecoration: "underline",
    fontWeight: 500,
    fontSize: 14,
    cursor: "pointer"
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: theme.spacing(5),
    alignItems: "center",
    cursor: "pointer",
  },
  dialog: {
    width: "45% !important",
    textAlign: "center",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  popupContent:{
    whiteSpace: "nowrap",
    paddingTop: theme.spacing( 5),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: 5,
    paddingTop: theme.spacing( 8),
    paddingBottom: 15,
    width: "50%",
    margin: "auto",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    marginRight: 15,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.contrastText,
    },
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer"
  },
}));

export default useStyles;
