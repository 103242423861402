import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function CeroRadioButton(props) {
    const [value, setValue] = React.useState(props.default);

    const handleChange = (event) => {
        setValue(event.target.value);
        props.value(event.target.value)
    };
    return (
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">{props.label}</FormLabel>
            <RadioGroup
                row={props.row}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                {props.labels.map((key) => (
                    <FormControlLabel value={key} control={<Radio />} label={key} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}