import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables as registerablesJS,
} from "chart.js";
import { Line } from "react-chartjs-2";
import _ from "lodash";

import useStyles from "./styles";
import CeroItemPair from "../../../../components/CeroItemPair";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const InitiativeDetailsGraph = (props) => {
  const classes = useStyles();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          fontColor: "rgba(0,0,0,.4)",
        },
        align: "end",
        position: "bottom",
      },
      title: {
        align: "start",
        display: true,
        text: "Task Progress",
        color: "black",
        padding: {
          bottom: 20,
        },
        font: {
          size: 18,
          family: "Inter",
          weight: "400",
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Progress Percentage",
          color: "#4F4F4F",
          font: {
            size: 12,
            family: "Inter",
            weight: "400",
          },
        },
        ticks: {
          color: "black",
          // callback: formatAxisValue,z
        },
      },
      x: {
        ticks: {
          color: "black",
        },
      },
    },
  };

  const data = {
    labels: _.map(props.data.task, (item) => item.task_no),
    datasets: [
      {
        label: "Progress Percentage",
        data: _.map(props.data.task, (item) => item.progress),
        color: "black",
        borderColor: "#846BB5",
        backgroundColor: "#6FBFBA",
      },
    ],
  };

  return (
    <Paper className={classes.container}>
      <Grid>
        <Typography variant="h6" fontWeight={500}>
          {props.data.initiative_id} -{" "}
          {capitalizeFirstLetter(props?.data?.initiative_name)}
        </Typography>
        <Box className={classes.subheaderContainer}>
          <Box className={classes.subheader}>
            <Typography variant="body1" className={classes.filterText}>
              Target:
            </Typography>
            <Typography variant="body1" className={classes.textValue}>
              {props.data.target}Ltr/Month
            </Typography>
          </Box>
          <Box className={classes.subheader}>
            <Typography variant="body1" className={classes.filterText}>
              Actual:
            </Typography>
            <Typography variant="body1" className={classes.textValue}>
              {props.data.actual}Ltr/Month
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Box display={"flex"} gap={5} alignItems={"center"} mt={5}>
        <Paper className={classes.innerContainer}>
          {props.data.task.length > 0 ? (
            <Line options={options} data={data} height={325} width={560} />
          ) : (
            "No Data"
          )}
        </Paper>
        <Paper className={classes.innerContainer}>
          {props.data.task.map((item) => (
            <CeroItemPair
              key={item.id}
              title={`${item.task_no} - ${item.task}`}
              value={item.progress_percentage}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
          ))}
        </Paper>
      </Box>
    </Paper>
  );
};

export default InitiativeDetailsGraph;
