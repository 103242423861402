import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  StationaryColumns,
  MobileColumns,
  PurchasedElectricityColumns,
  WaterDischargeColumns,
  WaterConsumptionColumns,
  RefrigerantsColumns,
  TransportationColumns,
  WasteCombustionColumns,
  DevelopmentTrainingColumns,
  EmployeeHealthDetailsColumns,
  WorkerSafetyTrainingColumns,
  EmployeeDescriminationIncidentColumns,
  SupplierHumanRightsTrainigColumns,
  SocialHumanRightsTrainigColumns,
  SupplierScreeningColumns,
  LocalCommunitiesColumns,
  PoliticalContributionColumns,
  AntiCorruptionDisclosureColumns,
  AntiCorruptionTrainigColumns,
  AntiCompetitiveDisclosureColumns,
  FinancialAssistanceColumns,
  UploadFileEmissionColumns,
  EmployeeHireAndTurnover,
  EmployeeBenefits,
  TrainingHoursPerEmployee,
  PerformanceAndCareerDevelopmentProgramsColumns,
  // EconominImpactColumns,
  BoardDiversityByGenderColumns,
  BoardDiversityByAgeGroupColumns,
  ManagementDiversityByGenderColumns,
  ManagementDiversityByAgeGroupColumns,
  EconomicImpactColumns,
} from "./TableColumns";
import {
  StationaryColumnsReport,
  MobileColumnsReport,
  PurchasedElectricityColumnsReport,
  WaterDischargeColumnsReport,
  WaterConsumptionColumnsReport,
  RefrigerantsColumnsReport,
  TransportationColumnsReport,
  WasteCombustionColumnsReport,
  DevelopmentTrainingColumnsReport,
  EmployeeHealthDetailsColumnsReport,
  WorkerSafetyTrainingColumnsReport,
  EmployeeDescriminationIncidentColumnsReport,
  SupplierHumanRightsTrainigColumnsReport,
  SocialHumanRightsTrainigColumnsReport,
  SupplierScreeningColumnsReport,
  LocalCommunitiesColumnsReport,
  PoliticalContributionColumnsReport,
  AntiCorruptionDisclosureColumnsReport,
  AntiCorruptionTrainigColumnsReport,
  AntiCompetitiveDisclosureColumnsReport,
  FinancialAssistanceColumnsReport,
  UploadFileEmissionColumnsReport,
  EmployeeBenefitsColumnsReport,
  TrainingHoursColumnsReport,
  PerformanceCareerColumnsReport,
} from "./TableColumnReport";
import {
  StationaryColumnsStakeholderReport,
  MobileColumnsStakeholderReport,
  PurchasedElectricityColumnsStakeholderReport,
  WaterDischargeColumnsStakeholderReport,
  WaterConsumptionColumnsStakeholderReport,
  RefrigerantsColumnsStakeholderReport,
  TransportationColumnsStakeholderReport,
  WasteCombustionColumnsStakeholderReport,
  DevelopmentTrainingColumnsStakeholderReport,
  EmployeeHealthDetailsColumnsStakeholderReport,
  WorkerSafetyTrainingColumnsStakeholderReport,
  EmployeeDescriminationIncidentColumnsStakeholderReport,
  SupplierHumanRightsTrainigColumnsStakeholderReport,
  SocialHumanRightsTrainigColumnsStakeholderReport,
  SupplierScreeningColumnsStakeholderReport,
  LocalCommunitiesColumnsStakeholderReport,
  PoliticalContributionColumnsStakeholderReport,
  AntiCorruptionDisclosureColumnsStakeholderReport,
  AntiCorruptionTrainigColumnsStakeholderReport,
  AntiCompetitiveDisclosureColumnsStakeholderReport,
  FinancialAssistanceColumnsStakeholderReport,
  UploadFileEmissionColumnsStakeholderReport,
} from "./TableColumns";
import CeroTable from "../../../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../../../redux/constants";
import CeroButton from "../../../../../components/CeroButton";
import { getCookie } from "../../../../../services/cookie";

const EmissionTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    isStakeholder,
    company,
    backToEmission,
    headerOff,
    editOff,
    reportTable,
    isOnlyAdmin,
    onSelectRow
  } = props;



  const getTableColumn =  isStakeholder ? 
  {
    stationary_combustion:StationaryColumnsStakeholderReport,
    mobile_combustion: MobileColumnsStakeholderReport,
    purchased_electricity: PurchasedElectricityColumnsStakeholderReport,
    water_discharge: WaterDischargeColumnsStakeholderReport,
    water_consumption: WaterConsumptionColumnsStakeholderReport,
    refrigerants: RefrigerantsColumnsStakeholderReport,
    transportation: TransportationColumnsStakeholderReport,
    waste: WasteCombustionColumnsStakeholderReport,
    development_training: DevelopmentTrainingColumnsStakeholderReport,
    employee_health_safety_incident_record: EmployeeHealthDetailsColumnsStakeholderReport,
    worker_safety_training_procedures: WorkerSafetyTrainingColumnsStakeholderReport,
    discrimination_incident_record: EmployeeDescriminationIncidentColumnsStakeholderReport,
    supplier_screening_using_enviormental_social_criteria: SupplierScreeningColumnsStakeholderReport,
    suppliers_human_rights: SupplierHumanRightsTrainigColumnsStakeholderReport,
    social_engagement_human_rights_training: SocialHumanRightsTrainigColumnsStakeholderReport,
    local_communities: LocalCommunitiesColumnsStakeholderReport,
    political_contributions: PoliticalContributionColumnsStakeholderReport,
    anti_corruption_disclosure: AntiCorruptionDisclosureColumnsStakeholderReport,
    anti_corruption_training: AntiCorruptionTrainigColumnsStakeholderReport,
    anti_competitive_disclosure: AntiCompetitiveDisclosureColumnsStakeholderReport,
    subsidies_financial_assistance: FinancialAssistanceColumnsStakeholderReport,
    employees_turnover: UploadFileEmissionColumnsStakeholderReport,
  }
   :
   {
    stationary_combustion: reportTable
      ? StationaryColumnsReport
      : StationaryColumns,
    mobile_combustion: reportTable ? MobileColumnsReport : MobileColumns,
    purchased_electricity: reportTable
      ? PurchasedElectricityColumnsReport
      : PurchasedElectricityColumns,
    water_discharge: reportTable
      ? WaterDischargeColumnsReport
      : WaterDischargeColumns,
    water_consumption: reportTable
      ? WaterConsumptionColumnsReport
      : WaterConsumptionColumns,
    refrigerants: reportTable ? RefrigerantsColumnsReport : RefrigerantsColumns,
    transportation: reportTable
      ? TransportationColumnsReport
      : TransportationColumns,
    waste: reportTable ? WasteCombustionColumnsReport : WasteCombustionColumns,
    development_training: reportTable
      ? DevelopmentTrainingColumnsReport
      : DevelopmentTrainingColumns,
    employee_health_safety_incident_record: reportTable
      ? EmployeeHealthDetailsColumnsReport
      : EmployeeHealthDetailsColumns,
    worker_safety_training_procedures: reportTable
      ? WorkerSafetyTrainingColumnsReport
      : WorkerSafetyTrainingColumns,
    discrimination_incident_record: reportTable
      ? EmployeeDescriminationIncidentColumnsReport
      : EmployeeDescriminationIncidentColumns,
    supplier_screening_using_enviormental_social_criteria: reportTable
      ? SupplierScreeningColumnsReport
      : SupplierScreeningColumns,
    suppliers_human_rights: reportTable
      ? SupplierHumanRightsTrainigColumnsReport
      : SupplierHumanRightsTrainigColumns,
    social_engagement_human_rights_training: reportTable
      ? SocialHumanRightsTrainigColumnsReport
      : SocialHumanRightsTrainigColumns,
    local_communities: reportTable
      ? LocalCommunitiesColumnsReport
      : LocalCommunitiesColumns,
    political_contributions: reportTable
      ? PoliticalContributionColumnsReport
      : PoliticalContributionColumns,
    anti_corruption_disclosure: reportTable
      ? AntiCorruptionDisclosureColumnsReport
      : AntiCorruptionDisclosureColumns,
    anti_corruption_training: reportTable
      ? AntiCorruptionTrainigColumnsReport
      : AntiCorruptionTrainigColumns,
    anti_competitive_disclosure: reportTable
      ? AntiCompetitiveDisclosureColumnsReport
      : AntiCompetitiveDisclosureColumns,
    subsidies_financial_assistance: reportTable
      ? FinancialAssistanceColumnsReport
      : FinancialAssistanceColumns,
    employees_turnover: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    board_diversity: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    management_diversity: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    tax: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    direct_economic_impact: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    indirect_economic_impact: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    employees_hire_and_turnover: reportTable
      ? UploadFileEmissionColumnsReport
      : EmployeeHireAndTurnover,
    employees_benefit: reportTable
      ? EmployeeBenefitsColumnsReport
      : EmployeeBenefits,
    training_hours_per_employee: reportTable
      ? TrainingHoursColumnsReport
      : TrainingHoursPerEmployee,
    performance_and_career_development_programs: reportTable
      ? PerformanceCareerColumnsReport
      : PerformanceAndCareerDevelopmentProgramsColumns,
    economic_impact: reportTable
      ? UploadFileEmissionColumnsReport
      : EconomicImpactColumns,    
  }
  ;


  
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  // const selectedYear = queryParams.get("year");

  // const onSelectEmissionData = (emission) => {
  //   isAuditor
  //     ? navigate(
  //       `/emissions/${emissionType}/details/${emission.id}/?company=${company}`
  //     )
  //     : navigate(`/emissions/${emissionType}/details/${emission.id}`);
  // };
  // const onSelectEmissionData = (emission) => {
  // navigate(`/reports/list-topic/${emissionType}/details/${emission.id}`);
  // };

  const onEditEmissionData = (e, emission) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/emissions/edit/${emissionType}/${emission}`);
  };

  const uploadHistoryEmissionTypes = [
  "stationary_combustion",
  "mobile_combustion",
  "transportation",
  "purchased_electricity",
  "refrigerants",
];

  const getStatus = (status) => {
    const statusText = status === 'audited' ? 'Audited' : (status === 'approved' ? 'Approved' : 'Pending');
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    } else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    } else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }else if (status === 'In Progress') {
      return reportTable ? <Tooltip title="In Progress" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };
  const getStatusReport = (status) => {
    const statusColor =
      status === "audited"
        ? "#478A1D"
        : status === "approved"
          ? "#FFBF00"
          : "#D2222D";
    if (status === "approved") {
      return (
        <Chip
          sx={{
            bgcolor: statusColor,
            width: 20,
            height: 20,
            borderRadius: "50%",
            border: "1px solid black",
          }}
        />
      );
    } else if (status === "submitted") {
      return (
        <Chip
          sx={{
            bgcolor: statusColor,
            width: 20,
            height: 20,
            borderRadius: "50%",
            border: "1px solid black",
          }}
        />
      );
    } else if (status === "audited") {
      return (
        <Chip
          sx={{
            bgcolor: statusColor,
            width: 20,
            height: 20,
            borderRadius: "50%",
            border: "1px solid black",
          }}
        />
      );
    } else if (status === "reported") {
      return (
        <Chip
          sx={{
            bgcolor: statusColor,
            width: 20,
            height: 20,
            borderRadius: "50%",
            border: "1px solid black",
          }}
        />
      );
    } else if (status === "assigned") {
      return (
        <Chip
          sx={{
            bgcolor: statusColor,
            width: 20,
            height: 20,
            borderRadius: "50%",
            border: "1px solid black",
          }}
        />
      );
    } else {
      return (
        <Chip
          sx={{
            bgcolor: statusColor,
            width: 20,
            height: 20,
            borderRadius: "50%",
            border: "1px solid black",
          }}
        />
      );
    }
  };



  const getEmissionData = () =>
    Array.isArray(emissionData)? (emissionData && emissionData?.map((item) => ({
      ...item,
      
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      amount: `${item.amount}  ${item.unit ? item.unit : ""}`,
      status: getStatus(item.status),
      date: item.date,
      // statusReport: reportTable && getStatusReport(item.status),
      treatment_required: item.treatment_required ? "Yes" : "No",
      employees_participation_percentage: `${item.employees_participation_percentage} %`,
      action: (
        !readOnlyMode && 
        <Box className={classes.actionContainer}>
          {/* <CeroButton className={classes.button} buttonText={item.status} /> */}
          {item.status == "added" &&
            // item.status !== "audited" &&
            // item.status !== "assigned" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }))):[];

  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          {!headerOff && (
            <Box className={classes.tableTopic}>
              <Typography variant="h7" component="span">
              <span className={classes.tableTitle}>
              {emissionType.replace(/_/g, ' ')}
              </span>
              </Typography>
              <Box>
              <CeroButton
                buttonText="Back"
                variant="outlined"
                className={classes.buttonSecondary}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              />
              </Box>
            </Box>
          )}
          <CeroTable
            columns={getTableColumn[props.emissionType] || []}
            data={getEmissionData}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectRow}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default EmissionTable;
