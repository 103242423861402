import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 140px)',
        width: "85%",
        margin: "auto",
        marginTop: theme.spacing(5)
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: 20,
        alignItems: 'center',
        cursor: "pointer"
    },
    activeStatus:{
        color: theme.palette.status.closed,
        fontWeight:500,
        fontSize: 14
    },
    inActiveStatus:{
        color:  '#E81111',
        fontWeight:500,
        fontSize: 14
    },
    switchColor: {
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "white",
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: theme.palette.status.closed,
          opacity: 1.5,
        },
      },
      inactiveSwitchColor: {
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "grey",
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: "grey",
          opacity: 1.5,
        },
      },
      statusText: {
        fontSize: 10,
      },
}));

export default useStyles;