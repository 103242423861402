import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useStyles from "./styles";
import dayjs from "dayjs";

const CeroDateTime = (props) => {
  const classes = useStyles();
  // const adapter = props.useDayjs ? AdapterDayjs : AdapterDateFns;

  const boxStyles = clsx(
    classes.container,
    props.classes?.container,
    props.error && classes.errorBox
  );

  const handleDateChange = (newValue) => {
    const timestamp = newValue ? newValue.valueOf() : null;
    props.onChange(timestamp);
  };

  return (
    <Box className={boxStyles}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={props.label}
          value={props.value ? dayjs(props.value) : null}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!props.error}
              helperText={props.error ? props.helperText : ""}
            />
          )}
        />
      </LocalizationProvider>
      {!!props.helperText && (<Typography className={clsx(classes.text, props.classes?.helperText)}>{props.helperText}</Typography>)}
      {!!props.error && (<Typography className={clsx(classes.text, classes.error, props.classes?.error)}>{props.error}</Typography>)}
    </Box>
  );
};

export default CeroDateTime;
