import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Link, Stack, TextField, Typography } from "@mui/material";
import { getLocations, getProjectDetails } from "../../../../redux/actions";
import CeroItemPair from "../../../../components/CeroItemPair";
import ReactCountryFlag from "react-country-flag";
import CeroButton from "../../../../components/CeroButton";
import { useFormik } from "formik";

const CarbonCreditsProject = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const projectDetails = useSelector(
    (state) => state.trade.getProjectDetails.data
  );
  
  const countryListData = useSelector((state) => state.trade.getLocations.data);

  const formik = useFormik({
    initialValues: {
      projectName: "",
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    dispatch(getLocations());
    id && dispatch(getProjectDetails(id));
  }, [dispatch, id]);

  const getCountryCode = (location) => {
    if (!location || !countryListData) return "";
    const countryName = location.split(", ").pop();
    const country = countryListData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    return country ? country.code : "";
  };

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Box className={classes.boxContainer}>
        <Grid className={classes.titleGrid}>
          <Grid item>
            <Typography variant="h6">{projectDetails?.project_name}</Typography>
            <div className={classes.location}>
              <ReactCountryFlag
                countryCode={getCountryCode(projectDetails?.location)}
                svg
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  marginRight: "0.8em",
                }}
              />
              <Typography variant="body1">
                {projectDetails?.location}
              </Typography>
            </div>
          </Grid>
          <Grid item display="flex">
            <Typography className={classes.topRight}
            >
              {projectDetails?.co2_reduction}t CO2 Reductions
            </Typography>
            <Typography>{projectDetails?.certification}</Typography>
          </Grid>
        </Grid>

        <Typography className={classes.contentBox}>
          {projectDetails?.additional_details}
        </Typography>
        <Grid spacing={4} className={classes.gridContainer}>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>
              Project Type
            </Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.project_type}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>
              Price per ton (USD)
            </Typography>
            <Typography className={classes.gridItem}>
              ${projectDetails?.price_per_ton}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>
              Start Date
            </Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.start_date}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>End Date</Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.end_date}
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.contactBox}>
          <Typography style={{ fontWeight: 500, marginBottom: 10 }}>
            Contact Information
          </Typography>
          <Typography className={classes.gridItemTitle}>
            Contact Person: {projectDetails.contact_person || "NA"}
          </Typography>
          <Typography className={classes.gridItemTitle}>
            Email: {projectDetails.email || "NA"}
          </Typography>
          <Typography className={classes.gridItemTitle}>
            Phone: {projectDetails.phone || "NA"}
          </Typography>
          <Typography className={classes.gridItemTitle}>
            Website: {projectDetails.website || "NA"}
          </Typography>
        </Box>
      </Box>
      <Stack padding={7} gap={2}>
            <Typography className={classes.title}>Purchase Carbon Credits</Typography>
            <TextField
                    id="projectName"
                    name="projectName"
                    type="number"
                    placeholder="Input number of Carbon Credits to purchase"
                    variant="outlined"
                    className={classes.textField}
                    value={formik.values.projectName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
            <CeroButton
              buttonText="Contact Now"
              className={classes.buttonPrimary}
              onClick={()=>{}}
            />
        </Stack>
    </DashboardLayout>
  );
};

export default CarbonCreditsProject;
