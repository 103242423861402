import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0,
    },
    drawerContainer: {
      maxWidth: '30vw',
      width: '30vw',
    },
    drawerContentArea: {
      padding: theme.spacing(4),
      overflow: 'auto'
    },
    selectCheckbox: {
        padding: theme.spacing(3),
      },
      checkbox: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        maxWidth: "27vw",
        border: "1px solid #5A404E",
        borderRadius: 4,
        maxHeight: "55vh",
        overflow:"scroll",
        '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey',
            borderRadius:8
          }
      },

      justCheckSelected:{
        width:'100%',
        maxHeight:'100%',
        "& .MuiOutlinedInput-notchedOutline":{
            borderColor: `${theme.palette.Primary.main} !important`
          },
        "& .Mui-focused > fieldset > legend":{
          maxWidth: '100%',
          paddingLeft: "140px !important"
        }
      },
      multiSelect: {
        width: "100%",
        height: "45px",
        margin: theme.spacing(2, 0),
        borderColor: "#1D1D1D !important",
        borderRadius: 4,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#1D1D1D !important",
          borderRadius: 4,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#1D1D1D !important",
          borderRadius: 4,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#1D1D1D !important",
          borderRadius: 4,
        },
      },

}));

export default useStyles;
