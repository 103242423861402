import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        height: '320px',
        borderRadius: 8,
        margin: theme.spacing(4, 0),
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
        '& svg': {
            padding: theme.spacing(0),
        }
    },
}));

export default useStyles;
