import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
    marginTop: theme.spacing(5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight:500,
    fontSize: 14
  },
  verified:{
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  review:{
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  },
  pending:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  default:{
    color: theme.palette.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  added: {
    color: theme.palette.status.added,
    fontWeight:500,
    fontSize: 14
  },
  filterContainer:{
    display: "flex",
    justifyContent: "flex-end",
    width: 300,
    marginRight:10,
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  }
}));

export default useStyles;
