import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiDialog-container':{
      margin: "auto",
    },
    '& .MuiDialog-paper':{
      backgroundColor: theme.palette.Primary.light,
      width: "100%"
    },
    '& .MuiDialogTitle-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(20), 
      paddingTop: theme.spacing(10)
    },
  },
  icon: {
    margin: theme.spacing(5),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  card: {
    backgroundColor: theme.palette.background.white,
    color: 'black',
    width: "75%",
    margin: "auto"
  },
  numberBox: {
    backgroundColor: 'rgba(90, 64, 78, 0.1)',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding:  theme.spacing(4),
    borderRadius: 5,
    marginTop: theme.spacing(4),
  },
  boxTitle:{
    textAlign: "center",
    fontWeight: 600
  },
  boxValue:{
    fontWeight: 700,
  },
  underline:{
    height: 1,
    backgroundColor: theme.palette.Primary.main, 
    marginBottom: 25,
  },
  tableTitle:{
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    fontWeight: 500
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20
  },
  buttonPrimary: {
    color: theme.palette.Primary.background,
    backgroundColor:theme.palette.background.white,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    float: "right",
    marginTop: theme.spacing(3),
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor:theme.palette.Secondary.hoverButton,
    },
    },
    DialogSubtitle:{
      paddingTop: 10
    },
    Link:{
      fontSize: 16
    }
}));

export default useStyles;
