import * as yup from "yup";

export const emissionDownloadValidation = yup.object({
  file: yup
    .mixed()
    .nullable()
    .required("File is required"),
  // facility: yup
  //   .string("Facility is required")
  //   .required("Facility is required"),
  // year: yup
  //   .number("Emission year is required")
  //   .required("Emission year is required"),
  // month: yup
  //   .string("Emission month is required")
  //   .required("Emission month is required"),
});
