import { Box, Container } from "@mui/material";
import dayjs from "dayjs";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import useStyles from "./styles";

const Status = ({ reportDetails, reportType }) => {
  const classes = useStyles();

  console.log('reportDetails', reportDetails)

  return (
    <Container className={classes.container}>
      {reportType === "audited_report" ? (
        <Box>
          <CeroInfoPair
            title="Year"
            value={reportDetails.year}
            classes={{ container: classes.infoContainer }}
          />
          {/* {reportDetails.facilities?.length>0 && (<CeroInfoPair title="Facilities" value={reportDetails.facilities} classes={{ container: classes.infoContainer}} />)} */}
          <CeroInfoPair
            title="Assigned By"
            value={reportDetails.assigned_by}
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Audited on"
            value={
              reportDetails.audited_on &&
              dayjs(reportDetails.created_ts).format("DD MMM YYYY HH:mm")
            }
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Assigned on"
            value={
              reportDetails.assigned_on &&
              dayjs(reportDetails.assigned_on).format("DD MMM YYYY HH:mm")
            }
            classes={{ container: classes.infoContainer }}
          />
        </Box>
      ) : (
        <Box>
          <CeroInfoPair
            title="Name"
            value={reportDetails.name}
            classes={{ container: classes.infoContainer }}
          />
          {/* {reportDetails.facilities?.length>0 && (<CeroInfoPair title="Facilities" value={reportDetails.facilities} classes={{ container: classes.infoContainer}} />)} */}
          <CeroInfoPair
            title="Framework"
            value={reportDetails.framework_name}
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Year"
            value={reportDetails.year}
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Created by"
            value={reportDetails.created_by_name}
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Created on"
            value={
              reportDetails.created_ts &&
              dayjs(reportDetails.assigned_on).format("DD MMM YYYY HH:mm")
            }
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Period"
            value={ reportDetails.assessment_period}
            classes={{ container: classes.infoContainer }}
          />
          
        </Box>
      )}
    </Container>
  );
};

export default Status;
