import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        height: '100%',
        width: '100%',
        maxWidth: '100%',
    },
    loader: {
        width: "100%",
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
}));

export default useStyles;
