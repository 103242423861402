import { Box, Checkbox, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroDropdown from "../../components/CeroDropdown";
import useStyles from "./styles";
import { designationWorkFlow, sampleYear } from "../../constants";
import ArrowIcon from "../../assets/icons/ArrowIcon.png"
import ArrowIconDisabled from "../../assets/icons/ArrowDisabled.png"
import { getApprovalFlow, resetApprovalUpdate, resetGetApproval, updateApprovalFlow } from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import { useSnackbar } from "notistack";
import CeroButton from "../../components/CeroButton";
import CeroPopUp from "../../components/CeroPopUp";

const ApprovalWorkFlow = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [country, setCountry] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [selected, setSelected]  = useState(null);
    const [selectedFlow, setSelectedFlow]  = useState(null);
    const [showSaveButton, setShowSaveButton]  = useState(false);
    const [open,setOpen] = useState(false);
    const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
    const getApproval = useSelector(state => state.company.approvalFlow.data?.approval_flow);
    const getApprovalStatus = useSelector(state => state.company.approvalFlow.status);
    const updateApprovalStatus = useSelector(state => state.company.approvalFlowUpdate.status);
    const updateApproval = useSelector(state => state.company.approvalFlowUpdate);
    const countryMap = {};
    let countryOptions = [];
    useEffect(() => {
        if (country && year) {
            dispatch(getApprovalFlow(country, year));
        }

        // Cleanup function to reset selected when component unmounts
        return () => {
            setSelected(null);
            dispatch(resetGetApproval())
        };
    }, [country, year, dispatch]);

    useEffect(() => {
        if (getApprovalStatus === STATUS.SUCCESS) {
            setSelected(getApproval);
        } else {
            setSelected(null);
        }
    }, [getApprovalStatus, getApproval]);
      useEffect(() => {
        if (updateApprovalStatus == STATUS.SUCCESS)
            enqueueSnackbar("User Flow Selected Successfully !", { variant: "success" });
        else if (updateApprovalStatus == STATUS.ERROR)
            enqueueSnackbar(updateApproval.message.message, { variant: "error" });
        dispatch(resetApprovalUpdate())
      }, [updateApprovalStatus]);
    for (const [key, value] of Object.entries(countryData)) {
        countryMap[value] = key;
        countryOptions = countryOptions.concat({key:value,value:key})
    }
    countryOptions.unshift({key:null, value: 'Select Option'})
    const handleCountryChange = (event) => {
        setCountry(event.target.value);
      };
    const handleYearChange = (event) => {
    setYear(event.target.value);
    };
    const checkBoxChange = (item, name) => (event) => {
        if (event.target.checked) {
          setSelected(item);
          setSelectedFlow(name)
        //   dispatch(updateApprovalFlow(country, year, item))
        if (year && country )
            setShowSaveButton(true)
        else
            setShowSaveButton(false)
        } else 
        {
            setShowSaveButton(false)
            if (year && country && getApproval){
                enqueueSnackbar("Changing approval is not allowed", { variant: "error" });
            }
            else{
                setSelected(null)

            }
      }
    };

      const getApproveConfirm = (flow) => {
        return (
          <Box className={classes.popUp}>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              Are you sure you want to save "{flow}" as your approval flow?
            </Typography>
            <div className={classes.buttonContainerReview}>
              <CeroButton
                className={classes.buttonPrimary}
                variant="contained"
                onClick={() => {
                    year && country && dispatch(updateApprovalFlow(country, year, selected))
                    setOpen(false)
                    setShowSaveButton(false)
                }}
                buttonText="Confirm"
              />
              <CeroButton
                className={classes.buttonSecondary}
                variant="outlined"
                onClick={() => setOpen(false)}
                buttonText="Cancel"
              />
            </div>
          </Box>
        );
      };
    return (
        <DashboardLayout>
        <Container className={classes.container}>
            <Grid container spacing={2} className={classes.filterHeader}>
                <Grid item xs={3} className={classes.filterContainer}>
                    <CeroDropdown
                        id="country"
                        label="Country"
                        fullWidth
                        options={countryOptions}
                        onChange={handleCountryChange}
                        selectedValue={country}
                        />
                </Grid>
                <Grid item xs={3} className={classes.filterContainer}>
                    <CeroDropdown
                        id="year"
                        label="Assessment Year"
                        fullWidth
                        options={sampleYear()}
                        onChange={handleYearChange}
                        selectedValue={year}
                        />
                </Grid>
                {showSaveButton && 
                <Grid item xs={5.4} className={classes.saveDiv}>
                    <CeroButton
                        buttonText="Save"
                        className={classes.buttonPrimary}
                        onClick={() => setOpen(true)}
                        // disabled={readOnlyMode}   
                        />
                </Grid>
                }
            </Grid>
            <Grid container className={classes.content} spacing={2}>
                {designationWorkFlow.map(item=>{
                        const isSelected = (selected) && (item.value.every((value, index) => value === selected[index]))
                        return(
                        <Grid item xs={12} className={selected ? isSelected ? classes.flowMainContainerSelected : classes.flowMainContainerDisabled : classes.flowMainContainer}>
                            <div className={classes.textAction}>
                                <Typography className={selected ? isSelected ? classes.flowText : classes.flowTextDisabled : classes.flowText}>{item.name}:</Typography>
                                <Checkbox  checked={isSelected} disabled={selected && !isSelected} style={{ color: selected ? isSelected ? "#8C2148" : "rgba(162, 159, 159, 1)" : "#8C2148" }}  onChange={checkBoxChange(item.value, item.name)}/>
                            </div>
                            <div className={classes.designationOuterBox}>
                                <div className={selected ? isSelected ? classes.designationBox : classes.designationBoxDisabled : classes.designationBox}>
                                    <Typography className={selected ? isSelected ?  classes.designationText: classes.designationTextDisabled :classes.designationText}>{item.designationOrder[0]}</Typography>
                                </div>
                                {item.designationOrder.slice(1,).map((designation, index) => (
                                    <>
                                    <img src={selected ? isSelected ? ArrowIcon : ArrowIconDisabled : ArrowIcon } alt="Arrow" width="30" height="25" />
                                    <div className={selected ? isSelected ? classes.designationBox : classes.designationBoxDisabled : classes.designationBox}>
                                        <Typography className={selected ? isSelected ?  classes.designationText: classes.designationTextDisabled :classes.designationText}>
                                        {designation}
                                        </Typography>
                                    </div>
                                    </>
                                ))}
                            </div>
                        </Grid>
                        )
                })}
            </Grid>
            <CeroPopUp
                primaryPopUp={{
                    open: open,
                    onClose: () => setOpen(false),
                    content: getApproveConfirm(selectedFlow),
                    header: { title: "" },
                }}
                classes={{ dialog: classes.dialog }}
            />
            </Container>
            
            </DashboardLayout>
    )
}
export default ApprovalWorkFlow;