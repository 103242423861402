import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 8,
    margin: theme.spacing(5, 0),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
      '@media (min-width: 1200px)': {
        maxWidth: "none"
      }, 
    },
  ticket: {
    color: theme.palette.text.accent,
  },
  infoContainer: {
    maxWidth: 400,
    display: "flex !important",
    justifyContent: "flex-start",
  },
  title: {
    width: "50%",
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14,
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
  },
  submitted: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  added: {
    color: theme.palette.status.added,
    fontWeight: 500,
    fontSize: 14,
  },
  Inprogress: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    fontSize: 14,
  },
  tableTitle: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: "capitalize",
  },
  tableTopic: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
    alignItems: "center",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
  },
  textField: {
    backgroundColor: theme.palette.background.white,
    '& label.Mui-focused': {
      color: theme.palette.Primary.main,
  },
  '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.Primary.main,
  },
  '& .MuiInput-root:hover::before': {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
  },
  '& .MuiFilledInput-root:hover::before': {
      borderBottomColor: theme.palette.Primary.main,
  },
  '& .MuiFilledInput-underline:after': {
      borderColor: theme.palette.Primary.main,
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderColor: theme.palette.Primary.main,
      },
      '&:hover fieldset': {
          borderColor: theme.palette.Primary.main,
      },
      '&.Mui-focused fieldset': {
          borderWidth: 2,
          borderColor: theme.palette.Primary.main,
      },
  },
  },
  noComments: {
    float: "left",
    fontFamily: "Arial, sans-serif",
    fontSize: 12,
  },
  maxCharacters: {
    float: "right",
    fontSize: 12,
  },
  dialogActions: {
    justifyContent: "flex-start",
    display: "flex",
    paddingLeft: 30,
    paddingBottom: 30,
  },
  textLimit: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto"
  },
  popUp:{
    margin: "auto",
  },
  dialog:{
    width: "40% !important",
    textAlign: "center",
  },
  reviewContainer:{
    display: "flex",
    alignItems: "center",
    color: "red",
  },
  reviewButtons:{
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    width: "18%"
  }
}));

export default useStyles;
