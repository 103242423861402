import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 140px)',
        width: "100%",
        margin: "auto",
        marginTop: theme.spacing(5)
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: 20,
        alignItems: 'center',
        cursor: "pointer"
    },
}));

export default useStyles;