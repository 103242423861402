import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflow: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "none",
  },
  table: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#D0C5CA",
    fontWeight: "bold",
    border: "1px solid #5A404E80",
    textAlign: "left",
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  tableCell: {
    border: "1px solid #5A404E80",
    textAlign: "left",
    margin: "auto",
  },
  noDataWarning: {
    textAlign: "center",
    width: "75%",
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 500,
    fontSize: 14,
  },
  unAudited: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
  },
}));

export default useStyles;
