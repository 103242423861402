import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    paddingBottom: theme.spacing(3),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  detailsContainer: {
    flex: 1,
    minHeight: "calc( 100vh + 30px )",
    overflow: "hidden",
    padding: theme.spacing(4),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    position: "relative",
    margin: 0,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 15,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    minWidth: 140,
    height: 37,
    fontSize: 13,
    margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    width: 140,
    height: 37,
    fontSize: 13,
    margin: theme.spacing(0, 5),
  },
  buttonTeritiary: {
    height: 37,
    fontSize: 13,
    margin: theme.spacing(0, 5),
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.dark,
    },
  },
  editorContainer: {
    height: "calc(100% - 50px)",
    width: "100%",
  },
  saveButton: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  tooltip: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    cursor: "pointer",
    color: theme.palette.text.accent,
  },
  footer: {
    marginTop: theme.spacing(3),
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
    marginTop: theme.spacing(5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  audited: {
    color: theme.palette.Success.main,
    fontWeight: 500,
    fontSize: 14,
  },
  pending: {
    color: theme.palette.status.open,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    fontSize: 14,
  },
  default: {
    color: theme.palette.Secondary.main,
    fontWeight: 500,
    fontSize: 14,
  },
  approved: {
    color: theme.palette.icon.blue,
    fontWeight: 500,
    fontSize: 14,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusText: {
    fontSize: 10,
  },
  button: {
    fontWeight: "600",
    fontSize: "13px",
    textTransform: "capitalize",
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.light,
    },
  },
  headerButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  switchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: theme.palette.status.closed,
      opacity: 1.5,
    },
  },
  inactiveSwitchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "grey",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "grey",
      opacity: 1.5,
    },
  },
}));

export default useStyles;
