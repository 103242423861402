import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: "fit-content",
    },
    actionContainer: {
        display: 'flex',
    },
    button: {
        backgroundColor: theme.palette.Secondary.main,
    },
    editIcon: {
        marginLeft: theme.spacing(3)
    },
    cellContainer: {
        boxShadow: "none",
    },
    tableRow:{
        boxShadow: "inset 0px -1px 0px #5A404E",
    },
}));

export default useStyles;