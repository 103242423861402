import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@mui/material";
import { getAuditorDashboard } from "../../redux/actions";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import StatisticsCard from "../Dashboard/Statistics/StatisticsCard";
import AuditTrends from "./AuditTrends";
import AuditStatusGraph from "./AuditStatusGraph";
import EsgOverviewGraph from "./EsgOverviewGraph";
import TicketStatusGraph from "./TicketStatusGraph";

const AuditorDashboard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const statisticsData = useSelector(
    (state) => state.dashboard.auditorDashboard.data
  );
  const dataKeys = Object.keys(statisticsData);

  useEffect(() => {
    dispatch(getAuditorDashboard());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid container spacing={5} className={classes.cardsContainer}>
          {dataKeys.map((item, index) => {
            return (
              <Grid item className={classes.cardItem} key={index}>
                <StatisticsCard
                  index={index}
                  title={statisticsData[item]?.name}
                  value={statisticsData[item]?.value}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid container rowSpacing={-2} columnSpacing={5}  className={classes.gridContainer}>
                <Grid xs={7.2} item>
                    <AuditTrends/>
                </Grid>
                  <Grid xs={4.8} item>
                    <AuditStatusGraph/>
                </Grid>
                <Grid xs={7.2} item>
                  <TicketStatusGraph/>
                </Grid>
                <Grid xs={4.8} item>
                    <EsgOverviewGraph/>
                </Grid> 
            </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default AuditorDashboard;
