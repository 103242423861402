import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        height: '100%',
        width: '100%',
        maxWidth: '100%',
    },
    buttonAdd: {
        backgroundColor: `${theme.palette.Primary.background}!important`,
        fontWeight: '400!important',
        fontSize: 13,
        minWidth: 100,
        textTransform: "none",
        margin: theme.spacing(5),
        borderRadius: 6
    },
    buttonApply: {
        backgroundColor: `${theme.palette.Primary.background}!important`,
        fontWeight: '400!important',
        fontSize: 13,
        minWidth: 60,
        textTransform: "none",
        borderRadius: 6,
        padding:theme.spacing(1.5,8)
    },
    pagination: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(3),
        alignItems: "center",
        '& .MuiPagination-root':{
            backgroundColor: theme.palette.background.white,
            padding: theme.spacing(1),
        },
        '& .MuiButtonBase-root':{
            fontWeight: 600
        },
        '& .MuiButtonBase-root.Mui-selected':{
            backgroundColor: theme.palette.Primary.background,
            borderRadius: 0
        }
    },
    loading:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiCircularProgress-root':{
            color: theme.palette.Primary.background,
        }
    },
    tableTitle:{
        fontWeight: 700, 
        fontSize: 18,
        textTransform: 'capitalize',
      },
      tableTopic:{
        display:"flex",
        justifyContent:"space-between",
        paddingBottom: 20,
        alignItems: "center"
      },
      buttonSecondary: {
        color: theme.palette.Primary.background,
        borderColor: theme.palette.Primary.background,
        "&:hover": {
          borderColor: theme.palette.Primary.background,
          backgroundColor:theme.palette.Primary.contrastText,
        },
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5),
    },
}));

export default useStyles;