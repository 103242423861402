import * as yup from 'yup';

export const schemeValidation = yup.object({
    facility: yup
        .string('Facility is required')
        .required('Facility is required'),
    year: yup
        .number()
        .required('Year is required'),
    assessmentPeriod: yup
        .string()
        .required('Assessment Period is required'),
    legalActions: yup
        .number()
        .required('Number of legal actions is required'),
    description: yup
        .string()
        .required('Main outcomes of legal actions is required'),
    correctiveAction: yup
        .string()
        .required('Corrective Action is required'),
    
});