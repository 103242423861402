import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    paddingBottom: theme.spacing(1),
    width: "350px !important",
    maxHeight: 425,
    borderRadius: 8,
  },
  scrollContainer: {
    maxHeight: 400,
    width: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  markAll: {
    textAlign: "right",
    fontSize: 12,
    color: theme.palette.text.accent,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  label: {
    fontSize: "12px!important",
    color: theme.palette.text.accent,
    cursor: "pointer",
    margin: 0,
  },
  menuItem: {
    width: "100%",
    minHeight: 50,
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: 'space-between',
    borderBottom: `1px solid #D0D0D0`,
    "&:hover": {
      background: theme.palette.Secondary.background,
    },
  },
  menuItemEnabled: {
    cursor: "pointer",
    fontSize: 12,
    color: theme.palette.text.dark
  },
  menuItemDisabled: {
    cursor: "not-allowed",
  },
  unread: {
    width: 10,
    height: 10,
    padding: theme.spacing(2),
  },
  hidden: {
    visibility: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    textAlign: "center",
    padding: theme.spacing(5),
    borderBottom: `1px solid #D0D0D0`,
    backgroundColor: theme.palette.background.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  empty: {
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    "&.MuiMenu-root": {
      padding: 0,
    },
  },
  timestamp:{
    fontSize: 10,
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(2),
  },
  avatar:{
    color: theme.palette.text.dark,
    marginRight: theme.spacing(4),
    backgroundColor: "#D9D9D9",
    fontWeight: 500
  },
  popover:{
    "& .MuiList-root.MuiMenu-list":{
      padding: "0 !important"
    }
  }
}));

export default useStyles;
