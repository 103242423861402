import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const AntiCorruptionDisclosure = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;

  return (
    <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div">
            Anti Corruption Disclosure
          </Typography>
        </Box>
        <Box className={classes.topContainer}>
          <Grid
            container
            direction={"row"}
            wrap="nowrap"
            justifyContent={"space-between"}
            spacing={12}
            display={"contents"}
          >
            <Grid item container direction={"column"} display={"contents"} xs={10}>
            <Box className={classes.actionBox}>
            <CeroInfoPair 
                title="Status:"
                value={emissionData.status ? emissionData.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: emissionData.status === "In Progress" ? classes.Inprogress : classes[emissionData?.status]
                }}
              /></Box>
            <CeroItemPair
              title="Facility:"
              value={emissionData.facility_name ? emissionData.facility_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Period:"
              value={emissionData.assessment_period ? emissionData.assessment_period : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Year:"
              value={emissionData.year ? emissionData.year : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Is your organization assessed for risk of corruption:"
              value={emissionData.is_organisation_accessed_for_corruption ? emissionData.is_organisation_accessed_for_corruption : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            {emissionData.is_organisation_accessed_for_corruption === "Yes" &&
            <>
            <CeroItemPair
              title="Total number of operations assessed:"
              value={emissionData.number_of_operation ? emissionData.number_of_operation : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Number of incidents reported:"
              value={emissionData.number_of_incident ? emissionData.number_of_incident : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            </>}
            <CeroItemPair
              title="Describe any significant risks related to corruption identified during the assessment:"
              value={emissionData.risk_related_corruption ? emissionData.risk_related_corruption : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Describe any confirmed incidents of corruption within your organization and actions taken to address them:"
              value={emissionData.description_of_corruption ? emissionData.description_of_corruption : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
              <CeroItemPair
              title="Audited on:"
              value={emissionData.audited_by ? emissionData.audited_by : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Audited by:"
              value={emissionData.audited_on
                ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </>
  );
};


export default AntiCorruptionDisclosure;
