import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.white,
    height: "fit-content",
    width: "95%",
    borderRadius: 14,
    padding: theme.spacing(5, 7),
    position: "relative",
  },
  innerContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.darkGray,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.background.darkGray,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    padding: theme.spacing(4, 0),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    marginRight: 15,
    marginTop: theme.spacing(5),
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    backgroundColor: theme.palette.background.white,
    textTransform: "capitalize",
    marginTop: theme.spacing(5),
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  topContainer: {
    maxWidth: 700,
  },
  bottomContainer: {
    maxWidth: 700,
    marginTop: theme.spacing(5),
  },
  previewTitle: {
    marginBottom: theme.spacing(3),
  },
  previewItem: {
    margin: theme.spacing(1, 0),
    fontSize: 15,
  },
  secondResultContainer: {
    paddingLeft: `${theme.spacing(4)} !important`,
    marginLeft: theme.spacing(4),
    borderLeft: `solid 1px ${theme.palette.Other.stroke}`,
  },
  textAreaContainer: {
    height: "fit-content",
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 35,
    paddingTop: 10,
    alignItems: "center",
    cursor: "pointer",
  },
  filterContainer: {
    margin: theme.spacing(5, 0),
  },
  stepper: {
    display: "none",
  },
  tableContainer: {
    maxHeight: 500,
    minHeight: 110,
    marginTop: theme.spacing(4),
  },
  tableHeader: {
    height: 60,
  },
  headerCell: {
    height: 70,
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1),
    color: theme.palette.text.dark,
    backgroundColor: theme.palette.Other.background,
    "&:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "&:last-child": {
      paddingRight: theme.spacing(4),
    },
  },
  tableDataRow: {
    height: 65,
    backgroundColor: theme.palette.Primary.contrastText,
    boxShadow: "inset 0px -1px 0px rgba(80, 80, 80, 0.15)",
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
  selectableTableRow: {
    cursor: "pointer",
  },
  tableSelectedRow: {
    backgroundColor: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: "#FFDED2", // TODO: add to the theme once it is available
    },
  },
  tableBodyCell: {
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(1),
    textTransform: "capitalize",
    boxShadow: "inset 0px -1px 0px rgba(80, 80, 80, 0.15)",
    "&:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "&:last-child": {
      paddingRight: theme.spacing(4),
    },
  },
  progress: {
    color: theme.palette.Warning.main,
  },
  footerCell: {
    textAlign: "center",
    padding: theme.spacing(3),
    boxShadow: "inset 0px -1px 0px rgba(80, 80, 80, 0.15)",
  },
  tableFooter: {
    height: 52,
    backgroundColor: theme.palette.Primary.contrastText,
  },
  noDataWarning: {
    position: "absolute",
    textAlign: "center",
    width: "75%",
  },
  input: {
    width: "50%",
    display: "block",
    height: 30,
    borderRadius: 4,
    border: "1px solid #5A404E",
  },
}));

export default useStyles;
