import { all, put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

export function* addSuperAdmin(action) {
    try {
        const { email, name } = action.payload
        const response = yield call(request, APIEndpoints.ADD_SUPER_ADMIN, {
            method: 'POST',
            payload: { email, name }
        })
        yield put({
            type: ActionTypes.ADD_SUPER_ADMIN_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.ADD_SUPER_ADMIN_FAILURE,
            payload: err.message
        })
    }
}

export function* editSuperAdmin(action) {
    try {
        const { userId, email, name } = action.payload
        const response = yield call(request, APIEndpoints.EDIT_SUPER_ADMIN(userId), {
            method: 'PUT',
            payload: { email, name}
        })
        yield put({
            type: ActionTypes.EDIT_SUPER_ADMIN_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.EDIT_SUPER_ADMIN_FAILURE,
            payload: err.message
        })
    }
}

export function* deleteSuperAdmin(action) {
    try {
        const { userId } = action.payload
        const response = yield call(request, APIEndpoints.DELETE_SUPER_ADMIN(userId), {
            method: 'DELETE',
        })
        yield put({
            type: ActionTypes.DELETE_SUPER_ADMIN_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.DELETE_SUPER_ADMIN_FAILURE,
            payload: err.message
        })
    }
}

export function* getSuperadminList() {
    try {
      const response = yield call(request, APIEndpoints.GET_SUPERADMIN_LIST, {
        method: 'GET',
      })
      yield put({
        type: ActionTypes.GET_SUPERADMIN_LIST_SUCCESS,
        payload: response
      })
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.GET_SUPERADMIN_LIST_FAILURE,
        payload: err.message
      })
    }
  }

  export function* getSuperadmin(action) {
    try {
        const { userId } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUPERADMIN(userId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPERADMIN_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPERADMIN_FAILURE,
            payload: err.message
        })
    }
}

export function* updateCompanySubscription(action) {
    try {
        const { companyId, data } = action.payload
        const response = yield call(request, APIEndpoints.UPDATE_COMPANY_SUBSCRIPTION(companyId), {
            method: 'PUT',
            payload: data 
        })
        yield put({
            type: ActionTypes.UPDATE_COMPANY_SUBSCRIPTION_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.UPDATE_COMPANY_SUBSCRIPTION_FAILURE,
            payload: err.message
        })
    }
}

export function* createBusinessAdmin(action) {
    try {
        const { companyId, data } = action.payload
        const response = yield call(request, APIEndpoints.CREATE_BUSINESS_ADMIN(companyId), {
            method: 'POST',
            payload: data 
        })
        yield put({
            type: ActionTypes.CREATE_BUSINESS_ADMIN_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.CREATE_BUSINESS_ADMIN_FAILURE,
            payload: err.message
        })
    }
}

export function* updateBusinessAdminStatus(action) {
    try {
      const requestData  = action.payload; 
      const response = yield call(request, APIEndpoints.UPDATE_BUSINESS_ADMIN_STATUS(requestData.userId), {
        method: 'PUT',
        payload: {status:requestData.status},
      });
      yield put({
        type: ActionTypes.UPDATE_BUSINESS_ADMIN_STATUS_SUCCESS,
        payload: response,
      });
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.UPDATE_BUSINESS_ADMIN_STATUS_FAILURE,
        payload: err,
      });
    }
  }

  export function* createSubscriptionPlan(action) {
    try {
      const { companyId, subscriptionId,duration,data } = action.payload;
      const response = yield call(
        request,
        APIEndpoints.CREATE_SUBSCRIPTION_PLAN(companyId,subscriptionId,duration ),
        {
          method: "POST",
          payload: data,
        }
      );
      yield put({
        type: ActionTypes.CREATE_SUBSCRIPTION_PLAN_SUCCESS,
        payload: response,
      });
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.CREATE_SUBSCRIPTION_PLAN_FAILURE,
        payload: err.message,
      });
    }
  }

    export function* getSubscriptionDetailsSuperadmin(action) {

      try {
        const response = yield call(
          request,
          APIEndpoints.GET_SUBSCRIPTION_DETAILS_SUPERADMIN,
          {
            method: "GET",
          }
        );
        yield put({
          type: ActionTypes.GET_SUBSCRIPTION_DETAILS_SUPERADMIN_SUCCESS,
          payload: response,
        });
      } catch (err) {
        /* istanbul ignore next */
        yield put({
          type: ActionTypes.GET_SUBSCRIPTION_DETAILS_SUPERADMIN_FAILURE,
          payload: err.message,
        });
      }
    }

      export function* getAllSubscriptionPlansSuperadmin(action) {
      const { companyId } = action.payload;

        try {
          const response = yield call(
            request,
            APIEndpoints.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN(companyId),
            {
              method: "GET",
            }
          );
          yield put({
            type: ActionTypes.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_SUCCESS,
            payload: response,
          });
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_FAILURE,
            payload: err.message,
          });
        }
      }

      export function* getSuperAdminReport(action) {
        try {
          const { companyId, year, country, facility } = action.payload;
          const data = {}
          if (year)
            data.year = year
          if (country)
            data.country = country
          if (facility)
            data.facility = facility
          const response = yield call(
            request,
            APIEndpoints.GET_SUPER_ADMIN_REPORT(companyId),
            {
              method: "POST",
              payload: data,
            }
          );
          yield put({
            type: ActionTypes.GET_SUPER_ADMIN_REPORT_SUCCESS,
            payload: response,
          });
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.GET_SUPER_ADMIN_REPORT_FAILURE,
            payload: err.message,
          });
        }
      }

      export function* getSuperAdminOrganizationCountry(action) {
        try {
          const { companyId } = action.payload;
          const response = yield call(
            request,
            APIEndpoints.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY(companyId),
            {
              method: "GET",
            }
          );
          yield put({
            type: ActionTypes.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_SUCCESS,
            payload: response,
          });
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_FAILURE,
            payload: err.message,
          });
        }
      }

      export function* getSuperadminTickets({ payload }) {
        try {
          const response = yield call(request, APIEndpoints.GET_SUPERADMIN_TICKETS(payload.payload), {
            method: "GET",
          });
          yield put({
            type: ActionTypes.GET_SUPERADMIN_TICKETS_SUCCESS,
            payload: response.tickets,
          });
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.GET_SUPERADMIN_TICKETS_FAILURE,
            payload: err,
          });
        }
      }

      export function* getSuperadminTicketDetails({ payload }) {
        try {
          const apiUrl =  APIEndpoints.GET_SUPERADMIN_TICKET_DETAILS(payload.ticketId);
          const response = yield call(request, apiUrl, {
            method: "GET",
          });
          yield put({
            type: ActionTypes.GET_SUPERADMIN_TICKET_DETAILS_SUCCESS,
            payload: response,
          });
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.GET_SUPERADMIN_TICKET_DETAILS_FAILURE,
            payload: err,
          });
        }
      }
      
      export function* addSuperadminResponse({ payload }) {
        try {
          const apiUrl = APIEndpoints.ADD_SUPERADMIN_RESPONSE(payload.ticketId);
          const response = yield call(request, apiUrl, {
            method: "POST",
            payload: {
              response: payload.response,
            },
          });
          yield put({
            type: ActionTypes.ADD_SUPERADMIN_RESPONSE_SUCCESS,
            payload: response,
          });
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.ADD_SUPERADMIN_RESPONSE_FAILURE,
            payload: err,
          });
        }
      }

      export function* closeSuperadminTicket({ payload }) {
        try {
          const apiUrl =  APIEndpoints.CLOSE_SUPERADMIN_TICKET(payload.ticketId);
          const response = yield call(request, apiUrl, {
            method: "POST",
            payload,
          });
          yield put({
            type: ActionTypes.CLOSE_SUPERADMIN_TICKET_SUCCESS,
            payload: response,
          });
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.CLOSE_SUPERADMIN_TICKET_FAILURE,
            payload: err,
          });
        }
      }

      export function* getAdminReportDetails(action) {
        try {
          const {companyId, countryCode, facilityId, year} = action.payload
          const response = yield call(request, APIEndpoints.GET_ADMIN_REPORT_DETAILS(companyId, countryCode, facilityId, year), {
            method: 'GET',
          })
          yield put({
            type: ActionTypes.GET_ADMIN_REPORT_DETAILS_SUCCESS,
            payload: response
          })
        } catch (err) {
          /* istanbul ignore next */
          yield put({
            type: ActionTypes.GET_ADMIN_REPORT_DETAILS_FAILURE,
            payload: err.message
          })
        }
      }

      export function* generateAnnouncement(action) {
        try {
            const { requestData } = action.payload
            const response = yield call(request, APIEndpoints.GENERATE_ANNOUNCEMENT, {
                method: 'POST',
                payload: requestData
            })
            yield put({
                type: ActionTypes.GENERATE_ANNOUNCEMENT_SUCCESS,
                payload: response
            })
        } catch (err) {
            /* istanbul ignore next */
            yield put({
                type: ActionTypes.GENERATE_ANNOUNCEMENT_FAILURE,
                payload: err.message
            })
        }
    }

    export function* updateAnnouncement(action) {
      try {
          const { requestData } = action.payload
          const response = yield call(request, APIEndpoints.UPDATE_ANNOUNCEMENT(requestData.id), {
              method: 'PUT',
              payload: requestData
          })
          yield put({
              type: ActionTypes.UPDATE_ANNOUNCEMENT_SUCCESS,
              payload: response
          })
      } catch (err) {
          /* istanbul ignore next */
          yield put({
              type: ActionTypes.UPDATE_ANNOUNCEMENT_FAILURE,
              payload: err
          })
      }
  }

  export function* getAnnouncement(action) {
    try {
      const { announcementId } = action.payload
      const response = yield call(request, APIEndpoints.GET_ANNOUNCEMENT(announcementId), {
        method: 'GET',
      })
      yield put({
        type: ActionTypes.GET_ANNOUNCEMENT_SUCCESS,
        payload: response.announcement
      })
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.GET_ANNOUNCEMENT_FAILURE,
        payload: err.message
      })
    }
  }

  export function* listAnnouncement(action) {
    try {
      const response = yield call(request, APIEndpoints.LIST_ANNOUNCEMENT, {
        method: 'GET',
      })
      yield put({
        type: ActionTypes.LIST_ANNOUNCEMENT_SUCCESS,
        payload: response.announcements
      })
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.LIST_ANNOUNCEMENT_FAILURE,
        payload: err.message
      })
    }
  }

export default function* root() {
    yield all([
        takeLatest(ActionTypes.ADD_SUPER_ADMIN, addSuperAdmin),
        takeLatest(ActionTypes.EDIT_SUPER_ADMIN, editSuperAdmin),
        takeLatest(ActionTypes.DELETE_SUPER_ADMIN, deleteSuperAdmin),
        takeLatest(ActionTypes.GET_SUPERADMIN_LIST, getSuperadminList),
        takeLatest(ActionTypes.GET_SUPERADMIN, getSuperadmin),
        takeLatest(ActionTypes.UPDATE_COMPANY_SUBSCRIPTION, updateCompanySubscription),
        takeLatest(ActionTypes.CREATE_BUSINESS_ADMIN, createBusinessAdmin),
        takeLatest(ActionTypes.UPDATE_BUSINESS_ADMIN_STATUS, updateBusinessAdminStatus),
        takeLatest(ActionTypes.CREATE_SUBSCRIPTION_PLAN, createSubscriptionPlan),
        takeLatest(ActionTypes.GET_SUBSCRIPTION_DETAILS_SUPERADMIN, getSubscriptionDetailsSuperadmin),
        takeLatest(ActionTypes.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN, getAllSubscriptionPlansSuperadmin),
        takeLatest(ActionTypes.GET_SUPER_ADMIN_REPORT, getSuperAdminReport),
        takeLatest(ActionTypes.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY, getSuperAdminOrganizationCountry),
        takeLatest(ActionTypes.GET_SUPERADMIN_TICKETS, getSuperadminTickets),
        takeLatest(ActionTypes.GET_SUPERADMIN_TICKET_DETAILS, getSuperadminTicketDetails),
        takeLatest(ActionTypes.ADD_SUPERADMIN_RESPONSE, addSuperadminResponse),
        takeLatest(ActionTypes.CLOSE_SUPERADMIN_TICKET, closeSuperadminTicket),
        takeLatest(ActionTypes.GET_ADMIN_REPORT_DETAILS, getAdminReportDetails),
        takeLatest(ActionTypes.GENERATE_ANNOUNCEMENT, generateAnnouncement),
        takeLatest(ActionTypes.UPDATE_ANNOUNCEMENT, updateAnnouncement),
        takeLatest(ActionTypes.GET_ANNOUNCEMENT, getAnnouncement),
        takeLatest(ActionTypes.LIST_ANNOUNCEMENT, listAnnouncement),
    ])
}
