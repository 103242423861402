import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';

import { STATUS } from "../../../redux/constants";
import { sampleYear, months } from "../../../constants";
import { addTransportationCombutionValidation } from './schema';
import { addTransportationCombustion, getEmissionInputFormat, resetAddCombustionStatus } from '../../../redux/actions';

import CeroButton from '../../../components/CeroButton';
import CeroSelect from '../../../components/CeroSelect';
import CeroInput from '../../../components/CeroInput';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import CeroInfoPair from '../../../components/CeroInfoPair';
import useStyles from "./styles";
import DownloadIcon from '@mui/icons-material/Download';
import EmissionUploadDrawer from '../../EmissionList/EmissionUploadDrawer';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AddTransportationForm = (props) => {
    const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const addEmissionData = useSelector(state => state.emission.addTransportationCombustion);
    const emissionInputs = useSelector(state => state.emission.emissionInputs.data);

    const isCalculateDone = addEmissionData.isCalculateDone;
    const facilitiesData = useSelector(state => state.listings.listFacilities.data);
    const facilitiesList = facilitiesData.map(item => ({ key: item.id, value: item.name }));
    const currentYear = new Date().getFullYear().toString()

    const formik = useFormik({
        initialValues: {
            facility: '',
            description: '',
            category: '',
            emissionFactorDataset: '',
            activityType: '',
            year: currentYear,
            month: '',
            modeOfTransport: '',
            vehicleType: '',
            amount: '',
            unit: '',
        },
        validationSchema: addTransportationCombutionValidation,
        onSubmit: () => { },
    });
    const [isDistanceRemoved, setIsDistanceRemoved] = useState(false);

    const vehicleTypes = (emissionInputs?.vehicle_types || []).filter((item) => !formik.values.modeOfTransport || item.transport_mode_id === formik.values.modeOfTransport)
        .map(item => ({ key: item?.id, value: item?.name }));
    
    const [activityTypes, setActivityTypes] = useState([]);
    const customActivityTypes = (emissionInputs?.custom_activity_types || []).map(item => ({ key: item?.id, value: item?.name, type: item?.unit_type }));
    const units = (emissionInputs?.units || []).filter((item) => !formik.values.activityType || item.type === activityTypes.find(item => item.key === formik.values.activityType)?.type)
        .map(item => ({ key: item?.name, value: item?.description }));
    const customUnits = (emissionInputs?.units || []).filter((item) => item.type === 'distance')
        .map(item => ({ key: item?.name, value: item?.description }));
    const transportModes = (emissionInputs?.transport_modes || []).map(item => ({ key: item?.id, value: item?.name }));
    const emissionFactorDataset = (emissionInputs?.ef_dataset || []).map(item => ({ key: item?.id, value: item?.name }));
    const categories = (emissionInputs?.categories || []).map(item => ({ key: item?.id, value: item?.name }));
    const yearList = sampleYear().map(item => ({ id: item.key, label: item.value }));
    useEffect(() => {
        dispatch(getEmissionInputFormat('transportation'));
        return () => {
            dispatch(resetAddCombustionStatus());
        }
    }, [dispatch]);

    useEffect(() => {
        if (addEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Transportation combustion added successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus());
            onCancel('transportation');
        } else if (addEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(addEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
        }
    }, [addEmissionData, enqueueSnackbar, dispatch, onCancel])

    const onCalculate = () => {
        const requestData = {
            facility_id: formik.values.facility,
            description: formik.values.description,
            category_id: formik.values.category,
            ef_dataset_id: formik.values.emissionFactorDataset,
            activity_type_id: formik.values.activityType,
            year: formik.values.year,
            mode_of_transport_id: formik.values.modeOfTransport,
            vehicle_type_id: emissionFactorDataset.find((item) => item.key === formik.values.emissionFactorDataset)?.value !== 'Custom emission factor'
                ? formik.values.vehicleType : null,
            amount: parseFloat(formik.values.amount),
            month: formik.values.month,
            unit: formik.values.unit,
            save: false,
        }
        dispatch(addTransportationCombustion(requestData))
    };

    const onAddTransportationData = () => {
        const requestData = {
            facility_id: formik.values.facility,
            description: formik.values.description,
            category_id: formik.values.category,
            ef_dataset_id: formik.values.emissionFactorDataset,
            activity_type_id: formik.values.activityType,
            year: formik.values.year,
            mode_of_transport_id: formik.values.modeOfTransport,
            vehicle_type_id: emissionFactorDataset.find((item) => item.key === formik.values.emissionFactorDataset)?.value !== 'Custom emission factor'
                ? formik.values.vehicleType : null,
            amount: parseFloat(formik.values.amount),
            month: formik.values.month,
            unit: formik.values.unit,
            save: true
        }
        sessionStorage.setItem('year', requestData.year)
        dispatch(addTransportationCombustion(requestData));
    };
    const distanceRemovalLookup = {
        "upstream t&d": {
            "uk defra": {
                "car": ["hgv (all diesel) - tonne.km", "hgv refrigerated (all diesel) - tonne.km"],
                "air": ["freight flights - domestic, to/from uk", "freight flights - short-haul, to/from uk", "freight flights - long-haul, to/from uk", "freight flights - international, to/from non-uk"],
                "rail": ["rail - freight train"],
                "ferry": ["sea tanker - crude tanker - average", "sea tanker - products tanker - average", "sea tanker - chemical tanker - average", "sea tanker - lng tanker - average", "sea tanker - lpg tanker - average", "cargo ship - bulk carrier - average", "cargo ship - general cargo - average", "cargo ship - container ship - average",
                            "cargo ship - vehicle transport - average", "cargo ship - roro-ferry - average", "cargo ship - large ropax ferry - average", "cargo ship - refrigerated cargo -  all dwt"]
            },
            "us epa": {
                "air":["aircraft"],
                "rail":["rail"],
                "ferry": ["waterborne craft"],

            }
        }
    }

    useEffect(() => {
        const filteredCategory = (emissionInputs?.categories || []).filter(item=>item.id===formik.values.category)
        const category = filteredCategory.length > 0 ? filteredCategory[0].name.toLowerCase() : null;
        const filteredDataSet = (emissionInputs?.ef_dataset || []).filter(item=>item.id===formik.values.emissionFactorDataset)
        const dataSet = filteredDataSet.length > 0 ? filteredDataSet[0].name.toLowerCase() : null;
        const filteredTransport = (emissionInputs?.transport_modes || []).filter(item=>item.id===formik.values.modeOfTransport)
        const transport = filteredTransport.length > 0 ? filteredTransport[0].name.toLowerCase() : null;
        const filteredVehicleType = (emissionInputs?.vehicle_types || []).filter(item=>item.id===formik.values.vehicleType)
        const vehicleType = filteredVehicleType.length > 0 ? filteredVehicleType[0].name.toLowerCase() : null;
        const datasetExists = distanceRemovalLookup?.[category]?.[dataSet]?.[transport];
        const vehicleIncluded = distanceRemovalLookup?.[category]?.[dataSet]?.[transport]?.includes(vehicleType);
        if ((datasetExists && vehicleIncluded)){
            if (isDistanceRemoved){
                setActivityTypes((emissionInputs?.activity_types || []).filter(item=>item.name.toLowerCase()==='weight distance').map(item => ({ key: item?.id, value: item?.name, type: item?.unit_type })));
                setIsDistanceRemoved(false);
            }
        }
        else{
            // if (!isDistanceRemoved){
            setActivityTypes((emissionInputs?.activity_types|| []).filter(item=>item.name.toLowerCase()!=='weight distance').map(item => ({ key: item?.id, value: item?.name, type: item?.unit_type })));
            setIsDistanceRemoved(true);
            console.log(activityTypes, "types");
            // }
        }
    }, [formik.values.category, formik.values.emissionFactorDataset, formik.values.modeOfTransport, formik.values.vehicleType]);

    const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] = useState(false);
    const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
    };

    const info = () => {
        return(
            <div>
            <Typography variant='body2' style={{ fontSize : 11 , fontWeight: 500}}>Upstream T & D: Upstream transportation & distribution in the supply chain, including the movement
            of raw materials, components or products from suppliers to manufacturers or distributors.<br/>
            Business Travel: The movement of employees for business purposes<br />
            Employee commute: The travel undertaken by employees to and from their workplace</Typography>
            </div>
        )
      } 

    return (
        <>
        <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
        <ArrowBackIosIcon />
        Back
        </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Add Transportation</Typography>
                <Box className={classes.topContainer}>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={4}>
                        <Grid item container direction='column' xs={4}>
                        <CeroSelect
                                required
                                id="facility"
                                name="facility"
                                label="Facility"
                                fullWidth
                                options={facilitiesList}
                                selectedValue={formik.values.facility}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.facility && formik.errors.facility}
                            />
                            <CeroSelect
                                required
                                id="category"
                                name="category"
                                label="Category"
                                fullWidth
                                options={categories}
                                selectedValue={formik.values.category || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.category && formik.errors.category}
                            />
                            <CeroSelect
                                required
                                id="emissionFactorDataset"
                                name="emissionFactorDataset"
                                label="Emission Factor Dataset"
                                fullWidth
                                options={emissionFactorDataset}
                                selectedValue={formik.values.emissionFactorDataset || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.emissionFactorDataset && formik.errors.emissionFactorDataset}
                            />
                            <CeroSelect
                                required
                                id="activityType"
                                name="activityType"
                                label="Activity Type"
                                fullWidth
                                options={emissionFactorDataset.find((item) => item.key === formik.values.emissionFactorDataset)?.value !== 'Custom emission factor'
                                    ? activityTypes : customActivityTypes}
                                selectedValue={formik.values.activityType || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.activityType && formik.errors.activityType}
                            />
                            <CeroAutoComplete
                                id="year"
                                label="Assessment Year"
                                onChange={(e, value) => formik.setFieldValue('year', value.id)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.year && formik.errors.year}
                                options={yearList}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={formik.values.year}
                            />
                            <CeroSelect
                                required
                                id="month"
                                name="month"
                                label="Month"
                                fullWidth
                                options={months}
                                selectedValue={formik.values.month || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.month && formik.errors.month}
                            />
                        </Grid>
                        <Grid item container direction='column' xs={1} style={{ position: "relative"}}>
                            <span className={classes.category}>
                                    <Tooltip title={info()} classes={{ popper: classes.tooltip }}arrow placement="right-start">
                                    <InfoOutlinedIcon className={classes.infoIcon}/></Tooltip>
                            </span>
                            <span className={classes.activityType}>
                                    <Tooltip title="Weight distance refers to the total weight of the vehicle, 
                                    which is the weight of the vehicle alone plus the weight of its load" classes={{ popper: classes.tooltip }}arrow placement="right-start">
                                    <InfoOutlinedIcon className={classes.infoIcon}/></Tooltip>
                            </span>
                        </Grid>
                        <Grid item container direction={'column'} xs={4}>
                            <CeroSelect
                                required
                                id="modeOfTransport"
                                name="modeOfTransport"
                                label="Mode of Transport"
                                fullWidth
                                options={transportModes}
                                selectedValue={formik.values.modeOfTransport || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.modeOfTransport && formik.errors.modeOfTransport}
                            />
                            {emissionFactorDataset.find((item) => item.key === formik.values.emissionFactorDataset)?.value !== 'Custom emission factor' && (
                                <CeroSelect
                                    required
                                    id="vehicleType"
                                    name="vehicleType"
                                    label="Vehicle Type"
                                    fullWidth
                                    options={vehicleTypes}
                                    selectedValue={formik.values.vehicleType || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.vehicleType && formik.errors.vehicleType}
                                    classes={{ input: classes.input,
                                        container: classes.selectContainer,
                                        paperRoot: classes.paperRoot
                                    }}
                                />)}
                            <CeroInput
                                required
                                id="amount"
                                name="amount"
                                label="Amount of Activity Type"
                                value={formik.values.amount || ''}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.amount && formik.errors.amount}
                            />
                            <CeroSelect
                                required
                                id="unit"
                                name="unit"
                                label="Unit"
                                fullWidth
                                options={emissionFactorDataset.find((item) => item.key === formik.values.emissionFactorDataset)?.value !== 'Custom emission factor'
                                    ? units : customUnits}
                                selectedValue={formik.values.unit || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.unit && formik.errors.unit}
                            />
                            <CeroInput
                                required
                                id="description"
                                name="description"
                                label="Description"
                                value={formik.values.description || ''}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                        <Grid item container direction='column' md={6} xs={1}>
                        <span className={classes.info}>
                                <Tooltip title="Radiative forcing: measurement of the imbalance in the Earth's energy budget caused by factors
                                        that after the balance between incoming solar radiation and outgoing infrared radiation" classes={{ popper: classes.tooltip }}arrow placement="right-start">
                                <InfoOutlinedIcon className={classes.infoIcon}/></Tooltip>
                            </span>
                        </Grid>
                    </Grid>
                    <CeroButton
                        buttonText="Calculate"
                        className={classes.buttonPrimary}
                        onClick={onCalculate}
                        disabled={!formik.dirty || !formik.isValid}
                    />
                    <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
                </Box>
                {isCalculateDone && <Box className={classes.bottomContainer}>
                    <Typography variant="h6" component="h6" className={classes.previewTitle}>Emission Preview</Typography>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                        <Grid item container direction='column' xs={12} md={6}>
                            <CeroInfoPair title={<>CO<sub>2</sub></>} value={`${addEmissionData.data.co2} tonnes`} />
                            <CeroInfoPair title={<>CH<sub>4</sub></>} value={`${addEmissionData.data.ch4} tonnes`} />
                            <CeroInfoPair title={<>CO<sub>2</sub>e</>} value={`${addEmissionData.data.co2e} tonnes`} />
                        </Grid>
                        <Grid className={classes.secondResultContainer} item container direction='column' xs={6}>
                            <CeroInfoPair title={<>N<sub>2</sub>O</>} value={`${addEmissionData.data.n2o} tonnes`} />
                            <CeroInfoPair title={<>EF</>} value={<>{addEmissionData.data.ef} kgCO<sub>2</sub>e/unit</>} />
                        </Grid>
                    </Grid>
                </Box>}
            </Box>
            <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Add Data"
                    disabled={!isCalculateDone || addEmissionData.status === STATUS.RUNNING}
                    className={classes.buttonPrimary}
                    onClick={() => onAddTransportationData(formik.values)} />
                                    <div className={classes.buttonContainer}>
                 <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                  </div>
            </Box>
            <EmissionUploadDrawer
            isOpen={isOpenEmissionTypeUpload}
            onClose={onCloseDrawerUpload}
           onUpload={onUpload}
           onValidate={onValidate}
           uploadDisabled={uploadDisabled}
           />
        </Container>
        </>
    )
}

export default AddTransportationForm;
