import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
    marginTop: theme.spacing(5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 230,
    fontSize: 14,
    fontWeight: 400
  },
  header: {
    display:'flex',
    alignItems: 'end',
    flexDirection:"column",
    // justifyContent: 'space-between'
    paddingRight:"10px"
  }
}));

export default useStyles;
