import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.white,
        height: 'fit-content',
        width: '95%',
        borderRadius: 14,
        padding: theme.spacing(5, 7),
        paddingBottom: 0,
        position: 'relative',
    },
    innerContainer: {
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.background.darkGray
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.background.darkGray
        }
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    buttonContainer: {
        padding: theme.spacing(4, 0),
    },
buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 140,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
        backgroundColor: theme.palette.Primary.hoverButton,
        color: theme.palette.Primary.light,
      },
},
buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    "&:hover": {
        borderColor: theme.palette.Primary.background,
        backgroundColor:theme.palette.Secondary.hoverButton,
      },
    marginRight: theme.spacing(5),
},
    topContainer: {
        maxWidth: 700,
    },
    bottomContainer: {
        maxWidth: 700,
        marginTop: theme.spacing(5),
    },
    previewTitle: {
        marginBottom: theme.spacing(3)
    },
    previewItem: {
        margin: theme.spacing(1, 0),
        fontSize: 15
    },
    secondResultContainer:{
        paddingLeft: `${theme.spacing(4)} !important`,
        marginLeft: theme.spacing(4),
        borderLeft: `solid 1px ${theme.palette.Other.stroke}`,
    },
    textAreaContainer: {
        height: 'fit-content',
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: 35,
        paddingTop: 10,
        alignItems: 'center',
        cursor: "pointer"
    },
}));

export default useStyles;