import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6),
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
        borderRadius: theme.spacing(2)
    },
    buttonPrimary: {
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        height: 35,
        borderColor: theme.palette.Primary.background,
        marginRight: 15,
        fontWeight: 400,
        color: theme.palette.background.white,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            borderColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    },
    uploadBox:{
        border:"dotted 2px",
        padding: theme.spacing(3),
        textAlign:"center",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        borderRadius: 8,
        margin: theme.spacing(3,0),
        width: "90%",
        marginBottom: theme.spacing(3),
        whiteSpace: "nowrap",
        fontSize: 14
    },
    uploadedImage: {
        width: '90%',
        height: '35%',
        border:"dotted 2px",
        borderRadius: 8,
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      logoUploadBtn:{
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        height: 35,
        borderColor: theme.palette.Primary.background,
        fontWeight: 400,
        color: theme.palette.background.white,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            borderColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
      }
}));

export default useStyles;
