import * as yup from 'yup';

export const schemeValidation = yup.object({
    facility: yup
        .string('Facility is required')
        .required('Facility is required'),
    year: yup
        .number('Year should be a number')
        .required('Year is required'),
    assessmentPeriod: yup
        .string('Assessment period is required')
        .required('Assessment period is required'),
    salaryPaid: yup
        .number('Salary paid is required')
        .required('Salary paid is required'),
    
});