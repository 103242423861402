import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardFooter: {
    display: "flex",
    justifyContent: "flex-start !important",
    padding: theme.spacing(5, 0, 0, 0),
  },
  button: {
    margin: theme.spacing(0, 2),
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  focusedLabel: {
    "&.MuiInputLabel-root.MuiInputLabel-root.Mui-focused": {
      display: "none",
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.text.dark,
      },
  },
  userBox: {
        width: "-webkit-fill-available",
    '& label.Mui-focused': {
        color: theme.palette.Primary.main,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.Primary.main,
    },
    '& .MuiInput-root:hover::before': {
        borderWidth: 1,
        borderBottomColor: theme.palette.Primary.main,
    },
    '& .MuiFilledInput-root:hover::before': {
        borderBottomColor: theme.palette.Primary.main,
    },
    '& .MuiFilledInput-underline:after': {
        borderColor: theme.palette.Primary.main,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.Other.stroke,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.Primary.main,
        },
        '&.Mui-focused fieldset': {
            borderWidth: 2,
            borderColor: theme.palette.Primary.main,
        },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root':{
        display: "flex"
    }
},
  select: {
    height: "50px",
    marginBottom: theme.spacing(1),
  },
  label: {
    color: "rgba(0, 0, 0, 0.4)",
    letterSpacing: 0,
  },
  logo: {
    [theme.breakpoints.down("xl")]: {
      height: "40px",
    },
  },
  input: {
    marginBottom: theme.spacing(5),
  },
  phoneInput: {
    margin: theme.spacing(5, 0, 5, 0),
  },
}));

export default useStyles;
