import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  filterContainer:{
    display: "flex",
    justifyContent: "flex-end",
    width: 300,
    marginRight:10,
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  },
  filterHeader:{
    marginLeft : 5
  },
  flowMainContainer:{
    height:'auto',
    width:'100%',
    border: '1px solid rgba(140, 33, 72, 1)',
    borderRadius : "4px",
    margin: '5px 0px',
    backgroundColor:"white",
  },
  flowMainContainerSelected:{
    height:'auto',
    width:'100%',
    border: '3px solid rgba(71, 168, 66, 1)',
    borderRadius : "4px",
    margin: '5px 0px',
    backgroundColor:"rgba(245, 253, 245, 1)",
  },
  flowMainContainerDisabled:{
    height:'auto',
    width:'100%',
    border: '1px solid rgba(162, 159, 159, 1)',
    borderRadius : "4px",
    margin: '5px 0px',
    backgroundColor:"rgba(162, 159, 159, 0.1)",
  },
  content:{
    padding: '20px 20px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  flowText:{
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24.2px',
    textAlign: 'left',
  },
  flowTextDisabled:{
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24.2px',
    textAlign: 'left',
    color: 'rgba(162, 159, 159, 1)',
  },
  textAction:{
    display: "flex",
    alignItems: 'center',
  },
  designationBox:{
    width: '205px',
    height: '80px',
    borderRadius: '10px',
    border: '3px solid rgba(140, 33, 72, 1)',
    margin: "0px 15px",
    backgroundColor: "rgba(233, 226, 229, 1)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  designationBoxDisabled:{
    width: '200px',
    height: '80px',
    borderRadius: '10px',
    border: '3px solid rgba(162, 159, 159, 1)',
    margin: "0px 15px",
    backgroundColor: "rgba(162, 159, 159, 0.1)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  designationOuterBox:{
      display:"flex",
      flexDirection:"row",
      alignItems: 'center',
      margin: "20px 1px"
  },
  designationText:{
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: 600,
    color: "rgba(139, 33, 71, 1)",
    textAlign: 'center',
  },
  designationTextDisabled:{
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: 600,
    color: "rgba(162, 159, 159, 1)",
    textAlign: 'center',
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 100,
    height: 40,
    fontSize: 14,
    fontWeight: 400,
    marginRight: theme.spacing(5),

    // margin: '2px'
  },
  saveDiv:{
    display: "flex",
    flexDirection: "row",
    alignContent: 'flex-end',
    justifyContent: 'flex-end'
},
popUp: {
  margin: "auto",
},
buttonContainerReview: {
  display: "flex",
  justifyContent: "space-around",
  paddingTop: 20,
  paddingBottom: 15,
  width: "50%",
  margin: "auto",
},
buttonSecondary: {
  color: theme.palette.Primary.background,
  borderColor: theme.palette.Primary.background,
  textTransform: "capitalize",
  "&:hover": {
    borderColor: theme.palette.Primary.background,
    backgroundColor: theme.palette.Primary.contrastText,
  },
  // margin: '2px'
},
dialog: {
  width: "40% !important",
  textAlign: "center",
},
}));
export default useStyles;
