import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "99%",
    // height: "100%",
    borderRadius: 8,
    margin: theme.spacing(0, 1,0,2),
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 450,
    color: theme.palette.Primary.background,
    padding: theme.spacing(4, 0),
  },

  subItemTitle: {
    fontSize: 20,
    fontWeight: 450,
    padding: theme.spacing(2, 0),
  },

  subItemValue: {
    padding: theme.spacing(2, 0, 4, 0),
    whiteSpace: "pre-line",
    margin: 0,
  },
  letterContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding:theme.spacing(4)
  },
  available: {
    color: "#0088C7",
  },
  active:{

  }
}));

export default useStyles;
