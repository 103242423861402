import * as yup from 'yup';

export const updateDevelopmentTrainingValidation = yup.object({
  numberOfAttendee: yup
    .number('Number of Attendees must be a number')
    .required('Number of Attendees is required')
    .positive('Number of Attendees must be a positive number')
    .integer('Number of Attendees must be an integer'),
  numberOfHours: yup
    .number('Number of Hours must be a number')
    .required('Number of Hours is required')
    .positive('Number of Hours must be a positive number')
    .integer('Number of Hours must be an integer'),
  content: yup.string('Content Covered is required').required('Content Covered is required'),
  objective: yup.string('Objective is required').required('Objective is required'),
  date: yup
    .date('Invalid date')
    .required('Date is required')
    .max(new Date(), 'Date cannot be in the future'), // Optionally, you can set a max date limit
});
