import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "100%",
    maxWidth: "100%"
  },
  filterContainer: {
    margin: theme.spacing(0,0,5,0),
    '& .MuiInputBase-root':{
      background: theme.palette.background.white
    }
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
