import React, { useState } from "react";
import CeroButton from "../../../components/CeroButton";
import useStyles from "./styles";
import { userRoles } from "../../../constants";
import { Box, Typography } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CeroDropdown from "../../../components/CeroDropdown";
import { clearDashboardData, setActiveRole, showSwitchUserModal, userActiveRole } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCookie, setCookie } from "../../../services/cookie";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

function SwitchUserPopUp(props) {
  const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedUserRole,setSelectedUserRole] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const role = getCookie("role");

    const currentUserAvailableRoles = getCookie('availableRoles') && getCookie('availableRoles').split(',') || []

    const availableRoles = userRoles.filter(user=>currentUserAvailableRoles.includes(user.key))

    const getValueForKey = (key) => {
      const role = userRoles.find(role => role.key === key);
      return role ? role.value : null;
    };

  const handleCloseModal = () => {
    dispatch(showSwitchUserModal(false))
  };

  const handleUserRoleChange = (event) => {
    setSelectedUserRole(event.target.value);
  };

  const changeUser = ()=>{
    setCookie("role",selectedUserRole);
    dispatch(showSwitchUserModal(false)); 
    dispatch(userActiveRole([selectedUserRole]))
    dispatch(setActiveRole(selectedUserRole))
    dispatch(clearDashboardData())
    navigate('/')
    enqueueSnackbar(`Role Switched Successfully.Your role is ${getValueForKey(selectedUserRole)} now`, { variant: 'success' });
  }

  return (
    <>
      <Box className={classes.backdrop}></Box>
      <Box className={classes.modal}>
        <Box className={classes.content}>
          <ClearRoundedIcon onClick={handleCloseModal} className={classes.closeIcon} />
          <Typography className={classes.p}>
            You are logged in as <b>{getValueForKey(role)}</b>
          </Typography>
          <Box style={{display:"flex",gap:10,alignItems:"center"}}>
          <Typography className={classes.b}>
          Switch to:{" "}
          </Typography>
          <Box width={400}>
          <CeroDropdown
            id="userRole"
            label="Select Role"
            options={availableRoles}
            onChange={handleUserRoleChange}
            className= {classes.dropdown}
            classes={{input: classes.input, menuItem: classes.menuItem}}
          />
          </Box>
          </Box>
          <Box sx={{marginTop:"20px",paddingBottom:"20px"}}>
            <CeroButton
              className={classes.primaryBtn}
              buttonText="Confirm"
              onClick={changeUser}
            />
            <CeroButton
              className={classes.secondaryBtn}
              onClick={handleCloseModal}
              buttonText="Cancel"
            />
          </Box>
        </Box>
      </Box>

      {props && props.children && (
        <Box
          style={{
            opacity: (props.isOpen) ? 0.4 : 1,
            // pointerEvents: (isOpen) ? "none" : "",
          }}
        >
          {props.children}
        </Box>
      )}
    </>
  )
  
}

export default SwitchUserPopUp;