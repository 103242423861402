import * as yup from 'yup';

export const schemeValidation = yup.object({
  facility: yup
    .string()
    .required('Facility is required'),

  year: yup
    .number()
    .required('Year is required'),

  assessmentPeriod: yup
    .string()
    .required('Assessment Period is required'),

  employeeClassification: yup
    .string()
    .required('Employee Classification is required'),

  noOfEmployees: yup
    .number()
    .required('Number of Employees is required'),

  benefitsProvided: yup
    .array()
    .required('Benefits Provided is required'),

  otherDetails: yup
    .string()
    .when('benefitsProvided', {
      is: (benefitsProvided) => benefitsProvided && benefitsProvided.includes('Others'),
      then: yup.string().required('Details is required when "Others" is selected'),
      otherwise: yup.string(),
    }),
});
