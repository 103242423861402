import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { getEmission, getEmissionInputFormat } from "../../redux/actions";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";
import DashboardLayout from "../../layouts/DashboardLayout";
import EditWasteCombustion from "./EditWasteCombustion";
import EditPurchasedElectricityForm from "./EditPurchasedElectricityForm";
import EditStationaryCombustionForm from "./EditStationaryCombustionForm";
import EditMobileCombustionForm from "./EditMobileCombustionForm";
import EditWaterDistributionForm from "./EditWaterDistributionForm";
import EditWaterConsumptionForm from "./EditWaterConsumptionForm";
import EditTransportationForm from "./EditTransportationForm";
import EditRefrigerantsForm from "./EditRefrigerantsForm";
import EditUploadEmissionForm from "./EditUploadEmissionForm";
import EditEmployeesHealthForm from "./EditEmployeesHealthForm";
import useStyles from "./styles";
import EditDevelopmentAndTrainingForm from "./EditDevelopmentAndTrainingForm";
import EditWorkerSafetyTrainingForm from "./EditWorkerSafetyTrainingForm";
import EditDiscriminationIncidentForm from "./EditDiscriminationIncidentForm";
import EditSupplierScreeningForm from "./EditSupplierScreeningForm";
import EditSupplierHumanRightsTrainingForm from "./EditSupplierHumanRightsTrainingForm";
import EditSocialHumanRightsTrainingForm from "./EditSocialHumanRightsTrainingForm";
import EditLocalCommunitiesForm from "./EditLocalCommunitiesForm";
import EditPoliticalContributionsForm from "./EditPoliticalContributionForm";
import EditAntiCorruptionTrainingForm from "./EditAntiCorruptionTrainingForm";
import EditAntiCompetitiveDisclosureForm from "./EditAntiCompetitiveDisclosureForm";
import EditAntiCorruptionDisclosureForm from "./EditAntiCorruptionDisclosureForm";
import EditSubsidiesFinancialForm from "./EditSubsidiesFinancialForm";
import EditEmployeeTrainingHoursForm from "./EditEmployeeTrainingHours";
import EditPerformanceAndCareerDevelopmentPrograms from "./EditPerformanceAndCareerDevelopmentPrograms";
import EditEconomicImpact from "./EditEconomicImpact";
import EditEmployeeDiversity from "./EditEmployeeDiversity";
import EditEmployeeBenefits from "./EditEmployeeBenefits";
import EditEmployeeAndTurnoverForm from "./EditEmployeeAndTurnover";
import EditBoardDiversity from "./EditBoardDiversity";
import EditManagementDiversity from "./EditManagementDiversity";


const uploadFileEmissions = [
  "employees_turnover",
  "age_based_statistics",
  "gender_based_statistics",
  "board_diversity",
  "management_diversity",
  "tax",
  "direct_economic_impact",
  "indirect_economic_impact"
];

const emissions = [
  "stationary_combustion",
  "mobile_combustion",
  "refrigerants",
  "transportation",
  "water",
  "waste",
  "purchased_electricity",
  "water_consumption",
  "water_discharge",
];

const EditEmissions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const emissionData = useSelector(
    (state) => state.emission.emissionDetails.data
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionDetails.status
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const emissionInputs = useSelector(
    (state) => state.emission.emissionInputs.data
  );
  const userRole = useSelector((state) => state.auth.userInfo.role);
  const isDeleteEnable = [
    rolesEnum.FACILITY_MANAGER,
    rolesEnum.BUSINESS_USER,
    rolesEnum.APPROVER,
  ].includes(userRole);

  const pathNameArr = pathname.split("/");
  const emissionType = pathNameArr[pathNameArr.length - 2];
  const emissionId = pathNameArr[pathNameArr.length - 1];

  const onCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    emissionType && dispatch(getEmissionInputFormat(emissionType));
    emissionType &&
      emissionId &&
      dispatch(getEmission({ emissionType, emissionId }));
  }, [emissionType, emissionId, dispatch]);

  const isLoadingSection1 =
    emissionDataStatus === "running" && _.isEmpty(emissionInputs);
  const isLoadingSection2 = emissionDataStatus === "running";
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (!isLoadingSection1 && !isLoadingSection2) {
      setIsDataLoaded(true);
    }
  }, [isLoadingSection1, isLoadingSection2]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {isLoadingSection1 || isLoadingSection2 ? (
          <div>Loading</div>
        ) : emissionDataStatus === "error" ? (
          <div>We couldn't process your request. Please try again later.</div>
        ) : (
          <>
            {emissionType === "purchased_electricity" && (
              <EditPurchasedElectricityForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionInputs={emissionInputs}
                emissionData={emissionData}
              />
            )}

            {emissionType === "stationary_combustion" && (
              <EditStationaryCombustionForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionData={emissionData}
              />
            )}
            {emissionType === "mobile_combustion" && (
              <EditMobileCombustionForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionInputs={emissionInputs}
                emissionData={emissionData}
              />
            )}
            {emissionType === "water_discharge" && (
              <EditWaterDistributionForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionInputs={emissionInputs}
                emissionData={emissionData}
              />
            )}
            {emissionType === "water_consumption" && (
              <EditWaterConsumptionForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionInputs={emissionInputs}
                emissionData={emissionData}
              />
            )}
            {emissionType === "refrigerants" && (
              <EditRefrigerantsForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionInputs={emissionInputs}
                emissionData={emissionData}
              />
            )}
            {emissionType === "transportation" && (
              <EditTransportationForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionInputs={emissionInputs}
                emissionData={emissionData}
              />
            )}
            {emissionType === "waste" && (
              <EditWasteCombustion
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionInputs={emissionInputs}
                emissionData={emissionData}
              />
            )}
            {emissionType === "employee_health_safety_incident_record" && (
              <EditEmployeesHealthForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                facilitiesData={facilitiesData}
                emissionData={emissionData}
              />
            )}
          </>
        )}
        {isDataLoaded && isLoadingSection2 ? null : emissionDataStatus ===
          "error" ? (
          <div>We couldn't process your request. Please try again later.</div>
        ) : (
          <>
            {emissionType === "development_training" && (
              <EditDevelopmentAndTrainingForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                // emissionId={emissionId}
                // facilitiesData={facilitiesData}
                emissionData={emissionData}
              />
            )}
            {emissionType === "worker_safety_training_procedures" && (
              <EditWorkerSafetyTrainingForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "discrimination_incident_record" && (
              <EditDiscriminationIncidentForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "supplier_screening_using_enviormental_social_criteria" && (
              <EditSupplierScreeningForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "suppliers_human_rights" && (
              <EditSupplierHumanRightsTrainingForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "social_engagement_human_rights_training" && (
              <EditSocialHumanRightsTrainingForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "local_communities" && (
              <EditLocalCommunitiesForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "political_contributions" && (
              <EditPoliticalContributionsForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "anti_corruption_disclosure" && (
              <EditAntiCorruptionDisclosureForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "anti_corruption_training" && (
              <EditAntiCorruptionTrainingForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "anti_competitive_disclosure" && (
              <EditAntiCompetitiveDisclosureForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "subsidies_financial_assistance" && (
              <EditSubsidiesFinancialForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "training_hours_per_employee" && (
              <EditEmployeeTrainingHoursForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "performance_and_career_development_programs" && (
              <EditPerformanceAndCareerDevelopmentPrograms
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "economic_impact" && (
              <EditEconomicImpact
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "employees_diversity_and_ratio_of_basic_salary" && (
              <EditEmployeeDiversity
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "employees_benefit" && (
              <EditEmployeeBenefits
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "employees_hire_and_turnover" && (
              <EditEmployeeAndTurnoverForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {/* {emissionType === "board_diversity" && (
              <EditBoardDiversity
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )}
            {emissionType === "management_diversity" && (
              <EditManagementDiversity
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionData={emissionData}
              />
            )} */}
            {uploadFileEmissions.includes(emissionType) && (
              <EditUploadEmissionForm
                isDeleteEnable={isDeleteEnable}
                onCancel={onCancel}
                emissionId={emissionId}
                emissionType={emissionType}
                emissionData={emissionData}
              />
            )}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default EditEmissions;
