import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: "100%",
    maxWidth: "100%",
  },
  gridContainer: {
    height: "calc(100% - 64px)",
    marginBottom: theme.spacing(5),
  },
  companyCard: {
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
    marginBottom: theme.spacing(4),
  },
  companyName: {
    fontWeight: 600,
    padding: theme.spacing(2),
  },
}));

export default useStyles;