import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  settingsMenuContainer: {
      marginLeft: 20
  },
  avatar:{
    background: theme.palette.background.white,
    color:theme.palette.text.dark
  },
  text:{
      color:theme.palette.Primary.main
  }
}));

export default useStyles;
