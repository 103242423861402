import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0,
        '@media (min-width: 1200px)': {
            maxWidth: "none"
          },
    },
    topContainer: {
        marginBottom: theme.spacing(8),
        backgroundColor: theme.palette.background.white,
        width: '100%',
        borderRadius: 8,
        padding: theme.spacing(5, 7),
        position: 'relative',
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    },
    previewTitle: {
        marginBottom: theme.spacing(2)
    },
    previewItem: {
        margin: theme.spacing(1, 0),
        fontSize: 15
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: theme.spacing(8),
        paddingTop: 10,
        alignItems: 'center',
        cursor: "pointer",
    },
    loader: {
        width: "100%",
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
}));

export default useStyles;