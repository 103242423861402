import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, Paper, Typography, IconButton } from "@mui/material";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  registerables as registerablesJS,
} from "chart.js";
import _ from "lodash";
import {
  getInitiativeTracking,
} from "../../../redux/actions";
import { emissionTypes } from "../../../constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
ChartJS.register(...registerablesJS);
ChartJS.register(ArcElement, Tooltip, Legend);

const InitiativeTrackingGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie('role');
    const companyId = getCookie("companyId");
  const navigate = useNavigate();
  const initiativeTracking = useSelector(
    (state) => state.dashboard.getInitiativeTracking.data
  );

   const initiativeTrackingStatus = useSelector(
     (state) => state.dashboard.getInitiativeTracking.status
   );
  const [currentInitiativeIndex, setCurrentInitiativeIndex] = useState(0);
  useEffect(() => {
    dispatch(getInitiativeTracking(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  const handleNextInitiative = (e) => {
    e.stopPropagation()
    setCurrentInitiativeIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousInitiative = (e) => {
    e.stopPropagation()
    setCurrentInitiativeIndex((prevIndex) => prevIndex - 1);
  };

  const handleMoreHorizClick = () => {
    if (initiativeTracking?.response?.length > 1) {
      handleNextInitiative();
    }
  };

    const handleContainerClick = () => {
      navigate("/dashboard/details/initiatives");
    };

  return (
    <Paper className={classes.container} onClick={handleContainerClick}>
      <div className={classes.header}>
        <Typography className={classes.graphTitle}>
          Initiative Tracking
        </Typography>
      </div>
      {initiativeTracking?.response &&
      initiativeTracking.response.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                className={classes.arrowButton}
                onClick={handlePreviousInitiative}
                disabled={currentInitiativeIndex === 0}
                style={{
                  color: currentInitiativeIndex === 0 ? "grey" : "#8C2148",
                }}
              >
                <KeyboardArrowLeftIcon sx={{ fontSize: "xxx-large" }} />
              </IconButton>
              <Typography
                variant="subtitle1"
                className={classes.initiativeName}
              >
                Initiative {currentInitiativeIndex + 1}:
              </Typography>
            </div>
            <IconButton
              onClick={handleNextInitiative}
              disabled={
                currentInitiativeIndex ===
                initiativeTracking.response.length - 1
              }
              className={classes.arrowButton}
              style={{
                color:
                  currentInitiativeIndex ===
                  initiativeTracking.response.length - 1
                    ? "grey"
                    : "#8C2148",
              }}
            >
              <KeyboardArrowRightIcon sx={{ fontSize: "xxx-large" }} />
            </IconButton>
          </div>
          <div className={classes.cardContent}>
            <Typography className={classes.initiativeName}>
              {
                initiativeTracking?.response[currentInitiativeIndex]
                  ?.initiative_name
              }
            </Typography>
            {initiativeTracking?.response[currentInitiativeIndex]?.tasks
              .length > 0 ? (
              initiativeTracking?.response[currentInitiativeIndex]?.tasks.map(
                (value, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" className={classes.text}>
                        {value.task}
                      </Typography>
                      <Typography variant="body1" className={classes.text}>
                        {value.progress}%
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={Math.min(value.progress, 100)}
                      className={classes.linearProgress}
                      classes={{
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary,
                      }}
                    />
                  </div>
                )
              )
            ) : (
              <Typography variant="body1" className={classes.text}>
                No Task Added
              </Typography>
            )}
          </div>
          {initiativeTracking.response.length > 1 && (
            <div className={classes.moreHorizon}>
              {initiativeTracking.response.map((_, index) => (
                <FiberManualRecordIcon
                  key={index}
                  className={classes.moreButton}
                  style={{
                    color:
                      index === currentInitiativeIndex ? "#8C2148" : "grey",
                  }}
                  onClick={() => setCurrentInitiativeIndex(index)}
                />
              ))}
            </div>
          )}
        </>
      ) : initiativeTrackingStatus === "running" ? (
        <Typography variant="body1">Loading...</Typography>
      ) : (
        <Typography variant="body1">No Data Available</Typography>
      )}
    </Paper>
  );
};

export default InitiativeTrackingGraph;
