import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        height: '100%',
        width: '100%',
    },
    loadingDiv: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "10vw",
        height: "10vh",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.Primary.hoverButton,
        color: theme.palette.Primary.light,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default useStyles;