import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopicBasedApproval } from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import EmployeeHealthApproval from "./EmployeeHealthApproval";
import WorkerSafetyApproval from "./WorkerSafetyApproval";
import EmployeeBenefitsApproval from "./EmployeeBenefitsApproval";
import EmployeeDiversityApproval from "./EmployeeDiversityApproval";
import DiscriminationIncidentApproval from "./DiscriminationIncidentApproval";
import TrainingHoursApproval from "./TrainingHoursApproval";
import PerformanceAndCareerApproval from "./PerformanceAndCareerApproval";
import SupplierScreeningApproval from "./SupplierScreeningApproval";
import SupplierHumanRightsApproval from "./SupplierHumanRightsApproval";
import LocalCommunitiesApproval from "./LocalCommunitiesApproval";
import SocialHumanRightsApproval from "./SocialHumanRightsApproval";
import PoliticalContributionsApproval from "./PoliticalContributionsApproval";
import BoardDiversityApproval from "./BoardDiversity";
import ManagementDiversityApproval from "./MangementDiversity";
import AntiCorruptionDisclosureApproval from "./AntiCorruptionDisclosure";
import AntiCompatitiveDisclosureApproval from "./AntiCompatitiveDisclosure";
import EconomicImpactApproval from "./EconomicImpact";
import SubsidiesAndFinancialAssistanceApproval from "./SubsidiesAndFinancialAssistance";
import AntiCorruptionTrainingApproval from "./AntiCorruptionTraining";

const ApprovalDetailsCard = (props) => {
  const { approvalData, emissionDataStatus, topicType } = props;
  
  return (
    <>
      {emissionDataStatus === STATUS.SUCCESS && (
        <>
          {topicType === "employees_benefit" && (
            <EmployeeBenefitsApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "employee_health_safety_incident_record" && (
            <EmployeeHealthApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "worker_safety_training_procedures" && (
            <WorkerSafetyApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "employees_diversity_and_ratio_of_basic_salary" && (
            <EmployeeDiversityApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "discrimination_incident_record" && (
            <DiscriminationIncidentApproval
              emissionData={approvalData}
            />
          )}
            {topicType === "training_hours_per_employee" && (
            <TrainingHoursApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "performance_and_career_development_programs" && (
            <PerformanceAndCareerApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "supplier_screening_using_enviormental_social_criteria" && (
            <SupplierScreeningApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "suppliers_human_rights" && (
            <SupplierHumanRightsApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "local_communities" && (
            <LocalCommunitiesApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "social_engagement_human_rights_training" && (
            <SocialHumanRightsApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "political_contributions" && (
            <PoliticalContributionsApproval
            emissionData={approvalData}
            />
          )}
          {topicType === "board_diversity" && (
            <BoardDiversityApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "management_diversity" && (
            <ManagementDiversityApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "anti_corruption_disclosure" && (
            <AntiCorruptionDisclosureApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "anti_competitive_disclosure" && (
            <AntiCompatitiveDisclosureApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "economic_impact" && (
            <EconomicImpactApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "subsidies_financial_assistance" && (
            <SubsidiesAndFinancialAssistanceApproval
              emissionData={approvalData}
            />
          )}
          {topicType === "anti_corruption_training" && (
            <AntiCorruptionTrainingApproval
              emissionData={approvalData}
            />
          )}
        </>
      )}
    </>
  );
};

export default ApprovalDetailsCard;
