import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Typography,CircularProgress, Chip, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import CeroTable from '../../../components/CeroTable';
import DashboardLayout from '../../../layouts/DashboardLayout';
import Header from './Header';
import {
  getCompanyAuditDetails,
  approveCompanyAudit,
  approveCompanyAuditReset,
  getYearlyAuditStatusSummaryOverview
} from '../../../redux/actions';
import { STATUS } from '../../../redux/constants';
import CreateTicketDrawer from '../../common/CreateTicketDrawer';
import useStyles from './styles';
import Status from './Status';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { generateAuditedReport } from '../../../redux/sagas/reports';
import CeroStatusTableAccordion from '../../../components/StatusTableAccordion';
import { emissionTypeData, reportEmissionTypeData } from '../../../constants';
import ReportAccordion from '../../Reports/ReportDetails/ReportAccordian';

const AuditDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { company, id, year } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const auditDetailsState = useSelector((state) => state.company.auditDetails);
  const approveAuditStatus = useSelector(
    (state) => state.company.approveAudit.status
  );
  const auditStatusSummaryState = useSelector(
    (state) => state.audit.auditStatusYearlySummaryOverview
  );
  const auditOverallStatus = useSelector(
    (state) => state.company.auditDetails.data
  );

  const auditDetails = auditDetailsState.data;
  const summaryData = _.groupBy(auditDetails.response, 'topic');
  const topicKeys = _.keys(summaryData);
  const combustionSummaryColumns = [
    {
      columnKey: 'status',
      columnId: 'status',
      columnHeader: 'Status',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'sector',
      columnId: 'sector',
      columnHeader: 'Topics',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_co2',
      columnId: 'total_co2',
      columnHeader: 'CO2 (tonnes)',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_ch4',
      columnId: 'total_ch4',
      columnHeader: 'CH4 (tonnes)',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_n2o',
      columnId: 'total_n2o',
      columnHeader: 'N2O (tonnes)',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_co2e',
      columnId: 'total_co2e',
      columnHeader: 'CO2e (tonnes)',
      classes: { column: classes.cellContainer }
    }
  ];

  const waterSummaryColumns = [
    {
      columnKey: 'status',
      columnId: 'status',
      columnHeader: 'Status',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'sector',
      columnId: 'sector',
      columnHeader: 'Topics',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'usage',
      columnId: 'usage',
      columnHeader: 'Usage',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_co2e',
      columnId: 'total_co2e',
      columnHeader: 'CO2e (tonnes)',
      classes: { column: classes.cellContainer }
    }
  ];

  const onSelectData = (row) => {
    const emisionType = row.type.replace(/ /g, '_')
    navigate(`emissions/${emisionType}/?year=${year||auditDetails.year}&company=${company}&redirect_table=${true}&period=${auditStatusSummaryState.data.period}&country=${auditStatusSummaryState.data.country}`);
    // navigate(`/emissions/${emisionType}/?year=${year||auditDetails.year}&company=${company}&redirect_table=${true}&period=${auditStatusSummaryState.data.period}&country=${auditStatusSummaryState.data.country}`);
  };

  useEffect(() => {
    company &&
      (id || year) &&
      dispatch(getCompanyAuditDetails(company, id, year));
    dispatch(getYearlyAuditStatusSummaryOverview(company, id, year));
  }, [dispatch, company, id, year]);

  useEffect(() => {
    if (auditDetailsState.status === STATUS.ERROR) {
      dispatch(approveCompanyAuditReset());
      enqueueSnackbar(auditDetailsState.message || "We couldn't process your request. Please try again later.", {
        variant: 'error'
      });
    }
  }, [auditDetailsState, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (approveAuditStatus === STATUS.SUCCESS) {
      dispatch(approveCompanyAuditReset());
      dispatch(getCompanyAuditDetails(company, id, year));
      dispatch(getYearlyAuditStatusSummaryOverview(company, id, year));
      enqueueSnackbar('Successfully approved audit', {
        variant: 'success'
      });
    } else if (approveAuditStatus === STATUS.ERROR) {
      dispatch(approveCompanyAuditReset());
      enqueueSnackbar("We couldn't process your request. Please try again later.", { variant: 'error' });
    }
  }, [approveAuditStatus, dispatch, enqueueSnackbar, id, company, year]);

  const onApplyFilter = (year) => {
    navigate(`/companies/${company}/year/${year}`);
  };

  const onApproveAudit = (comment) => {
    dispatch(approveCompanyAudit(company, auditDetails.audit_status_id, comment));
  };

  const getWaterData = (data) =>
    data.map((item) => ({
      ...item,
      usage: `${item.usage} ${item.unit}`
    }));

    const navigateBack = () => {
      navigate(-1);
    }; 
  
  // Nikhil change for new table
  let statusDict = {};
let statusClassesDict = {};

// const parentData = {}
// const superParentData = {}
const getData = (columnData) => {
  if (columnData.type === 'development_training') {
    return `Attended: ${columnData.attended}, Hours: ${columnData.hours}`;
  } else if (
    columnData.type === 'employee_health_safety_incident_record' ||
    columnData.type === 'discrimination_incident_record'
  ) {
    return `Affected: ${columnData.affected}`;
  } else if (
    columnData.type === 'worker_safety_training_procedures' ||
    columnData.type === 'operational_human_rights_training' ||
    columnData.type === 'anti_corruption_training' ||
    columnData.type === 'social_engagement_human_rights_training'
  ) {
    return `Attended: ${columnData.attended}`;
  } else if (
    columnData.type === 'political_contributions' ||
    columnData.type === 'subsidies_financial_assistance'
  ) {
    return `Amount: ${columnData.amount}`;
  } else return `Records: ${columnData.records}`;
};
const generalColumnConfig = [
  {
    columnKey: 'status',
    columnId: 'status',
    columnHeader: 'Status',
    classes: { column: classes.cellContainer }
  },
  {
    columnKey: 'sector',
    columnId: 'sector',
    columnHeader: 'Topics',
    classes: { column: classes.generalCellContainer }
  },
  {
    columnKey: 'detailsColumn',
    columnId: 'detailsColumn',
    columnHeader: 'Details',
    classes: { column: classes.cellContainer }
  }
];
const getStatusColor = (item) => {
  if (item === 'approved') {
  return <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
  } else if (item === 'submitted') {
      return  <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
  } else if (item === 'audited') {
      return  <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
  } else if (item === 'review') {
      return <Tooltip title="Review" arrow><Chip className={classes.signalReview}/></Tooltip>
  }else if (item === 'added') {
      return <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
  }else if (item === 'assigned') {
    return <Tooltip title="assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
  }else if (item === 'Awaiting approval') {
    return <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
  }else if (item === 'In Progress') {
    return <Tooltip title="In Progress" arrow><Chip className={classes.signalPending}/></Tooltip>
  } else if (item === 'verified') {
    return  <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
  }else {
      return <Tooltip title={item} arrow><Chip className={classes.signalAdded}/>{item}</Tooltip>
  } 
  }
  
  const parentData = {};
    const superParentData = {};
    reportEmissionTypeData.map((item) => {
      item.subItems.map((innerItem) => {
        innerItem.subItems.map((subInnerItem) => {
          parentData[subInnerItem.id] = innerItem.id;
          superParentData[subInnerItem.id] = item.id;
        });
      });
    });
    let aggreatedData = {};
    auditDetails.response?.map((item) => {
      let pushedData = item;
      let pushedcolumn = combustionSummaryColumns;
      if (parentData[item.type] === "energy_and_materials") {
        pushedData = {
          ...item,
          type: item.type ? item.type.replace(/_/g, ' ') : "",
          status: getStatusColor(item.status)
        };
        pushedcolumn = combustionSummaryColumns;
      } else if (
        parentData[item.type] === "waste_water" 
      ) {
        pushedData = {
          ...item,
          type: item.type ? item.type.replace(/_/g, ' ') : "",
          usage: `${item.usage}`,
          status: getStatusColor(item.status)
        };
        pushedcolumn = waterSummaryColumns;
      }
      if (parentData[item.type] in aggreatedData) {
        aggreatedData[parentData[item.type]]["data"] =
          aggreatedData[parentData[item.type]]["data"].concat(pushedData);
      } else {
        aggreatedData[parentData[item.type]] = {};
        aggreatedData[parentData[item.type]]["data"] = [pushedData];
      }
      if (!("columns" in aggreatedData[parentData[item.type]]))
        aggreatedData[parentData[item.type]]["columns"] = pushedcolumn;
    });
    const getStatusObject =  Object.keys(statusDict).map((item,index)=>{
      const uniqueStatuses = [...new Set(statusDict[item])];
      if (uniqueStatuses.length > 1) 
        statusClassesDict[item] = 'in progress'
      else{
        statusClassesDict[item] = uniqueStatuses[0]
        // if (statusDict[item].includes('in progress'))
        //   statusClassesDict[item] = 'in progress'
        // else if (statusDict[item].includes('added'))
        //   statusClassesDict[item] = 'added'
        // else if (statusDict[item].includes('pending approval'))
        //   statusClassesDict[item] = 'pending approval'
        // else if (statusDict[item].includes('approved'))
        //   statusClassesDict[item] = 'approved'
        //   else if (statusDict[item].includes('review'))
        //   statusClassesDict[item] = 'review'
        // else if (statusDict[item].includes('audited'))
        //   statusClassesDict[item] = 'audited'
      }
    }
    )
    const filteredData = auditDetails.response?.filter(function (item) {
      return !("sector" in item);
    });
    const isaudited = auditDetails.status === 'audited';
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.headerContainer}>
        <div className={classes.backContainer} onClick={navigateBack}>
          <ArrowBackIosIcon />
          Back
       </div>
       <div>
       <Header
          auditId={id}
          onApplyFilter={onApplyFilter}
          selectedYear={year || auditDetails.year}
          isApproveAuditVisible={auditDetails.status === 'pending'}
          onApproveAudit={onApproveAudit}
          onRaiseAuditTicket={() => setIsDrawerOpen(true)}
          isLoading={auditDetailsState === STATUS.RUNNING}
          companyId={company}
          data={auditDetails.response}
          country={auditStatusSummaryState?.data.country}
          performAudit={auditDetailsState?.data.perform_audit}
          isAudited={auditDetails.status === "audited"}
        />
        </div>
        </div>
        <Typography className={classes.title}>{`Audit Summary ${
          auditDetails?.year || ''
        }`}</Typography>
        {auditDetailsState.status !== STATUS.SUCCESS ? (
          <Box className={classes.loader}>
            <Typography variant='h7' component='span'>
              {auditDetails === STATUS.RUNNING
                ? <CircularProgress/>
                : auditDetails === STATUS.ERROR
                ? "We couldn't process your request. Please try again later."
                : ''}
            </Typography>
          </Box>
        ) : (
          ''
        )}
        {auditStatusSummaryState.status === STATUS.SUCCESS && (
          <Status 
          details={auditStatusSummaryState.data} 
          auditOverallStatus={auditOverallStatus.overall_status}
          />
        )}
        {/* {auditDetailsState.status === STATUS.SUCCESS && (<CeroStatusTableAccordion approvalData={auditDetails.response} aggreatedData={aggreatedData} emissionData={filteredData}
            emissionDataStatus={auditStatusSummaryState.status}
            isaudited={isaudited} statusClassesDict={statusClassesDict} onSelectData={onSelectData} company={company} isAuditor={true}/>)} */}
          {auditDetailsState.status === STATUS.SUCCESS && (<ReportAccordion
              approvalData={auditDetails?.emissions}
              aggreatedData={aggreatedData}
              emissionData={filteredData}
              emissionDataStatus={auditDetailsState.status}
              onSelectData={onSelectData}
              isaudited={isaudited}
              reportSignals={auditDetails.category}
              topicSignals={auditDetails.sub_category_list}
              subTopicSignals={auditDetails.topic_type_status}
            /> )}
        {/* {auditDetailsState.status === STATUS.SUCCESS && topicKeys[1] && (
          <Container className={classes.tableContainer}>
            <Typography
              variant='h7'
              component='div'
              className={classes.tableHeaderContainer}
            >
              {topicKeys[1]}
            </Typography>
            <CeroTable
              columns={waterSummaryColumns}
              data={getWaterData(summaryData[topicKeys[1]] || [])}
              hasMore={false}
              loading={false}
              onSelectRow={onSelectData}
            />
          </Container>
        )} */}
        <CreateTicketDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          scope='audit'
          scopeId={id}
          companyId={company}
        />
      </Container>
    </DashboardLayout>
  );
};

export default AuditDetails;
