import * as yup from "yup";

export const addOffsetValidation = yup.object({
  program_name: yup
    .string("Program name is required")
    .required("Program name is required"),
  country: yup.string("Country is required").required("Country is required"),
  start_year: yup
    .number("Start year is required"),
  start_month: yup
    .string("Start month is required"),
  program_type: yup
    .string("Program type is required")
    .required("Program type  is required"),
  program: yup.string("Program is required").required("Program is required"),
  installed_capacity: yup
    .number("Installed capacity is required")
    .required("Installed capacity is required"),
  number_of_panels: yup
    .number("Number of panels is required")
    .required("Number of panels  is required"),
  description: yup
    .string("Description about project is required")
    .required("Description about project is required"),
  facility: yup.string("Facility is required").required("Facility is required"),
});
