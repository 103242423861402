import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    cardItem: {
        width: "20%",
        margin: 0,
    },
    cardsContainer:{
        marginBottom: theme.spacing(3),
        display: "flex",
        justifyContent: "space-between"
    }
}));

export default useStyles;
