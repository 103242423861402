import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const EmployeeBenefit = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;

  return (
    <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div">
            Employee Benefits
          </Typography>
        </Box>
        <Box className={classes.topContainer}>
        <Grid
            container
            direction={"row"}
            wrap="nowrap"
            justifyContent={"space-between"}
            display={"contents"}
            spacing={12}
          >
            <Grid item container direction={"column"} display={"contents"} xs={10}>
            <Box className={classes.actionBox}>
            <CeroInfoPair 
                title="Status:"
                value={emissionData.status ? emissionData.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: emissionData.status === "In Progress" ? classes.Inprogress : classes[emissionData?.status]
                }}
              /></Box>
            <CeroItemPair
              title="Facility:"
              value={emissionData.facility_name ? emissionData.facility_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Period:"
              value={emissionData.assessment_period ? emissionData.assessment_period : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Year:"
              value={emissionData.year ? emissionData.year : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair 
                    title="Number of Employees:" 
                    value={emissionData.number_of_employees ? emissionData.number_of_employees : "NA"} 
                    classes={{ container: classes.infoContainer, title: classes.diversityTitle, value: classes.value }} 
                />
                {emissionData.benefit_provided  &&
                <CeroItemPair 
                title="Benefits Provided:" 
                value={emissionData.benefit_provided ? emissionData.benefit_provided?.map((benefit, index) => (
                    <li className={classes.list} key={index}>{index + 1}. {benefit}</li>)) : "NA"} 
                classes={{ container: classes.infoContainer, title: classes.diversityTitle, value: classes.value }} 
              />}
              {emissionData?.benefit_provided?.includes('Others') && 
              <CeroItemPair 
              title="Details:" 
              value={emissionData.details ? emissionData.details : "NA"} 
              classes={{ container: classes.infoContainer, title: classes.diversityTitle, value: classes.value }} 
              />}
              <CeroItemPair
              title="Audited on:"
              value={emissionData.audited_by ? emissionData.audited_by : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Audited by:"
              value={emissionData.audited_on
                ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default EmployeeBenefit;
