import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    '@media (max-width: 1200px)': {
      maxWidth: "none"
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    background: theme.palette.background.white,
    marginTop: theme.spacing(5),
    marginLeft: 0,
    marginRight: 0
  },
  cellContainer: {
    boxShadow: "none",
  },
  generalCellContainer: {
    width: '50%',
    boxShadow: "none",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeaderContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    background: theme.palette.Secondary.background,
    fontWeight: 500,
  },
  audited: {
    color: "green",
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  approved: {
    color: "orange",
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  review: {
    color: theme.palette.status.review,
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  added:{
    color: "red",
    fontWeight:500,
    display: "flex",
    alignItems: "center"
  },
  signal:{
    backgroundColor: "green",
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalAdded:{
    backgroundColor: theme.palette.status.added,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalApproved:{
    backgroundColor: theme.palette.status.approved,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalReview:{
    backgroundColor: theme.palette.status.review,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)   
  },
  signalPending:{
    backgroundColor: theme.palette.status.pendingApproval,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  },
  signalAudited:{
    backgroundColor: theme.palette.status.audited,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  },
  signalVerified:{
    backgroundColor: theme.palette.status.verified,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  }
}));

export default useStyles;
