import * as yup from 'yup';

export const superAdminValidation = yup.object({
    email: yup
        .string()
        .required('Email is required')
        .email('Invalid email format')
        .matches(/@ceroed\.com$/, 'Email must be from @ceroed.com domain')
        .max(150, 'Email must not exceed 150 characters'),
    name: yup
        .string()
        .required('Name is required'),
});