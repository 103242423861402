import * as yup from 'yup';

export const schemeValidation = yup.object({
  facility: yup
    .string()
    .required('Facility is required'),

  assessmentPeriod: yup
    .string()
    .required('Assessment period is required'),

  year: yup
    .number()
    .required('Year is required'),

  environmentalCriteria: yup
    .array()
    .required('Environmental criteria is required'),

  otherDetails: yup
    .string()
    .when('environmentalCriteria', {
      is: (environmentalCriteria) => environmentalCriteria && environmentalCriteria.includes('Others'),
      then: yup.string().required('Details is required when "Others" is selected'),
      otherwise: yup.string(),
    }),

  environmentalSuppliers: yup
    .number()
    .required('Suppliers screened is required'),

  describeEnvironmentalImpact: yup
    .string()
    .required('Negative environmental impact is required'),

  actionsToNegative: yup
    .string()
    .required('Action taken is required'),

  socialCriteria: yup
    .array()
    .required('Social criteria is required'),

  otherDetailsSocial: yup
    .string()
    .when('socialCriteria', {
      is: (socialCriteria) => socialCriteria && socialCriteria.includes('Others'),
      then: yup.string().required('Details is required when "Others" is selected'),
      otherwise: yup.string(),
    }),

  socialSuppliers: yup
    .number()
    .required('Suppliers screened is required'),

  describeSocialImpact: yup
    .string()
    .required('Negative social impact is required'),

  actionsToNegativeImpact: yup
    .string()
    .required('Action taken is required'),

});
