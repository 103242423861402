
import { Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import CeroButton from "../../../components/CeroButton";
import ceroed from "../../../assets/ceroed.png";
import useStyles from "./styles";

const TradeSignupResponse = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate('/trade/login');
  };


  return (
    <Container
      container
      justifyContent="center"
      alignContent="center"
      className={classes.signup}
    >
    <Box className={classes.successBox}>
    <img src={ceroed} alt="ceroED Logo" className={classes.logo} />
    <Typography>Thanks for signing up.</Typography>
    <Typography>We're glad you're here</Typography>
    <CeroButton
      variant="contained"
      onClick={navigateBack}
      buttonText={"Login to Get Started!"}
      className={classes.loginButton}
    />
  </Box>
  </Container>
  );
};

export default TradeSignupResponse;
