import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import _, { values } from "lodash";

import CeroInput from "../../../components/CeroInput";
import CeroSelect from "../../../components/CeroSelect";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroSideSheetDrawer from "../../../components/CeroSideSheetDrawer";
import { months, sampleYear } from "../../../constants";
import { emissionDownloadValidation } from "./schema";
import {
    listFramework,
    listTopic,
    resetReportStatus,
    createReport,
} from "../../../redux/actions";
import { APIEndpoints, STATUS } from "../../../redux/constants";

import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import { useLocation } from "react-router-dom";
import CeroButton from "../../../components/CeroButton";
import CeroFileUpload from "../../../components/CeroFileUpload";

const EmissionUploadDrawer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { pathname } = useLocation();
    const emissionType = pathname.substring(pathname.lastIndexOf('/') + 1)
    const { reportData, isOpen, onUpload, onValidate, uploadDisabled } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [save, setSave] = useState();
    const [isValidationInProgress, setIsValidationInProgress] = useState(false);
    const [isValidationCompleted, setIsValidationCompleted] = useState(false);
    const frameworkList = useSelector(
        (state) => state.listings.frameworkList.data
    );
    const [getImageFile, setImageFile] = useState(null);
    const topicList = useSelector((state) => state.listings.topicList.data);

    const facilitiesData = useSelector(
        (state) => state.listings.listFacilities.data
      );

    const yearList = sampleYear().map((item) => ({
        id: item.key,
        label: item.value,
    }));
//     const monthList = months.map((month) => ({
//         id: month.key,
//         label: month.value,
//    }));
    const facilitiesList = facilitiesData.map((item) => ({
        id: item.id,
        label: item.name,
   }));


    const formik = useFormik({
        initialValues: {
            // name: reportData ? reportData.name : "",
            year: reportData ? dayjs(reportData.created_ts).get("year") : "",
            // month: reportData ? dayjs(reportData.created_ts).get("month") : "",
            facility: reportData ? reportData.facility_Id : "",
            file: reportData ? reportData.file : ""

        },
        validationSchema: emissionDownloadValidation,
        enableReinitialize: true,
        onSubmit: () => { },
    });

    const onSubmitReportData = (event) => {
        event.preventDefault();
        const { file, facility, year } = formik.values;
        // const selectedMonthId = monthList.find(monthObj => monthObj.label === month)?.id || "";
        const selectedFacility = facilitiesList.find(facilityObj => facilityObj.id === facility);
        const selectedFacilityId = selectedFacility ? selectedFacility.id : "";
        const fileName = `${emissionType}-${selectedFacilityId}-${year}-1.xlsx`;
        const newFile = new File([getImageFile], fileName, {
            type: getImageFile.type
        });
        setIsUploading(false); 
        setIsValidationInProgress(true);
        onUpload( emissionType, save, () => {
            setIsValidationInProgress(false); 
            setIsValidationCompleted(false);
          });
        formik.resetForm();
    };
    const onClose = useCallback(() => {
        formik.resetForm();
        props.onClose();
        setIsValidationCompleted(false);
    }, [formik, props]);


    const value = (value) => {
        setSave(value)
    }

    const onFileUpload = ( target ) =>{
        formik.setFieldValue('file', target[0]);
        setImageFile(target[0]);
        setIsFileSelected(true);
    };

    const onValidateData = () => {
        const { file, facility, year } = formik.values;
        // const selectedMonthId = monthList.find(monthObj => monthObj.label === month)?.id || "";
        const selectedFacility = facilitiesList.find(facilityObj => facilityObj.id === facility);
        const selectedFacilityId = selectedFacility ? selectedFacility.id : "";
        const fileName = `${emissionType}-${selectedFacilityId}-${year}-1.xlsx`;
        const newFile = new File([getImageFile], fileName, {
            type: getImageFile.type
        });
        setIsValidationInProgress(false); 
        setIsUploading(true);
        onValidate(newFile, save, () => {
            setIsUploading(false); 
            setIsValidationCompleted(true);
          });
    }

    const isFileValid = Boolean(getImageFile); 

    const isUploadButtonDisabled = !uploadDisabled || isUploading || !isFileValid || !formik.dirty || !isFileSelected || !formik.isValid;

    const getPrimaryPaymentDrawer = () => {
        const selectedFacility = facilitiesList.find(facility => facility.id === formik.values.facility);
        return (
            <Box className={classes.mainContainer}>
                <CeroFileUpload  onFileUpload={onFileUpload}/>
                <CeroAutoComplete
                    required
                    id="facility"
                    name="facility"
                    label="Facility"
                    fullWidth
                    options={facilitiesList}
                    value={selectedFacility}
                    onChange={(e, value) => {
                        formik.setFieldValue("facility", value.id);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.facility && formik.errors.facility}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                 <CeroAutoComplete
                    required
                    id="year"
                    name="year"
                    label="Year"
                    fullWidth
                    options={yearList}
                    value={formik.values.year}
                    onChange={(e, value) => {
                        formik.setFieldValue("year", value.id);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.year && formik.errors.year}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                 {/* <CeroAutoComplete
                    required
                    id="month"
                    name="month"
                    label="Month"
                    fullWidth
                    options={monthList}
                    value={formik.values.month}
                    onChange={(e, value) => {
                        formik.setFieldValue("month", value.label);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.month && formik.errors.month}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />  */}
                <CeroButton
                    buttonText="Validate"
                    className={classes.buttonPrimary}
                    onClick={onValidateData} 
                    disabled={isValidationCompleted}
                    />
                    {isUploading && (
                    <CircularProgress  size={25} thickness={8} className={classes.progressLoader}/>
                )}
            </Box>
        );
    };

    return (
        <CeroSideSheetDrawer
            primaryDrawer={{
                drawerOpen: isOpen,
                onClose: onClose,
                content: (
                    <>
                        {getPrimaryPaymentDrawer()}
                        {isValidationInProgress && (
                            <div className={classes.progressLoader}>
                                <CircularProgress size={25} thickness={8} style={{ marginTop: 10}} className={classes.progressLoader} />
                            </div>
                        )}
                    </>
                ),
                header: { title: "Upload File" },
                footer: {
                    primaryBtnTitle: isUploading ? "Upload" : "Upload",
                    secondaryBtnTitle: "Cancel",
                    primaryBtnAction: onSubmitReportData,
                    secondaryBtnAction: onClose,
                    disablePrimaryBtn: isUploadButtonDisabled,
                },
                classes: {
                    drawerContainer: classes.drawerContainer,
                    contentArea: classes.drawerContentArea,
                },
            }}
        />
    );
};

export default EmissionUploadDrawer;
