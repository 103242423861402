import { Container, Grid } from "@mui/material";
import dayjs from "dayjs";

import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "./styles";

const Status = ({ reportDetails }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid className={classes.statusContainer}>
        <Grid>
          <CeroInfoPair
            title="Year"
            value={reportDetails.year}
            classes={{ container: classes.infoContainer }}
          />
          {reportDetails.period && (
            <CeroInfoPair
              title="Period"
              value={reportDetails.period}
              classes={{ container: classes.infoContainer }}
            />
          )}
          {reportDetails.facilities?.length > 0 && (
            <CeroInfoPair
              title="Facilities"
              value={reportDetails.facilities}
              classes={{ container: classes.infoContainer }}
            />
          )}
        </Grid>
        <Grid>
          <CeroInfoPair
            title="Created By"
            value={reportDetails.created_by_name}
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Created on"
            value={
              reportDetails.created_ts &&
              dayjs(reportDetails.created_ts).format("DD MMM YYYY HH:mm")
            }
            classes={{ container: classes.infoContainer }}
          />
          <CeroInfoPair
            title="Last updated on"
            value={
              reportDetails.last_updated_ts &&
              dayjs(reportDetails.last_updated_ts).format("DD MMM YYYY HH:mm")
            }
            classes={{ container: classes.infoContainer }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Status;
