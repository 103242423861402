import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "93%"
    },
    mainContainer: {
        paddingTop: theme.spacing(5)
    },
    tabs:{
        "& .MuiTab-root":{
        color: theme.palette.text.dark,
        textTransform: "capitalize",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.login.loginButton,
            height: 4
          },
         "& .MuiTab-root.Mui-selected":{
            color: `${theme.palette.login.loginButton} !important`,
            fontWeight: 700
         }

    },
    button: {
        backgroundColor: `${theme.palette.Primary.background}!important`,
        fontWeight: '400!important',
        fontSize: 13,
        width: '100%',
        textTransform: "none",
        borderRadius: 5
    },
    card:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(5),
        marginRight: theme.spacing(5),
        borderRadius: 8
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: theme.spacing(10),
        paddingLeft: theme.spacing(5),
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        cursor: "pointer",
    },
    detailsCard:{
        padding: theme.spacing(3),
        borderRadius: 8
    },
    editTitle:{
        backgroundColor: theme.palette.Other.background,
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        fontWeight: 600,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
    },
    name:{
        fontWeight: 700,
        paddingBottom: theme.spacing(5),
    }
}));

export default useStyles;