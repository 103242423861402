import React, { useEffect } from "react";
import { Container, Grid, Card, CardContent, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import { getSuperadminDashboard } from "../../redux/actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
import CompaniesIcon from "../../assets/icons/CompaniesIcon.png";
import TotalUsersIcon from "../../assets/icons/TotalUsersIcon.png";
import ActiveUserIcon from "../../assets/icons/ActiveUserIcon.png";

const SuperAdminDashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSuperadminDashboard(props.filter));
  }, [dispatch, props.filter]);

  const statisticsData = useSelector(
    (state) => state.dashboard.getAdminDashboard.data
  );

  const displayNames = {
    total_user_count: "Total Users",
    company_count: "Companies",
    active_user_count: "Active Users",
  };

  const iconMapping = {
    total_user_count: <img src={TotalUsersIcon} alt="Total Users" />,
    company_count: <img src={CompaniesIcon} alt="Companies"  style={{ width: 50, height: 50 }} />,
    active_user_count: <img src={ActiveUserIcon} alt="Active Users" />
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid container>
        {Object.entries(statisticsData).map(([key, value], index) => (
            <Grid item xs={12} sm={6} md={4} key={key} className={classes.card}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    {iconMapping[key]}
                    <Typography variant="h6">{displayNames[key] || key}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h4" className={classes.count}>{value}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default SuperAdminDashboard;
