import { Container, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import CeroInfoPair from '../../../../components/CeroInfoPair'
import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getYearlyAuditStatusSummaryOverview } from '../../../../redux/actions'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

const StatusCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const company = queryParams.get("company");
    const { id } = useParams();
    const tableTopics = [
      "employees_hire_and_turnover",
      "employees_diversity_and_ratio_of_basic_salary",
      "board_diversity",
    ]

    const data = (props?.emissionType === "management_diversity" || props?.emissionType === "board_diversity") 
    ? props?.emissionData?.based_on_gender?.[0] 
    : (tableTopics.includes(props?.emissionType) 
      ? props?.emissionData?.gender_distribution?.[0] 
      : props?.emissionData?.[0]);

    const auditData = useSelector(
      (state) => state.audit.auditStatusYearlySummaryOverview.data
    );

    useEffect(() => {
      if (id && company) {
        dispatch(getYearlyAuditStatusSummaryOverview(company, id));
      }
    }, [dispatch, company, id]);

    return (
    <Container className={classes.container}>
    <Grid container spacing={2}>
    <Grid item xs={6}>
      <CeroInfoPair
        title="Status"
        value={data?.status === "pending_approval" ? "Pending Approval" : data?.status}
        classes={{ container: classes.infoContainer, value: data?.status === "In Progress" || data?.status === "pending_approval" ? classes.Inprogress : classes[data?.status] , title:classes.title}}
      />
      <CeroInfoPair
        title="Audited By"
        value={auditData?.audited_by_name ? auditData?.audited_by_name : data?.audited_by_name? data?.audited_by_name : "NA"}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
      <CeroInfoPair
        title="Audited On"
        value={auditData?.audited_on ? dayjs(auditData?.audited_on).format('DD/MM/YYYY') : data?.audited_on ? dayjs(data?.audited_on).format('DD/MM/YYYY'): "NA"}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
      <CeroInfoPair
        title="Approval Cycle"
        value={auditData?.approval_cycle_type?.split("_").join(" ")}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
      <CeroInfoPair
        title="Audit Cycle"
        value={auditData?.audit_cycle ? auditData?.audit_cycle : "NA"}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
      </Grid>
    <Grid item xs={6}>
      <CeroInfoPair
        title="Period"
        value={data?.assessment_period}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
      <CeroInfoPair
        title="Assessment Year"
        value={data?.year}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
      <CeroInfoPair
        title="Facility"
        value={data?.facility_name}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
      <CeroInfoPair
        title="No of tickets"
        value={data?.ticket_count || auditData?.total_tickets}
        classes={{ container: classes.infoContainer, title: classes.title }}
      />
    </Grid>
    </Grid>
    </Container>
  )
}

export default StatusCard
