import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import { accountSchema } from "./schema";
import useStyles from "./styles";
import { getCookie } from "../../../../services/cookie";
import { getTradeAccount, resetSignup, updateTradeAccount } from "../../../../redux/actions";
import { STATUS } from "../../../../redux/constants";
import CeroInput from "../../../../components/CeroInput";
import CeroButton from "../../../../components/CeroButton";
import CeroSelect from "../../../../components/CeroSelect";

const TradeAccountSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const role = getCookie("role");

  const accountData = useSelector((state) => state.trade.getTradeAccount);

  const updateAccountData = useSelector(
    (state) => state.trade.updateTradeAccount
  );

  useEffect(() => {
    dispatch(getTradeAccount());
  }, [dispatch]);

  useEffect(() => {
    if (updateAccountData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Account details updated", { variant: "success" });
        dispatch(resetSignup());
      dispatch(getTradeAccount());
    } else if (updateAccountData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateAccountData.message?.message ||
          updateAccountData.message ||
          "Someting went wrong",
        { variant: "error" }
      );
        dispatch(resetSignup());
    }
  }, [updateAccountData, dispatch, enqueueSnackbar]);

  const accountForm = useFormik({
    initialValues: {
      username: accountData.data.user_details?.name || "",
      company: accountData.data.user_details?.company || "",
      email: accountData.data.user_details?.email || "",
      phone: accountData.data.user_details?.phone || "",
      userType: accountData.data.user_details?.user_type || "",
    },
    enableReinitialize: true,
    validationSchema: accountSchema,
    onSubmit: (values) => {},
  });

  const handleUpdate = () => {
    const requestData = {
      user_name: accountForm.values.username,
      company_name: accountForm.values.company,
      company_email: accountForm.values.email,
      company_phone: accountForm.values.phone,
      user_type: accountForm.values.userType,
    };
    dispatch(updateTradeAccount(requestData));
  };

  const userTypes = [
    { key: "buyer", value: "Buyer" },
    { key: "seller", value: "Seller" },
  ];

  return (
    <Paper className={classes.container}>
      <CeroInput
        required
        fullWidth
        id="username"
        label="User Name"
        autoFocus
        value={accountForm.values.username}
        onChange={accountForm.handleChange}
        onBlur={accountForm.handleBlur}
        error={accountForm.errors.username}
      />
      <CeroInput
        required
        fullWidth
        id="company"
        label="Company Name"
        autoFocus
        value={accountForm.values.company}
        onChange={accountForm.handleChange}
        onBlur={accountForm.handleBlur}
        error={accountForm.errors.company}
      />
      <CeroInput
        required
        fullWidth
        id="email"
        label="Email"
        autoFocus
        value={accountForm.values.email}
        onChange={accountForm.handleChange}
        onBlur={accountForm.handleBlur}
        error={accountForm.errors.email}
      />
      <CeroInput
        required
        fullWidth
        id="phone"
        label="Phone Number"
        autoFocus
        value={accountForm.values.phone}
        onChange={accountForm.handleChange}
        onBlur={accountForm.handleBlur}
        error={accountForm.errors.phone}
      />
      <CeroSelect
        required
        id="userType"
        name="userType"
        label="User Type"
        fullWidth
        options={userTypes}
        selectedValue={accountForm.values.userType}
        onChange={accountForm.handleChange}
        onBlur={accountForm.handleBlur}
        error={accountForm.touched.userType && accountForm.errors.userType}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <div>
          <CeroButton
            buttonText="Save"
            onClick={handleUpdate}
            disabled={!accountForm.dirty || !accountForm.isValid}
            className={classes.buttonPrimary}
          />
          <CeroButton
            variant="outlined"
            buttonText="Cancel"
            onClick={() => navigate(-1)}
            className={classes.buttonSecondary}
          />
        </div>
      </Stack>
    </Paper>
  );
};

export default TradeAccountSettings;
