import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';

export const userState = {
  userList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  addUser: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  userDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  editUser: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  editUserStakeholder: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  proxyLoginAccessSuperadmin: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  deleteUser: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  setEmailConfirmed: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getAssignedStakeholders: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  stakeholderStatus: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getReadOnlyUserAssignedReports: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  revokeGrantReportAccess: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getUserLogs: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  lockUnlockUser: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getRecentLogs: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSubscriptionDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  showSwitchUserModal: false,
};

const userActions = {
  users: handleActions(
    {
      [ActionTypes.LIST_USERS]: (state, { payload }) =>
        immutable(state, {
          userList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_USERS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          userList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_USERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          userList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ADD_USER]: (state, { payload }) =>
        immutable(state, {
          addUser: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_USER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addUser: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_USER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addUser: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_USER_DETAILS]: (state, { payload }) =>
        immutable(state, {
          userDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_USER_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          userDetails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_USER_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          userDetails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.EDIT_USER]: (state, { payload }) =>
        immutable(state, {
          editUser: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.EDIT_USER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          editUser: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.EDIT_USER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editUser: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.EDIT_USER_STAKEHOLDER]: (state, { payload }) =>
        immutable(state, {
          editUserStakeholder: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.EDIT_USER_STAKEHOLDER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          editUserStakeholder: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.EDIT_USER_STAKEHOLDER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editUserStakeholder: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.EDIT_USER_STAKEHOLDER]: (state, { payload }) =>
        immutable(state, {
          editUserStakeholder: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.EDIT_USER_STAKEHOLDER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          editUserStakeholder: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.EDIT_USER_STAKEHOLDER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editUserStakeholder: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.DELETE_USER]: (state, { payload }) =>
        immutable(state, {
          deleteUser: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.DELETE_USER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          deleteUser: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.DELETE_USER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deleteUser: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_USER_STATUS]: (state, { payload }) =>
        immutable(state, {
          addUser: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          userDetails: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          editUser: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          editUserStakeholder: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          editUserStakeholder: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          deleteUser: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          lockUnlockUser: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          }
        }),

      [ActionTypes.SET_EMAIL_CONFIRMED]: (state, { payload }) =>
        immutable(state, {
          setEmailConfirmed: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.SET_EMAIL_CONFIRMED_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          setEmailConfirmed: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.SET_EMAIL_CONFIRMED_FAILURE]: (state, { payload }) =>
        immutable(state, {
          setEmailConfirmed: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.CLEAR_EMAIL_CONFIRMED_FAILURE]: (state, { payload }) =>
        immutable(state, {
          setEmailConfirmed: {
            status: { $set: STATUS.IDLE },
          },
        }),
      [ActionTypes.GET_ASSIGNED_STAKEHOLDERS]: (state, { payload }) =>
        immutable(state, {
          getAssignedStakeholders: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ASSIGNED_STAKEHOLDERS_SUCSESS]: (state, { payload }) =>
        immutable(state, {
          getAssignedStakeholders: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ASSIGNED_STAKEHOLDERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getAssignedStakeholders: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.UPDATE_STAKEHOLDER_STATUS]: (state, { payload }) =>
        immutable(state, {
          stakeholderStatus: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_STAKEHOLDER_STATUS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          stakeholderStatus: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.UPDATE_STAKEHOLDER_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          stakeholderStatus: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_READ_ONLY_USER_ASSIGNED_REPORTS]: (state, { payload }) =>
        immutable(state, {
          getReadOnlyUserAssignedReports: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_READ_ONLY_USER_ASSIGNED_REPORTS_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getReadOnlyUserAssignedReports: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_READ_ONLY_USER_ASSIGNED_REPORTS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getReadOnlyUserAssignedReports: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.REVOKE_GRANT_REPORT_ACCESS]: (state, { payload }) =>
        immutable(state, {
          revokeGrantReportAccess: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.REVOKE_GRANT_REPORT_ACCESS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          revokeGrantReportAccess: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.REVOKE_GRANT_REPORT_ACCESS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          revokeGrantReportAccess: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.RESET_REVOKE_GRANT_REPORT_ACCESS]: (state, { payload }) =>
        immutable(state, {
          revokeGrantReportAccess: {
            status: { $set: STATUS.IDLE },
          },
        }),
      [ActionTypes.REVOKE_GRANT_REPORT_ACCESS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          revokeGrantReportAccess: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.REVOKE_GRANT_REPORT_ACCESS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          revokeGrantReportAccess: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.RESET_REVOKE_GRANT_REPORT_ACCESS]: (state, { payload }) =>
        immutable(state, {
          revokeGrantReportAccess: {
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.PROXY_LOGIN_ACCESS_SUPERADMIN]: (state, { payload }) =>
        immutable(state, {
          proxyLoginAccessSuperadmin: {
            status: { $set: STATUS.IDLE },
          },
        }),
      [ActionTypes.PROXY_LOGIN_ACCESS_SUPERADMIN_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          proxyLoginAccessSuperadmin: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.PROXY_LOGIN_ACCESS_SUPERADMIN_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          proxyLoginAccessSuperadmin: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.RESET_PROXY_LOGIN_ACCESS_SUPERADMIN]: (state, { payload }) =>
        immutable(state, {
          proxyLoginAccessSuperadmin: {
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.RESET_UPDATE_STAKEHOLDER_STATUS]: (state, { payload }) =>
        immutable(state, { $set: userState }),

      [ActionTypes.SHOW_SWITCH_USER_MODAL]: (state, { payload }) =>
        immutable(state, {
          showSwitchUserModal: { $set: payload.showSwitchUserModal },
        }),

      [ActionTypes.GET_USER_LOGS]: (state, { payload }) =>
        immutable(state, {
          getUserLogs: {
            status: { $set: STATUS.RUNNING },
            data: { $set: [] },
          },
        }),
      [ActionTypes.GET_USER_LOGS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getUserLogs: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_USER_LOGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getUserLogs: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.LOCK_UNLOCK_USER]: (state, { payload }) =>
        immutable(state, {
          lockUnlockUser: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LOCK_UNLOCK_USER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          lockUnlockUser: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LOCK_UNLOCK_USER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          lockUnlockUser: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_RECENT_LOGS]: (state, { payload }) =>
        immutable(state, {
          getRecentLogs: {
            status: { $set: STATUS.RUNNING },
            data: { $set: {} },
          },
        }),
      [ActionTypes.GET_RECENT_LOGS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getRecentLogs: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_RECENT_LOGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getRecentLogs: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.RESET_LIST_USERS]: (state, { payload }) =>
          immutable(state, { $set: userState }),

      [ActionTypes.GET_SUBSCRIPTION_DETAILS]: (state, { payload }) =>
        immutable(state, {
          getSubscriptionDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUBSCRIPTION_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getSubscriptionDetails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUBSCRIPTION_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getSubscriptionDetails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
    },
    userState
  ),
};

export default userActions;
