import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    height: "95%",
    borderRadius: 8,
    marginBottom: 20,
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  innerContainer: {
    padding: theme.spacing(4),
    width: "50%",
    minHeight: 360,
    borderRadius: 8,
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  title: {
    width: "270px",
    marginRight: theme.spacing(5),
    fontWeight: "350 !important",
  },
  value: {
    fontWeight: 350,
    whiteSpace: "break-spaces",
  },
  infoContainer: {
    display: "flex !important",
    alignItems: "start",
    justifyContent: "space-between",
    gap: "46px",
    padding: theme.spacing(2.5, 3),
  },
  filterText: {
    whiteSpace: "nowrap",
    fontWeight: 500,
    paddingRight: theme.spacing(3),
  },
  textValue: {
    border: "1px solid #E0E0E0",
    width: 120,
    fontSize: "14px",
    borderRadius: 5,
    padding: theme.spacing(2),
  },
  subheaderContainer: {
    display: "flex",
    alignItems: "center",
    gap: 30,
    margin: theme.spacing(3, 0),
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
}));

export default useStyles;
