import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: theme.spacing(4),
    padding: "16px 16px 0 0",
    width: "97%",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 20,
    borderRadius: 8,
    marginLeft: 12,
    marginRight: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "-webkit-fill-available",
    },
  },
  containerNoPadding : {
    padding: "0 !important",
  },
  table: {
    width: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "-webkit-fill-available",
    },
  },
  infoArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoContainer: {
    width: 400,
    "& p:first-child" : {
      width : '40%'
    },
    "& p:last-child" : {
      width : '60%'
    }
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    marginLeft: 18,
  },
  name: {
    fontWeight: 700,
    fontSize: "30px",
  },
  infoSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  tabContainer: {
    marginTop: theme.spacing(4),
  },
  tabs: {
    "& .MuiTab-root": {
      textTransform: "capitalize",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.login.loginButton,
    },
    "& .MuiTab-root.Mui-selected": {
      color: `${theme.palette.login.loginButton} !important`,
    },
  },
}));

export default useStyles;