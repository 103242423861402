import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import CeroButton from "../../components/CeroButton";
import CeroInput from "../../components/CeroInput";
import CeroTable from "../../components/CeroTable";
import CeroPopUp from "../../components/CeroPopUp";
import { answerSurveyQuestions, getSurveyQuestions, resetCreateQuestionsStatus } from "../../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { STATUS } from "../../redux/constants";
import { useSnackbar } from "notistack";

const MaterialitySurvey = () => {
  const classes = useStyles();
  const { answerId } = useParams();
  const [selectedValues, setSelectedValues] = useState({});
  const [tableData, setTableData] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const materialityQuestionsList = useSelector((state) => {
    const data = state.materiality.getSurveyQuestions.data.esg_questions_list;
    return Array.isArray(data) ? data : [];
  });

  const surveyStatus = useSelector((state) => state.materiality.getSurveyQuestions);
  const companyName = useSelector((state) => state.materiality.getSurveyQuestions.data.company_name);
  const formStatus = useSelector((state) => state.materiality.answerSurveyQuestions);

  const tableColums = [
    {
      columnKey: "topic",
      columnId: "topic",
      columnHeader: "Topic",
      width: "35vw",
    },
    {
      columnKey: "relevance",
      columnId: "relevance",
      columnHeader: `The relevance of the topic for you as a ${companyName} stakeholder`,
      width: "35vw",
    },
    {
      columnKey: "impact",
      columnId: "impact",
        columnHeader: `Impact/Influence of ${companyName} on this topic`,
      width: "35vw",
    },
  ];

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        if (value === "Other") {
          setTextAnswers((prevAnswers) => {
            const newAnswers = { ...prevAnswers };
            delete newAnswers[`other-${questionId}`];
            return newAnswers;
          });
        }
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        if (value === "Other") {
          setTextAnswers((prevAnswers) => ({
            ...prevAnswers,
            [`other-${questionId}`]: "", 
          }));
        }
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };
  
  const handleSingleSelect = (questionId, selectedOption) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [questionId]: selectedOption,
    }));

    if (selectedOption === "Other") {
      setTextAnswers((prevAnswers) => ({
        ...prevAnswers,
        [`other-${questionId}`]: "",
      }));
    }
  };

  const handleTableInputChange = (questionId, option, field, value) => {
    setTableData((prevData) => ({
      ...prevData,
      [questionId]: {
        ...prevData[questionId],
        [option]: {
          ...prevData[questionId]?.[option],
          [field]: value,
        },
      },
    }));
  };
  
  const handleTextInputChange = (questionId, value) => {
    setTextAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };
  
  useEffect(() => {
    if (answerId) {
      dispatch(getSurveyQuestions(answerId));
    }
  }, [dispatch, answerId]);

  const onSubmit = () => {
    const answerList = materialityQuestionsList.map((question) => {
      let answer = "";
      let answer_value = "";
      if (
        question.question_type === "text" ||
        question.question_type === "Text Field"
      ) {
        answer = textAnswers[question.id] || "";
      } 
      else if (question.question_type === "choose_from_option" || question.question_type === "Choose from Options") {
    answer = selectedValues[question.id] || "";

    if (answer === "Other (please specify):") {
      answer_value = textAnswers[`other-${question.id}`] || "";
    }
      } else if (question.question_type === "choose_from_topic") {
        const selectedOptions = selectedValues[question.id] || [];
        answer = selectedOptions.join(", "); 
        if (selectedOptions.includes("Other (please specify):")) {
          answer_value = textAnswers[`other-${question.id}`] || "";
        }
      }
      else if (question.question_type === "table") {
        answer = {};
        question.options.forEach((option) => {
          const relevance = document.getElementById(`relevance-${question.id}-${option}`).value;
          const impact = document.getElementById(`impact-${question.id}-${option}`).value;
          answer[option] = {
            "relevance": relevance,
            "impact": impact,
          };
        });
      }
      return {
        question_id: question.id,
        answer,
        question_type: question.question_type,
        ...(answer_value && { answer_value }) 
      };
    });
    const payload = {
      answer_list: answerList,
    };    
    dispatch(answerSurveyQuestions(answerId, payload))
  };

  useEffect(() => {
    if (formStatus.status === STATUS.SUCCESS) {
      navigate("/materiality/survey-response")
    } else if (formStatus.status === STATUS.ERROR) {
      enqueueSnackbar(formStatus.message || "We couldn't process your request. Please try again later.", {
        variant: "error", autoHideDuration: 3000 
      });
      dispatch(resetCreateQuestionsStatus())
    }
  }, [formStatus, dispatch, enqueueSnackbar]);

  const getTabledata = (question) =>
    question?.options?.map((item) => {
      const relevanceValue = tableData[question.id]?.[item]?.relevance || '';
    const impactValue = tableData[question.id]?.[item]?.impact || '';
      return {
        ...item,
        topic: item,
        relevance: (
          <div className={classes.editBox}>
             <TextField
            id={`relevance-${question.id}-${item}`}
            value={relevanceValue}
            onChange={(e) => {
              const value = e.target.value;          
              if (value === "" || (Number(value) >= 0 && Number(value) <= 10)) {
                handleTableInputChange(question.id, item, "relevance", e.target.value)
              }
            }}
            onInput={(e) => {
              const value = e.target.value;
              if (value !== "" && Number(value) > 10) {
                e.target.value = 10; 
              }
            }}
            variant="outlined"
            type="number"
            className={clsx(classes.input, classes.textField)}
            inputProps={{
              min: 0,
              max: 10,
              step: 1, 
            }}
          />
          </div>
        ),
        impact: (
          <div className={classes.editBox}>
             <TextField
            id={`impact-${question.id}-${item}`}
            value={impactValue}
            onChange={(e) => {
              const value = e.target.value;          
              if (value === "" || (Number(value) >= 0 && Number(value) <= 10)) {
                handleTableInputChange(question.id, item, "impact", value);
              }
            }}
            onInput={(e) => {
              const value = e.target.value;
              if (value !== "" && Number(value) > 10) {
                e.target.value = 10; 
              }
            }}
            variant="outlined"
            type="number"
            className={clsx(classes.input, classes.textField)}
            inputProps={{
              min: 0,
              max: 10,
              step: 1, 
            }}
          />
          </div>
        ),
      };
    });

  return (
    <Paper className={classes.container}>
      {surveyStatus?.status === STATUS.SUCCESS ?
      <>
      <Typography variant="h4" className={classes.title}>
        ESG MATERIAL TOPICS SURVEY
      </Typography>
      <div className={classes.questionList}>
        {materialityQuestionsList.map((question, index) => (
          <Grid key={question.id} className={(question.question_type === 'text' || question.question_type === 'Text Field')
            ? classes.inputGrid : classes.gridItem}>
            <Typography
              fontSize={18}
              fontWeight={500}
              variant="h3"
              paddingLeft={4}
              paddingBottom={2}
            >
              {index + 1}. {question.question}
            </Typography>
            <Box display="flex" flexDirection="column" paddingLeft={6}>
              {(question.question_type === "choose_from_option" ||
                question.question_type === "Choose from Options") &&
                question.options.map((option, idx) => (
                  <>
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        className={classes.selectCheckbox}
                        checked={selectedValues[question.id] === option}
                        onChange={() => handleSingleSelect(question.id, option)}
                        value={option}
                        sx={{
                          color: "#5A404E !important", 
                          borderWidth: "none",
                          '&.Mui-checked': {
                            color: "#8C2148 !important", 
                          },
                        }}  
                      />
                    }
                    label={option}
                  />
                  {selectedValues[question.id] === "Other (please specify):" && option === "Other (please specify):" && (
                    <TextField
                      variant="outlined"
                      placeholder="Please type your Answer"
                      multiline
                      rows={3}
                      value={textAnswers[`other-${question.id}`] || ""}
                      onChange={(e) => handleTextInputChange(`other-${question.id}`, e.target.value)}
                      className={classes.input}
                    />
                  )}
                  </>
                ))}
              {question.question_type === "choose_from_topic" &&
                question.options.map((option, idx) => (
                  <>
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        className={classes.selectCheckbox}
                        checked={
                          selectedValues[question.id]?.includes(option) || false
                        }
                        onChange={(e) => handleCheckboxChange(e, question.id)}
                        value={option}
                        sx={{
                          color: "#5A404E !important", 
                          borderWidth: "none",
                          '&.Mui-checked': {
                            color: "#8C2148 !important", 
                          },
                        }}  
                      />
                    }
                    label={option}
                  />
                  {selectedValues[question.id]?.includes("Other (please specify):") && option == "Other (please specify):" && (
      <TextField
        variant="outlined"
        placeholder="Please type your Answer"
        multiline
        rows={3}
        value={textAnswers[`other-${question.id}`] || ""}
        onChange={(e) => handleTextInputChange(`other-${question.id}`, e.target.value)}
        className={classes.input}
      />
    )}
                  </>
                ))}
              {(question.question_type === "text" ||
                question.question_type === "Text Field") && (
                <Box minHeight={80}>
                  <CeroInput
                    className={classes.textAreaContainer}
                    rows={3}
                    placeholder={"Please type your Answer"}
                    id={`answer-${question.id}`}
                    name={`answer-${question.id}`}
                    label="Please type your Answer"
                    multiline
                    value={textAnswers[question.id] || ''}
                    onChange={(e) => handleTextInputChange(question.id, e.target.value)}
                  />
                </Box>
              )}
              {question.question_type === "table" && (
                <Grid>
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    variant="h3"
                    paddingLeft={4}
                    paddingY={3}
                  >
                    Please rate the importance of the following topics for you
                    in two main areas:
                  </Typography>
                  <Typography paddingLeft={6} paddingBottom={2} mt={3}>
                    a) The relevance of the topic for you as a {companyName}
                    stakeholder
                  </Typography>
                  <Typography paddingLeft={6} paddingBottom={2} mb={3}>
                    b) The influence of {companyName} on this topic
                  </Typography>
                  <Typography paddingLeft={4} paddingY={3}>
                    This will allow us to identify material topics relevant to
                    our activities and disclose information about our activities
                    within{"\n"} these topics.Please use a scale from 0 to 10
                  </Typography>
                  <Typography paddingLeft={4} paddingBottom={2}>
                    (0 - the topic is not important at all; 10 - the topic is
                    extremely important).
                  </Typography>
                  <CeroTable
                    columns={tableColums}
                    data={getTabledata(question)}
                    hasMore={false}
                    loading={false}
                    classes={{headerCell : classes.headerCell,
                      tableBodyCell: classes.tableBodyCell,
                      tableHead: classes.tableTitle,
                      tableContainer: classes.tableContainer
                    }}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        ))}
      </div>
      <div className={classes.buttonContainer}>
        <CeroButton
          buttonText="Submit Survey"
          className={classes.buttonPrimary}
          onClick={onSubmit}
        />
      </div>
      </> :
      surveyStatus?.status === STATUS.RUNNING ?
      <div className={classes.loader}><CircularProgress className={classes.icon}/></div> :
      <Typography variant="h6" className={classes.tableTitle}>{surveyStatus?.message}</Typography>}
    </Paper>
  );
};

export default MaterialitySurvey;
