import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from 'notistack';

import { STATUS } from "../../../redux/constants";
import { updateSubsidiesFinancialValidation } from './schema';
import { getAssessmentPeriod, resetAddCombustionStatus, updateSubsidiesFinancialAssistance } from '../../../redux/actions';
import CeroButton from '../../../components/CeroButton';
import CeroInput from '../../../components/CeroInput';
import useStyles from "./styles";
import CeroEpochDatePicker from '../../../components/CeroDateTimePicker/CeroEpochDatePicker';
import dayjs from 'dayjs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroSelect from '../../../components/CeroSelect';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import CeroQuestionWithCheckbox from '../../../components/CeroQuestionWithCheckbox';
import { sampleYear } from '../../../constants';
import CeroChipSelect from '../../../components/CeroChipSelect';

const EditSubsidiesFinancialForm = (props) => {
    const { emissionData, onCancel } = props
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [showOtherDetails, setShowOtherDetails] = useState(false);
    
    const facilitiesData = useSelector(
      (state) => state.listings.listFacilities.data
    );
  
    const facilitiesList = facilitiesData.map((item) => ({
      key: item.id,
      value: item.name,
    }));
  
    const yearList = sampleYear().map((item) => ({
      id: item.key,
      label: item.value,
    }));

    const assessmentData = useSelector(
      (state) => state.assessment.assessmentPeriod.data
    );
  
    const assessmentOptions = Array.isArray(assessmentData)
      ? assessmentData.map((item) => ({
          key: item.period,
          value: item.period,
        }))
      : [];
    
      const FinancialAssistanceType = {
        "Awards" : "awards",
        "Export Credit Agencies" : "export credit agencies",
        "Financial Incentives" : "financial incentives",
        "Investment Grands" : "investment grands",
        "Royalty Holidays" : "royalty holidays",
        "Subsidies" : "subsidies",
        "Tax relief and Tax credits": "tax relief and tax credits",
        "Others" : "others" 
      }

    const updateEmissionData = useSelector(state => state.emission.updateSubsidiesFinancialAssistance);
    
    const dropHandleChange = (e) => {
      const value = e.target.value;
    
    if (value[value.length - 1] === "all") {
      const updateValues = formik.values.financialAssistanceType.length === Object.keys(FinancialAssistanceType).length ? [] : Object.keys(FinancialAssistanceType) 
      formik.setFieldValue("financialAssistanceType", updateValues);
      setShowOtherDetails(updateValues.includes("Others"));
      return;
    }
    else{
      formik.setFieldValue("financialAssistanceType",value)
      setShowOtherDetails(value.includes("Others"));
    }
    }
    const facilityAmountChange = (fac, event) => {
      formik.setFieldValue("facilities", {...formik.values.facilities, [fac]:event.target.value})
    }
    const facilityInitiate = () =>{
      return facilitiesData.reduce((acc, curr) => {
        const financialAssistant = emissionData.financial_assistant_by_facilities?.find(item => item.facility_id === curr.id);

        // If financial assistant object exists, assign its amount to the facility
        if (financialAssistant) {
            acc[curr.id] = financialAssistant.amount;
        } else {
            // If no corresponding financial assistant found, assign 0
            acc[curr.id] = 0;
        }
        
        return acc;
      }, {})
    }
    const facInt = facilityInitiate()
    const formik = useFormik({
      // emissionData.financial_assistant_by_facilities
        initialValues: {
                facility: emissionData.facility_id ||"",
                year: emissionData.year || "",
                assessmentPeriod: emissionData.assessment_period || "",
                financialAssistanceRecieved: emissionData.financial_assistant_received || "",
                financialAssistanceType: emissionData.type_financial_assistant? emissionData.type_financial_assistant.split(','):[],
                isGovernmentPresent: emissionData.any_government_present_in_shareholder || "No",
                details: emissionData.details || "",
                facilities: facInt || {},
                hye: facInt || {},
                otherDetails: emissionData.type_financial_assistant_details || "",
              },
            
        validationSchema: updateSubsidiesFinancialValidation,
        onSubmit: () => {},
    });
    useEffect(() => {
      const { facility, year } = formik.values;
      if (facility && year) {
        dispatch(getAssessmentPeriod(year, facility, 'subsidies_financial_assistance', emissionData.id));
      }
    }, [formik.values.facility, formik.values.year, dispatch]);

    useEffect(() => {
      setShowOtherDetails(emissionData.type_financial_assistant?.split(',').includes("Others"))
    }, [emissionData.type_financial_assistant]);

    useEffect(() => {
        if (updateEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Subsidies financial form updated successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus())
            onCancel();
        } else if (updateEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(updateEmissionData.message.message || "Something went wrong", { variant: 'error' });
            dispatch(resetAddCombustionStatus())
        }
    }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);
    const convert_facility_data = () => {
      const return_data = []
      Object.keys(formik.values.facilities).map(item=>{
        return_data.push({
          "facility_name": facilitiesData.filter(data=>data.id==item)[0].name,
          "facility_id": item,
          "amount": formik.values.facilities[item]
        })
      })
      return return_data
    }
    const onUpdateSubsidiesFinancialAssistance = () => {
        const requestData = {
            id: emissionData.id,
            facility_id: formik.values.facility,
            year: formik.values.year,
            assessment_period : formik.values.assessmentPeriod,
            type_financial_assistant: formik.values.financialAssistanceType,
            financial_assistant_received: formik.values.financialAssistanceRecieved,
            any_government_present_in_shareholder: formik.values.isGovernmentPresent,
            financial_assistant_by_facilities:convert_facility_data(),
            details: formik.values.details,
            type_financial_assistant_details:formik.values.otherDetails,
            save: true,
        };
        dispatch(updateSubsidiesFinancialAssistance(requestData));
    };
    
    const isFormChanged = useMemo(() => {
      return JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues);
  }, [formik.values, formik.initialValues]);
  
    return (
        <>
      <div className={classes.backContainer} onClick={onCancel}>
      <ArrowBackIosIcon />
      Back
      </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Edit Subsidies Financial</Typography>
                <Box className={classes.topContainer}>
                <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  mu
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} lg={6} md={6} xs={4}>
                <CeroAutoComplete
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
              
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={8} md={8} xs={8}>
                <CeroInput 
                required
                name="financialAssistanceRecieved" 
                label="Total Financial Assistance Recieved" 
                id="financialAssistanceRecieved"
                value={formik.values.financialAssistanceRecieved}
                fullWidth
                multiline
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.financialAssistanceRecieved && formik.errors.financialAssistanceRecieved}
                classes={{ container: classes.textAreaContainer }}/>
              </Grid>
              <Grid item container direction="column"  lg={4} md={4} xs={4}>
              <CeroChipSelect
              id="financialAssistance"
              name="financialAssistance"
              label={"Type of Financial Assistance"}
              formControlClass={classes.MultiSelectformControl}
              optionsde={FinancialAssistanceType}
              selected={formik.values.financialAssistanceType}
              handleChange={(e)=>dropHandleChange(e)}
              // classes={{ justCheckSelected: classes.justCheckSelected, label: classes.label  }}
              // shrink={false}
              // renderValue={() => null}
              />
                {/* <CeroChipSelect
                      required
                      id="financialAssistanceType"
                      name="financialAssistanceType"
                      label="Type of Financial Assistance"
                      optionsde={FinancialAssistanceType}
                      selected={formik.values.financialAssistanceType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    /> */}
              </Grid>
            </Grid>
            {showOtherDetails && (
                      <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      justifyContent="space-between"
                      spacing={8}
                      className={classes.financialEntries}
                    >
                        <Grid item container direction="column"   lg={12} md={12} xs={12}>
                          <CeroInput
                            id="otherDetails"
                            name="otherDetails"
                            label="Financial assistance details"
                            fullWidth
                            value={formik.values.otherDetails}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                          </Grid>
                        </Grid>
              )}
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
              className={classes.financialEntries}
            >
              <Grid item container direction="column"   lg={12} md={12} xs={12}>
              <Typography fontWeight="bold">Financial Assistance by Facility</Typography>
              </Grid>
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
              <Typography>Enter the total monetary value of financial assistance recieved from each facility</Typography>
              </Grid>
              {facilitiesData.map(item=>(<Grid item container direction="column"  className={classes.facilityEntries} lg={12} md={12} xs={12}>
                <Typography fontWeight="bold" className={classes.facilityNames}>{item.name}</Typography>
                <CeroInput
                  required
                  name={item.id} 
                  label="Amount" 
                  id={item.id}
                  value={formik.values.facilities[item.id]?formik.values.facilities[item.id]: 0}
                  fullWidth
                  multiline
                  type={"number"}
                  onChange={(e)=>facilityAmountChange(item.id,e)}
                  onBlur={formik.handleBlur}
                  error={formik.values.facilities[item.id] && formik.touched.facilities && formik.touched.facilities[item.id] && formik.errors.facilities[item.id]}
                  classes={{ container: classes.textAreaContainer }}/>
              </Grid>))}
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
              <CeroQuestionWithCheckbox classes={{'questionBox':classes.questionBox}} question="Indicating whether any government is present in the shareholding structure of the organization?"
              formik={formik}
              fieldName="isGovernmentPresent"
              onChange={()=>console.log("enter")}/>
                
              </Grid>
            </Grid>
            {formik.values.isGovernmentPresent === "Yes" && (<Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
                <CeroInput 
                name="details" 
                label="Details" 
                id="details"
                value={formik.values.details}
                fullWidth
                row={2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.details && formik.errors.details}
                classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>)}
            
                </Box>
                <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Update"
                    disabled={!isFormChanged}
                    className={clsx(classes.button, classes.buttonPrimary)}
                    onClick={onUpdateSubsidiesFinancialAssistance}
                />
                <CeroButton
                    buttonText="Clear"
                    variant="outlined"
                    className={clsx(classes.button, classes.buttonSecondary)}
                    onClick={formik.resetForm}
                />
            </Box>
            </Box>
        </Container>
        </>
    );
};

export default EditSubsidiesFinancialForm;
