import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { companyDetails, companyUsersList, getUsersList, resetSuperAdminStatus } from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CeroInfoPair from "../../../components/CeroInfoPair";
import CeroItemPair from "../../../components/CeroItemPair";
import CeroButton from "../../../components/CeroButton";
import { useSnackbar } from "notistack";
import { updateCompanySubscription } from "../../../redux/actions";
import CeroPopUp from "../../../components/CeroPopUp";
import CloseIcon from "@mui/icons-material/Close";

const CustomerManagement = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const {companyId , companyData, companyName} = props
  const [editAccessDisabled, setEditAccessDisabled] = useState(companyData?.edit_access === true ? true : false);
  const [selectedValue, setSelectedValue] = useState(companyData?.read_only_access_duration || "")
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const editAccess = props?.companyData?.edit_access;

  const actionStatus = useSelector(
    (state) => state.superAdmin.updateCompanySubscription
  );

  const extendedSub = props?.companyData?.extended_subscription === true
  const onEdit = () => {
    if (!editAccessDisabled) { 
      setEditAccessDisabled(true); 
      const data = {
          disable_edit_access: true,
      };
      dispatch(updateCompanySubscription(companyId, data));
  }
  };
  
  const readOnlyAccess = (duration) => {
    const data = {
      duration_for_read_only_access: duration,
    };
    dispatch(updateCompanySubscription(companyId, data));
  };

  const handleChange = (event) => {
    const selectedDuration = parseInt(event.target.value); 
    setSelectedValue(selectedDuration);
    readOnlyAccess(selectedDuration); 
  };

  const extendSubscription = () => {
    const data = {
      extended_subscription: true,
    };
    dispatch(updateCompanySubscription(companyId, data));
  }

  const reactivateSubscription = () => {
    const data = {
      renew: true,
    };
    dispatch(updateCompanySubscription(companyId, data));
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (actionStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar(actionStatus?.data.message, {
        variant: "success", autoHideDuration: 3000,
      });
      dispatch(resetSuperAdminStatus());
      setIsPopupOpen(false);
      dispatch(companyDetails(companyId));
    } else if (actionStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        actionStatus?.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" , autoHideDuration: 3000, }
      );
      dispatch(resetSuperAdminStatus());
    }
  }, [actionStatus, dispatch, enqueueSnackbar]);

  const getConfirmation = () => {
    return (
      <Box className={classes.popUp}>
          <CloseIcon onClick={handleClosePopup} className={classes.closeIcon}/>
          <Typography variant="body1" style={{fontWeight: 500}}>{extendedSub ? 
            `Are you sure you want to reactivate Subscription for company ${companyName}?` : `Are you sure you want to extend the Subscription period to 30 days
          for company ${companyName}?`}</Typography>
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={extendedSub ? reactivateSubscription : extendSubscription}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setIsPopupOpen(false)}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Subscription Details</Typography>
            <Box className={classes.statusBox}>
              <Typography>Subscription Status:</Typography>
              <Typography
                className={classes.activeStatus}
                style={{
                  color:
                    props?.companyData?.subscription_status === "active"
                      ? (extendedSub ? "orange" : "green") : "grey",
                }}
              >{props?.companyData?.subscription_status ? props?.companyData?.subscription_status : "NA"}</Typography>
            </Box>
          </div>
          <CeroItemPair
            title="License Type:"
            value={
              props?.companyData?.license_type
                ? (extendedSub ? `${props?.companyData?.license_type} (Extended Subscription 30 days)` : props?.companyData?.license_type)
                : "NA"
            }
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Subscription Start Date:"
            value={props?.companyData?.subscription_start_date ? props?.companyData?.subscription_start_date : "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <CeroItemPair
              title="Subscription End Date:"
              value={
                props?.companyData?.subscription_end_date
                  ? props?.companyData?.subscription_end_date
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            {extendedSub ? <Typography className={classes.subValue}>Extended Subscription - {props?.companyData?.extended_subscription_days_left} days left !
            </Typography> :
            <Typography className={classes.remainingValue}>{(props?.companyData?.days_left < 30) ? `${props?.companyData?.days_left} days left!` : ""}</Typography>
            }
          </div>
          {extendedSub && (
            <>
              <CeroItemPair
                title="Disable edit access?"
                value={
                  <div className={classes.radioBox}>
                    <FormControlLabel
                      control={
                        <Radio
                          id="disableEditAccess"
                          name="disableEditAccess"
                          checked={editAccessDisabled === true}
                          onClick={onEdit}
                          className={classes.radio}
                        />
                      }
                      label="Yes"
                      labelPlacement="end"
                    />
                  </div>
                }
                classes={{
                  container: classes.editContainer,
                  title: classes.title,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Duration for Read only Access"
                value={
                  <div className={classes.radioBox}>
                    <FormControlLabel
                      control={
                        <Radio
                        checked={selectedValue === 3}
                         value="3"
                          id="durationReadOnly"
                          name="durationReadOnly"
                          onChange={handleChange}
                          className={classes.radio}
                          onClick={() => {
                            readOnlyAccess(3);
                          }}
                        />
                      }
                      label="3 months"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedValue === 6}
                          value="6"
                          id="durationReadOnly"
                          onChange={handleChange}
                          name="durationReadOnly"
                          className={classes.radio}
                          onClick={() => {
                            readOnlyAccess(6);
                          }}
                        />
                      }
                      label="6 months"
                      labelPlacement="end"
                    />
                  </div>
                }
                classes={{
                  container: classes.editContainer,
                  title: classes.title,
                  value: classes.value,
                }}
              />
            </>
          )}
          {props?.companyData?.days_left < 30 && 
          <CeroButton
            buttonText={extendedSub ? "Reactivate Subscription" : "Extent Subscription Period"}
            className={classes.buttonPrimary}
            disabled={props?.companyData?.days_left !== 0 && props?.companyData?.days_left < 30}
            onClick={() => setIsPopupOpen(true)}
          />}
        </CardContent>
      </Card>
      <CeroPopUp
        primaryPopUp={{
          open: isPopupOpen,
          onClose: { handleClosePopup },
          content: getConfirmation(),
          header: { title: extendedSub ? "Reactivate Subscription" : "Extend Subscription Period" },
        }}
        classes={{ dialog: classes.dialog,
        }}
      />
      
    </>
  );
};

export default CustomerManagement;
