import { BorderColor } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  childContainer: {
    backgroundColor: "#EDFFF8 !important",
  },
  container: {
    backgroundColor: theme.palette.background.white,
    height: "fit-content",
    width: "97%",
    borderRadius: 8,
    padding: theme.spacing(5, 7),
    position: "relative",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  innerContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.darkGray,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.background.darkGray,
    },
  },
  textField: {
    width: "25%",
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
        // backgroundColor: theme.palette.background.white,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize:"20px",
    fontWeight:500
  },
  buttonContainer: {
    padding: theme.spacing(4, 0),
  },
  buttonPrimary: {
    background: "#2A6565",
    textTransform: "capitalize",
    width: 200,
    height: 42,
    marginRight: 15,
    marginTop:15,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#2A6565",
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: "#2A6565",
    borderColor: "#2A6565",
    textTransform: "capitalize",
    width: 80,
    height: 42,
    "&:hover": {
      borderColor: "#2A6565",
      backgroundColor: "#2A6565",
      color: "#FFF",
    },
    marginRight: theme.spacing(5),
  },
  topContainer: {
    "& .MuiGrid-container > .MuiGrid-container > .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: "#686868 !important",
      },
    "& .MuiGrid-container > .MuiGrid-container > .MuiBox-root > .MuiStack-root > .MuiFormControl-root > .Mui-focused":
      {
        color: "#686868 !important",
      },
    "& .MuiGrid-container > .MuiGrid-container > .MuiBox-root > .MuiStack-root > .MuiFormControl-root > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#686868",
      },
  },
  contain: {
    margin: theme.spacing(3, 0),
    display: "flex",
    flexDirection: "column",
  },
  bottomContainer: {
    maxWidth: 700,
    marginTop: theme.spacing(5),
  },
  previewTitle: {
    marginBottom: theme.spacing(3),
  },
  previewItem: {
    margin: theme.spacing(1, 0),
    fontSize: 15,
  },
  secondResultContainer: {
    paddingLeft: `${theme.spacing(4)} !important`,
    marginLeft: theme.spacing(4),
    borderLeft: `solid 1px ${theme.palette.Other.stroke}`,
  },
  textAreaContainer: {
    height: "fit-content",
  },
  backContainer: {
    color: "#387A8A",
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 35,
    paddingTop: 10,
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: theme.spacing(8),
  },
}));

export default useStyles;
