export const StationaryColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "fuel",
      columnId: "fuel",
      columnHeader: "Fuel",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "biofuel_co2",
      columnId: "biofuel_co2",
      columnHeader: "BioFuel CO2\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const TransportationColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "category_name",
      columnId: "category_name",
      columnHeader: "Category",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "mode_of_transport_name",
      columnId: "mode_of_transport_name",
      columnHeader: "Mode of Transport",
    },
    {
      columnKey: "vehicle_type_name",
      columnId: "vehicle_type_name",
      columnHeader: "Vehicle type",
    },
    {
      columnKey: "activity_type_name",
      columnId: "activity_type_name",
      columnHeader: "Unit",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "biofuel_co2",
      columnId: "biofuel_co2",
      columnHeader: "BioFuel CO2\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const MobileColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "fuel_source",
      columnId: "fuel_source",
      columnHeader: "Fuel Source",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "activity_type",
      columnId: "activity_type",
      columnHeader: "Activity Type",
    },
    {
      columnKey: "vehicle_type",
      columnId: "vehicle_type",
      columnHeader: "Vehicle Type",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "biofuel_co2",
      columnId: "biofuel_co2",
      columnHeader: "BioFuel CO2\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const PurchasedElectricityColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "calculation_approach",
      columnId: "calculation_approach",
      columnHeader: "Calculation Approach",
    },
    {
      columnKey: "co2",
      columnId: "co2",
      columnHeader: "CO2\n(Ton)",
    },
    {
      columnKey: "ch4",
      columnId: "ch4",
      columnHeader: "CH4\n(Ton)",
    },
    {
      columnKey: "n2o",
      columnId: "n2o",
      columnHeader: "N2O\n(Ton)",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "ef",
      columnId: "ef",
      columnHeader: "EF \n(kgCO2e/unit)",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WaterDischargeColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "treatment_level",
      columnId: "treatment_level",
      columnHeader: "Treatment Level",
    },
    {
      columnKey: "treatment_required",
      columnId: "treatment_required",
      columnHeader: "Treatment Required",
    },
    {
      columnKey: "water_destination_name",
      columnId: "water_destination_name",
      columnHeader: "Destination Name",
    },
    {
      columnKey: "water_destination_stress_type_name",
      columnId: "water_destination_stress_type_name",
      columnHeader: "Stress Type",
    },
    {
      columnKey: "water_destination_type_name",
      columnId: "water_destination_type_name",
      columnHeader: "Destination type",
    },
    {
      columnKey: "usage",
      columnId: "usage",
      columnHeader: "Usage",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e\n(Ton)",
    },
    {
      columnKey: "month",
      columnId: "month",
      columnHeader: "Month",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WaterConsumptionColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "water_source_stress_type_name",
      columnId: "water_source_stress_type_name",
      columnHeader: "Stress type",
    },
    {
      columnKey: "water_source_type_name",
      columnId: "water_source_type_name",
      columnHeader: "Source type",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "CO2e (Ton)",
    },
    {
      columnKey: "usage",
      columnId: "usage",
      columnHeader: "Usage",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const RefrigerantsColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "gas_type_name",
      columnId: "gas_type_name",
      columnHeader: "Gas Type",
    },
    {
      columnKey: "refrigerant_name",
      columnId: "refrigerant_name",
      columnHeader: "Refrigerant",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "Co2E",
    },
    {
      columnKey: "month",
      columnId: "month",
      columnHeader: "Month",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WasteCombustionColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "facility_name",
      columnId: "facility_name",
      columnHeader: "Facility",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Amount",
    },
    {
      columnKey: "waste_type_name",
      columnId: "waste_type_name",
      columnHeader: "Waste Type",
    },
    {
      columnKey: "waste_disposal_location_name",
      columnId: "waste_disposal_location_name",
      columnHeader: "Waste disposal location",
    },
    {
      columnKey: "waste_disposal_operation_name",
      columnId: "waste_disposal_operation_name",
      columnHeader: "Waste disposal operation",
    },
    {
      columnKey: "waste_disposal_option_name",
      columnId: "waste_disposal_option_name",
      columnHeader: "Waste disposal option",
    },
    {
      columnKey: "waste_hazard_option_name",
      columnId: "waste_hazard_option_name",
      columnHeader: "Waste hazard option",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "co2e",
      columnId: "co2e",
      columnHeader: "Co2E",
    },
    {
      columnKey: "usage",
      columnId: "usage",
      columnHeader: "Usage",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const DevelopmentTrainingColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "objective",
      columnId: "objective",
      columnHeader: "Objective",
    },
    {
      columnKey: "attended",
      columnId: "attended",
      columnHeader: "No of Attendee",
    },
    {
      columnKey: "hours",
      columnId: "hours",
      columnHeader: "No of Hours",
    },
    {
      columnKey: "content",
      columnId: "content",
      columnHeader: "Content",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const EmployeeHealthDetailsColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of incidents reported",
    },
    {
      columnKey: "affected",
      columnId: "affected",
      columnHeader: "Total No. of Employees affected",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const WorkerSafetyTrainingColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of Safety Trainings Conducted",
    },
    {
      columnKey: "attended",
      columnId: "attended",
      columnHeader: "Total No. of Employees attended",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const EmployeeDescriminationIncidentColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of discrimination Incidents Reported",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const SupplierHumanRightsTrainigColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of Suppliers screened under Human Rights",
    },
    {
      columnKey: "is_at_risk_of_child_labor",
      columnId: "is_at_risk_of_child_labor",
      columnHeader: "Total No. of Suppliers reported Child Labour",
    },
    {
      columnKey: "is_at_risk_for_compulsory_labor",
      columnId: "is_at_risk_for_compulsory_labor",
      columnHeader: "Total No. of Suppliers reported Forced Labour",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const SocialHumanRightsTrainigColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "is_at_risk_of_child_labor",
      columnId: "is_at_risk_of_child_labor",
      columnHeader: "Total No. of Operations reported Child Labour",
    },
    {
      columnKey: "is_at_risk_for_compulsory_labor",
      columnId: "is_at_risk_for_compulsory_labor",
      columnHeader: "Total No. of Operations reported Forced Labour",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const SupplierScreeningColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "suppliers_screened_using_env_criteria",
      columnId: "suppliers_screened_using_env_criteria",
      columnHeader:
        "Total No. of Suppliers screened using Environmental Criteria",
    },
    {
      columnKey: "suppliers_screened_using_social_criteria",
      columnId: "suppliers_screened_using_social_criteria",
      columnHeader: "Total No. of Suppliers screened using Social Criteria",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const LocalCommunitiesColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of Local Communities",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Total amount of contributions made",
      type: "number"
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const PoliticalContributionColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader:
        "Frequency of political contributions during the reporting period",
    },
    {
      columnKey: "amount",
      columnId: "amount",
      columnHeader: "Total amount of contributions made",
      type: "number",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const AntiCorruptionDisclosureColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total_operations",
      columnId: "total_operations",
      columnHeader: "Total Number of operations assessed",
    },
    {
      columnKey: "total_incidents",
      columnId: "total_incidents",
      columnHeader: "Total Number of Incidents Reported",
    },
    
  ];
  
  export const AntiCorruptionTrainigColumnsReport = [
    
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total Number of Trainings Conducted",
    },
    {
      columnKey: "attended",
      columnId: "attended",
      columnHeader: "Total Number of Attendees",
    },
    
  ];
  
  export const AntiCompetitiveDisclosureColumnsReport = [
    
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total Number of Legal Actions",
    },
  ];
  
  export const FinancialAssistanceColumnsReport = [
    
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total amountof Financial Assistance Received",
      type: "number",
    },
   
  ];
  
  export const TrainingHoursColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Average Training Hours Per Employee",
    },
    {
      columnKey: "female",
      columnId: "female",
      columnHeader: "Average Training Hours Per Female Employee",
    },
    {
      columnKey: "male",
      columnId: "male",
      columnHeader: "Average Training Hours Per Male Employee",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const PerformanceCareerColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of Development Programs Conducted",
    },
    {
      columnKey: "employees_participation_percentage",
      columnId: "employees_participation_percentage",
      columnHeader: "Percentage of Employees participated",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const EmployeeBenefitsColumnsReport = [
    {
      columnKey: "statusReport",
      columnId: "statusReport",
      columnHeader: "",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of Employee Benefits Recipients",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
    },
  ];
  
  export const UploadFileEmissionColumnsReport = [
    
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "total",
      columnId: "total",
      columnHeader: "Total No. of Infrastructure Investments",
    },
  ];
  
  export const DiversityByGenderColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "male",
      columnId: "male",
      columnHeader: "% of Male Employees",
    },
    {
      columnKey: "female",
      columnId: "female",
      columnHeader: "% of Female Employees",
    },
    {
      columnKey: "other",
      columnId: "other",
      columnHeader: "% of Non-Binary Employees",
    },
  ];
  
  export const DiversityByAgeColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "under_30",
      columnId: "under_30",
      columnHeader: "% of Employees under 30 years old",
    },
    {
      columnKey: "between_30_50",
      columnId: "between_30_50",
      columnHeader: "% of Employees 30-50 years old",
    },
    {
      columnKey: "over_50",
      columnId: "over_50",
      columnHeader: "% of Employees over 50 years old",
    },
  ];
  
  export const DiversityBySalaryColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "male",
      columnId: "male",
      columnHeader: "Basic Salary Ratio (Male)",
    },
    {
      columnKey: "female",
      columnId: "female",
      columnHeader: "Basic Salary Ratio (Female)",
    }
  ];
  
  export const EmployeeByGenderColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "hired",
      columnId: "hired",
      columnHeader: "Total No. of Employees Hired",
    },
    {
      columnKey: "resigned",
      columnId: "resigned",
      columnHeader: "Total No. of Employees Resigned",
    },
    {
      columnKey: "turn_over",
      columnId: "turn_over",
      columnHeader: "Turnover Rate",
    }
  ];
  
  export const EmployeeByAgeColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "hired",
      columnId: "hired",
      columnHeader: "Total No. of Employees Hired",
    },
    {
      columnKey: "resigned",
      columnId: "resigned",
      columnHeader: "Total No. of Employees Resigned",
    },
    {
      columnKey: "turn_over",
      columnId: "turn_over",
      columnHeader: "Turnover Rate",
    }
  ];
  
  export const BoardDiversityByGenderColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "male",
      columnId: "male",
      columnHeader: "Male %",
    },
    {
      columnKey: "female",
      columnId: "female",
      columnHeader: "Female %",
    },
    {
      columnKey: "other",
      columnId: "other",
      columnHeader: "Non-Binary %",
    },
  ];
  
  export const BoardDiversityByAgeColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "under_30",
      columnId: "under_30",
      columnHeader: "% of Members under 30 years old",
    },
    {
      columnKey: "between_30_50",
      columnId: "between_30_50",
      columnHeader: "% of Members 30-50 years old",
    },
    {
      columnKey: "over_50",
      columnId: "over_50",
      columnHeader: "% of Members over 50 years old",
    },
  ];
  
  export const ManagementDiversityByGenderColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "male",
      columnId: "male",
      columnHeader: "Male %",
    },
    {
      columnKey: "female",
      columnId: "female",
      columnHeader: "Female %",
    },
    {
      columnKey: "other",
      columnId: "other",
      columnHeader: "Non-Binary %",
    },
  ];
  
  export const ManagementDiversityByAgeColumnsReport = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "under_30",
      columnId: "under_30",
      columnHeader: "% of Members under 30 years old",
    },
    {
      columnKey: "between_30_50",
      columnId: "between_30_50",
      columnHeader: "% of Members 30-50 years old",
    },
    {
      columnKey: "over_50",
      columnId: "over_50",
      columnHeader: "% of Members over 50 years old",
    },
  ];