import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(5),
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: "100%",
    marginRight: 15,
    fontWeight: 400,
    marginTop: 5,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  percentField: {
    width: "60%",
    "& .MuiInputBase-root .MuiOutlinedInput-root": {
      height: "40px !important",
    },
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  textField: {
    width: "80%",
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  editIcon: {
    color: theme.palette.Primary.hoverButton,
  },
  tableBodyCell: {
    textTransform: "capitalize",
  },
}));

export default useStyles;
