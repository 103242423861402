import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { useFormik } from "formik";
import DashboardLayout from "../../../layouts/DashboardLayout";
import {
  getIotData,
  getOffsetDetails,
  refreshIotData,
  resetCarbonOffset,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DataTable from "./DataTable";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";

const CarbonOffsetDetails = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [year, setYear] = useState(0);

  const projectDetails = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.data
  );

  const projectDetailsCard = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.status
  );

  const iotDetails = useSelector((state) => state.carbonOffset.getIotData.data);
  const updatedStatus = useSelector(
    (state) => state.carbonOffset.updateIotData
  );
  const refreshedData = useSelector(
    (state) => state.carbonOffset.refreshIotData
  );
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    dispatch(getIotData(id, year));
  }, [dispatch, id, year, updatedStatus]);

  useEffect(() => {
    if (refreshedData?.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Data fetched from IoT successfully!",
        { variant: "success" , autoHideDuration: 3000}
      );
      dispatch(getIotData(id, year));
      dispatch(resetCarbonOffset());
    } else if (refreshedData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        refreshedData?.message
          ? refreshedData?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error" , autoHideDuration: 3000}
      );
      dispatch(resetCarbonOffset());
    }
  }, [refreshedData, enqueueSnackbar, dispatch]);

  useEffect(() => {
    id && dispatch(getOffsetDetails(id));
  }, [dispatch, id]);

  const refreshData = () => {
    id && dispatch(refreshIotData(id));
  }
  const onChangeFilter = (selectedValue) => {
    setYear(selectedValue.id);
  };
  
  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      {projectDetailsCard === STATUS.RUNNING ?
      <Box className={classes.loader}>
      <CircularProgress />
      </Box> :
      <>
      <Box className={classes.boxContainer}>
        <Grid className={classes.titleGrid}>
          <Grid item>
            <Typography variant="h6">{projectDetails?.name}</Typography>
            <div className={classes.location}>
              <ReactCountryFlag
                countryCode={projectDetails?.country_code}
                svg
                style={{
                  width: "2em",
                  height: "1.8em",
                  marginRight: "0.8em",
                }}
              />
              <Typography variant="subtitle1">
                {projectDetails?.location}
              </Typography>
            </div>
          </Grid>
          <Grid item display="flex">
            <CeroButton
              buttonText="Refresh"
              variant="outlined"
              endIcon={<AutorenewIcon />}
              onClick={refreshData}
              className={classes.buttonSecondary}
            />
          </Grid>
        </Grid>
        <Typography className={classes.contentBox}>
          {projectDetails?.description}
        </Typography>
        <Grid spacing={5} className={classes.gridContainer}>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>
              Program Type
            </Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.program_type}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>Program</Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.program_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>
              Start year
            </Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.start_year}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>
              Installed Capacity (kW)
            </Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.program_specific_data?.find(
                  (data) => data.data_name === "Installed capacity (KW)"
                )?.value}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.gridItemTitle}>
              No. of Panels installed
            </Typography>
            <Typography className={classes.gridItem}>
              {projectDetails?.program_specific_data?.find(
                  (data) => data.data_name === "number of panels"
                )?.value}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.tableTitle}><Typography variant="h6">Data collected from IoT Device</Typography>
      <CeroAutoComplete
        id="year"
        label="Year"
        onChange={(e, value) => onChangeFilter(value)}
        options={yearList}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        classes={{ root: classes.filter }}
      />
      <DataTable 
      programId={id} 
      iotDetails={iotDetails}
      updatedStatus={updatedStatus}/>
      </div></>}
    </DashboardLayout>
  );
};

export default CarbonOffsetDetails;
