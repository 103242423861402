import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  addEmployeeBenefit,
  getEmissionInputFormat,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroChipSelect from "../../../components/CeroChipSelect";
import { schemeValidation } from "./schema";

const AddEmployeeBenefits = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const addDataStatus = useSelector(
    (state) => state.emission.addEmployeeBenefit
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      employeeClassification: "",
      noOfEmployees: "0",
      benefitsProvided: [],
      otherDetails: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (addDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Benefits added successfully", {variant: "success",});
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addDataStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        addDataStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error",}
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addDataStatus, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    const { facility, year, assessmentPeriod } = formik.values;
    if (facility && year && assessmentPeriod) {
      dispatch(getEmissionInputFormat("employees_benefit", assessmentPeriod, year, facility));
    }
  }, [formik.values.facility, formik.values.year, formik.values.assessmentPeriod, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const emissionInputs = useSelector(
    (state) => state.emission.emissionInputs.data
  );

  useEffect(() => {
  const { employeeClassification } = formik.values;
  if (employeeClassification === "Temporary") {
    formik.setFieldValue("noOfEmployees", emissionInputs.temporary_employees);
  } else if (employeeClassification === "Permanent") {
    formik.setFieldValue("noOfEmployees", emissionInputs.permanent_employees);
  } else {
    formik.setFieldValue("noOfEmployees", "");
  }
}, [formik.values.employeeClassification, emissionInputs]);

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const onAddData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      employees_classification: formik.values.employeeClassification,
      number_of_employees:  formik.values.noOfEmployees,
      benefit_provided: selectedBenefits,
      details: formik.values.otherDetails,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addEmployeeBenefit(requestData));
  };

  const classificationTypes = [
    { key: "Permanent", value: "Permanent" },
    { key: "Temporary", value: "Temporary" },
  ];

  const benefitsList = {
    "Disability and invalidity coverage": "Disability and invalidity coverage",
    "Health care": "Health care",
    "Life insurance": "Life insurance",
    "Parental leave": "Parental leave",
    "Retirement provision": "Retirement provision",
    "Stock ownership": "Stock ownership",
    "Others": "Others"
};

  const benefitHandleChange = (event) => {
    const value = event.target.value;
    setSelectedBenefits(value);
    formik.setFieldValue("benefitsProvided", value);
    formik.setFieldTouched("benefitsProvided", true);
    if (value[value.length - 1] === "all") {
      setSelectedBenefits(selectedBenefits.length === Object.keys(benefitsList).length ? [] : Object.keys(benefitsList));
      setShowOtherDetails(value.length !== 8)
      return;
    }
    setShowOtherDetails(value.includes("Others"));
  };
  
  const empHandleChange = (event, value) => {
    if(emissionInputs.permanent_employees === null || emissionInputs.temporary_employees === null){
     formik.handleChange(event);
    }else{
      formik.setFieldValue("noOfEmployees", value.id)
    }
  }

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Employee Benefits
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroSelect
                  required
                  id="employeeClassification"
                  name="employeeClassification"
                  label="Employee Classification"
                  options={classificationTypes}
                  selectedValue={formik.values.employeeClassification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.employeeClassification &&
                    formik.errors.employeeClassification
                  }
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <Box className={classes.yearFilter}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                 </Box>
                <CeroInput
                  required
                  id="noOfEmployees"
                  name="noOfEmployees"
                  label="Number of Employees"
                  value={formik.values.noOfEmployees}
                  fullWidth
                  onChange={(e, value) => empHandleChange(e, value)}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.noOfEmployees && formik.errors.noOfEmployees
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
                <CeroChipSelect
                  required
                  id="benefitsProvided"
                  name="benefitsProvided"
                  label={"Benefits Provided"}
                  formControlClass={classes.MultiSelectformControl}
                  optionsde={benefitsList}
                  selected={selectedBenefits}
                  handleChange={benefitHandleChange}
                  error={
                    formik.touched.benefitsProvided &&
                    formik.errors.benefitsProvided
                  }
                  helperText={formik.errors.benefitsProvided}
                />
              </Grid>
            </Grid>
          </Box>
          {showOtherDetails && (
            <CeroInput
              id="otherDetails"
              name="otherDetails"
              label="Details of benefits provided"
              fullWidth
              value={formik.values.otherDetails}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.otherDetails && formik.errors.otherDetails}
            />
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid || formik.values.benefitsProvided.length === 0}
            className={classes.buttonPrimary}
            onClick={() => onAddData()}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddEmployeeBenefits;
