import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Typography, Grid, Box, CircularProgress, IconButton, TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import CeroDropdown from "../../components/CeroDropdown";
import { sampleYear, userRolesForAddUser } from "../../constants";
import TicketsTable from "./TicketsTable";
import CeroButton from "../../components/CeroButton";
import TicketStatus from "./TicketStatus";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";

import { listTickets, getCompanyList, getManagerList, getTicketDetails } from "../../redux/actions";
import { STATUS } from "../../redux/constants";

import useStyles from "./styles";
import CreateTicketDrawer from "../common/CreateTicketDrawer";
// import TwoTickets from "../../assets/icons/TicketIcon.png";
import ListTicketDrawer from "../common/ListTicketsDrawer";
import { getCookie } from "../../services/cookie";
// import CeroPopUp from "../../components/CeroPopUp";
// import CeroSelect from "../../components/CeroSelect";

var yearInput = [{ key: 0, value: 'All' }]
yearInput = yearInput.concat(sampleYear())
const ticketTypeOption = [
  {
    key: "all",
    value: "All",
  },
  {
    key: "assigned",
    value: "Assigned tickets",
  },
  {
    key: "self",
    value: "My tickets",
  },

];

const ticketStatusOption = [
  {
    key: "all",
    value: "All",
  },
  {
    key: "open",
    value: "Open",
  },
  {
    key: "closed",
    value: "Closed",
  },
];

const Tickets = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [year, setYear] = useState(0);
  const [ticketType, setTicketType] = useState("all");
  const [ticketStatus, setTicketStatus] = useState("all");
  const [company, setCompany] = useState("");
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isToCreate, setIsToCreate] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  // const [reviewPopup, setReviewPopup] = useState(false);
  // const [comment, setComment] = useState("");
  // const [assignee, setAssignee] = useState();
  // const [role, setRole] = useState();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const ticketList = useSelector((state) => state.ticket.listTickets.data);
  const ticketCount = useSelector((state) => state.ticket.listTickets.count);
  const companyList = useSelector((state) => state.company.companyList.data);
  const createTicketDetailsStatus = useSelector((state) => state.ticket.createTicketDetails.status);
  const updateTicketDetailsStatus = useSelector((state) => state.ticket.updateTicketDetails.status);
  const ticketListStatus = useSelector(
    (state) => state.ticket.listTickets.status
  );


  const companyListOption = companyList.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const isAuditor = userInfo && userInfo.role.includes(rolesEnum.AUDITOR);
  const role = getCookie('role');
  // const listManager = useSelector((state) => state.listings.listManager.data);
  // const managerList = listManager.map((item) => ({
  //   key: item.id,
  //   value: item.name,
  // }));

  const ticketDetails = useSelector(
    (state) => state.ticket.ticketDetails.data.response
  );
  const ticketId = useSelector(
    (state) => state.ticket.ticketDetails.data.ticket_id
  );
  const onSelectTicket = (selectedRow) => {
    isAuditor
      ? navigate(`id?ticketId=${selectedRow.id}&companyId=${company}`)
      : navigate(`id?ticketId=${selectedRow.id}`);
  };

  // const onClear = () => {
  //   setYear(0);
  //   setTicketType("all");
  //   setTicketStatus("all");
  // };

  // const onclickShowTickets = () => {
  //   setShowTickets(true);
  // };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  const internal =  true

  useEffect(() => {
    if (isAuditor) {
      company &&
        dispatch(listTickets({ year, ticketType, ticketStatus, company,isAuditor }));
    } else {
      dispatch(listTickets({ year, ticketType, ticketStatus, internal, isAuditor }));
    }
  }, [year, ticketType, ticketStatus, dispatch, isAuditor, company, userInfo, createTicketDetailsStatus, updateTicketDetailsStatus]);

  useEffect(() => {
    isAuditor &&
      !company &&
      companyListOption &&
      companyListOption.length &&
      setCompany(companyListOption[0].key);
  }, [company, companyListOption, isAuditor]);

  useEffect(() => {
    isAuditor && dispatch(getCompanyList());
  }, [dispatch, isAuditor]);
  
  const handleReviewPopup = (item) => {
    // setReviewPopup(true);
    setIsToCreate(true);
    setIsDrawerOpen(true);
  }
  // const handleCommentChange = (event) => {
  //   setComment(event.target.value);
  // };
  // const onSubmitReview = () => {
  //   setReviewPopup(false);

  // }
  const onClickEditIcon = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    setIsToCreate(false);
    dispatch(getTicketDetails({id}));
    setIsDrawerOpen(true);
  }
  
  // const rolePriority = (role) => {
  //   if (role === "sustainability_manager") {
  //     return "sustainability_manager";
  //   } else if (role === "approver") {
  //     return "approver";
  //   } else if (role === "facility_manager") {
  //     return "facility_manager";
  //   } else if (role === "business_user") {
  //     return "business_user";
  //   }else {
  //     return "read_only_user"
  //   }
  // };
  // const getReviewComments = () => {
  //   return (
  //     <>
  //     <Box className={classes.checkbox}>
  //     <CeroSelect
  //         id="role"
  //         name="role"
  //         label="Assinee Role"
  //         options={userRolesForAddUser}
  //         selectedValue={role}
  //         onChange={(e) => {
  //           setRole(e.target.value);
  //           dispatch(getManagerList(rolePriority(e.target.value)));}}
  //           classes={{container:classes.selectCheckbox}}
  //       />
  //       <CeroSelect
  //         id="assignee"
  //         name="assignee"
  //         label="Assignee Name"
  //         options={managerList}
  //         selectedValue={assignee}
  //         onChange={(e) => {console.log(e.target.value);setAssignee(e.target.value)}}
  //         classes={{container:classes.selectCheckbox}}
  //       />
  //       </Box>
  //     <TextField
  //       id="outlined-multiline-static"
  //       multiline
  //       rows={5}
  //       placeholder="Ticket details"
  //       style={{ width: "100%" }}
  //       value={comment}
  //       onChange={handleCommentChange}
  //       className={classes.textField}
  //     />
  //     <div className={classes.textLimit}>
  //       <div className={classes.noComments}>
  //         {comment.trim().length === 0 ? "No comments" : ""}
  //       </div>
  //       <div className={classes.maxCharacters}>
  //         {"Maximum 200 characters"}
  //       </div>
  //     </div>
  //     <div className={classes.reviewButtons}>
  //       <CeroButton
  //             className={classes.buttonPrimary}
  //             variant="contained"
  //             onClick={onSubmitReview}
  //             buttonText="Create"
  //           />
  //           <CeroButton
  //             className={classes.buttonSecondary}
  //             variant="outlined"
  //             onClick={() => setReviewPopup(false)}
  //             buttonText="Cancel"
  //           />
  //     </div>
  //     </>)}
      
  return (
    <DashboardLayout>
      <Container className={classes.container}>
      <div className={classes.topButtonsContainer}>
        <Grid
          className={classes.filterContainer}
          container
          columnSpacing={2}
          alignItems="center"
          wrap="nowrap"
        >
          {isAuditor && (
            <Grid item xs={3.5}>
              <CeroDropdown
                id="company"
                label="Company"
                fullWidth
                options={companyListOption}
                onChange={({ target }) => setCompany(target.value)}
                selectedValue={company}
                className= {classes.dropdown}
              />
            </Grid>
          )}
          <Grid item xs={3.5}>
            <CeroDropdown
              id="year"
              label="Year"
              fullWidth
              options={yearInput}
              onChange={({ target }) => setYear(target.value)}
              selectedValue={year}
              className= {classes.dropdown}
            />
          </Grid>
          <Grid item xs={3.5}>
            <CeroDropdown
              id="ticketType"
              label="Ticket type"
              fullWidth
              options={ticketTypeOption}
              onChange={({ target }) => setTicketType(target.value)}
              selectedValue={ticketType}
              className= {classes.dropdown}
            />
          </Grid>
          <Grid item xs={3.5}>
            <CeroDropdown
              id="ticketStatus"
              label="Ticket status"
              fullWidth
              options={ticketStatusOption}
              onChange={({ target }) => setTicketStatus(target.value)}
              selectedValue={ticketStatus}
              className= {classes.dropdown}
            />
          </Grid>
        </Grid>
        <CeroButton
                buttonText="Create Ticket"
                variant="contained"
                className={classes.buttonPrimary}
                onClick={handleReviewPopup}
                disabled={readOnlyMode}
              /></div>
        {ticketListStatus === STATUS.SUCCESS ? (
          <Fragment>
            <TicketStatus
              ticketCount={ticketCount}
              setTicketType={setTicketType}
            />
            <TicketsTable
              ticketList={ticketList}
              onSelectTicket={onSelectTicket}
              userInfo={userInfo}
              clickEditIcon={onClickEditIcon}
            />
          </Fragment>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {ticketListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : ticketListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later."
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
        <CreateTicketDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          scope="util"
          ticketData={!isToCreate?ticketDetails:null}
          ticketId={!isToCreate?ticketId:null}
          // scopeId={statusId}
        />
        {showTickets && (
          <ListTicketDrawer
            isOpen={showTickets}
            scope="approval"
            onClose={onCloseTickets}
          />
        )}
        {/* <CeroPopUp
        primaryPopUp={{
          open: reviewPopup,
          onClose: () => setReviewPopup(false),
          content: getReviewComments(),
          header: { title: "Create Ticket" },
        }} */}
        {/* /> */}
      </Container>
    </DashboardLayout>
  );
};

export default Tickets;
