import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        fontWeight:600,
        cursor: 'pointer',
        paddingBottom: 20,
    },
    valueContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgba(90, 64, 78, 0.1)',
        width:'90%',
        borderRadius: 8,
        margin: 'auto',
        height: '90px',
    },
    value: {
        fontSize: 36,
        fontWeight: 600,
        color: theme.palette.Primary.background
    },
    name: {
        fontSize: 12,
        color: theme.palette.Primary.main
    },
    cardComponent: {
        padding: '20px 0!important'
    },
    container:{
        marginBottom: 8
    }
}));

export default useStyles;
