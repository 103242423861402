import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Chip,
  Tooltip,
} from "@mui/material";
import "jspdf-autotable";
import { APIEndpoints } from "../../../redux/constants";
import { getCookie } from "../../../services/cookie";
import DashboardLayout from "../../../layouts/DashboardLayout";
import Status from "../Status";
import CeroButton from "../../../components/CeroButton";

import {
  getReportDetails,
  deleteReport,
  resetReportStatus,
  getCumulativeTopics,
  getReportBlob,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";

import useStyles from "./styles";
import { reportEmissionTypeData } from "../../../constants";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EmissionPDFExport from "./EmissionPDFExport";
import AssignReport from "./AssignReport";
import ReportAccordion from "./ReportAccordian";

const ReportDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [isOpenAssignReport, setOpenAssignReport] = useState(false);
  const role = useSelector((state) => state.auth.role);
  const currentRole = getCookie('role')
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const handleDrawerClose = () => {
    setOpenAssignReport(false);
  };
  const isSustainabilityManager =
    role.includes(rolesEnum.SUSTAINABILITY_MANAGER) || role.includes(rolesEnum.PROXY_SUSTAINABILITY_MANAGER) ||
    role === "sustainability_manager" || role === "proxy_sustainability_manager";

  const reportDetails = useSelector(
    (state) => state.reports.reportDetails.data
  );

  const selectedYear = reportDetails.year;
  const facilities = reportDetails.facilities;
  const countryFacilityDataObject = {};
  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }

  const reportsListStatus = useSelector(
    (state) => state.reports.reportDetails.status
  );
  const deleteReportStatus = useSelector(
    (state) => state.reports.deleteReport.status
  );

  const reportsData = useSelector(
    (state) => state.reports.getCumulativeTopics.data
  );

  const reportsBlobExist = useSelector(
    (state) => state.reports.getReportBlob.data
  );

  const reportsDataStatus = useSelector(
    (state) => state.reports.getCumulativeTopics.status
  );
  const isAuditor = role === rolesEnum.AUDITOR;
  const onDeleteReport = () => {
    id && dispatch(deleteReport(id));
  };

  const authToken = getCookie("auth_token_admin");

  const onDownloadReport = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(
        `${APIEndpoints.DOWNLOAD_REPORT}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const url = response.data.blob_url;
      const link = document.createElement("a");
      link.href = url;
      link.click();
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (deleteReportStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Report deleted successfully", { variant: "success" });
      dispatch(resetReportStatus());
      navigate("/reports");
    } else if (deleteReportStatus === STATUS.ERROR) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      dispatch(resetReportStatus());
    }
  }, [deleteReportStatus, enqueueSnackbar, dispatch, navigate]);

  const isDeleteLoading = deleteReportStatus === STATUS.RUNNING;
  const data = reportsData?.emissions;
  const filteredData = data?.filter(function (item) {
    return !("sector" in item);
  });
  const emissionTypes = reportDetails.topics;
  const isaudited = reportDetails.is_audited;

  const onSelectData = (row) => {
    const testdata = row.type || row.id
    const emisionType = testdata.replace(/ /g, '_')
    navigate(
      `/reports/list-topic/${emisionType}/?year=${selectedYear}&facility=${
        reportDetails.facility_ids
      }&redirect_table=${true}&period=${reportDetails.period}&distribution=${row.distribution || "" }`
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getReportDetails(id));
      dispatch(getReportBlob(id));

      const intervalId = setInterval(() => {
        dispatch(getReportBlob(id));
      }, 30000); 

      return () => clearInterval(intervalId);
    }
  }, [id, dispatch]);

  useEffect(() => {
    (selectedYear) && dispatch(getCumulativeTopics(emissionTypes, selectedYear, isAuditor, reportDetails?.facility_ids, reportDetails?.period));
  }, [selectedYear, emissionTypes, reportDetails?.facility_ids, reportDetails?.period, dispatch]);

  const navigateBack = () => {
    navigate(-1);
  };

  const combustionSummaryColumns = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "type",
      columnId: "type",
      columnHeader: "Topics",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_co2",
      columnId: "total_co2",
      columnHeader: "CO2 (tonnes)",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_ch4",
      columnId: "total_ch4",
      columnHeader: "CH4 (tonnes)",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_n2o",
      columnId: "total_n2o",
      columnHeader: "N2O (tonnes)",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_co2e",
      columnId: "total_co2e",
      columnHeader: "CO2e (tonnes)",
      classes: { column: classes.cellContainer },
    },
  ];

  const waterSummaryColumns = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "type",
      columnId: "type",
      columnHeader: "Topics",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "usage",
      columnId: "usage",
      columnHeader: "Usage",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_co2e",
      columnId: "total_co2e",
      columnHeader: "CO2e (tonnes)",
      classes: { column: classes.cellContainer },
    },
  ];
const getStatusColor = (item) => {
if (item === 'approved') {
return <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
} else if (item === 'submitted') {
    return  <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
} else if (item === 'audited') {
    return  <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
} else if (item === 'review') {
    return <Tooltip title="Review" arrow><Chip className={classes.signalReview}/></Tooltip>
}else if (item === 'added') {
    return <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
}else if (item === 'assigned') {
  return <Tooltip title="assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
}else if (item === 'Awaiting approval') {
  return <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
}else if (item === 'In Progress') {
  return <Tooltip title="In Progress" arrow><Chip className={classes.signalPending}/></Tooltip>
}else if (item === 'verified') {
  return <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
}else {
    return <Tooltip title={item} arrow><Chip className={classes.signalAdded}/>{item}</Tooltip>
} 
}

const parentData = {};
  const superParentData = {};
  reportEmissionTypeData.map((item) => {
    item.subItems.map((innerItem) => {
      innerItem.subItems.map((subInnerItem) => {
        parentData[subInnerItem.id] = innerItem.id;
        superParentData[subInnerItem.id] = item.id;
      });
    });
  });

  let aggreatedData = {};
  reportsData?.emissions?.map((item) => {
    let pushedData = item;
    let pushedcolumn = combustionSummaryColumns;
    if (parentData[item.type] === "energy_and_materials") {
      pushedData = {
        ...item,
        type: item.type ? item.type.replace(/_/g, ' ') : "",
        status: getStatusColor(item.status)
      };
      pushedcolumn = combustionSummaryColumns;
    } else if (
      parentData[item.type] === "waste_water" 
    ) {
      pushedData = {
        ...item,
        type: item.type ? item.type.replace(/_/g, ' ') : "",
        usage: `${item.usage}`,
        status: getStatusColor(item.status)
      };
      pushedcolumn = waterSummaryColumns;
    }
    if (parentData[item.type] in aggreatedData) {
      aggreatedData[parentData[item.type]]["data"] =
        aggreatedData[parentData[item.type]]["data"].concat(pushedData);
    } else {
      aggreatedData[parentData[item.type]] = {};
      aggreatedData[parentData[item.type]]["data"] = [pushedData];
    }
    if (!("columns" in aggreatedData[parentData[item.type]]))
      aggreatedData[parentData[item.type]]["columns"] = pushedcolumn;
  });

  const blobExist = (reportsBlobExist.is_blob_exist === true)
 
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {reportsListStatus === STATUS.SUCCESS ? (
          <Fragment>
            <Box>
              <Box className={classes.buttonContainer}>
                <div className={classes.backContainer} onClick={navigateBack}>
                  <ArrowBackIosIcon />
                  Back
                </div>
                <div>
                  {isSustainabilityManager && (
                    <CeroButton
                      buttonText="Assign Report"
                      className={classes.buttonPrimary}
                      onClick={() => setOpenAssignReport(true)}
                      // disabled={isDeleteLoading}
                      disabled={readOnlyMode}
                    />
                  )}
                  {isOpenAssignReport && (
                    <AssignReport
                      isOpen={isOpenAssignReport}
                      onClose={handleDrawerClose}
                    />
                  )}
                  {(currentRole ==='sustainability_manager' || currentRole ==='proxy_sustainability_manager') && (
                    <CeroButton
                      buttonText={
                        isDeleteLoading ? "Deleting..." : "Delete Report"
                      }
                      className={classes.buttonPrimary}
                      onClick={onDeleteReport}
                      disabled={isDeleteLoading || readOnlyMode} 
                    />
                  )}
                   <Tooltip
                   title={!blobExist ? "Report is being generated" : ""}
                   arrow
                   disableHoverListener={blobExist} >
                   <span> 
                   <CeroButton
                    buttonText="Download report"
                    endIcon={<DownloadForOfflineIcon />}
                    className={classes.buttonPrimary}
                    onClick={onDownloadReport}
                    disabled={readOnlyMode || !blobExist}
                  />
                  </span>
                  </Tooltip>
                </div>
              </Box>
              <Typography variant="h6" component="span">
                {`Report: ${reportDetails.name}`}
              </Typography>
            </Box>
            <Status reportDetails={reportDetails} />
            <div className={classes.exportIcons}>
              <EmissionPDFExport
                emissionsArray={reportDetails.topics}
                selectedYear={selectedYear}
                facility={facilities}
                period={reportDetails?.period || null}
              />
            </div>
            {reportsDataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : reportsDataStatus === STATUS.SUCCESS ? (
              <ReportAccordion
                approvalData={reportsData?.emissions}
                aggreatedData={aggreatedData}
                emissionData={filteredData}
                emissionDataStatus={reportsDataStatus}
                onSelectData={onSelectData}
                isaudited={isaudited}
                reportSignals={reportsData.category}
                topicSignals={reportsData.sub_category_list}
                subTopicSignals={reportsData.topic_type_status}
              />
            ) : (
              "Something went wrong. Please try again later"
            )}
          </Fragment>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {reportsListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : reportsListStatus === STATUS.ERROR ? (
                "Something went wrong. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ReportDetails;
