import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import axios from "axios";
import { Container, Typography, Box, CircularProgress, Tooltip, Chip } from "@mui/material";
import "jspdf-autotable";
import { emissionTypeData, reportEmissionTypeData } from "../../../constants";
import { APIEndpoints } from "../../../redux/constants";
import { getCookie } from "../../../services/cookie";
import DashboardLayout from "../../../layouts/DashboardLayout";
import Status from "./Status";
import CeroButton from "../../../components/CeroButton";


import {
  clearEmissionList,
  getEmissionList,
  getStakeholderTopicsSummary,
  getStakeholderAssignedReports,
  getStakeholderCumulativeData,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";

import useStyles from "./styles";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroReportAccordion from "../../Reports/CeroReportAccordion";
import { getStakeholderAuditedReports } from "../../../redux/actions";
import Cookies from "js-cookie";
import ReportAccordion from "../../Reports/ReportDetails/ReportAccordian";

const ReportDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);

  const yearParam = queryParams.get("year");
  const type = queryParams.get("type");
  const companyId = Cookies.get("companyId");

  const auditedReportDetails = useSelector(
    (state) => state.reports.getStakeholderAuditedReports.data
  );
  const auditedReportDetailsStatus = useSelector(
    (state) => state.reports.getStakeholderAuditedReports.status
  );

  const assignedReportDetails = useSelector(
    (state) => state.reports.getStakeholderAssignedReports.data.report
  );
  const assignedReportDetailsStatus = useSelector(
    (state) => state.reports.getStakeholderAssignedReports.status
  );
  const stakeholderTopicsSummary = useSelector(
    (state) => state.reports.getStakeholderTopicsSummary.data
  );
  const emissionData = useSelector(
    (state) => state.emission.emissionList.data.emissions
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionList.status
  );
  const cumulativeData = useSelector(
    (state) => state.reports.getStakeholderCumulativeData.data
  );
  const reportsDataStatus = useSelector(
    (state) => state.reports.getStakeholderCumulativeData.status
  );
  const role = useSelector((state) => state.auth.role);

  const DEFAULT_ITEMS_PER_PAGE = 20;
  const savedPage = {
    pageNumber: 1,
  };
  const isStakeholder = role[0] === rolesEnum.STAKEHOLDER;

  useEffect(() => {
    if (type==='assigned_report')
      dispatch(getStakeholderAssignedReports(companyId, id));
    if (type==='audited_report')
      dispatch(getStakeholderAuditedReports(companyId, yearParam));
    dispatch(getStakeholderCumulativeData(companyId, id, type));
  }, [dispatch, companyId, yearParam]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const topicsSummaryData =
  //       type === "assigned_report"
  //         ? {
  //             year: yearParam,
  //             facilities: [
  //               assignedReportDetails &&
  //                 assignedReportDetails.facilities[0].facility_id,
  //             ],
  //             emission_types:
  //               assignedReportDetails && assignedReportDetails.topics,
  //           }
  //         : {
  //             year: yearParam,
  //             country: auditedReportDetails && auditedReportDetails.country,
  //             emission_types:
  //               auditedReportDetails && auditedReportDetails.topics,
  //           };
  //     dispatch(getStakeholderTopicsSummary(companyId, type, topicsSummaryData));
  //   }, 1000);
  // }, [auditedReportDetails]);
  let topicsSummaryData = {}
  useEffect(() => {
    if (auditedReportDetailsStatus=== STATUS.SUCCESS){
      const topicsSummaryData = {
        year: yearParam,
        country: auditedReportDetails && auditedReportDetails.country,
        emission_types:
          auditedReportDetails && auditedReportDetails.topics,
      };
      dispatch(getStakeholderTopicsSummary(companyId, type, topicsSummaryData));
    }
    else if (assignedReportDetailsStatus=== STATUS.SUCCESS){
      assignedReportDetails.topics = assignedReportDetails.topics.map(topic => topic.replace('employees_diversity_ratio_of_basic_salary', 'employees_diversity_and_ratio_of_basic_salary').replace('employees_hire_turnover', 'employees_hire_and_turnover'));

      const topicsSummaryData = {
              year: yearParam,
              facilities: [
                assignedReportDetails &&
                  assignedReportDetails.facilities[0].facility_id,
              ],
              emission_types:
                assignedReportDetails && assignedReportDetails.topics,
            }
    dispatch(getStakeholderTopicsSummary(companyId, type, topicsSummaryData));
    }
  }, [dispatch, auditedReportDetailsStatus, assignedReportDetailsStatus]);

  const downloadUrl =
    type === "audited_report"
      ? APIEndpoints.DOWNLOAD_STAKEHOLDER_REPORTS(companyId, type, id)
      : APIEndpoints.DOWNLOAD_ASSIGNED_STAKEHOLDER_REPORTS(companyId, type, id);

  const authToken = getCookie("auth_token_admin");

  const onDownloadReport = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(downloadUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const url = response.data.blob_url;
      const link = document.createElement("a");
      link.href = url;
      link.click();
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const [open, setOpen] = useState();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, 1);
    return () => {
      clearEmissionList();
    };
  }, [open2]);
  const onLoadMore = (limit = DEFAULT_ITEMS_PER_PAGE, pageNumber) => {
    const filterRequest = {
      limit,
      skip: ((pageNumber || (savedPage.pageNumber || 0) + 1) - 1) * limit,
      year: 2023,
    };
    if (!filterRequest?.facility_id) {
      delete filterRequest?.facility_id;
    }
    dispatch(
      getEmissionList(
        open2,
        filterRequest,
        false,
        companyId,
        null,
        null,
        null,
        isStakeholder
      )
    );
  };

  const navigateBack = () => {
    navigate(-1);
  };
  const combustionSummaryColumns = [
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "type",
      columnId: "type",
      columnHeader: "Topics",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_co2",
      columnId: "total_co2",
      columnHeader: "CO2 (tonnes)",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_ch4",
      columnId: "total_ch4",
      columnHeader: "CH4 (tonnes)",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_n2o",
      columnId: "total_n2o",
      columnHeader: "N2O (tonnes)",
      classes: { column: classes.cellContainer },
    },
    {
      columnKey: "total_co2e",
      columnId: "total_co2e",
      columnHeader: "CO2e (tonnes)",
      classes: { column: classes.cellContainer },
    },
  ];
  const parentData = {};
  const superParentData = {};
  reportEmissionTypeData.map((item) => {
    item.subItems.map((innerItem) => {
      innerItem.subItems.map((subInnerItem) => {
        parentData[subInnerItem.id] = innerItem.id;
        superParentData[subInnerItem.id] = item.id;
      });
    });
  });
  const getStatusColor = (item) => {
    if (item === 'approved') {
    return <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
    } else if (item === 'submitted') {
        return  <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
    } else if (item === 'audited') {
        return  <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
    } else if (item === 'review') {
        return <Tooltip title="Review" arrow><Chip className={classes.signalReview}/></Tooltip>
    }else if (item === 'added') {
        return <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
    }else if (item === 'assigned') {
      return <Tooltip title="assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
    }else if (item === 'Awaiting approval') {
      return <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
    }else if (item === 'In Progress') {
      return <Tooltip title="In Progress" arrow><Chip className={classes.signalPending}/></Tooltip>
    }else if (item === 'verified') {
      return <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
    }else {
        return <Tooltip title={item} arrow><Chip className={classes.signalAdded}/>{item}</Tooltip>
    } 
    }
    const waterSummaryColumns = [
      {
        columnKey: "status",
        columnId: "status",
        columnHeader: "Status",
        classes: { column: classes.cellContainer },
      },
      {
        columnKey: "type",
        columnId: "type",
        columnHeader: "Topics",
        classes: { column: classes.cellContainer },
      },
      {
        columnKey: "usage",
        columnId: "usage",
        columnHeader: "Usage",
        classes: { column: classes.cellContainer },
      },
      {
        columnKey: "total_co2e",
        columnId: "total_co2e",
        columnHeader: "CO2e (tonnes)",
        classes: { column: classes.cellContainer },
      },
    ];
  let aggreatedData = {};
  cumulativeData?.emissions?.map((item) => {
    let pushedData = item;
    let pushedcolumn = combustionSummaryColumns;
    if (parentData[item.type] === "energy_and_materials") {
      pushedData = {
        ...item,
        type: item.type ? item.type.replace(/_/g, ' ') : "",
        status: getStatusColor(item.status)
      };
      pushedcolumn = combustionSummaryColumns;
    } else if (
      parentData[item.type] === "waste_water" 
    ) {
      pushedData = {
        ...item,
        type: item.type ? item.type.replace(/_/g, ' ') : "",
        usage: `${item.usage}`,
        status: getStatusColor(item.status)
      };
      pushedcolumn = waterSummaryColumns;
    }
    if (parentData[item.type] in aggreatedData) {
      aggreatedData[parentData[item.type]]["data"] =
        aggreatedData[parentData[item.type]]["data"].concat(pushedData);
    } else {
      aggreatedData[parentData[item.type]] = {};
      aggreatedData[parentData[item.type]]["data"] = [pushedData];
    }
    if (!("columns" in aggreatedData[parentData[item.type]]))
      aggreatedData[parentData[item.type]]["columns"] = pushedcolumn;
  });
  const data = cumulativeData?.emissions;
  const filteredData = data?.filter(function (item) {
    return !("sector" in item);
  });
  const onSelectData = (row) => {
    const testdata = row.type || row.id
    const emisionType = testdata.replace(/ /g, '_')
    navigate(
      `/reports/list-topic/${emisionType}/?year=${yearParam}&facility=${
        assignedReportDetails.facility_ids
      }&redirect_table=${true}&period=${assignedReportDetails.period}&distribution=${row.distribution || "" }`
    );
  };
  const isaudited = assignedReportDetails?.is_audited;
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {auditedReportDetailsStatus === STATUS.SUCCESS ||
        assignedReportDetailsStatus === STATUS.SUCCESS ? (
          <Fragment>
            <Box>
              <Box className={classes.buttonContainer}>
                <div className={classes.backContainer} onClick={navigateBack}>
                  <ArrowBackIosIcon />
                  Back
                </div>
                <div>
                  <CeroButton
                    buttonText="Download report"
                    endIcon={<DownloadForOfflineIcon />}
                    className={classes.buttonPrimary}
                    onClick={onDownloadReport}
                  />
                </div>
              </Box>
              {type === "assigned_report" && (
                <Typography variant="h6" component="span">
                  {`Report: ${assignedReportDetails?.name}`}
                </Typography>
              )}
            </Box>
            <Status
              reportType={auditedReportDetails.report_type}
              reportDetails={
                auditedReportDetails.report_type === "audited_report"
                  ? auditedReportDetails
                  : assignedReportDetails
              }
            />
            {reportsDataStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : reportsDataStatus === STATUS.SUCCESS ? (
            <ReportAccordion
            approvalData={cumulativeData?.emissions}
            aggreatedData={aggreatedData}
            emissionData={filteredData}
            emissionDataStatus={reportsDataStatus}
            onSelectData={onSelectData}
            isaudited={isaudited}
            reportSignals={cumulativeData.category}
            topicSignals={cumulativeData.sub_category_list}
            subTopicSignals={cumulativeData.topic_type_status}

              // approvalData={cumulativeData?.emissions}
              // aggreatedData={aggreatedData}
              // // emissionData={filteredData}
              // emissionDataStatus={reportsDataStatus}
              // onSelectData={()=>{console.log("hello");}}
              // isaudited={true}
              // reportSignals={reportSignals}
              // topicSignals={topicSignals}
              // subTopicSignals={subTopicSignals}
              reportDetails={stakeholderTopicsSummary}
              // onLoadMore={onLoadMore}
              isStakeholder={isStakeholder}
              // company={companyId}
              year={yearParam}
              topics={
                auditedReportDetails.report_type === "audited_report"
                  ? auditedReportDetails.topics
                  : assignedReportDetails.topics
              }
              facility={assignedReportDetails?.facilities?.map(item=>item.facility_id)}
              // statusClassesDict={statusClassesDict}
            />): "Something went wrong. Please try again later"}
          </Fragment>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {auditedReportDetails === STATUS.RUNNING ? (
                <CircularProgress />
              ) : auditedReportDetails === STATUS.ERROR ? (
                "Something went wrong. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ReportDetails;
