import { Typography, Box, Container, Grid } from '@mui/material';
import React from 'react'
import CeroInfoPair from '../../../components/CeroInfoPair';
import useStyles from '../styles';
import CeroItemPair from '../../../components/CeroItemPair';

const SupplierHumanRightsApproval = (props) => {
    const classes = useStyles();
    const { emissionData } = props;

    return (
        <Container className={classes.mainContainer}>
        {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Supplier Name:"
              value={item.supplier_name ? item.supplier_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Risk of Child Labour:"
              value={item.risk_of_child_labor ? item.risk_of_child_labor : "NA"} 
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Child Labour risk explanation:"
              value={item.child_labor_details ? item.child_labor_details : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Risk of Forced or Compulsory Labour:"
              value={item.risk_of_compulsory_labor ? item.risk_of_compulsory_labor : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Forced Labour risk explanation:"
              value={item.compulsory_labor_details ? item.compulsory_labor_details : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Incidents and Remediation actions:"
              value={item.incident_or_action_occurred ? item.incident_or_action_occurred : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            </Container>
        ))}
        </Container>
  )
}

export default SupplierHumanRightsApproval