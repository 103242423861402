import * as yup from "yup";

export const assignReportSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup
    .string("Enter your name")
    .required("Name is required"),
});
