import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Container, Pagination, Typography } from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import { clearEmissionList, getEmissionList } from "../../redux/actions";
import { sampleYearArray } from "../../constants";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";
import EmissionTable from "./EmissionTable";
import useStyles from "./styles";
import { STATUS } from "../../redux/constants";
import EmployeeHireTable from "./EmployeeHireTable";
import { getCookie } from "../../services/cookie";
import EmployeesDiversityTable from "./EmployeesDiversityTable";
import BoardDiversityTable from "./BoardDiversity/BoardDiversityTableByGender";
import ManagementDiversity from "../EmissionsDetails/ManagementDiversity";
import ManagementDiversityTable from "./ManagementDiversity/ManagementDiversityTable";
import CeroButton from "../../components/CeroButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const savedPage = {
  pageNumber: 1,
};

const DEFAULT_ITEMS_PER_PAGE = 20;

const EmissionList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");
  const emissionData = useSelector((state) => state.emission.emissionList.data.emissions);
  const totalEntries = useSelector((state) => state.emission.emissionList.data.total_count);
  const emissionDataStatus = useSelector((state) => state.emission.emissionList.status);
  const role = useSelector((state) => state.auth.role);
  const isAuditor = role.includes(rolesEnum.AUDITOR);
  const isOnlyAdmin = (role.includes(rolesEnum.ADMIN) && role.length === 1) || role==="admin";

  const emissionYear = useSelector((state) => state.emission.addStationaryCombustion);
  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );
  const emissionType = type;
  const [page, setPage] = useState(1);
  var x = sessionStorage.getItem("year");
  const [filter, setFilter] = useState({
    month: selectedMonth,
    year: selectedYear || Number(x) || sampleYearArray[0].key,
    facility_id: selectedFacility,
  });
  // useEffect(() => {
  //   !emissionType
  //     ? navigate("stationary_combustion")
  //     : onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
  //   return () => {
  //     clearEmissionList();
  //   };
  // }, [emissionType]);

  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [filter]);
  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS){
      onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
      return () => {
        clearEmissionList();
      };
  }
  }, [deleteEmissionData]);

  useEffect(() => {
    if (!emissionType) {
      navigate("emission-esg");
      return;
    }

    onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);

    return () => {
      clearEmissionList();
    };
  }, [emissionType, filter]);


  const onLoadMore = (limit, pageNumber) => {
    const skip =(pageNumber - 1) * 20;
    const newLimit = DEFAULT_ITEMS_PER_PAGE *  pageNumber;    
    const filterRequest = {
      limit: newLimit,
      skip,
      month: selectedMonth,
      year: selectedYear || Number(x) || sampleYearArray[0].key,
      facility_id: selectedFacility,
      period:period,
      country:country
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    dispatch(getEmissionList(emissionType, filterRequest, isAuditor, company, typeof(selectedFacility)==='string'?selectedFacility==='null'?null:selectedFacility.split(','):selectedFacility, period, country));
  };

  const [tableShow, setTableShow] = useState(false);
  const onApplyFilter = (filter) => {
    setTableShow(filter.tableShow);
    setFilter(filter);
  };
  const backToEmission = (tableShow) => {
    setTableShow(tableShow);
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onLoadMore(DEFAULT_ITEMS_PER_PAGE,newPage);
  };

  console.log('emissionType', emissionType)

  const BusinessUserDelete = role.includes('business_user') && ['stationary_combustion', 'mobile_combustion', 'refrigerants', 'transportation', 'water', 'waste', 'purchased_electricity', 'water_consumption', 'water_discharge'].includes(emissionType)
  const FacilityManagerDelete = role.includes('facility_manager') && ['employees_hire_and_turnover', 'employees_benefit', 'employee_health_safety_incident_record', 'worker_safety_training_procedures', 'employees_diversity_and_ratio_of_basic_salary', 'discrimination_incident_record', 'supplier_screening_using_enviormental_social_criteria', 'social_engagement_human_rights_training', 'local_communities', 'political_contributions', 'training_hours_per_employee', 'performance_and_career_development_programs','suppliers_human_rights'].includes(emissionType)


  const startItem = (page - 1) * DEFAULT_ITEMS_PER_PAGE + 1;
  const endItem = Math.min(startItem + DEFAULT_ITEMS_PER_PAGE - 1, totalEntries);
const getHeader = () => {
  return (
      <Box className={classes.tableTopic}>
        <Typography variant="h7" component="span">
        <span className={classes.tableTitle}>
        {emissionType.replace(/_/g, ' ')}
        </span>
        </Typography>
        <Box>
        <CeroButton
          buttonText="Back"
          variant="outlined"
          className={classes.buttonSecondary}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        />
        </Box>
      </Box>
  )
}
  return (
    <DashboardLayout>
      <Container className={classes.container}>

      {emissionDataStatus === STATUS.SUCCESS ? (
  <>
    {emissionType === "employees_hire_and_turnover" ? (
      <>{getHeader()}
      <EmployeeHireTable 
        emissionData={emissionData}
        dataStatus={emissionDataStatus}
        onLoadMore={onLoadMore}
        emissionType={emissionType}
        isAuditor={isAuditor}
        company={company}
        backToEmission={backToEmission}
        isOnlyAdmin={!isOnlyAdmin}
        period={period}
        isDeleteActionRequired={true}
      /></>
    ) : (
      emissionType === "employees_diversity_and_ratio_of_basic_salary" ? (
        <>{getHeader()}
        <EmployeesDiversityTable 
      emissionData={emissionData}
      dataStatus={emissionDataStatus}
      onLoadMore={onLoadMore}
      emissionType={emissionType}
      isAuditor={isAuditor}
      company={company}
      backToEmission={backToEmission}
      isOnlyAdmin={!isOnlyAdmin}
      period={period}
      isDeleteActionRequired={true}
      /></>
      ) : emissionType === "board_diversity" ? (
        <>{getHeader()}
        <BoardDiversityTable 
      emissionData={emissionData}
      dataStatus={emissionDataStatus}
      onLoadMore={onLoadMore}
      emissionType={emissionType}
      isAuditor={isAuditor}
      company={company}
      backToEmission={backToEmission}
      isOnlyAdmin={!isOnlyAdmin}
      period={period}
      isDeleteActionRequired={true}
      />
      </>
      ):emissionType === "management_diversity" ? (
        <>{getHeader()}
        <ManagementDiversityTable 
      emissionData={emissionData}
      dataStatus={emissionDataStatus}
      onLoadMore={onLoadMore}
      emissionType={emissionType}
      isAuditor={isAuditor}
      company={company}
      backToEmission={backToEmission}
      isOnlyAdmin={!isOnlyAdmin}
      period={period}
      isDeleteActionRequired={true}
      /></>
      ): (
        <>
          <EmissionTable
            emissionData={emissionData}
            dataStatus={emissionDataStatus}
            onLoadMore={onLoadMore}
            emissionType={emissionType}
            isAuditor={isAuditor}
            company={company}
            backToEmission={backToEmission}
            isOnlyAdmin={!isOnlyAdmin}
            isDeleteActionRequired={true}
            BusinessUserDelete={BusinessUserDelete}
            FacilityManagerDelete={FacilityManagerDelete}
          />
          {totalEntries > 0 && (
            <div className={classes.pagination}>
              <Typography variant="body" style={{ fontSize: 12 }}>
                Showing {startItem} to {endItem} of {totalEntries} entries
              </Typography>
              <Pagination
                count={Math.ceil(totalEntries / DEFAULT_ITEMS_PER_PAGE)} 
                page={page}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          )}
        </>
      )
    )}
  </>
) : (  
  <div className={classes.loading}>
    <CircularProgress />
  </div>
)}

      </Container>
    </DashboardLayout>
  );
};

export default EmissionList;