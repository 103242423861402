import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { getDashboardStatistics } from "../../../redux/actions/dashboard";
import useStyles from "./styles";
import StatisticsCard from "./StatisticsCard";
import clsx from "clsx";
import { getCookie } from "../../../services/cookie";
import { STATUS } from "../../../redux/constants";

const Statistics = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const statisticsData = useSelector(
    (state) => state.dashboard.getStatistics.data
  );
  const userRoleUpdateStatus = useSelector(
    (state) => state.auth.setActiveRole.status
  );
  const dataKeys = Object.keys(statisticsData);
  const role = getCookie("role");
  const totalEmission = statisticsData.total_co2e;
  const energyUsed = statisticsData.total_energy_consumed;
  const scope1 = statisticsData.Scope_1;
  const scope2 = statisticsData.Scope_2;
  const scope3 = statisticsData.Scope_3;

  useEffect(() => {
    if (role !== "read_only_user")
      if ([STATUS.SUCCESS, STATUS.IDLE].includes(userRoleUpdateStatus))
        dispatch(getDashboardStatistics(props.filter));
  }, [dispatch, props.filter, userRoleUpdateStatus]);

  return (
    <>
      {role === "sustainability_manager" || role === "proxy_sustainability_manager" ||
      role === "read_only_user" ? (
        ""
      ) : (
        <>
          <Grid container spacing={5} className={classes.cardsContainer}>
            {dataKeys.map((item, index) => {
              if (item === "status") {
                return null;
              }
              return (
                <Grid item className={clsx(classes.cardItem, {})} key={index}>
                  <StatisticsCard
                    index={index}
                    title={statisticsData[item]?.prop?.title}
                    value={statisticsData[item]?.prop?.value}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export default Statistics;
