import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.Primary.background,
    marginRight: 15,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  popUp: {
    margin: "auto",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "flex-start",
    gap:5,
    paddingTop: 20,
    paddingBottom: 15,
    // width: "50%",
    // margin: "auto",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
  },
}));

export default useStyles;