import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    redBackground: {
      backgroundColor: "#C00",
      padding: 10
    },
    whiteBackground: {
      backgroundColor: "#FFF"
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: 2,
      backgroundColor: "#FFF",
      maxHeight:39
    },
    noLabel: {
      marginTop: theme.spacing(3)
    },
    justCheck:{
        width:'100%',
        maxHeight:'100%',
        "& .MuiOutlinedInput-notchedOutline":{
            borderColor: `${theme.palette.Primary.main} !important`
          }
    },
    justCheckSelected:{
      width:'100%',
        maxHeight:'100%',
        "& .MuiOutlinedInput-notchedOutline":{
            borderColor: `${theme.palette.Primary.main} !important`
          },
        "& > fieldset > legend":{
          maxWidth: '100%',
          paddingLeft:55
        }
    },
    input: {
        width: '100%',
        height: '100%',
        "& MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.Primary.main} !important`
          },  
          "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
            borderColor: "green !important"
          }
    },
    label: {
        color: theme.palette.Primary.main,
        margin: -7,
        "&.Mui-focused": {
          color: theme.palette.Primary.main,
          margin: -3,
        },
        "&.MuiFormLabel-filled":{
          margin: -3,
        }
      },
      indeterminateColor:{
        "& .MuiSvgIcon-root":{
        color:'rgb(140, 33, 72)'
        }
      },
      checkbox:{
      "& .MuiSvgIcon-root":{
        color:'rgb(140, 33, 72)'
        }
      },
      selectedAll:{
        "& .MuiSvgIcon-root":{
          color:'rgb(140, 33, 72)'
          }
      }
  }));

export default useStyles;

