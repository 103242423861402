import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography } from "@mui/material"
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, ArcElement, registerables as registerablesJS } from 'chart.js';
import _ from 'lodash';
import { facilityDistribution, getEmissionRegion, getEsgOverview } from '../../../redux/actions';

import useStyles from './styles'
import EsgOverviewChart from './EsgOverviewChart';
import FacilityDistributionChart from './FacilityDistributionChart';
import { getCookie } from '../../../services/cookie';

ChartJS.register(...registerablesJS)
ChartJS.register(ArcElement, Tooltip, Legend);

const GeographicalChart = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();

    const role = getCookie('role')
    const emissionData = useSelector(state => state.dashboard.getEmissionRegion.data);
    const facilityData = useSelector(state => state.dashboard.facilityDistribution.data);

    const emissionDataStatus = useSelector(state => state.dashboard.getEmissionRegion.status);
    const facilityDataStatus = useSelector(state => state.dashboard.facilityDistribution.status);
    const esgOverviewStatus = useSelector(state => state.dashboard.getEsgOverview.status);

    const esgOverview = useSelector(
        (state) => state.dashboard.getEsgOverview.data.response
      );

      

    useEffect(() => {
        // dispatch(getEmissionRegion(props.filter));
        dispatch(getEsgOverview(props.filter));
        dispatch(facilityDistribution(props.filter));

    }, [props.filter, dispatch]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    fontColor: 'rgba(0,0,0,.4)'
                },
                align: 'end',
                position: 'top',
            },
            title: {
                align: 'start',
                display: true,
                text: 'Geographical Units',
                font: {
                    size: 18,
                    family: 'Inter'
                }
            },
        },
        maintainAspectRatio: false
    };

    const data = {
        labels: _.map(emissionData, (item) => item.country),
        datasets: [
            {
                label: 'Emissions',
                data: _.map(emissionData, item => item.total_co2e),
                backgroundColor: [
                    '#DC7A48',
                    '#E69D78',
                    '#F5D9CB',
                    '#003f5c',
                    '#2f4b7c',
                    '#488f31',
                    '#6aa040',
                    '#89b050',
                    '#a7c162',
                    '#c5d275',
                    '#de425b',
                    '#faa15e'
                ]
            },
        ],
    };

    return (
      <Paper className={classes.container}>
        {/* <Doughnut data={data} options={options} height={300} /> */}
        {role === "admin" || role === "facility_manager" ? (
          <>
            <Typography className={classes.graphTitle}>
              Facility Distribution
            </Typography>
            <div className={classes.chart}>
              {facilityData.length > 0 ? (
                <FacilityDistributionChart data={facilityData} />
              ) :facilityDataStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <Typography className={classes.graphTitle}>ESG Overview</Typography>
            <div className={classes.doughnutChart}>
              {esgOverview?.length > 0 ? (
                <EsgOverviewChart data={esgOverview} />
              ) :esgOverviewStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
        )}
      </Paper>
    );
}

export default GeographicalChart
