import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.white,
        height: 'fit-content',
        width: '95%',
        borderRadius: 14,
        padding: theme.spacing(5, 7),
        paddingBottom: 0,
        position: 'relative',
    },
    innerContainer: {
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.background.darkGray
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.background.darkGray
        }
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    buttonContainer: {
        padding: theme.spacing(4, 0),
    },
    button: {
        width: 'fit-content',
        height: 42
    },
    buttonPrimary: {
        background: theme.palette.Primary.background,
        textTransform: "capitalize",
        width: "auto",
        height: 40,
        marginRight: 15,
        fontWeight: 400,
        padding: theme.spacing(0,8),
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    },
    buttonSecondary: {
        color: theme.palette.Primary.background,
        borderColor: theme.palette.Primary.background,
        textTransform: "capitalize",
        width: "auto",
        height: 40,
        padding: theme.spacing(0,5),
        "&:hover": {
            borderColor: theme.palette.Primary.background,
            backgroundColor:theme.palette.Secondary.hoverButton,
          },
        marginRight: theme.spacing(5),
    },
    deleteButton: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.Primary.main,
        '&:hover': {
            backgroundColor: theme.palette.Primary.dark,
        }
    },
    topContainer: {
        maxWidth: 700,
    },
    bottomContainer: {
        maxWidth: 700,
        marginTop: theme.spacing(5),
    },
    previewTitle: {
        marginBottom: theme.spacing(3)
    },
    previewItem: {
        margin: theme.spacing(1, 0),
        fontSize: 15
    },
    secondResultContainer: {
        paddingLeft: `${theme.spacing(4)} !important`,
        marginLeft: theme.spacing(4),
        borderLeft: `solid 1px ${theme.palette.Other.stroke}`,
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: 35,
        paddingTop: 10,
        alignItems: 'center',
        cursor: "pointer"
    },
    input:{
        maxWidth: "352px !important",
        "& .MuiFormControl-root-MuiTextField-root":{
            maxWidth: "352px",
        }
    },
    selectContainer:{
        maxWidth: "352px !important",
    },
    paperRoot: {
        left: "46% !important"
    },
}));

export default useStyles;
