import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100% - 100px)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    marginTop: 20,
    overflow: "auto",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableInnerContainer: {
    maxHeight: "100%",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 170,
    height: 46,
    fontSize: 14,
    marginLeft: theme.spacing(2),
    marginTop:0,
    fontWeight: 400,
    textTransform: "capitalize",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
