import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing( 8, 10),
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        '@media (min-width: 1200px)': {
            maxWidth: "none"
          },
    },
    tabsCard: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
        paddingTop: theme.spacing(4),
        borderRadius: 5,
        "& .MuiTab-root": {
          color: theme.palette.text.dark,
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: 16,
        },
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.Primary.background,
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: 16,
        },
        "& .MuiTabs-indicator": {
          backgroundColor: theme.palette.Primary.background,
          height: 3,
        },
      },
    card:{
        margin: theme.spacing(5, "auto"),
        width: "50%",
        '@media (min-width: 1200px)': {
            width: "45% !important",
          },
    },
    details:{
        margin: "auto", 
    },
    cardContent:{
        display: "flex",
        justifyContent: "center",
    },
    subContent:{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    image: {
        width: '150px',
        height: '150px',
        '& .MuiGrid-root>.MuiGrid-item':{
            display: "flex",
            justifyContent: "center"
        }
    },
    profileHead: {
        fontWeight: 500,
        minWidth: '15vw',
        height: 50,
        left: '742px',
        top: '251px',
        fontSize: 25,
        lineHeight: '140%',
        textAlign: "center",
        margin: theme.spacing(2),
    },
    profileKey: {
        width: '126px',
        height: '28px',
        left: '742px',
        top: '347px',
        fontWeight: 550,
        fontSize: 14,
        lineHeight: '140%',
    },
    profileValues: {
        height: '28px',
        left: '894px',
        top: '347px',
        fontSize: 14,
        lineHeight: '140%',
        color: theme.palette.Primary.main,

    },
    profileDesignationValues: {
        height: '28px',
        left: '894px',
        top: '347px',
        fontSize: 14,
        lineHeight: '140%',
        color: theme.palette.Primary.main,
        textTransform: "capitalize"
    },
    button: {
        backgroundColor: `${theme.palette.Primary.background}!important`,
        fontWeight: '400!important',
        fontSize: 13,
        minWidth: 145,
        textTransform: "none",
        marginTop: theme.spacing(5),
        borderRadius: 10
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        padding: theme.spacing(5,0,0,10),
        alignItems: 'center',
        cursor: "pointer"
    },
    tableContainer: {
        "& .MuiTable-root":{
          paddingBottom: theme.spacing(3)
        }
    },
    dataPrivacyCard:{
        marginTop: theme.spacing(5),
        padding: theme.spacing(5),
        boxShadow: "none",
        borderRadius: 8,
    },
    dataBox:{
        width: "100%",
        marginTop: theme.spacing(5),
        borderRadius: 8,
        border: "1px solid rgba(80, 80, 80, 0.15)",
        paddingBottom: theme.spacing(2),
    },
    dataCard: {
        display: "flex",
        justifyContent: "flex-start",
        padding: theme.spacing(4),
        borderBottom: "0.5px solid rgba(80, 80, 80, 0.15)",
        paddingLeft: theme.spacing(20),
    },
    title: {
        backgroundColor: "#F9F7F7",
        padding: theme.spacing(4),
    },
    dataTitle: {
        width: "40%"
    },
    dataValue: {

    }
}));

export default useStyles;