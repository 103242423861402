import * as yup from 'yup';

export const addEmployeeHours = yup.object({
  facility: yup.string().required('Facility is required'),
  year: yup.string().required('Year is required'),
  assessmentPeriod: yup.string().required('Assessment period is required'),
  trainingTitle: yup.string().required('Training title is required'),
  trainingDetails: yup.string().required('Training details is required'),
  totalTrainingHours: yup.number().required('Total Training Hours is required').integer('Total Training Hours must be an integer'),
  femaleTrainingHours: yup.number().required('Total female training Hours is required').integer('Total female training Hours must be an integer'),
  maleTrainingHours: yup.number().required('Total female training Hours is required').integer('Total female training Hours must be an integer'),
  trainingMethods: yup.string().required('Training methods is required')
});
