import { Box, Typography } from "@mui/material"

import CeroSideSheetDrawer from "../../components/CeroSideSheetDrawer"
import useStyles from './styles'

const EmissionDeletePrompt = (props) => {
    const { setOpenDeletePrompt } = props
    const classes = useStyles()

    const getPrimaryConfirmDrawer = () => {
        return (
            <Box className={classes.mainContainer}>
                <Typography >Are you sure you want to delete topic?</Typography>
            </Box>
        )
    };

    return (
        <CeroSideSheetDrawer
            primaryDrawer={{
                drawerOpen: props.isOpen,
                onClose: props.onClose,
                content: getPrimaryConfirmDrawer(),
                header: { title: "Confirm!" },
                classes: {
                    drawerContainer: classes.drawerContainer,
                    contentArea: classes.drawerContentArea,
                },
                footer: {
                    primaryBtnTitle: 'Confirm',
                    secondaryBtnTitle: 'Cancel',
                    hideSecondaryBtn: false,
                    primaryBtnAction: props.onConfirm,
                    secondaryBtnAction: props.onClose,
                },
            }}
        />
    )
}
export default EmissionDeletePrompt
