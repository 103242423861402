import React, { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import CeroPopUp from "../../../../components/CeroPopUp";

const DashboardNews = (props) => {
  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNews, setSelectedNews] = useState({ title: "", message: "" });
  const [visibleNews, setVisibleNews] = useState(
    props?.newsHeadline?.map(() => true) 
  );
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0); 
  const scrollData = props?.newsHeadline;
  
  useEffect(() => {
    const timer = setTimeout(() => {
      moveToNextNews();
    }, 7000); 
    
    return () => clearTimeout(timer);
  }, [currentNewsIndex]);

  const moveToNextNews = () => {
    let nextIndex = (currentNewsIndex + 1) % scrollData.length;
    while (!visibleNews[nextIndex]) { 
      nextIndex = (nextIndex + 1) % scrollData.length;
    }
    setCurrentNewsIndex(nextIndex);
  };

  const handleCloseIconClick = (e, index) => {
    e.stopPropagation();
    const updatedVisibleNews = [...visibleNews];
    updatedVisibleNews[index] = false;
    setVisibleNews(updatedVisibleNews);

    if (index === currentNewsIndex) {
      moveToNextNews();
    }
  };

  const handleReadMoreClick = (newsItem) => {
    setSelectedNews({ title: newsItem.title, message: newsItem.message });
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const getNewsPopup = () => (
    <>
      <CloseIcon onClick={handlePopupClose} className={classes.closeIcon} />
      <Typography variant="body2" className={classes.newsContent}>
        {selectedNews.message}
      </Typography>
    </>
  );

  return (
    <Box position="relative">
      {scrollData?.map((item, index) =>
        visibleNews[index] && index === currentNewsIndex ? ( 
          <TextField
            key={index}
            id="read-only-input"
            label="Latest News"
            className={classes.textField}
            value={item.message ? `${item.message.slice(0, 100)}...Read more` : ""}
            onClick={() => handleReadMoreClick(item)}
            InputProps={{
              readOnly: true,
              className: classes.newsScroll,
              endAdornment: (
                <>
                  <CloseIcon
                    onClick={(e) => handleCloseIconClick(e, index)}
                    className={classes.newsClose}
                  />
                </>
              ),
            }}
            fullWidth
          />
        ) : null
      )}
      <CeroPopUp
        primaryPopUp={{
          open: showPopup,
          onClose: handlePopupClose,
          content: getNewsPopup(),
          header: {
            title: (
              <Typography variant="h5" className={classes.title}>
                {selectedNews.title}
              </Typography>
            ),
          },
        }}
        classes={{ dialog: classes.dialog }}
      />
    </Box>
  );
};

export default DashboardNews;
