import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroButton from "../../components/CeroButton";
import { useNavigate } from "react-router-dom";

const TradeDashboard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate()

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer}}>
      <Container className={classes.container}>
        <Paper className={classes.card}>
        <Typography style={{fontWeight: 500}}>Please select if you want to BUY or SELL Carbon credits</Typography>
        <Box>
          <CeroButton
           className={classes.buttonPrimary}
           onClick={()=>{navigate('/trade/buy')}}
           buttonText="Buy"
          />
          <CeroButton
            className={classes.buttonPrimary}
            onClick={()=>{navigate('/trade/sell')}}
            buttonText="Sell"
          />
        </Box>
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default TradeDashboard;
