import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import { getTicketStatus } from "../../../redux/actions";
import useStyles from "./styles";
import TicketStatusChart from "../../Dashboard/FuelSourceGraph/TicketStatusChart";
import { getCookie } from "../../../services/cookie";

const TicketStatusGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuditor = getCookie("role") === "auditor";

  const ticketStatus = useSelector(
    (state) => state.dashboard.getTicketStatus.data
  );

  const userRole = getCookie("role");
  if (userRole === "auditor") {
    var role = "auditor";
  } else {
    role = "business";
  }

  useEffect(() => {
    if (userRole !== "read_only_user")
      dispatch(getTicketStatus(isAuditor, props.filter, role));
  }, [dispatch, props.filter, role]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>Ticket Status</Typography>
      {ticketStatus ? (
        <div>
          <TicketStatusChart apiResponse={ticketStatus} />
        </div>
      ) : (
        "Data loading ..."
      )}
    </Paper>
  );
};

export default TicketStatusGraph;
