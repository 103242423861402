import * as yup from 'yup';

export const schemeValidation = yup.object({
  facility: yup.string().required('Facility is required'),
  year: yup.string().required('Year is required'),
  assessmentPeriod: yup.string().required('Assessment Period is required'),
  politicalContributions: yup.string().required('Choose whether your company made political contributions during the reporting period.'),
  amount: yup.number()
    .when('politicalContributions', {
      is: 'Yes',
      then: yup.number().required('Total amount of Political Contributions is required when political contributions were made.'),
      otherwise: yup.number()
    }),
  beneficiaryContribution: yup.string().when('politicalContributions', {
    is: 'Yes',
    then: yup.string().required('Beneficiary of the contribution is required when political contributions were made.'),
    otherwise: yup.string()}),
  contributionDescription: yup.string().when('politicalContributions', {
    is: 'Yes',
    then: yup.string().required('Description of Contributions is required when political contributions were made.'),
    otherwise: yup.string()
  }),
  disclosureExplanation: yup.string().when('politicalContributions', {
    is: 'Yes',
    then: yup.string().required('Disclosure explanation is required when political contributions were made.'),
    otherwise: yup.string()
  }),
});
