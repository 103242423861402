import * as yup from 'yup';

export const updateSubsidiesFinancialValidation = yup.object({
  facility: yup.string().required('Facility is required'),
  year: yup.string().required('Year is required'),
  assessmentPeriod: yup.string().required('Assessment period is required'),
  financialAssistanceRecieved:yup.number().required('Financial Assistance is required'),
  financialAssistanceType:yup.array().required("Financial Assistance type is required"),
  isGovernmentPresent:yup.string().required('Government is present in the shareholding structure of the organization is required'),
  details:yup.string().required('details is required'),
});
