import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
  },
  card: {
    marginTop: theme.spacing(5),
    borderRadius: 5,
    position: "relative",
    height: "auto",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  actionBox: {
    position: "absolute",
    right: "20%",
    top: "20%",
  },
  infoContainer: {
    display: "flex !important",
    justifyContent: "flex-start",
    fontWeight: 500,
    padding: theme.spacing(2,0),
  },
  title: {
    width: "350px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  value: {
    fontWeight: 500,
  },
  activeStatus: {
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    textTransform: "capitalize",
  },
  remainingValue: {
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    color: "red",
  },
  statusContainer:{
    display:"flex !important",
    alignItems: 'start', 
    gap: '46px',
    paddingBottom: theme.spacing(5),
  },
  statusTitle:{
    width: "50% !important",
    fontSize: 16,
  },
  statusBox: {
    display: "flex",
    marginRight: theme.spacing(5),
  },
  radioBox: {
    "& .MuiFormControlLabel-label":{
        fontWeight: 500,
        fontSize: 14
    } 
  },
  radio: {
    color: `${theme.palette.Primary.background} !important`,
    "& .MuiButtonBase-root-MuiRadio-root.Mui-checked":{
        color: `${theme.palette.Primary.background} !important`,
    }
  },
  editContainer: {
    display: "flex !important",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: 500,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto"
  },
  popUp:{
    margin: "auto",
  },
  dialog:{
    width: "40% !important",
    textAlign: "center",
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer"
  },
  subValue: {
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    color: theme.palette.Primary.background,
  }
}));

export default useStyles;
